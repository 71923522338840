import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiArrowLeftSLine, RiCheckFill } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

import { useInvestorAccounts } from 'providers/investor-accounts';

const useStyles = makeStyles((theme) => ({
  header: {
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '16px',
    marginBottom: '40px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },

  headerTitle: {
    textDecoration: 'underline',
  },

  body: {
    position: 'relative',
    margin: 0,
    padding: 0,
    outline: 0,
    listStyle: 'none',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  menuItem: {
    display: 'flex',
    gap: '8px',
    padding: '8px 24px',
  },

  itemIcon: {
    width: '24px',
    fontSize: '24px',
    flexShrink: 0,
  },

  itemContent: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
}));

interface Props {
  onBack?: () => void;
  onSidebarClose?: () => void;
}

export const AccountSelectSubmenu = ({ onBack, onSidebarClose }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { accounts, selectedAccount, selectAccount } = useInvestorAccounts();

  return (
    <>
      <Box className={classes.header} onClick={() => onBack?.()}>
        <RiArrowLeftSLine size={24} />
        <Typography className={classes.headerTitle}>
          <FormattedMessage id="switch_account" />
        </Typography>
      </Box>

      <Box component="ul" className={classes.body}>
        {accounts.map((account, index) => {
          // prettier-ignore
          const isSelected = !!selectedAccount?.investor && selectedAccount.investor?.id === account.investor?.id;

          return (
            <MenuItem
              key={account.investor?.id ?? index}
              className={classes.menuItem}
              // Async method is a workaround. problem is that we have bunch of 
              // `collapsed` stated that should be synched between each other.
              // And we need to await until react queue will finish with hook updates.
              onClick={async () => {
                if (account.investor?.id) {
                  await onSidebarClose?.();
                  selectAccount(account.investor.id);
                }
              }}
              selected={isSelected}
            >
              {isSelected ? (
                <RiCheckFill className={classes.itemIcon} color={theme.palette.primary.main} />
              ) : (
                <Box className={classes.itemIcon} />
              )}

              <Typography className={classes.itemContent}>
                {account?.investor?.name} (
                <FormattedMessage id={`role.${account.role}`} />)
              </Typography>
            </MenuItem>
          );
        })}
      </Box>
    </>
  );
};

import { formatMoney as fm } from 'accounting';
import dayjs from 'dayjs';
import { Currency } from 'types';

export const toTwoDecimals = (value: number) => (value / 100).toFixed(2);

export const safeParseNumber = (value: string, currency?: Currency) => {
  const parsed = parseFloat(value);
  if(isNaN(parsed)) return value;
  return parsed > 1000 ? formatMoney(parsed, {prefix: currency}) : parsed.toFixed(2)
}

export const toMoneyNumberWithDecimals = (
  value?: number | null,
  roundDown?: boolean,
) => {
  const numValue = value ?? 0;
  return ((roundDown ? -Math.round(-numValue) : Math.round(numValue)) / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '’');
};

export const toPercent = (val: number, maxDigits = 2) => {
  return `${+(val * 100).toFixed(maxDigits)}%`;
};
type BaseConfig = Parameters<typeof fm>[1];

// other cfg options can be found in `accounting` module
type Config = Omit<BaseConfig, 'symbol'> & {
  /** Prefix before formatted number */
  prefix?: string;
  /** Suffix after formatted number */
  suffix?: string;
  /**
   * If the number doesn't have minor units (cents) it will not display it
   * (will not put `.00` at the end)
   */
  optionalDecimals?: boolean;
  /** Will put `+` | `-` before number */
  forceSign?: boolean;
  /** Will put space after sign */
  spaceSign?: boolean;
};

const DEFAULT_CONFIG: Config & { symbol: string } = {
  decimal: '.',
  thousand: '’',
  precision: 2,
  symbol: '',
};

const resolveEndSpace = (withSign: boolean) => (str: string) => {
  return `${str}${withSign ? ' ' : ''}`;
};

export const formatMoney = (value: number, config: Config = {}) => {
  const cfg = Object.assign({ ...config }, DEFAULT_CONFIG);
  let res = fm(value, cfg as BaseConfig);

  if (cfg.optionalDecimals && value % 1 === 0) {
    res = res.slice(0, res.lastIndexOf(cfg.decimal!));
  }

  res = res.replace(/^-/, '');

  const applySpace = resolveEndSpace(!!cfg.spaceSign);
  const sign =
    value > 0 && cfg.forceSign === true
      ? applySpace(`+`)
      : value < 0
      ? applySpace(`-`)
      : '';

  res = `${cfg.prefix ?? ''}${sign}${res}${cfg.suffix ?? ''}`;
  return res;
};

export const formatDateTime = (date: string) => {
  return dayjs(date).format('DD.MM.YYYY HH:mm');
};

export const formatBigNumber = (value: number, precision = 0) => {
  const numberSymbols = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  let fitting: { value: number; symbol: string };

  for (let i = numberSymbols.length - 1; i >= 0; i--) {
    fitting = numberSymbols[i];
    if (value >= fitting.value) break;
  }

  return (
    (value / fitting!.value)
      .toFixed(precision)
      .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + fitting!.symbol
  );
};

import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { InvestmentWithDealDto } from 'api/investor-client';
import { DataOverviewTable, ITableRow } from 'components';
import Card from 'components/card';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';

interface Props {
  investment: InvestmentWithDealDto;
}

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
  },
  containerBody: {
    flex: 1,
    justifyContent: 'center',
  },
  repaymentTable: {
    '& th,td': {
      textAlign: 'right',
    },
  },
  dataDisplay: {
    textAlign: 'center',
    fontSize: '18px',
    adding: '0px 20px',
    '&:not(:last-child)': {
      marginBottom: '30px',
    },
  },
  dataDisplayTitle: {
    fontWeight: 600,
    color: '#37A1E4',
  },
  dataDisplayContent: {},
}));

export const InvestmentHeader = ({ investment }: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  const formatMoney = useFormatMoney({
    prefix: `${investment.deal.currency} `,
  });

  const investmentTableRows = useMemo((): ITableRow[] => {
    return [
      {
        cols: [
          intl.formatMessage({ id: 'status' }),
          intl.formatMessage({
            id: investment.canceled
              ? `investment.status.Cancelled`
              : investment.type === 'Commitment'
                ? 'investment.status.committed'
                : `investment.status.${investment.status}`,
          }),
        ],
        variant: 'value-accent',
        divider: true,
      },
      {
        cols: [
          intl.formatMessage({ id: 'investment_date' }),
          dayjs(investment.date).format('DD.MM.YYYY'),
        ],
        variant: 'value-accent',
      },
      {
        cols: [
          ...(investment.status !== 'Loss'
            ? [
              intl.formatMessage({ id: 'expected_end_date' }),
              dayjs(investment.deal.endDate).format('DD.MM.YYYY'),
            ]
            : [
              intl.formatMessage({ id: 'default_date' }),
              dayjs(investment.deal.lossDate).format('DD.MM.YYYY'),
            ]),
        ],
        variant: 'value-accent',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment, intl.formatMessage]);

  const repaymentTableRows = useMemo((): ITableRow[] => {
    let realizedPrincipal = 0;
    let realizedInterest = 0;
    let outPrincipal = 0;
    let outInterest = 0;
    let lostPrincipal = 0;
    let lostInterest = 0;

    for (const invRepayment of investment.repayments) {
      const transaction = invRepayment.transaction;
      if (invRepayment.isLost) {
        lostPrincipal += transaction.principal;
        lostInterest += transaction.interest;
      } else if (invRepayment.isBooked) {
        realizedPrincipal += transaction.principal;
        realizedInterest += transaction.interest;
      } else {
        outPrincipal += transaction.principal
        outInterest += transaction.interest;
      }
    }

    const totalPrincipal = realizedPrincipal + outPrincipal + lostPrincipal;
    const totalInterest = realizedInterest + outInterest + lostInterest;

    return [
      {
        cols: [
          null,
          intl.formatMessage({ id: 'principal' }),
          intl.formatMessage({ id: 'interest' }),
        ],
        variant: 'header',
      },
      {
        cols: [
          intl.formatMessage({ id: 'realized' }),
          formatMoney(moveDecimalPoint(realizedPrincipal, -2)),
          formatMoney(moveDecimalPoint(realizedInterest, -2)),
        ],
      },
      ...(lostPrincipal > 0 || lostInterest > 0) ? [{
        cols: [
          intl.formatMessage({ id: 'lost' }),
          formatMoney(moveDecimalPoint(lostPrincipal, -2)),
          formatMoney(moveDecimalPoint(lostInterest, -2)),
        ],
      }] : [],
      {
        cols: [
          intl.formatMessage({ id: 'outstanding' }),
          formatMoney(moveDecimalPoint(outPrincipal, -2)),
          formatMoney(moveDecimalPoint(outInterest, -2)),
        ],
        divider: investment.status !== 'Loss',
      },
      {
        cols: [
          intl.formatMessage({ id: 'total' }),
          formatMoney(moveDecimalPoint(totalPrincipal, -2)),
          formatMoney(moveDecimalPoint(totalInterest, -2)),
        ],
        variant: 'header',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment, intl.formatMessage]);

  const similarDealsLink = useMemo(() => {
    return `../../../deals/primary?pageIndex=0&pageSize=10&productType[]=${investment.deal.risk.productType}&rating[]=${investment.deal.risk.rating}`;
  }, [investment]);

  const dealTableRows = useMemo((): ITableRow[] => {
    return [
      {
        cols: [
          intl.formatMessage({ id: 'borrower.type' }),
          intl.formatMessage({
            id: `borrower.${investment.deal.risk.productType}`,
          }),
        ],
        variant: 'value-accent',
      },
      {
        cols: [
          intl.formatMessage({ id: 'rating' }),
          investment.deal.risk.rating,
        ],
        variant: 'value-accent',
        divider: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment, intl.formatMessage]);

  return (
    <Stack direction="row" spacing={4}>
      <Card className={classes.container}>
        <Card.Header
          variant="condensed"
          title={intl.formatMessage({ id: 'investment' })}
        />
        <Card.Body className={classes.containerBody}>
          <DataOverviewTable tableOnly rows={investmentTableRows} />
        </Card.Body>
      </Card>
      <Card className={classes.container}>
        <Card.Header
          variant="condensed"
          title={intl.formatMessage({ id: 'repayment' })}
        />
        <Card.Body className={classes.containerBody}>
          <DataOverviewTable
            tableOnly
            rows={repaymentTableRows}
            className={classes.repaymentTable}
          />
        </Card.Body>
      </Card>
      <Card className={classes.container}>
        <Card.Header
          variant="condensed"
          title={intl.formatMessage({ id: 'deal' })}
        />
        <Card.Body className={classes.containerBody}>
          <DataOverviewTable tableOnly rows={dealTableRows} />

          <Box textAlign="center">
            <Link to={similarDealsLink}>
              <Button variant="contained" sx={{ marginTop: '12px' }}>
                <FormattedMessage id="show_similar_investment_opportunities" />
              </Button>
            </Link>
          </Box>
        </Card.Body>
      </Card>
    </Stack>
  );
};

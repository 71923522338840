import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { DealDto } from 'api/investor-client';
import { IDealDetails } from 'utils';

const byOtherColor = '#122554';
const byYouColor = '#37A1E4';
const newEntryColor = '#FFD600';

const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%',
    height: '12px',
    display: 'flex',
    marginBottom: '16px',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },

  progressNode: {
    height: '100%',
    transition: theme.transitions.create(['width'], {
      duration: 350,
      easing: 'ease-out',
    }),
  },

  legendContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },

  legendGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  legendSymbol: {
    width: '6px',
    height: '6px',
    borderRadius: '50%',
  },
}));

interface Props {
  deal: DealDto;
  dealDetails: IDealDetails;
  amountToInvest: number;
}

export const InvestmentProgress = ({ deal, dealDetails, amountToInvest }: Props) => {
  const classes = useStyles();

  const { investorCommittedAmount, totalCommittedAmount } = dealDetails.common;
  const otherInvestedAmount = totalCommittedAmount - investorCommittedAmount;

  const otherInvestedPercentage = (otherInvestedAmount / deal.principal) * 100;
  const investedPercentage = (investorCommittedAmount / deal.principal) * 100;

  const leftoverPercentage = 100 - otherInvestedPercentage - investedPercentage;
  let amountToInvestPercentage = (amountToInvest / deal.principal) * 100;
  amountToInvestPercentage = Math.min(amountToInvestPercentage, leftoverPercentage);

  return (
    <Box>
      <Box className={classes.progress}>
        <Box
          className={classes.progressNode}
          sx={{
            backgroundColor: byOtherColor,
            width: `${otherInvestedPercentage}%`,
          }}
        />

        <Box
          className={classes.progressNode}
          sx={{
            backgroundColor: byYouColor,
            width: `${investedPercentage}%`,
          }}
        />

        <Box
          className={classes.progressNode}
          sx={{
            backgroundColor: newEntryColor,
            width: `${amountToInvestPercentage}%`,
          }}
        />
      </Box>

      <Box className={classes.legendContainer}>
        <Box className={classes.legendGroup}>
          <Box className={classes.legendSymbol} sx={{ backgroundColor: byOtherColor }} />
          <Typography variant="caption">
            <FormattedMessage id="by_others" />
          </Typography>
        </Box>

        <Box className={classes.legendGroup}>
          <Box className={classes.legendSymbol} sx={{ backgroundColor: byYouColor }} />
          <Typography variant="caption">
            <FormattedMessage id="by_you" />
          </Typography>
        </Box>

        <Box className={classes.legendGroup}>
          <Box className={classes.legendSymbol} sx={{ backgroundColor: newEntryColor }} />
          <Typography variant="caption">
            <FormattedMessage id="your_new_entry" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import { languages, Language as ELanguage } from 'countries-list';

type ELanguageExtended = ELanguage & { short: string };
export enum Language {
  DE = 'de-CH',
  EN = 'en-CH',
  // FR = 'fr-CH',
  // IT = 'it-CH',
  ACH = 'acholi',
}

export const languageCodeToLanguageMap = {
  'de-CH': { ...languages.de, short: 'DE' },
  'en-CH': { ...languages.en, short: 'EN' },
  'fr-CH': { ...languages.fr, short: 'FR' },
  'it-CH': { ...languages.it, short: 'IT' },
  acholi: { name: 'Dummy', native: 'Dummy', short: 'Dummy' } as unknown as ELanguageExtended,
};

export const DefaultLanguage = Language.DE;

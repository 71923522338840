export enum Role {
  MarketplaceInvestor = 'marketplace:investor',
}

export enum InvestorRole {
  InvestorOwner = 'investor:owner',
  InvestorAdmin = 'investor:admin',
  InvestorAdvisor = 'investor:advisor',
  InvestorViewOnly = 'investor:viewonly',
}

export type OptionType = {
  label: string;
  value: string;
};

export type OptionsType = OptionType[];

export enum BooleanOptions {
  Yes = 'true',
  No = 'false',
}

export enum Salutation {
  Mr = 'Mr',
  Ms = 'Ms',
  Mx = 'Mx',
}

export enum Milestone {
  GeneralUpdate = 'General update',
  RecoveryUpdate = 'Recovery update',
  FirstReminder = 'First reminder',
  SecondReminder = 'Second reminder',
  PartialPayment = 'We received a partial payment',
  PaymentPlanSent = 'Payment plan sent',
  PaymentPlanSigned = 'Payment plan signed and returned',
  InstalmentCollectionInit = 'Instalment collection initiated',
  CreditTermination = 'Credit termination with payment deadline until date',
  DebtCollectionInit = 'Debt collection initiated',
  LegalProposalReceived = 'Legal proposal of the borrower received',
  LegalProposalRemoved = 'Legal proposal removed',
  WageGarnishmentReceived = 'Wage garnishment certificate received, garnishment period from date to date',
}

export * from './deal';
export * from './filter';
export * from './currency';
export * from './language';
export * from './events';
export * from './transaction';
export * from './payments';
export * from './secondary-market';

import { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAsync } from 'react-async-hook';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { InvestorAccountInheritorsDto } from 'api/investor-client';
import { userApiClient } from 'api';
import { InvestorRole } from 'types';
import { Select } from 'components/forms';
import { Loader } from 'components';
import { notEmpty } from 'utils/validation';

interface Props {
  open: boolean;
  investorId?: string;
  userRole: InvestorRole;
  onClose: (result: boolean, chosenUser?: string) => void;
}

const useStyles = makeStyles(() => ({
  actionBar: {
    padding: '12px 24px',
    justifyContent: 'flex-end',
  },
  select: {
    maxWidth: '550px',
  },
  selectOption: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const ConfirmRemoveAccount = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [possibleInheritors, setPossibleInheritors] = useState<InvestorAccountInheritorsDto | null>(
    null,
  );

  const isOwner = useMemo(() => props.userRole === InvestorRole.InvestorOwner, [props.userRole]);

  const { loading } = useAsync(async () => {
    if (!props.open || !props.investorId) return;
    if (!isOwner) return setPossibleInheritors(null);

    const { data } = await userApiClient.user.userApiControllerGetPossibleAccountInheritors({
      investorId: props.investorId,
    });

    setPossibleInheritors(data);
  }, [isOwner, props.investorId, props.open]);

  const inheritorsList = useMemo(() => {
    if (!possibleInheritors || !possibleInheritors.lastOwner) return [];

    return possibleInheritors.inheritors.map((i) => ({
      label: (
        <Typography className={classes.selectOption}>
          {i.name}
          <Typography component="span" color="#999" marginLeft={2}>
            {i.email}
          </Typography>
        </Typography>
      ),
      value: i.userId,
    }));
  }, [possibleInheritors, classes.selectOption]);

  const userSelector = useMemo(() => {
    if (!isOwner || !possibleInheritors?.lastOwner) {
      return null;
    }

    if (loading) {
      return <Loader />;
    }

    if (inheritorsList.length === 0) {
      return (
        <DialogContentText>
          <Typography variant="subtitle1" marginTop={2} color="error">
            <FormattedMessage id="error.remove_last_user" />
          </Typography>
        </DialogContentText>
      );
    }

    return (
      <Field
        id="inheritor"
        name="inheritor"
        label={intl.formatMessage({ id: 'choose_owner' })}
        labelDirection="column"
        options={inheritorsList}
        component={Select}
        className={classes.select}
        placeholder={intl.formatMessage({ id: 'choose_inheritor' })}
        validate={notEmpty}
      />
    );
  }, [isOwner, loading, possibleInheritors, inheritorsList, classes.select, intl]);

  const cantDelete =
    isOwner &&
    (loading ||
      (!!possibleInheritors &&
        possibleInheritors.lastOwner &&
        possibleInheritors.inheritors.length === 0));

  return (
    <Dialog open={props.open} onClose={() => props.onClose(false)}>
      <Formik<Record<string, string>>
        initialValues={{}}
        onSubmit={(res) => props.onClose(true, res.inheritor)}
      >
        <Form>
          <DialogTitle>
            <FormattedMessage id="remove_myself_from_account" />
          </DialogTitle>

          <DialogContent>
            {!cantDelete && possibleInheritors?.lastOwner && inheritorsList.length !== 0 && (
              <DialogContentText sx={{ marginBottom: 2 }}>
                <FormattedMessage id="error.last_account" />
              </DialogContentText>
            )}
            {!cantDelete && possibleInheritors?.lastOwner && inheritorsList.length === 0 && (
              <DialogContentText sx={{ marginBottom: 2 }}>
                <FormattedMessage id="error.select_new_owner" />
              </DialogContentText>
            )}
            {userSelector}
          </DialogContent>

          <DialogActions className={classes.actionBar}>
            <Button onClick={() => props.onClose(false)} variant="outlined">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              autoFocus
              disabled={cantDelete}
            >
              <FormattedMessage id="ok" />
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

import { PropsWithChildren, ReactNode } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';

interface Props {
  title?: ReactNode;
  sx?: SxProps<Theme>;
  wrapperClassName?: string;
  boxClassName?: string;
  boxSx?: SxProps<Theme>;
  withWrapperBlock?: boolean;
  /** default: `normal` */
  variant?: 'large' | 'normal' | 'condensed' | 'small';
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: '16px',
  },
}));

export const CardHeader = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const { title } = props;

  return title ? <Typography classes={{ root: classes.title }}>{title}</Typography> : null;
};

import { useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import { Select } from '../forms/Select/Select';
import { notEmpty } from 'utils/validation';
import { InvestorUserAccessDto } from 'api/investor-client';

interface Props {
  open: boolean;
  onClose: (result: boolean, userId?: string) => void;
  inheritorsList: InvestorUserAccessDto[];
}

const useStyles = makeStyles((theme) => ({
  actionBar: {
    padding: '12px 24px',
    justifyContent: 'flex-end',
  },
  select: {
    maxWidth: '550px',
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  selectOption: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const SelectNewOwnerWidget = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const [list, setList] = useState<InvestorUserAccessDto[]>([]);

  const inheritorsList = useMemo(() => {
    return (
      list.map((o) => ({
        value: o.userId,
        label: (
          <Typography className={classes.selectOption}>
            {o.name}
            <Typography component="span" color="#999" marginLeft={2}>
              {o.email}
            </Typography>
          </Typography>
        ),
      })) ?? []
    );
  }, [list, classes]);

  useEffect(() => {
    if (props.open) setList(props.inheritorsList);
  }, [props.inheritorsList, props.open]);

  const isNoInheritors = list.length === 0;

  return (
    <Dialog open={props.open} onClose={() => props.onClose(false)}>
      <Formik<Record<string, string>>
        initialValues={{}}
        onSubmit={(res) => props.onClose(true, res.inheritor)}
      >
        <Form>
          <DialogTitle>
            <FormattedMessage id="select_new_owner_widget.title" />
          </DialogTitle>

          <DialogContent>
            {isNoInheritors ? (
              <Typography variant="subtitle1" color="error">
                <FormattedMessage id="select_new_owner_widget.no_inheritors" />
              </Typography>
            ) : (
              <Field
                id="inheritor"
                name="inheritor"
                label={intl.formatMessage({
                  id: 'select_new_owner_widget.owner_select_label',
                })}
                labelDirection="column"
                options={inheritorsList}
                component={Select}
                className={classes.select}
                placeholder={intl.formatMessage({
                  id: 'select_new_owner_widget.owner_select_placeholder',
                })}
                validate={notEmpty}
              />
            )}
          </DialogContent>

          <DialogActions className={classes.actionBar}>
            <Button onClick={() => props.onClose(false)} variant="outlined">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isNoInheritors}
            >
              <FormattedMessage id="ok" />
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

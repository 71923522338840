import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { Spinner } from 'components';

type Props = {
  children: ReactNode;
};

export const AuthWrapper = withAuthenticationRequired(
  ({ children }: Props) => {
    return <>{children}</>;
  },
  {
    onRedirecting: Spinner,
  },
);

import { useInvestorAccounts } from 'providers/investor-accounts';
import { useKycProvider } from 'providers/kyc';
import { useUserDetails } from 'providers/user-details';
import { getUserRoleByAccount } from 'utils';

export const useSelectedInvestorAccount = () => {
  const { selectedAccount } = useInvestorAccounts();
  const userContext = useUserDetails();
  const kycProvider = useKycProvider();

  return {
    selectedAccount,
    ...getUserRoleByAccount(userContext.userDetails, selectedAccount?.investor?.id),
    isBlockedFromInvesting: !!userContext?.userDetails?.accounts?.find((acc) => acc.investor?.id === selectedAccount?.investor?.id)?.investor?.blockedFromInvestingReason || kycProvider.uncompletedTaskGroups.length > 0,
    isKycFlowUnfinished: kycProvider.uncompletedTaskGroups.length > 0,
    isBlockedFromSelling: !!userContext?.userDetails?.accounts?.find((acc) => acc.investor?.id === selectedAccount?.investor?.id)?.investor?.blockedFromSellingReason
  };
};

import { Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { investorApiClient } from 'api';
import { useAsyncCallback } from 'react-async-hook';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px',
  },
  container: {
    margin: '0 8px 0px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  svg: {
    maxWidth: '258px',
    maxHeight: '244px',
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  link: {
    marginTop: 48,
    color: theme.palette.text.secondary,
  },
}));

type IProps = {
  email?: string;
};

export const Auth0EmailVerificationPage = ({ email }: IProps) => {
  const classes = useStyles();

  const handleSendNewLink = useAsyncCallback(async () => {
    await investorApiClient.user.userApiControllerResendEmailVerificationLink({ email });
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.content}>
          <img src="img/mail-2-light.svg" alt="Email verification icon" className={classes.svg} />
          <Typography fontWeight={700} variant="h6" fontSize={26}>
            Email Verification
          </Typography>
          <Typography fontSize={16} fontWeight={400} className={classes.subtitle}>
            Verify your email by clicking on a link we just sent to {email}
          </Typography>
          <Typography fontSize={14} fontWeight={400} className={classes.link}>
            Didn’t receive a link?{' '}
            <Link component="button" onClick={handleSendNewLink.execute}>
              Send new link
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

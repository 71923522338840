import { Box, Button, Typography, alpha, Tooltip, Link } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import clsx from 'clsx';

import { InvestmentWithDealDto } from 'api/investor-client';
import { CurrencyIcon, ProductTypeChip, RiskChip, InvestmentStatusChip } from 'components/UI';
import { useLink } from 'hooks';
import { DealRating, DealType } from 'types';

import { useTableRowStyles } from '../styles';
import { useCallback, useRef, useState } from 'react';
import { InvestedTotalProgress } from 'components';
import { RiFileCopyLine, RiCheckLine } from 'react-icons/ri';
import theme from 'config/theme';

interface Props {
  record: InvestmentWithDealDto;
  tableLayout?: boolean;
  onSell?: (arg0: InvestmentWithDealDto) => void;
}

const useCardViewStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.07),
    },
  },

  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '24px 16px',
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
  },

  cancelBtn: {
    height: 'fit-content',
    marginTop: '-16px',
    marginRight: '-8px',
    fontSize: '18px',
  },

  id: {
    flexGrow: 1,
    textDecoration: 'underline',
    overflow: 'hidden',
  },

  chipsGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  cardLink: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    padding: '24px 16px 16px',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexBasis: '40%',
    overflow: 'hidden',
  },

  fullTextGroup: {
    flexBasis: '100%',
  },

  textGroupHeader: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textTransform: 'uppercase',
  },

  tooltip: {
    color: alpha(theme.palette.text.secondary, 0.36),
    fontSize: '16px',
    lineHeight: '16px',
  },

  cardFooter: {
    padding: '16px',
  },

  submitBtn: {
    width: '100%',
    padding: '8px 16px',
    borderWidth: '1px',
    '&:hover': {
      borderWidth: '1px',
    }
  },
}));
const sellDisabledReasons: Record<string, string> = {
  "blocked-from-selling": "blocked-from-selling",
  "deal-not-for-sale": "deal-not-for-sale",
  "fallback": "fallback",
  "investor-view-only": "investor-view-only",
  // "no-repayments-repaid": "no-repayments-repaid",
  "open-share-sales": "open-share-sales",
  "share-not-active-status": "share-not-active-status",
  "signed-contract-needed": "signed-contract-needed",
  "share-outstanding-too-low": "share-outstanding-too-low",
  "secondary-market-actions-disabled": "secondary-market-actions-disabled",
};

function getSellDisabledReasonText(share: InvestmentWithDealDto, intl: IntlShape) {
  const sellDisabledReason = share.sellDisabledReason && sellDisabledReasons[share.sellDisabledReason];
  let values = {};
  if (sellDisabledReason === 'signed-contract-needed') {
    values = {
      aAcc: (msg: string) => (

        <Link
          sx={(theme) => ({
          color: theme.palette.common.lightBlue,
          textDecorationColor: alpha(theme.palette.common.lightBlue, 0.4),
        })}>
          <LinkRouter
            to={`/accounts/${share.investorId}/settings/account`}
          >
            {msg}
          </LinkRouter>
        </Link>
      ),
    };
  }
  const sellDisabledReasonText = share.sellDisabledReason && (sellDisabledReason ?
   <FormattedMessage id={`sell_disabled_reason.${sellDisabledReason}`} values={values}/> :
  <FormattedMessage id={'sell_disabled_reason.fallback' }/>);
  return sellDisabledReasonText;
}

const PrimaryInvestmentRecordCardView = (props: Props) => {
  const { record: share, onSell, tableLayout } = props;

  const classes = useCardViewStyles();
  const intl = useIntl();
  const getLink = useLink();

  const handleSell = useCallback(() => {
    if (onSell) onSell(share);
  }, [onSell, share]);
  const sellDisabledReasonText = getSellDisabledReasonText(share, intl);

  return (
    <Box className={classes.card}>
      <Box className={classes.cardLink} component={LinkRouter} to={getLink(`investments/${share.id}`, 'continue-url')}>
        <Box className={classes.cardHeader}>
          <Box className={classes.id}>
            <Typography variant="subtitle2" noWrap>
              {share.id}
            </Typography>
          </Box>

          <Box className={classes.chipsGroup}>
            <CurrencyIcon currency={share.deal.currency} size={24} />
            <RiskChip rating={share.deal.risk.rating as DealRating} />
            <ProductTypeChip productType={share.deal.risk.productType as DealType} />
            <InvestmentStatusChip type={share.type} status={share.status} dealStatus={share.deal.status} />
          </Box>

        </Box>

        <Box className={classes.cardBody}>
          <Box className={classes.row}>
            <Box className={classes.textGroup}>
              <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                <FormattedMessage id="duration" />
              </Typography>

              <Typography variant="subtitle2" noWrap>
                {share.deal.duration}{' '}
                {intl.formatMessage({ id: share.deal.duration === 1 ? 'month' : 'months' })}
              </Typography>
            </Box>

            <Box className={classes.textGroup}>
              <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                <FormattedMessage id="investment_date" />
              </Typography>

              <Typography variant="subtitle2" noWrap>
                <FormattedDate value={share.date} />
              </Typography>

            </Box>
          </Box>

          <Box className={classes.row}>
            <Box className={clsx(classes.textGroup, classes.fullTextGroup)}>
              <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                <FormattedMessage id="repaid_invested_amount" />
              </Typography>

              <InvestedTotalProgress
                currency={share.deal.currency}
                investedAmount={share.repayments.reduce((repaidSum, next) => repaidSum += next.isPaid ? next.transaction.principal : 0, 0)}
                totalAmount={share.amount}
                tableLayout={tableLayout}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Tooltip title={sellDisabledReasonText || ''}>
        <Box className={classes.cardFooter}>
          <Button disabled={!!share.sellDisabledReason} id="submitBtn" variant="outlined" className={classes.submitBtn} onClick={handleSell}>
            <Typography variant="body2" noWrap>
              <FormattedMessage id="sell" />
            </Typography>
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

const useRowViewStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    overflow: 'hidden',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },
  },

  tooltip: {
    maxWidth: 'fit-content',
  },

  linkContainer: {
    display: 'flex',
    flexGrow: 1,
    cursor: 'pointer',
    width: '86%'
  },

  rowWrapper: {
    width: '100%',
    display: 'flex',
  },

  cellDivider: {
    borderLeft: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
  },

  submitBtn: {
    minWidth: 'auto',
    padding: '8px 16px',
    borderWidth: '1px',
    '&:hover': {
      borderWidth: '1px',
    }
  },
}));

const PrimaryInvestmentRecordRowView = (props: Props) => {
  const { record: share, onSell, tableLayout } = props;

  const tableRowClasses = useTableRowStyles();
  const classes = useRowViewStyles();
  const intl = useIntl();
  const getLink = useLink();
  let timeout = useRef<NodeJS.Timeout | undefined>();
  const [copied, setCopied] = useState<boolean>(false);

  const handleSell = useCallback(() => {
    if (onSell) onSell(share);
  }, [onSell, share]);

  const copy = useCallback((e) => {
    e.preventDefault();
    navigator.clipboard.writeText(share.id);
    if (timeout.current) clearTimeout(timeout.current);
    setCopied(true);
    timeout.current = setTimeout(() => setCopied(false), 1000);
  }, [share]);
  const sellDisabledReasonText = getSellDisabledReasonText(share, intl);

  return (
    <Box className={classes.card}>
      {/* <Tooltip
        title={`${intl.formatMessage({ id: 'investment_id' })}: ${share.id}`}
        componentsProps={{
          tooltip: { className: classes.tooltip },
          popper: { modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] },
        }}
        followCursor
      > */}
      <LinkRouter
        to={getLink(`investments/${share.id}`, 'continue-url')}
        className={classes.linkContainer}
      >
        <Box className={classes.rowWrapper}>
          <Box
            className={clsx(tableRowClasses.cell, tableRowClasses.idCell)}
            sx={{ textDecoration: 'underline' }}
          >
            <Typography variant="subtitle3" noWrap >
              {share.id}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={copy}
            >
              {copied
                ? <RiCheckLine size={18} color={theme.palette.success.main} />
                : <RiFileCopyLine size={18} opacity={0.5} />
              }
            </Box>
          </Box>

          <Box className={clsx(tableRowClasses.cell, tableRowClasses.riskCell, classes.cellDivider)}>
            <RiskChip rating={share.deal.risk.rating as DealRating} />
          </Box>

          <Box className={clsx(tableRowClasses.cell, tableRowClasses.productTypeCell)}>
            <ProductTypeChip productType={share.deal.risk.productType as DealType} />
          </Box>

          <Box className={clsx(tableRowClasses.cell, tableRowClasses.statusCell)}>
            <InvestmentStatusChip type={share.type} status={share.status} dealStatus={share.deal.status} />
          </Box>

          <Box className={clsx(tableRowClasses.cell, tableRowClasses.durationCell)}>
            <Typography variant="body2" noWrap>
              {share.deal.duration}{' '}
              {intl.formatMessage({ id: share.deal.duration === 1 ? 'month' : 'months' })}
            </Typography>
          </Box>

          <Box className={clsx(tableRowClasses.cell, tableRowClasses.dateCell)}>
            <Typography variant="body2" noWrap>
              <FormattedDate value={share.date} />
            </Typography>
          </Box>

          <Box className={clsx(tableRowClasses.cell, tableRowClasses.priceCell)}>
            <InvestedTotalProgress
              currency={share.deal.currency}
              investedAmount={share.amount - share.outstandingAmount}
              totalAmount={share.amount}
              tableLayout={tableLayout}
            />
          </Box>
        </Box>
      </LinkRouter>
      {/* </Tooltip> */}

      <Tooltip title={sellDisabledReasonText || ''}>
        <Box className={clsx(tableRowClasses.cell, tableRowClasses.inputCell)}>
          <Button disabled={!!share.sellDisabledReason} id="submitBtn" variant="outlined" className={classes.submitBtn} onClick={handleSell}>
            <Typography variant="body2" noWrap>
              <FormattedMessage id="sell" />
            </Typography>
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

export const PrimaryInvestmentRecord = (props: Props) => {

  const ViewComponent = props.tableLayout ? PrimaryInvestmentRecordRowView : PrimaryInvestmentRecordCardView;
  return <ViewComponent {...props} />
};

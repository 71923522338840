import dayjs, { Dayjs } from 'dayjs';
import { IntlShape } from 'react-intl';
import { ChartData } from 'chart.js';

import { RepaymentStatisticsResultDto } from 'api/investor-client';
import { moveDecimalPoint } from 'utils';

const getQuartersFromMonth = (date: Dayjs | Date | string) => {
  return Math.ceil((dayjs.utc(date).get('month') + 1) / 3);
};

const getLabelsFromDataset = (
  dataset: RepaymentStatisticsResultDto[],
  intl: IntlShape,
) => {
  // Create quarters out of dates
  if (dataset.length > 7) {
    const quarters = new Set<string>();

    dataset.forEach((ds) => {
      const date = dayjs.utc(ds.date);
      const quarter = getQuartersFromMonth(date);
      quarters.add(`Q${quarter};${date.format('YYYY')}`);
    });

    return [...quarters.values()].map((v) => v.split(';'));
  }

  // else return month + year
  return dataset.map((ds) => {
    return [
      intl.formatMessage({
        id: `month.${dayjs.utc(ds.date).format('MMMM').toLowerCase()}`,
      }),
      dayjs.utc(ds.date).format('YYYY'),
    ];
  });
};

const groupDataByMonth = (
  dataset: RepaymentStatisticsResultDto[],
  principals: number[],
  interests: number[],
) => {
  dataset.forEach((ds) => {
    let principal = 0;
    let interest = 0;

    ds.repayments.forEach((dataSetRow) => {
      principal += dataSetRow.repayment.principal;
      interest += dataSetRow.repayment.interest;
    });

    principals.push(moveDecimalPoint(principal, -2));
    interests.push(moveDecimalPoint(interest, -2));
  });
};

const groupDataByQuarters = (
  dataset: RepaymentStatisticsResultDto[],
  principals: number[],
  interests: number[],
) => {
  if (!dataset.length) return;

  let currentQuarter = getQuartersFromMonth(dataset[0].date);
  let principal = 0;
  let interest = 0;

  dataset.forEach((ds) => {
    const targetQuarter = getQuartersFromMonth(ds.date);

    if (targetQuarter !== currentQuarter) {
      currentQuarter = targetQuarter;
      principals.push(moveDecimalPoint(principal, -2));
      interests.push(moveDecimalPoint(interest, -2));
      principal = 0;
      interest = 0;
    }

    ds.repayments.forEach((dataSetRow) => {
      principal += dataSetRow.repayment.principal;
      interest += dataSetRow.repayment.interest;
    });
  });

  principals.push(moveDecimalPoint(principal, -2));
  interests.push(moveDecimalPoint(interest, -2));
};

const getChartDataset = (dataset: RepaymentStatisticsResultDto[]) => {
  const principals: number[] = [];
  const interests: number[] = [];

  if (dataset.length > 7) {
    groupDataByQuarters(dataset, principals, interests);
  } else {
    groupDataByMonth(dataset, principals, interests);
  }

  return [
    {
      label: 'principal',
      stack: 's0',
      order: 0,
      data: principals,
      backgroundColor: '#254E8F',
    },
    {
      label: 'interest',
      stack: 's0',
      order: 1,
      data: interests,
      backgroundColor: '#97D7FF',
    },
  ];
};

export const getChartDataFromRowStatistics = (
  rowStat: RepaymentStatisticsResultDto[],
  intl: IntlShape,
): ChartData<'bar'> => {
  return {
    labels: getLabelsFromDataset(rowStat, intl),
    datasets: getChartDataset(rowStat),
  };
};

import {
  RepaymentDto,
  ShareRepaymentTransactionDto,
  ShareTransactionDto,
} from 'api/investor-client';

export interface Repayment extends ShareTransactionDto {
  installment: number | string;
  outstandingInvestment: number;
  repayment: RepaymentDto;
}

export const getExpectedRepaymentTableData = (
  dealRepayments: RepaymentDto[],
  transactions: ShareTransactionDto[],
) => {
  return transactions
    .filter((tr) => tr.type === 'Repayment')
    .map((tr) => {
      const r = { ...tr } as Repayment;

      r.repayment = dealRepayments.find(
        (r) => r.id === tr.relatedId,
      ) as RepaymentDto;

      r.repayment = { ...r.repayment };
      return r;
    })
    .sort(
      (a, b) =>
        new Date(a.repayment.date).getTime() -
        new Date(b.repayment.date).getTime(),
    )
    .map((r, i) => {
      r.installment = i + 1;
      r.outstandingInvestment = r.repayment.repaymentDate ? 0 : r.principal;
      return r;
    });
};

type TableRepaymentObject = ShareRepaymentTransactionDto & {
  installment: number;
  dealRepayment?: RepaymentDto;
};

export const getRepaymentTableData = (
  repayments: ShareRepaymentTransactionDto[],
  dealRepayments: RepaymentDto[],
): Array<TableRepaymentObject> => {
  let result = repayments
    .sort((a, b) => {
      if (!a.transaction.date || !b.transaction.date)
        return -new Date().getTime();

      let aTime = new Date(a.transaction.date).getTime();
      let bTime = new Date(b.transaction.date).getTime();

      if (aTime === bTime) {
        return getStatusValue(a) - getStatusValue(b);
      }
      return (aTime - bTime);
    })
    .map((repayment, i) => {
      const res = { ...repayment } as TableRepaymentObject;
      res.dealRepayment = dealRepayments.find((r) => {
        return (
          repayment.transaction.date &&
            repayment.isPaid === !!r.repaymentDate &&
          r.id.includes(repayment.transaction.date)
        );
      });
      return res;
    });
  let currentInstallment = 0;
  for (let i = 0; i < result.length; i++) {
    if (i === 0 || result[i - 1].transaction.date !== result[i].transaction.date) {
      currentInstallment++;
    }
    result[i].installment = currentInstallment;
  }
  return result;
};

const getStatusValue = (rep: { isPaid: boolean, isBooked: boolean, isLost: boolean }) => {
  if (rep.isBooked) return 1;
  if (rep.isPaid) return 2;
  if (rep.isLost) return 3;
  return 4;
};

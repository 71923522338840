import { Box, CircularProgress } from '@mui/material';

interface Props {
  size?: number;
}

export const PageLoader = ({ size = 64 }: Props) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { Api as InvestorApi, ProblemDocument } from './investor-client';
import { Api as PaymentsApi } from './payments-client';
import { Api as DocumentApi } from './document-client';

const investorApiClient = new InvestorApi({
  baseURL: `${process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3001'}`,
  securityWorker: (securityData: { token: string } | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: `Bearer ${securityData.token}`,
        },
      };
    }
  },
});

const userApiClient = new InvestorApi({
  baseURL: `${process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3001'}`,
  securityWorker: (securityData: { token: string } | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: `Bearer ${securityData.token}`,
        },
      };
    }
  },
});

const paymentsApiClient = new PaymentsApi({
  baseURL: `${process.env.REACT_APP_PAYMENTS_API_BASE_URL ?? 'http://localhost:3002'}`,
  securityWorker: (securityData: { token: string } | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: `Bearer ${securityData.token}`,
        },
      };
    }
  },
});

const documentApiClient = new DocumentApi({
  baseURL: `${process.env.REACT_APP_DOCUMENT_API_BASE_URL ?? 'http://localhost:3008'}`,
  securityWorker: (securityData: { token: string } | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: `Bearer ${securityData.token}`,
        },
      };
    }
  },
});

type DefaultResponse = { detail: string | string[] };

const handleError = (error: { response: AxiosResponse<ProblemDocument | DefaultResponse> }) => {
  const detail = error.response?.data?.detail;
  let err = '';

  if (Array.isArray(detail)) {
    detail.forEach((e) => {
      toast.error(e);
      err += `${e} `;
    });
  } else {
    toast.error(detail);
    err = detail ?? '';
  }

  throw err;
};

investorApiClient.instance.interceptors.response.use(undefined, handleError);
userApiClient.instance.interceptors.response.use(undefined, handleError);
paymentsApiClient.instance.interceptors.response.use(undefined, handleError);
documentApiClient.instance.interceptors.response.use(undefined, handleError);

export { investorApiClient, userApiClient, paymentsApiClient, documentApiClient };

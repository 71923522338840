import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router';
import { useAsync } from 'react-async-hook';

import { investorApiClient } from 'api';
import { InvestmentWithDealDto } from 'api/investor-client';
import { useLayoutPageTitle } from 'providers/layout-provider';
import { PageContainer } from 'components/UI';
import { DetailsTabs, PageLoader } from 'components';

import { InvestmentHeader } from './InvestmentHeader';
import { DealInformation, InvestmentInformation } from './tabs';

interface Props {}

enum InvestmentDetailTab {
  InvestmentInformation = 'investment',
  DealInformation = 'deal_information',
}

export const InvestmentDetailPage = (props: Props) => {
  const intl = useIntl();
  const { accountId, id } = useParams<{ id: string; accountId: string }>();

  const [investment, setInvestment] = useState<InvestmentWithDealDto | null>(
    null,
  );

  useLayoutPageTitle(
    intl.formatMessage(
      { id: 'investment.details.header' },
      { id, dealId: investment?.deal.id ?? '' },
    ),
  );

  const { loading, error } = useAsync(async () => {
    if (id) {
      // prettier-ignore
      const { data } = await investorApiClient.investors.investorApiControllerGetInvestment({
        investmentId: id.toString(),
        loadBorrower: true,
        skipRisk: false
      });

      setInvestment(data);
    }
  }, [id]);

  const [tab, setTab] = useState<InvestmentDetailTab>(
    InvestmentDetailTab.InvestmentInformation,
  );

  const tabs = useMemo(
    () => [
      {
        id: InvestmentDetailTab.InvestmentInformation,
        title: intl.formatMessage({
          id: InvestmentDetailTab.InvestmentInformation,
        }),
        content: InvestmentInformation,
      },
      {
        id: InvestmentDetailTab.DealInformation,
        title: intl.formatMessage({ id: InvestmentDetailTab.DealInformation }),
        content: DealInformation,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage],
  );

  if (loading) {
    return <PageLoader />;
  }

  if (error || !investment) {
    return <Redirect to={`/accounts/${accountId}/not-found`} />;
  }

  return (
    <PageContainer>
      <InvestmentHeader investment={investment} />

      <DetailsTabs
        activeTab={tab}
        onTabChange={setTab as (id: string) => void}
        tabs={tabs}
        tabProps={{
          investment,
        }}
        wrapperSx={{ marginTop: 6 }}
      />
    </PageContainer>
  );
};

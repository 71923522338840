import { useMemo, useState } from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { InvestorDto, InvestorUserAccessDto, UserInviteDto } from 'api/investor-client';
import { investorApiClient, paymentsApiClient } from 'api';
import { VirtualAccountDTO } from 'api/payments-client';
import { useUserDetails } from 'providers/user-details';
import { useInvestorAccounts } from 'providers/investor-accounts';
import Card from 'components/card';
import { SelectNewOwnerWidget } from 'components';
import { MembersTable } from './MembersTable';
import { InviteUserWidget } from './InviteUserWidget';
import { BankingForm } from './BankingForm';
import { getUserRoleByAccount } from 'utils';
import { InvestorRole } from 'types';
import { DDOrLSVActivation } from './DDOrLSVActivation';
import { SecondaryMarketContractDownload } from 'pages/SettingsPage/AccountTab/SecondaryMarketContractDownload';

export const AccountTab = () => {
  const isMultiAccountsEnabled = process.env.REACT_APP_DISABLE_MULTI_ACCOUNT !== 'true';
  const userContext = useUserDetails();
  const investorAccounts = useInvestorAccounts();

  // prettier-ignore
  const [virtualAccount, setVirtualAccount] = useState<VirtualAccountDTO | null>(null);
  const [account, setAccount] = useState<InvestorDto | null>(null);

  const { loading, execute: refetchAccount } = useAsync(async () => {
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetCurrentAccount();
    setAccount(data);
  }, []);

  const { loading: virtualAccountLoading, execute: refetchVirtualAccount } =
    useAsync(async () => {
      // prettier-ignore
      const { data } = await paymentsApiClient.api.investorApiControllerGetCurrentVirtualAccount();
      setVirtualAccount(data);
      return data;
    }, []);

  const { isOwner, isAdmin, isViewOnly, isAdvisor } = useMemo(
    () => getUserRoleByAccount(userContext.userDetails, account?.id),
    [userContext.userDetails, account?.id],
  );

  const inviteUser = useAsyncCallback(async (invite: UserInviteDto) => {
    await investorApiClient.investors.investorApiControllerInviteUser(invite);
    await refetchAccount();
  });

  const removeUser = useAsyncCallback(
    async (userId: string, newOwnerId?: string) => {
      await investorApiClient.investors.investorApiControllerUnassignUserRole(
        userId,
        { newOwnerId },
      );

      await Promise.all([refetchAccount(), investorAccounts.refresh()]);
    },
  );

  const [selectNewOwner, setSelectNewOwner] = useState<{
    userAccess: InvestorUserAccessDto;
    inheritors: InvestorUserAccessDto[];
  } | null>(null);

  const checkForInheritors = useAsyncCallback(
    async (e: InvestorUserAccessDto) => {
      const { data } =
        await investorApiClient.investors.investorApiControllerGetPossibleAccountInheritors(
          e.userId,
        );

      if (data.lastOwner) {
        setSelectNewOwner({
          userAccess: e,
          inheritors: data.inheritors,
        });
      } else {
        return changeRole.execute(e);
      }
    },
  );

  const changeRole = useAsyncCallback(
    async (e: InvestorUserAccessDto, newOwnerId?: string) => {
      await investorApiClient.investors.investorApiControllerChangeRole(
        e.userId,
        { role: e.role, newOwnerId },
      );
    },
  );

  const isLoading = loading || virtualAccountLoading || userContext.loading;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <BankingForm
            account={account}
            virtualAccount={virtualAccount}
            refetchVirtualAccount={refetchVirtualAccount}
            isViewOnly={isViewOnly || isAdvisor}
            loading={isLoading}
          />
        </Grid>

        <Grid item md={6}>
          <DDOrLSVActivation
            virtualAccount={virtualAccount}
            refreshVirtualAccount={refetchVirtualAccount}
            loading={isLoading}
          />
        <br/>
          <SecondaryMarketContractDownload investorId={investorAccounts.selectedAccount?.investor?.id} unlocked={investorAccounts.selectedAccount?.investor && !investorAccounts.selectedAccount.investor.blockedFromSellingReason}/>
        </Grid>

        {isMultiAccountsEnabled && (
          <Grid item md={12}>
            <Formik
              enableReinitialize
              initialValues={{ userAccess: account?.userAccess ?? [] }}
              onSubmit={async (data: { userAccess: InvestorUserAccessDto[] }) => {
                await Promise.all(
                  data.userAccess.map((e) => {
                    const match = account?.userAccess.find(
                      (e2) => e.userId === e2.userId,
                    );
                    if (match?.role && e.role !== match?.role) {
                      return match.role === InvestorRole.InvestorOwner
                        ? checkForInheritors.execute(e)
                        : changeRole.execute(e);
                    }
                    return undefined;
                  }),
                );
                userContext.refresh();
                refetchAccount();
              }}
            >
              {({ values }) => {
                const isDisabled =
                  account?.userAccess?.reduce((acc, e) => {
                    const match = values.userAccess.find(
                      (e2) => e.userId === e2.userId,
                    );
                    return !acc ? acc : e.role === match?.role;
                  }, true) ?? true;

                return (
                  <Form>
                    <Card>
                      <Card.Header
                        title={
                          <>
                            <FormattedMessage id="members">
                              {(txt) => <div>{txt}</div>}
                            </FormattedMessage>
                            <FormattedMessage id="manage_members">
                              {(txt) => (
                                <div style={{ fontSize: '1rem' }}>{txt}</div>
                              )}
                            </FormattedMessage>
                          </>
                        }
                      >
                        {(isAdmin || isOwner) && (
                          <InviteUserWidget
                            isAdmin={isAdmin}
                            onSubmit={inviteUser.execute}
                          />
                        )}
                      </Card.Header>
                      <Card.Body loadingContent={isLoading}>
                        <MembersTable
                          isViewOnly={isViewOnly}
                          isAdvisor={isAdvisor}
                          investor={account}
                          data={values.userAccess}
                          onRemove={removeUser.execute}
                        />
                      </Card.Body>
                      <Card.Footer>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{ width: 'max-content' }}
                          disabled={isDisabled}
                        >
                          {loading && (
                            <CircularProgress size={20} sx={{ marginRight: 3 }} />
                          )}
                          <FormattedMessage id="save_changes" />
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        )}
      </Grid>

      <SelectNewOwnerWidget
        open={!!selectNewOwner}
        inheritorsList={selectNewOwner?.inheritors ?? []}
        onClose={(res, newOwnerId) => {
          const e = selectNewOwner!.userAccess;
          setSelectNewOwner(null);

          if (res) {
            changeRole.execute(e, newOwnerId);
          }
        }}
      />
    </>
  );
};

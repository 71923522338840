import { Dispatch, SetStateAction } from 'react';
import { Box, TextField, Typography, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import clsx from 'clsx';

import { Currency } from 'types';
import { formatMoney, restrictProp } from 'utils';

const deduceFontSize = (
  value: string,
  minSize: number,
  maxSize: number,
  breakpoint: number,
  factor = 1.75,
) => {
  const vLength = value.length;
  if (vLength < breakpoint) return `${maxSize}px`;
  return `${Math.max(maxSize - (vLength - (breakpoint - 1)) * factor, minSize)}px`;
};

const SNumberFormat = styled(NumericFormat, {
  shouldForwardProp: restrictProp(['rawValue']),
})<{ rawValue: string; widthMultiplier: number }>(({ rawValue, widthMultiplier = 23, theme }) => {
  return {
    '&&': {
      width: `${widthMultiplier * rawValue.length}px`,
      maxWidth: '280px',
      padding: 0,
      lineHeight: 1.4,
      fontWeight: 500,
      fontSize: deduceFontSize(rawValue, 20, 40, 12, 2.5),

      [theme.breakpoints.up('sm')]: {
        fontSize: deduceFontSize(rawValue, 20, 40, 12, 1.75),
      },
    },
  };
});

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '14px',
  },

  inputLabel: {
    fontSize: '40px',
    lineHeight: 1.4,
    fontWeight: 500,
    color: theme.palette.common.warmGrey,
  },

  input: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

interface Props {
  currency: Currency;
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  disabled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  widthMultiplier?: number;
  max?: number
}

export const AmountInput = ({ widthMultiplier, max, currency, amount, setAmount, disabled, containerClassName, labelClassName, inputClassName }: Props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.inputContainer, containerClassName)}>
      <Typography component="label" htmlFor="founds-amount" className={clsx(classes.inputLabel, labelClassName)}>
        {currency}
      </Typography>

      <TextField
        InputProps={{
          // @ts-ignore: incorrect interfaces
          inputComponent: SNumberFormat,
          inputProps: {
            id: 'founds-amount',
            thousandSeparator: '’',
            allowNegative: false,
            decimalScale: 2,
            fixedDecimalScale: true,
            value: amount,
            rawValue: formatMoney(amount),
            widthMultiplier,
            onValueChange: (e: NumberFormatValues, s: any) => {
              let newAmount = e.floatValue ?? 0;
              if (max && newAmount > max) newAmount = max;
              setAmount(newAmount);
            },
            className: inputClassName
          },
          className: inputClassName
        }}
        className={clsx(classes.input, inputClassName)}
        disabled={disabled}
      />
    </Box>
  );
};

import { useCallback, useEffect, useRef } from 'react';
import { ParsedQuery } from 'query-string';

interface LoadMoreControllerOptions {
  query: ParsedQuery<string>;
  fetch: (query: ParsedQuery<string>) => void;
  loading: boolean;
  continuationToken: string | null;
  wrapperRef?: HTMLElement | null;
  preloadHeight?: number;
}

export const useLoadMoreController = (options: LoadMoreControllerOptions) => {
  const {
    wrapperRef,
    loading,
    continuationToken,
    query,
    fetch,
    preloadHeight = 200,
  } = options;

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const loadMore = useCallback(() => {
    if (!loadingRef.current && continuationToken !== 'EOF') {
      fetch(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, query, continuationToken]);

  const checkToLoadNext = useCallback(() => {
    const el = wrapperRef;
    if (!el) return;

    const elTotalHeight = el.scrollHeight;
    const elScrolledDistance = el.clientHeight + el.scrollTop;

    if (elScrolledDistance + preloadHeight > elTotalHeight) {
      loadMore();
    }
  }, [loadMore, wrapperRef, preloadHeight]);

  useEffect(() => {
    if (!wrapperRef) return;

    wrapperRef.addEventListener('scroll', checkToLoadNext);
    document.addEventListener('resize', checkToLoadNext);

    return () => {
      wrapperRef.removeEventListener('scroll', checkToLoadNext);
      document.removeEventListener('resize', checkToLoadNext);
    };
  }, [checkToLoadNext, wrapperRef]);
};

import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexGrow: 1,
  },

  body: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
    marginBottom: '96px',
  },

  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.barkSurface004,
    borderRadius: '50%',
  },
}));

interface Props {}

export const EmptyBasketMessage = (props: Props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.body}>
        <Box className={classes.container}>
          <Box className={classes.icon}>
            <img src="/img/logo-icon.svg" alt="Logo icon" width={50} />
          </Box>

          <Typography variant="h6" fontWeight={400}>
            <FormattedMessage id="investment_bag.empty" />
          </Typography>

          <Link to="../deals">
            <Button variant="contained" color="primary">
              <FormattedMessage id="back_to_new_deals" />
            </Button>
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

import { SortOrder } from 'types';
import { ESecondaryInvestmentSortOption } from './types';

export const secondaryInvestmentSortVariantsMap = {
  [ESecondaryInvestmentSortOption.Duration]: {
    [SortOrder.ASC]: { sortBy: 'deal.duration', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.duration', sortOrder: SortOrder.DESC },
  },

  [ESecondaryInvestmentSortOption.Currency]: {
    [SortOrder.ASC]: { sortBy: 'deal.currency', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.currency', sortOrder: SortOrder.DESC },
  },

  [ESecondaryInvestmentSortOption.InvestmentAmount]: {
    [SortOrder.ASC]: { sortBy: 'amount', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'amount', sortOrder: SortOrder.DESC },
  },

  [ESecondaryInvestmentSortOption.HavingSaleStatus]: {
    [SortOrder.ASC]: { sortBy: 'status', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'status', sortOrder: SortOrder.DESC },
  },

  [ESecondaryInvestmentSortOption.InvestmentDate]: {
    [SortOrder.ASC]: { sortBy: 'date', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'date', sortOrder: SortOrder.DESC },
  },

  [ESecondaryInvestmentSortOption.Rating]: {
    [SortOrder.ASC]: { sortBy: 'deal.risk.rating', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.risk.rating', sortOrder: SortOrder.DESC },
  },

  [ESecondaryInvestmentSortOption.ProductType]: {
    [SortOrder.ASC]: { sortBy: 'deal.risk.productType', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.risk.productType', sortOrder: SortOrder.DESC },
  },
};

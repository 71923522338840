/**
 * Height sizes for mobile devices.
 * Problem: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
const appHeight = () => {
  const doc = document.documentElement;
  const mobileHeaderSize = doc.getBoundingClientRect().height - window.innerHeight;

  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  doc.style.setProperty('--app-vh', `${window.innerHeight * 0.01}px`);
  doc.style.setProperty('--app-header', `${mobileHeaderSize}px`);
};

window.addEventListener('resize', appHeight);
appHeight();

export {};

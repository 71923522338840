import { AppBar, Container, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useLayoutProvider } from 'providers/layout-provider';
import { BalanceInfo } from './BalanceInfo';
import { BasketWidget } from './BasketWidget';
import { NotificationsWidget } from './NotificationsWidget';

export const HEADER_HEIGHT = '89px';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    justifyContent: 'center',
    padding: '24px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    zIndex: 10,

    [theme.breakpoints.up('sm')]: {
      padding: '24px 8px',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },

    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },
  },

  toolbar: {
    width: '100%',
    maxWidth: theme.breakpoints.values.xxxl,
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    paddingTop: 0,
    paddingBottom: 0,

    [theme.breakpoints.up('sm')]: {
      gap: '48px',
    },

    [theme.breakpoints.up('md')]: {
      minHeight: '40px',
    },
  },
}));

interface Props {}

export const Header = (props: Props) => {
  const classes = useStyles();
  const { title } = useLayoutProvider();

  const isDesktopSize = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  return (
    <AppBar position="sticky" className={classes.header}>
      <Container maxWidth="xxxl" className={classes.toolbar}>
        <Typography variant="h6" fontWeight={{ xs: 700, sm: 500 }} noWrap flexGrow={1}>
          {title}
        </Typography>

        {isDesktopSize && <BalanceInfo />}
        <BasketWidget />
        <NotificationsWidget />
      </Container>
    </AppBar>
  );
};

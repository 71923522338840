import { useMemo, useState } from 'react';
import { CellProps } from 'react-table';
import { Button, Box, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdOutlineBookmark } from 'react-icons/md';
import { useAsyncCallback } from 'react-async-hook';
import { FormattedMessage, useIntl } from 'react-intl';

import { InvestorUserAccessDto } from 'api/investor-client';
import { userApiClient } from 'api';
import Card from 'components/card';
import { Table, ConfirmRemoveAccount } from 'components';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { getUserRoleByAccount } from 'utils';
import { useUserDetails } from 'providers/user-details';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  actionBar: {
    textAlign: 'right',
    '& > *:not(:last-child)': {
      marginRight: '8px',
    },
  },
  primaryIcon: {
    display: 'flex',
    verticalAlign: 'middle',
    fontSize: '20px',
    marginLeft: '-16px',
    left: '16px',
    position: 'relative',
    color: '#ffc82a',
  },
  cardHeader: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}));

export const AccountsList = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const investorAccounts = useInvestorAccounts();

  const setPrimaryAccount = useAsyncCallback(
    async (account: InvestorUserAccessDto) => {
      if (account?.investor?.id) {
        await userApiClient.user.userApiControllerSetPrimaryAccount({
          investorId: account.investor.id,
        });

        await investorAccounts.refresh();
      }
    },
  );

  const removeAccount = useAsyncCallback(
    async (account: InvestorUserAccessDto, newOwner?: string) => {
      if (account?.investor?.id) {
        await userApiClient.user.userApiControllerRemoveAccount({
          investorId: account.investor.id,
          newOwner: newOwner,
        });

        await investorAccounts.refresh();
      }
    },
  );

  const columns = useMemo(() => {
    return [
      {
        id: 'primary',
        Cell: function Cell({
          row: { original },
        }: CellProps<InvestorUserAccessDto>) {
          return original.isPrimaryAccount ? (
            <Tooltip
              arrow
              title={intl.formatMessage({ id: 'primary_account' })}
            >
              <Box className={classes.primaryIcon}>
                <MdOutlineBookmark />
              </Box>
            </Tooltip>
          ) : null;
        },
      },
      {
        Header: intl.formatMessage({ id: 'name' }),
        accessor: 'investor.name',
      },
      {
        Header: intl.formatMessage({ id: 'email' }),
        accessor: 'investor.email',
      },
      {
        Header: intl.formatMessage({ id: 'role' }),
        accessor: 'role',
        Cell: function Cell({ value }: CellProps<InvestorUserAccessDto>) {
          return <FormattedMessage id={`role.${value}`} />;
        },
      },
      {
        id: 'actions',
        Cell: function Cell({
          rows,
          row: { original },
        }: CellProps<InvestorUserAccessDto>) {
          const userContext = useUserDetails();

          const { isAdmin } = useMemo(() => {
            return getUserRoleByAccount(
              userContext.userDetails,
              original.investor?.id,
            );
          }, [userContext.userDetails, original.investor?.id]);

          const [open, setOpen] = useState(false);

          return (
            <>
              <Box className={classes.actionBar}>
                {!original.isPrimaryAccount && (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setPrimaryAccount.execute(original)}
                    disabled={setPrimaryAccount.loading}
                  >
                    <FormattedMessage id="set_as_primary" />
                  </Button>
                )}

                {isAdmin && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => setOpen(true)}
                    disabled={removeAccount.loading}
                  >
                    <FormattedMessage id="remove_myself" />
                  </Button>
                )}
              </Box>

              <ConfirmRemoveAccount
                open={open}
                investorId={original?.investor?.id}
                userRole={original.role as any}
                onClose={(result, chosenUser) => {
                  setOpen(false);
                  if (result) removeAccount.execute(original, chosenUser);
                }}
              />
            </>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPrimaryAccount]);

  return (
    <Card>
      <Card.Header
        title={intl.formatMessage({ id: 'linked_accounts' })}
        wrapperClassName={classes.cardHeader}
      >
        <FormattedMessage id="manage_linked_accounts" />
      </Card.Header>
      <Card.Body>
        <Table<InvestorUserAccessDto & { [key: string]: any }>
          initialFilters={[]}
          initialSort={[]}
          withPagination={false}
          onFiltersChange={() => {}}
          columns={columns}
          data={investorAccounts.accounts}
          count={investorAccounts.accounts.length}
          sortable={false}
        />
      </Card.Body>
    </Card>
  );
};

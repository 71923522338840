import { DealType, DealRating, Currency, SortOrder } from 'types';
import { CommonFilterValues } from '../types';

export interface CommonSecondaryDealFilterValues extends CommonFilterValues {}

export interface SecondaryDealFilterValues extends CommonSecondaryDealFilterValues {}

export type SecondaryDealFilterPayload = {
  productType: DealType[];
  rating: DealRating[];
  currency: Currency[];
  sortBy: string;
  sortOrder: SortOrder;
};

export enum ESecondaryDealSortOption {
  PublicationDate = 'PublicationDate',
  Currency = 'Currency',
  Price = 'Price',
  PriceDiscount = 'PriceDiscount',
  NominalValue = 'NominalValue',
  IRR = 'IRR',
  ExpirationDate = 'ExpirationDate',
  Rating = 'Rating',
  ProductType = 'ProductType',
}

import { ChangeEvent, useMemo, useState } from 'react';
import { Button, Box, ListItem, List, ListItemText, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { Select } from 'components/forms';
import { ConfirmRemoveAccount } from 'components';
import { InvestorDto, InvestorUserAccessDto } from 'api/investor-client';
import { getInvestorRoleList } from 'utils';
import { InvestorRole } from 'types';
import { Field, FieldArray } from 'formik';

type InvestorUserAccess = InvestorUserAccessDto & { [key: string]: any };

interface Props {
  isViewOnly: boolean;
  isAdvisor: boolean;
  investor?: InvestorDto | null;
  data: InvestorUserAccess[];
  onRemove: (userId: string, newOwnerId?: string) => void;
}

export const MembersTable = (props: Props) => {
  const intl = useIntl();
  const investorRoleList = useMemo(() => getInvestorRoleList(intl), [intl]);
  const [ua, setUA] = useState<InvestorUserAccess | null>(null);

  return (
    <>
      <FieldArray name="userAccess">
        {({ form }) => (
          <List>
            <Box>
              <ListItem divider style={{ padding: '10px' }}>
                <Grid item xs={3}>
                  <ListItemText>Name</ListItemText>
                </Grid>
                <Grid item xs={4}>
                  <ListItemText>Email</ListItemText>
                </Grid>
                <Grid item xs={2}>
                  <ListItemText>Phone</ListItemText>
                </Grid>
                <Grid item xs={2}>
                  <ListItemText>Role</ListItemText>
                </Grid>
                <Grid item xs={1}></Grid>
              </ListItem>
            </Box>
            {props.data.map((e, index) => {
              const canDelete =
                !props.isViewOnly && !props.isAdvisor && e.role !== InvestorRole.InvestorOwner;

              return (
                <Box key={index}>
                  <ListItem divider style={{ padding: '10px' }}>
                    <Grid item xs={3}>
                      <ListItemText>{e.name}</ListItemText>
                    </Grid>
                    <Grid item xs={4}>
                      <ListItemText>{e.email}</ListItemText>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItemText>{e.phone}</ListItemText>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItemText>
                        <Field
                          disabled={
                            props.isViewOnly ||
                            props.isAdvisor ||
                            e.role === InvestorRole.InvestorOwner
                          }
                          id={`userAccess.${index}.role`}
                          name={`userAccess.${index}.role`}
                          labelDirection="column"
                          component={Select}
                          options={
                            e.role === InvestorRole.InvestorOwner
                              ? investorRoleList
                              : investorRoleList.filter(
                                  ({ value }) => value !== InvestorRole.InvestorOwner,
                                )
                          }
                          onChange={(e: ChangeEvent<{ value: InvestorRole }>) => {
                            form.setFieldValue(`userAccess.${index}.role`, e.target.value);
                          }}
                        />
                      </ListItemText>
                    </Grid>
                    <Grid item xs={1}>
                      <ListItemText>
                        <Box textAlign="right">
                          {canDelete && (
                            <Button
                              color="error"
                              variant="outlined"
                              onClick={() => {
                                setUA(e);
                              }}
                            >
                              <FormattedMessage id="remove" />
                            </Button>
                          )}
                        </Box>
                      </ListItemText>
                    </Grid>
                  </ListItem>
                </Box>
              );
            })}
          </List>
        )}
      </FieldArray>

      {ua && (
        <ConfirmRemoveAccount
          open={!!ua}
          investorId={props.investor?.id}
          userRole={ua.role as InvestorRole}
          onClose={(result, chosenUser) => {
            setUA(null);
            if (result) props.onRemove(ua.userId, chosenUser);
          }}
        />
      )}
    </>
  );
};

import { useMemo } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Box, Link, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { IDealDto } from 'api/investor-client';
import { IDealEventWithShareId } from 'types';
import { formatMoney, moveDecimalPoint } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: '18px',

    '&.with-padding': {
      paddingTop: '18px',
    },
  },

  activityLineContainer: {
    position: 'relative',
    color: theme.palette.primary.light,
  },

  activityLineIconWrapper: {
    position: 'relative',
    display: 'flex',
    zIndex: 1,

    '& > svg': {
      zIndex: 1,
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      inset: '3px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '32px',
      zIndex: 0,
    },
  },

  activityLine: {
    position: 'absolute',
    top: '5px',
    bottom: 'calc(100% - 5px)',
    left: 'calc(50% - 1px)',
    width: '2px',
    backgroundColor: theme.palette.primary.light,

    '&.element-above': {
      top: '-18px',
    },

    '&.element-below': {
      bottom: '0px',
    },

    '&.preview': {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '10px',
        height: '4px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
      },
    },
  },

  contextWrapper: {
    '&.with-padding': {
      paddingBottom: '32px',
    },
  },
}));

interface IDealEventData {
  title: string;
  timestamp: string;
  comment?: string;
  shareId?: string;
  color?: string;
}

const processDealEvent = (
  dealEvent: IDealEventWithShareId,
  deal: IDealDto,
  intl: IntlShape,
  theme: Theme,
) => {
  const res = {} as IDealEventData;

  // Title section
  switch (dealEvent.type) {
    case 'InvestmentCommittedEvent': {
      const investmentAmount = dealEvent.json['investmentAmount'] as number;

      if (typeof investmentAmount === 'number') {
        res.title = intl.formatMessage(
          { id: 'milestone.InvestmentCommittedEvent.with_amount' },
          { amount: formatMoney(moveDecimalPoint(investmentAmount, -2), { suffix: ` ${deal.currency}` }) },
        );
      } else {
        res.title = intl.formatMessage({ id: `milestone.${dealEvent.type}` });
      }

      break;
    }

    default: {
      res.title = intl.formatMessage({ id: `milestone.${dealEvent.type}` });
      break;
    }
  }

  // Color section
  switch (dealEvent.type) {
    case 'DealCancelledEvent':
    case 'DealNotFinancedEvent':
    case 'DealMarkedAsLossEvent':
    case 'DealRejectedEvent': {
      res.color = theme.palette.error.main;
      break;
    }

    case 'DealFinishedEvent': {
      res.color = theme.palette.success.main;
      break;
    }

    default: {
      res.color = theme.palette.primary.light;
      break;
    }
  }

  res.timestamp = dayjs(dealEvent.createdDate).format('DD/MM/YYYY');
  res.shareId = dealEvent.shareId;

  const description = dealEvent.json['description'] as string;
  res.comment = typeof description === 'string' ? description : undefined;

  return res;
};

interface Props {
  deal: IDealDto;
  dealEvent: IDealEventWithShareId;
  elementAbove?: boolean;
  elementBelow?: boolean;
  preview?: boolean;
}

export const ActivityTimelineEvent = (props: Props) => {
  const { deal, dealEvent, elementAbove, elementBelow, preview } = props;

  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  const dealEventData = useMemo(() => {
    return processDealEvent(dealEvent, deal, intl, theme);
  }, [deal, dealEvent, intl, theme]);

  return (
    <Box className={clsx(classes.container, { 'with-padding': elementAbove })}>
      <Box className={classes.activityLineContainer}>
        <Box
          className={clsx(classes.activityLine, {
            'element-above': elementAbove,
            'element-below': elementBelow,
            preview: preview,
          })}
          sx={{ '&&': { background: dealEventData.color } }}
        />

        <Box className={classes.activityLineIconWrapper}>
          <RiCheckboxCircleFill size={24} color={dealEventData.color} />
        </Box>
      </Box>

      <Box className={clsx(classes.contextWrapper, { 'with-padding': elementBelow })}>
        {!!dealEventData.shareId && (
          <Typography variant="body2" fontWeight={500} margin="2px 0 4px">
            <Link component={ReactLink} to={`../../../portfolio/primary/investments/${dealEventData.shareId}`}>
              <FormattedMessage id="investment" /> {dealEventData.shareId}
            </Link>
          </Typography>
        )}

        <Typography variant="subtitle2">{dealEventData.title}</Typography>

        <Typography
          variant="caption"
          component="p"
          fontWeight={300}
          color="text.secondary"
          marginTop="8px"
        >
          {dealEventData.timestamp}
        </Typography>

        {!!dealEventData.comment && (
          <Typography
            variant="caption"
            component="p"
            fontWeight={300}
            color="text.secondary"
            fontStyle="italic"
            marginTop="16px"
          >
            "{dealEventData.comment}"
          </Typography>
        )}
      </Box>
    </Box>
  );
};

import { Box, Skeleton, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  notificationCard: {
    display: 'flex',
    gap: '24px',
    padding: '24px 16px',

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.12)}`,
    },
  },

  notificationCardIcon: {
    width: '32px',
    height: '32px',
    flexShrink: 0,
  },

  notificationCardContent: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '6px 8px',

    '& > .MuiSkeleton-root': {
      height: '12px',
    },
  },

  notificationCardUnreadMark: {
    width: '8px',
    height: '8px',
    marginTop: '8px',
    flexShrink: 0,
  },
}));

interface Props {}

export const NotificationSkeleton = (props: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.notificationCard}>
      <Skeleton className={classes.notificationCardIcon} variant="rounded" />

      <Box className={classes.notificationCardContent}>
        <Skeleton width="80%" />
        <Skeleton width="30%" />
        <Skeleton width="35%" />
        <Skeleton width="40%" />
      </Box>

      <Skeleton className={classes.notificationCardUnreadMark} variant="rounded" />
    </Box>
  );
};

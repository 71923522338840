import { BadgeProps, Box, Theme, styled } from '@mui/material';

const getColors = (color: BadgeProps['color'], theme: Theme) => {
  switch (color) {
    case 'primary': {
      return {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      };
    }

    default: {
      return {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.text.primary,
      };
    }
  }
};

const SInlineBadge = styled(Box)<{ color?: BadgeProps['color'] }>(
  ({ theme, color = 'primary' }) => ({
    minWidth: '16px',
    display: 'inline-flex',
    justifyContent: 'center',
    ...theme.typography.caption,
    fontWeight: 500,
    borderRadius: '16px',
    ...getColors(color as any, theme),
  }),
);

interface Props {
  label: string | number;
  className?: string;
  color?: BadgeProps['color'];
}

export const InlineBadge = ({ label, ...rest }: Props) => {
  return <SInlineBadge {...rest}>{label}</SInlineBadge>;
};

import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CellProps } from 'react-table';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { InlineTooltip } from 'components/UI';
import { Table } from 'components';
import { SubTabProps } from './InvestmentInformation';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { getExpectedRepaymentTableData, Repayment } from 'utils/repayments';

type RepaymentType = { [key: string]: any } & Repayment;

const useStyles = makeStyles({
  table: {
    transition: 'opacity 125ms',
    '& td': {
      whiteSpace: 'nowrap',
    },
    '& th, & tr:last-of-type td': {
      fontWeight: 600,
    },
    '&.loading': {
      opacity: 0.55,
    },
  },
  accentCol: {
    background: '#ededed',
    '&.MuiTableCell-head': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
  },
});

export const PaymentScheduleSubTab = ({ deal, repaymentsData, loading, isSecondaryMarket }: SubTabProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const formatMoney = useFormatMoney();

  const [repayments, setRepayments] = useState<Repayment[]>([]);

  useEffect(() => {
    if (repaymentsData) {
      setRepayments(getExpectedRepaymentTableData(deal.repayments, repaymentsData.transactions));
    }
  }, [deal.repayments, repaymentsData]);

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: 'installment' }),
        accessor: 'installment',
        disableSortBy: true,
      },
      {
        Header: (
          <span>
            <FormattedMessage id={isSecondaryMarket ? "date" : "sample_date"} />{' '}
            {!isSecondaryMarket && <InlineTooltip tooltip={intl.formatMessage({ id: 'sample_date_tooltip' })} />}
          </span>
        ),
        accessor: 'date',
        disableSortBy: true,
        Cell: function Cell({ value }: CellProps<RepaymentType, string>) {
          if (!value) return '';
          return dayjs(value).format('DD.MM.YYYY');
        },
      },
      {
        Header: intl.formatMessage({ id: 'gross_repayment' }),
        id: 'gross_repayment',
        disableSortBy: true,
        className: classes.accentCol,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentType>) {
          return formatMoney(moveDecimalPoint(original.principal + original.interest, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'amortization' }),
        accessor: 'principal',
        disableSortBy: true,
        Cell: function Cell({ value }: CellProps<RepaymentType, number>) {
          return formatMoney(moveDecimalPoint(value, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'interests' }),
        accessor: 'interest',
        disableSortBy: true,
        Cell: function Cell({ value }: CellProps<RepaymentType, number>) {
          return formatMoney(moveDecimalPoint(value, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'service_fee' }),
        accessor: 'fee',
        disableSortBy: true,
        Cell: function Cell({ value }: CellProps<RepaymentType, number>) {
          return formatMoney(moveDecimalPoint(value, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'net_repayment' }),
        accessor: 'total',
        disableSortBy: true,
        className: classes.accentCol,
        Cell: function Cell({ value }: CellProps<RepaymentType, number>) {
          return formatMoney(moveDecimalPoint(value, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'investment.outstanding' }),
        accessor: 'outstandingInvestment',
        disableSortBy: true,
        Cell: function Cell({ value }: CellProps<RepaymentType, number>) {
          return formatMoney(moveDecimalPoint(value, -2));
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage, formatMoney],
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Table<RepaymentType>
          size="small"
          initialFilters={[]}
          initialSort={[]}
          withPagination={false}
          onFiltersChange={(f) => {}}
          columns={columns}
          data={repayments}
          count={0}
          noRowsIntlName="warning.no_repayments"
          className={clsx(classes.table, { loading: loading })}
        />
      </Grid>
    </Grid>
  );
};

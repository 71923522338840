import { Alert } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {}

export const AdditionalInformationSubTab = (props: Props) => {
  return (
    <Alert severity="warning" color='info'>
      <FormattedMessage id="warning.no_info" />
    </Alert>
  );
};

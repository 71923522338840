import { Box, Typography, alpha, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const SWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 8px',
  borderRadius: '4px',
  backgroundColor: alpha(theme.palette.background.barkSurface, 0.08),
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
}));

interface Props {
  months: number;
}

export const MonthChip = ({ months }: Props) => {
  return (
    <SWrapper>
      <Typography variant="subtitle3">
        {months} <FormattedMessage id={months > 1 ? 'months' : 'month'} />
      </Typography>
    </SWrapper>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { Box, Dialog, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SimpleBar from 'simplebar-react';

import { BulkError, ShareCommitmentDto } from 'api/investor-client';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { Currency, EBankAccountStatus, MarketType } from 'types';
import theme from 'config/theme';

import { ConfirmationModalSubtitle, ConfirmationModalHeaded, ActivateDDInfo, StickyFooter } from './components';
import {
  EBasketReviewTabType,
  ITabObjet,
  InvestmentConfirmationModalOptionsWithType,
  TTabData,
  TTabsData,
} from './types';
import { PaymentsOverview } from './PaymentsOverview';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative'
  },

  body: {
    width: '100%',
    padding: '32px 16px',

    [theme.breakpoints.up('sm')]: {
      padding: '32px 24px',
    },
  },

  dialog: {
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      alignSelf: 'flex-end',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
}));

interface Props extends InvestmentConfirmationModalOptionsWithType {
  isOpen: boolean;
  onClose: () => void;
}

export const InvestmentConfirmationModal = (props: Props) => {
  const results = props.commitmentResult?.result;
  const errors = props.commitmentResult?.errors;
  const withCommitments = !!results || !!errors;

  const classes = useStyles();

  const { selectedAccount, updateAccountBalance } = useInvestorAccounts();
  useEffect(() => {
    if (props.isOpen) {
      updateAccountBalance()
    }
  }, [updateAccountBalance, props.isOpen]);
  const { VABalance: newBalance } = selectedAccount ?? {};

  // --- Tabs section ---

  const tabsData: TTabsData = useMemo(() => {
    const data = (results || []).reduce<TTabsData>(
      (tabs, el) => {
        const activeDdCurrencies = new Set(
          props.virtualAccount?.bankAccounts
            ?.filter((ba) => {
              return (
                ba?.dd?.status === EBankAccountStatus.Active ||
                ba?.lsv?.status === EBankAccountStatus.Active
              );
            })
            .map((ba) => ba.currency),
        );

        const { deal, response } = el;
        const currency = deal.currency;
        const tabType = (response as ShareCommitmentDto).confirmed || props.type === MarketType.secondary
          ? EBasketReviewTabType.Paid
          : activeDdCurrencies.has(currency)
            ? EBasketReviewTabType.Pending
            : EBasketReviewTabType.Unpaid;
        const currencyTabs = tabs[tabType];
        currencyTabs[currency] = [...(currencyTabs[currency] || []), el];

        return tabs;
      },
      { Paid: {}, Pending: {}, Unpaid: {}, Rejected: {} },
    );

    data.Rejected = (errors || []).reduce<Partial<Record<Currency, Array<BulkError>>>>(
      (currencies, el) => {
        const currency = el.deal?.currency || el.request.currency;
        currencies[currency] = (currencies[currency] || []).concat(el);
        return currencies;
      },
      {},
    );

    return data;
  }, [results, errors, props.virtualAccount?.bankAccounts, props.type]);

  const availableTabs = useMemo<ITabObjet[]>(() => {
    const tabs = Object.keys(tabsData) as EBasketReviewTabType[];

    const available = tabs
      .map((tab) => {
        const size = Object.values(tabsData[tab]).reduce((size, arr) => {
          return size + arr.length;
        }, 0);

        return {
          value: tab,
          size: size,
          color: tab === EBasketReviewTabType.Rejected ? theme.palette.error.dark : undefined,
        };
      })
      .filter(({ size }) => {
        return size > 0;
      });

    return available;
  }, [tabsData]);

  const firstAvailableTab = availableTabs[0]?.value;
  const [activeTabState, setActiveTab] = useState(firstAvailableTab);
  const activeTab = activeTabState || firstAvailableTab;
  const currentTabsData = tabsData[activeTab];
  const rejectedPaymentsTab = activeTab === EBasketReviewTabType.Rejected;

  // const targetBalance = useMemo(() => {
  //   if (activeTab === EBasketReviewTabType.Paid) {
  //     return newBalance;
  //   }
  //
  //   // prettier-ignore
  //   const paidSize = availableTabs.find((tab) => {
  //     return tab.value === EBasketReviewTabType.Paid;
  //   })?.size ?? 0;
  //
  //   if (paidSize > 0) {
  //     return newBalance;
  //   } else {
  //     return props.oldBalance;
  //   }
  // }, [activeTab, availableTabs, newBalance, props.oldBalance]);

  const showDDInfoNotification = useMemo(() => {
    if (!currentTabsData) return false;
    if (props.type === MarketType.secondary) return false;
    const currencies = Object.keys(currentTabsData);

    for (const currency of currencies) {
      const isActive = props.virtualAccount?.bankAccounts?.find((bankAccount) => {
        return (
          bankAccount.currency === currency &&
          (bankAccount.dd?.status === EBankAccountStatus.Active ||
            bankAccount.lsv?.status === EBankAccountStatus.Active)
        );
      });

      if (!isActive) return true;
    }

    return false;
  }, [currentTabsData, props.virtualAccount, props.type]);

  const dataToDisplay = useMemo(() => {
    if (!currentTabsData) return null;
    const result: TTabData = {};

    for (const [currency, payments] of Object.entries(currentTabsData)) {
      if (payments.length) result[currency as Currency] = payments;
    }

    return result;
  }, [currentTabsData]);

  const isDataToDisplay = useMemo(() => Object.keys(dataToDisplay || {}).length > 0, [dataToDisplay]);

  if (!withCommitments) return null;
  return (
    <Dialog
      open={props.isOpen}
      maxWidth={'md'}
      PaperProps={{ className: classes.dialog }}
      PaperComponent={SimpleBar}
    >
      <Paper className={classes.container}>
        <ConfirmationModalHeaded
          type={availableTabs.length > 1 ? 'Mixed' : firstAvailableTab}
          onClose={props.onClose}
        />

        <Box className={classes.body}>
          <ConfirmationModalSubtitle
            results={results}
            tabs={availableTabs}
            activeTab={activeTab}
            onTabChange={setActiveTab}
            rejectedType={rejectedPaymentsTab}
          />

          {isDataToDisplay && (
            <PaymentsOverview
              type={props.type}
              dataToDisplay={dataToDisplay}
              isPaidTab={activeTab === EBasketReviewTabType.Paid}
              newBalance={newBalance}
              oldBalance={props.oldBalance}
              virtualAccount={props.virtualAccount}
            />
          )}
        </Box>

        {showDDInfoNotification && <ActivateDDInfo />}

        {isDataToDisplay && (
          <StickyFooter
            dataToDisplay={dataToDisplay}
            balance={props.oldBalance}
          />
        )}
      </Paper>
    </Dialog>
  );
};

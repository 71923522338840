import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import { BulkError } from 'api/investor-client';
import { InlineTooltip } from 'components/UI';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { BulkResultWithPayment, Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltip: {
    color: theme.palette.text.secondary
  }
}));

interface Props {
  currency: Currency;
  payments: Array<BulkResultWithPayment | BulkError>;
  newBalance: number;
  oldBalance: number;
  ddActive?: boolean;
  isPaidTab?: boolean;
}

export const PaymentSummary = (props: Props) => {
  const { currency, payments, newBalance, oldBalance, ddActive, isPaidTab } = props;
  const classes = useStyles();
  const intl = useIntl();
  const formatMoney = useFormatMoney({ prefix: `${currency} ` });

  const orderAmount = payments.reduce((sum, next) => {
    return sum + next.request.amount + (next.request.fee || 0)
  }, 0);

  const totalTitle = isPaidTab ? 'Paid' : ddActive ? 'Pending' : 'Unpaid';
  const total = isPaidTab ? orderAmount : newBalance - orderAmount;
  return (
    <Box display="flex" flexDirection="column" gap="16px" mt="24px">

      <Box className={classes.row}>
        <Typography variant="body2" fontWeight={500} color="text.secondary">
          <FormattedMessage id="your_balance" />
        </Typography>
        <Typography variant="body2" fontWeight={500} color="text.secondary">
          {formatMoney(moveDecimalPoint(isPaidTab ? oldBalance : newBalance, -2))}
        </Typography>
      </Box>

      <Box className={classes.row}>
        <Typography variant="body2" fontWeight={500} color="text.secondary">
          <FormattedMessage id="order_amount" />
        </Typography>
        <Typography variant="body2" fontWeight={500} color="text.secondary">
          {formatMoney(moveDecimalPoint(orderAmount, -2))}
        </Typography>
      </Box>

      <Divider sx={{ borderColor: 'text.primary' }} />

      <Box className={classes.row}>
        <Typography variant="body2" fontWeight={500}>
          <FormattedMessage id={`investment_summary.${totalTitle}`} values={{ currency }} />{' '}
          <InlineTooltip className={classes.tooltip} tooltip={intl.formatMessage({id: `investment_summary.${totalTitle}.tooltip`})} size={20} />
        </Typography>
        <Typography
          variant="body2"
          fontWeight={600}
          color={(theme) => {
            return (isPaidTab || ddActive) ? theme.palette.text.primary : theme.palette.error.main;
          }}
        >
          {formatMoney(moveDecimalPoint(total, -2))}
        </Typography>
      </Box>
    </Box>
  );
};

import { useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  alpha,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiArrowDownSFill, RiCloseLine } from 'react-icons/ri';
import { Form, FormikContext, useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { InlineBadge } from 'components/UI';
import { withDialog } from 'wrappers';
import { SortOrder } from 'types';

import { SortButton } from '../SortButton';
import { useCommonFiltersStyles } from '../useCommonFiltersStyles';
import { CommonFilterModalProps } from '../types';
import { CommonFilters } from './CommonFilters';
import { primaryDealFiltersHelper } from './helpers';
import { primaryDealSortVariantsMap } from './constants';
import { PrimaryDealFilterValues, EPrimaryDealSortOption } from './types';

const useStyles = makeStyles((theme) => ({
  closeButtonContainer: {
    position: 'absolute',
    top: '12px',
    right: '12px',
  },

  body: {
    padding: '24px 24px 0px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '4px',
  },

  sortContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  sortGroup: {
    display: 'flex',
    gap: '16px',
    padding: '0 16px',
    flexWrap: 'wrap',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    borderTop: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
    boxShadow: `0px 2px 6px 0px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

type Props = CommonFilterModalProps<PrimaryDealFilterValues>;

export const PrimaryDealFilterModal = withDialog((props: Props) => {
  const { initialValues, withSort, onClear, onApply, onClose } = props;

  const commonFiltersStyles = useCommonFiltersStyles();
  const classes = useStyles();
  const intl = useIntl();

  const handleSubmit = (values: PrimaryDealFilterValues) => {
    onApply?.(values);
    onClose?.();
  };

  const formik = useFormik<PrimaryDealFilterValues>({
    initialValues: initialValues ?? primaryDealFiltersHelper.getEmptyValue(),
    onSubmit: handleSubmit,
  });

  const getSortLabel = useCallback(
    (type: EPrimaryDealSortOption) => {
      switch (type) {
        case EPrimaryDealSortOption.PublicationDate:
          return intl.formatMessage({ id: 'publication_date' });

        case EPrimaryDealSortOption.Currency:
          return intl.formatMessage({ id: 'currency' });

        case EPrimaryDealSortOption.IRR:
          return intl.formatMessage({ id: 'irr' });

        case EPrimaryDealSortOption.Duration:
          return intl.formatMessage({ id: 'duration' });

        case EPrimaryDealSortOption.Amount:
          return intl.formatMessage({ id: 'amount' });

        case EPrimaryDealSortOption.Rating:
          return intl.formatMessage({ id: 'rating' });

        case EPrimaryDealSortOption.ProductType:
          return intl.formatMessage({ id: 'type' });

        default:
          return '';
      }
    },
    [intl],
  );

  const handleClear = () => {
    formik.resetForm({ values: primaryDealFiltersHelper.getEmptyValue() });
    onClear?.();
  };

  const totalFiltersCount = primaryDealFiltersHelper.countValues(formik.values);

  return (
    <Box>
      <Box className={classes.closeButtonContainer}>
        <IconButton onClick={onClose}>
          <RiCloseLine />
        </IconButton>
      </Box>

      <FormikContext.Provider value={formik}>
        <Form className={classes.body}>
          <Box className={classes.title}>
            <Typography variant="subtitle1" fontWeight={500}>
              <FormattedMessage id="all_filters" />
            </Typography>

            {totalFiltersCount > 0 && <InlineBadge label={totalFiltersCount} />}
          </Box>

          <Typography variant="body2" color="text.secondary" marginBottom="32px">
            <FormattedMessage id="select_filters_to_apply" />
          </Typography>

          {withSort && (
            <Accordion className={commonFiltersStyles.accordion} elevation={0}>
              <AccordionSummary expandIcon={<RiArrowDownSFill />}>
                <Typography>
                  <FormattedMessage id="sort_by" />
                </Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.sortContainer}>
                {Object.entries(primaryDealSortVariantsMap).map(([key, value]) => {
                  return (
                    <Box key={key} className={classes.sortGroup}>
                      <SortButton
                        label={getSortLabel(key as EPrimaryDealSortOption)}
                        value={value[SortOrder.ASC]}
                      />

                      <SortButton
                        label={getSortLabel(key as EPrimaryDealSortOption)}
                        value={value[SortOrder.DESC]}
                        defaultSelect={key === EPrimaryDealSortOption.PublicationDate}
                      />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}

          <CommonFilters />
        </Form>
      </FormikContext.Provider>

      <Paper className={classes.footer}>
        <Button variant="text" onClick={handleClear}>
          <FormattedMessage id="filters.clear" />
        </Button>

        <Button onClick={() => handleSubmit(formik.values)}>
          <FormattedMessage id="filters.apply" />
        </Button>
      </Paper>
    </Box>
  );
});

import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Button } from '@mui/material';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserInviteDto } from 'api/investor-client';
import { Select } from 'components/forms';
import { Input, FormDialog } from 'components';
import { notEmpty } from 'utils/validation';
import { getInvestorRoleList } from 'utils';
import { InvestorRole } from 'types';

const useStyles = makeStyles(() => ({
  block: {
    marginBottom: '8px',
  },
  colText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const InviteUserForm = ({ isAdmin }: { isAdmin: boolean }) => {
  const classes = useStyles();
  const intl = useIntl();

  const investorRoleList = useMemo(() => {
    let allRoles = getInvestorRoleList(intl);

    allRoles = allRoles.filter(
      ({ value }) => value !== InvestorRole.InvestorOwner,
    );

    return allRoles;
  }, [intl]);

  return (
    <>
      <Grid container spacing={4} classes={{ root: classes.block }}>
        <Grid item md={6}>
          <Field
            required
            id="firstName"
            name="firstName"
            label={intl.formatMessage({ id: 'common.form_field.first_name' })}
            labelDirection="column"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            id="lastName"
            name="lastName"
            label={intl.formatMessage({ id: 'common.form_field.last_name' })}
            labelDirection="column"
            component={Input}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} classes={{ root: classes.block }}>
        <Grid item md={6}>
          <Field
            required
            id="email"
            name="email"
            label={intl.formatMessage({ id: 'email' })}
            labelDirection="column"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="role"
            name="role"
            label={intl.formatMessage({ id: 'role' })}
            labelDirection="column"
            component={Select}
            options={investorRoleList}
            validate={notEmpty}
          />
        </Grid>
      </Grid>
    </>
  );
};

type Props = {
  isAdmin: boolean;
  onSubmit: (arg0: UserInviteDto) => Promise<unknown>;
};

export const InviteUserWidget = ({ isAdmin, onSubmit }: Props) => {
  const intl = useIntl();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        <FormattedMessage id="add_new_person" />
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        header={intl.formatMessage({ id: 'invite_member_to_account' })}
        onSave={async (values: UserInviteDto) => {
          onSubmit(values);
          setModalOpen(false);
        }}
      >
        <InviteUserForm isAdmin={isAdmin} />
      </FormDialog>
    </>
  );
};

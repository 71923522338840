import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/index.css';
import './config/app-height-calculator';

import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ThemeProvider as StylesThemeProvider } from '@mui/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { ProSidebarProvider } from 'react-pro-sidebar';

import { InvestorAccountsProvider } from 'providers/investor-accounts';
import { BrowserWrapper, I18nWrapper } from 'wrappers';
import theme from 'config/theme';

import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { UserDetailsProvider } from 'providers/user-details';

const translationErrorLog = console.warn;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_ISSUER!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      cacheLocation="localstorage"
    >
      <ThemeProvider theme={theme}>
        <StylesThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <BrowserWrapper>
              <InvestorAccountsProvider loadInitially={false}>
                <ProSidebarProvider>
                  <UserDetailsProvider loadInitially={true}>
                    <I18nWrapper onError={(e) => translationErrorLog(e.message)}>
                      <App />
                    </I18nWrapper>
                  </UserDetailsProvider>
                </ProSidebarProvider>
              </InvestorAccountsProvider>
            </BrowserWrapper>
          </StyledEngineProvider>
        </StylesThemeProvider>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

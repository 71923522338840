import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha, Box, IconButton, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { RiCloseLine } from 'react-icons/ri';
import clsx from 'clsx';

import { InvestorDto } from 'api/investor-client';
import { EBasketReviewTabType } from '../types';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '32px 16px',
    backgroundColor: theme.palette.background.blueSurface,

    [theme.breakpoints.up('sm')]: {
      padding: '32px 24px',
    },
  },

  closeButton: {
    position: 'absolute',
    marginRight: '-14px',
    alignSelf: 'flex-end',
    top: '6px',

    [theme.breakpoints.down('sm')]: {
      '& .MuiIconButton-root': {
        backgroundColor: theme.palette.background.blueSurface,
      },
    },
  },

  rejectedContainer: {
    backgroundColor: alpha(theme.palette.error.main, 0.04),
  },

  title: {
    display: 'flex',
    alignItems: 'center',
  },

  rejectedTitle: {
    color: theme.palette.error.dark,
  },

  content: {
    maxWidth: '850px',
  },

  link: {
    '&.rejected': {
      color: theme.palette.error.dark,
      textDecorationColor: alpha(theme.palette.error.dark, 0.4),
    },
  },
}));

type THeaderType = EBasketReviewTabType | 'Mixed';

interface Props {
  type: THeaderType;
  investor?: InvestorDto;
  onClose: () => void;
}

export const ConfirmationModalHeaded = ({ type, onClose }: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  const title = useMemo(() => {
    switch (type) {
      case 'Rejected':
        return intl.formatMessage({
          id: 'commitment_overview.investment_rejected!',
        });

      default:
        return intl.formatMessage({
          id: 'commitment_overview.investment_confirmed!',
        });
    }
  }, [type, intl]);

  const content = useMemo(() => {
    const idMap = {
      Paid: 'commitment_overview.content.Paid',
      Pending: 'commitment_overview.content.Pending',
      Unpaid: 'commitment_overview.content.Unpaid',
      Rejected: 'commitment_overview.content.Rejected',
      Mixed: 'commitment_overview.content.Mixed',
    };

    const id = idMap[type];

    return (
      <FormattedMessage
        id={id}
        values={{
          aBal: (msg: string) => (
            <Link
              className={clsx(classes.link, {
                rejected: type === EBasketReviewTabType.Rejected,
              })}
              component={RouterLink}
              to={`../settings/account`}
            >
              {msg}
            </Link>
          ),
          aSup: (msg: string) => (
            <Link
              className={clsx(classes.link, {
                rejected: type === EBasketReviewTabType.Rejected,
              })}
              href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {msg}
            </Link>
          ),
        }}
      />
    );
  }, [type, classes.link]);

  return (
    <Box
      className={clsx(classes.container, {
        [classes.rejectedContainer]: type === EBasketReviewTabType.Rejected,
      })}
    >
      <Box className={classes.closeButton}>
        <IconButton onClick={onClose}>
          <RiCloseLine />
        </IconButton>
      </Box>

      <Box
        className={clsx(classes.title, {
          [classes.rejectedTitle]: type === EBasketReviewTabType.Rejected,
        })}
      >
        <Typography component="span" variant="subtitle2" fontWeight={600}>
          {title}
        </Typography>
      </Box>

      <Box className={classes.content}>
        <Typography variant="body2">{content}</Typography>
      </Box>
    </Box>
  );
};

import { Container, Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams, useHistory } from 'react-router';
import { useAsync } from 'react-async-hook';
import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';

import { userApiClient } from 'api';
import { Loader } from 'components';

interface Props {}

interface LocationState {
  token: string;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    textAlign: 'center',
  },
}));

export const EmailVerificationPage = (props: Props) => {
  const { logout } = useAuth0();
  const params = useParams<LocationState>();
  const history = useHistory();
  const classes = useStyles();

  const { result = false, loading } = useAsync(async () => {
    if (!params.token) return;

    await userApiClient.user.userApiControllerVerifyChangeEmail({
      token: params.token,
    });

    return true;
  }, [params.token]);

  if (loading) {
    return (
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h3" marginBottom={4}>
            <FormattedMessage id="email_verification.verifying" />
          </Typography>
          <Box position="relative">
            <Loader />
          </Box>
        </Box>
      </Container>
    );
  }

  if (!params.token || !result) {
    return (
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h3" marginBottom={4}>
            <FormattedMessage id="email_verification.error.title" />
          </Typography>
          <Typography variant="h6">
            <FormattedMessage id="email_verification.error.description" />
          </Typography>
          <Typography variant="h6" marginBottom={8}>
            <FormattedMessage id="email_verification.error.description_helper" />
          </Typography>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => history.push('/accounts')}
          >
            <FormattedMessage id="email_verification.error.back_button" />
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.wrapper}>
        <Typography variant="h3" marginBottom={4}>
          <FormattedMessage id="email_verification.success.title" />
        </Typography>
        <Typography variant="h6" marginBottom={8}>
          <FormattedMessage id="email_verification.success.description" />
        </Typography>
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <FormattedMessage id="email_verification.success.relogin_button" />
        </Button>
      </Box>
    </Container>
  );
};

import { useLocation } from 'react-router';
import * as history from 'history';

/**
 * default - default react-router link behavior
 * continue-url - will concat provided path with page URL.
 *  (e.g. if URL = 'localhost:3000/home/content', and path = 'data/42',
 *   them result = localhost:3000/home/content/data/42')
 */
type TLinkResolveStrategy = 'default' | 'continue-url';

const resolveCurrentLocationPath = (path: string, strategy: TLinkResolveStrategy) => {
  if (strategy === 'default') {
    return path;
  }

  if (strategy === 'continue-url') {
    if (path.endsWith('/')) return path;
    return path + '/';
  }

  return path;
};

export const useLink = () => {
  const location = useLocation();

  return (urlPath: string, strategy: TLinkResolveStrategy = 'default') => {
    const currentLocation: history.Location = {
      ...location,
      pathname: resolveCurrentLocationPath(location.pathname, strategy),
    };

    return history.createPath(
      history.createLocation(urlPath, undefined, undefined, currentLocation),
    );
  };
};

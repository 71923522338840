import { styled, alpha, Divider } from '@mui/material';

export const SCheckbox = styled('svg')(({ theme }) => ({
  fontSize: '24px',
  color: alpha(theme.palette.text.secondary, 0.36),

  '&.checked': {
    color: theme.palette.primary.light,
  },
}));

export const SCountChip = styled('div')(({ theme }) => ({
  minWidth: '16px',
  display: 'inline-flex',
  justifyContent: 'center',
  ...theme.typography.caption,
  fontWeight: 500,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  borderRadius: '16px',
}));

export const SLabelIcon = styled('div')({
  display: 'inline-flex',
  fontSize: '18px',
  marginLeft: '-8px',
});

export const SGroup = styled('div')(({ theme }) => ({
  display: 'flow-root',

  '&:not(:last-of-type)': {
    marginBottom: '16px',
  },
}));

export const SGroupLabel = styled('div')(({ theme }) => ({
  padding: '8px 16px',
  ...theme.typography.caption,
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

export const SGroupDivider = styled(Divider)({
  margin: '8px 16px !important',
});

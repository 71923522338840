import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { ToastContainer } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

import { userApiClient } from 'api';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { useUserDetails } from 'providers/user-details';
import { LayoutProvider } from 'providers/layout-provider';
import { BasketProvider } from 'providers/basket';
import { AccountCreationPage, Spinner } from 'components';
import { AuthWrapper } from 'wrappers';

import { AccountsRouter } from './AccountsRouter';
import { VerificationRouter } from './VerificationRouter';
import { RedirectToAccounts } from './RedirectToAccounts';
import { EMAIL_NOT_VERIFIED_ERROR_MESSAGE } from 'auth0';
import { Auth0EmailVerificationPage } from 'pages/Auth0EmailVerificationPage';
import { Auth0ToSPage } from 'pages/Auth0ToSPage';
import { NotFoundPage } from 'pages';

const reg = /[^< ]+(?=>)/g;

export const App = () => {
  const {
    error,
    logout,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading: auth0IsLoading,
    user,
  } = useAuth0();

  const investorAccounts = useInvestorAccounts();
  const [userInitialized, setUserInitialized] = useState(false);
  const { refresh } = useUserDetails();

  const { loading } = useAsync(async () => {
    if (!isAuthenticated) return;
    const userToken = await getAccessTokenSilently();
    userApiClient.setSecurityData({ token: userToken });
    setUserInitialized(true);
    await refresh();
    await investorAccounts.refresh(false);
  }, [isAuthenticated, getAccessTokenSilently, investorAccounts.refresh]);

  useEffect(() => {
    if (error && !error?.message.includes(EMAIL_NOT_VERIFIED_ERROR_MESSAGE)) {
      const handle = setTimeout(() => {
        logout({ returnTo: window.location.origin });
      }, 3000);

      return () => clearTimeout(handle);
    }
  }, [error, logout]);

  const {
    loading: tosLoading,
    result: acceptedTermsAndConditions,
    execute: fetchToS,
  } = useAsync(async () => {
    if (userInitialized) {
      const { data } = await userApiClient.user.userApiControllerGetTermsAndConditions();
      return data;
    }
  }, [userInitialized]);

  const acceptTerms = useAsyncCallback(async () => {
    await userApiClient.user.userApiControllerAcceptTermsAndConditions();
    await fetchToS();
  });

  if (tosLoading || auth0IsLoading || loading || (isAuthenticated && !userInitialized)) {
    return <Spinner />;
  }
  if (error?.message.includes(EMAIL_NOT_VERIFIED_ERROR_MESSAGE)) {
    return <Auth0EmailVerificationPage email={error.message.match(reg)?.[0]} />;
  }

  if (error) {
    return (
      <div>
        <span>{`${error.name}:${error.message}`}</span>
      </div>
    );
  }

  if (user && !acceptedTermsAndConditions) {
    return acceptedTermsAndConditions === false ?
      <Auth0ToSPage user={user} acceptTerms={acceptTerms.execute} /> :
      <NotFoundPage />;
  }
  if (investorAccounts.initiallyLoaded && investorAccounts.accounts.length === 0) {
    return <AccountCreationPage />;
  }

  return (
    <AuthWrapper>
      <Switch>
        <Route path="/accounts/:accountId">
          <LayoutProvider>
            <BasketProvider>
              <AccountsRouter />
            </BasketProvider>
          </LayoutProvider>
        </Route>

        <Route path="/verification">
          <VerificationRouter />
        </Route>

        <RedirectToAccounts />
      </Switch>
      <ToastContainer />
    </AuthWrapper>
  );
};

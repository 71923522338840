import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { Grid, Theme, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { useAsyncCallback } from 'react-async-hook';
import { FormattedMessage, useIntl } from 'react-intl';

import { userApiClient } from 'api';
import { Input } from 'components';
import Card from 'components/card';
import { groupValidators, notEmpty, password } from 'utils/validation';
import { useCallback } from 'react';

interface Values {
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: '750px',
  },
  cardHeader: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}));

const FormFields = ({ loading }: { loading: boolean }) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFormikContext<Values>();

  return (
    <Card>
      <Card.Header
        title={intl.formatMessage({ id: 'password' })}
        wrapperClassName={classes.cardHeader}
      >
        <FormattedMessage id="update_password" />
      </Card.Header>
      <Card.Body>
        <Grid container spacing={3} className={classes.wrapper}>
          <Grid item sm={6}>
            <Field
              type="password"
              id="currentPassword"
              name="currentPassword"
              label={intl.formatMessage({
                id: 'common.form_field.password_current',
              })}
              labelDirection="column"
              component={Input}
              validate={notEmpty}
            />
          </Grid>
          <Grid item sm={6}></Grid>
          <Grid item sm={6}>
            <Field
              type="password"
              id="newPassword"
              name="newPassword"
              label={intl.formatMessage({
                id: 'common.form_field.password_new',
              })}
              labelDirection="column"
              component={Input}
              validate={groupValidators(notEmpty, password)}
            />
          </Grid>
          <Grid item sm={6}>
            <Field
              type="password"
              id="reNewPassword"
              name="reNewPassword"
              label={intl.formatMessage({
                id: 'common.form_field.password_new_confirm',
              })}
              labelDirection="column"
              component={Input}
              validate={groupValidators(notEmpty, password)}
            />
          </Grid>
        </Grid>
      </Card.Body>
      <Card.Footer>
        <Button
          size="small"
          variant="contained"
          color="primary"
          type="submit"
          sx={{ width: 'max-content' }}
          disabled={!fields.dirty || loading}
        >
          {loading && <CircularProgress size={20} sx={{ marginRight: 3 }} />}
          <FormattedMessage id="password_change" />
        </Button>
      </Card.Footer>
    </Card>
  );
};

export const ChangePasswordForm = () => {
  const intl = useIntl();

  const validate = useCallback((values: Values) => {
    if (values.newPassword !== values.reNewPassword) {
      const message = 'error.password_match';
      return { reNewPassword: message };
    }
  }, []);

  const submit = useAsyncCallback(
    async (data: Values, formik: FormikHelpers<Values>) => {
      await userApiClient.user.userApiControllerUpdatePassword({
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      });

      toast.success(intl.formatMessage({ id: 'success.password_change' }));
      formik.resetForm();
    },
  );

  return (
    <Formik<Values>
      initialValues={{
        currentPassword: '',
        newPassword: '',
        reNewPassword: '',
      }}
      validate={validate}
      onSubmit={submit.execute}
    >
      <Form>
        <FormFields loading={submit.loading} />
      </Form>
    </Formik>
  );
};

import { useCallback, useState } from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FieldArray, Field, Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncCallback } from 'react-async-hook';

import { InvestorDto } from 'api/investor-client';
import { BankAccountDTO, BankAccountCreateDTO, VirtualAccountDTO } from 'api/payments-client';
import { paymentsApiClient } from 'api';
import Card from 'components/card';
import { ConfirmationDialog, Input } from 'components';
import { Currency, EBankAccountStatus } from 'types';

interface Props {
  account: InvestorDto | null;
  virtualAccount: VirtualAccountDTO | null;
  refetchVirtualAccount(): Promise<VirtualAccountDTO>;
  loading?: boolean;
  isViewOnly?: boolean;
}

const useStyles = makeStyles(() => ({
  cardHeader: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}));

export const BankingForm = ({
  account,
  virtualAccount,
  refetchVirtualAccount,
  loading,
  isViewOnly,
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState<{ cb: () => void } | null>(null);

  const createBankAccount = useAsyncCallback(async (bankAccount: BankAccountCreateDTO) => {
    await paymentsApiClient.api.investorApiControllerCreateBankAccount(bankAccount);
  });

  const updateBankAccount = useAsyncCallback(async (bankAccount: BankAccountDTO) => {
    await paymentsApiClient.api.investorApiControllerUpdateBankAccount(bankAccount);
  });

  const removeBankAccount = useAsyncCallback(async (id: string) => {
    await paymentsApiClient.api.investorApiControllerRemoveBankAccount(id);
  });

  const processChanges = useCallback(
    async (bankAccounts: BankAccountDTO[]) => {
      const promises = bankAccounts.map((e) => {
        const newIban = (e?.iban as any)?.value;

        if (newIban && !e?.id) {
          return createBankAccount.execute(e);
        } else if (!newIban && e?.id) {
          return removeBankAccount.execute(e.id);
        } else if (
          newIban &&
          (
            virtualAccount?.bankAccounts?.find((initialBA) => initialBA.currency === e.currency)
              ?.iban as any
          )?.value !== newIban
        ) {
          return updateBankAccount.execute(e);
        }

        return null;
      });

      await Promise.all(promises);
      await refetchVirtualAccount();
    },
    [
      createBankAccount,
      removeBankAccount,
      updateBankAccount,
      virtualAccount?.bankAccounts,
      refetchVirtualAccount,
    ],
  );

  const handleSubmit = useAsyncCallback(
    async (originalBankAccounts: BankAccountDTO[], bankAccounts: BankAccountDTO[]) => {
      let withConfirmation = false;

      const changes = bankAccounts
        .map((ba) => {
          const match = originalBankAccounts?.find((v) => ba.id === v.id);

          if ((ba.iban as any)?.value !== (match?.iban as any)?.value) {
            if (
              match?.dd?.status === EBankAccountStatus.Active ||
              match?.lsv?.status === EBankAccountStatus.Active
            ) {
              withConfirmation = true;
            }

            return ba;
          }

          return null;
        })
        .filter<BankAccountDTO>((ba): ba is BankAccountDTO => !!ba);

      if (withConfirmation) {
        setConfirmation({ cb: processChanges.bind(null, changes) });
      } else {
        processChanges(changes);
      }
    },
  );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          ...account,
          bankAccounts: Object.values(Currency).map(
            (currency) =>
              virtualAccount?.bankAccounts?.find((ba) => ba.currency === currency) ?? {
                currency,
                iban: '',
                id: '',
              },
          ),
        }}
        onSubmit={() => {}}
      >
        {({ values }) => {
          const isDisabled =
            values.bankAccounts?.reduce((acc, e) => {
              if (!acc) {
                return acc;
              }

              const match = virtualAccount?.bankAccounts?.find((e2) => e.id === e2.id);
              if (!match) {
                return (
                  (e.iban as any)?.value === '' || typeof (e.iban as any)?.value === 'undefined'
                );
              }
              return (e.iban as any)?.value === (match?.iban as any)?.value;
            }, true) ?? true;

          return (
            <Form>
              <Card>
                <Card.Header
                  title={intl.formatMessage({ id: 'banking' })}
                  wrapperClassName={classes.cardHeader}
                >
                  <FormattedMessage id="modify_bank_account" />
                </Card.Header>

                <Card.Body loadingContent={loading && !virtualAccount}>
                  <Grid container spacing={4}>
                    <FieldArray name="bankAccounts">
                      {() => (
                        <>
                          {values.bankAccounts.map((ba, index) => (
                            <>
                              <Grid item xs={6} key={`${ba.currency}_${index}`}>
                                <Field
                                  id={`bankAccounts.${index}.iban.value`}
                                  name={`bankAccounts.${index}.iban.value`}
                                  label={`${ba.currency} account`}
                                  labelDirection="column"
                                  disabled={isViewOnly}
                                  component={Input}
                                />
                              </Grid>
                              <Grid item xs={6}></Grid>
                            </>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </Card.Body>

                <Card.Footer>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="button"
                    sx={{ width: 'max-content' }}
                    disabled={isDisabled}
                    onClick={() =>
                      handleSubmit.execute(virtualAccount?.bankAccounts ?? [], values.bankAccounts)
                    }
                  >
                    {loading && !virtualAccount && (
                      <CircularProgress size={20} sx={{ marginRight: 3 }} />
                    )}
                    <FormattedMessage id="update_account" />
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          );
        }}
      </Formik>

      <ConfirmationDialog
        open={!!confirmation}
        strings={{
          title: intl.formatMessage({
            id: 'lsv_payments.confirmation.edit_iban_with_enabled_lsv',
          }),
          subtitle: intl.formatMessage({
            id: 'lsv_payments.confirmation.edit_iban_with_enabled_lsv.description',
          }),
        }}
        onClose={(result) => {
          setConfirmation(null);
          if (result) confirmation?.cb();
        }}
      />
    </>
  );
};

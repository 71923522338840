import { ChangeEvent, FocusEvent, forwardRef, useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { FieldProps } from 'formik';

import { usePasswordType } from './usePasswordType';

type Props = Partial<FieldProps<string>> & TextFieldProps;

export const TextInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { field, form, meta, ...restProps } = props;
  const name = field?.name ?? props.name;
  const value = field?.value ?? props.value;

  const errorMessage = meta?.error ?? form?.errors[name!];
  const isError = !!errorMessage ?? props.error;

  const { type, inputProps } = usePasswordType(props.type);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (field?.onChange) field.onChange(e);
      if (props?.onChange) props.onChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field?.onChange, props?.onChange],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (field?.onBlur) field.onBlur(e);
      if (props?.onBlur) props.onBlur(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field?.onBlur, props?.onBlur],
  );

  return (
    <TextField
      ref={ref}
      {...restProps}
      {...field}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      helperText={errorMessage ?? props.helperText}
      error={isError}
      InputProps={{
        ...props.InputProps,
        ...inputProps,
      }}
    />
  );
});

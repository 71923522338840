import { Box, Button, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { useSelectedInvestorAccount } from 'hooks';

const useStyles = makeStyles((theme) => ({
  blockedOverlay: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 24px 72px',
    background: alpha(theme.palette.background.default, 0.95),
    zIndex: 1,

    [theme.breakpoints.up('sm')]: {
      background: `linear-gradient(to top, ${alpha(
        theme.palette.background.default,
        0.95,
      )} 60%, ${alpha(theme.palette.background.default, 0.45)} 100%)`,
    },
  },
}));

interface Props {}

export const BlockedFromInvestingModal = (props: Props) => {
  const classes = useStyles();

  const { isBlockedFromInvesting } = useSelectedInvestorAccount();
  if (!isBlockedFromInvesting) return null;

  return (
    <Box className={classes.blockedOverlay}>
      <Typography variant="h6" fontWeight={500} marginBottom="8px">
        <FormattedMessage id="investor_account_blocked" />
      </Typography>

      <Typography marginBottom="40px">
        <FormattedMessage id="investor_account_blocked.help" />
      </Typography>

      <Button
        color="primary"
        onClick={() => window.open(`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`)}
      >
        <FormattedMessage id="contact_us.title" />
      </Button>
    </Box>
  );
};

import { ChangeEvent, useCallback, useState } from 'react';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import { dequal } from 'dequal';
import clsx from 'clsx';

import { useMapOptions } from './useMapOptions';
import { SCountChip, SLabelIcon } from './styled';
import { BaseSelectProps } from './types';

type Props<T> = BaseSelectProps<T> &
  Modify<
    TextFieldProps,
    {
      variant?: TextFieldProps['variant'] | 'filter';
      disableSelectedClass?: boolean;
    }
  >;

export const Select = <T,>(props: Props<T>) => {
  const {
    field,
    form,
    meta,
    variant,
    options,
    placeholder,
    disableSelectedClass,
    onChange,
    ...restProps
  } = props;

  const isMultiselect = props.SelectProps?.multiple ?? false;
  const fieldVariant = variant === 'filter' ? 'outlined' : variant;

  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (field?.onChange) field?.onChange(e);
      if (props.onChange) props.onChange(e);

      if (!isMultiselect) setOpen(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field?.onChange, props.onChange, isMultiselect],
  );

  const { name, list, flatOptions, valueArray, innerValue, innerValueArray } = useMapOptions(
    props,
    isMultiselect,
    handleChange,
  );

  const isEmpty = !valueArray.some((el) => el !== undefined);

  const renderPlaceholder = () => {
    if (isMultiselect) {
      if (valueArray.length) {
        return (
          <>
            <Typography variant="inherit" noWrap>
              {placeholder}
            </Typography>
            <SCountChip>{valueArray.length}</SCountChip>
          </>
        );
      }

      return (
        <Typography variant="inherit" noWrap>
          {placeholder}
        </Typography>
      );
    }

    if (!innerValueArray.length) {
      return (
        <Typography variant="inherit" noWrap>
          {placeholder}
        </Typography>
      );
    }

    const selectedOption = flatOptions.find((option) => {
      return dequal(option.value, innerValueArray[0]);
    });

    if (selectedOption) {
      const label = selectedOption.placeholder ?? selectedOption.label;

      if (selectedOption.icon) {
        return (
          <>
            <SLabelIcon>{selectedOption.icon}</SLabelIcon>
            <Typography variant="inherit" noWrap>
              {label}
            </Typography>
          </>
        );
      }

      return (
        <Typography variant="inherit" noWrap>
          {label}
        </Typography>
      );
    }

    return placeholder;
  };

  return (
    <TextField
      select
      variant={fieldVariant as any}
      {...restProps}
      {...field}
      name={name}
      value={innerValue ?? ''}
      SelectProps={{
        open: open,

        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),

        displayEmpty: true,
        renderValue: renderPlaceholder,
        ...restProps.SelectProps,

        className: clsx(
          restProps.SelectProps?.className,
          { 'variant-filter': variant === 'filter' },
          { 'value-selected': !disableSelectedClass && !isEmpty },
        ),

        MenuProps: {
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },

          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },

          ...restProps.SelectProps?.MenuProps,
          className: clsx('select-menu', restProps.SelectProps?.MenuProps?.className),
        },
      }}
    >
      {list}
    </TextField>
  );
};

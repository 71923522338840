import { Typography, styled, CSSObject } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { mixinObject, restrictProp } from 'utils';
import { DealStatusDto } from 'api/investor-client';

type TStatusType = 'Invest' | 'Active';

const getStatusType = (status: DealStatusDto): TStatusType => {
  const investType = [
    DealStatusDto.New,
    DealStatusDto.Invest,
    DealStatusDto.Committed,
    DealStatusDto.Confirm,
    DealStatusDto.Payout,
    DealStatusDto.Start,
  ];

  if (investType.includes(status)) return 'Invest';
  return 'Active';
};

const SChip = styled(Typography, {
  shouldForwardProp: restrictProp(['statusType']),
})<{ statusType: TStatusType }>(({ statusType, theme }) => ({
  display: 'inline-block',
  padding: '2px 8px',
  borderRadius: theme.shape.borderRadius,

  ...mixinObject<CSSObject>(statusType === 'Invest', {
    backgroundColor: '#FFF5E6',
    color: '#FD9E02',
  }),

  ...mixinObject<CSSObject>(statusType === 'Active', {
    backgroundColor: '#EAFBED',
    color: '#23A438',
  }),
}));

interface Props {
  status: DealStatusDto;
}

export const DealStatusChip = ({ status }: Props) => {
  return (
    <SChip statusType={getStatusType(status)} variant="subtitle3">
      <FormattedMessage id={`deal.status.${status}`} />
    </SChip>
  );
};

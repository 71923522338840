import { useRef, useMemo, useState } from 'react';
import { emphasize } from '@mui/material';
import type { ChartOptions, ChartDataset, ChartData, Plugin, DoughnutController } from 'chart.js';

import { getTotalRepaymentDetails } from 'utils';
import { EChartSection, TDataType } from './types';

type TRepaymentDetails = ReturnType<typeof getTotalRepaymentDetails>;

interface IChartData {
  id: EChartSection;
  value: number;
}

const repaymentDetailsAttributesMap: Record<EChartSection, keyof TRepaymentDetails> = {
  [EChartSection.Realized]: 'paid',
  [EChartSection.Pending]: 'pending',
  [EChartSection.Outstanding]: 'outstanding',
  [EChartSection.Delayed]: 'overdue',
  [EChartSection.Loss]: 'loss',
};

const colors = {
  [EChartSection.Realized]: '#23337D',
  [EChartSection.Pending]: '#FEC260',
  [EChartSection.Outstanding]: '#F6F6F6',
  [EChartSection.Delayed]: '#F09F86',
  [EChartSection.Loss]: '#BA1A1A',
};

export const legendColors = {
  ...colors,
  [EChartSection.Outstanding]: '#D9D9D9',
};

const addColor = (dataset: ChartDataset<'doughnut', any>, color: EChartSection) => {
  (dataset.backgroundColor as string[]).push(colors[color]);
  (dataset.borderColor as string[]).push(colors[color]);
  (dataset.hoverBackgroundColor as string[]).push(emphasize(colors[color], 0.15));
  (dataset.hoverBorderColor as string[]).push(emphasize(colors[color], 0.15));
};

const getRepaymentSectors = (data: TRepaymentDetails, type: TDataType) => {
  const order: EChartSection[] = [];
  const colors: EChartSection[] = [];

  if (data.loss[type] > 0) {
    order.push(EChartSection.Realized);
    colors.push(EChartSection.Outstanding);

    order.push(EChartSection.Loss);
    colors.push(EChartSection.Loss);
  } else if (data.overdue[type]) {
    order.push(EChartSection.Realized);
    colors.push(EChartSection.Realized);

    order.push(EChartSection.Delayed);
    colors.push(EChartSection.Delayed);

    order.push(EChartSection.Outstanding);
    colors.push(EChartSection.Outstanding);
  } else {
    order.push(EChartSection.Realized);
    colors.push(EChartSection.Realized);

    order.push(EChartSection.Pending);
    colors.push(EChartSection.Pending);

    order.push(EChartSection.Outstanding);
    colors.push(EChartSection.Outstanding);
  }

  return { order, colors };
};

export const useChartData = (data: TRepaymentDetails, type: TDataType) => {
  return useMemo(() => {
    const dataset: ChartDataset<'doughnut', IChartData[]> = {
      data: [],
      backgroundColor: [],
      borderColor: [],
      hoverBackgroundColor: [],
      hoverBorderColor: [],
    };

    const { order, colors } = getRepaymentSectors(data, type);

    for (const [index, dataType] of order.entries()) {
      const key = repaymentDetailsAttributesMap[dataType];
      dataset.data.push({ id: dataType, value: data[key][type] });
      addColor(dataset, colors[index]);
    }

    return {
      chartData: { datasets: [dataset] } as ChartData<'doughnut', IChartData[], unknown>,
      order,
      colorsType: colors,
    };
  }, [data, type]);
};

export const useOptions = (chartData: ChartData<'doughnut', IChartData[], unknown>) => {
  const INNER_OFFSET = 3;
  const OUTER_OFFSET = 6;

  const firstEntryIndex = chartData.datasets[0].data.findIndex((d) => d.value > 0);

  const [activeElement, setActiveElement] = useState<EChartSection | null>(() => {
    return chartData.datasets[0].data[firstEntryIndex]?.id ?? null;
  });

  const activeEl = useRef(firstEntryIndex ?? 0);

  const options = useRef<ChartOptions<'doughnut'>>({
    rotation: -90,
    circumference: 180,
    cutout: '80%',
    aspectRatio: 1.5,

    layout: {
      padding: OUTER_OFFSET,
    },

    elements: {
      arc: {
        borderWidth: 0,
      },
    },

    plugins: {
      tooltip: {
        enabled: false,
      },
    },

    parsing: {
      key: 'value',
    },

    onHover(event, elements, chart) {
      if (elements.length) {
        chart.canvas.style.cursor = 'pointer';
      } else {
        chart.canvas.style.cursor = 'initial';
      }
    },

    onClick(event, elements, chart) {
      const el = elements[0];
      if (!el) return;
      console.log((el.element as any)['$context'].raw.id);

      setActiveElement((el.element as any)['$context'].raw.id);
      activeEl.current = el.index;
    },
  });

  const plugins = useMemo(
    (): Plugin<'doughnut'>[] => [
      {
        id: 'custom',
        beforeDraw(chart, args, options) {
          const controller: DoughnutController = (chart as any)._metasets[0]?.controller;
          const arcs = controller._cachedMeta.data;

          const defaultInnerRadius = controller.innerRadius;
          const defaultOuterRadius = controller.outerRadius;

          const newInnerRadius = defaultInnerRadius - INNER_OFFSET;
          const newOuterRadius = defaultOuterRadius + OUTER_OFFSET;

          for (const [index, arc] of arcs.entries()) {
            const arcInnerRadius = (arc as any).innerRadius as number;
            const arcOuterRadius = (arc as any).outerRadius as number;

            if (index === activeEl.current) {
              if (arcInnerRadius !== newInnerRadius || arcOuterRadius !== newOuterRadius) {
                // @ts-ignore: private method
                controller.updateElement(
                  arc,
                  index,
                  { innerRadius: newInnerRadius, outerRadius: newOuterRadius },
                  'active',
                );
              }
            } else if (
              arcInnerRadius !== defaultInnerRadius ||
              arcOuterRadius !== defaultOuterRadius
            ) {
              // @ts-ignore: private method
              controller.updateElement(
                arc,
                index,
                { innerRadius: defaultInnerRadius, outerRadius: defaultOuterRadius },
                'active',
              );
            }
          }
        },
      },
    ],
    [],
  );

  return {
    activeElement,
    chartOptions: {
      data: chartData,
      options: options.current,
      plugins,
    },
  };
};

import { FocusEvent, useCallback, useMemo } from 'react';
import { FieldProps } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { withFormLabel } from './forms/withFormLabel';
import { Grid } from '@mui/material';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error' | 'onChange'> & {
    error?: boolean;
    min?: number;
    max?: number;
    onChange: (id: string, value: string | number) => void;
    leftId: string;
    rightId: string;
    dividedby100?: boolean;
    multipliedby100?: boolean;
  };

const InputComponent = ({
  disabled,
  multiline,
  field,
  min,
  max,
  error = false,
  form,
  leftId,
  rightId,
  dividedby100,
  multipliedby100,
  onBlur,
  onChange,
  ...props
}: Props): JSX.Element => {
  const [handleInValue, handleOutValue] = useMemo(() => {
    const divideValue = (value: any) => {
      if (!value) return value;
      return value / 100;
    };
    const multiplyValue = (value: any) => {
      if (!value) return value;
      return value * 100;
    };
    if (dividedby100) return [divideValue, multiplyValue];
    if (multipliedby100) return [multiplyValue, divideValue];
    return [(value: any) => value, (value: any) => value];
  }, [dividedby100, multipliedby100]);

  const [leftValue, rightValue] = useMemo(
    () => [handleInValue(form.values[leftId]), handleInValue(form.values[rightId])],
    [handleInValue, leftId, rightId, form.values],
  );

  const onInputChange = useCallback(
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(id, handleOutValue(e.target.value));
    },
    [handleOutValue, onChange],
  );

  return (
    <Grid container direction="row" wrap="nowrap">
      <TextField
        disabled={disabled}
        error={error}
        size="small"
        variant="outlined"
        inputProps={{
          min,
          max,
        }}
        {...field}
        {...props}
        value={leftValue ?? ''}
        onChange={onInputChange(leftId)}
        onBlur={onBlur}
        sx={{
          width: '100px',
          '& input': { padding: '6px 16px' },
        }}
        InputProps={{
          ...props.InputProps,
        }}
      />
      <TextField
        disabled={disabled}
        error={error}
        size="small"
        variant="outlined"
        inputProps={{
          min,
          max,
        }}
        {...field}
        {...props}
        value={rightValue ?? ''}
        onChange={onInputChange(rightId)}
        onBlur={onBlur}
        sx={{
          width: '100px',
          marginLeft: '-1px',
          '& input': { padding: '6px 16px' },
        }}
        InputProps={{
          ...props.InputProps,
        }}
      />
    </Grid>
  );
};

export const RangeInput = withFormLabel(({ field, form, leftId, rightId, ...props }: Props) => {
  const onChange = useCallback(
    (id: string, value: string | number) => {
      if (form.setFieldValue) form.setFieldValue(id, value);
      if (props.onChange) props.onChange(id, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.setFieldValue, props.onChange],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (field.onBlur) field.onBlur(e);
      if (props.onBlur) props.onBlur(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onBlur, props.onBlur],
  );

  return (
    <InputComponent
      field={field}
      form={form}
      {...props}
      leftId={leftId}
      rightId={rightId}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
});

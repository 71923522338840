import { Box, Button, Collapse, IconButton, Theme, Tooltip, Typography, alpha, useMediaQuery } from '@mui/material';
// import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import { CurrencyIcon, ProductTypeChip, RiskChip, InlineTooltip } from 'components/UI';
import {
  DurationDisplay,
  // ExpirationTimer
} from 'components';
import {
  useFormatMoney,
  // useLink,
  useSelectedInvestorAccount
} from 'hooks';
import { DealRating, DealType } from 'types';

import { useTableRowStyles } from '../styles';
import { moveDecimalPoint } from 'utils';
import { SecondaryInvestment, ShareSaleStatus } from 'types/secondary-market';
import { useCallback, useState } from 'react';
import theme from 'config/theme';
import { RiArrowDownSFill } from 'react-icons/ri';

interface Props {
  record: SecondaryInvestment;
  tableLayout?: boolean;
  onCancelSell?: (arg0: SecondaryInvestment) => void;
}

const useCardViewStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },
  },

  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 16px 24px',
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
  },

  expirationTimer: {
    fontWeight: 500,
  },

  accordionBtn: {
    height: 'fit-content',
    marginTop: '-8px',
    marginRight: '-4px',
    transition: theme.transitions.create(['transform'], {
      duration: 150,
    }),

    '&.expanded': {
      transform: 'rotate(180deg)',
    },
  },

  chipsGroup: {
    display: 'flex',
    gap: '8px',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    padding: '24px 16px',
    backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexBasis: '40%',
    overflow: 'hidden',
  },

  textGroupHeader: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textTransform: 'uppercase',
  },

  tooltip: {
    color: alpha(theme.palette.text.secondary, 0.36),
    fontSize: '16px',
    lineHeight: '16px',
  },

  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px 16px 16px',
  },
}));

const SecondaryInvestmentRecordCardView = (props: Props) => {
  const { record: shareSell, onCancelSell } = props;

  const classes = useCardViewStyles();
  const intl = useIntl();
  // const getLink = useLink();
  const formatMoney = useFormatMoney({ prefix: `${shareSell.shareWithDeal.deal?.currency} ` });

  const [expanded, setExpanded] = useState(false);

  const { isViewOnly } = useSelectedInvestorAccount();

  const onClick = useCallback(() => {
    if (onCancelSell) onCancelSell(shareSell)
  }, [shareSell, onCancelSell])

  const isTabletSize = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'), { noSsr: true });
  const isExpanded = isTabletSize ? true : expanded;

  return (
    <Box className={classes.card}>
      {/* <Box component={Link} to={getLink(`investments/${shareSell.id}`, 'continue-url')}> */}
      <Box>
        <Box className={classes.cardHeader}>
          <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
            {/* <Box display="flex"> */}
            {/* <Typography variant="subtitle3" color="text.secondary" noWrap>
                <ExpirationTimer
                  expiredAt={shareSell.expiresAt}
                  className={classes.expirationTimer}
                />
              </Typography> */}

            {/* <Typography variant="subtitle3" color="text.secondary" fontWeight={400} noWrap>
                {'\u00a0'}
                <FormattedMessage id="left" />
              </Typography> */}
            {/* </Box> */}

            {!isTabletSize && (
              <Box>
                <IconButton
                  size="small"
                  className={clsx(classes.accordionBtn, { expanded: isExpanded })}
                  onClick={(e) => {
                    e.preventDefault();
                    setExpanded((state) => !state);
                  }}
                >
                  <RiArrowDownSFill />
                </IconButton>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Box sx={{ flexGrow: 1, textDecoration: 'underline', overflow: 'hidden' }}>
              <Typography variant="subtitle2" noWrap>
                {shareSell.shareWithDeal.id + '_' + shareSell.id}
              </Typography>
            </Box>

            <Box className={classes.chipsGroup}>
              <CurrencyIcon currency={shareSell.shareWithDeal.deal?.currency} size={24} />
              <RiskChip rating={shareSell.shareWithDeal.deal?.risk.rating as DealRating} />
              <ProductTypeChip productType={shareSell.shareWithDeal.deal?.risk.productType as DealType} />
            </Box>
          </Box>
        </Box>

        <Collapse in={isExpanded}>
          <Box className={classes.cardBody}>
            <Box className={classes.row}>
              <Box className={classes.textGroup}>
                <Box display="flex" alignItems="center" gap="8px">
                  <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                    <FormattedMessage id="nominal_value" />
                  </Typography>

                  <InlineTooltip
                    tooltip={intl.formatMessage({ id: 'nominal_value.description_tooltip' })}
                    className={classes.tooltip}
                  />
                </Box>

                <Box display="flex" gap="0px 4px" alignItems="center" flexWrap="wrap">
                  <Typography variant="subtitle2" noWrap>
                    {formatMoney(moveDecimalPoint(shareSell.shareSale.saleAmount, -2))}
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.textGroup}>
                <Box display="flex" alignItems="center" gap="8px">
                  <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                    <FormattedMessage id="interest" />
                  </Typography>

                  <InlineTooltip
                    tooltip={intl.formatMessage({ id: 'interest.description_tooltip' })}
                    className={classes.tooltip}
                  />
                </Box>

                <Box display="flex" gap="0 4px" alignItems="center" flexWrap="wrap">
                  <Typography variant="subtitle2" noWrap>
                    {`+ ${(shareSell.shareWithDeal.deal.nominalInterestRate * 100).toFixed(2)}%`}
                  </Typography>

                  <Typography variant="body2" color="text.secondary" noWrap>
                    ({formatMoney(moveDecimalPoint(shareSell.shareSale.potentialInterest, -2))})
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={classes.row}>
              <Box className={classes.textGroup} sx={{ flexBasis: '100% !important' }}>
                <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                  <FormattedMessage id="terms_left" />
                </Typography>

                <DurationDisplay deal={shareSell.shareWithDeal.deal} size="normal" />
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Box className={classes.cardFooter}>
        <Box className={classes.row}>
          <Box className={classes.textGroup} sx={{ flexBasis: '100% !important' }}>
            <Box display="flex" alignItems="center" gap="8px">
              <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                <FormattedMessage id="selling_price" />
              </Typography>

              <InlineTooltip
                tooltip={intl.formatMessage({ id: 'selling_price.description_tooltip' })}
                className={classes.tooltip}
              />
            </Box>

            <Box display="flex" gap="0 8px" alignItems="center" flexWrap="wrap">
              <Typography variant="subtitle2" noWrap>
                {formatMoney(moveDecimalPoint(shareSell.shareSale.latestCalculatedPrice, -2))}
              </Typography>
            </Box>
          </Box>
        </Box>

        {!isViewOnly && shareSell.shareSale.status === ShareSaleStatus.Open && (
          <Button
            fullWidth
            variant="outlined"
            color="error"
            onClick={onClick}
            sx={{
              borderWidth: '1px',
              "&:hover": {
                borderWidth: '1px',
                borderColor: theme.palette.error.main,
              }
            }}
          >
            <Typography variant="subtitle3">
              <FormattedMessage id="cancel" />
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

const useRowViewStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    overflow: 'hidden',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
      '& #submitBtn': {
        display: 'block',
      }
    },

  },

  tooltip: {
    maxWidth: 'fit-content',
  },

  linkContainer: {
    display: 'flex',
    flexGrow: 1,
    cursor: 'pointer',
    width: '87%'
  },

  rowWrapper: {
    width: '100%',
    display: 'flex',
  },

  cellDivider: {
    borderLeft: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
  },

  submitBtn: {
    display: 'none',
    minWidth: 'auto',
    padding: '8px 16px',
    borderWidth: '1px',
    '&:hover': {
      borderWidth: '1px',
    }
  },
}));

const SecondaryInvestmentRecordRowView = (props: Props) => {
  const { record: shareSell, onCancelSell } = props;

  const tableRowClasses = useTableRowStyles();
  const classes = useRowViewStyles();
  const intl = useIntl();
  // const getLink = useLink();
  const formatMoney = useFormatMoney({ prefix: `${shareSell.shareWithDeal.deal?.currency} ` });

  const { isViewOnly } = useSelectedInvestorAccount();

  // const [isHovered, setIsHovered] = useState(false);

  // const onHover = useCallback(() => {
  //   if (!isViewOnly) {
  //     setIsHovered(true);
  //   }
  // }, [isViewOnly]);

  // const onUnhover = useCallback(() => {
  //   setIsHovered(false);
  // }, []);

  // const onClick = useCallback(() => {
  //   if (isHovered && onCancelSell) {
  //     onCancelSell(shareSell)
  //   }
  // }, [shareSell, onCancelSell, isHovered])

  const onClick = useCallback(() => {
    if (onCancelSell) {
      onCancelSell(shareSell)
    }
  }, [shareSell, onCancelSell])

  return (
    <Box className={classes.card}>
      <Tooltip
        title={`${intl.formatMessage({ id: 'investment_id' })}: ${shareSell.shareWithDeal.id + '_' + shareSell.id}`}
        componentsProps={{
          tooltip: { className: classes.tooltip },
          popper: { modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] },
        }}
        followCursor
      >
        {/* <Link
          to={getLink(`investments/${shareSell.id}`, 'continue-url')} */}
        <Box
          className={classes.linkContainer}
        >
          <Box className={classes.rowWrapper}>
            <Box
              className={clsx(tableRowClasses.cell, tableRowClasses.idCell)}
              sx={{ textDecoration: 'underline' }}
            >
              <Typography variant="subtitle3" noWrap>
                {shareSell.shareWithDeal.id + '_' + shareSell.id}
              </Typography>
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.riskCell, classes.cellDivider)}>
              <RiskChip rating={shareSell.shareWithDeal.deal?.risk.rating as DealRating} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.productTypeCell)}>
              <ProductTypeChip productType={shareSell.shareWithDeal.deal?.risk.productType as DealType} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.timeLeftCell)}>
              <DurationDisplay deal={shareSell.shareWithDeal.deal} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.irrCell)}>
              <Typography variant="subtitle3" noWrap flexShrink={0}>
                {`+ ${+(shareSell.shareWithDeal.deal.nominalInterestRate * 100).toFixed(2)}%\u00a0`}
              </Typography>
              <Typography variant="body2" noWrap>
                ({formatMoney(moveDecimalPoint(shareSell.shareSale.potentialInterest ?? 0, -2))})
              </Typography>
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.priceCell)}>
              <Typography variant="subtitle3" noWrap>
                {formatMoney(moveDecimalPoint(shareSell.shareSale.saleAmount ?? 0, -2))}
              </Typography>
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.priceCell)}>
              <Typography variant="subtitle3" noWrap>
                {formatMoney(moveDecimalPoint(shareSell.shareSale.latestCalculatedPrice ?? 0, -2))}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* </Link> */}
      </Tooltip>
      <Box className={clsx(tableRowClasses.cell, tableRowClasses.inputCell)}>
        {!isViewOnly && shareSell.shareSale.status === ShareSaleStatus.Open && (
          <Button
            // onMouseEnter={onHover}
            // onMouseLeave={onUnhover}
            variant="outlined"
            // color={isHovered ? 'error' : 'primary'}
            color="error"
            onClick={onClick}
            sx={{
              borderWidth: '1px',
              width: '107px',
              "&:hover": {
                borderWidth: '1px',
                borderColor: theme.palette.error.main,
              }
            }}
          >
            <Typography variant="subtitle3">
              <FormattedMessage id="cancel" />
            </Typography>
            {/* {isHovered
            ? <Typography variant="subtitle3">
              <FormattedMessage id="cancel" />
            </Typography>
            : <ExpirationTimer expiredAt={shareSell.expiresAt} />
          } */}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export const SecondaryInvestmentRecord = (props: Props) => {
  const ViewComponent = props.tableLayout ? SecondaryInvestmentRecordRowView : SecondaryInvestmentRecordCardView;
  return <ViewComponent {...props} />
};

import { useEffect, useState } from 'react';
import { alpha, Badge, Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useInvestorAccounts } from 'providers/investor-accounts';
import { useNotificationsProvider } from 'providers/notifications';
import { useUserDetails } from 'providers/user-details';

import { ANIMATION_SPEED, EXPANDED_WIDTH } from '../constants';
import { ESidebarSize } from '../types';
import { FooterMenu } from './FooterMenu/FooterMenu';
import { Avatar } from './Avatar';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 32px 16px 80px',
    borderTop: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
    transition: theme.transitions.create(['padding'], {
      duration: ANIMATION_SPEED,
    }),

    '&.desktop': {
      alignItems: 'flex-start',
      padding: '16px 16px',
    },

    '&.collapsed': {
      padding: '16px 6px',
    },
  },

  investorWrapper: {
    height: '88px',
    display: 'flex',
    alignItems: 'center',

    '&.desktop': {
      height: '104px',
      width: '100%',
    },
  },

  investorContainer: {
    maxWidth: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '16px',
    padding: '8px',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },

    '&.desktop': {
      width: '100%',
    },
  },

  notificationBadge: {
    '& .MuiBadge-badge': {
      minWidth: '16px',
      height: '16px',
      padding: '0 4px',
      backgroundColor: theme.palette.error.main,
      fontWeight: 400,
    },
  },

  investorCredentials: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: theme.transitions.create(['opacity'], {
      duration: 150,
    }),

    '&.desktop': {
      // fix width so text would not jump while collapsing
      // = "full width" - "padding from .footer" - "padding from .investorContainer" - "avatar width" - ".investorContainer gap"
      width: `calc(${EXPANDED_WIDTH}px - 32px - 16px - 40px - 16px)`,
      flexShrink: 0,
    },

    '&.collapsed': {
      opacity: 0,
    },
  },

  investorName: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    maxHeight: '72px',
    overflow: 'hidden',
  },

  investorId: {
    lineHeight: '16px',
    color: theme.palette.common.lightBlue,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  copyright: {
    maxWidth: '100%',
    height: '0px',
    padding: '0px',
    color: alpha(theme.palette.common.white, 0.15),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: theme.transitions.create(['height', 'padding', 'opacity'], {
      duration: ANIMATION_SPEED,
    }),

    '&.collapsed': {
      opacity: 0,
    },

    '&.desktop': {
      height: 'auto',
      padding: '16px 0 16px 8px',
    },
  },
}));

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
  onSidebarClose?: () => void;
}

export const SidebarFooter = (props: Props) => {
  const { collapsed, sidebarSize, onSidebarClose } = props;
  const classes = useStyles();
  const investorAccountsContext = useInvestorAccounts();
  const { userDetails } = useUserDetails();
  const { notificationMetadata } = useNotificationsProvider();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (collapsed && anchorEl) setAnchorEl(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  const selectedInvestor = investorAccountsContext.selectedAccount?.investor;
  const investorName = selectedInvestor?.name ?? '';
  const investorId = selectedInvestor?.id ?? '';

  const isDesktop = sidebarSize === ESidebarSize.Desktop;
  const isCollapsed = collapsed && isDesktop;

  return (
    <Box className={clsx(classes.footer, { collapsed: isCollapsed }, { desktop: isDesktop })}>
      <Box className={clsx(classes.investorWrapper, { desktop: isDesktop })}>
        <Box
          className={clsx(classes.investorContainer, { desktop: isDesktop })}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Badge
            badgeContent={!!anchorEl ? undefined : notificationMetadata.unread}
            color="error"
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={classes.notificationBadge}
          >
            <Avatar user={userDetails} />
          </Badge>

          <Tooltip title={`${investorName}`}>
            <Box
              className={clsx(
                classes.investorCredentials,
                { desktop: isDesktop },
                { collapsed: isCollapsed },
              )}
            >
              <Typography className={classes.investorName}>{investorName}</Typography>
              <Typography variant="body2" className={classes.investorId}>
                {investorId}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <FooterMenu
        collapsed={collapsed}
        sidebarSize={sidebarSize}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSidebarClose={onSidebarClose}
      />

      <Typography
        className={clsx(classes.copyright, { collapsed: isCollapsed }, { desktop: isDesktop })}
      >
        © {new Date().getFullYear()} | Loantrade AG
      </Typography>
    </Box>
  );
};

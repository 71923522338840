import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  typographyClasses,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import { ISecondaryBasketDeal, useBasketProvider } from 'providers/basket';
import { Currency } from 'types';

import { PaymentSummary } from './PaymentSummary';
import { BalanceDto } from 'api/investor-client';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { useFormatMoney } from 'hooks';
import { DataOverviewListRow } from 'components/deals/DataOverviewList';
import { moveDecimalPoint } from 'utils';
import { MarketType } from 'types/secondary-market';
import { UseAsyncReturn } from 'react-async-hook';

interface Props {
  onSubmit(): void;
  submitDisabled?: boolean;
  submitLoading?: boolean;
  agreementsReady: boolean;
  uploadMissingAgreements?: UseAsyncReturn<void, []>;
  type: MarketType;
}

const useStyles = makeStyles(() => ({
  paymentDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },

  submitButton: {
    width: '100%',
    margin: '24px 0 24px',
  },
}));

const getAmountByCurrency = <D extends number | undefined>(
  balances: BalanceDto[],
  currency: Currency,
  drawback?: D,
): D extends number ? number : number | undefined => {
  const balance = balances.find((balance: BalanceDto) => {
    return balance.currency === currency;
  });

  return (balance?.amount ?? drawback) as any;
};

export const PaymentsDetails =
  ({
     onSubmit,
     submitDisabled,
     agreementsReady,
     uploadMissingAgreements,
     submitLoading,
     type,
   }: Props) => {
    const classes = useStyles();
  const intl = useIntl();
  const basket = useBasketProvider();
  const { selectedAccount } = useInvestorAccounts();
  const formatMoney = useFormatMoney();
  const [agreementsChecked, setAgreementsChecked] = useState<boolean>(false);
  const rows = useMemo((): Record<Currency, DataOverviewListRow[]> => {
    const basketTotal: Record<string, number> = {};
    const discountsTotal: Record<string, number> = {};
    const feesTotal: Record<string, number> = {};
    const priceTotal: Record<string, number> = {};
    const accruedInterestsTotal: Record<string, number> = {};

    for (const deal of basket[type].deals) {
      if (!basketTotal[deal.currency]) basketTotal[deal.currency] = 0;
      if (!discountsTotal[deal.currency]) discountsTotal[deal.currency] = 0;
      if (!feesTotal[deal.currency]) feesTotal[deal.currency] = 0;
      if (!priceTotal[deal.currency]) priceTotal[deal.currency] = 0;
      if (!accruedInterestsTotal[deal.currency]) accruedInterestsTotal[deal.currency] = 0;

      basketTotal[deal.currency] += deal.amount;
      discountsTotal[deal.currency] += (deal as ISecondaryBasketDeal).multiplierAmount ?? 0;
      feesTotal[deal.currency] += (deal as ISecondaryBasketDeal).buyerFeeAmount ?? 0;
      priceTotal[deal.currency] += (deal as ISecondaryBasketDeal).latestCalculatedPrice ?? 0;
      accruedInterestsTotal[deal.currency] += (deal as ISecondaryBasketDeal).accruedInterest ?? 0;
    }

    return [Currency.CHF, Currency.EUR].reduce((acc, currency) => {
      const amountInBasket = basketTotal[currency] ?? 0;
      const discountsInBasket = discountsTotal[currency] ?? 0;
      const feesInBasket = feesTotal[currency] ?? 0;
      const priceInBasket = (priceTotal[currency] + feesInBasket)?? 0;
      const accruedInterestsInBasket = accruedInterestsTotal[currency] ?? 0;

      if (!amountInBasket) {
        return acc;
      }
      let value: DataOverviewListRow[] = [];
      if (type === 'secondary') {
        value = [
          {
            title: intl.formatMessage({ id: 'investment.amount' }),
            value: formatMoney(moveDecimalPoint(amountInBasket, -2)),
            variant: 'header',
          },
          {
            title: intl.formatMessage({ id: 'discounts_and_premiums' }),
            value: formatMoney(moveDecimalPoint(discountsInBasket, -2)),
          },
          {
            title: intl.formatMessage({ id: 'accrued_interest' }),
            value: formatMoney(moveDecimalPoint(accruedInterestsInBasket, -2)),
          },
          {
            title: intl.formatMessage({ id: 'service_fee' }),
            value: formatMoney(moveDecimalPoint(feesInBasket, -2)),
          },
          {
            title: intl.formatMessage({ id: 'total' }),
            value: formatMoney(moveDecimalPoint(priceInBasket, -2)),
            variant: 'header',
          },
        ]
      } else {
        const {
          VABalance = [],
          unpaid = [],
          pendingPayins = [],
          pendingPayouts = [],
        } = selectedAccount ?? {};

        const unpaidAmount = -getAmountByCurrency(unpaid, currency, 0);
        const totalToPay = amountInBasket + unpaidAmount;

        const pendingPayin = getAmountByCurrency(pendingPayins, currency, 0);
        const pendingPayout = getAmountByCurrency(pendingPayouts, currency, 0);
        const pendingAmount = -(pendingPayin + pendingPayout);

        const balance = getAmountByCurrency(VABalance, currency, 0);
        const amountToDeduct = totalToPay + pendingAmount;
        const deductedAmount = -Math.max(Math.min(balance, amountToDeduct), 0);
        const remainingAmount = Math.max(amountToDeduct + deductedAmount, 0);
        value = [
          {
            title: intl.formatMessage({ id: 'investment.new_amount' }),
            value: formatMoney(moveDecimalPoint(amountInBasket, -2)),
            variant: 'header',
          },
          {
            title: intl.formatMessage({ id: 'investments.unpaid' }),
            value: formatMoney(moveDecimalPoint(unpaidAmount, -2)),
          },
          {
            title: intl.formatMessage({ id: 'investment.total_amount' }),
            value: formatMoney(moveDecimalPoint(totalToPay, -2)),
            variant: 'header',
          },
          {
            title: intl.formatMessage({ id: 'pending_payments' }),
            value: formatMoney(moveDecimalPoint(pendingAmount, -2)),
          },
          {
            title: intl.formatMessage({ id: 'balance.deduct_from_current' }),
            value: formatMoney(moveDecimalPoint(deductedAmount, -2)),
          },
          {
            title: intl.formatMessage({ id: 'amount.unpaid.amount' }),
            value: formatMoney(moveDecimalPoint(remainingAmount, -2), {
              prefix: remainingAmount > 0 ? `${currency} ` : '',
            }),
            divider: true,
            variant: 'summary',
          },
        ]
      }

      return {
        ...acc,
        [currency]: value
      }
    }, {} as Record<Currency, DataOverviewListRow[]>)
  }, [selectedAccount, basket, type, intl, formatMoney]);
  return (
    <Box>
      <Box className={classes.paymentDetailsWrapper}>
        {!!rows[Currency.CHF] && (
          <PaymentSummary
            currency={Currency.CHF}
            rows={rows[Currency.CHF]}
          />
        )}
        {!!rows[Currency.EUR] && (
          <PaymentSummary
            currency={Currency.EUR}
            rows={rows[Currency.EUR]}
          />
        )}
      </Box>

      {uploadMissingAgreements && !agreementsReady &&
        <Button
          size='large'
          variant='contained'
          className={classes.submitButton}
          onClick={() => {
            uploadMissingAgreements.execute();
            setAgreementsChecked(false);
          }}
          disabled={agreementsReady || submitDisabled || uploadMissingAgreements.loading}
        >
          {uploadMissingAgreements.loading ? (
            <CircularProgress size='20px' color='inherit' />
          ) : (
            <FormattedMessage id={'investor_agreements.load_drafts'} />
          )}
        </Button>
      }
      {agreementsReady && uploadMissingAgreements &&
        <FormControlLabel
          sx={{ margin: '24px 0 0 0' }}
          slotProps={{typography: {variant: 'caption'}}}
          control={<Checkbox
            checked={agreementsChecked}
            disabled={!agreementsReady || submitDisabled}
            onChange={(e) => setAgreementsChecked(!agreementsChecked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label={<FormattedMessage id={'investor_agreements.i_have_read'} />}
        />}
      {(agreementsReady || !uploadMissingAgreements) && <Button
        size='large'
        variant='contained'
        className={classes.submitButton}
        onClick={async () => onSubmit()}
        disabled={submitDisabled || (!agreementsChecked && !!uploadMissingAgreements)}
      >
        {submitLoading ? (
          <CircularProgress size='20px' color='inherit' />
        ) : (
          <FormattedMessage id={type === MarketType.primary ? 'commit_to_invest' : 'buy'} />
        )}
      </Button>}

      <Typography
        component="p"
        variant="caption"
        color="text.secondary"
        fontWeight={300}
        textAlign="justify"
      >
        {agreementsReady && <FormattedMessage id={type === MarketType.primary ? 'basket_review.legal_notice' : 'basket_review.secondary.legal_notice'} />}
      </Typography>
    </Box>
  );
};

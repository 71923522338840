import { FocusEvent, ReactElement, useCallback, useMemo } from 'react';
import ReactSelect, {
  Props as ReactSelectProps,
  GroupBase,
} from 'react-select';
import { FieldProps, useField } from 'formik';

import { withFormLabel } from '../../forms/withFormLabel';

type Option = { label: ReactElement; value: string };

type Props = FieldProps &
  ReactSelectProps<Option> & {
    error?: boolean;
    disabled?: boolean;
  };

export const Select = withFormLabel(
  ({ disabled, error, placeholder, ...props }: Props) => {
    const [field, , { setValue, setTouched }] = useField(props.field.name);

    const onBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (field.onBlur) field.onBlur(e);
        if (props.onBlur) props.onBlur(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field.onBlur, props.onBlur],
    );

    const selected = useMemo(() => {
      const value = field.value as string;
      if (!props.options || !value) return null;
      let selected: Option | null = null;

      for (const option of props.options) {
        let exists: Option | null = null;

        if (Array.isArray((option as GroupBase<Option>)['options'])) {
          const el = option as GroupBase<Option>;
          exists = el.options.find((el) => el.value === field.value) ?? null;
        } else {
          const el = option as Option;
          if (el.value === field.value) exists = el;
        }

        if (exists) selected = exists;
      }

      return selected;
    }, [field.value, props.options]);

    return (
      // @ts-ignore
      <ReactSelect<Option>
        isDisabled={disabled}
        {...field}
        {...props}
        value={selected}
        onChange={(newValue) => {
          setValue(newValue?.value ?? '', true);
        }}
        onBlur={(e) => {
          onBlur(e);
          setTouched(true, true);
        }}
      />
    );
  },
);

import { useKycProvider } from 'providers/kyc';
import { Box, Grid, Typography } from '@mui/material';
import { ContactUs } from './ContactUs';
import { CompanyKyc } from './CompanyKyc';
import { PrivateKyc } from './PrivateKyc';
import { FormattedMessage } from 'react-intl';

export const KycTab = () => {
  const { kycRecord } = useKycProvider();
  const condition =
    (kycRecord?.beneficialOwners?.length ?? 0) > 1 || kycRecord?.type === 'BusinessKycRecord';

  return (
    <Box display="flex" justifyContent={'center'} sx={{minHeight: '75vh'}}>
      <Grid container md={condition ? 8 : 6} flexDirection={'column'}>
        <Typography variant="h6" margin="56px 0 16px 0">
          <FormattedMessage id="kyc_details" />
        </Typography>
        <Typography variant="body2" marginBottom="24px">
          <FormattedMessage id="kyc_details_overview" />
        </Typography>

        <ContactUs />

        {kycRecord?.type === 'BusinessKycRecord' && <CompanyKyc kycRecord={kycRecord} />}
        {kycRecord?.type === 'PrivateKycRecord' && <PrivateKyc kycRecord={kycRecord} />}
      </Grid>
    </Box>
  );
};

import { Badge, Box, emphasize, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCloseLine, RiMenuLine } from 'react-icons/ri';
import clsx from 'clsx';

import { useNotificationsProvider } from 'providers/notifications';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    left: '16px',
    bottom: '16px',
    zIndex: 1500,
  },

  fab: {
    '&.active': {
      backgroundColor: theme.palette.common.lightBlue,
      color: theme.palette.primary.dark,

      '&:hover': {
        backgroundColor: emphasize(theme.palette.common.lightBlue, 0.15),
      },
    },
  },

  notificationBadge: {
    '& .MuiBadge-badge': {
      minWidth: '16px',
      height: '16px',
      padding: '0 4px',
      margin: '5px',
      backgroundColor: theme.palette.error.main,
      fontWeight: 400,
      zIndex: 1501,
    },
  },
}));

interface Props {
  active: boolean;
  onClick: () => void;
}

export const MenuFab = ({ active, onClick }: Props) => {
  const classes = useStyles();
  const { notificationMetadata } = useNotificationsProvider();

  const Icon = active ? RiCloseLine : RiMenuLine;

  return (
    <Box className={classes.container}>
      <Badge
        badgeContent={active ? undefined : notificationMetadata.unread}
        color="error"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.notificationBadge}
      >
        <Fab className={clsx(classes.fab, { active: active })} onClick={() => onClick()}>
          <Icon size={24} />
        </Fab>
      </Badge>
    </Box>
  );
};

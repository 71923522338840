import { useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { useIntl, FormattedDate } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { CellProps, Column } from 'react-table';
import { useHistory } from 'react-router';

import { DealEventDto } from 'api/investor-client';
import { investorApiClient } from 'api';
import { useInvestorAccounts } from 'providers/investor-accounts';
import Card from 'components/card';
import { Table } from 'components';
import { DealEventsToShow } from 'utils';

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 600,
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',

    '& .MuiTableContainer-root': {
      maxHeight: '196px',

      '& th,td': {
        fontSize: '12px',
        padding: '10px 16px',
      },
    },
  },
}));

export const InvestmentActivity = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const investorAccountsContext = useInvestorAccounts();

  const { result: events = [], loading } = useAsync(async () => {
    const { data } = await investorApiClient.investors.investorApiControllerGetInvestorEvents({
      types: DealEventsToShow,
    });

    return data;
  }, []);

  const columns = useMemo((): Column<DealEventDto>[] => {
    return [
      {
        Header: <span className={classes.header}>{intl.formatMessage({ id: 'date' })}</span>,
        accessor: 'createdDate',
        Cell: function Cell({ value }) {
          return <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />;
        },
      },
      {
        Header: <span className={classes.header}>{intl.formatMessage({ id: 'id' })}</span>,
        accessor: 'json.investmentId' as 'json',
        Cell: function Cell({ row: { original } }: CellProps<DealEventDto>) {
          let json = original.json as any;
          return json.investmentId || `${original.providerId}_${original.dealId}`;
        },
      },
      {
        Header: <span className={classes.header}>{intl.formatMessage({ id: 'milestone' })}</span>,
        accessor: 'type',
        Cell: function Cell({ value }) {
          return intl.formatMessage({ id: `milestone.${value}` });
        },
      },
    ];
  }, [intl, classes.header]);

  return (
    <Card>
      <Card.Header variant="condensed" title={intl.formatMessage({ id: 'activity_investment' })} />
      <Card.Body loadingContent={loading}>
        <Table<DealEventDto>
          className={classes.table}
          columns={columns}
          data={events}
          sortable={false}
          withPagination={false}
          rowOnClick={(e, row) => {
            e.stopPropagation();

            const clickable =
              row.original.json?.investmentId ||
              (investorAccountsContext?.selectedAccount?.investor?.id &&
                row.original?.providerId &&
                row.original?.dealId);

            const investmentId =
              clickable &&
              (row.original.json?.investmentId ||
                `${row.original?.providerId}_${row.original?.dealId}_${investorAccountsContext?.selectedAccount?.investor?.id}`);

            clickable && history.push(`portfolio/primary/investments/${investmentId}`);
          }}
          rowUrl={(row) => `portfolio/primary/investments/${row.original.json?.investmentId}`}
        />
      </Card.Body>
    </Card>
  );
};

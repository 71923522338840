import React, { useEffect, useMemo, useState } from 'react';
import { FieldProps } from 'formik';
import { Box, FormHelperText, InputLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

type UnknownEvent = (event: unknown) => unknown | undefined;

type LabelDirection = 'row' | 'column';

interface DecoratedField {
  onFocus?(event: unknown): unknown;
  onBlur?(event: unknown): unknown;
}

interface IStyleProps {
  readonly direction: LabelDirection;
}

type Props = Partial<FieldProps> & {
  label?: string;
  labelDirection?: LabelDirection;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  warningMessage?: string;
  labelClassName?: string;
};

const useStyles = makeStyles<Theme, IStyleProps>({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: (props) => props.direction,
    justifyContent: (props) => (props.direction === 'row' ? 'space-between' : 'initial'),
    alignItems: (props) => (props.direction === 'row' ? 'center' : 'initial'),
  },
  label: {
    fontSize: '14px',
  },
});

export function withFormLabel<T extends Partial<FieldProps>>(
  FormComponent: React.ComponentType<T>,
) {
  return function WrappedWithFormComponent({
    label,
    labelDirection = 'column',
    error = false,
    form,
    field,
    disabled,
    warningMessage,
    labelClassName,
    ...props
  }: Props & T): JSX.Element {
    const classes = useStyles({ direction: labelDirection });
    const [focused, setFocused] = useState(false);
    const [onBlur, setOnBlur] = useState<UnknownEvent>();
    const [onFocus, setOnFocus] = useState<UnknownEvent>();

    const decorated = useMemo(() => (field as unknown as DecoratedField) ?? {}, [field]);

    decorated.onFocus = (event?: unknown) => {
      if (!event) return;

      setFocused(true);
      if (onFocus) onFocus(event);
    };

    decorated.onBlur = (event?: unknown) => {
      if (!event) return;

      setFocused(false);
      if (onBlur) onBlur(event);
    };

    useEffect(() => {
      setOnBlur(decorated.onBlur);
      setOnFocus(decorated.onFocus);
    }, [decorated]);

    const errorMessage = useMemo(() => {
      return form && field && (form.touched[field.name] || form.submitCount > 0)
        ? form.errors[field.name]
        : undefined;
    }, [form, field]);

    return (
      <Box>
        <Box className={classes.container}>
          {!!label && (
            <InputLabel
              required={props.required}
              error={error || !!errorMessage}
              focused={focused}
              htmlFor={field?.name}
              classes={{
                root: classes.label,
              }}
              disabled={disabled}
              className={labelClassName}
            >
              {label}
            </InputLabel>
          )}

          {/* @ts-ignore */}
          <FormComponent
            field={field}
            form={form}
            // For some components overrides above are just enough,
            // but some components are ignoring focus unless specified as here:
            // @ts-ignore
            onFocus={decorated.onFocus}
            onBlur={decorated.onBlur}
            error={error || !!errorMessage}
            disabled={disabled}
            {...props}
          />
        </Box>
        {(warningMessage || errorMessage) && (
          <FormHelperText className={errorMessage ? 'error' : 'warn'}>
            <FormattedMessage id={(errorMessage || warningMessage) as string} />
          </FormHelperText>
        )}
      </Box>
    );
  };
}

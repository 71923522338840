import { SortOrder } from 'types';
import { EPrimaryDealSortOption } from './types';

export const primaryDealSortVariantsMap = {
  [EPrimaryDealSortOption.PublicationDate]: {
    [SortOrder.ASC]: { sortBy: 'publication.publishDate', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'publication.publishDate', sortOrder: SortOrder.DESC },
  },

  [EPrimaryDealSortOption.Currency]: {
    [SortOrder.ASC]: { sortBy: 'deal.currency', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.currency', sortOrder: SortOrder.DESC },
  },

  [EPrimaryDealSortOption.IRR]: {
    [SortOrder.ASC]: { sortBy: 'fullRepaymentPlan.expectedXirr', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'fullRepaymentPlan.expectedXirr', sortOrder: SortOrder.DESC },
  },

  [EPrimaryDealSortOption.Duration]: {
    [SortOrder.ASC]: { sortBy: 'deal.duration', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.duration', sortOrder: SortOrder.DESC },
  },

  [EPrimaryDealSortOption.Amount]: {
    [SortOrder.ASC]: { sortBy: 'ledgerDeal.dealTotalAmount', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'ledgerDeal.dealTotalAmount', sortOrder: SortOrder.DESC },
  },

  [EPrimaryDealSortOption.Rating]: {
    [SortOrder.ASC]: { sortBy: 'risk.rating', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'risk.rating', sortOrder: SortOrder.DESC },
  },

  [EPrimaryDealSortOption.ProductType]: {
    [SortOrder.ASC]: { sortBy: 'risk.productType', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'risk.productType', sortOrder: SortOrder.DESC },
  },
};

import { PropsWithChildren, memo } from 'react';

import { useSelectedInvestorAccount } from 'hooks';

import { useMarketTypeBasket } from './useMarketTypeBasket';
import { BasketContext } from './context';
import {
  EMarketTypeBasket,
  IBaseBasketDealIdentifier,
  IPrimaryBasketDeal,
  ISecondaryBasketDeal,
} from './types';

interface Props {}

export const BasketProvider = memo(({ children }: PropsWithChildren<Props>) => {
  const { selectedAccount } = useSelectedInvestorAccount();
  const investorId = selectedAccount?.investor?.id ?? null;

  const primary = useMarketTypeBasket<IBaseBasketDealIdentifier, IPrimaryBasketDeal>(
    EMarketTypeBasket.Primary,
    investorId,
  );

  const secondary = useMarketTypeBasket<IBaseBasketDealIdentifier, ISecondaryBasketDeal>(
    EMarketTypeBasket.Secondary,
    investorId,
  );

  return <BasketContext.Provider value={{ primary, secondary }}>{children}</BasketContext.Provider>;
});

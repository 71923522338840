import { trimObject } from 'utils';
import { SecondaryDealFilterValues, SecondaryDealFilterPayload } from './types';
import { FilterTransformHelpers } from '../types';

class SecondaryDealFiltersHelper
  implements FilterTransformHelpers<SecondaryDealFilterPayload, SecondaryDealFilterValues>
{
  getEmptyValue = (): SecondaryDealFilterValues => {
    return {
      productType: [],
      rating: [],
      currency: [],
    };
  };

  getPayloadFromValue = (values: SecondaryDealFilterValues): SecondaryDealFilterPayload => {
    return trimObject({
      productType: values.productType?.length ? values.productType : undefined,
      rating: values.rating?.length ? values.rating : undefined,
      currency: values.currency?.length ? values.currency : undefined,
      sortBy: values.sort?.sortBy,
      sortOrder: values.sort?.sortOrder,
    });
  };

  getValueFromPayload = (
    payload: Partial<SecondaryDealFilterPayload>,
  ): SecondaryDealFilterValues => {
    return trimObject({
      productType: Array.isArray(payload.productType) ? payload.productType : [],
      rating: Array.isArray(payload.rating) ? payload.rating : [],
      currency: Array.isArray(payload.currency) ? payload.currency : [],

      // prettier-ignore
      sort: payload.sortBy && payload.sortOrder
        ? { sortBy: payload.sortBy, sortOrder: payload.sortOrder }
        : undefined,
    });
  };

  countValues = (values: SecondaryDealFilterValues) => {
    let counter = 0;

    counter += values.currency?.length ?? 0;
    counter += values.productType?.length ?? 0;
    counter += values.rating?.length ?? 0;

    return counter;
  };
}

export const secondaryDealFiltersHelper = new SecondaryDealFiltersHelper();

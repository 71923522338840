import { Button } from '@mui/material';

import { UseAsyncReturn } from 'react-async-hook';
import { FormattedMessage } from 'react-intl';
import { RiDownload2Line, RiFile2Line } from 'react-icons/ri';
import theme from 'config/theme';


interface Props {
  downloadAgreement: UseAsyncReturn<void, []>;
  size?: 'medium' | 'large';
}

export const AgreementDownloadChip = ({ downloadAgreement, size = 'medium' }: Props) => {
  return (

    <Button
      variant="filter"
      startIcon={<RiFile2Line />}
      endIcon={<RiDownload2Line color={theme.palette.primary.main}/>}
      onClick={downloadAgreement.execute}
      disabled={downloadAgreement.loading}
    >
      <FormattedMessage id="investor_agreements.contract" />
    </Button>
  );
};

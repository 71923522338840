import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiArrowRightSLine } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { useInvestorAccounts } from 'providers/investor-accounts';
import { ESidebarSize } from 'components/sidebar/types';
import { MenuFooter } from './MenuFooter';
import { MenuHeader } from './MenuHeader';

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'relative',
    margin: 0,
    padding: 0,
    outline: 0,
    listStyle: 'none',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '16px',
  },

  menuItem: {
    display: 'flex',
    gap: '16px',
    padding: '8px 24px',
    color: theme.palette.secondary.dark,
    textTransform: 'capitalize',

    '&.submenu': {
      justifyContent: 'space-between',
      fontWeight: 500,
      color: theme.palette.text.primary,

      '& > p': {
        fontWeight: 500,
      },
    },
  },

  notificationBadge: {
    height: '24px',
    minWidth: '24px',
    textAlign: 'center',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: '2px 6px',
    borderRadius: '32px',
  },
  comingSoon: {
    fontSize: '12px',
    borderRadius: '4px',
    border: '1px solid #546878',
    padding: '2px 4px',
  },
}));

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
  onClose?: () => void;
  onSidebarClose?: () => void;
  onAccountMenuSelect?: () => void;
}

export const MainSubmenu = (props: Props) => {
  const { collapsed, sidebarSize, onClose, onSidebarClose, onAccountMenuSelect } = props;

  const classes = useStyles();
  const { url } = useRouteMatch();
  const { logout } = useAuth0();

  const { accounts } = useInvestorAccounts();

  const handleLinkClick = () => {
    onClose?.();
    onSidebarClose?.();
  };

  return (
    <>
      <MenuHeader collapsed={collapsed} sidebarSize={sidebarSize} onClose={onClose} />

      <Box component="ul" className={classes.body}>
        {accounts.length > 1 && (
          <MenuItem
            className={clsx(classes.menuItem, 'submenu')}
            onClick={() => onAccountMenuSelect?.()}
          >
            <Typography>
              <FormattedMessage id="switch_account" />
            </Typography>
            <RiArrowRightSLine size={24} />
          </MenuItem>
        )}

        <Link to={`${url}/account-balance`} onClick={handleLinkClick}>
          <MenuItem className={classes.menuItem}>
            <Typography>
              <FormattedMessage id="account_balance" />
            </Typography>
          </MenuItem>
        </Link>

        <MenuItem className={classes.menuItem} disabled>
          <FormattedMessage id="documents" />
          <span className={classes.comingSoon}>SOON</span>
        </MenuItem>

        <Link to={`${url}/settings`} onClick={handleLinkClick}>
          <MenuItem className={classes.menuItem}>
            <Typography>
              <FormattedMessage id="account_settings" />
            </Typography>
          </MenuItem>
        </Link>

        <MenuItem
          className={classes.menuItem}
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <Typography>
            <FormattedMessage id="log_out" />
          </Typography>
        </MenuItem>
      </Box>

      <MenuFooter collapsed={collapsed} sidebarSize={sidebarSize} />
    </>
  );
};

import { useEffect, useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import { IDealDto } from 'api/investor-client';
import { useFormatMoney, useTextResize } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { ExpectedRepayments } from 'types';

import { useCardStyles } from '../styles';
import { InlineTooltip } from 'components/UI';

const useStyles = makeStyles((theme) => ({
  irrChip: {
    display: 'inline-block',
    padding: '2px 6px',
    backgroundColor: '#EAF3FB',
    borderRadius: theme.shape.borderRadius,
  },

  irrChipText: {
    display: 'inline-block',
    fontWeight: 500,
  },

  amountWrapper: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },

  amount: {
    display: 'inline',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },

  tooltipIcon: {
    marginLeft: '8px',
  },
}));

interface Props {
  deal: IDealDto;
  expectedRepaymentsInfo: ExpectedRepayments | null | undefined;
}

export const ExpectedReturnCard = ({ deal, expectedRepaymentsInfo }: Props) => {
  const cardClasses = useCardStyles();
  const classes = useStyles();
  const intl = useIntl();

  const formatMoney = useFormatMoney({ prefix: `${deal.currency} ` });
  const expectedInterest = expectedRepaymentsInfo?.expectedInterest ?? 0;

  let interestFee = useMemo(() => {
    const pricingInfo = expectedRepaymentsInfo?.pricingInfo.value as Record<string, string>;
    const interestFeeStr = pricingInfo?.interestFee;
    if (!interestFeeStr) return null;

    const interestFee = Number(interestFeeStr);
    if (!interestFee || Number.isNaN(interestFee)) return null;

    return `${+(interestFee * 100).toFixed(2)}%`;
  }, [expectedRepaymentsInfo?.pricingInfo.value]);

  const { refText, refContent, recalculate } = useTextResize(16, 28);
  useEffect(() => recalculate(), [expectedInterest, deal.currency, recalculate]);

  return (
    <Card variant="outlined" className={cardClasses.card}>
      <CardContent className={cardClasses.cardContent}>
        <Typography variant="body2" color="text.secondary" marginBottom="16px">
          <FormattedMessage id="expected_return" />
          <InlineTooltip
            tooltip={intl.formatMessage({ id: 'deal_details.expected_return.tooltip' })}
            size={16}
            className={classes.tooltipIcon}
          />
        </Typography>

        <Box ref={refContent} className={classes.amountWrapper}>
          <Typography ref={refText} variant="h5" className={classes.amount}>
            {formatMoney(moveDecimalPoint(expectedInterest, -2))}
          </Typography>
        </Box>

        {interestFee && (
          <Typography variant="body2" fontWeight={300} marginBottom="24px">
            <FormattedMessage
              id="service_fee_will_be_applied_later"
              values={{ amount: interestFee }}
            />
          </Typography>
        )}

        <Box className={classes.irrChip}>
          <Typography variant="body2" className={classes.irrChipText} color={'#37A1E4'}>
            <FormattedMessage id="irr.max_net" />:
          </Typography>{' '}
          <Typography variant="body2" className={classes.irrChipText}>
            {((expectedRepaymentsInfo?.xirr || 0) * 100).toFixed(2)}%
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

import React, { PropsWithChildren } from 'react';
import { Paper, Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CardHeader } from './CardHeader';
import { CardBody } from './CardBody';
import { FormattedMessage } from 'react-intl';

interface Item {
  key: string;
  value: string;
}
export interface Props {
  header?: React.ReactNode;
  items: Array<Item>;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: '8px',
    border: '1px solid var(--light-state-layers-outline-opacity-36, rgba(114, 119, 127, 0.36))',
    background: 'var(--colors-white, #FFF)',
    padding: '24px 24px 16px 24px',
    flexWrap: 'unset',
  },
  row: {
    padding: '16px 0',
  },
  lastRow: {
    borderBottom: `1px solid ${theme.palette.text.secondary016}`,
  },
  key: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 400,
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'right',
  },
}));

export const Card = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={clsx(classes.paper)}>
      <CardHeader title={props.header} />
      <CardBody>
        {props.items.map(({ key, value }, idx) => (
          <Grid
            container
            className={clsx({
              [classes.row]: true,
              [classes.lastRow]: idx < props.items.length - 1,
            })}
          >
            <Grid xs={12} sm={6} md={6} lg={6}>
              {key && (
                <Typography className={classes.key}>
                  <FormattedMessage id={key} />
                </Typography>
              )}
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6}>
              <Typography className={classes.value}>{value}</Typography>
            </Grid>
          </Grid>
        ))}
      </CardBody>
    </Paper>
  );
};

import { Link, Typography, Box, alpha, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const SContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '88px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 16px',
  backgroundColor: '#1A4063',
  color: theme.palette.common.white,
}));

interface Props {}

export const ActivateDDInfo = (props: Props) => {
  return (
    <SContainer>
      <Typography>
        <FormattedMessage
          id="activate.debit_direct"
          values={{
            aSup: (msg: string) => (
              <Link
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={(theme) => ({
                  color: theme.palette.common.lightBlue,
                  textDecorationColor: alpha(theme.palette.common.lightBlue, 0.4),
                })}
              >
                {msg}
              </Link>
            ),
          }}
        />
      </Typography>
    </SContainer>
  );
};

import { useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  alpha,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiArrowDownSFill, RiCloseLine } from 'react-icons/ri';
import { Field, Form, FormikContext, useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { InlineBadge } from 'components/UI';
import { InlineSelect } from 'components/forms';
import { withDialog } from 'wrappers';
import { SortOrder } from 'types';

import { SortButton } from '../SortButton';
import { useCommonFiltersStyles } from '../useCommonFiltersStyles';
import { useProductTypeOptions, useRatingOptions, useCurrencyOptions } from '../options';
import { CommonFilterModalProps } from '../types';
import { secondaryDealFiltersHelper } from './helpers';
import { primaryDealSortVariantsMap } from './constants';
import { SecondaryDealFilterValues, ESecondaryDealSortOption } from './types';

const useStyles = makeStyles((theme) => ({
  closeButtonContainer: {
    position: 'absolute',
    top: '12px',
    right: '12px',
  },

  body: {
    padding: '24px 24px 0px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '4px',
  },

  sortContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  sortGroup: {
    display: 'flex',
    gap: '16px',
    padding: '0 16px',
    flexWrap: 'wrap',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    borderTop: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
    boxShadow: `0px 2px 6px 0px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

type Props = CommonFilterModalProps<SecondaryDealFilterValues>;

export const SecondaryDealFilterModal = withDialog((props: Props) => {
  const { initialValues, withSort, onClear, onApply, onClose } = props;

  const commonFiltersStyles = useCommonFiltersStyles();
  const classes = useStyles();
  const intl = useIntl();

  const productTypeOptions = useProductTypeOptions(intl);
  const ratingOptions = useRatingOptions();
  const currencyOptions = useCurrencyOptions();

  const handleSubmit = (values: SecondaryDealFilterValues) => {
    onApply?.(values);
    onClose?.();
  };

  const formik = useFormik<SecondaryDealFilterValues>({
    initialValues: initialValues ?? secondaryDealFiltersHelper.getEmptyValue(),
    onSubmit: handleSubmit,
  });

  const getSortLabel = useCallback(
    (type: ESecondaryDealSortOption) => {
      switch (type) {
        case ESecondaryDealSortOption.PublicationDate:
          return intl.formatMessage({ id: 'publication_date' });

        case ESecondaryDealSortOption.Currency:
          return intl.formatMessage({ id: 'currency' });

        case ESecondaryDealSortOption.Price:
          return intl.formatMessage({ id: 'deal_price' });

        case ESecondaryDealSortOption.PriceDiscount:
          return intl.formatMessage({ id: 'discount_premium' });

        case ESecondaryDealSortOption.NominalValue:
          return intl.formatMessage({ id: 'nominal_value' });

        case ESecondaryDealSortOption.IRR:
          return intl.formatMessage({ id: 'interest' });

        case ESecondaryDealSortOption.ExpirationDate:
          return intl.formatMessage({ id: 'offer_expires_in' });

        case ESecondaryDealSortOption.Rating:
          return intl.formatMessage({ id: 'rating' });

        case ESecondaryDealSortOption.ProductType:
          return intl.formatMessage({ id: 'type' });

        default:
          return '';
      }
    },
    [intl],
  );

  const handleClear = () => {
    formik.resetForm({ values: secondaryDealFiltersHelper.getEmptyValue() });
    onClear?.();
  };

  const totalFiltersCount = secondaryDealFiltersHelper.countValues(formik.values);

  return (
    <Box>
      <Box className={classes.closeButtonContainer}>
        <IconButton onClick={onClose}>
          <RiCloseLine />
        </IconButton>
      </Box>

      <FormikContext.Provider value={formik}>
        <Form className={classes.body}>
          <Box className={classes.title}>
            <Typography variant="subtitle1" fontWeight={500}>
              <FormattedMessage id="all_filters" />
            </Typography>

            {totalFiltersCount > 0 && <InlineBadge label={totalFiltersCount} />}
          </Box>

          <Typography variant="body2" color="text.secondary" marginBottom="32px">
            <FormattedMessage id="select_filters_to_apply" />
          </Typography>

          {withSort && (
            <Accordion className={commonFiltersStyles.accordion} elevation={0}>
              <AccordionSummary expandIcon={<RiArrowDownSFill />}>
                <Typography>
                  <FormattedMessage id="sort_by" />
                </Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.sortContainer}>
                {Object.entries(primaryDealSortVariantsMap).map(([key, value]) => {
                  return (
                    <Box key={key} className={classes.sortGroup}>
                      <SortButton
                        label={getSortLabel(key as ESecondaryDealSortOption)}
                        value={value[SortOrder.ASC]}
                      />

                      <SortButton
                        label={getSortLabel(key as ESecondaryDealSortOption)}
                        value={value[SortOrder.DESC]}
                        defaultSelect={key === ESecondaryDealSortOption.PublicationDate}
                      />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="borrower.type" />
              </Typography>

              {formik.values.productType.length > 0 && (
                <InlineBadge label={formik.values.productType.length} />
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Field
                id="productType"
                name="productType"
                component={InlineSelect}
                options={productTypeOptions}
                multiple
                className={commonFiltersStyles.inlineSelect}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="loan_currency" />
              </Typography>

              {formik.values.currency.length > 0 && (
                <InlineBadge label={formik.values.currency.length} />
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Field
                id="currency"
                name="currency"
                component={InlineSelect}
                options={currencyOptions}
                multiple
                className={commonFiltersStyles.inlineSelect}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="risk_type" />
              </Typography>

              {formik.values.rating.length > 0 && (
                <InlineBadge label={formik.values.rating.length} />
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Field
                id="rating"
                name="rating"
                component={InlineSelect}
                options={ratingOptions}
                multiple
                className={commonFiltersStyles.inlineSelect}
              />
            </AccordionDetails>
          </Accordion>
        </Form>
      </FormikContext.Provider>

      <Paper className={classes.footer}>
        <Button variant="text" onClick={handleClear}>
          <FormattedMessage id="filters.clear" />
        </Button>

        <Button onClick={() => handleSubmit(formik.values)}>
          <FormattedMessage id="filters.apply" />
        </Button>
      </Paper>
    </Box>
  );
});

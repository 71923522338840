import React, { useMemo, useEffect, useCallback } from 'react';
import { useAsync } from 'react-async-hook';
import { makeStyles } from '@mui/styles';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useAsyncDebounce, CellProps } from 'react-table';
import { Grid } from '@mui/material';
import { isEmpty, isNil } from 'ramda';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import Card from 'components/card';
import { paymentsApiClient } from 'api';
import { useQuery } from 'hooks';
import { Table, ExportButton } from 'components';
import {
  InvestorApiControllerGetAccountTransactionsParams,
  TransactionDto,
} from 'api/payments-client';
import {
  toMoneyNumberWithDecimals,
  downloadCSV,
  convertTableDataToCsvContent,
} from 'utils';
import { Total } from './Total';
import { TableFilters } from './TableFilters';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type Props = {
  currency: string;
};

type TransactionPopulated = TransactionDto & {
  bookingDate?: Date;
  transferDueDate?: Date;
  repaymentId?: string;
  investmentId?: string;
  class?: string;
  principal?: number;
  income?: number;
  expenses?: number;
  type?: string;
};

enum TransactionClassSlug {
  Principal = 'Principal',
  Interest = 'Interest',
  Fee = 'Fee',
}

const mapTransactionClassToColumn = (tr: TransactionPopulated) => {
  if (tr.class?.includes(TransactionClassSlug.Principal)) return 'principal';
  if (tr.class?.includes(TransactionClassSlug.Interest)) return 'income';
  if (tr.class?.includes(TransactionClassSlug.Fee)) return 'expenses';
  return;
};

const mapTransactionClassToMultiplier = (tr: TransactionPopulated) => {
  if (tr.class?.includes(TransactionClassSlug.Fee)) return 1;
  return -1;
};

type TransactionsType = { [key: string]: any } & TransactionDto;

const useStyles = makeStyles(() => ({
  table: {
    '& th': {
      fontWeight: 'bold !important',
    },
  },
  exportButton: {
    position: 'absolute',
    top: 22,
    right: 16,
  },
}));

export const TransactionsTable = ({ currency }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    parsedQuery,
    parsedFilters: initialFilters,
    pageIndex,
    pageSize,
    sort: initialSort,
    pushQuery,
    push,
    convertFiltersToQuery,
  } = useQuery({ defaultPageSize: 25 });

  const { result: transactions = [], loading } = useAsync(async () => {
    const { data } =
      await paymentsApiClient.api.investorApiControllerGetAccountTransactions({
        currency,
        pageSize: 100000,
        pageIndex: 0,
      } as InvestorApiControllerGetAccountTransactionsParams);
    const items = data.items
      // put tags to object properties
      ?.map(
        (item) =>
          ({
            ...item,
            status: 'PAID',
          } as TransactionPopulated),
      )
      // combine transactions that relates to same repayment
      .reduce((acc, tr) => {
        // check if it is repayment
        const fieldToSetAmount = mapTransactionClassToColumn(tr);
        const multiplier = mapTransactionClassToMultiplier(tr);
        tr.amount = tr.amount * multiplier;
        if (!tr.repaymentId || !tr.investmentId || !fieldToSetAmount) {
          tr.principal = tr.amount;
          return [...acc, tr];
        }
        // find same repayment in accumulator
        const existingTrIndex = acc.findIndex(
          (accTr) =>
            accTr.repaymentId === tr.repaymentId &&
            accTr.investmentId === tr.investmentId,
        );
        // if no same repayment, add to acc and set amout to needed column
        if (existingTrIndex === -1) {
          return [...acc, { ...tr, [fieldToSetAmount]: tr.amount }];
        }
        // update existing repayment by adding amount to needed column and to prev amount
        const updatedAcc: TransactionPopulated[] = [...acc];
        updatedAcc[existingTrIndex].amount += tr.amount;
        updatedAcc[existingTrIndex][fieldToSetAmount] =
          (updatedAcc[existingTrIndex][fieldToSetAmount] ?? 0) + tr.amount;
        return updatedAcc;
      }, [] as TransactionPopulated[]);
    return items;
  }, [currency]);

  useEffect(() => {
    if (isEmpty(parsedQuery)) {
      pushQuery({ pageSize, pageIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        type: 'date',
        Header: intl.formatMessage({ id: 'pay_date' }),
        accessor: 'bookingDate',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          if (!value) return '';
          return (
            <FormattedDate
              value={value}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          );
        },
      },
      {
        type: 'date',
        Header: intl.formatMessage({ id: 'due_date' }),
        accessor: 'transferDueDate',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          if (!value) return '';
          return (
            <FormattedDate
              value={value}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          );
        },
      },
      {
        Header: intl.formatMessage({ id: 'annuity' }),
      },
      {
        Header: intl.formatMessage({ id: 'status' }),
        accessor: 'status',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          return <FormattedMessage id="investment.status.transferred" />;
        },
      },
      {
        Header: intl.formatMessage({ id: 'type' }),
        accessor: 'type',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          if (!value) return '';
          return <FormattedMessage id={`transaction_type.${value}`} />;
        },
      },
      {
        Header: intl.formatMessage({ id: 'category' }),
        accessor: 'method',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          if (!value) return '';
          return <FormattedMessage id={`transaction_method.${value}`} />;
        },
      },
      {
        Header: intl.formatMessage({ id: 'investment' }),
        accessor: 'investmentId',
      },
      {
        Header: intl.formatMessage({ id: 'borrower.type' }),
        accessor: 'productType',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          if (!value) return '';
          return <FormattedMessage id={`borrower.${value}`} />;
        },
      },
      {
        Header: intl.formatMessage({ id: 'rating' }),
        accessor: 'rating',
      },
      {
        handler: toMoneyNumberWithDecimals,
        Header: intl.formatMessage({ id: 'amount.booking' }),
        accessor: 'amount',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          return <b>{toMoneyNumberWithDecimals(value)}</b>;
        },
      },
      {
        handler: toMoneyNumberWithDecimals,
        Header: intl.formatMessage({ id: 'principle' }),
        accessor: 'principal',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          return <b>{toMoneyNumberWithDecimals(value)}</b>;
        },
      },
      {
        handler: toMoneyNumberWithDecimals,
        Header: intl.formatMessage({ id: 'income' }),
        accessor: 'income',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          return <b>{toMoneyNumberWithDecimals(value)}</b>;
        },
      },
      {
        show: false,
        Header: intl.formatMessage({ id: 'expenses' }),
        accessor: 'expenses',
        Cell: function Cell({ value }: CellProps<TransactionsType>) {
          return <b>{toMoneyNumberWithDecimals(value)}</b>;
        },
      },
    ],
    [intl],
  );

  const handleFiltersChange = useCallback(
    (data: {
      filters?: Record<string, string | number | string[]>;
      sort?: Array<{ id: string; desc?: boolean }>;
      pageSize?: number;
      pageIndex?: number;
    }) => {
      let sort = {};

      if (data.sort?.length ?? initialSort[0]) {
        sort = convertFiltersToQuery({ sort: data.sort ?? initialSort });
      }

      const query = {
        ...(data.filters ?? initialFilters),
        ...sort,
        pageSize: data.pageSize ?? pageSize,
        pageIndex: data.pageIndex ?? pageIndex,
      };

      push(query);
    },
    [
      convertFiltersToQuery,
      initialSort,
      initialFilters,
      pageSize,
      pageIndex,
      push,
    ],
  );

  const onFiltersChangeDebounced = useAsyncDebounce(handleFiltersChange, 500);

  const onFiltersChange = useCallback(
    (f) => {
      delete (f as Partial<typeof f>).filters;
      handleFiltersChange(f);
    },
    [handleFiltersChange],
  );

  const handleFiltersClear = useCallback(() => {
    pushQuery({ pageSize: 25, pageIndex: 0 });
  }, [pushQuery]);

  const transactionsFiltered = useMemo(() => {
    if (isEmpty(initialFilters)) return transactions;
    let filteredTransactions = [...transactions];
    const {
      minBookingDate,
      maxBookingDate,
      minTransferDueDate,
      maxTransferDueDate,
      ...rest
    } = initialFilters;
    if (minBookingDate) {
      filteredTransactions = filteredTransactions.filter((tr) => {
        if (!tr.bookingDate) return false;
        const bookingDate = dayjs(tr.bookingDate);
        return bookingDate.isSameOrAfter(minBookingDate as string, 'date');
      });
    }
    if (maxBookingDate) {
      filteredTransactions = filteredTransactions.filter((tr) => {
        if (!tr.bookingDate) return false;
        const bookingDate = dayjs(tr.bookingDate);
        return bookingDate.isSameOrBefore(maxBookingDate as string, 'date');
      });
    }
    if (minTransferDueDate) {
      filteredTransactions = filteredTransactions.filter((tr) => {
        if (!tr.transferDueDate) return false;
        const dueDate = dayjs(tr.transferDueDate);
        return dueDate.isSameOrAfter(minTransferDueDate as string, 'date');
      });
    }
    if (maxTransferDueDate) {
      filteredTransactions = filteredTransactions.filter((tr) => {
        if (!tr.transferDueDate) return false;
        const dueDate = dayjs(tr.transferDueDate);
        return dueDate.isSameOrBefore(maxTransferDueDate as string, 'date');
      });
    }
    if (!isEmpty(rest)) {
      Object.entries(rest).forEach(([key, value]) => {
        filteredTransactions = filteredTransactions.filter((tr) => {
          if (isNil(tr[key as keyof TransactionPopulated])) return false;
          return tr[key as keyof TransactionPopulated] === value;
        });
      });
    }
    return filteredTransactions;
  }, [transactions, initialFilters]);

  const transactionsSorted = useMemo(() => {
    if (!initialSort?.[0]?.id) return transactionsFiltered;
    const id = initialSort[0].id as keyof TransactionPopulated;
    const desc = initialSort[0].desc;
    const sortedTransactions = [...transactionsFiltered].sort((a, b) => {
      if (id === ('amount' as keyof TransactionPopulated)) {
        return (a.amount ?? 0) - (b.amount ?? 0);
      }
      if (isNil(a[id])) return -1;
      if (isNil(b[id])) return 1;
      if (id === 'bookingDate' || id === 'transferDueDate') {
        return +new Date(a[id]!) - +new Date(b[id]!);
      }
      if (id === 'principal' || id === 'income' || id === 'expenses') {
        return a[id]! - b[id]!;
      }
      return (a[id] as string).localeCompare(b[id] as string);
    });
    return desc ? sortedTransactions : sortedTransactions.reverse();
  }, [transactionsFiltered, initialSort]);

  const transactionsPaginated = useMemo(
    () =>
      transactionsSorted.slice(
        pageIndex * pageSize,
        (pageIndex + 1) * pageSize,
      ),
    [transactionsSorted, pageIndex, pageSize],
  );

  const { totalBookingAmount, totalPrincipal, totalIncome, totalExpenses } =
    useMemo(
      () =>
        transactionsPaginated.reduce(
          (acc, val) => ({
            totalBookingAmount: acc.totalBookingAmount + (val.amount ?? 0),
            totalPrincipal: acc.totalPrincipal + (val.principal ?? 0),
            totalIncome: acc.totalIncome + (val.income ?? 0),
            totalExpenses: acc.totalExpenses + (val.expenses ?? 0),
          }),
          {
            totalBookingAmount: 0,
            totalPrincipal: 0,
            totalIncome: 0,
            totalExpenses: 0,
          },
        ),
      [transactionsPaginated],
    );

  const availableInvestmentIds = useMemo(
    () =>
      Array.from(
        new Set(
          transactions.reduce(
            (acc, tr) => (tr.investmentId ? [...acc, tr.investmentId] : acc),
            [] as string[],
          ),
        ),
      ),
    [transactions],
  );

  const exportData = useCallback(
    (data) => {
      downloadCSV(
        convertTableDataToCsvContent(data, columns),
        `${dayjs().format('DDMMYYYY')}_marketplace_transactions_export`,
      );
    },
    [columns],
  );

  return (
    <>
      <ExportButton
        className={classes.exportButton}
        onExportCurrent={() => exportData(transactionsSorted)}
        onExportFull={() => exportData(transactions)}
        loading={false}
        exportAllMessage="export_all_transactions"
      />
      <Card sx={{ marginBottom: 4 }}>
        <Card.Body loadingContent={loading}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Total
                amount={totalBookingAmount}
                intlName="amount.booking"
                grey
              />
            </Grid>
            <Grid item xs={3}>
              <Total amount={totalPrincipal} intlName="principle" />
            </Grid>
            <Grid item xs={3}>
              <Total amount={totalIncome} intlName="income" />
            </Grid>
            <Grid item xs={3}>
              <Total amount={totalExpenses} intlName="expenses" />
            </Grid>
          </Grid>
        </Card.Body>
      </Card>
      <Card sx={{ marginBottom: 4 }}>
        <Card.Body>
          <TableFilters
            initialFilters={initialFilters}
            filtersOptions={{
              investments: availableInvestmentIds,
            }}
            onChange={onFiltersChangeDebounced}
            onClearFilters={handleFiltersClear}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body loadingContent={loading}>
          <Table<TransactionsType>
            className={classes.table}
            size="small"
            initialFilters={[]}
            initialSort={initialSort}
            pageSize={pageSize}
            pageIndex={pageIndex}
            onFiltersChange={onFiltersChange}
            data={transactionsPaginated}
            columns={columns}
            count={transactions.length}
            noRowsIntlName={
              Object.keys(initialFilters).length
                ? 'no_entries_match_filter'
                : undefined
            }
          />
        </Card.Body>
      </Card>
    </>
  );
};

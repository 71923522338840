import { ComponentType, useCallback, useMemo, useState } from 'react';
import { Box, Button, Divider, Fade, Theme, alpha, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik, FormikContext, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncCallback } from 'react-async-hook';
import { RiFilter3Line, RiShareBoxLine } from 'react-icons/ri';
import clsx from 'clsx';

import { SubmitFormOnChange } from 'components';
import { InlineBadge } from 'components/UI';
import {
  CommonFilterModalProps,
  CommonFilterValues,
  FilterTransformHelpers,
  IFilterSort,
} from 'components/filters';
import { InvestmentStatusFilterOption } from 'components/filters/primary-investment';
import { Radio } from 'components/forms';
import { ShareSaleStatus } from 'types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },

  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '32px',
    padding: '16px 0',

    '&.table-layout': {
      padding: '16px 0 22px',
    },
  },

  filterGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    flexWrap: 'wrap',
  },

  sortGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',

    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },

  count: {
    marginRight: '8px',
    fontWeight: 500,
    color: alpha(theme.palette.text.secondary, 0.38),
  },

  filterDivider: {
    height: 'auto',
    alignSelf: 'stretch',
  },

  filterCountChip: {
    fontSize: '12px !important',
  },

  loadingScreen: {
    position: 'absolute',
    inset: 0,
    backgroundColor: alpha(theme.palette.background.paper, 0.55),
    zIndex: 1,
  },

  divider: {
    display: 'block',
    margin: '8px 0 24px',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

interface ITableHeaderProps {
  sort: IFilterSort | undefined;
  onSortChange: (sortState: IFilterSort | undefined) => void;
}

interface Props<Payload extends AnyObject, Values extends CommonFilterValues> {
  components: {
    TableHeader?: ComponentType<ITableHeaderProps>;
    AllFiltersModal?: ComponentType<CommonFilterModalProps<Values>>;
  };

  helpers: FilterTransformHelpers<Payload, Values>;

  initialFilters?: Partial<Payload>;
  onFiltersChange?: (filters: Payload) => void;
  onExport?: (values: Payload) => void;

  tableLayout?: boolean;

  isSecondary?: boolean;

  loading?: boolean;
  total?: number | null;
}

export const TableFilters = <Payload extends AnyObject, Values extends CommonFilterValues>(
  props: Props<Payload, Values>,
) => {
  // prettier-ignore
  const { initialFilters, onFiltersChange, onExport, tableLayout, loading } = props;
  const { AllFiltersModal, TableHeader } = props.components;
  const { getPayloadFromValue, getValueFromPayload, getEmptyValue, countValues } = props.helpers;

  const classes = useStyles();
  const intl = useIntl();

  const [filterModal, setFilterModal] = useState(false);

  const handleSubmit = useCallback(
    (values: Values) => onFiltersChange?.(getPayloadFromValue(values)),
    [getPayloadFromValue, onFiltersChange],
  );

  const formik = useFormik<Values>({
    initialValues: { ...getValueFromPayload(initialFilters ?? {}) },
    onSubmit: handleSubmit,
  });

  const clearForm = () => {
    formik.resetForm({ values: getEmptyValue() });
  };

  const handleExport = useAsyncCallback(async () => {
    await onExport?.(getPayloadFromValue(formik.values));
  });

  const desktopView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'), { noSsr: true });

  const statusFilterOptions = useMemo(() =>
    props.isSecondary
      ? [ShareSaleStatus.Open, ShareSaleStatus.Sold].map((o) => ({
        label: intl.formatMessage({ id: `portfolio.secondary.tabs.${o}` }),
        value: o
      }))
      : Object.values(InvestmentStatusFilterOption).map((o) => ({
        label: intl.formatMessage({ id: `portfolio.tabs.${o}` }),
        value: o
      })), [intl, props.isSecondary]);

  const defaultValue = useMemo(() =>
    props.isSecondary
      ? ShareSaleStatus.Open
      : InvestmentStatusFilterOption.All,
    [props.isSecondary]
  );

  const valuesCount = countValues(formik.values);
  const isEmpty = valuesCount === 0;

  return (
    <>
      <Box className={classes.wrapper}>
        <FormikContext.Provider value={formik}>
          <Box
            className={clsx(classes.container, { 'table-layout': tableLayout })}
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Box className={classes.filterGroup}>
              {desktopView && (
                <Field
                  id={props.isSecondary ? 'havingSaleStatus' : 'status'}
                  name={props.isSecondary ? 'havingSaleStatus' : 'status'}
                  component={Radio}
                  options={statusFilterOptions}
                  defaultValue={defaultValue}
                />
              )}
              <SubmitFormOnChange />
            </Box>

            <Box className={classes.sortGroup}>
              {!!AllFiltersModal && (
                <Button
                  variant="filter"
                  startIcon={<RiFilter3Line />}
                  endIcon={
                    !isEmpty && (
                      <InlineBadge label={valuesCount} className={classes.filterCountChip} />
                    )
                  }
                  className={clsx({ 'value-selected': !isEmpty })}
                  onClick={() => setFilterModal(true)}
                >
                  <FormattedMessage id="all_filters" />
                </Button>
              )}

              <Button variant="filter-contained" onClick={clearForm} disabled={isEmpty}>
                <FormattedMessage id="clear_all" />
              </Button>

              {!!onExport && (
                <>
                  <Divider orientation="vertical" className={classes.filterDivider} />

                  <Button
                    variant="filter"
                    startIcon={<RiShareBoxLine />}
                    onClick={handleExport.execute}
                    disabled={handleExport.loading}
                  >
                    <FormattedMessage id="export_as_csv" />
                  </Button>
                </>
              )}
            </Box>

            <Fade in={loading}>
              <Box className={classes.loadingScreen} />
            </Fade>
          </Box>
        </FormikContext.Provider>

        <Divider className={classes.divider} />

        {/* Table header is moved here to implement sticky effect */}
        {tableLayout && TableHeader && (
          <TableHeader
            sort={formik.values.sort}
            onSortChange={(newSort) => formik.setFieldValue('sort', newSort)}
          />
        )}
      </Box>

      {!!AllFiltersModal && (
        <AllFiltersModal
          open={filterModal}
          onClose={() => setFilterModal(false)}
          withSort
          initialValues={formik.values}
          onClear={clearForm}
          onApply={(values) => {
            handleSubmit(values);
            formik.setValues(values);
          }}
        />
      )}
    </>
  );
};

import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { Link, Tooltip, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { FileMetaInvestor, SearchResultInvestor } from 'api/document-client';
import { documentApiClient } from 'api';
import { DotLoader } from 'components';
import { delay, downloadFile } from 'utils';

interface Props {
  id: string;
  totalAmount: number;
}

export const DownloadAgreementAction = ({ id, totalAmount }: Props) => {
  const intl = useIntl();

  const [document, setDocument] = useState<FileMetaInvestor | undefined>();

  const loadDocument = useAsyncCallback(async () => {
    const { data } = await documentApiClient.api.investorApiControllerList({
      searchCriteria: {
        documentType: 'Investor Agreements',
        fileNamePartial: `${id}`,
        totalInvestmentAmount: totalAmount,
      },
      orderCriteria: [{ name: 'created', order: 'DESC' }],
    });

    setDocument((data as unknown as SearchResultInvestor)?.files?.[0]);
    return data;
  });

  const { loading } = useAsync(async () => {
    let pollCount = 0;
    let documentFound = false;

    while (!documentFound && pollCount < 7) {
      // prettier-ignore
      const result = (await loadDocument.execute()) as unknown as SearchResultInvestor;

      if (result.files?.[0]) {
        documentFound = true;
      } else {
        pollCount++;
        await delay(1000);
      }
    }
  }, []);

  const downloadDocument = useAsyncCallback(async () => {
    if (document) {
      // prettier-ignore
      const { data } = await documentApiClient.api.investorApiControllerDownload(document.id, {
        format: 'arraybuffer',
      });

      downloadFile(data as unknown as string, document.name);
    }
  });

  if (loadDocument.loading || loading) {
    return (
      <Typography color="text.secondary">
        <FormattedMessage id="generating" />
        <DotLoader />
      </Typography>
    );
  }

  if (document?.id) {
    return (
      <Link onClick={downloadDocument.execute} sx={{ cursor: 'pointer' }}>
        <FormattedMessage id="download" />
      </Link>
    );
  }

  return (
    <Tooltip title={intl.formatMessage({ id: 'agreement_failed' })}>
      <Typography textAlign={{ xs: 'left', sm: 'center' }}>
        <FormattedMessage
          id="error.contact_our_support"
          values={{
            a: (msg: string) => (
              <Link component={RouterLink} to={`../support`}>
                {msg}
              </Link>
            ),
          }}
        ></FormattedMessage>
      </Typography>
    </Tooltip>
  );
};

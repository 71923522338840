import axios, { AxiosInstance } from 'axios';

export enum ENotificationID {
  NewDealPublished = 'newdealpublished',
  AutoWithdrawalBooked = 'autowithdrawalbooked',
  PayoutDeclined = 'payoutdeclined',
  PayoutBooked = 'payoutbooked',
  PayinDeclined = 'payindeclined',
  PayinBooked = 'payinbooked',
  DealCancelled = 'dealcancelled',
  DealDefaulted = 'dealdefaulted',
  InvestmentCancelled = 'investmentcancelled',
  DealNotFinanced = 'dealnotfinanced',
  BankAccountChangeLsvStatus = 'bankaccountchangelsvstatus',
  MilestoneAdded = 'milestoneadded',
  UserRoleChangedInInvestorAccount = 'userrolechangedininvestoraccout',
  UserRemovedFromInvestorAccount = 'userremovedfrominvestoraccount',
  UserAddedToInvestorAccount = 'useraddedtoinvestoraccount',
  DealPayout = 'dealpayout',
  DealFilter = 'dealfilter',
  InvestmentPaid = 'investmentpaid',
}

export interface BaseNotificationMessageData {
  url?: string;
  user?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface NotificationMessage<D extends object = BaseNotificationMessageData> {
  id: string;
  projectId: string;
  notificationId: ENotificationID;
  userId: string;
  data: D;
  content: string;
  icon: string;
  createdAt: string;
  incrementCounter: number;
  readAt?: string;
  readFromChannel?: string;
  stackId?: string;
  stackCounter?: number;
  stackAt?: string;
}

export interface MessageListDto<D extends object = BaseNotificationMessageData> {
  messages: NotificationMessage<D>[];
  total: number;
  unread: number;
}

export interface NotifuseUserPreference {
  notificationId: ENotificationID;
  muteWidget: boolean;
  muteEmail: boolean;
  muteSMS: boolean;
}

export type TUserPreferences = Partial<Record<ENotificationID, NotifuseUserPreference>>;

export interface NotifuseUser {
  id: string;
  projectId: string;
  language: string;
  timezone: string;
  preferences: TUserPreferences;
  firstName: string;
  lastName: string;
  email: string;
  photoURL?: string;
  telephone?: string;
  country?: string;
  createdAt: string;
  updatedAt: string;
}

export interface NotificationSettings {
  id: ENotificationID;
  projectId: string;
  name: string;
  withWidget: boolean;
  withEmail: boolean;
  withSMS: boolean;
  allowMuteWidget: boolean;
  allowMuteEmail: boolean;
  allowMuteSMS: boolean;
  createdAt: string;
}

export interface NotifuseUserResponse {
  user?: NotifuseUser | null;
  notifications: NotificationSettings[];
}

export class NotifuseApiClient {
  private instance: AxiosInstance;
  private projectId;
  private userId;
  private userHmac;

  constructor(userId: string, userHmac: string) {
    this.projectId = process.env.REACT_APP_NOTIFUSE_PROJECT_ID;
    this.userId = userId;
    this.userHmac = userHmac;
    this.instance = axios.create({
      baseURL: 'https://api.notifuse.com/front',
      timeout: 5000,
      headers: {
        Expires: 0,
      },
    });
  }

  private getData = () => ({
    projectId: this.projectId,
    userId: this.userId,
    userHmac: this.userHmac,
  });

  markAllMessagesAsRead = async () => {
    return this.instance.post('messages.readAll', this.getData());
  };

  markMessageAsRead = async (messageId: string) => {
    return this.instance.post('messages.read', {
      ...this.getData(),
      messageId,
    });
  };

  listMessages = async (skip: number, limit: number) => {
    return this.instance.get<MessageListDto>('messages.list', {
      params: { ...this.getData(), skip, limit },
    });
  };

  getUser = async () => {
    return this.instance.get<NotifuseUserResponse>('users.get', {
      params: this.getData(),
    });
  };

  updateUser = async (user: NotifuseUser) => {
    return this.instance.post<{ code: number }>('users.setProfile', {
      ...this.getData(),
      user,
    });
  };
}

import { PropsWithChildren } from 'react';
import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';

interface Props {
  sx?: SxProps<Theme>;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderTop: `1px solid ${grey[300]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    flexWrap: 'unset',
  },
}));

export const CardFooter = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={clsx(classes.paper, props.className)} sx={props.sx}>
      {props.children}
    </Paper>
  );
};

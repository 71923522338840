import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Box, Divider, List, ListItem, ListItemButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SimpleBar from 'simplebar-react';

import { InvestmentWithDealDto } from 'api/investor-client';
import { Loader } from 'components';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    padding: '16px',
    fontWeight: 600,

    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px 8px',
    },
  },

  divider: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 24px',
    },
  },

  content: {
    position: 'relative',
    height: '208px',
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
      height: 'auto',
      flexGrow: 1,
    },
  },

  contentWrapper: {
    position: 'absolute',
    inset: 0,
    padding: '0 16px',

    [theme.breakpoints.up('sm')]: {
      padding: '0 8px',
      margin: '0 16px',
    },
  },

  listItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    color: theme.palette.text.secondary,
  },

  listItem: {
    marginBottom: '12px',

    '& > .MuiListItemButton-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 8px',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },
  },

  listItemButton: {
    padding: '4px 8px',
  },

  summary: {
    padding: '16px',
    textAlign: 'right',

    [theme.breakpoints.up('sm')]: {
      padding: '8px 24px 16px',
    },
  },
}));

interface Props {
  currency: Currency;
  data?: InvestmentWithDealDto[];
  loading?: boolean;
}

export const UnpaidCommitments = ({ data, loading, currency }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const formatMoney = useFormatMoney({ suffix: ` ${currency}` });

  const total = useMemo(
    () => data?.reduce((acc, val) => acc + val.unpaidAmount, 0) ?? 0,
    [data],
  );

  return (
    <Paper variant="outlined" className={classes.container}>
      <Box>
        <Typography className={classes.title}>
          <FormattedMessage id="unpaid_commitments" />
          {data?.length ? ` (${data.length})` : ''}
        </Typography>
        <Divider className={classes.divider} />
      </Box>

      <Box className={classes.content}>
        {loading ? (
          <Loader />
        ) : (
          <SimpleBar className={classes.contentWrapper}>
            <List disablePadding>
              {!!data?.length && (
                <ListItem className={classes.listItemHeader}>
                  <Typography variant="caption" fontWeight={500}>
                    <FormattedMessage id="id" />
                  </Typography>

                  <Typography variant="caption" fontWeight={500}>
                    <FormattedMessage id="amount" />
                  </Typography>
                </ListItem>
              )}

              {data?.map((comm) => (
                <ListItem key={comm.id} disablePadding className={classes.listItem}>
                  <ListItemButton onClick={() => history.push(`portfolio/primary/investments/${comm.id}`)}>
                    <Typography noWrap>{comm.id}</Typography>
                    <Typography noWrap flexShrink={0}>
                      {formatMoney(moveDecimalPoint(comm.unpaidAmount, -2))}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </SimpleBar>
        )}
      </Box>

      <Box>
        <Divider className={classes.divider} />
        <Box className={classes.summary}>
          <Typography component="span" variant="body2">
            <FormattedMessage id="total" />:{' '}
          </Typography>

          <Typography
            component="span"
            variant="subtitle2"
            fontSize="18px"
            color={!!total ? 'error' : undefined}
          >
            {formatMoney(moveDecimalPoint(total, -2))}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

import { SortOrder } from 'types';
import { ESecondaryDealSortOption } from './types';

export const primaryDealSortVariantsMap = {
  [ESecondaryDealSortOption.PublicationDate]: {
    [SortOrder.ASC]: { sortBy: 'deal.publication.publishDate', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.publication.publishDate', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.Currency]: {
    [SortOrder.ASC]: { sortBy: 'deal.currency', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.currency', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.Price]: {
    [SortOrder.ASC]: { sortBy: 'price', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'price', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.PriceDiscount]: {
    [SortOrder.ASC]: { sortBy: 'discount', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'discount', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.NominalValue]: {
    [SortOrder.ASC]: { sortBy: 'nominalValue', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'nominalValue', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.IRR]: {
    [SortOrder.ASC]: { sortBy: 'irr', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'irr', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.ExpirationDate]: {
    [SortOrder.ASC]: { sortBy: 'expiresAt', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'expiresAt', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.Rating]: {
    [SortOrder.ASC]: { sortBy: 'deal.risk.rating', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.risk.rating', sortOrder: SortOrder.DESC },
  },

  [ESecondaryDealSortOption.ProductType]: {
    [SortOrder.ASC]: { sortBy: 'deal.risk.productType', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.risk.productType', sortOrder: SortOrder.DESC },
  },
};

import { SortOrder } from 'types';
import { EPrimaryInvestmentSortOption } from './types';

export const primaryInvestmentSortVariantsMap = {
  [EPrimaryInvestmentSortOption.Duration]: {
    [SortOrder.ASC]: { sortBy: 'deal.duration', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.duration', sortOrder: SortOrder.DESC },
  },

  [EPrimaryInvestmentSortOption.Currency]: {
    [SortOrder.ASC]: { sortBy: 'deal.currency', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.currency', sortOrder: SortOrder.DESC },
  },

  [EPrimaryInvestmentSortOption.InvestmentAmount]: {
    [SortOrder.ASC]: { sortBy: 'amount', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'amount', sortOrder: SortOrder.DESC },
  },

  [EPrimaryInvestmentSortOption.ShareStatus]: {
    [SortOrder.ASC]: { sortBy: 'status', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'status', sortOrder: SortOrder.DESC },
  },

  [EPrimaryInvestmentSortOption.InvestmentDate]: {
    [SortOrder.ASC]: { sortBy: 'date', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'date', sortOrder: SortOrder.DESC },
  },

  [EPrimaryInvestmentSortOption.Rating]: {
    [SortOrder.ASC]: { sortBy: 'deal.risk.rating', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.risk.rating', sortOrder: SortOrder.DESC },
  },

  [EPrimaryInvestmentSortOption.ProductType]: {
    [SortOrder.ASC]: { sortBy: 'deal.risk.productType', sortOrder: SortOrder.ASC },
    [SortOrder.DESC]: { sortBy: 'deal.risk.productType', sortOrder: SortOrder.DESC },
  },
};

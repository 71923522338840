import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAsyncCallback } from 'react-async-hook';
import { ChartProps, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Stack, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';

import { investorApiClient } from 'api';
import Card from 'components/card';
import { Select } from 'components/forms';
import { SubmitFormOnChange } from 'components';
import { Currency, DealRating, PortfolioAllocationInvestmentStatus } from 'types';
import { formatMoney, getProductTypeOptions, moveDecimalPoint } from 'utils';

interface Values {
  productType: string | number;
  rating: string | number;
  overdue: string | number;
  currency: Currency;
}

const colors = {
  backgroundColor: [
    '#254E8F',
    '#4E84C1',
    'rgba(145, 154, 255, 0.95)',
    '#37A1E4',
    '#97D7FF',
    '#97FFF3',
  ],
};

const emptyData: {
  labels: string[];
  datasets: [
    {
      data: number[];
      count: string[];
      currency: string;
      name: string;
    },
  ];
} = {
  labels: [],
  datasets: [{ ...colors, data: [], count: [], currency: '', name: '' }],
};

const RepaymentStatuses: Record<string, PortfolioAllocationInvestmentStatus[]> = {
  OnTime: [PortfolioAllocationInvestmentStatus.OnTime],
  Arrears_1_30: [PortfolioAllocationInvestmentStatus.Overdue30Days],
  Arrears_31_60: [PortfolioAllocationInvestmentStatus.Overdue60Days],
  Arrears_61_119: [PortfolioAllocationInvestmentStatus.Overdue120Days, PortfolioAllocationInvestmentStatus.Overdue90Days],
  TechnicalDefault: [PortfolioAllocationInvestmentStatus.TechnicalDefault],
  Loss: [PortfolioAllocationInvestmentStatus.Loss],
};

const chartCommonProps: Partial<ChartProps<'pie'>> = {
  redraw: true,
  width: 177,
  height: 177,
  style: {
    margin: 'auto',
  },
  options: {
    responsive: false,
    plugins: {
      datalabels: {
        display: true,
        color: '#fff',
        align: 'center',
        textAlign: 'center',
        formatter: (value, context) => {
          const label = (context.chart.data?.labels ?? [])[context.dataIndex];
          const datapoints = context.chart.data.datasets[0].data;
          const totalValue = datapoints.reduce(
            (acc, val) => (acc as number) + ((val as number) ?? 0),
            0,
          ) as number;
          const percentage = ((value / totalValue) * 100).toFixed(1);
          return [`${label}`, `${percentage}%`];
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (items) => {
            const item = items[0];
            return `${(item.dataset as any).name}: ${item.label}`;
          },
          label: (item) => {
            return [
              formatMoney(moveDecimalPoint(item.raw as number, -2), {
                prefix: `${(item.dataset as any).currency} `,
              }),
              `${(item.dataset as any).count[item.dataIndex]}`,
            ];
          },
        },
      },
    },
  },
  plugins: [
    ChartDataLabels,
    {
      id: 'emptyDataPlugin',
      afterDraw: (chart) => {
        if (chart.data.datasets[0].data.length < 1) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.arc(width / 2, height / 2, width / 2, 0, 2 * Math.PI, false);
          ctx.fillStyle = 'rgb(0,0,0,0.05)';
          ctx.fill();
          ctx.restore();
        }
      },
    },
  ],
};

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: 'flex-start',
  },
}));

interface Props {
  defaultCurrency?: Currency;
}

export const PortfolioAllocation = ({ defaultCurrency }: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  const [data, setData] = useState([emptyData, emptyData, emptyData]);

  const productTypeOptions = useMemo(() => getProductTypeOptions(intl), [intl]);
  const currencyOptions = useMemo(() => {
    return Object.values(Currency).map((e) => ({
      value: e,
      label: e,
    }));
  }, []);
  const ratingOptions = useMemo(
    () =>
      Object.values(DealRating).map((e) => ({
        value: e,
        label: e,
      })),
    [],
  );
  const statusOptions = useMemo(
    () =>
      Object.entries(RepaymentStatuses).map(([k, v]) => ({
        value: v && v[0],
        label: intl.formatMessage({ id: `repayment_status.${k}` }),
      })),
    [intl],
  );
  const { loading, execute } = useAsyncCallback(async (values: Values) => {
    const filters = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== -1),
    );

    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetPortfolioAllocationStatistics(
      filters,
    );

    setData([
      {
        labels: Object.keys(data.productTypeSum).map((key) => {
          return intl.formatMessage({ id: `borrower.${key}` });
        }),
        datasets: [
          {
            ...colors,
            data: Object.values(data.productTypeSum).map((i) => i.sum),
            count: Object.values(data.productTypeSum).map((i) => {
              return intl.formatMessage({ id: 'investments_count' }, { number: i.count });
            }),
            currency: values.currency,
            name: intl.formatMessage({ id: 'borrower.type' }),
          },
        ],
      },
      {
        labels: Object.keys(data.ratingSum),
        datasets: [
          {
            ...colors,
            data: Object.values(data.ratingSum).map((i) => i.sum),
            count: Object.values(data.ratingSum).map((i) => {
              return intl.formatMessage({ id: 'investments_count' }, { number: i.count });
            }),
            currency: values.currency,
            name: intl.formatMessage({ id: 'rating.credit' }),
          },
        ],
      },
      {
        labels: Object.keys(data.statusSum).map((key) => {
          return intl.formatMessage({ id: `repayment_status.${key}` });
        }),
        datasets: [
          {
            ...colors,
            data: Object.values(data.statusSum).map((i) => i.sum),
            count: Object.values(data.statusSum).map((i) => {
              return intl.formatMessage({ id: 'investments_count' }, { number: i.count });
            }),
            currency: values.currency,
            name: intl.formatMessage({ id: 'status' }),
          },
        ],
      },
    ]);
  });

  return (
    <Formik<Values>
      initialValues={{
        productType: -1,
        rating: -1,
        overdue: -1,
        currency: defaultCurrency ?? Currency.CHF,
      }}
      onSubmit={(v) => {
        execute(v);
      }}
    >
      <Form>
        <Card>
          <Card.Header
            variant="condensed"
            title={intl.formatMessage({ id: 'portfolio_allocation' })}
            wrapperClassName={classes.header}
          >
            <Field
              id="currency"
              name="currency"
              sx={{ width: '150px', marginLeft: '10px' }}
              component={Select}
              options={currencyOptions}
              variant="filter"
              disableSelectedClass
            />
          </Card.Header>
          <Card.Body loadingContent={loading}>
            <Box display="flex" gap="16px" flexDirection={{ xs: 'column', sm: 'row' }}>
              <Stack spacing={4}>
                <Field
                  id="productType"
                  name="productType"
                  sx={{ width: '150px' }}
                  component={Select}
                  options={[
                    { label: intl.formatMessage({ id: 'all' }), value: -1 },
                    ...productTypeOptions,
                  ]}
                  label={intl.formatMessage({ id: 'filter_by_product' })}
                  variant="filter"
                  disableSelectedClass
                />
                <Field
                  id="rating"
                  name="rating"
                  sx={{ width: '150px' }}
                  component={Select}
                  options={[
                    { label: intl.formatMessage({ id: 'all' }), value: -1 },
                    ...ratingOptions,
                  ]}
                  label={intl.formatMessage({ id: 'filter_by_rating' })}
                  variant="filter"
                  disableSelectedClass
                />
                <Field
                  id="overdue"
                  name="overdue"
                  sx={{ width: '150px' }}
                  component={Select}
                  options={[
                    { label: intl.formatMessage({ id: 'all' }), value: -1 },
                    ...statusOptions,
                  ]}
                  label={intl.formatMessage({ id: 'filter_by_delay' })}
                  variant="filter"
                  disableSelectedClass
                />
              </Stack>

              <Box
                display="flex"
                flexGrow={1}
                justifyContent="space-around"
                gap="16px"
                flexWrap="wrap"
              >
                <div>
                  <Typography variant="body1" color="#222" fontWeight={600} mb={1}>
                    {intl.formatMessage({ id: 'allocation_by_type' })}:
                  </Typography>
                  <Pie {...chartCommonProps} data={data[0]} />
                </div>

                <div>
                  <Typography variant="body1" color="#222" fontWeight={600} mb={1}>
                    {intl.formatMessage({ id: 'allocation_by_rating' })}:
                  </Typography>
                  <Pie {...chartCommonProps} data={data[1]} />
                </div>

                <div>
                  <Typography variant="body1" color="#222" fontWeight={600} mb={1}>
                    {intl.formatMessage({ id: 'repayment_performance' })}:
                  </Typography>
                  <Pie {...chartCommonProps} data={data[2]} />
                </div>
              </Box>
            </Box>

            <SubmitFormOnChange initialSubmit />
          </Card.Body>
        </Card>
      </Form>
    </Formik>
  );
};

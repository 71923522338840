import { UserDto } from 'api/investor-client';
import { InvestorRole, Language } from 'types';

export const getUserRoleByAccount = (user?: UserDto, accountId?: string) => {
  const currentAccount = user?.accounts?.find((acc) => acc.investor?.id === accountId);
  return {
    isOwner: currentAccount?.role === InvestorRole.InvestorOwner,
    isAdmin: currentAccount?.role === InvestorRole.InvestorAdmin,
    isAdvisor: currentAccount?.role === InvestorRole.InvestorAdvisor,
    isViewOnly: currentAccount?.role === InvestorRole.InvestorViewOnly,
  };
};

export const getLinks = (language: Language) => {
  switch (language) {
    case Language.EN:
      return {
        companyLink: 'https://www.loantrade.io',
        termsConditionsLink: 'https://www.loantrade.io/terms',
        privacyLink: 'https://www.loantrade.io/data_privacy',
        legalNoticeLink: 'https://www.loantrade.io/legal_notice',
      };

    // case Language.FR:
    //   return {
    //     companyLink: 'https://www.cg24.com/fr',
    //     termsConditionsLink: 'https://www.cg24.com/fr/conditions',
    //     privacyLink: 'https://www.cg24.com/fr/protection',
    //   };

    // case Language.IT:
    //   return {
    //     companyLink: 'https://www.cg24.com/it',
    //     termsConditionsLink: 'https://www.cg24.com/it/termini',
    //     privacyLink: 'https://www.cg24.com/it/protezione',
    //   };

    default:
      return {
        companyLink: 'https://www.loantrade.io/de',
        termsConditionsLink: 'https://www.loantrade.io/de/terms',
        privacyLink: 'https://www.loantrade.io/de/data_privacy',
        legalNoticeLink: 'https://www.loantrade.io/de/legal_notice',
      };
  }
};

import { FormEvent, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Fab,
  IconButton,
  Tooltip,
  Typography,
  colors,
  emphasize,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiAddFill, RiCloseCircleFill, RiCloseFill } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { IDealDto } from 'api/investor-client';
import { useBasketProvider } from 'providers/basket';
import { AmountTextField } from 'components/forms';
import { useDealAmountInput } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    backgroundColor: theme.palette.primary.light,

    '&:hover': {
      backgroundColor: emphasize(theme.palette.primary.light, 0.15),
    },
  },

  dialog: {
    '& .MuiPaper-root': {
      padding: '32px 16px',
      textAlign: 'center',
    },
  },

  closeBtn: {
    alignSelf: 'flex-end',
    margin: '-24px -8px 0 0',
  },

  input: {
    marginBottom: '8px',

    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,

      '&.MuiInputBase-adornedEnd': {
        paddingRight: '4px',

        '& .MuiButtonBase-root': {
          color: theme.palette.grey[300],

          '&:hover': {
            color: theme.palette.grey[400],
          },
        },
      },
    },

    '& .MuiInputBase-input': {
      padding: '12px',
      textAlign: 'center',
      ...theme.typography.subtitle2,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.divider,
    },

    '&.error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.red[200],
      },
    },
  },
}));

interface Props {
  deal: IDealDto;
  amount: number | undefined;
  onAmountChange(value: number | undefined): void;
}

export const AddInvestmentModal = ({ deal, amount, onAmountChange }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const basket = useBasketProvider();

  const [open, setOpen] = useState(false);

  const { placeholder, isError, tooltipMessage, loaded } = useDealAmountInput(deal, amount);
  const [tooltip, setTooltip] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (amount) {
      basket.primary.setDeal({
        id: deal.id,
        providerId: deal.providerId,
        currency: deal.currency,
        amount: moveDecimalPoint(amount, 2),
      });

      toast.success(intl.formatMessage({ id: 'deal.details.select_investment_success' }));
    } else {
      removeDeal();
    }
  };

  const removeDeal = () => {
    if (basket.primary.getDeal(deal)) {
      basket.primary.removeDeal(deal);
      toast.info(intl.formatMessage({ id: 'deal.details.removal_investment_success' }));
    }

    onAmountChange(undefined);
  };

  return (
    <>
      <Fab className={classes.fab} onClick={() => setOpen(true)}>
        <RiAddFill size={24} />
      </Fab>

      <Dialog open={open} className={classes.dialog} onClose={() => setOpen(false)}>
        <IconButton className={classes.closeBtn} onClick={() => setOpen(false)}>
          <RiCloseFill />
        </IconButton>

        <Typography variant="subtitle2" margin="8px 0">
          <FormattedMessage id="new_investment_commitment" />
        </Typography>

        <Typography variant="caption" marginBottom="16px" color="text.secondary">
          <FormattedMessage id="investment_opportunity_time_limit_helper_text" />
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Tooltip
            open={tooltip}
            placement={'bottom'}
            title={tooltipMessage ?? ''}
            color={isError ? 'error' : undefined}
          >
            <AmountTextField
              fullWidth
              className={clsx(classes.input, { error: isError })}
              currency={deal.currency as Currency}
              placeholder={placeholder}
              value={amount ?? ''}
              disabled={!loaded}
              inputProps={{
                onValueChange: (data) => onAmountChange(data.floatValue),
                onFocus: () => setTooltip(true),
                onBlur: () => setTooltip(false),
                autoComplete: 'off',
              }}
              InputProps={{
                endAdornment: !!amount ? (
                  <IconButton onClick={removeDeal}>
                    <RiCloseCircleFill />
                  </IconButton>
                ) : undefined,
              }}
            />
          </Tooltip>

          <Button type="submit" fullWidth disabled={!loaded || isError || !amount}>
            <FormattedMessage id="add_investment" />
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

import { InvestorApiControllerGetDealsParams } from 'api/investor-client';
import { CommonFilterValues } from '../types';

export interface CommonPrimaryDealFilterValues extends CommonFilterValues {
  minDuration?: number;
  maxDuration?: number;
  minPrincipal?: number;
  maxPrincipal?: number;
}

export interface PrimaryDealFilterValues extends CommonPrimaryDealFilterValues {}

export interface PrimaryDealFilterConfigurationValues extends CommonPrimaryDealFilterValues {
  name: string;
}

export type PrimaryDealFilterPayload = Omit<
  InvestorApiControllerGetDealsParams,
  'id' | 'pageSize' | 'pageIndex' | 'withXirr'
>;

export enum EPrimaryDealSortOption {
  PublicationDate = 'PublicationDate',
  Currency = 'Currency',
  IRR = 'IRR',
  Duration = 'Duration',
  Amount = 'Amount',
  Rating = 'Rating',
  ProductType = 'ProductType',
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface EntityMetadataEntity {
  /** @format date-time */
  created: string;

  /** @format date-time */
  updated: string;
  version: number;
}

export interface AddressDTO {
  street: string;
  zipcode: string;
  city: string;
  country: string;
  streetNr?: string;
}

export interface ContactCreateDTO {
  firstName: string;
  lastName: string;
  address: AddressDTO;
  isCompany: boolean;
  email: string;
}

export interface DirectDebitDto {
  name: string;
  status: "ACTIVE" | "INACTIVE";
}

export interface LSVDto {
  name: string;
  status: "ACTIVE" | "INACTIVE";
}

export interface BankAccountCreateDTO {
  iban: string;
  currency: "CHF" | "EUR";
  dd?: DirectDebitDto;
  lsv?: LSVDto;
}

export interface VirtualAccountCreateDTO {
  name: string;
  tags?: object;
  tenant: string;
  organizationName: string;
  contact?: ContactCreateDTO;
  bankAccounts?: BankAccountCreateDTO[];

  /** @format date-time */
  createdAt?: string;
}

export interface PaymentDTO {
  id: string;
  status: "OPEN" | "UPLOADED" | "PENDING" | "DECLINED" | "DELETED" | "PENDING_PARTIALLY" | "BOOKED" | "PARTIAL";
  declinedReason?: string;
  description: string;
  meta: EntityMetadataEntity;
  lastUpdateBy: string;

  /** @format date-time */
  lastUpdateDate: string;
  endToEndId?: string;
  virtualAccount: VirtualAccountCreateDTO;
  type: "PAYIN" | "PAYOUT";
  amount: number;
  currency: "CHF" | "EUR";
  method: "BANK_TRANSFER" | "CASH" | "DEBIT_DIRECT" | "LSV";
  reference?: string;

  /** @format date-time */
  transferDueDate?: string;
  bookingText?: string;
  comment?: string;
  extra?: object;
  virtualAccountId: string;
  contact?: ContactCreateDTO;
  bankAccount?: BankAccountCreateDTO;
  tenantId: string;
}

export interface TransactionDto {
  id: number;
  debit: number;
  credit: number;
  amount: number;
  currency: string;
  account: string;
  acc1?: string;
  acc2?: string;
  acc3?: string;
  acc4?: string;
  acc5?: string;
  acc6?: string;
  ledgerEntry?: object;
  tenant?: object;
}

export interface LSVStatusChangeEntity {
  id: string;
  name: string;
  status: "ACTIVE" | "INACTIVE";

  /** @format date-time */
  date: string;
  bankAccount: BankAccountEntity;
}

export interface GetManyVirtualAccountEntityResponseDto {
  data: VirtualAccountEntity[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface GetManyTenantEntityResponseDto {
  data: TenantEntity[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface TenantEntity {
  id: string;
  virtualAccounts: VirtualAccountEntity[];
  meta: EntityMetadataEntity;
}

export interface PaymentEntity {
  id: string;
  type: "PAYIN" | "PAYOUT";
  reference: string;
  amount: number;
  currency: "CHF" | "EUR";
  method: "BANK_TRANSFER" | "CASH" | "DEBIT_DIRECT" | "LSV";

  /** @format date-time */
  transferDueDate?: string;
  status: "OPEN" | "UPLOADED" | "PENDING" | "DECLINED" | "DELETED" | "PENDING_PARTIALLY" | "BOOKED" | "PARTIAL";
  declinedReason?: string;
  comment?: string;
  bookingText: string;
  endToEndId: string;
  extra?: object;
  virtualAccount: VirtualAccountEntity;
  meta: EntityMetadataEntity;
  description: object;
}

export enum Iso2CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  XK = "XK",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export interface AddressEntity {
  country: Iso2CountryCode;
  id: string;
  street: string;
  zipcode: string;
  city: string;
  streetNr?: string;
  contact: ContactEntity;
}

export interface ContactEntity {
  id: string;
  firstName: string;
  lastName?: string;
  address: AddressEntity;
  isCompany: boolean;
  email?: string;
  metadata?: object;
  virtualAccount: VirtualAccountEntity;
}

export interface VirtualAccountEntity {
  logger: object;
  id: string;
  name: string;
  tags?: object;
  tenant: TenantEntity;
  organizationName: string;
  payments?: PaymentEntity[];
  contact?: ContactEntity;
  bankAccounts?: BankAccountEntity[];
  meta: EntityMetadataEntity;
  lsvId: string;
  reference: string;
  qrId: string;
}

export interface BankAccountEntity {
  iban: string;
  id: string;
  bankCode: string;
  name: string;
  bic: string;
  valid: boolean;
  dd?: DDStatusChangeEntity;
  lsv?: LSVStatusChangeEntity;
  currency: "CHF" | "EUR";
  virtualAccountId: string;
  virtualAccount: VirtualAccountEntity;
}

export interface DDStatusChangeEntity {
  id: string;
  name: string;
  status: "ACTIVE" | "INACTIVE";

  /** @format date-time */
  date: string;
  bankAccount: BankAccountEntity;
}

export interface BankAccountDTO {
  iban: string;
  id: string;
  dd?: DDStatusChangeEntity;
  lsv?: LSVStatusChangeEntity;
  currency: "CHF" | "EUR";
}

export interface VirtualAccountDTO {
  id: string;
  bankAccounts?: BankAccountDTO[];
  lsvId: string;
  qrId: string;
  reference: string;
}

export interface CreatePayinDto {
  amount: number;
  currency: "CHF" | "EUR";
}

export type WithdrawAmountDto = object;

export interface QrCreditorDto {
  /** The IBAN. **21 characters.** */
  account: string;

  /** Name. **Max. 70 characters.** */
  name: string;

  /** Address. **Max 70 characters.** */
  address: string;

  /** Building number. **Max 16 characters.** */
  buildingNumber?: object;

  /** Postal code. **Max 16 characters.** */
  zip: object;

  /** City. **Max 35 characters.** */
  city: string;

  /** Country code. **2 characters.** */
  country: string;
}

export interface QrDebtorDto {
  /** Name. **Max. 70 characters.** */
  name: string;

  /** Address. **Max 70 characters.** */
  address: string;

  /** Building number. **Max 16 characters.** */
  buildingNumber?: object;

  /** Postal code. **Max 16 characters.** */
  zip: object;

  /** City. **Max 35 characters.** */
  city: string;

  /** Country code. **2 characters.** */
  country: string;
}

export interface QrDataDto {
  /** The currency to be used. **3 characters.** */
  currency: string;

  /** The amount. **Max. 12 digits.** */
  amount?: number;

  /**
   * A reference number. **Max 27 characters.**
   * > QR-IBAN: Maximum 27 characters. Must be filled if a QR-IBAN is used.
   * Creditor Reference (ISO 11649): Maximum 25 characters.
   */
  reference?: string;

  /**
   * A message. **Max. 140 characters.**
   * > message can be used to indicate the payment purpose or for additional textual information about payments with a structured reference.
   */
  message?: string;

  /**
   * Additional information. **Max 140 characters.**
   * > Bill information contain coded information for automated booking of the payment. The data is not forwarded with the payment.
   */
  additionalInformation?: string;

  /**
   * Alternative scheme. **Max. 100 characters.**
   * > Parameter character chain of the alternative scheme according to the syntax definition in the [“Alternative scheme” section](https://www.paymentstandards.ch/dam/downloads/ig-qr-bill-en.pdf)
   */
  av1?: string;

  /**
   * Alternative scheme. **Max. 100 characters.**
   * > Parameter character chain of the alternative scheme according to the syntax definition in the [“Alternative scheme” section](https://www.paymentstandards.ch/dam/downloads/ig-qr-bill-en.pdf)
   */
  av2?: string;

  /** Creditor related data. */
  creditor: QrCreditorDto;

  /** Debtor related data. */
  debtor?: QrDebtorDto;
}

export interface QrCodeDto {
  /** The SVG of the qr code (qr bill, or just qr code) */
  svg: string;

  /**
   * data used to generate the qr code, used in swissqrbill: https://github.com/schoero/SwissQRBill
   * const data = {
   *         currency: "CHF",
   *         amount: 1199.95,
   *         reference: "210000000003139471430009017",
   *         creditor: {
   *             name: "Robert Schneider AG",
   *             address: "Rue du Lac",
   *             buildingNumber: "1268",
   *             zip: 2501,
   *             city: "Biel",
   *             account: "CH4431999123000889012",
   *             country: "CH"
   *         },
   *         debtor: {
   *             name: "Pia-Maria Rutschmann-Schnyder",
   *             address: "Grosse Marktgasse",
   *             buildingNumber: "28",
   *             zip: 9400,
   *             city: "Rorschach",
   *             country: "CH"
   *         }
   *         };
   */
  data: QrDataDto;
}

export interface CreditorDto {
  /** The IBAN. **21 characters.** */
  account: string;

  /** Name. **Max. 70 characters.** */
  name: string;

  /** Address. **Max 70 characters.** */
  address: string;

  /** Building number. **Max 16 characters.** */
  buildingNumber?: object;

  /** Postal code. **Max 16 characters.** */
  zip: object;

  /** City. **Max 35 characters.** */
  city: string;

  /** Country code. **2 characters.** */
  country: string;
}

export interface DebtorDto {
  /** Name. **Max. 70 characters.** */
  name: string;

  /** Address. **Max 70 characters.** */
  address: string;

  /** Building number. **Max 16 characters.** */
  buildingNumber?: object;

  /** Postal code. **Max 16 characters.** */
  zip: object;

  /** City. **Max 35 characters.** */
  city: string;

  /** Country code. **2 characters.** */
  country: string;
}

export interface DepositDetailsDto {
  currency: string;
  amount?: number;
  reference?: string;
  creditor: CreditorDto;
  debtor?: DebtorDto;
}

export interface ProblemDocument {
  status: number;
  title: string;
  detail?: string;
  instance?: string;
  type?: string;
}

export interface InvestorApiControllerGetAccountTransactionsParams {
  currency?: string;
  pageSize?: number;
  pageIndex?: number;
  minCreationDate?: string;
  maxCreationDate?: string;
}

export interface InvestorApiControllerGetPaymentsParams {
  /** Comma separated enum values: 'PAYIN', 'PAYOUT' */
  type?: string;

  /** Comma separated enum values: 'BANK_TRANSFER', 'CASH', 'DEBIT_DIRECT', 'LSV' */
  method?: string;

  /** Comma separated enum values: 'OPEN', 'UPLOADED', 'PENDING', 'DECLINED', 'DELETED', 'PENDING_PARTIALLY', 'BOOKED', 'PARTIAL' */
  status?: string;

  /** Comma separated enum values: 'CHF', 'EUR' */
  currency?: string;
  id?: string;
  tenantId?: string;
  reference?: string;
  minAmount?: number;
  maxAmount?: number;

  /** @format date-time */
  minCreationDate?: string;

  /** @format date-time */
  maxCreationDate?: string;
}

export interface InvestorApiControllerGetQrCodeParams {
  /** If present the amount will be part of the qr code, if not, the amount will have to be put by */
  amount?: number;

  /** CHF or EUR */
  currency?: string;

  /** If true, the full bill will be generated as SVG, otherwise, just the qr code */
  fullBill?: boolean;
}

export interface InvestorApiControllerGetDepositDetailsParams {
  /** CHF or EUR */
  currency?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Payments investor API
 * @version 1.0
 * @contact
 *
 * The Payments API. Used by investors to manage banking information
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerGetCurrentVirtualAccount
     * @summary Get investor virtual account
     * @request GET:/api/investor/virtual-account
     * @secure
     */
    investorApiControllerGetCurrentVirtualAccount: (params: RequestParams = {}) =>
      this.request<VirtualAccountDTO, any>({
        path: `/api/investor/virtual-account`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerCreateVirtualAccount
     * @summary Create investor virtual account
     * @request POST:/api/investor/virtual-account
     * @secure
     */
    investorApiControllerCreateVirtualAccount: (params: RequestParams = {}) =>
      this.request<VirtualAccountDTO, any>({
        path: `/api/investor/virtual-account`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerUpdateBankAccount
     * @summary Update investor bank account
     * @request PUT:/api/investor/bank-account
     * @secure
     */
    investorApiControllerUpdateBankAccount: (data: BankAccountDTO, params: RequestParams = {}) =>
      this.request<BankAccountDTO, any>({
        path: `/api/investor/bank-account`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerCreateBankAccount
     * @summary Create investor bank account
     * @request POST:/api/investor/bank-account
     * @secure
     */
    investorApiControllerCreateBankAccount: (data: BankAccountCreateDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investor/bank-account`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerRemoveBankAccount
     * @summary Remove investor bank account
     * @request DELETE:/api/investor/bank-account/{id}
     * @secure
     */
    investorApiControllerRemoveBankAccount: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investor/bank-account/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerDeactivateBankAccount
     * @summary Deactivate DD/LSV for the given bank account
     * @request POST:/api/investor/bank-account/{id}/deactivate
     * @secure
     */
    investorApiControllerDeactivateBankAccount: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investor/bank-account/${id}/deactivate`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerGetAccountTransactions
     * @summary Get transactions of investor virtual account
     * @request GET:/api/investor/transactions
     * @secure
     */
    investorApiControllerGetAccountTransactions: (
      query: InvestorApiControllerGetAccountTransactionsParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items?: TransactionDto[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
          options?: Record<string, string[]>;
          filters?: Record<string, string[]>;
        },
        any
      >({
        path: `/api/investor/transactions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerGetPayments
     * @summary Get payments of investor virtual account
     * @request GET:/api/investor/payments
     * @secure
     */
    investorApiControllerGetPayments: (query: InvestorApiControllerGetPaymentsParams, params: RequestParams = {}) =>
      this.request<
        {
          items?: PaymentDTO[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
          options?: Record<string, string[]>;
          filters?: Record<string, string[]>;
        },
        any
      >({
        path: `/api/investor/payments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerCreatePayin
     * @summary Create payin based on the bank account
     * @request POST:/api/investor/payments/payin
     * @secure
     */
    investorApiControllerCreatePayin: (data: CreatePayinDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investor/payments/payin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerWithdrawAmount
     * @summary Request withdrawal
     * @request POST:/api/investor/withdrawal
     * @secure
     */
    investorApiControllerWithdrawAmount: (data: WithdrawAmountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investor/withdrawal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerGetQrCode
     * @summary Gets a QR code in SVG format for deposits
     * @request GET:/api/investor/qr-code
     * @secure
     */
    investorApiControllerGetQrCode: (query: InvestorApiControllerGetQrCodeParams, params: RequestParams = {}) =>
      this.request<QrCodeDto, any>({
        path: `/api/investor/qr-code`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investor
     * @name InvestorApiControllerGetDepositDetails
     * @summary Gets deposit details
     * @request GET:/api/investor/deposit/details
     * @secure
     */
    investorApiControllerGetDepositDetails: (
      query: InvestorApiControllerGetDepositDetailsParams,
      params: RequestParams = {},
    ) =>
      this.request<DepositDetailsDto, any>({
        path: `/api/investor/deposit/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}

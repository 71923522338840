import React from 'react';

import { IBasketContext } from './types';

const stub = () => {
  throw new Error('<BasketProvider> not found.');
};

export const BasketContext = React.createContext<IBasketContext>({
  primary: {
    deals: [],
    getDeal: stub,
    setDeal: stub,
    removeDeal: stub,
    clear: stub,
  },

  secondary: {
    deals: [],
    getDeal: stub,
    setDeal: stub,
    removeDeal: stub,
    clear: stub,
  },
});

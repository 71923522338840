import { Typography, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Card from 'components/cardv2';
import { FormattedMessage } from 'react-intl';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { KycRecord, PrivateKycRecord } from 'api/investor-client';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '64px',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

type IProps = {
  kycRecord: KycRecord;
};
export const PrivateKyc = (props: IProps) => {
  const classes = useStyles();
  const { kycRecord } = props;
  const privateKycRecord = kycRecord as PrivateKycRecord;

  return (
    <>
      {privateKycRecord.contactPerson && (
        <>
          <Typography className={classes.title}>
            <FormattedMessage id="contact_person_details" />
            <AiOutlineInfoCircle />
          </Typography>

          <Grid spacing={4}>
            <Grid>
              <Card
                header={'Contact person'}
                items={[
                  {
                    key: 'common.form_field.salutation',
                    value: privateKycRecord.contactPerson.title,
                  },
                  {
                    key: 'legal_name',
                    value: `${privateKycRecord.contactPerson.firstName} ${privateKycRecord.contactPerson.lastName}`,
                  },
                  {
                    key: 'common.form_field.birthday',
                    value: privateKycRecord.contactPerson.dateOfBirth,
                  },
                  {
                    key: 'common.form_field.nationality',
                    value: privateKycRecord.contactPerson.nationality.shortName,
                  },
                  {
                    key: 'address',
                    value: `${privateKycRecord.contactPerson.address.street} ${privateKycRecord.contactPerson.address.streetNr}, ${privateKycRecord.contactPerson.address.zipcode} ${privateKycRecord.contactPerson.address.country.shortName}`,
                  },
                  { key: 'Phone', value: privateKycRecord.contactPerson.phoneNumber },
                ]}
              />
            </Grid>
          </Grid>
        </>
      )}

      {privateKycRecord?.beneficialOwners?.length > 0 && (
        <>
          <Typography className={classes.title}>
            <FormattedMessage id="beneficial_owners" />
            <AiOutlineInfoCircle />
          </Typography>
          <Grid spacing={4}>
            {privateKycRecord.beneficialOwners.map((e, idx) => {
              return (
                <Grid>
                  <Card
                    header={<FormattedMessage id="beneficial_owner_nr" values={{ nr: idx + 1 }} />}
                    items={[
                      { key: 'legal_name', value: `${e.firstName} ${e.lastName}` },
                      { key: 'common.form_field.birthday', value: e.dateOfBirth },
                      { key: 'common.form_field.nationality', value: e.nationality.shortName },
                      {
                        key: 'address',
                        value: `${e.address.street} ${e.address.streetNr}, ${e.address.zipcode} ${e.address.country.shortName}`,
                      },
                    ]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}

      <Typography className={classes.title}>
        <FormattedMessage id="aml_questionnaire" />
        <AiOutlineInfoCircle />
      </Typography>
      <Grid spacing={4}>
        <Grid>
          <Card
            items={[
              { key: 'privateAnnualIncome', value: privateKycRecord.privateAnnualIncome },
              { key: 'privateNetWorth', value: privateKycRecord.privateNetWorth },
              {
                key: 'privatePlannedInvestment',
                value: privateKycRecord.privatePlannedInvestment,
              },
              { key: 'moneyOrigination', value: privateKycRecord.moneyOrigination },
              { key: 'politicalExposure', value: privateKycRecord.politicalExposure },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

import { Box, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import { useIntl } from 'react-intl';

import { NotificationMessage } from 'api/notifications-client';
import { NotificationIcon } from './NotificationIcon';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

const useStyles = makeStyles((theme) => ({
  notificationCard: {
    display: 'flex',
    gap: '24px',
    padding: '24px 16px',
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.12)}`,
    },
  },

  notificationCardIcon: {
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: alpha(theme.palette.text.secondary, 0.08),
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
  },

  notificationCardContent: {
    display: 'flex',
    flexDirection: 'column',
  },

  notificationCardUnreadMark: {
    width: '8px',
    height: '8px',
    marginTop: '8px',
    flexShrink: 0,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '8px',

    '&.read': {
      visibility: 'hidden',
    },
  },
}));

interface Props {
  notification: NotificationMessage;
  onClick: (notification: NotificationMessage) => void;
}

export const Notification = ({ notification, onClick }: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  const createdAt = dayjs(notification.createdAt);
  const timestamp = createdAt.isToday()
    ? `${intl.formatMessage({ id: 'today' })}, ${createdAt.format('h:mma')}`
    : createdAt.isYesterday()
    ? `${intl.formatMessage({ id: 'yesterday' })}, ${createdAt.format('h:mma')}`
    : createdAt.format('DD.MM.YYYY, HH:mm');

  return (
    <Box className={classes.notificationCard} onClick={() => onClick(notification)}>
      <NotificationIcon notification={notification} />

      <Box className={classes.notificationCardContent}>
        <Typography variant="body2" marginBottom="8px">
          {notification.content}
        </Typography>

        <Typography variant="caption" color="text.secondary">
          {timestamp}
        </Typography>
      </Box>

      <Box className={clsx(classes.notificationCardUnreadMark, { read: !!notification.readAt })} />
    </Box>
  );
};

import { Box, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import {
  EPrimaryInvestmentSortOption,
  primaryInvestmentSortVariantsMap,
} from 'components/filters/primary-investment';
import { SortIcon } from 'components';
import { SortOrder } from 'types';

import { useTableRowStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },

  cell: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },

  firstCell: {},

  lastCell: {
    minHeight: 'auto',
    paddingRight: '8px',
    justifyContent: 'flex-end',
    color: alpha(theme.palette.text.secondary, 0.36),
  },

  sortIcon: {
    fontSize: '20px',
  },

  tooltip: {
    color: alpha(theme.palette.text.secondary, 0.36),
  },
}));

interface Props {
  sort: { sortBy: string; sortOrder: SortOrder } | undefined;
  onSortChange: (sortState: { sortBy: string; sortOrder: SortOrder } | undefined) => void;
}

export const PrimaryInvestmentHeader = ({ sort, onSortChange }: Props) => {
  const tableRowClasses = useTableRowStyles();
  const classes = useStyles();

  const getSortState = (sortOption: EPrimaryInvestmentSortOption) => {
    if (!sort) return undefined;
    const sortValue = primaryInvestmentSortVariantsMap[sortOption][sort.sortOrder];
    if (sortValue.sortBy === sort.sortBy) return sort.sortOrder;
    return undefined;
  };

  const handleSortClick = (sortOption: EPrimaryInvestmentSortOption) => {
    return () => {
      if (sort) {
        const sortValue = primaryInvestmentSortVariantsMap[sortOption][sort.sortOrder];

        if (sortValue.sortBy === sort.sortBy) {
          switch (sort.sortOrder) {
            case SortOrder.ASC:
              return onSortChange(primaryInvestmentSortVariantsMap[sortOption][SortOrder.DESC]);

            case SortOrder.DESC:
              return onSortChange(undefined);
          }
        }
      }

      onSortChange(primaryInvestmentSortVariantsMap[sortOption][SortOrder.ASC]);
    };
  };

  return (
    <Box className={classes.card}>
      <Box display="flex" flexGrow={1}>
        <Box
          className={clsx(
            tableRowClasses.cell,
            classes.cell,
            tableRowClasses.idCell,
            classes.firstCell,
          )}
        >
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="investment_id" />
          </Typography>
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.riskCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="rating" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryInvestmentSortOption.Rating)}
            onClick={handleSortClick(EPrimaryInvestmentSortOption.Rating)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.productTypeCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="type" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryInvestmentSortOption.ProductType)}
            onClick={handleSortClick(EPrimaryInvestmentSortOption.ProductType)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.statusCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="status" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryInvestmentSortOption.ShareStatus)}
            onClick={handleSortClick(EPrimaryInvestmentSortOption.ShareStatus)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.durationCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="duration" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryInvestmentSortOption.Duration)}
            onClick={handleSortClick(EPrimaryInvestmentSortOption.Duration)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.dateCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="investment_date" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryInvestmentSortOption.InvestmentDate)}
            onClick={handleSortClick(EPrimaryInvestmentSortOption.InvestmentDate)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.priceCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="repaid_invested_amount" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryInvestmentSortOption.InvestmentAmount)}
            onClick={handleSortClick(EPrimaryInvestmentSortOption.InvestmentAmount)}
          />
        </Box>
      </Box>

      <Box
        className={clsx(
          tableRowClasses.cell,
          tableRowClasses.inputCell,
          classes.cell,
          classes.lastCell,
        )}
      >
      </Box>
    </Box>
  );
};

import { PropsWithChildren, ComponentProps } from 'react';
import { Container } from '@mui/material';

import { combineSx, mixinSx } from 'utils';

interface Props extends ComponentProps<typeof Container> {
  centered?: boolean;
}

export const PageContainer = ({ centered, sx, ...rest }: PropsWithChildren<Props>) => {
  return (
    <Container
      maxWidth='xxxl'
      sx={combineSx(
        sx,
        mixinSx(!!centered, {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
        }),
      )}
      {...rest}
    />
  );
};

import React, { useRef } from 'react';
import { useFormikContext } from 'formik';

interface Props {
  initialSubmit?: boolean;
}

export const SubmitFormOnChange = ({ initialSubmit }: Props) => {
  const formik = useFormikContext();
  const initial = useRef(false);

  React.useEffect(() => {
    if (!initialSubmit && initial.current === false) {
      initial.current = true;
      return;
    }

    formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return null;
};

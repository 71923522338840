import { useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { investorApiClient } from 'api';
import { DealDto, DealEventDto, DealStatusDto, TransactionsInfoDto } from 'api/investor-client';
import { DataOverviewTable, ITableRow } from 'components';
import Card from 'components/card';
import { useFormatMoney } from 'hooks';
import { IDealDetails, mixinArray, moveDecimalPoint } from 'utils';
import { IInvestmentPaidEvent } from 'types';

interface Props {
  deal: DealDto;
  dealDetails: IDealDetails;
  repaymentsData?: TransactionsInfoDto
  loading?: boolean;
  totalAmount: number;
}

type PaymentEvent = Modify<DealEventDto, { json: IInvestmentPaidEvent }>;

const getIntervalDescription = (interval: number, intl: IntlShape) => {
  const every = intl.formatMessage({ id: 'every' });

  if (interval < 30) {
    return `${every} ${interval} ${intl.formatMessage({ id: 'days' })}`;
  } else if (interval < 360) {
    interval = +(interval / 30).toFixed(2);
    return `${every} ${interval} ${intl.formatMessage({ id: interval === 1 ? 'month' : 'months'})}`
  } else {
    interval = +(interval / 360).toFixed(2);
    return `${every} ${interval} ${intl.formatMessage({ id: 'years' })}`;
  }
};

export const DealDetailsSubTab = ({
  deal,
  dealDetails,
  totalAmount,
  repaymentsData,
  loading,
}: Props) => {
  const intl = useIntl();
  const formatMoney = useFormatMoney({ prefix: `${deal.currency} ` });

  // prettier-ignore
  const { result: paymentHistory = [], loading: paymentHistoryLoading } = useAsync(async () => {
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetDealPaymentHistory(
      deal.id, deal.providerId
    );

    return data as unknown as PaymentEvent[];
  }, [deal]);

  const dealTermsRows = useMemo((): ITableRow[] => {
    const financedAmount = formatMoney(
      moveDecimalPoint(dealDetails.common.totalCommittedAmount, -2),
    );

    const requestedAmount = formatMoney(moveDecimalPoint(deal.principal, -2), {
      prefix: undefined,
    });

    return [
      {
        cols: [
          intl.formatMessage({ id: 'amount.financed_requested' }),
          `${financedAmount} / ${requestedAmount}`,
        ],
      },
      ...mixinArray(typeof deal.nominalInterestRate === 'number', [
        {
          cols: [
            intl.formatMessage({ id: 'interest_rate.nominal.short' }),
            `${(deal.nominalInterestRate * 100).toFixed(3)}%`,
          ],
        },
      ]),
      ...mixinArray(loading || typeof repaymentsData?.xirr === 'number', [{
        cols: [
          intl.formatMessage({ id: 'irr.maximum_net' }),
          loading ? <CircularProgress /> : `${((repaymentsData?.xirr || 0) * 100).toFixed(2)}%`,
        ],
      }]),
      ...mixinArray(!!deal.risk.productType, [
        {
          cols: [
            intl.formatMessage({ id: 'borrower.type' }),
            intl.formatMessage({ id: `borrower.${deal.risk.productType}` }),
          ],
        },
      ]),
      ...mixinArray(!!deal.risk.purpose, [
        {
          cols: [
            intl.formatMessage({ id: 'category' }),
            intl.formatMessage({
              id: `deal.purpose.${deal.risk.purpose}`,
              defaultMessage: deal.risk.purpose,
            }),
          ],
        },
      ]),
      ...mixinArray(typeof deal.duration === 'number', [
        {
          cols: [
            intl.formatMessage({ id: 'duration.months' }),
            `${deal.duration} ${intl.formatMessage({ id: 'months' })}`,
          ],
        },
      ]),
      ...mixinArray(
        ![DealStatusDto.New, DealStatusDto.Invest].includes(
          deal.status,
        ) &&
        !!deal.startDate &&
        !!deal.endDate,
        [
          {
            cols: [
              intl.formatMessage({ id: 'expected_start_end_date' }),
              `${dayjs(deal.startDate).format('DD.MM.YYYY')} / ${dayjs(
                deal.endDate,
              ).format('DD.MM.YYYY')}`,
            ],
          },
        ],
      ),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.formatMessage, deal, dealDetails, formatMoney]);

  const repaymentModalsRows = useMemo((): ITableRow[] => {
    const interval = deal.repayments.length > 0 ? (deal.duration / deal.repayments.length) * 30 : 0
    const intervalMessage = interval
      ? getIntervalDescription(interval, intl)
      : null;

    return [
      {
        cols: [
          intl.formatMessage({ id: 'repayment.type' }),
          intl.formatMessage({
            id: `repayment.type.${deal.repayments
                ? deal.repayments.length > 1
                  ? 'annuity'
                  : 'bullet'
                : '~'
              }`,
          }),
        ],
      },
      ...mixinArray(!!intervalMessage, [
        {
          cols: [
            intl.formatMessage({ id: 'interval_amortization_payments' }),
            intervalMessage,
          ],
        },
      ]),
      ...mixinArray(!!intervalMessage, [
        {
          cols: [
            intl.formatMessage({ id: 'interval_interest_payments' }),
            intervalMessage,
          ],
        },
      ]),
      ...mixinArray(!!deal.repayments?.length, [
        {
          cols: [
            intl.formatMessage({ id: 'number_of_amortization_payments' }),
            deal.repayments.length,
          ],
        },
      ]),
      ...mixinArray(!!deal.repayments?.length, [
        {
          cols: [
            intl.formatMessage({ id: 'number_of_interest_payments' }),
            deal.repayments.length,
          ],
        },
      ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.formatMessage, deal]);

  // const riskOverviewRows = useMemo((): ITableRow[] => {
  //   return [
  //     {
  //       cols: [
  //         intl.formatMessage({ id: 'rating.originator_generic' }),
  //         `n/a / ${deal.risk.rating}`,
  //       ],
  //     },
  //     {
  //       cols: [intl.formatMessage({ id: 'collateral' }), 'n/a'],
  //     },
  //     {
  //       cols: [
  //         intl.formatMessage({ id: 'insurance_coverage' }),
  //         deal.risk.insurance,
  //       ],
  //     },
  //   ];
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [intl.formatMessage, deal]);

  const investmentCommitmentsRows = useMemo((): ITableRow[] => {
    const historyToShow = paymentHistory.slice(0, 4);
    const rest = paymentHistory.slice(4);

    const restAmount = rest.reduce(
      (amount, event) => amount + event.json.investmentAmount,
      0,
    );

    const rows: ITableRow[] = historyToShow.map((event) => ({
      cols: [
        dayjs(event.createdDate).format('DD.MM.YYYY'),
        formatMoney(moveDecimalPoint(event.json.investmentAmount, -2)),
      ],
    }));

    if (rest.length) {
      rows.push({
        cols: [
          intl.formatMessage(
            { id: 'investments_before' },
            { number: rest.length },
          ),
          formatMoney(moveDecimalPoint(restAmount, -2)),
        ],
      });
    }

    return rows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentHistory, formatMoney]);

  return (
    <Grid container mt={0} spacing={4}>
      <Grid item md={6} sm={12}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'deal_terms' })}
              rows={dealTermsRows}
              emptyMessage={
                <Alert severity="warning" color="info">
                  <FormattedMessage id="warning.no_info" />
                </Alert>
              }
            />
          </Grid>
          <Grid item>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'repayment_modals' })}
              rows={repaymentModalsRows}
              emptyMessage={
                <Alert severity="warning" color="info">
                  <FormattedMessage id="warning.no_info" />
                </Alert>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Card>
              <Card.Header
                variant="condensed"
                title={intl.formatMessage({ id: 'loan_usage' })}
                divided
              />
              <Card.Body>
                {deal.description ? (
                  <Box sx={{ px: '10px', wordBreak: 'break-word' }}>
                    {deal.description ?? 'n/a'}
                  </Box>
                ) : (
                  <Alert severity="warning" color="info">
                    <FormattedMessage id="warning.no_info" />
                  </Alert>
                )}
              </Card.Body>
            </Card>
          </Grid>
          {/* <Grid item>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'risk_overview' })}
              rows={riskOverviewRows}
            />
          </Grid> */}
          <Grid item>
            {paymentHistoryLoading || paymentHistory.length > 0 ? (
              <DataOverviewTable
                title={intl.formatMessage({ id: 'investment_commitments' })}
                rows={investmentCommitmentsRows}
              />
            ) : (
              <Card>
                <Card.Header
                  variant="condensed"
                  title={intl.formatMessage({ id: 'investment_commitments' })}
                />
                <Card.Body>
                  <Alert severity="warning" color="info">
                    <FormattedMessage id="portfolio.table.empty" />
                  </Alert>
                </Card.Body>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { createContext } from 'react';

import { INotificationsContext } from './types';

const stub = () => {
  throw new Error('<NotificationsProvider> not found.');
};

export const NotificationsContext = createContext<INotificationsContext>({
  userId: null,
  hmacSignature: null,
  notificationMetadata: { total: 0, unread: 0 },
  userData: null,
  isReady: false,
  refreshMetadata: stub,
  getMessages: stub,
  markMessageAsRead: stub,
  markAllMessagesAsRead: stub,
  goToUrl: stub,
  refreshUser: stub,
  fetchingUser: false,
  changeUserPreferences: stub,
});

import { useCallback, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import {
  ENotificationID,
  NotificationSettings,
  NotifuseUser,
  NotifuseUserPreference,
  TUserPreferences,
} from 'api/notifications-client';
import { useNotificationsProvider } from 'providers/notifications';

import { TNotificationGroupType, INotificationConfigOption } from './types';

const notificationMuteMap = (type: TNotificationGroupType) => {
  switch (type) {
    case 'widget':
      return 'muteWidget';
    case 'email':
      return 'muteEmail';
    case 'sms':
      return 'muteSMS';
  }
};

const createNotificationOption = (
  notificationSettings: NotificationSettings,
  user: NotifuseUser,
  type: TNotificationGroupType,
  intl: IntlShape,
) => {
  const option: INotificationConfigOption = {
    id: notificationSettings.id,
    label: intl.formatMessage({
      id: `notifications.${notificationSettings.id}.title`,
      defaultMessage: notificationSettings.name,
    }),
    checked: true,
  };

  // if muted in preferences
  if (user.preferences[notificationSettings.id]?.[notificationMuteMap(type)]) {
    option.checked = false;
  }

  return option;
};

const getUserPreferencesFromNotifications = (
  userPreferences: TUserPreferences,
  type: TNotificationGroupType,
  changedNotifications: INotificationConfigOption[],
) => {
  const preferencesFromChanges: NotifuseUserPreference[] = [];

  for (const changedNotification of changedNotifications) {
    const existedPreference = userPreferences[changedNotification.id];

    if (existedPreference) {
      preferencesFromChanges.push({
        ...existedPreference,
        [notificationMuteMap(type)]: !changedNotification.checked,
      });
    } else {
      preferencesFromChanges.push({
        notificationId: changedNotification.id,
        muteWidget: false,
        muteEmail: false,
        muteSMS: false,
        [notificationMuteMap(type)]: !changedNotification.checked,
      });
    }
  }

  return preferencesFromChanges;
};

export const useNotificationConfiguration = () => {
  const { isReady, userData, changeUserPreferences, fetchingUser } = useNotificationsProvider();
  const intl = useIntl();

  const optionGroups = useMemo(() => {
    const result = {
      widget: [] as INotificationConfigOption[],
      email: [] as INotificationConfigOption[],
      sms: [] as INotificationConfigOption[],
    };

    if (!userData?.user) return result;

    for (const notification of userData.notifications) {
      if (notification.withWidget && notification.allowMuteWidget) {
        result.widget.push(createNotificationOption(notification, userData.user, 'widget', intl));
      }

      if (notification.withEmail && notification.allowMuteEmail) {
        result.email.push(createNotificationOption(notification, userData.user, 'email', intl));
      }

      if (notification.withSMS && notification.allowMuteSMS) {
        result.sms.push(createNotificationOption(notification, userData.user, 'sms', intl));
      }
    }

    return result;
  }, [userData, intl]);

  const changeOptions = useCallback(
    (type: TNotificationGroupType, changedNotifications: INotificationConfigOption[]) => {
      if (!userData?.user) return;

      const preferencesFromChanges = getUserPreferencesFromNotifications(
        userData.user.preferences,
        type,
        changedNotifications,
      );

      changeUserPreferences(preferencesFromChanges);
    },
    [userData, changeUserPreferences],
  );

  const changeOptionsBulk = useCallback(
    (changes: Partial<Record<TNotificationGroupType, INotificationConfigOption[]>>) => {
      if (!userData?.user) return;

      let preferences = userData.user.preferences;
      let preferencesFromChanges: typeof preferences = {};

      for (const [type, changedNotifications] of Object.entries(changes)) {
        const changes = getUserPreferencesFromNotifications(
          preferences,
          type as TNotificationGroupType,
          changedNotifications,
        );

        const changesObj = Object.fromEntries(changes.map((c) => [c.notificationId, c]));

        preferencesFromChanges = { ...preferencesFromChanges, ...changesObj };
        preferences = { ...preferences, ...changesObj };
      }

      changeUserPreferences(Object.values(preferencesFromChanges));
    },
    [userData, changeUserPreferences],
  );

  const findOption = useCallback(
    (type: ENotificationID) => {
      return {
        widget: optionGroups.widget.find((o) => o.id === type) ?? null,
        email: optionGroups.email.find((o) => o.id === type) ?? null,
        sms: optionGroups.sms.find((o) => o.id === type) ?? null,
      };
    },
    [optionGroups],
  );

  const initialLoading = !isReady || (!userData && fetchingUser);

  return { initialLoading, optionGroups, changeOptions, changeOptionsBulk, findOption };
};

import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { TextField, Tooltip, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { IntlFormatters, useIntl } from 'react-intl';
import clsx from 'clsx';

import { DealDto } from 'api/investor-client';
import { useFormatMoney, useControlledState } from 'hooks';
import { getDealDetails, IDealDetails, moveDecimalPoint } from 'utils';

interface Props {
  deal: DealDto;
  investorId: string;
  onChange: (value: number | undefined) => void;
  changeType?: 'change' | 'blur';
  onError?: (error: boolean) => void;
  initialAmount?: number;
  displayInvestedAmount?: boolean;
  className?: string;
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  investedAmount: {
    position: 'absolute',
    top: 0,
    right: '14px',
    fontStyle: 'italic',
    lineHeight: 1.35,
  },
  investmentInput: {
    width: '100%',
    background: '#FFFEFE80',
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'not-allowed',
    },
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      textAlign: 'right',
      padding: '9px 14px',
    },
    '&.labeled input': {
      padding: '18px 14px 0px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,

      '&.Mui-error': {
        outline: '2px solid #e38080',
      },
    },
  },
  errorTooltip: {
    fontSize: '.9rem',
    background: '#ff5151eb',
  },
}));

const getFieldMessages = (
  dDetails: IDealDetails,
  formatMessage: IntlFormatters['formatMessage'],
  formatMoney: (value: number) => string,
) => {
  let placeholder = '';
  const tooltip: string[] = [];

  // if last investor
  if (dDetails.common.isLastInvestor) {
    const value = formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2));

    placeholder = formatMessage({ id: 'deals.amount_for_last_investor.mini' }, { value: value });

    tooltip.push(formatMessage({ id: 'deals.amount_for_last_investor' }, { value: value }));

    return { placeholder, tooltip: tooltip.join('. ') };
  }

  // step message
  if (dDetails.config.investmentStep > 1) {
    tooltip.push(
      formatMessage(
        { id: 'deals.step' },
        { value: formatMoney(moveDecimalPoint(dDetails.config.investmentStep, -2)) },
      ),
    );
  }

  // min. amount message
  if (dDetails.config.minInvestment && dDetails.config.minInvestment > 1) {
    const value = formatMoney(moveDecimalPoint(dDetails.config.minInvestment, -2));

    placeholder = formatMessage({ id: 'deals.min_amount_to_invest.mini' }, { value: value });

    tooltip.push(formatMessage({ id: 'deals.min_amount_to_invest' }, { value: value }));
  }

  // max. amount message
  if (dDetails.config.maxInvestment) {
    if (
      dDetails.config.maxInvestment === dDetails.common.totalRestToCommit ||
      dDetails.config.maxInvestment + dDetails.config.investmentStep >=
        dDetails.common.totalRestToCommit
    ) {
      const trueValue =
        dDetails.config.maxInvestment + dDetails.config.investmentStep ===
        dDetails.common.totalRestToCommit
          ? dDetails.common.totalRestToCommit
          : dDetails.config.maxInvestment;

      const value = formatMoney(moveDecimalPoint(trueValue, -2));

      if (!placeholder) {
        placeholder = formatMessage({ id: 'deals.max_amount_to_invest.mini' }, { value: value });
      }

      tooltip.push(formatMessage({ id: 'deals.max_amount_to_invest' }, { value: value }));
    } else {
      const value = formatMoney(moveDecimalPoint(dDetails.config.maxInvestment, -2));
      const total = formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2));

      if (!placeholder) {
        placeholder = formatMessage({ id: 'deals.max_amount_to_invest.mini' }, { value: total });
      }

      tooltip.push(
        formatMessage({ id: 'deals.max_amount_to_invest_with_total' }, { value, total }),
      );
    }
  }

  return { placeholder, tooltip: tooltip.join('. ') };
};

const validate = (
  amount: number,
  dDetails: IDealDetails,
  formatMessage: IntlFormatters['formatMessage'],
  formatMoney: (value: number, config?: any) => string,
) => {
  // validate last investor
  if (dDetails.common.isLastInvestor && amount !== dDetails.common.totalRestToCommit) {
    return formatMessage(
      { id: 'deals.error.last_investor' },
      { value: formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2)) },
    );
  }

  // validate min amount
  if (amount < dDetails.config.minInvestment) {
    return formatMessage(
      { id: 'deals.error.min_amount' },
      { value: formatMoney(moveDecimalPoint(dDetails.config.minInvestment, -2)) },
    );
  }

  // validate max amount
  if (
    dDetails.config.maxInvestment &&
    amount > dDetails.config.maxInvestment &&
    amount !== dDetails.common.totalRestToCommit
  ) {
    if (dDetails.config.maxInvestment === dDetails.common.totalRestToCommit) {
      return formatMessage(
        { id: 'deals.error.max_amount' },
        { value: formatMoney(moveDecimalPoint(dDetails.config.maxInvestment, -2)) },
      );
    } else if (
      dDetails.config.maxInvestment + dDetails.config.investmentStep >=
      dDetails.common.totalRestToCommit
    ) {
      return formatMessage(
        { id: 'deals.error.max_amount' },
        { value: formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2)) },
      );
    } else {
      return formatMessage(
        { id: 'deals.error.max_amount_with_total' },
        {
          value: formatMoney(moveDecimalPoint(dDetails.config.maxInvestment, -2)),
          total: formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2)),
        },
      );
    }
  }

  // validate step amount
  if (dDetails.config.investmentStep && amount % dDetails.config.investmentStep !== 0) {
    let minNearest = amount - (amount % dDetails.config.investmentStep);
    // prettier-ignore
    if (dDetails.config.minInvestment && minNearest < dDetails.config.minInvestment) {
      minNearest = dDetails.config.minInvestment;
    }

    let maxNearest = minNearest + dDetails.config.investmentStep;
    // prettier-ignore
    if (dDetails.config.maxInvestment && maxNearest > dDetails.config.maxInvestment) {
      maxNearest = dDetails.config.maxInvestment;
    }

    return formatMessage(
      { id: 'deals.error.step' },
      {
        value: formatMoney(moveDecimalPoint(dDetails.config.investmentStep, -2), {
          prefix: '',
        }),
        minNearest: formatMoney(moveDecimalPoint(minNearest, -2), { prefix: '' }),
        maxNearest: formatMoney(moveDecimalPoint(maxNearest, -2), { prefix: '' }),
      },
    );
  }

  return null;
};

const InvestmentFieldComponent = ({
  deal,
  investorId,
  onChange,
  changeType,
  onError,
  initialAmount,
  displayInvestedAmount = true,
  className,
  disabled,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const [amount, setAmount] = useControlledState(initialAmount);
  const [tooltip, setTooltip] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const formatMoney = useFormatMoney({ prefix: `${deal.currency} ` });

  const dealDetails = useMemo(() => {
    return getDealDetails(deal, investorId);
  }, [deal, investorId]);

  const helperMessages = useMemo(
    () => getFieldMessages(dealDetails, intl.formatMessage, formatMoney),
    [dealDetails, intl.formatMessage, formatMoney],
  );

  const tooltipMessage = useMemo(() => {
    return error ? error! : helperMessages.tooltip ?? null;
  }, [error, helperMessages.tooltip]);

  useEffect(() => {
    if (amount === undefined || !deal.configuration) return setError(null);
    const trueAmount = moveDecimalPoint(amount, 2);

    return setError(validate(trueAmount, dealDetails, intl.formatMessage, formatMoney));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, dealDetails, deal.configuration, intl.formatMessage, formatMoney]);

  useEffect(() => {
    if (onError) onError(!!error);
  }, [error, onError]);

  const investedAmount = useMemo(() => {
    return displayInvestedAmount && dealDetails.common.investorCommittedAmount
      ? formatMoney(moveDecimalPoint(dealDetails.common.investorCommittedAmount, -2))
      : null;
  }, [dealDetails.common.investorCommittedAmount, formatMoney, displayInvestedAmount]);

  const openTooltip = useCallback(() => {
    if (tooltipMessage) setTooltip(true);
  }, [tooltipMessage]);

  const closeTooltip = useCallback(() => {
    setTooltip(false);
  }, []);

  changeType = changeType ?? 'blur';

  return (
    <Tooltip
      open={tooltip}
      title={tooltipMessage}
      componentsProps={{
        tooltip: { className: clsx({ [classes.errorTooltip]: error }) },
      }}
    >
      <Box className={classes.wrapper}>
        {investedAmount && (
          <Typography className={classes.investedAmount}>{investedAmount}</Typography>
        )}

        <TextField
          size="small"
          className={clsx(classes.investmentInput, { labeled: !!investedAmount }, className)}
          error={!!error}
          InputProps={{
            // @ts-ignore: incorrect interfaces
            inputComponent: NumericFormat,
            inputProps: {
              prefix: `${deal.currency} `,
              thousandSeparator: '’',
              allowNegative: false,
              decimalScale: 2,
              fixedDecimalScale: true,
              value: amount ?? '',
              placeholder: helperMessages.placeholder,
              onValueChange: (e: NumberFormatValues, s: any) => {
                setAmount(e.floatValue);
                if (changeType === 'change') onChange(e.floatValue);
              },
              onFocus: () => {
                openTooltip();
              },
              onBlur: () => {
                closeTooltip();
                if (changeType === 'blur') onChange(amount);
              },
            },
          }}
          disabled={disabled}
        />
      </Box>
    </Tooltip>
  );
};

export const InvestmentField = InvestmentFieldComponent;

import { Skeleton, styled } from '@mui/material';
import clsx from 'clsx';

import { useSelectedInvestorAccount } from 'hooks';

const SSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
  height: '72px',

  '&:not(.table-layout)': {
    height: '317px',

    '&.view-only': {
      height: '245px !important',
    },
  },
}));

interface Props {
  tableLayout?: boolean;
}

export const PrimaryInvestmentRecordSkeleton = ({ tableLayout }: Props) => {
  const { isViewOnly } = useSelectedInvestorAccount();

  return (
    <SSkeleton
      variant="rounded"
      className={clsx({ 'table-layout': tableLayout }, { 'view-only': isViewOnly })}
    />
  );
};

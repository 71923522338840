/**
 * Try to get number from any value
 * @param value - value, that will be validated
 * @param defaultValue - fallback value if given value is not valid
 * @param strict - in a strict mode method validates only `strings` and `numbers`.
 *   For any other types of value, `defaultValue` will be returned.
 */
export const getNumber = (value: any, defaultValue: number, strict = false) => {
  if (strict && !['string', 'number'].includes(typeof value)) {
    return defaultValue;
  }

  const num = Number(value);
  return Number.isNaN(num) ? defaultValue : num;
};

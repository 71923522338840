import { useState } from 'react';
import { Box, IconButton, Tooltip, Typography, alpha, emphasize } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { RiCloseLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { IDealDto } from 'api/investor-client';
import { IPrimaryBasketDeal, useBasketProvider } from 'providers/basket';
import { CurrencyIcon, InlineTooltip, ProductTypeChip, RiskChip } from 'components/UI';
import { ConfirmationDialog, InvestedTotalProgress } from 'components';
import { useLink, useSelectedInvestorAccount } from 'hooks';
import { DealRating, DealType } from 'types';

import { DealAmountInput } from './DealAmountInput';
import { useTableRowStyles } from '../styles';

interface Props {
  record: IDealDto;
  isReadonly?: boolean;
  tableLayout?: boolean;
}

interface ViewComponentProps extends Props {
  basketDeal?: IPrimaryBasketDeal | null;
  onBasketRemove: () => void;
}

const useCardViewStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.07),
    },

    '&.in-basket': {
      backgroundColor: '#FBFCFF',
      borderColor: '#00639A1C',

      '&:hover': {
        backgroundColor: emphasize('#FBFCFF', 0.016),
      },
    },
  },

  cardHeader: {
    display: 'flex',
    gap: '16px',
    padding: '24px 16px',
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,

    '&.in-basket': {
      borderColor: '#00639A1C',
    },
  },

  cancelBtn: {
    height: 'fit-content',
    marginTop: '-16px',
    marginRight: '-8px',
    fontSize: '18px',
  },

  id: {
    flexGrow: 1,
    textDecoration: 'underline',
    overflow: 'hidden',
  },

  chipsGroup: {
    display: 'flex',
    gap: '8px',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    padding: '24px 16px 16px',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexBasis: '40%',
    overflow: 'hidden',
  },

  textGroupHeader: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textTransform: 'uppercase',
  },

  tooltip: {
    color: alpha(theme.palette.text.secondary, 0.36),
    fontSize: '16px',
    lineHeight: '16px',
  },

  cardFooter: {
    padding: '16px',
  },
}));

const PrimaryDealRecordCardView = (props: ViewComponentProps) => {
  const { record: deal, basketDeal, isReadonly, tableLayout, onBasketRemove } = props;

  const classes = useCardViewStyles();
  const intl = useIntl();
  const getLink = useLink();

  const { isViewOnly } = useSelectedInvestorAccount();

  const inBasket = !!basketDeal;

  return (
    <Box className={clsx(classes.card, { 'in-basket': inBasket })}>
      <Box component={Link} to={getLink(`${deal.providerId}/${deal.id}`, 'continue-url')}>
        <Box className={clsx(classes.cardHeader, { 'in-basket': inBasket })}>
          <Box className={classes.id}>
            <Typography variant="subtitle2" noWrap>
              {deal.id}
            </Typography>
          </Box>

          <Box className={classes.chipsGroup}>
            <CurrencyIcon currency={deal.currency} size={24} />
            <RiskChip rating={deal.risk.rating as DealRating} />
            <ProductTypeChip productType={deal.risk.productType as DealType} />
          </Box>

          {inBasket && (
            <IconButton
              className={classes.cancelBtn}
              onClick={(e) => {
                e.preventDefault();
                onBasketRemove();
              }}
            >
              <RiCloseLine />
            </IconButton>
          )}
        </Box>

        <Box className={classes.cardBody}>
          <Box className={classes.row}>
            <Box className={classes.textGroup}>
              <Box display="flex" alignItems="center" gap="8px">
                <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                  <FormattedMessage id="irr" />
                </Typography>

                <InlineTooltip
                  tooltip={intl.formatMessage({ id: 'irr.description_tooltip' })}
                  className={classes.tooltip}
                />
              </Box>

              <Box display="flex" gap="0px 4px" alignItems="center" flexWrap="wrap">
                <Typography variant="subtitle2" noWrap>
                  {typeof deal.projectedXirr === 'number'
                    ? `${(deal.projectedXirr * 100).toFixed(2)}%`
                    : '~'}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.textGroup}>
              <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                <FormattedMessage id="duration" />
              </Typography>

              <Typography variant="subtitle2" noWrap>
                {deal.duration}{' '}
                {intl.formatMessage({ id: deal.duration === 1 ? 'month' : 'months' })}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.row}>
            {!!deal.dealProviderShare && (
              <InvestedTotalProgress
                currency={deal.currency}
                investedAmount={deal.dealProviderShare.amount - deal.dealProviderShare.unpaidAmount}
                totalAmount={deal.dealProviderShare.amount}
                tableLayout={tableLayout}
              />
            )}
          </Box>
        </Box>
      </Box>

      {!isViewOnly && (
        <Box className={classes.cardFooter}>
          <DealAmountInput deal={deal} basketDeal={basketDeal} isReadonly={isReadonly} />
        </Box>
      )}
    </Box>
  );
};

const useRowViewStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    overflow: 'hidden',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },

    '&.in-basket': {
      backgroundColor: '#FBFCFF',
      borderColor: '#00639A1C',

      '&:hover': {
        backgroundColor: emphasize('#FBFCFF', 0.015),
      },
    },
  },

  dealTooltip: {
    maxWidth: 'fit-content',
  },

  linkContainer: {
    display: 'flex',
    flexGrow: 1,
    cursor: 'pointer',
  },

  rowWrapper: {
    width: '100%',
    display: 'flex',
  },
}));

const PrimaryDealRecordRowView = (props: ViewComponentProps) => {
  const { record: deal, basketDeal, isReadonly, tableLayout, onBasketRemove } = props;

  const tableRowClasses = useTableRowStyles();
  const classes = useRowViewStyles();
  const intl = useIntl();
  const getLink = useLink();

  const { isViewOnly } = useSelectedInvestorAccount();

  return (
    <Box className={clsx(classes.card, { 'in-basket': !!basketDeal })}>
      <Tooltip
        title={`${intl.formatMessage({ id: 'deal.id' })}: ${deal.id}`}
        componentsProps={{
          tooltip: { className: classes.dealTooltip },
          popper: { modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] },
        }}
        followCursor
      >
        <Link
          to={getLink(`${deal.providerId}/${deal.id}`, 'continue-url')}
          className={classes.linkContainer}
        >
          <Box className={classes.rowWrapper}>
            <Box className={clsx(tableRowClasses.cell, tableRowClasses.currencyCell)}>
              <CurrencyIcon currency={deal.currency} size={24} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.riskCell)}>
              <RiskChip rating={deal.risk.rating as DealRating} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.productTypeCell)}>
              <ProductTypeChip productType={deal.risk.productType as DealType} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.durationCell)}>
              <Typography variant="subtitle3" noWrap>
                {deal.duration}{' '}
                {intl.formatMessage({ id: deal.duration === 1 ? 'month' : 'months' })}
              </Typography>
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.irrCell)}>
              <Typography variant="subtitle3" noWrap>
                {typeof deal.projectedXirr === 'number'
                  ? `+ ${(deal.projectedXirr * 100).toFixed(2)}%`
                  : '~'}
              </Typography>
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.dealSizeCell)}>
              {!!deal.dealProviderShare && (
                <InvestedTotalProgress
                  currency={deal.currency}
                  investedAmount={deal.dealProviderShare.amount - deal.dealProviderShare.unpaidAmount}
                  totalAmount={deal.dealProviderShare.amount}
                  tableLayout={tableLayout}
                />
              )}
            </Box>
          </Box>

          {isViewOnly && <Box className={clsx(tableRowClasses.cell, tableRowClasses.inputCell)} />}
        </Link>
      </Tooltip>

      {!isViewOnly && (
        <Box className={clsx(tableRowClasses.cell, tableRowClasses.inputCell)}>
          <DealAmountInput
            deal={deal}
            basketDeal={basketDeal}
            isReadonly={isReadonly}
            onCancel={onBasketRemove}
          />
        </Box>
      )}
    </Box>
  );
};

export const PrimaryDealRecord = (props: Props) => {
  const basket = useBasketProvider();
  const intl = useIntl();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleRemoveDeal = () => {
    setConfirmationOpen(true);
  };

  const removeDeal = (result?: boolean) => {
    setConfirmationOpen(false);

    if (result) {
      basket.primary.removeDeal(props.record);
      toast.info(intl.formatMessage({ id: 'deal.details.removal_investment_success' }));
    }
  };

  const basketDeal = basket.primary.getDeal(props.record);

  const ViewComponent = props.tableLayout ? PrimaryDealRecordRowView : PrimaryDealRecordCardView;

  return (
    <>
      <ViewComponent {...props} basketDeal={basketDeal} onBasketRemove={handleRemoveDeal} />

      <ConfirmationDialog
        open={confirmationOpen}
        strings={{
          title: intl.formatMessage({ id: 'are_you_sure' }),
          subtitle: intl.formatMessage({ id: 'delete' }),
          acceptText: intl.formatMessage({ id: 'yes_delete' }),
        }}
        onClose={removeDeal}
      />
    </>
  );
};

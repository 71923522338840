import { makeStyles } from '@mui/styles';

export const useDialogStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      width: '424px',
    },

    [theme.breakpoints.up('xxxl')]: {
      width: '480px',
    },
  },
}));

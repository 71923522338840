import { useState } from 'react';
import { Box, ClickAwayListener, Tooltip, styled } from '@mui/material';
import { RiInformationLine } from 'react-icons/ri';

const SWrapper = styled(Box)({
  cursor: 'help',
  lineHeight: 'inherit',
  verticalAlign: 'middle',
});

const SIcon = styled(RiInformationLine)({
  verticalAlign: '-0.125em',
});

interface Props {
  tooltip: string;
  size?: number;
  className?: string;
}

export const InlineTooltip = ({ tooltip, size, className }: Props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip title={tooltip} open={open}>
        <SWrapper
          component="span"
          onClick={handleTooltipOpen}
          onMouseOver={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
          className={className}
          fontSize={size ?? 'inherit'}
        >
          <SIcon size={size ?? '1em'} />
        </SWrapper>
      </Tooltip>
    </ClickAwayListener>
  );
};

import React from 'react';

import { InvestorUserAccessDto } from 'api/investor-client';
import { AccountWithBalance } from './provider';

const stub = () => {
  throw new Error('<InvestorAccountsProvider> not found.');
};

export interface IInvestorAccountsContext {
  accounts: InvestorUserAccessDto[];
  selectedAccount: AccountWithBalance | null;
  selectAccount: (accountId?: string) => boolean;
  updateAccountBalance: () => void;
  updateInvestorToken: () => void;
  accountBalanceLoading: boolean;
  loading: boolean;
  initiallyLoaded: boolean;
  refresh: (withDefaultAccountSelection?: boolean) => Promise<void> | void;
}

export const InvestorAccountsContext =
  React.createContext<IInvestorAccountsContext>({
    accounts: [],
    selectedAccount: null,
    selectAccount: stub,
    updateAccountBalance: stub,
    updateInvestorToken: stub,
    accountBalanceLoading: false,
    loading: false,
    initiallyLoaded: false,
    refresh: stub,
  });

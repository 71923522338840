import { Accordion, AccordionSummary, Typography, AccordionDetails, Box } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { RiArrowDownSFill } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';

import { InlineBadge } from 'components/UI';
import { InlineSelect, AmountTextField } from 'components/forms';

import { useCommonFiltersStyles } from '../useCommonFiltersStyles';
import { useProductTypeOptions, useRatingOptions, useCurrencyOptions } from '../options';
import { primaryDealFiltersHelper } from './helpers';
import { CommonPrimaryDealFilterValues } from './types';

interface Props {
  accordionExpanded?: boolean;
}

export const CommonFilters = ({ accordionExpanded }: Props) => {
  const classes = useCommonFiltersStyles();
  const intl = useIntl();

  const formik = useFormikContext<CommonPrimaryDealFilterValues>();

  const productTypeOptions = useProductTypeOptions(intl);
  const ratingOptions = useRatingOptions();
  const currencyOptions = useCurrencyOptions();

  const amountCount = primaryDealFiltersHelper.countAmountValues(formik.values);
  const durationCount = primaryDealFiltersHelper.countDurationValues(formik.values);

  return (
    <>
      <Accordion className={classes.accordion} elevation={0} defaultExpanded={accordionExpanded}>
        <AccordionSummary expandIcon={<RiArrowDownSFill />}>
          <Typography>
            <FormattedMessage id="borrower.type" />
          </Typography>

          {formik.values.productType.length > 0 && (
            <InlineBadge label={formik.values.productType.length} />
          )}
        </AccordionSummary>

        <AccordionDetails>
          <Field
            id="productType"
            name="productType"
            component={InlineSelect}
            options={productTypeOptions}
            multiple
            className={classes.inlineSelect}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} elevation={0} defaultExpanded={accordionExpanded}>
        <AccordionSummary expandIcon={<RiArrowDownSFill />}>
          <Typography>
            <FormattedMessage id="loan_currency" />
          </Typography>

          {formik.values.currency.length > 0 && (
            <InlineBadge label={formik.values.currency.length} />
          )}
        </AccordionSummary>

        <AccordionDetails>
          <Field
            id="currency"
            name="currency"
            component={InlineSelect}
            options={currencyOptions}
            multiple
            className={classes.inlineSelect}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} elevation={0} defaultExpanded={accordionExpanded}>
        <AccordionSummary expandIcon={<RiArrowDownSFill />}>
          <Typography>
            <FormattedMessage id="loan_amount" />
          </Typography>

          {amountCount > 0 && <InlineBadge label={amountCount} />}
        </AccordionSummary>

        <AccordionDetails>
          <Box className={classes.rangeInput}>
            <Field
              id="minPrincipal"
              name="minPrincipal"
              component={AmountTextField}
              label={intl.formatMessage({ id: 'min_amount' })}
              size="medium"
            />

            <Box className={classes.dash} />

            <Field
              id="maxPrincipal"
              name="maxPrincipal"
              component={AmountTextField}
              label={intl.formatMessage({ id: 'max_amount' })}
              size="medium"
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} elevation={0} defaultExpanded={accordionExpanded}>
        <AccordionSummary expandIcon={<RiArrowDownSFill />}>
          <Typography>
            <FormattedMessage id="risk_type" />
          </Typography>

          {formik.values.rating.length > 0 && <InlineBadge label={formik.values.rating.length} />}
        </AccordionSummary>

        <AccordionDetails>
          <Field
            id="rating"
            name="rating"
            component={InlineSelect}
            options={ratingOptions}
            multiple
            className={classes.inlineSelect}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} elevation={0} defaultExpanded={accordionExpanded}>
        <AccordionSummary expandIcon={<RiArrowDownSFill />}>
          <Typography>
            <FormattedMessage id="loan_duration.in_months" />
          </Typography>

          {durationCount > 0 && <InlineBadge label={durationCount} />}
        </AccordionSummary>

        <AccordionDetails>
          <Box className={classes.rangeInput}>
            <Field
              id="minDuration"
              name="minDuration"
              component={AmountTextField}
              label={intl.formatMessage({ id: 'min_duration' })}
              size="medium"
              inputProps={{
                thousandSeparator: false,
                decimalScale: 0,
              }}
            />
            <Box className={classes.dash} />

            <Field
              id="maxDuration"
              name="maxDuration"
              component={AmountTextField}
              label={intl.formatMessage({ id: 'max_duration' })}
              size="medium"
              inputProps={{
                thousandSeparator: false,
                decimalScale: 0,
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

import { useRouteMatch } from 'react-router';
import { Badge, IconButton, Theme, alpha, styled, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { RiBriefcaseLine } from 'react-icons/ri';
import urljoin from 'url-join';

import { useBasketProvider } from 'providers/basket';

const SBadge = styled(Badge)(({ theme }) => ({
  cursor: 'pointer',

  '& .MuiBadge-badge': {
    padding: '0 4px',
    outline: `2px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.primary.light,

    [theme.breakpoints.up('sm')]: {
      minWidth: '16px',
      height: '16px',
      outline: `2px solid ${theme.palette.background.paper}`,
    },
  },
}));

const SIconButton = styled(IconButton)(({ theme }) => ({
  margin: '-8px',

  '&.active': {
    backgroundColor: alpha(theme.palette.text.secondary, 0.08),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.08),
    },
  },
}));

interface Props {}

export const BasketWidget = (props: Props) => {
  const basket = useBasketProvider();

  const currentRouterPath = useRouteMatch();
  const activeUrl = urljoin(currentRouterPath.url, 'deals/basket');
  const activeRouterPath = useRouteMatch(activeUrl);

  const isTabletSize = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const totalLength = basket.primary.deals.length + basket.secondary.deals.length;

  return (
    <Link to={activeUrl}>
      <SBadge
        badgeContent={totalLength}
        color="primary"
        variant={isTabletSize ? 'standard' : 'dot'}
      >
        <SIconButton color="inherit" className={activeRouterPath ? 'active' : undefined}>
          <RiBriefcaseLine size={24} />
        </SIconButton>
      </SBadge>
    </Link>
  );
};

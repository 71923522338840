import { FormEvent, useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  alpha,
  colors,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiBriefcaseLine, RiCloseLine, RiPencilLine } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { IDealDto } from 'api/investor-client';
import { IPrimaryBasketDeal, useBasketProvider } from 'providers/basket';
import { AmountInputBase } from 'components/forms';
import { useFormatMoney, useDealAmountInput } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  inputField: {
    width: '100%',
    display: 'flex',
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.38)}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'initial',
    outline: `1px solid transparent`,
    transition: theme.transitions.create(['border-color', 'outline'], {
      duration: 150,
    }),

    '&:not(.disabled):not(.error):hover': {
      borderColor: theme.palette.primary.light,
    },

    '&.disabled': {
      borderColor: alpha(theme.palette.text.secondary, 0.24),
    },

    '&.focus': {
      borderColor: theme.palette.primary.light,
      outline: `1px solid ${theme.palette.primary.light}`,

      '&.error': {
        outlineColor: colors.red[200],
      },
    },

    '&.error': {
      borderColor: colors.red[200],
    },

    '&.action-disabled': {
      overflow: 'hidden',
    },
  },

  input: {
    flexGrow: 1,
    ...(theme.typography.body2 as any),

    '& .MuiInputBase-input': {
      height: 'auto',
      padding: '8px',
    },

    '&:not(.table-layout)': {
      ...(theme.typography.body1 as any),

      '&:not(.Mui-focused) .MuiInputBase-input': {
        textAlign: 'center',
      },
    },
  },

  inputButton: {
    minWidth: 'auto',
    padding: '9px',
    margin: '-1px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: theme.shape.borderRadius,
  },

  submitButton: {
    width: '100%',
  },

  amountDisplayContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  displayButton: {
    width: '100%',
    flexGrow: 1,
    padding: '9px 16px',

    '&:hover': {
      '& .MuiButton-endIcon': {
        opacity: 1,
      },
    },

    '&:has(> .MuiButton-endIcon)': {
      paddingLeft: '42px',
    },

    '& .MuiButton-endIcon': {
      opacity: 0,
      marginRight: 0,
      color: theme.palette.primary.light,
      transition: theme.transitions.create(['opacity'], {
        duration: 250,
      }),
    },
  },

  investAgainButton: {
    width: '100%',
    flexGrow: 1,
    padding: '7px 14px',
  },

  deleteAmountButton: {
    marginRight: '-8px',
    color: theme.palette.secondary.dark,
  },
}));

interface Props {
  deal: IDealDto;
  basketDeal?: IPrimaryBasketDeal | null;
  isReadonly?: boolean;
  onCancel?: () => void;
}

export const DealAmountInput = ({ deal, basketDeal, isReadonly, onCancel }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const basket = useBasketProvider();
  const formatMoney = useFormatMoney({ prefix: `${deal.currency} ` });

  // prettier-ignore
  const { dealDetails, amount, setAmount, placeholder, isError, tooltipMessage, loaded } = useDealAmountInput(deal);
  const [tooltip, setTooltip] = useState(false);

  const formRef = useRef<HTMLElement | null>(null);

  const investAgain = !!dealDetails?.common.isInvestorCommitted;
  const viewModeAccessible = investAgain || basketDeal;

  const [editMode, setEditMode] = useState(!viewModeAccessible);
  const [enterEditModeByClick, setEnterEditModeByClick] = useState(false);
  const [amountInput, setAmountInput] = useState<HTMLInputElement | null>();

  useLayoutEffect(() => {
    if (!viewModeAccessible) setEditMode(true);
  }, [viewModeAccessible]);

  useLayoutEffect(() => {
    if (amountInput && enterEditModeByClick) {
      amountInput.querySelector('input')?.focus();
      setEnterEditModeByClick(false);
    }
  }, [amountInput, enterEditModeByClick]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (amount) {
      basket.primary.setDeal({
        id: deal.id,
        providerId: deal.providerId,
        currency: deal.currency,
        amount: moveDecimalPoint(amount, 2),
      });

      toast.success(intl.formatMessage({ id: 'deal.details.select_investment_success' }));
      setEditMode(false);
    } else {
      removeDeal();
      setEditMode(true);
    }

    setAmount(undefined);
  };

  const removeDeal = () => {
    basket.primary.removeDeal(deal);
    toast.info(intl.formatMessage({ id: 'deal.details.removal_investment_success' }));
  };

  const enterEditMode = () => {
    if (viewModeAccessible) {
      setEditMode(true);
      setEnterEditModeByClick(true);
      setAmount(typeof basketAmount === 'number' ? moveDecimalPoint(basketAmount, -2) : undefined);
    }
  };

  const isTabletView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const basketAmount = basketDeal?.amount;
  const controlDisabled = isReadonly || !loaded;
  const submitDisabled = controlDisabled || isError || !amount;

  if (dealDetails?.common.isInvestorCommitted && !basketDeal && !editMode) {
    return (
      <Button variant="outlined" className={classes.investAgainButton} onClick={enterEditMode}>
        <Typography variant={isTabletView ? 'subtitle3' : 'subtitle2'} noWrap>
          <FormattedMessage id="invest_again" />
        </Typography>
      </Button>
    );
  }

  if (!editMode) {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.amountDisplayContainer}>
          <Button
            color="secondary"
            className={classes.displayButton}
            onClick={enterEditMode}
            endIcon={<RiPencilLine size={18} />}
          >
            <Typography variant={isTabletView ? 'subtitle3' : 'subtitle2'} width="100%" noWrap>
              {formatMoney(moveDecimalPoint(basketAmount ?? 0, -2))}
            </Typography>
          </Button>

          {onCancel && (
            <IconButton className={classes.deleteAmountButton} onClick={onCancel}>
              <RiCloseLine size={18} />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => viewModeAccessible && setEditMode(false)}>
      <Box component="form" ref={formRef} onSubmit={handleSubmit} className={classes.wrapper}>
        <Tooltip
          open={tooltip}
          placement={isTabletView ? 'bottom' : 'top'}
          title={tooltipMessage ?? ''}
          color={isError ? 'error' : undefined}
        >
          <Box
            className={clsx(
              classes.inputField,
              { error: isError },
              { focus: tooltip },
              { disabled: controlDisabled },
              { 'action-disabled': submitDisabled },
            )}
          >
            <AmountInputBase
              ref={setAmountInput}
              currency={deal.currency as Currency}
              className={clsx(classes.input, { 'table-layout': isTabletView })}
              placeholder={placeholder}
              disabled={controlDisabled}
              value={amount}
              inputProps={{
                onValueChange: (data) => setAmount(data.floatValue),
                onFocus: () => setTooltip(true),
                onBlur: () => setTooltip(false),
              }}
            />

            {isTabletView && (
              <Button
                type="submit"
                color="primary"
                className={classes.inputButton}
                disabled={submitDisabled}
              >
                <RiBriefcaseLine size={18} />
              </Button>
            )}
          </Box>
        </Tooltip>

        {!isTabletView && (
          <Button
            type="submit"
            color="primary"
            className={classes.submitButton}
            disabled={submitDisabled}
            startIcon={<RiBriefcaseLine size={18} />}
          >
            <Typography variant="subtitle3">
              <FormattedMessage id="add_to_basket" />
            </Typography>
          </Button>
        )}
      </Box>
    </ClickAwayListener>
  );
};

import { PropsWithChildren, useCallback, useState } from 'react';

import { LayoutContext } from './context';

interface Props {
  defaultTitle?: string;
}

export const LayoutProvider = (props: PropsWithChildren<Props>) => {
  const [title, setTitle] = useState(props.defaultTitle ?? 'Loantrade');

  const handleSetTitle = useCallback((title: string | null) => {
    setTitle(title ?? props.defaultTitle ?? 'Loantrade');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        title,
        setTitle: handleSetTitle,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};

import { Box, CSSObject, Theme, Typography, styled } from '@mui/material';
import { RiBuilding2Line, RiCarLine, RiHome2Line } from 'react-icons/ri';

import { mixinObject, restrictProp } from 'utils';
import { DealType } from 'types';
import { FormattedMessage } from 'react-intl';

type TSize = 'small' | 'medium' | 'large';

interface Props {
  productType: DealType;
  size?: TSize;
}

const getColor = (productType: DealType, theme: Theme) => {
  switch (productType) {
    case DealType.CORPORATE:
      return '#889CAA';

    case DealType.PRIVATE:
      return '#4A5C68';

    case DealType.REAL_ESTATE:
      return '#3e4c56';

    // case DealType.TBC:
    //   return '#122554';

    default:
      return theme.palette.primary.dark;
  }
};

const getIcon = (productType: DealType) => {
  switch (productType) {
    case DealType.CORPORATE:
      return <RiHome2Line size={24} />;

    case DealType.PRIVATE:
      return <RiCarLine size={24} />;

    case DealType.REAL_ESTATE:
      return <RiBuilding2Line size={24} />;

    // case DealType.TBC:
    //   return <Logo width={24} />;

    default:
      return <RiHome2Line size={24} />;
  }
};

const SWrapper = styled(Box, {
  shouldForwardProp: restrictProp(['size', 'productType']),
})<Props>(({ theme, size, productType }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,

  ...mixinObject<CSSObject>(size === 'medium' || size === 'small', {
    padding: '2px 8px',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    backgroundColor: getColor(productType, theme),
  }),

  ...mixinObject<CSSObject>(size === 'large', {
    padding: '8px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
  }),
}));

export const ProductTypeChip = ({ productType, size = 'medium' }: Props) => {
  return (
    <SWrapper productType={productType} size={size}>
      {size === 'large' ? (
        getIcon(productType)
      ) : (
        <Typography variant="subtitle3">
          <FormattedMessage id={`borrower.${productType}${size === 'small' ? '.short' : ''}`} />
        </Typography>
      )}
    </SWrapper>
  );
};

import { useMemo } from 'react';
import {
  Filters,
  TableOptions,
  useAsyncDebounce,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
  SortingRule,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useIntl } from 'react-intl';
import {
  Box,
  Table as MUTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableProps,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdArrowDropDown } from 'react-icons/md';
import SimpleBar from 'simplebar-react';
import clsx from 'clsx';

import { SortOrder } from 'types';

const useStyles = makeStyles(() => ({
  scrollbar: {
    height: 'inherit',
    maxHeight: 'inherit',
    width: 'inherit',
    maxWidth: 'inherit',
  },

  stickyCell: {
    background: '#fff',
  },

  hoverTableRow: {
    cursor: 'pointer',

    '&:hover': {
      background: '#f9f9f9',
      borderRadius: 25,
      overflow: 'hidden',
    },
  },

  emptyTableMessage: {
    fontStyle: 'italic',
    fontSize: '16px',
    border: 'none',
  },
}));

interface FiltersChangeObject<T extends Record<string, any>> {
  filters: Filters<T>;
  sort: Array<SortingRule<T>>;
  pageSize: number;
  pageIndex: number;
}

export interface TableProperties<T extends Record<string, any>> extends TableOptions<T> {
  onFiltersChange?: (data: FiltersChangeObject<T>) => void;
  rowOnClick?: (e: any, row: any) => void;
  rowUrl?: (row: any) => string;
  initialFilters?: Filters<T>;
  initialSort?: Array<SortingRule<T>>;
  count?: number;
  pageSize?: number;
  pageIndex?: number;
  withPagination?: boolean;
  className?: string;
  stickyHeader?: boolean;
  noRowsIntlName?: string;
  size?: TableProps['size'];
}

export function Table<T extends Record<string, any>>(props: TableProperties<T>) {
  const {
    initialFilters = [],
    initialSort = [],
    pageSize,
    pageIndex,
    columns,
    data,
    count = data.length,
    onFiltersChange,
    withPagination = true,
    size,
    className,
    noRowsIntlName = 'no_rows_found',
    sortable = true,
    stickyHeader = false,
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  // prettier-ignore
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    gotoPage,
    setPageSize,
  } = useTable<T>(
    {
      columns,
      data,
      useControlledState: (state: any) => {
        return useMemo(
          () => ({
            ...state,
            pageCount: count / state.pageSize,
          }),
          [state],
        );
      },
      initialState: {
        pageSize,
        pageIndex,
        filters: initialFilters,
        sortBy: initialSort,
      },
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      pageCount: Math.ceil(count / 10),
      disableSortBy: !sortable,
    },
    useFilters,
    useSortBy,
    usePagination,
    useSticky,
  );

  const filtersChangeHandler = useMemo(() => onFiltersChange ?? (() => {}), [onFiltersChange]);
  const onFiltersChangeDebounced = useAsyncDebounce(filtersChangeHandler, 500);

  useDeepCompareEffect(() => {
    onFiltersChangeDebounced({
      filters: state.filters,
      sort: state.sortBy,
      pageSize: state.pageSize,
      pageIndex: state.pageIndex,
    });
  }, [state.filters, state.sortBy, state.pageSize, state.pageIndex]);

  return (
    <Box className={className}>
      <TableContainer>
        <SimpleBar className={classes.scrollbar}>
          <MUTable size={size} stickyHeader={stickyHeader}>
            <TableHead>
              {headerGroups.map((headerGroup) => {
                const { key: groupKey, ...restGroupProps } = headerGroup.getHeaderGroupProps();

                return (
                  <TableRow key={groupKey} {...restGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const { key: cellKey, ...restCellProps } = column.getHeaderProps();

                      const sortDirection = (
                        column.isSortedDesc ? SortOrder.DESC : SortOrder.ASC
                      ).toLowerCase() as 'desc' | 'asc';

                      const cellClasses = clsx((column as any).className, restCellProps.className, {
                        [classes.stickyCell]: (restCellProps as any)['data-sticky-td'],
                      });

                      return column.canSort ? (
                        <TableCell
                          key={cellKey}
                          sortDirection={column.isSorted ? sortDirection : false}
                          {...restCellProps}
                          className={cellClasses}
                        >
                          <TableSortLabel
                            active={column.isSorted}
                            direction={sortDirection}
                            IconComponent={MdArrowDropDown}
                            {...column.getSortByToggleProps()}
                          >
                            {column.render('Header')}
                          </TableSortLabel>
                          <div>
                            {column.canFilter && column.Filter ? column.render('Filter') : null}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell key={cellKey} {...restCellProps} className={cellClasses}>
                          {column.render('Header')}
                          <div>
                            {column.canFilter && column.Filter ? column.render('Filter') : null}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                const { key, ...getRowProps } = row.getRowProps();

                return (
                  <TableRow
                    onMouseDown={
                      props.rowUrl
                        ? (e) => {
                            if (e.button === 1) {
                              window.open(props.rowUrl?.(row) || '', '_blank');
                            }
                          }
                        : undefined
                    }
                    key={key}
                    {...getRowProps}
                    {...(props.rowOnClick
                      ? {
                          onClick: (e: any) => props.rowOnClick?.(e, row),
                          className: (getRowProps.className || '') + classes.hoverTableRow,
                        }
                      : {})}
                  >
                    {row.cells.map((cell, j) => {
                      const { key, ...getCellProps } = cell.getCellProps();

                      return (
                        <TableCell
                          key={key}
                          {...getCellProps}
                          className={clsx((cell.column as any).className, getCellProps.className, {
                            [classes.stickyCell]: (getCellProps as any)['data-sticky-td'],
                          })}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

              {!rows.length && (
                <TableRow>
                  <TableCell colSpan={columns.length} className={classes.emptyTableMessage}>
                    {intl.formatMessage({ id: noRowsIntlName })}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MUTable>
        </SimpleBar>
      </TableContainer>

      {withPagination && (
        <TablePagination
          component="div"
          count={count}
          page={state.pageIndex}
          onPageChange={(event, page) => gotoPage(page)}
          rowsPerPage={state.pageSize}
          onRowsPerPageChange={(event) => setPageSize(+event.target.value)}
        />
      )}
    </Box>
  );
}

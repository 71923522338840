import { useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiShareBoxLine } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { DealStatusDto, IDealDto } from 'api/investor-client';
import { IDealEventWithShareId } from 'types';

import { ActivityTimelineEvent } from './ActivityTimelineEvent';
import { ActivityTimelineModal } from './ActivityTimelineModal';
import { useCardStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  tooltipIcon: {
    fontSize: '16px',
    marginLeft: '8px',
    verticalAlign: '-0.175em',
    transition: theme.transitions.create(['color'], {
      duration: 150,
    }),

    '&:not(.disabled)': {
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.light,
      },
    },

    '&.disabled': {
      opacity: 0.45,
    },
  },
}));

interface Props {
  deal: IDealDto;
  dealEvents: IDealEventWithShareId[];
}

export const ActivityTimelineCard = ({ deal, dealEvents }: Props) => {
  const cardClasses = useCardStyles();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  const dealIsActive = deal.status === DealStatusDto.Active;
  const showOneMoreEvent = dealIsActive && dealEvents.length > 1;

  return (
    <Card variant="outlined" className={cardClasses.card}>
      <CardContent className={cardClasses.cardContent}>
        <Typography
          variant="body2"
          color="text.secondary"
          marginBottom={dealEvents.length === 0 ? '40px' : '16px'}
        >
          <FormattedMessage id="your_activity_timeline" />
          <RiShareBoxLine
            className={clsx(classes.tooltipIcon, { disabled: dealEvents.length === 0 })}
            onClick={() => dealEvents.length !== 0 && setModalOpen(true)}
          />
        </Typography>

        {dealEvents.length === 0 ? (
          <Typography variant="body2" color="grey.400" marginBottom="16px" maxWidth={250}>
            <FormattedMessage id="activity_timeline.no_activities" />
          </Typography>
        ) : (
          <Box>
            {showOneMoreEvent && (
              <ActivityTimelineEvent
                deal={deal}
                dealEvent={dealEvents[dealEvents.length - 2]}
                elementAbove={dealEvents.length > 2}
                elementBelow
                preview
              />
            )}

            <ActivityTimelineEvent
              deal={deal}
              dealEvent={dealEvents[dealEvents.length - 1]}
              elementAbove={dealEvents.length > 1}
              preview={!showOneMoreEvent}
            />
          </Box>
        )}
      </CardContent>

      <ActivityTimelineModal
        open={modalOpen}
        deal={deal}
        dealEvents={dealEvents}
        onClose={() => setModalOpen(false)}
      />
    </Card>
  );
};

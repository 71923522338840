import { ComponentType, ComponentProps, forwardRef } from 'react';
import { InputBase, InputBaseProps } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { Currency } from 'types';
import { mixinObject } from 'utils';

type Props = Omit<InputBaseProps, 'inputComponent'> & {
  currency?: Currency;
  inputProps?: NumericFormatProps;
};

export const AmountInputBase = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { currency, inputProps, ...restProps } = props;

  return (
    <InputBase
      ref={ref}
      {...restProps}
      inputComponent={NumericFormat as ComponentType<ComponentProps<'input'>>}
      inputProps={{
        thousandSeparator: '’',
        allowNegative: false,
        decimalScale: 2,
        fixedDecimalScale: true,
        ...mixinObject(!!currency, { prefix: `${currency} ` }),
        ...inputProps,
      }}
    />
  );
});

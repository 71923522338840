import { useEffect } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import { IDealDto } from 'api/investor-client';
import { InlineTooltip } from 'components/UI';
import { useFormatMoney, useTextResize } from 'hooks';
import { IDealDetails, moveDecimalPoint } from 'utils';

import { InvestmentProgress } from './InvestmentProgress';
import { useCardStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  amountWrapper: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },

  amount: {
    display: 'inline',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },

  tooltipIcon: {
    marginLeft: '8px',
  },
}));

interface Props {
  deal: IDealDto;
  dealDetails: IDealDetails;
  amountToInvest: number;
  totalToInvest: number;
  totalInvestedAmount: number;
}

export const InvestmentCard = (props: Props) => {
  const { deal, dealDetails, amountToInvest, totalToInvest, totalInvestedAmount } = props;

  const cardClasses = useCardStyles();
  const classes = useStyles();
  const intl = useIntl();

  const formatMoney = useFormatMoney({ suffix: ` ${deal.currency}` });

  const { refText, refContent, recalculate } = useTextResize(16, 28);
  useEffect(() => recalculate(), [totalToInvest, deal.currency, recalculate]);

  return (
    <Card variant="outlined" className={cardClasses.card}>
      <CardContent className={cardClasses.cardContent}>
        <Typography variant="body2" color="text.secondary" marginBottom="16px">
          <FormattedMessage id="investment.your" />
          <InlineTooltip
            tooltip={intl.formatMessage({ id: 'deal_details.investment.tooltip' })}
            size={16}
            className={classes.tooltipIcon}
          />
        </Typography>

        <Box ref={refContent} className={classes.amountWrapper}>
          <Typography ref={refText} variant="h5" className={classes.amount}>
            {formatMoney(moveDecimalPoint(totalToInvest, -2), {
              prefix: `${deal.currency} `,
              suffix: '',
            })}
          </Typography>
        </Box>

        <Typography variant="caption" component="p" color="text.secondary" marginBottom="16px">
          <FormattedMessage
            id="partial_amount_of_total_financed"
            values={{
              amount: formatMoney(moveDecimalPoint(totalInvestedAmount, -2)),
              total: formatMoney(moveDecimalPoint(deal.principal, -2)),
            }}
          />
        </Typography>

        <InvestmentProgress deal={deal} dealDetails={dealDetails} amountToInvest={amountToInvest} />
      </CardContent>
    </Card>
  );
};

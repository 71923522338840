import { FormEvent, useState } from 'react';
import { Box, Button, IconButton, Tooltip, Typography, colors } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCloseCircleFill } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { IDealDto } from 'api/investor-client';
import { useBasketProvider } from 'providers/basket';
import { AmountTextField } from 'components/forms';
import { useDealAmountInput } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '40px 24px 24px',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',

    [theme.breakpoints.up('lg')]: {
      padding: '40px 80px 24px',
    },
  },

  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '16px',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  input: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,

      '&.MuiInputBase-adornedEnd': {
        paddingRight: '4px',

        '& .MuiButtonBase-root': {
          color: theme.palette.grey[300],

          '&:hover': {
            color: theme.palette.grey[400],
          },
        },
      },
    },

    '& .MuiInputBase-input': {
      padding: '12px',
      textAlign: 'center',
      ...theme.typography.subtitle2,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.divider,
    },

    '&.error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.red[200],
      },
    },
  },

  submitButton: {
    flexShrink: 0,
    padding: '10px 40px',
    textTransform: 'uppercase',
  },
}));

interface Props {
  deal: IDealDto;
  amount: number | undefined;
  onAmountChange(value: number | undefined): void;
}

export const AddInvestment = ({ deal, amount, onAmountChange }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const basket = useBasketProvider();

  const { placeholder, isError, tooltipMessage, loaded } = useDealAmountInput(deal, amount);
  const [tooltip, setTooltip] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (amount) {
      basket.primary.setDeal({
        id: deal.id,
        providerId: deal.providerId,
        currency: deal.currency,
        amount: moveDecimalPoint(amount, 2),
      });

      toast.success(intl.formatMessage({ id: 'deal.details.select_investment_success' }));
    } else {
      removeDeal();
    }
  };

  const removeDeal = () => {
    if (basket.primary.getDeal(deal)) {
      basket.primary.removeDeal(deal);
      toast.info(intl.formatMessage({ id: 'deal.details.removal_investment_success' }));
    }

    onAmountChange(undefined);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} className={classes.card}>
      <Box className={classes.inputWrapper}>
        <Tooltip
          open={tooltip}
          placement={'bottom'}
          title={tooltipMessage ?? ''}
          color={isError ? 'error' : undefined}
        >
          <AmountTextField
            fullWidth
            className={clsx(classes.input, { error: isError })}
            currency={deal.currency as Currency}
            placeholder={placeholder}
            value={amount ?? ''}
            disabled={!loaded}
            inputProps={{
              onValueChange: (data) => onAmountChange(data.floatValue),
              onFocus: () => setTooltip(true),
              onBlur: () => setTooltip(false),
              autoComplete: 'off',
            }}
            InputProps={{
              endAdornment: !!amount ? (
                <IconButton onClick={removeDeal}>
                  <RiCloseCircleFill />
                </IconButton>
              ) : undefined,
            }}
          />
        </Tooltip>

        <Button
          type="submit"
          className={classes.submitButton}
          disabled={!loaded || isError || !amount}
        >
          <FormattedMessage id="add_investment" />
        </Button>
      </Box>

      <Typography variant="caption" color="text.secondary" component="p">
        <FormattedMessage id="investment_opportunity_time_limit_helper_text" />
      </Typography>
    </Box>
  );
};

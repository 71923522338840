import { useCallback, useMemo } from 'react';
import { ParseOptions, parseUrl, stringify } from 'query-string';
import { omit } from 'ramda';
import { FilterData, FilterFragment, SortFragment, SortOrder } from 'types';
import { getNumber } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';

const queryParseOptions: ParseOptions = {
  arrayFormat: 'bracket',
  parseNumbers: true,
  parseBooleans: true,
};

const convertFiltersToQuery = (data: FilterData): Record<string, string | number | boolean | undefined> => ({
  ...data.filters?.reduce(
    (acc, val) => ({ ...acc, [val.id]: val.value }),
    {},
  ),
  ...(data.sort?.length
    ? {
      sortBy: data.sort[0].id,
      sortOrder: data.sort[0].desc ? SortOrder.DESC : SortOrder.ASC
    }
    : {}
  ),
  pageSize: data?.pageSize,
  pageIndex: data?.pageIndex,
});

export const useQuery = ({
  defaultPageSize = 10,
  defaultPageIndex = 0,
} = {
    defaultPageSize: 10,
    defaultPageIndex: 0,
  }) => {
  const history = useHistory();
  const { search } = useLocation();

  const { query: parsedQuery } = useMemo(
    () => parseUrl(search, queryParseOptions),
    [search],
  );

  const parsedFilters = useMemo(
    () => omit(['pageSize', 'pageIndex', 'sortBy', 'sortOrder'], parsedQuery),
    [parsedQuery],
  );

  const pageSize = useMemo(
    () => getNumber(parsedQuery.pageSize, defaultPageSize),
    [parsedQuery.pageSize, defaultPageSize],
  );
  const pageIndex = useMemo(
    () => getNumber(parsedQuery.pageIndex, defaultPageIndex),
    [parsedQuery.pageIndex, defaultPageIndex],
  );

  const sort = useMemo(() => {
    if (parsedQuery.sortBy && parsedQuery.sortOrder) {
      return [{
        id: parsedQuery.sortBy,
        desc: parsedQuery.sortOrder === SortOrder.DESC,
      } as SortFragment]
    }
    return [];
  }, [parsedQuery.sortBy, parsedQuery.sortOrder]);

  const filters: FilterFragment[] = useMemo(
    () =>
      Object.entries(parsedFilters).map(
        ([id, value]) => ({
          id,
          value,
        }),
      ),
    [parsedFilters],
  );

  const pushQuery = useCallback(
    (data: FilterData) => {
      history.push({
        ...history,
        search: stringify(convertFiltersToQuery(data), queryParseOptions),
      });
    },
    [history],
  );

  const push = useCallback(
    (data: Record<string, string | string[] | number>) => {
      history.push({
        ...history,
        search: stringify(data, queryParseOptions),
      });
    },
    [history],
  );

  return {
    parsedQuery,
    parsedFilters,
    filters,
    pageIndex,
    pageSize,
    sort,
    pushQuery,
    push,
    convertFiltersToQuery,
  };
};

import React, { ChangeEvent, MouseEvent, useMemo } from 'react';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { RiCheckboxIndeterminateFill, RiCheckboxFill, RiCheckboxBlankLine } from 'react-icons/ri';
import { dequal } from 'dequal';

import { BaseSelectProps, IGroupedOptions, IOption, TOptionType } from './types';
import { SCheckbox, SGroup, SGroupLabel, SGroupDivider } from './styled';

const isGroupedOption = <T,>(option: TOptionType<T>): option is IGroupedOptions<T> => {
  return !!(option as IGroupedOptions<T>).options;
};

export const useMapOptions = <T, E extends HTMLElement>(
  props: BaseSelectProps<T>,
  isMultiselect: boolean,
  onChange?: (e: ChangeEvent<E>) => void,
) => {
  const { field, options } = props;

  const { flatOptions, name, value, valueArray, innerValue, innerValueArray } = useMemo(() => {
    const flatOptions = options.flatMap((o) => (isGroupedOption(o) ? o.options : o));

    const name = field?.name ?? props.name;
    const value = field?.value ?? props.value;
    const valueArray = Array.isArray(value) ? value : [value];

    let innerValue: MaybeArray<T | undefined>;

    if (Array.isArray(value)) {
      if (value.length === 0) {
        innerValue = flatOptions.filter((v) => v.selectDefault).map((v) => v.value);
      } else {
        innerValue = value;
      }
    } else {
      if (!value) {
        innerValue = flatOptions.find((v) => v.selectDefault)?.value;
      } else {
        innerValue = value;
      }
    }

    const innerValueArray = Array.isArray(innerValue) ? innerValue : [innerValue];

    return { flatOptions, name, value, valueArray, innerValue, innerValueArray };
  }, [field?.name, field?.value, options, props.name, props.value]);

  const handleItemClick = (event: MouseEvent<HTMLLIElement>, option: IOption<T>) => {
    let newValue: MaybeArray<T | undefined>;

    if (option.singleSelectValue || !isMultiselect) {
      newValue = [option.value];
    } else {
      if (valueArray.includes(option.value as T)) {
        newValue = valueArray.filter((v) => !dequal(v, option.value));
      } else {
        newValue = [...valueArray, option.value];
      }

      const defaultValues = flatOptions.filter((o) => o.selectDefault).map((o) => o.value);

      if (newValue.length === 0) {
        newValue = defaultValues;
      } else {
        newValue = newValue.filter((nv) => !defaultValues.find((dv) => dequal(dv, nv)));
      }
    }

    newValue = newValue.filter((v) => v !== undefined);

    // Clone the event to not override `target` of the original event.
    const nativeEvent = event.nativeEvent || event;
    // @ts-ignore
    const clonedEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);
    Object.defineProperty(clonedEvent, 'target', {
      writable: true,
      value: {
        value: isMultiselect ? newValue : newValue[0],
        name,
      },
    });

    onChange?.(clonedEvent);
  };

  const mapOption = (option: IOption<T>, key: string | number) => {
    const isSelected = innerValueArray.some((iv) => dequal(iv, option.value));

    return (
      <React.Fragment key={key}>
        <MenuItem
          role="option"
          selected={isSelected}
          value={undefined}
          data-value={option.value}
          onClick={(e) => handleItemClick(e, option)}
        >
          {isMultiselect ? (
            <ListItemIcon sx={{ minWidth: '40px !important' }}>
              {isSelected ? (
                option.singleSelectValue ? (
                  <SCheckbox as={RiCheckboxIndeterminateFill} className="checked" />
                ) : (
                  <SCheckbox as={RiCheckboxFill} className="checked" />
                )
              ) : (
                <SCheckbox as={RiCheckboxBlankLine} />
              )}
            </ListItemIcon>
          ) : option.icon ? (
            <ListItemIcon sx={{ minWidth: '34px !important', fontSize: '18px' }}>
              {option.icon}
            </ListItemIcon>
          ) : null}

          {typeof option.label === 'string' || typeof option.label === 'number' ? (
            <Typography variant="body2">{option.label}</Typography>
          ) : (
            option.label
          )}
        </MenuItem>
      </React.Fragment>
    );
  };

  const list = options.map((option, index) => {
    if (isGroupedOption(option)) {
      const optionElements = option.options.map(mapOption);
      const isLast = index === options.length - 1;

      return (
        <SGroup>
          <SGroupLabel>{option.label}</SGroupLabel>
          {optionElements}
          {!isLast && <SGroupDivider />}
        </SGroup>
      );
    } else {
      return mapOption(option, index);
    }
  });

  return { list, flatOptions, name, value, valueArray, innerValue, innerValueArray };
};

import { useContext, useLayoutEffect } from 'react';

import { LayoutContext } from './context';

export const useLayoutProvider = () => useContext(LayoutContext);

export const useLayoutPageTitle = (title?: string | null | undefined) => {
  const { setTitle } = useLayoutProvider();

  useLayoutEffect(() => {
    if (typeof title !== 'string') return;
    setTitle(title);
    return () => setTitle(null);
  }, [setTitle, title]);
};

interface WithLayoutPageTitleProps {
  title: string | null | undefined;
}

/**
 * Better to use this component instead of `useLayoutPageTitle` hook.
 * Issue: https://github.com/facebook/react/issues/15281.
 */
export const WithLayoutPageTitle = ({ title }: WithLayoutPageTitleProps) => {
  useLayoutPageTitle(title);
  return null;
};

interface TableColumn<T> {
  show?: boolean;
  Header: string,
  accessor?: keyof T,
  type?: string,
  handler?: (arg0: any) => any
}

export const convertTableDataToCsvContent = <T>(data: T[], columns: TableColumn<T>[]) => {
  let columnsArray = [...columns];
  const columnsToShow = columnsArray.filter(
    column => column.show !== false && !!column.accessor
  );
  const csvHeaders = columnsToShow.map(column => column.Header).join(',');
  const dateColumns = columnsToShow
    .filter(column => column.type === 'date')
    .map(column => column.accessor);
  const csvContent = data
    .map(obj => {
      dateColumns.forEach(
        // @ts-ignore
        field => (obj[field] = obj[field] ? formatDate(obj[field]) : ' ')
      );
      return obj;
    })
    .map(obj => subsetObject(obj, columnsToShow, ' '))
    .map(obj => Object.values(obj).join(','));
  return [csvHeaders, ...csvContent].join('\n');
};

export const formatDate = (d: Date | number | string) => {
  const date = new Date(d);
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  return year + '-' + month + '-' + day;
};

export const subsetObject = <T>(obj: T, columns: TableColumn<T>[], defaultValue: string) =>
  obj
    ? columns
      .map(col => {
        const key = col.accessor!;
        const emptyFunc = (a: any) => a;
        const hanldler = col.handler ?? emptyFunc;
        const nestedKey = key.toString().split('.');
        // @ts-ignore
        if (key in obj || nestedKey[1]) return { [key]: hanldler(nestedKey[1] ? obj[nestedKey[0]][nestedKey[1]] : obj[key]) };
        return defaultValue ? { [key]: defaultValue } : {};
      })
      // @ts-ignore
      .reduce((result, current) => ({ ...result, ...current }), {})
    : {};
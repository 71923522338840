import { Box, IconButton, Typography, Paper, Button, alpha, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCloseLine } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, FormikContext, useFormik } from 'formik';

import { TextInput } from 'components/forms';
import { WithDialogPros, withDialog } from 'wrappers';
import { trimObject } from 'utils';

import { CommonFilters } from './CommonFilters';
import { primaryDealFiltersHelper } from './helpers';
import { PrimaryDealFilterConfigurationValues } from './types';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    zIndex: 1,
  },

  closeButton: {
    margin: '-8px',
  },

  body: {
    padding: '40px 24px 0px',
  },

  sectionDivider: {
    margin: '40px 0',
  },

  nameInput: {
    width: '60%',
    maxWidth: '100%',
    minWidth: '220px',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    borderTop: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
    boxShadow: `0px 2px 6px 0px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const processValues = (
  values: PrimaryDealFilterConfigurationValues,
): PrimaryDealFilterConfigurationValues => {
  return {
    ...values,
    name: values.name.trim(),
  };
};

interface Props extends WithDialogPros {
  initialValues?: PrimaryDealFilterConfigurationValues;
  takenFilterNames?: string[]; // custom filter names that already taken
  onApply?: (values: PrimaryDealFilterConfigurationValues) => void;
}

export const PrimaryDealFilterConfigurationModal = withDialog((props: Props) => {
  const { initialValues, takenFilterNames, onApply, onClose } = props;

  const intl = useIntl();
  const classes = useStyles();

  const handleSubmit = (values: PrimaryDealFilterConfigurationValues) => {
    onApply?.(processValues(values));
    onClose?.();
  };

  const formik = useFormik<PrimaryDealFilterConfigurationValues>({
    initialValues: initialValues ?? { ...primaryDealFiltersHelper.getEmptyValue(), name: '' },
    onSubmit: handleSubmit,
    validate(values) {
      const { name } = processValues(values);

      const errors = trimObject({
        // prettier-ignore
        name: name.length === 0
          ? intl.formatMessage({ id: 'error.required_field' })
          : name !== formik.initialValues.name && takenFilterNames?.includes(name)
          ? intl.formatMessage({ id: 'name_is_taken' })
          : undefined,
      }) as object;

      if (Object.keys(errors).length > 0) return errors;
    },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const totalInitialFiltersCount = primaryDealFiltersHelper.countValues(formik.initialValues);
  const totalFiltersCount = primaryDealFiltersHelper.countValues(formik.values);
  const submittable = totalFiltersCount > 0 && formik.values.name.trim().length > 0;

  return (
    <Box>
      <Paper elevation={0} className={classes.header}>
        <Typography variant="subtitle2" color="text.secondary">
          <FormattedMessage id="your_custom_notification" />
        </Typography>

        <IconButton onClick={onClose} className={classes.closeButton}>
          <RiCloseLine />
        </IconButton>
      </Paper>

      <FormikContext.Provider value={formik}>
        <Form className={classes.body}>
          <Typography variant="subtitle2" marginBottom="4px">
            1. <FormattedMessage id="name_your_notification" />
          </Typography>

          <Typography variant="body2" fontWeight={300} color="text.secondary" marginBottom="24px">
            <FormattedMessage id="name_your_notification.description" />
          </Typography>

          <Field
            id="name"
            name="name"
            component={TextInput}
            placeholder={intl.formatMessage({ id: 'notification_name' })}
            className={classes.nameInput}
            size="medium"
          />

          <Divider className={classes.sectionDivider} />

          <Typography variant="subtitle2" marginBottom="16px">
            2. <FormattedMessage id="select_filters_to_apply" />
          </Typography>

          <CommonFilters accordionExpanded />
        </Form>
      </FormikContext.Provider>

      <Paper className={classes.footer}>
        <Button variant="text" onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button onClick={() => formik.submitForm()} disabled={!submittable}>
          <FormattedMessage
            id={totalInitialFiltersCount > 0 ? 'save_changes' : 'create_notification'}
          />
        </Button>
      </Paper>
    </Box>
  );
});

import { memo, useCallback, useMemo } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCloseLine } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';

import { IDealDto } from 'api/investor-client';
import { useBasketProvider } from 'providers/basket';
import { CurrencyIcon, DiscountChip, InlineTooltip, MonthChip, ProductTypeChip, RiskChip } from 'components/UI';
import {
  InvestmentField,
  // ExpirationTimer
} from 'components';
import { downloadFile, getDealDetails, getDurationToEndDeal, moveDecimalPoint } from 'utils';
import { DealRating, DealType } from 'types';
import { useFormatMoney, useSelectedInvestorAccount } from 'hooks';
import { MarketType, SecondaryDeal } from 'types/secondary-market';
import { getAgreementFullOfferId, getFullOfferId } from 'providers/basket/helpers';
import { useAsyncCallback } from 'react-async-hook';
import { documentApiClient } from 'api';
import { AgreementDownloadChip } from 'components/UI/AgreementDownloadChip';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: '24px',
    backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    borderColor: alpha(theme.palette.text.secondary, 0.36),
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',

    [theme.breakpoints.up('xl')]: {
      gap: '40px',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    flexWrap: 'wrap-reverse'
  },

  dataListTitle: {
    display: 'block',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textTransform: 'uppercase',

    [theme.breakpoints.up('xl')]: {
      marginBottom: '8px',
    },
  },

  dataIconList: {
    display: 'flex',
    gap: '8px',
  },

  inputWrapper: {
    minWidth: '180px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.up('xl')]: {
      minWidth: 'unset',
    },
  },

  divider: {
    display: 'none',

    [theme.breakpoints.up('xl')]: {
      display: 'flex',
    },
  },

  amountInput: {
    '& input': {
      display: 'flex',
      padding: '8px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.grey.A400}`,
    },

    '& .Mui-error': {
      outline: 'none !important',

      '& input': {
        borderColor: theme.palette.error.main,
      },
    },
  },
  tooltip: {
    display: 'flex',
    color: alpha(theme.palette.text.secondary, 0.36),
  },
}));

type ReviewDeal = IDealDto | SecondaryDeal;

interface Props {
  type: MarketType;
  deal: ReviewDeal;
  index: number;
  onClose: () => void;
  onError: (index: number, err: boolean) => void;
  agreements: Record<string, {id: string, name: string}>
}

export const InvestmentCard = memo((props: Props) => {
  const { deal, index, onClose, onError, type, agreements } = props;

  const primaryDeal = type === MarketType.secondary ? (deal as SecondaryDeal).shareWithDeal.deal : deal as IDealDto;

  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const isXLSize = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const formatMoney = useFormatMoney({ prefix: `${primaryDeal.currency} ` });
  const basket = useBasketProvider();
  // prettier-ignore
  const { selectedAccount, isViewOnly, isBlockedFromInvesting, isKycFlowUnfinished } = useSelectedInvestorAccount();
  const investorId = selectedAccount?.investor?.id;
  const isReadonly = isViewOnly || isBlockedFromInvesting || isKycFlowUnfinished;
  const basketAmount = basket[type].getDeal(deal)?.amount ?? 0;
  const committedAmount = investorId ? getDealDetails(primaryDeal, investorId).common.investorCommittedAmount : 0;

  let agreementFullOfferId = getAgreementFullOfferId(
    (deal as any).providerId,
    deal.id,
    committedAmount + basketAmount);
  const agreement = agreements[agreementFullOfferId]

  const downloadAgreement = useAsyncCallback(async () => {
    if (document) {
      // prettier-ignore
      const { data } = await documentApiClient.api.investorApiControllerDownload(agreement.id, {
        format: 'arraybuffer',
      });

      downloadFile(data as unknown as string, agreement.name);
    }
  });

  const handleError = useCallback((err: boolean) => onError(index, err), [onError, index]);
  const leftCols = useMemo(() => {
    const { durationToEnd, displayInMonths } = getDurationToEndDeal(primaryDeal);
    return [
      {
        size: 2,
        id: 'type',
        data: <FormattedMessage id={`borrower.${primaryDeal.risk.productType}`} />
      },
      {
        size: 1,
        id: 'rating',
        data: primaryDeal.risk.rating
      },
      ...(type === MarketType.secondary
        ? [
          {
            size: 1.5,
            id: 'interest',
            data: typeof (deal as SecondaryDeal).shareWithDeal.deal.nominalInterestRate === 'number'
              ? `+ ${((deal as SecondaryDeal).shareWithDeal.deal.nominalInterestRate * 100).toFixed(2)}%`
              : '~'
          },
          {
            size: 1.5,
            id: 'terms_left',
            data: <Box display="flex">
              {durationToEnd} <FormattedMessage id={displayInMonths ? 'month.short' : 'days.short'} />
              <span style={{ fontWeight: 400 }}>{'\u00a0/\u00a0'}</span>
              <span style={{ fontWeight: 400 }}>
                {primaryDeal.duration} <FormattedMessage id="month.short" />
              </span>
            </Box>
          }]
        : [
          {
            size: 2,
            id: 'irr',
            data: typeof (primaryDeal as IDealDto).projectedXirr === 'number'
              ? `+ ${((primaryDeal as IDealDto).projectedXirr * 100).toFixed(2)}%`
              : '~'
          },
          {
            size: 2,
            id: 'duration',
            data: `${primaryDeal.duration} ${intl.formatMessage({
              id: 'months',
            })}`
          }]
      )
    ]
  }, [type, primaryDeal, deal, intl]);
  return (
    <Paper variant="outlined" className={classes.container}>
      <Box className={classes.header}>
        <CurrencyIcon currency={primaryDeal.currency} size={18} />
        <Typography variant="subtitle2" sx={{ textDecoration: 'underline' }}>{deal.id}</Typography>
        {/* {type === 'secondary' && (
          <ExpirationTimer expiredAt={(deal as SecondaryDeal).expiresAt} />
        )} */}
        {agreement && <AgreementDownloadChip downloadAgreement={downloadAgreement} />}

      </Box>

      <IconButton
        sx={{ position: 'absolute', top: '4px', right: '4px' }}
        onClick={(e) => onClose()}
      >
        <RiCloseLine color={theme.palette.secondary.dark} size={16} />
      </IconButton>

      <Grid container spacing={{ xs: 8, xl: 3 }}>
        {!isXLSize && (
          <Grid item className={classes.dataIconList}>
            <RiskChip rating={primaryDeal.risk.rating as DealRating} />
            <ProductTypeChip productType={primaryDeal.risk.productType as DealType} />
            <MonthChip months={primaryDeal.duration} />
          </Grid>
        )}

        {isXLSize && (
          <>
            {leftCols.map((r) =>
              <Grid item xs={r.size}>
                <Typography variant="caption" className={classes.dataListTitle}>
                  <FormattedMessage id={r.id} />
                </Typography>
                <Typography variant="subtitle2">{r.data}</Typography>
              </Grid>
            )}
          </>
        )}

        <Grid item xl={1} className={classes.divider}>
          <Divider orientation="vertical" sx={{ display: 'block', margin: 'auto' }} />
        </Grid>

        {type === MarketType.secondary  ? <>
        <Grid item xs xl={3}>
              <Box display="flex" gap="4px">
                <Typography variant="caption" className={classes.dataListTitle}>
                  <FormattedMessage id="deal_price" />
                </Typography>
                {/*<InlineTooltip*/}
                {/*  tooltip={intl.formatMessage({ id: 'deal_price.description_tooltip' })}*/}
                {/*  className={classes.tooltip}*/}
                {/*/>*/}
              </Box>
              <Box display="flex" gap="8px">
                <Typography variant="subtitle2">{formatMoney(moveDecimalPoint((deal as SecondaryDeal).shareSale.latestCalculatedPrice ?? 0, -2))}</Typography>
                <DiscountChip coefficient={(deal as SecondaryDeal).shareSale.priceMultiplier - 1} />
              </Box>
        </Grid>
            <Grid item xs xl={2}>
              <Box display='flex' gap='4px'>
                <Typography variant='caption' className={classes.dataListTitle}>
                  <FormattedMessage id='service_fee' />
                </Typography>
              </Box>
              <Box display='flex' gap='8px'>
                <Typography
                  variant='subtitle3'>{formatMoney(moveDecimalPoint((deal as SecondaryDeal).shareSale.buyerFeeAmount ?? 0, -2))}</Typography>
              </Box>
            </Grid>
          </>
          : <Grid item xs xl={4}><Box className={classes.inputWrapper}>
            {investorId && (
              <InvestmentField
                  deal={primaryDeal}
                  investorId={investorId}
                  initialAmount={moveDecimalPoint(basketAmount, -2)}
                  displayInvestedAmount={false}
                  className={classes.amountInput}
                  changeType="change"
                  onChange={(amount) => {
                    basket.primary.setDeal({
                      id: primaryDeal.id,
                      providerId: primaryDeal.providerId,
                      currency: primaryDeal.currency,
                      amount: moveDecimalPoint(amount ?? 0, 2),
                    });
                  }}
                  onError={handleError}
                  disabled={isReadonly}
                />
              )}
            </Box>
          </Grid>}
      </Grid>
    </Paper>
  );
});

import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Link, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncCallback } from 'react-async-hook';

import { VirtualAccountDTO } from 'api/payments-client';
import { useUserDetails } from 'providers/user-details';
import Card from 'components/card';
import { Select } from 'components/forms';
import { ConfirmationDialog } from 'components';
import { downloadFile } from 'utils';
import { Currency, EBankAccountStatus } from 'types';
import { investorApiClient, paymentsApiClient } from 'api';

interface Props {
  virtualAccount: VirtualAccountDTO | null;
  loading?: boolean;
  refreshVirtualAccount?(): Promise<any>;
}

export const DDOrLSVActivation = ({ virtualAccount, loading, refreshVirtualAccount }: Props) => {
  const intl = useIntl();
  const { currentLanguage } = useUserDetails();
  const [currency, setCurrency] = useState<Currency | null>(null);
  const [confirmation, setConfirmation] = useState(false);

  const currencyOptions = useMemo(() => {
    const res = Object.values(Currency)
      .filter((e) => {
        if (!virtualAccount?.bankAccounts) return true;
        const ba = virtualAccount.bankAccounts.find((ba) => ba.currency === e);
        return ba ? !!(ba.iban as any).value : false;
      })
      .map((e) => ({
        value: e,
        label: e,
      }));

    setCurrency((state) => {
      if (state === null) return res[0]?.value ?? null;
      const exists = res.some(({ value }) => value === state);
      if (exists) return state;
      return res[0]?.value ?? null;
    });

    return res;
  }, [virtualAccount]);

  const bankAccount = useMemo(() => {
    return virtualAccount?.bankAccounts?.find((ba) => ba.currency === currency) ?? null;
  }, [virtualAccount, currency]);

  const deactivateBankAccount = useAsyncCallback(async () => {
    if (!bankAccount) return;

    await paymentsApiClient.api.investorApiControllerDeactivateBankAccount(bankAccount.id);
    await refreshVirtualAccount?.();
  });

  const downloadDDForm = useAsyncCallback(async () => {
    if (!bankAccount) return;

    const { data } = await investorApiClient.investors.investorApiControllerGenerateLsvddContract(
      {
        iban: (bankAccount.iban as any)?.value,
        language: currentLanguage.slice(0, 2),
        currency: currency as any,
      },
      { format: 'arraybuffer' },
    );
    downloadFile(data as unknown as string, 'DD/LSV Formular.pdf');
  });

  const isDD = !!bankAccount?.dd;
  const isActive =
    bankAccount?.dd?.status === EBankAccountStatus.Active ||
    bankAccount?.lsv?.status === EBankAccountStatus.Active;

  const lsvTypeName = intl.formatMessage({
    id: isDD ? 'direct_debit' : 'lsv',
  });

  const initialLoading = loading && !virtualAccount;

  return (
    <>
      <Card>
        <Card.Header>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography variant="h6" mb={1} fontWeight={600}>
              <FormattedMessage id="lsv_payments" />
            </Typography>

            {currency !== null && currencyOptions.length > 0 && (
              <Select
                size="small"
                options={currencyOptions}
                value={currency}
                onChange={(e) => setCurrency(e.target.value as Currency)}
                className="filter small auto"
                disabled={loading || currencyOptions.length === 1}
              />
            )}
          </Box>

          <Typography>
            <FormattedMessage id="lsv_payments.subtitle" />
          </Typography>
        </Card.Header>

        <Card.Body loadingContent={initialLoading}>
          {initialLoading ? (
            <></>
          ) : !bankAccount ? (
            <FormattedMessage id="lsv_payments.no_ibans" />
          ) : isActive ? (
            <FormattedMessage id="lsv_payments.active" values={{ value: lsvTypeName }} />
          ) : (
            <FormattedMessage
              id="lsv_payments.inactive.direct_debit"
              values={{
                a: (msg: string) => (
                  <Link component={RouterLink} to={`../account-balance`}>
                    {msg}
                  </Link>
                ),
              }}
            />
          )}
        </Card.Body>

        {!!bankAccount && (
          <Card.Footer>
            {isActive ? (
              <Button
                variant="contained"
                size="large"
                onClick={() => setConfirmation(true)}
                disabled={initialLoading || deactivateBankAccount.loading}
              >
                <FormattedMessage id="disable" values={{ value: lsvTypeName }} />
              </Button>
            ) : (
              <Button variant="contained" size="large" onClick={downloadDDForm.execute}>
                <FormattedMessage id="lsv_dd_download_form" />
              </Button>
            )}
          </Card.Footer>
        )}
      </Card>

      <ConfirmationDialog
        open={confirmation}
        strings={{
          title: intl.formatMessage({ id: 'lsv_payments.confirmation.disable_lsv' }),
        }}
        onClose={(result) => {
          setConfirmation(false);
          if (result) deactivateBankAccount.execute();
        }}
      />
    </>
  );
};

import { useEffect, useState } from 'react';
import { RiArrowDownSFill, RiGlobalLine } from 'react-icons/ri';
import { MenuItem as SelectMenuItem, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useUserDetails } from 'providers/user-details';
import { Language, languageCodeToLanguageMap } from 'types';

import { MenuItem } from './MenuItem';
import { ESidebarSize } from '../types';

const useStyles = makeStyles({
  dropdownArrow: {
    position: 'relative',
    top: '0.125em',
    marginLeft: '8px',
  },
});

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
  rawLabel?: boolean;
}

export const LanguageSelect = ({ collapsed, sidebarSize, rawLabel = false }: Props) => {
  const classes = useStyles();
  const userContext = useUserDetails();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (collapsed && anchorEl) setAnchorEl(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <MenuItem
        component="div"
        icon={<RiGlobalLine size={24} />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        collapsed={collapsed}
        sidebarSize={sidebarSize}
      >
        {rawLabel
          ? languageCodeToLanguageMap[userContext.currentLanguage]?.short
          : languageCodeToLanguageMap[userContext.currentLanguage]?.name ?? '~'}
        <RiArrowDownSFill className={classes.dropdownArrow} />
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        elevation={2}
        disablePortal
      >
        {Object.entries(Language)
          .filter(([title, value]) => {
            return process.env.REACT_APP_DUMMY_LANGUAGE === 'true' ? true : value !== Language.ACH;
          })
          .map(([title, value]) => (
            <SelectMenuItem
              key={value}
              value={value}
              selected={userContext.currentLanguage === value}
              onClick={() => {
                userContext.setLanguage(value);
                setAnchorEl(null);
              }}
            >
              {languageCodeToLanguageMap[value]?.native ?? title}
            </SelectMenuItem>
          ))}
      </Menu>
    </>
  );
};

import { ReactNode } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { DialogOptions, WithDialogPros, withDialog } from '../wrappers/withDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '16px 16px 8px',
    fontWeight: 600,
  },

  body: {
    padding: '0px 16px 8px',
  },

  actionBar: {
    justifyContent: 'flex-end',
    padding: '24px 16px 16px',
  },
}));

const DIALOG_OPTIONS: DialogOptions = {
  PaperProps: { sx: { minWidth: '250px' } },
  className: 'centered',
};

interface Props extends WithDialogPros {
  open: boolean;
  onClose: (result?: boolean) => void;
  strings: {
    title: ReactNode | string;
    subtitle?: ReactNode | string;
    acceptText?: ReactNode | string;
    cancelText?: ReactNode | string;
  };
  loading?: boolean;
}

export const ConfirmationDialog = withDialog((props: Props) => {
  const { onClose, strings, loading } = props;

  const classes = useStyles();

  return (
    <>
      <DialogTitle variant="subtitle1" className={classes.header}>
        {strings.title}
      </DialogTitle>

      {strings.subtitle && (
        <DialogContent className={classes.body}>
          <DialogContentText variant="body2">{strings.subtitle}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions className={classes.actionBar}>
        <Button variant="text" onClick={() => onClose(false)} disabled={loading}>
          {strings.cancelText ?? <FormattedMessage id="cancel" />}
        </Button>

        <Button onClick={() => onClose(true)} autoFocus disabled={loading}>
          {loading ? <CircularProgress /> : strings.acceptText ?? <FormattedMessage id="ok" />}
        </Button>
      </DialogActions>
    </>
  );
}, DIALOG_OPTIONS);

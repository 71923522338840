import { useState } from 'react';

import { MainSubmenu } from './MainSubmenu';
import { AccountSelectSubmenu } from './AccountSelectSubmenu';
import { ESidebarSize } from '../../types';

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
  onClose?: () => void;
  onSidebarClose?: () => void;
}

export const MenuContent = (props: Props) => {
  const [accountSelectMenu, setAccountSelectMenu] = useState(false);

  if (accountSelectMenu) {
    return <AccountSelectSubmenu {...props} onBack={() => setAccountSelectMenu(false)} />;
  }

  return <MainSubmenu {...props} onAccountMenuSelect={() => setAccountSelectMenu(true)} />;
};

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Menu } from 'react-pro-sidebar';
import { useRouteMatch } from 'react-router';
import { FormattedMessage } from 'react-intl';
import {
  RiListCheck,
  RiFoldersLine,
  RiDashboardLine,
  RiQuestionLine,
  RiArrowLeftRightFill,
} from 'react-icons/ri';
import clsx from 'clsx';

import { MenuItem } from './MenuItem';
import { LanguageSelect } from './LanguageSelect';
import { ESidebarSize } from '../types';

const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '16px',
    paddingBottom: '16px',

    '&.desktop': {
      paddingBottom: '40px',
    },
  },
  comingSoon: {
    fontSize: '12px',
    borderRadius: '4px',
    border: '1px solid #546878',
    padding: '2px 4px',
  },
}));

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
  onSidebarClose?: () => void;
}

export const SidebarBody = (props: Props) => {
  const { collapsed, sidebarSize, onSidebarClose } = props;
  const classes = useStyles();
  const { url } = useRouteMatch();

  const isDesktop = sidebarSize === ESidebarSize.Desktop;

  return (
    <Box className={clsx(classes.body, { desktop: isDesktop })}>
      <Menu>
        <MenuItem
          icon={<RiDashboardLine size={24} />}
          href={`${url}/dashboard`}
          activeMatch="start"
          collapsed={collapsed}
          sidebarSize={sidebarSize}
          onClick={onSidebarClose}
        >
          <Typography>
            <FormattedMessage id="dashboard" />
          </Typography>
        </MenuItem>

        <MenuItem
          href={`${url}/deals`}
          icon={<RiListCheck size={24} />}
          activeMatch="start"
          collapsed={collapsed}
          sidebarSize={sidebarSize}
          onClick={onSidebarClose}
        >
          <Typography>
            <FormattedMessage id="deals.new" />
          </Typography>
        </MenuItem>

        <MenuItem
          icon={<RiFoldersLine size={24} />}
          href={`${url}/portfolio`}
          activeMatch="start"
          collapsed={collapsed}
          sidebarSize={sidebarSize}
          onClick={onSidebarClose}
        >
          <Typography>
            <FormattedMessage id="portfolio" />
          </Typography>
        </MenuItem>

        <MenuItem
          icon={<RiArrowLeftRightFill size={24} />}
          href={`${url}/transactions`}
          activeMatch="start"
          collapsed={collapsed}
          sidebarSize={sidebarSize}
          onClick={onSidebarClose}
          disabled
        >
          <Typography display="flex" gap="16px">
            <FormattedMessage id="transactions" />
            <span className={classes.comingSoon}>SOON</span>
          </Typography>
        </MenuItem>
      </Menu>

      <Menu>
        <LanguageSelect collapsed={collapsed} sidebarSize={sidebarSize} />

        <MenuItem
          icon={<RiQuestionLine size={24} />}
          href={`${url}/support`}
          activeMatch="start"
          collapsed={collapsed}
          sidebarSize={sidebarSize}
          onClick={onSidebarClose}
        >
          <Typography>
            <FormattedMessage id="help" />
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

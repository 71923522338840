import { useCallback, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ParsedQuery } from 'query-string';
import { Theme, useMediaQuery } from '@mui/material';

import { investorApiClient } from 'api';
import { InvestmentWithDealDto, ShareStatus } from 'api/investor-client';
import {
  InvestmentStatusFilterOption,
  PrimaryInvestmentFilterModal,
  PrimaryInvestmentFilterPayload,
  PrimaryInvestmentFilterValues,
  primaryInvestmentFiltersHelper,
} from 'components/filters/primary-investment';
import { useQuery, useLoadMoreController } from 'hooks';
import { downloadFile, retrieveFileNameFromHeader } from 'utils';
import { EndOfTableMessage, ScrollToTopButton, TableLayout } from 'components';

import { TableFilters } from '../components';
import { PrimaryInvestmentHeader } from './PrimaryInvestmentHeader';
import { PrimaryInvestmentRecord, PrimaryInvestmentRecordSkeleton } from './PrimaryInvestmentRecord';
import { SellModal } from './SellModal';

interface Props {
  wrapperRef?: HTMLElement | null;
}

export const PrimaryMarketTab = ({ wrapperRef }: Props) => {
  const { parsedQuery, push } = useQuery();

  const [data, setData] = useState<InvestmentWithDealDto[]>([]);
  const [continuationToken, setContinuationToken] = useState<string | null>(null);
  const [total, setTotal] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shareToSell, setShareToSell] = useState<InvestmentWithDealDto | null>(null);

  const fetchInvestments = useAsyncCallback(async ({ shareStatus, ...query }: ParsedQuery, fromBeginning?: boolean) => {
    if (!fromBeginning && continuationToken === 'EOF') return;

    if (fromBeginning) {
      setData([]);
      setContinuationToken(null);
      setTotal(null);
    }
    const isUnpaid = shareStatus?.includes(InvestmentStatusFilterOption.Unpaid);

    const fetchFunc = isUnpaid
      ? investorApiClient.investors.investorApiControllerGetCommitmentsByContinuationToken
      : investorApiClient.investors.investorApiControllerGetInvestmentsByContinuationToken;

    // prettier-ignore
    const { data } = await fetchFunc({
      ...query,
      shareStatus: isUnpaid ? undefined : shareStatus as ShareStatus[],
      continuationToken: fromBeginning ? undefined : continuationToken ?? undefined,
    });

    setData((state) => state.concat(data.data));
    setContinuationToken(data.continuationToken ?? null);
    setTotal((state) => (typeof state === 'number' ? state : data.total));
  });

  const exportInvestments = useAsyncCallback(async () => {
    const { data, headers } = await investorApiClient.investors.investorApiControllerDownloadInvestments(
      { unpaidSeparate: true } as any,
      { format: 'blob' }

    );
    downloadFile(data as unknown as string, retrieveFileNameFromHeader(headers));
  });

  const handleSellModalOpen = useCallback((share) => {
    setShareToSell(share);
    setIsModalOpen(true);
  }, []);

  const handleSellModalClose = useCallback(() => {
    setIsModalOpen(false);
    setTimeout(() => setShareToSell(null), 500);
  }, []);

  useDeepCompareEffect(() => {
    fetchInvestments.execute(parsedQuery, true);
  }, [parsedQuery]);

  useLoadMoreController({
    wrapperRef: wrapperRef,
    loading: fetchInvestments.loading,
    continuationToken: continuationToken,
    fetch: fetchInvestments.execute,
    query: parsedQuery,
  });

  const withTableLayout = useMediaQuery<Theme>((theme) => theme.breakpoints.up(1400));

  return (
    <>
      <TableFilters<PrimaryInvestmentFilterPayload, PrimaryInvestmentFilterValues>
        components={{
          TableHeader: PrimaryInvestmentHeader,
          AllFiltersModal: PrimaryInvestmentFilterModal,
        }}
        helpers={primaryInvestmentFiltersHelper}
        initialFilters={parsedQuery}
        onFiltersChange={push}
        onExport={exportInvestments.execute}
        total={total}
        loading={fetchInvestments.loading}
        tableLayout={withTableLayout}
      />

      <TableLayout
        components={{
          Record: PrimaryInvestmentRecord,
          RecordSkeleton: PrimaryInvestmentRecordSkeleton,
        }}
        data={data}
        dataLoading={fetchInvestments.loading}
        tableLayout={withTableLayout}
        onSell={handleSellModalOpen}
      />

      {continuationToken === 'EOF' && <EndOfTableMessage />}
      <ScrollToTopButton wrapperRef={wrapperRef} />
      <SellModal
        isOpen={isModalOpen}
        onClose={handleSellModalClose}
        share={shareToSell}
        onReload={() => fetchInvestments.execute(parsedQuery, true)}
      />
    </>
  );
};

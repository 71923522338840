import { ReactNode, useCallback } from 'react';
import { ToggleButtonGroup, ToggleButton, RadioGroupProps, Typography, alpha } from '@mui/material';
import { FieldProps } from 'formik';
import theme from 'config/theme';

interface IOption<T> {
  label: ReactNode;
  value: T;
}


type Props<T> = Partial<FieldProps<MaybeArray<T>>> & RadioGroupProps & {
  options: IOption<T>[];
  defaultValue?: T;
};

export const Radio = <T,>(props: Props<T>) => {
  const {
    field,
    form,
    options,
    defaultValue
  } = props;

  const handleChange = useCallback(
    (e: any, value: T) => {
      console.log(value);
      if (form?.setFieldValue && field?.name) form?.setFieldValue(field?.name, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form?.setFieldValue, field?.name],
  );

  return (
    <ToggleButtonGroup
      exclusive
      value={field?.value ?? defaultValue}
      onChange={handleChange}
      sx={{ gap: "24px" }}
    >
      {options.map((o) =>
        <ToggleButton
          value={o.value as unknown as string}
          sx={{
            border: 0,
            borderRadius: '8px !important',
            padding: '4px 12px',
            '&:hover': {
              backgroundColor: alpha(theme.palette.text.secondary, 0.08),
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: theme.palette.primary.dark,
              '& > *': {
                color: 'white !important'
              }
            }
          }}
        >
          <Typography variant="subtitle2" color="text.secondary">
            {o.label}
          </Typography>
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

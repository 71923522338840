import { useState } from 'react';
import { useAsync } from 'react-async-hook';

import { investorApiClient } from 'api';
import { useBasketProvider } from 'providers/basket';
import { SecondaryDeal } from 'types/secondary-market';
import { getSecondaryDealsComparator, getSecondaryDealsComparator2 } from 'utils';

export const useInitiateSecondaryDeals = () => {
  const basket = useBasketProvider();
  const [secondaryDeals, setSecondaryDeals] = useState<SecondaryDeal[]>([]);
  const [initiallyFetched, setInitiallyFetched] = useState<boolean>(false);

  // Pre-fetch all secondary deals and filter basket if the deal is no longer
  // available (from the backend perspective).
  useAsync(async () => {
    if (basket.secondary.deals.length === 0) {
      setSecondaryDeals([]);
      setInitiallyFetched(true);
      return;
    }

    if (basket.secondary.deals?.length !== secondaryDeals.length) {
      setSecondaryDeals(secondaryDeals.filter(d => basket.secondary.deals.some(getSecondaryDealsComparator2(d))));
    }

    /** check if all deals already fetched */
    if (secondaryDeals.length) {
      const allFetched = basket.secondary.deals.every((bd) => {
        return !!secondaryDeals.find(getSecondaryDealsComparator(bd));
      });

      if (allFetched) {
        setInitiallyFetched(true);
        return;
      }
    }

    const res = await investorApiClient.investors.investorApiControllerGetSecondarymarketOffers({
      ids: basket.secondary.deals.map((d) => d.id),
    });

    const getRandomNumber = (min: number, max: number) => {
      return min + Math.random() * (max - min);
    };

    const secondaryMarketDeals: SecondaryDeal[] = res.data.items?.map((shareSell) => {
      return {
        ...shareSell,
        id: shareSell.shareSale.id,
        irr: +getRandomNumber(0.01, 0.06).toFixed(2),
        interest: getRandomNumber(5_000_00, 180_000_00) | 0,
        expiresAt: new Date(Date.now() + Math.abs(getRandomNumber(10_000, 100_000_000_000) | 0)),
      };
    }) ?? [];

    setSecondaryDeals(secondaryMarketDeals);
    setInitiallyFetched(true);

    basket.secondary.deals.forEach((d) => {
      const exists = secondaryMarketDeals.find(getSecondaryDealsComparator(d));
      if (!exists) basket.secondary.removeDeal(d);
    });
  }, [basket.secondary.deals, basket.secondary.deals?.length]);

  return { secondaryDeals, secondaryDealsInitiallyFetched: initiallyFetched };
};

import { forwardRef, ReactElement } from 'react';
import {
  Fade,
  Slide,
  Theme,
  alpha,
  colors,
  createTheme,
  emphasize,
  responsiveFontSizes,
  useMediaQuery,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
  RiInformationFill,
  RiAlertFill,
  RiErrorWarningFill,
  RiCheckboxCircleFill,
} from 'react-icons/ri';

import { mixinObject } from 'utils';

/**
 * Creating root theme to use `palette` and `transitions` properties in
 * `components` section below.
 */
const { palette, breakpoints, shape, transitions, typography, shadows } = createTheme({
  palette: {
    primary: {
      main: '#359CDD',
      dark: '#001D32',
      light: '#349CDA',
    },
    secondary: {
      main: '#c4c4c4',
      dark: '#42474E',
    },
    error: {
      dark: '#93000A',
      main: '#BA1A1A',
    },
    success: {
      main: '#23A438',
    },
    text: {
      primary: '#1F1F1F',
      secondary: 'rgba(114, 119, 127)',
      secondary016: 'rgba(114, 119, 127, 0.16)',
    },
    action: {
      disabledBackground: '#001E2C1F',
      disabled: '#001E2C61',
    },
    background: {
      default: '#F8F9FB',
      lightBlueSurface: '#F7FAFD',
      blueSurface: '#00639A14',
      greySurface: '#DEE3EB',
      barkSurface: '#001E2C',
      barkSurface004: alpha('#001E2C', 0.04),
      lightRed: '#FBEAEA',
    },
    common: {
      lightBlue: '#98CBFF',
      dimBlue: '#D6E7F5',
      darkBlue: '#F1F6FB',
      warmGrey: '#AAB2BB',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 769,
      lg: 1024,
      xl: 1200,
      xxl: 1536,
      xxxl: 1800,
    },
  },

  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    h1: {
      // M3/display/large
      fontSize: '57px',
      lineHeight: 1.123, // 64px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h2: {
      // M3/display/medium
      fontSize: '45px',
      lineHeight: 1.156, // 52px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h3: {
      // M3/display/small
      fontSize: '36px',
      lineHeight: 1.223, // 44px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h4: {
      // M3/headline/large
      fontSize: '32px',
      lineHeight: 1.25, // 40px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h5: {
      // M3/headline/medium
      fontSize: '28px',
      lineHeight: 1.286, // 36px
      fontWeight: 400,
      letterSpacing: 0,
    },

    h6: {
      // M3/headline/small
      fontSize: '24px',
      lineHeight: 1.334, // 32px
      fontWeight: 400,
      letterSpacing: 0,
    },

    subtitle1: {
      // M3/title/large
      fontSize: '22px',
      lineHeight: 1.273, // 28px
      fontWeight: 400,
      letterSpacing: 0,
    },

    subtitle2: {
      // M3/title/medium
      fontSize: '16px',
      lineHeight: 1.5, // 24px
      fontWeight: 500,
      letterSpacing: 0.15,
    },

    subtitle3: {
      // M3/title/small
      fontSize: '14px',
      lineHeight: 1.429, // 20px
      fontWeight: 500,
      letterSpacing: 0.1,
    },

    body1: {
      // M3/body/large
      fontSize: '16px',
      lineHeight: 1.5, // 24px
      fontWeight: 400,
      letterSpacing: 0.5,
    },

    body2: {
      // M3/body/medium
      fontSize: '14px',
      lineHeight: 1.429, // 20px
      fontWeight: 400,
      letterSpacing: 0.25,
    },

    caption: {
      // M3/body/small
      fontSize: '12px',
      lineHeight: 1.334, // 16px
      fontWeight: 400,
      letterSpacing: 0.4,
    },

    button: {
      // M3/label/large
      fontSize: '14px',
      lineHeight: 1.429, // 20px
      fontWeight: 500,
      letterSpacing: 0.1,
      textTransform: 'inherit',
    },
  },
});

let theme = createTheme({
  spacing: 4,
  palette: palette,
  breakpoints: breakpoints,
  shape: shape,
  typography: typography,

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.simplebar-scrollbar': {
          '&:before': {
            backgroundColor: palette.primary.main,
          },

          '&.simplebar-visible:before': {
            opacity: 0.5,
            transition: 'opacity 0.2s linear',
          },
        },

        '.simplebar-track': {
          '&.simplebar-vertical': {
            width: '8px',
          },

          '&.simplebar-horizontal': {
            height: '8px',

            '& .simplebar-scrollbar': {
              height: '4px',
            },
          },
        },
      },
    },

    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
        elevation: 0,
      },
    },

    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <RiInformationFill />,
          warning: <RiAlertFill />,
          error: <RiErrorWarningFill />,
          success: <RiCheckboxCircleFill />,
        },
      },

      styleOverrides: {
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'nowrap',
          padding: '32px 24px',
        },

        icon: {
          alignItems: 'center',
          padding: 0,
          marginRight: '24px',
          fontSize: '32px',
        },

        message: {
          width: '100%',
          padding: 0,
        },

        action: {
          padding: 0,
          flexShrink: 0,
          margin: '0 0 0 24px',
        },

        standard: {
          color: palette.text.primary,
        },
      },
    },

    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },

      styleOverrides: {
        root: {
          transition: transitions.create(['background-color', 'border-color', 'color', 'outline'], {
            duration: 150,
          }),
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
      },

      styleOverrides: {
        root: {
          ...typography.button,

          '&.Mui-focusVisible': {
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineOffset: '2px',
            outlineColor: palette.primary.light,
          },
        },

        sizeSmall: {
          padding: '6px 12px',
        },

        outlinedSizeSmall: {
          /** Account border size (2px) */
          padding: '4px 10px',
        },

        sizeMedium: {
          padding: '10px 16px',
        },

        outlinedSizeMedium: {
          /** Account border size (2px) */
          padding: '8px 14px',
        },

        sizeLarge: {
          padding: '12px 24px',
        },

        outlinedSizeLarge: {
          /** Account border size (2px) */
          padding: '10px 22px',
        },

        containedPrimary: {
          backgroundColor: palette.primary.light,

          '&:hover': {
            backgroundColor: emphasize(palette.primary.light, 0.15),
          },

          '&:active': {
            backgroundColor: emphasize(palette.primary.light, 0.3),
          },
        },

        containedSecondary: {
          backgroundColor: palette.common.dimBlue,

          '&:hover': {
            backgroundColor: emphasize(palette.common.dimBlue, 0.05),
          },

          '&:active': {
            backgroundColor: emphasize(palette.common.dimBlue, 0.15),
          },
        },

        containedInfo: {
          backgroundColor: palette.grey[100],
          color: palette.text.primary,

          '&:hover': {
            backgroundColor: emphasize(palette.grey[100], 0.05),
          },

          '&:active': {
            backgroundColor: emphasize(palette.grey[100], 0.15),
          },
        },

        outlined: {
          borderWidth: '2px',
          backgroundClip: 'padding-box',

          '&:hover': {
            border: `2px solid ${palette.primary.light}`,
          },
        },

        outlinedPrimary: {
          borderColor: palette.primary.light,
          color: palette.primary.light,

          '&:hover': {
            backgroundColor: alpha(palette.primary.light, 0.05),
            borderColor: palette.primary.light,
          },

          '&:active': {
            backgroundColor: alpha(palette.primary.light, 0.10),
            borderColor: palette.primary.light,
          },
        },

        outlinedSecondary: {},

        textPrimary: {
          color: palette.primary.light,

          '&:hover': {
            backgroundColor: alpha(palette.primary.light, 0.04),
          },

          '&:active': {
            backgroundColor: alpha(palette.primary.light, 0.08),
          },
        },

        textSecondary: {
          color: palette.text.secondary,

          '&:hover': {
            backgroundColor: alpha(palette.background.barkSurface, 0.04),
          },

          '&:active': {
            backgroundColor: alpha(palette.background.barkSurface, 0.08),
          },
        },
      },

      variants: [
        {
          props: { variant: 'filter' },
          style: {
            padding: '5px 15px',
            border: `1px solid ${palette.text.secondary}`,
            borderRadius: '8px',
            ...typography.subtitle3,
            whiteSpace: 'nowrap',

            '& > .MuiButton-startIcon': {
              marginLeft: '-8px',

              '& > *:nth-of-type(1)': {
                fontSize: '18px',
              },
            },

            '& > .MuiButton-endIcon': {
              marginRight: '-8px',

              '& > *:nth-of-type(1)': {
                fontSize: '18px',
              },
            },

            '&.value-selected': {
              backgroundColor: palette.common.dimBlue,
              borderColor: palette.common.dimBlue,

              '&:hover': {
                backgroundColor: emphasize(palette.common.dimBlue, 0.05),
              },

              '&:active': {
                backgroundColor: emphasize(palette.common.dimBlue, 0.15),
              },

              '&.Mui-focused': {
                borderColor: palette.primary.light,
              },
            },
          },
        },
        {
          props: { variant: 'filter-contained' },
          style: {
            padding: '6px 16px',
            borderRadius: '8px',
            backgroundColor: alpha(palette.text.secondary, 0.08),
            color: palette.primary.light,
            ...typography.subtitle3,
            whiteSpace: 'nowrap',

            '&:hover': {
              backgroundColor: alpha(palette.text.secondary, 0.12),
            },

            '&:active': {
              backgroundColor: alpha(palette.text.secondary, 0.16),
            },

            '&.Mui-disabled': {
              color: alpha(palette.text.secondary, 0.36),
            },

            '& > .MuiButton-startIcon': {
              marginLeft: '-8px',

              '& > *:nth-of-type(1)': {
                fontSize: '18px',
              },
            },

            '& > .MuiButton-endIcon': {
              marginRight: '-8px',

              '& > *:nth-of-type(1)': {
                fontSize: '18px',
              },
            },
          },
        },
      ],
    },

    /**
     * Introducing a new custom `variant` value - "page". Should be applied to the
     * main container on a page. Provides proper styles for container in mobile view.
     */
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',

          '&.MuiPaper-page': {
            border: `1px solid ${palette.divider}`,

            [breakpoints.down('sm')]: {
              borderColor: palette.background.paper,

              '&.MuiPaper-rounded': {
                borderRadius: '0px',
              },
            },
          },
        },
      },
    },

    MuiCardHeader: {
      defaultProps: {
        disableTypography: true,
      },

      styleOverrides: {
        root: {
          position: 'relative',
          padding: 0,
          margin: '24px',

          [breakpoints.up('sm')]: {
            margin: '32px 24px',
          },
        },

        content: {
          maxWidth: '100%',
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          position: 'relative',
          padding: 0,
          margin: '24px',

          [breakpoints.up('sm')]: {
            margin: '32px 24px',
          },

          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },

    MuiContainer: {
      defaultProps: {
        maxWidth: 'xxl',
      },

      styleOverrides: {
        root: {
          padding: 0,

          [breakpoints.up('sm')]: {
            padding: '16px',
          },
        },
      },
    },

    /**
     * Introducing a new className: `centered`.
     * With `centered` class modal sill be always shown in the center (for mobile devices the default
     * behavior is to show modal at the bottom of the screen).
     */
    MuiDialog: {
      defaultProps: {
        maxWidth: false,
        TransitionComponent: forwardRef<any, TransitionProps & { children: ReactElement }>(
          (props, ref) => {
            const isTabletView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
            const classNames = props.className?.split(' ') ?? [];
            const isCentered = classNames.includes('centered');

            if (isCentered || isTabletView) return <Fade ref={ref} {...props} />;
            return <Slide direction="up" ref={ref} {...props} />;
          },
        ),
      },

      styleOverrides: {
        root: {
          zIndex: 1550,
        },

        paper: (props) => {
          const classNames = props.ownerState.className?.split(' ') ?? [];
          const isCentered = classNames.includes('centered');

          return {
            ...mixinObject(!isCentered, {
              [breakpoints.down('sm')]: {
                width: '100%',
                maxWidth: '100%',

                margin: 0,
                alignSelf: 'flex-end',
                borderRadius: 0,
                borderTopLeftRadius: shape.borderRadius,
                borderTopRightRadius: shape.borderRadius,
              },
            }),

            [breakpoints.up('sm')]: {
              borderRadius: shape.borderRadius * 2,
            },
          };
        },
      },
    },

    /**
     * Introducing a new classNames: `variant-filter` and `value-selected`.
     * This classNames will change style of the button to fit `MuiOutlinedInput`
     * styles. Element would be styled in the "filters" theme:
     * https://www.figma.com/file/5W5IsZ5cRvNeDkkFkKheZd/Investors'-App?type=design&node-id=1123-27006&t=JOA1LwP62Aku9T7d-0
     *
     * `value-selected` - works only with `variant-filter` and will highlight the
     * component if the value is selected (mainly for the dropdown Menu).
     */
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },

      styleOverrides: {
        root: {
          transition: transitions.create(['background-color', 'border-color', 'color', 'outline'], {
            duration: 150,
          }),

          '&.Mui-focusVisible': {
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineOffset: '2px',
            outlineColor: palette.primary.light,
          },

          '&:hover': {
            backgroundColor: alpha(palette.background.barkSurface, 0.04),
          },

          '&:active': {
            backgroundColor: alpha(palette.background.barkSurface, 0.08),
          },

          '&.variant-filter': {
            padding: '6px',
            borderRadius: '8px',
            border: `1px solid ${palette.text.secondary}`,

            '&.value-selected': {
              backgroundColor: palette.common.dimBlue,
              borderColor: palette.common.dimBlue,

              '&.Mui-focused': {
                borderColor: palette.primary.light,
              },
            },
          },
        },

        colorSecondary: {
          color: palette.text.primary,

          '&:hover': {
            backgroundColor: alpha(palette.background.barkSurface, 0.04),
          },

          '&:active': {
            backgroundColor: alpha(palette.background.barkSurface, 0.08),
          },
        },
      },
    },

    MuiFab: {
      defaultProps: {
        color: 'primary',
        size: 'medium',
      },

      styleOverrides: {
        sizeMedium: {
          width: '48px',
          height: '48px',
        },

        primary: {
          backgroundColor: palette.primary.dark,
          color: palette.common.white,

          '&:hover': {
            backgroundColor: emphasize(palette.primary.dark, 0.15),
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            position: 'relative',
            transform: 'unset',
            ...typography.subtitle3,
            fontWeight: 600,
            color: palette.text.primary,
            marginBottom: '4px',
            zIndex: 'initial',
          },

          '& .MuiInputBase-root': {
            '& fieldset': {
              top: 0,

              '& legend': {
                display: 'none',
              },
            },
          },
        },
      },
    },

    /**
     * Introducing a new className: `select-menu`.
     * This class name is usually added by the Select control to specify context
     * in which the menu is opened.
     */
    MuiMenu: {
      defaultProps: {
        elevation: 2,
      },

      styleOverrides: {
        root: {
          '&.select-menu': {
            '& .MuiPaper-root': {
              margin: '8px 0',
            },

            '& .MuiList-root': {
              '& .MuiMenuItem-root': {
                color: palette.text.primary,
                padding: '16px',

                '& .MuiListItemIcon-root': {
                  minWidth: '40px',
                },
              },
            },
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: palette.secondary.dark,

          '&.Mui-selected': {
            color: palette.text.primary,
          },
        },
      },
    },

    /**
     * Introducing a new classNames: `variant-filter` and `value-selected`.
     * This classNames will change style of the input (and Select component)
     * to fit our figma styles for filter inputs:
     * https://www.figma.com/file/5W5IsZ5cRvNeDkkFkKheZd/Investors'-App?type=design&node-id=1123-27006&t=JOA1LwP62Aku9T7d-0
     *
     * `value-selected` - works only with `variant-filter` and will highlight the
     * component if the value is selected (mainly for Select components).
     */
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          transition: transitions.create(['background-color'], {
            duration: 150,
          }),

          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.text.secondary,
            },
          },

          '&.variant-filter': {
            borderRadius: '8px',

            '& .MuiSelect-select': {
              minHeight: 'unset',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '6px 32px 6px 16px',
              ...typography.button,
            },

            '& .MuiSelect-icon': {
              color: palette.text.primary,
            },

            '&.value-selected': {
              backgroundColor: palette.common.dimBlue,

              '&:hover': {
                backgroundColor: emphasize(palette.common.dimBlue, 0.05),
              },

              '&:active': {
                backgroundColor: emphasize(palette.common.dimBlue, 0.15),
              },

              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.common.dimBlue,
              },

              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: palette.primary.light,
                },
              },
            },
          },

          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.light,
            },
          },
        },

        notchedOutline: {
          borderColor: alpha(palette.text.secondary, 0.36),
          transition: transitions.create(['border-color', 'border-width'], {
            duration: 150,
          }),
        },

        input: {
          padding: '16px',
        },

        inputSizeSmall: {
          padding: '8.5px 14px',
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '42px',

          [breakpoints.up('sm')]: {
            minHeight: '46px',
          },
        },

        flexContainer: {
          gap: '24px',
        },

        indicator: {
          backgroundColor: palette.text.primary,
        },

        scrollButtons: {
          margin: '0 0 10px',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '4px 12px',
          margin: '0 0 10px',
          ...typography.subtitle2,
          color: palette.text.secondary,
          borderRadius: shape.borderRadius,
          transition: transitions.create(['color', 'background-color'], {
            duration: 150,
          }),

          '&:hover': {
            backgroundColor: alpha(palette.background.barkSurface, 0.04),
          },

          [breakpoints.up('sm')]: {
            margin: '0 0 18px',
          },

          '&.Mui-selected': {
            color: palette.text.primary,
          },

          '&.Mui-disabled': {
            color: alpha(palette.secondary.dark, 0.38),
          },
        },
      },
    },

    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: palette.background.paper,
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
    },

    MuiTooltip: {
      defaultProps: {
        arrow: false,
        PopperProps: {
          modifiers: [
            {
              name: 'preventOverflow',
              options: { padding: 8 },
            },
          ],
        },
      },

      styleOverrides: {
        popper: {
          zIndex: 1650,

          '&&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
            marginBottom: '8px',
          },

          '&&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
            marginTop: '8px',
          },
        },

        tooltip: ({ ownerState, theme }) => ({
          margin: 'unset',
          backgroundColor: palette.background.paper,
          color: palette.text.primary,
          boxShadow: shadows[2],
          ...typography.body2,

          ...(ownerState.color === 'error' && {
            background: colors.red[50],
            color: colors.red[900],
          }),
        }),

        arrow: ({ ownerState, theme }) => ({
          color: palette.background.paper,

          ...(ownerState.color === 'error' && {
            color: colors.red[50],
          }),

          '&:before': {
            boxShadow: shadows[1],
          },
        }),
      },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle3: 'h6',
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '24px',
          padding: 0,
          overflow: 'initial',
        },

        colorPrimary: {
          '&.Mui-checked': {
            color: palette.common.white,

            '& .MuiSwitch-thumb': {
              borderColor: palette.common.white,
            },

            '&+.MuiSwitch-track': {
              backgroundColor: palette.primary.light,
              opacity: 1,
            },
          },

          '&+.MuiSwitch-track': {
            backgroundColor: palette.background.greySurface,
            opacity: 1,
          },

          '& .MuiSwitch-thumb': {
            border: `1px solid ${alpha(palette.text.secondary, 0.36)}`,
          },
        },

        track: {
          borderRadius: '64px',
        },

        switchBase: {
          top: '-5px',
          left: '-5px',
          padding: '8px',

          '&.Mui-checked': {
            transform: 'translateX(16px)',
          },
        },

        thumb: {
          width: '18px',
          height: '18px',
          boxShadow: 'none',
          transition: transitions.create(['border-color'], {
            duration: 150,
          }),
        },
      },
    },

    // Styles below is not adjusted to the latest design.

    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: '#C4C4C4',
        },

        select: {
          padding: 0,
        },

        spacer: {
          display: 'none',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: '0',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          padding: '12px 0',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.warn': {
            color: '#cd9800',
          },
          '&.error': {
            color: '#f44336',
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;

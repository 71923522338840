export enum EMarketTypeBasket {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface IBaseBasketDealIdentifier {
  id: string;
  providerId?: string;
}

export interface MarketTypeBasket<
  Search extends IBaseBasketDealIdentifier,
  StoredDeal extends Search,
> {
  deals: StoredDeal[];
  getDeal: (deal: Search) => StoredDeal | null;
  setDeal: (deal: StoredDeal) => void;
  removeDeal: (deal: Search) => void;
  clear: () => void;
}

export interface IPrimaryBasketDeal extends IBaseBasketDealIdentifier {
  currency: string;
  amount: number;
}

export interface ISecondaryBasketDeal extends IBaseBasketDealIdentifier {
  currency: string;
  amount: number;
  latestCalculatedPrice?: number;
  buyerFeeAmount?: number;
  accruedInterest?: number;
  multiplierAmount?: number
}

export interface IBasketContext {
  primary: MarketTypeBasket<IBaseBasketDealIdentifier, IPrimaryBasketDeal>;
  secondary: MarketTypeBasket<IBaseBasketDealIdentifier, ISecondaryBasketDeal>;
}

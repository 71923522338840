import { useState } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { useAsyncCallback } from 'react-async-hook';
import { FormattedMessage, useIntl } from 'react-intl';

import { paymentsApiClient } from 'api';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';
import { AmountInput } from 'components';

import { IbanDisplay } from '../shared';

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    padding: '48px 24px',
    background: theme.palette.background.barkSurface004,
    textAlign: 'center',
  },

  dialogContent: {
    padding: '24px 24px 32px',
  },

  submitButton: {
    ...(theme.typography.subtitle2 as any),
  },
}));

interface Props {
  currency: Currency;
  onReload: () => Promise<void>;
  bankAccount?: string;
  unpaidCommitments?: number;
  isDDActivated?: boolean;
  setDepositInitAmount: React.Dispatch<React.SetStateAction<string>>;
}

export const DirectDebitTab = (props: Props) => {
  const {
    currency,
    onReload,
    bankAccount,
    unpaidCommitments,
    isDDActivated,
    setDepositInitAmount,
  } = props;

  const classes = useStyles();
  const intl = useIntl();
  const formatMoney = useFormatMoney({ prefix: `${currency} ` });

  const [amount, setAmount] = useState(0);

  const handleDDDeposit = useAsyncCallback(async () => {
    void paymentsApiClient.api.investorApiControllerCreatePayin({
      currency,
      amount: moveDecimalPoint(amount, 2),
    });
    setDepositInitAmount(formatMoney(amount));
    toast.success(intl.formatMessage({ id: 'your_deposit_order_has_been_set' }));
    onReload();
  });

  return (
    <Box>
      <Box className={classes.dialogHeader}>
        <Typography variant="subtitle2" marginBottom="16px">
          <FormattedMessage id="deposit" />
        </Typography>

        <AmountInput
          amount={amount}
          setAmount={setAmount}
          currency={currency}
          disabled={handleDDDeposit.loading}
        />

        {!!unpaidCommitments && (
          <Typography variant="body1" color="error" marginTop="8px">
            <FormattedMessage id="unpaid_commitments" />:{' '}
            {formatMoney(moveDecimalPoint(unpaidCommitments, -2))}
          </Typography>
        )}
      </Box>

      <Box className={classes.dialogContent}>
        {bankAccount && (
          <IbanDisplay
            currency={currency}
            iban={bankAccount}
            label={<FormattedMessage id="charge" />}
          />
        )}

        <Button
          fullWidth
          className={classes.submitButton}
          onClick={handleDDDeposit.execute}
          disabled={!bankAccount || !amount || !isDDActivated || handleDDDeposit.loading}
          startIcon={handleDDDeposit.loading && <CircularProgress size={18} color="inherit" />}
        >
          <FormattedMessage id="deposit_initiate" />
        </Button>
      </Box>
    </Box>
  );
};

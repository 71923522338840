import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { CurrencyIcon } from 'components/UI';
import { Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  ibanContainer: {
    display: 'flex',
    gap: '8px',
  },

  iban: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.barkSurface004,
  },

  infoText: {
    marginTop: '8px',
    marginBottom: '32px',
    fontWeight: 300,

    '& > a': {
      fontWeight: 400,
    },
  },
}));

interface Props {
  label: ReactNode;
  iban: string;
  currency: Currency;
}

export const IbanDisplay = ({ label, iban, currency }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="subtitle3" marginBottom="8px">
        {label}
      </Typography>

      <Box className={classes.ibanContainer}>
        <Typography variant="subtitle3" className={classes.iban}>
          {iban}
        </Typography>
        <CurrencyIcon currency={currency} size={40} />
      </Box>

      <Typography component="p" variant="caption" className={classes.infoText}>
        <FormattedMessage
          id="change_payment_destination"
          values={{
            a: (msg: string) => (
              <Link component={RouterLink} to={`./settings/account`}>
                {msg}
              </Link>
            ),
          }}
        />
      </Typography>
    </Box>
  );
};

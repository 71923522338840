import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DealDto } from 'api/investor-client';
import { DetailsSubTabs } from 'components';
import { IDealDetails } from 'utils';
import { ExpectedRepayments } from 'types';

import { DealDetailsSubTab } from './DealDetailsSubTab';
import { BorrowerSubTab } from './BorrowerSubTab';
import { RiskAssessmentSubTab } from './RiskAssessmentSubTab';
import { AdditionalInformationSubTab } from './AdditionalInformationSubTab';

interface Props {
  deal: DealDto;
  dealDetails: IDealDetails;
  repaymentsData?: ExpectedRepayments;
  loading?: boolean;
  totalAmount: number;
}

enum ESubTab {
  DealDetails = 'deal_details',
  RiskAssessment = 'risk_assessment',
  Borrower = 'borrower_information',
  AdditionalInformation = 'additional_information',
}

export const DealInformation = (props: Props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(ESubTab.DealDetails);

  const subTabs = useMemo(
    () => [
      {
        id: ESubTab.DealDetails,
        title: intl.formatMessage({ id: ESubTab.DealDetails }),
        content: DealDetailsSubTab,
      },
      {
        id: ESubTab.RiskAssessment,
        title: intl.formatMessage({ id: ESubTab.RiskAssessment }),
        content: RiskAssessmentSubTab,
      },
      {
        id: ESubTab.Borrower,
        title: intl.formatMessage({ id: ESubTab.Borrower }),
        content: BorrowerSubTab,
      },
      {
        id: ESubTab.AdditionalInformation,
        title: intl.formatMessage({ id: ESubTab.AdditionalInformation }),
        content: AdditionalInformationSubTab,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage],
  );

  return (
    <DetailsSubTabs
      tabs={subTabs}
      activeTab={activeTab}
      onTabChange={setActiveTab as any}
      tabProps={props}
    />
  );
};

import { useRef } from 'react';
import { Box, CircularProgress, Fade, alpha, styled } from '@mui/material';

const SWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
}));

interface Props {
  loading?: boolean;
}

export const CardLoader = ({ loading }: Props) => {
  const initialLoading = useRef(loading);

  return (
    <Fade in={loading} appear={!initialLoading.current} timeout={250}>
      <SWrapper>
        <CircularProgress />
      </SWrapper>
    </Fade>
  );
};

import type { VirtualAccountDTO } from 'api/payments-client';
import type { BalanceDto, BulkError } from 'api/investor-client';
import type { Currency, BulkResultWithPayment } from 'types';
import { MarketType } from 'types/secondary-market';

export enum EBasketReviewTabType {
  Paid = 'Paid',
  Pending = 'Pending',
  Unpaid = 'Unpaid',
  Rejected = 'Rejected',
}

export interface ITabObjet {
  value: EBasketReviewTabType;
  size: number;
  color?: string;
}

export type TTabData = Partial<Record<Currency, Array<BulkResultWithPayment | BulkError>>>;

export type TTabsData = Record<EBasketReviewTabType, TTabData>;

export interface InvestmentConfirmationModalOptions {
  virtualAccount?: VirtualAccountDTO;
  oldBalance?: BalanceDto[];
  commitmentResult?: {
    result: BulkResultWithPayment[];
    errors: BulkError[];
  };
}

export interface InvestmentConfirmationModalOptionsWithType extends InvestmentConfirmationModalOptions {
  type?: MarketType
}

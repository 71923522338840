import { useMemo } from 'react';
import { Alert, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { DealDto } from 'api/investor-client';
import { DataOverviewTable, ITableRow } from 'components';
import { IDealDetails, mixinArray } from 'utils';

interface Props {
  deal: DealDto;
  dealDetails: IDealDetails;
  totalAmount: number;
}

export const BorrowerSubTab = ({ deal }: Props) => {
  const intl = useIntl();

  const borrowerOverviewRows = useMemo((): ITableRow[] => {
    const borrowerInformation = (deal.risk.genericData as any)?.borrowerInformation;
    if (deal.risk.productType === 'PRIVATE' || (
      deal.risk.productType === 'REAL_ESTATE' && !!borrowerInformation?.length
    )) {
      const dataArray = (borrowerInformation ?? []) as [{ id: string, value: string }];
      const data = Object.values(dataArray).reduce((acc, val) => ({ ...acc, [val.id]: val.value }), {} as any)
      return [
        ...mixinArray(!!deal.borrower.marketplaceId, [
          {
            cols: [
              intl.formatMessage({ id: 'marketplace.id' }),
              deal.borrower.marketplaceId,
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerPnumber, [
          {
            cols: [
              intl.formatMessage({ id: 'originator.id' }),
              data.borrowerPnumber,
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerGender, [
          {
            cols: [
              intl.formatMessage({ id: 'gender' }),
              data.borrowerGender,
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerNationality, [
          {
            cols: [
              intl.formatMessage({ id: 'common.form_field.nationality' }),
              data.borrowerNationality,
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerAge, [
          {
            cols: [
              intl.formatMessage({ id: 'age' }),
              data.borrowerAge,
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerCountryOfResidence, [
          {
            cols: [
              intl.formatMessage({ id: 'country_of_residence' }),
              data.borrowerCountryOfResidence,
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerRegionOfResidence, [
          {
            cols: [
              intl.formatMessage({ id: 'region_of_residence' }),
              data.borrowerRegionOfResidence,
            ],
          },
        ]),
      ];
    }
    return [
      ...mixinArray(!!deal.borrower.marketplaceId, [
        {
          cols: [
            intl.formatMessage({ id: 'marketplace.id' }),
            deal.borrower.marketplaceId,
          ],
        },
      ]),
      ...mixinArray(!!deal.borrower.originatorId, [
        {
          cols: [
            intl.formatMessage({ id: 'originator.id' }),
            deal.borrower.originatorId,
          ],
        },
      ]),
      ...mixinArray(!!deal.borrower.industry, [
        {
          cols: [
            intl.formatMessage({ id: 'industry' }),
            deal.borrower.industry,
          ],
        },
      ]),
      ...mixinArray(!!deal.borrower.legalForm, [
        {
          cols: [
            intl.formatMessage({ id: 'legal_form' }),
            deal.borrower.legalForm,
          ],
        },
      ]),
      ...mixinArray(!!deal.borrower.country, [
        {
          cols: [intl.formatMessage({ id: 'country' }), deal.borrower.country],
        },
      ]),
      ...mixinArray(!!deal.borrower.region, [
        {
          cols: [intl.formatMessage({ id: 'region' }), deal.borrower.region],
        },
      ]),
      ...mixinArray(!!deal.borrower.incorporationDate, [
        {
          cols: [
            intl.formatMessage({ id: 'date_of_incorporation' }),
            dayjs(deal.borrower.incorporationDate).format('DD.MM.YYYY'),
          ],
        },
      ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, intl.formatMessage]);

  const partnerRows = useMemo((): ITableRow[] => {
    const borrowerInformation = (deal.risk.genericData as any)?.borrowerInformation;
    if (deal.risk.productType === 'PRIVATE' || (
      deal.risk.productType === 'REAL_ESTATE' && !!borrowerInformation?.length
    )) {
      const dataArray = (borrowerInformation ?? []) as [{ id: string, value: string }];
      const data = Object.values(dataArray).reduce((acc, val) => ({ ...acc, [val.id]: val.value }), {} as any)
      return [
        ...mixinArray(!!data.partnerGender, [
          {
            cols: [
              intl.formatMessage({ id: 'gender' }),
              data.partnerGender,
            ],
          },
        ]),
        ...mixinArray(!!data.partnerNationality, [
          {
            cols: [
              intl.formatMessage({ id: 'common.form_field.nationality' }),
              data.partnerNationality,
            ],
          },
        ]),
        ...mixinArray(!!data.partnerAge, [
          {
            cols: [
              intl.formatMessage({ id: 'age' }),
              data.partnerAge,
            ],
          },
        ]),
        ...mixinArray(!!data.partnerCountryOfResidence, [
          {
            cols: [
              intl.formatMessage({ id: 'country_of_residence' }),
              data.partnerCountryOfResidence,
            ],
          },
        ]),
        ...mixinArray(!!data.partnerRegionOfResidence, [
          {
            cols: [
              intl.formatMessage({ id: 'region_of_residence' }),
              data.partnerRegionOfResidence,
            ],
          },
        ]),
      ];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, intl.formatMessage]);

  const ratingRows = useMemo((): ITableRow[] => {
    if (deal.risk.productType === 'PRIVATE') return [];
    return [
      ...mixinArray(!!deal.borrower.rating, [
        {
          cols: [
            intl.formatMessage({ id: 'general_client_rating' }),
            deal.borrower.rating,
          ],
        },
      ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, intl.formatMessage]);

  const historyRows = useMemo((): ITableRow[] => {
    const historyMonths = deal.borrower.firstPayoutDate ? dayjs().diff(deal.borrower.firstPayoutDate, 'months') : 0;
    const showMonths = historyMonths < 12;
    return [
      ...mixinArray(!!deal.borrower.firstPayoutDate, [
        {
          cols: [
            intl.formatMessage({ id: 'client_history' }),
            `${showMonths ? historyMonths : Math.floor(historyMonths / 12)
            } ${intl.formatMessage({ id: showMonths ? 'months' : 'years' })}`,
          ],
        },
      ]),
      ...mixinArray(!!deal.borrower.paymentHistory, [
        {
          cols: [
            intl.formatMessage({ id: 'payment_history' }),
            deal.borrower.paymentHistory,
          ],
        },
      ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, intl.formatMessage]);

  if (!ratingRows?.length && !historyRows?.length && !borrowerOverviewRows?.length) {
    return (
      <Alert severity="warning" color="info">
        <FormattedMessage id="warning.no_info" />
      </Alert>
    );
  }

  return (
    <Grid container mt={0} spacing={4}>
      {(!!borrowerOverviewRows?.length || !!partnerRows?.length) && (
        <Grid item md={6} sm={12}>
          <Grid container direction="column" spacing={4}>
            {!!borrowerOverviewRows?.length && (
              <Grid item>
                <DataOverviewTable
                  title={intl.formatMessage({ id: 'borrower_overview' })}
                  rows={borrowerOverviewRows}
                  divided
                />
              </Grid>
            )}
            {!!partnerRows?.length && (
              <Grid item>
                <DataOverviewTable
                  title={intl.formatMessage({ id: 'overview_partner' })}
                  rows={partnerRows}
                  divided
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {(!!ratingRows?.length || !!historyRows?.length) && (
        <Grid item md={6} sm={12}>
          <Grid container direction="column" spacing={4}>
            {!!ratingRows?.length && (
              <Grid item>
                <DataOverviewTable
                  title={intl.formatMessage({ id: 'rating' })}
                  rows={ratingRows}
                  divided
                />
              </Grid>
            )}
            {!!historyRows?.length && (
              <Grid item>
                <DataOverviewTable
                  title={intl.formatMessage({ id: 'history' })}
                  rows={historyRows}
                  divided
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

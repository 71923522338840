import { ReactNode, useMemo } from 'react';
import {
  Card,
  CardHeader,
  Box,
  Typography,
  Divider,
  CardContent,
  Switch,
  alpha,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

import { ENotificationID } from 'api/notifications-client';
import { INotificationConfigOption } from './types';

const useNotificationConfigurationGroupStyles = makeStyles((theme) => ({
  configurationGroup: {},

  configurationTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },

  configurationGroupContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    marginTop: '24px',
  },

  notificationOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface NotificationConfigurationGroupProps {
  title: string;
  subtitle?: string;
  options?: INotificationConfigOption[];
  onOptionChange?: (change: INotificationConfigOption) => void;
  generalSwitch?: boolean | undefined;
  onGeneralSwitchChanged?: (newState: boolean) => void;
}

const NotificationConfigurationGroup = (props: NotificationConfigurationGroupProps) => {
  const { title, subtitle, options, onOptionChange, generalSwitch, onGeneralSwitchChanged } = props;
  const classes = useNotificationConfigurationGroupStyles();

  return (
    <Box className={classes.configurationGroup}>
      <Box className={classes.configurationTitle}>
        <Typography variant="subtitle2">{title}</Typography>

        {generalSwitch !== undefined && (
          <Switch
            checked={generalSwitch}
            onChange={(e) => onGeneralSwitchChanged?.(e.target.checked)}
          />
        )}
      </Box>

      {!!subtitle && (
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      )}

      {!!options?.length && (
        <Box className={classes.configurationGroupContent}>
          {options.map((option) => (
            <Box key={option.id} className={classes.notificationOption}>
              <Typography variant="body2">{option.label}</Typography>
              <Switch
                checked={option.checked}
                onChange={(e) => onOptionChange?.({ ...option, checked: e.target.checked })}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
  },

  cardHeader: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'space-between',
  },

  cardHeaderIcon: {
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    backgroundColor: alpha(theme.palette.text.secondary, 0.08),
    fontSize: '24px',
  },

  headerContent: {
    flexGrow: 1,
  },

  headerDivider: {
    marginTop: '24px',
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '56px',
    margin: '56px 24px',
  },
}));

interface Props {
  title: string;
  subtitle?: string;
  icon: ReactNode;
  options?: INotificationConfigOption[];
  onOptionsChange?: (changes: INotificationConfigOption[]) => void;
  loading?: boolean;
}

export const NotificationConfigurationCard = (props: Props) => {
  const { title, subtitle, icon, options, onOptionsChange, loading } = props;

  const classes = useStyles();
  const intl = useIntl();

  // prettier-ignore
  const { customNotificationsOption, paymentMethodsOptions, dealsInvestmentsOptions } = useMemo(() => {
    let customNotificationsOption: INotificationConfigOption | undefined;
    const paymentMethodsOptions: INotificationConfigOption[] = [];
    const dealsInvestmentsOptions: INotificationConfigOption[] = [];

    for (const option of (options ?? [])) {
      if (option.id === ENotificationID.DealFilter) {
        customNotificationsOption = option;
      } else if ([ENotificationID.BankAccountChangeLsvStatus].includes(option.id)) {
        paymentMethodsOptions.push(option);
      } else {
        dealsInvestmentsOptions.push(option);
      }
    }

    return { customNotificationsOption, paymentMethodsOptions, dealsInvestmentsOptions };
  }, [options]);

  const totalSwitchState = useMemo(() => {
    return (options ?? []).some((o) => o.checked);
  }, [options]);

  const handleTotalSwitchChanged = (newState: boolean) => {
    if (!options) return;

    const changes = options
      .filter((o) => o.checked !== newState)
      .map((o) => ({ ...o, checked: !o.checked }));

    onOptionsChange?.(changes);
  };

  const withOptions = !!options?.length;

  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        title={
          <>
            <Box className={classes.cardHeader}>
              <Box className={classes.cardHeaderIcon}>{icon}</Box>

              <Box className={classes.headerContent}>
                <Typography variant="subtitle2" marginBottom="8px">
                  {title}
                </Typography>
                {!!subtitle && (
                  <Typography variant="body2" fontWeight={300} maxWidth="450px">
                    {subtitle}
                  </Typography>
                )}
              </Box>

              {withOptions && (
                <Switch
                  checked={totalSwitchState}
                  onChange={(e) => handleTotalSwitchChanged(e.target.checked)}
                />
              )}
            </Box>

            {(withOptions || loading) && <Divider className={classes.headerDivider} />}
          </>
        }
      />

      {loading && (
        <CardContent className={classes.cardContent}>
          <Box>
            <Skeleton width="40%" />
            <Skeleton width="70%" sx={{ marginBottom: '24px' }} />
            <Skeleton width="55%" />
          </Box>
          <Box>
            <Skeleton width="40%" />
            <Skeleton width="70%" sx={{ marginBottom: '24px' }} />
            <Skeleton width="55%" sx={{ marginBottom: '32px' }} />
            <Skeleton width="45%" sx={{ marginBottom: '32px' }} />
            <Skeleton width="55%" />
          </Box>
        </CardContent>
      )}

      {withOptions && (
        <CardContent className={classes.cardContent}>
          {!!customNotificationsOption && (
            <NotificationConfigurationGroup
              title={intl.formatMessage({ id: 'custom_notifications' })}
              subtitle={intl.formatMessage({ id: 'custom_notifications.description' })}
              generalSwitch={customNotificationsOption.checked}
              onGeneralSwitchChanged={(newState) => {
                onOptionsChange?.([{ ...customNotificationsOption, checked: newState }]);
              }}
            />
          )}

          {paymentMethodsOptions.length > 0 && (
            <NotificationConfigurationGroup
              title={intl.formatMessage({ id: 'payment_methods' })}
              subtitle={intl.formatMessage({ id: 'payment_methods.description' })}
              options={paymentMethodsOptions}
              onOptionChange={(change) => onOptionsChange?.([change])}
            />
          )}

          {dealsInvestmentsOptions.length > 0 && (
            <NotificationConfigurationGroup
              title={intl.formatMessage({ id: 'deals_and_investments' })}
              subtitle={intl.formatMessage({ id: 'deals_and_investments.description' })}
              options={dealsInvestmentsOptions}
              onOptionChange={(change) => onOptionsChange?.([change])}
            />
          )}
        </CardContent>
      )}
    </Card>
  );
};

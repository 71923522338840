import { ReactNode } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import theme from 'config/theme';

const useStyles = makeStyles(() => ({
  container: {
    '& .data-overview-list__row': {
      display: 'flex',

      '&:has(.data-overview-list__text_header)': {
        marginTop: '16px',
      },
    },

    '& .data-overview-list__cell': {
      padding: '2px 0',

      '&.cell-data': {
        textAlign: 'right',
      },
    },

    '& .data-overview-list__text_header': {},

    '& .data-overview-list__text_summary': {
      fontWeight: 500,
    },

    '& .data-overview-list__text': {
      color: theme.palette.text.secondary,
    },

    '& .data-overview-list__divider': {
      margin: '8px 0',
      backgroundColor: '#72777F',
    },
  },
}));

export interface DataOverviewListRow {
  title: ReactNode;
  value: ReactNode;
  /** default: `normal` */
  variant?: 'header' | 'normal' | 'summary';
  divider?: boolean;
  sx?: SxProps;
}

export interface DataOverviewListProps {
  rows: DataOverviewListRow[];
  className?: string;
  emptyMessage?: ReactNode;
}

export const DataOverviewList = (props: DataOverviewListProps) => {
  const { rows, className, emptyMessage } = props;
  const classes = useStyles();

  return (
    <Box className={clsx('data-overview-list', classes.container, className)}>
      {rows.map((row, idx) => {
        return (
          <>
            {row.variant === 'summary' && (
              <Divider className="data-overview-list__divider" />
            )}

            <Box key={idx} className="data-overview-list__row" sx={row.sx}>
              <Box
                className="data-overview-list__cell cell-title"
                sx={{ flexGrow: 1 }}
              >
                <Typography
                  variant="body2"
                  className={`data-overview-list__text${
                    row.variant ? `_${row.variant}` : ''
                  }`}
                >
                  {row.title}
                </Typography>
              </Box>
              <Box
                className="data-overview-list__cell cell-data"
                sx={{ flexGrow: 0 }}
              >
                <Typography
                  variant="body2"
                  className={`data-overview-list__text${
                    row.variant ? `_${row.variant}` : ''
                  }`}
                >
                  {row.value}
                </Typography>
              </Box>
            </Box>

            {rows.length === 0 && <>{emptyMessage}</>}
          </>
        );
      })}
    </Box>
  );
};

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Will change the state if a provided value is changed.
 */
export const useControlledState = <T>(value: T): [T, Dispatch<SetStateAction<T>>] => {
  const [current, setCurrent] = useState<T>(value);

  useEffect(() => {
    if (value !== current) setCurrent(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [current, setCurrent];
};

import { Language } from 'types';
import deTranslationMessages from './de.json';
import enTranslationMessages from './en.json';
import frTranslationMessages from './fr.json';
import itTranslationMessages from './it.json';
import achTranslationMessages from './ach.json';

export const translationMessages = {
  [Language.DE]: deTranslationMessages,
  [Language.EN]: enTranslationMessages,
  // [Language.FR]: frTranslationMessages,
  // [Language.IT]: itTranslationMessages,
  [Language.ACH]: achTranslationMessages,
};

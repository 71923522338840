import dayjs, { Dayjs } from 'dayjs';

import {
  DealDto,
  IDealDto,
  InvestmentWithDealDto,
  RepaymentDto,
  ShareRepaymentTransactionDto,
  ShareTransactionDto,
} from 'api/investor-client';
import { IBaseBasketDealIdentifier } from 'providers/basket';
import { SecondaryDeal } from 'types/secondary-market';

// --- Deal Details ---

interface ICommonDetails {
  investorCommittedAmount: number;
  investorCommittedDate: Date | null;
  totalCommittedAmount: number;
  totalRestToCommit: number;
  isInvestorCommitted: boolean;
  isLastInvestor: boolean;
}

interface IConfigDetails {
  minInvestment: number;
  maxInvestment: number;
  investmentStep: number;
  maxInvestors: number | null;
}

export interface IDealDetails {
  common: ICommonDetails;
  config: IConfigDetails;
}

const getCommonDetails = (deal: DealDto, investorId: string): ICommonDetails => {
  let investorCommittedAmount = 0;
  let isInvestorCommitted = false;
  let investorCommittedDate = null;

  for (const commitment of deal.dealProviderShare?.commitments ?? []) {
    if (!commitment.confirmed && !commitment.canceled && commitment.investorId === investorId) {
      if (commitment.amount > 0) {
        investorCommittedAmount += commitment.amount;
        isInvestorCommitted = true;
        investorCommittedDate = new Date(commitment.date);
      }
    }
  }

  for (const share of deal.shares ?? []) {
    if (share.investorId === investorId) {
      if (share.amount > 0) {
        investorCommittedAmount += share.amount;
        isInvestorCommitted = true;
        investorCommittedDate = new Date(share.date ?? share.createdDate);
      }
    }
  }

  const totalRestToCommit = deal.dealProviderShare?.unpaidAmount ?? 0;
  const totalCommittedAmount = deal.principal - totalRestToCommit;

  const isLastInvestor = isInvestorCommitted
    ? false
    : deal.configuration.maxInvestors
      ? deal.configuration.maxInvestors === (deal.dealProviderShare?.committedInvestorsCount ?? 0) + 1
      : false;

  return {
    investorCommittedAmount,
    investorCommittedDate,
    totalCommittedAmount,
    totalRestToCommit,
    isInvestorCommitted,
    isLastInvestor,
  };
};

export const getDealDetails = (deal: DealDto, investorId: string): IDealDetails => {
  let {
    investorCommittedAmount,
    investorCommittedDate,
    isInvestorCommitted,
    totalRestToCommit,
    totalCommittedAmount,
    isLastInvestor,
  } = getCommonDetails(deal, investorId);

  const investmentStep = deal.configuration.investmentStep || 1;
  let minInvestment = deal.configuration.minInvestment || 0;

  const maxTemp = Math.max(totalRestToCommit - minInvestment, 0);
  const maxInvestment = maxTemp ? maxTemp - (maxTemp % investmentStep) : totalRestToCommit;

  if (minInvestment) {
    minInvestment = Math.max(minInvestment - investorCommittedAmount, investmentStep);

    // leave a space for the last investor
    if (minInvestment * 2 > totalRestToCommit) {
      isLastInvestor = true;
    }

    minInvestment = isLastInvestor ? totalRestToCommit : minInvestment;

    // to avoid overlapping (can be improved ?)
    if (minInvestment > maxTemp) {
      minInvestment = 0;
    }
  }

  return {
    common: {
      investorCommittedAmount,
      investorCommittedDate,
      isInvestorCommitted,
      totalRestToCommit,
      totalCommittedAmount,
      isLastInvestor,
    },
    config: {
      minInvestment,
      maxInvestment,
      investmentStep,
      maxInvestors: deal.configuration.maxInvestors || null,
    },
  };
};

// --- Repayments ---

type RepaymentWithDates = Modify<RepaymentDto, { date: Dayjs; repaymentDate?: Dayjs }>;

interface Repayment extends ShareTransactionDto {
  repayment: RepaymentWithDates;
}

const getScheduledNextRepayment = (repayments: Repayment[]): Repayment | null => {
  const today = dayjs().startOf('day');

  return (
    repayments.find(
      (r) =>
        !r.repayment.repaymentDate &&
        (r.repayment.date.isAfter(today) || r.repayment.date.isSame(today)),
    ) ?? null
  );
};

const getActualLastRepayment = (repayments: Repayment[]): Repayment | null => {
  let result: Repayment | null = null;

  repayments.forEach((r) => {
    if (r.repayment.repaymentDate) result = r;
  });

  return result;
};

const getScheduledLastRepayment = (
  repayments: Repayment[],
  isActualDateOfLastRepayment = false,
): Repayment | null => {
  const today = dayjs().startOf('day');
  let result: Repayment | null = isActualDateOfLastRepayment ? repayments[0] ?? null : null;

  repayments.forEach((r) => {
    if (r.repayment.date.isBefore(today) || r.repayment.date.isSame(today)) {
      result = r;
    }
  });

  return result;
};

const getFirstMissedRepayment = (repayments: Repayment[]): Repayment | null => {
  const today = dayjs().startOf('day');

  return (
    repayments.find((r) => r.repayment.date.isBefore(r.repayment.repaymentDate ?? today)) ?? null
  );
};

const getRepaymentAmountInDelay = (repayments: Repayment[]): number => {
  const today = dayjs().startOf('day');
  let amount = 0;

  repayments.forEach((r) => {
    if (!r.repayment.repaymentDate) {
      if (r.repayment.date.isBefore(today)) amount += r.total;
    }
  });

  return amount;
};

export const getInvestmentRepaymentInfo = (investment: InvestmentWithDealDto) => {
  const repayments: Repayment[] = investment.repayments
    .map((rep) => {
      const r = { ...rep.transaction } as Repayment;

      r.repayment = investment.deal.repayments.find(
        (r) => rep.transaction.date && r.id.includes(rep.transaction.date),
      ) as unknown as RepaymentWithDates;

      r.repayment = { ...r.repayment };
      r.repayment.date = dayjs(r.repayment.date).startOf('day');

      if (r.repayment.repaymentDate) {
        r.repayment.repaymentDate = dayjs(r.repayment.repaymentDate).startOf('day');
      }

      return r;
    })
    .sort((a, b) => a.repayment.date.valueOf() - b.repayment.date.valueOf());

  const nextRepayment = getScheduledNextRepayment(repayments);
  const actualLastRepayment = getActualLastRepayment(repayments);
  const scheduledLastRepayment = getScheduledLastRepayment(repayments, !!actualLastRepayment);
  const firstMissedRepayment = getFirstMissedRepayment(repayments);
  const amountInDelay = getRepaymentAmountInDelay(repayments);

  return {
    nextRepaymentDate: nextRepayment?.repayment.date ?? null,
    nextRepaymentAmount: nextRepayment?.total ?? null,
    actualLastRepaymentDate: actualLastRepayment?.repayment.repaymentDate ?? null,
    scheduledLastRepaymentDate: scheduledLastRepayment?.repayment.date ?? null,
    repaymentAmountInDelay: amountInDelay,
    firstMissedRepaymentDate: firstMissedRepayment?.repayment.date ?? null,
  };
};

// --- Shares ---

interface IInvestmentLike {
  repayments?: ShareRepaymentTransactionDto[];
}

const createRepaymentResultObject = () => {
  return {
    paid: {
      principal: 0,
      interest: 0,
    },

    overdue: {
      principal: 0,
      interest: 0,
    },

    loss: {
      principal: 0,
      interest: 0,
    },

    pending: {
      principal: 0,
      interest: 0,
    },

    outstanding: {
      principal: 0,
      interest: 0,
    },
  };
};

export const getTotalRepaymentDetails = (repayments: ShareRepaymentTransactionDto[]) => {
  const result = createRepaymentResultObject();

  for (const repayment of repayments) {
    let type: keyof typeof result | undefined;

    if (repayment.isBooked) type = 'paid';
    else if (repayment.daysInArrears > 0) type = 'overdue';
    else if (repayment.isLost) type = 'loss';
    else if (repayment.isPaid) type = 'pending';

    if (!type) type = 'outstanding';

    result[type].principal += repayment.transaction.principal;
    result[type].interest += repayment.transaction.interest;
  }

  return result;
};

export const getTotalRepaymentDetailsFromShares = (shares: IInvestmentLike[]) => {
  const total = createRepaymentResultObject();

  for (const share of shares) {
    if (share.repayments) {
      const result = getTotalRepaymentDetails(share.repayments);

      total.paid.principal += result.paid.principal;
      total.paid.interest += result.paid.interest;
      total.overdue.principal += result.overdue.principal;
      total.overdue.interest += result.overdue.interest;
      total.loss.principal += result.loss.principal;
      total.loss.interest += result.loss.interest;
      total.pending.principal += result.pending.principal;
      total.pending.interest += result.pending.interest;
      total.outstanding.principal += result.outstanding.principal;
      total.outstanding.interest += result.outstanding.interest;
    }
  }

  return total;
};

export const getDurationToEndDeal = (deal: IDealDto | DealDto) => {
  const endDate = dayjs(new Date(deal.endDate));
  const durationInDays = endDate.diff(dayjs(), 'days');
  const durationInMonths = endDate.diff(dayjs(), 'months', true);
  const displayInMonths = durationInMonths >= 1;

  const durationToEnd = displayInMonths
    ? Math.round(durationInMonths)
    : Math.max(durationInDays, 0);

  return {
    durationToEnd,
    displayInMonths
  }
}

export const getDealsComparator = (basketDeal: IBaseBasketDealIdentifier) => {
  return (sd: IDealDto) => {
    return basketDeal.id === sd.id && basketDeal.providerId === sd.providerId;
  };
};

export const getSecondaryDealsComparator = (basketDeal: IBaseBasketDealIdentifier) => {
  return (sd: SecondaryDeal) => {
    return basketDeal.id === sd.id;
  };
};

export const getDealsComparator2 = (sd: | IDealDto) => {
  return (basketDeal: IBaseBasketDealIdentifier) => {
    return basketDeal.id === sd.id && basketDeal.providerId === sd.providerId;
  };
};

export const getSecondaryDealsComparator2 = (sd: SecondaryDeal) => {
  return (basketDeal: IBaseBasketDealIdentifier) => {
    return basketDeal.id === sd.id;
  };
};

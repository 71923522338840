import { useCallback, useMemo, useRef } from 'react';
import { formatMoney } from 'utils';

type Config = Parameters<typeof formatMoney>[1];

export const useFormatMoney = (config?: Config) => {
  const configRef = useRef(config);
  configRef.current = config;

  return useCallback((value: number, overrideConfig?: Config) => {
    return formatMoney(value, Object.assign({}, configRef.current, overrideConfig));
  }, []);
};

export const useFormattedMoney = (value: number, config?: Config) => {
  return useMemo(() => formatMoney(value, config), [value, config]);
};

import { useRouteMatch, Switch, Route } from 'react-router';

import { EmailVerificationPage } from 'pages';

export const VerificationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new-email/:token`}>
        <EmailVerificationPage />
      </Route>
    </Switch>
  );
};

import { useCallback, useEffect, useRef } from 'react';

export const useDebounce = <R extends Array<any>, T extends (...args: R) => any>(
  fn: T,
  wait: number = 1000,
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current!);
  }, []);

  return useCallback(
    (...args: R) => {
      clearTimeout(timeoutRef.current!);
      timeoutRef.current = setTimeout(() => fn(...args), wait);
    },
    [fn, wait],
  );
};

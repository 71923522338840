import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

const MS_IN_SECOND = 1000;
const MS_IN_MINUTE = 60 * MS_IN_SECOND;
const MS_IN_HOUR = 60 * MS_IN_MINUTE;
const MS_IN_DAY = 24 * MS_IN_HOUR;

const formatCountdown = (duration: number) => {
  let result = '';
  let leftoverDuration = duration;

  const daysInDuration = (leftoverDuration / MS_IN_DAY) | 0;
  leftoverDuration = leftoverDuration % MS_IN_DAY;
  if (daysInDuration > 0) result += `${daysInDuration}d `;

  const hoursInDuration = (leftoverDuration / MS_IN_HOUR) | 0;
  leftoverDuration = leftoverDuration % MS_IN_HOUR;
  result += hoursInDuration.toString().padStart(2, '0') + ':';

  const minutesInDuration = (leftoverDuration / MS_IN_MINUTE) | 0;
  leftoverDuration = leftoverDuration % MS_IN_MINUTE;
  result += minutesInDuration.toString().padStart(2, '0') + ':';

  const secondsInDuration = (leftoverDuration / MS_IN_SECOND) | 0;
  leftoverDuration = leftoverDuration % MS_IN_SECOND;
  result += secondsInDuration.toString().padStart(2, '0');

  return result;
};

/**
 * @param duration - in milliseconds
 */
export const useCountdown = (from: Date | number | string) => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const durationMs = dayjs(from).diff();

    if (durationMs <= 0) {
      setCountdown(formatCountdown(0));
      return;
    }

    setCountdown(formatCountdown(durationMs));

    const timeout = setInterval(() => {
      setCountdown(formatCountdown(dayjs(from).diff()));
    }, 1000);

    return () => {
      clearInterval(timeout as NodeJS.Timeout);
    };
  }, [from]);

  return countdown;
};

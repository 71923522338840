import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useAsync } from 'react-async-hook';

import { investorApiClient } from 'api';
import { PageContainer } from 'components/UI';
import { UncompletedTaskGroups, UnpaidCommitmentsAlert } from 'components/card-widgets';
import { Currency } from 'types';

import { StatisticHeader } from './StatisticHeader';
import { InvestmentActivity } from './InvestmentActivity';
import { InvestmentSummary } from './InvestmentSummary';
import { ExpectedRepayments } from './ExpectedRepayments';
// import { DealsMatchesFilters } from './DealsMatchesFilters';
import { PortfolioAllocation } from './PortfolioAllocation';

interface IAmount {
  currency: Currency;
  amount: number;
}

const currencyPriority = [Currency.CHF, Currency.EUR];

const resolveHigherPriority = (a: IAmount, b: IAmount) => {
  if (a.amount > b.amount) {
    return a;
  } else if (a.amount < b.amount) {
    return b;
  }

  const cp = currencyPriority;
  if (cp.indexOf(a.currency) <= cp.indexOf(b.currency)) {
    return a;
  } else {
    return b;
  }
};

export const DashboardPage = () => {
  const [defaultCurrency, setDefaultCurrency] = useState<Currency | null>(null);

  const { result: generalStatistics, loading } = useAsync(async () => {
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetGeneralStatistics();
    return data;
  }, []);

  useEffect(() => {
    if (defaultCurrency !== null || !generalStatistics) return;
    const balance = Object.entries(generalStatistics.upcomingPrincipal);
    if (!balance.length) return setDefaultCurrency(currencyPriority[0]);

    let biggestAmount: IAmount = { currency: currencyPriority[0], amount: 0 };

    for (const [currency, amount] of balance) {
      biggestAmount = resolveHigherPriority(biggestAmount, {
        currency: currency as Currency,
        amount: amount,
      });
    }

    setDefaultCurrency(biggestAmount.currency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalStatistics]);

  return (
    <PageContainer>
      <Grid container spacing={4}>
        <UncompletedTaskGroups
          wrapper={(body) => (
            <Grid item xs={12}>
              {body}
            </Grid>
          )}
        />

        <UnpaidCommitmentsAlert
          wrapper={(body) => (
            <Grid item xs={12}>
              {body}
            </Grid>
          )}
        />

        <StatisticHeader generalStatistics={generalStatistics} loading={loading} />

        <Grid item xs={12} md={12}>
          <InvestmentActivity />
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <DealsMatchesFilters />
        </Grid> */}

        {defaultCurrency && (
          <>
            <Grid item xs={12}>
              <PortfolioAllocation defaultCurrency={defaultCurrency} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <InvestmentSummary defaultCurrency={defaultCurrency} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <ExpectedRepayments defaultCurrency={defaultCurrency} />
            </Grid>
          </>
        )}
      </Grid>
    </PageContainer>
  );
};

import { useState, useMemo, useEffect } from 'react';
import { Redirect } from 'react-router';

import { useInvestorAccounts } from 'providers/investor-accounts';
import { AccountCreationPage } from 'components';

export const RedirectToAccounts = () => {
  const investorAccounts = useInvestorAccounts();
  const [init, setInit] = useState(false);

  const selectedAccountId = useMemo(
    () => investorAccounts.selectedAccount?.investor?.id,
    [investorAccounts.selectedAccount],
  );

  useEffect(() => {
    if (!selectedAccountId) {
      investorAccounts.selectAccount();
    }

    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountId, investorAccounts.selectAccount]);

  if (selectedAccountId) {
    const to = `/accounts/${selectedAccountId}/dashboard`;
    return <Redirect to={to} />;
  } else if (init) {
    return <AccountCreationPage />;
  } else {
    return null;
  }
};

import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Theme, Button, Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: '8px',
    backgroundColor: theme.palette.common.darkBlue,
    border: `1px solid ${theme.palette.background.greySurface}`,
    padding: '24px',
    alignItems: 'center',
    display: 'flex',
    gap: '40px',
    flexWrap: 'nowrap',
  },
  icon: {
    width: '24px',
    height: '24px',
    color: theme.palette.primary.main,
  },
  button: {
    borderRadius: '4px',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

export const ContactUs = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} rowSpacing={'24px'}>
      <Grid xs={12} sm={12} md={9} lg={10}>
        <Typography>
          <Box display="flex" justifyContent="flex-start" gap={'16px'}>
            <AiOutlineInfoCircle className={classes.icon} />
            <FormattedMessage id="kyc_details_change" />
          </Box>
        </Typography>
      </Grid>
      <Grid xs={12} sm={12} md={2} lg={2}>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" className={classes.button}>
            <Link
              href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
              underline="none"
              target="_blank"
            >
              <FormattedMessage id="contact_us.title" />
            </Link>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

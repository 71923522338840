import { ComponentType, Key, memo, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { useSelectedInvestorAccount } from 'hooks';

const MIN_RECORD_TILE_WIDTH = 320;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${MIN_RECORD_TILE_WIDTH}px, 1fr))`,
    gridAutoRows: 'auto',
    gap: '16px',

    '&.table-layout': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.primary.light,

    '& > hr': {
      flexGrow: 1,
      borderColor: theme.palette.primary.light,
    },
  },
}));

const calculateSkeletonElements = (currentNumber: number, fitInRow: number) => {
  const leftToFit = fitInRow > currentNumber ? fitInRow - currentNumber : fitInRow % currentNumber;
  if (leftToFit === 0) return fitInRow;
  else if (fitInRow * 2 < currentNumber) return leftToFit;
  else return fitInRow + leftToFit;
};

interface RecordLike {
  id: Key;
}

interface RecordProps<T extends RecordLike> {
  record: T;
  isReadonly?: boolean;
  tableLayout?: boolean;
}

interface Props<T extends RecordLike> {
  components: {
    Record: ComponentType<RecordProps<T>>;
    RecordSkeleton: ComponentType<Omit<RecordProps<T>, 'record'>>;
  };

  data: T[];
  dataLoading?: boolean;
  tableLayout?: boolean;
  basketDeals?: T[];
  basketLoading?: number | boolean;
  onSell?: (arg0: T) => void;
  onCancelSell?: (arg0: T) => void;
}

const TableLayoutComponent = <T extends RecordLike>(props: Props<T>) => {
  const { components, data, basketDeals, basketLoading = false, dataLoading, tableLayout, ...rest } = props;
  const { Record, RecordSkeleton } = components;

  const classes = useStyles();
  const { isViewOnly, isBlockedFromInvesting, isKycFlowUnfinished } = useSelectedInvestorAccount();

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [canFitElementsInRow, setCanFitElementsInRow] = useState(1);

  useEffect(() => {
    if (!container) return;
    const el = container;

    const listener = () => {
      const totalWidth = el.clientWidth;
      setCanFitElementsInRow(Math.floor(totalWidth / MIN_RECORD_TILE_WIDTH));
    };

    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [container]);

  // prettier-ignore
  const basketLoadingNumber = basketLoading !== false
    ? (basketLoading === true ? 1 : basketLoading || 1)
    : false;

  // prettier-ignore
  let recordsLoadingNumber = !tableLayout
    ? calculateSkeletonElements(basketLoadingNumber || 0, canFitElementsInRow)
    : 3;

  const isReadonly = isViewOnly || isBlockedFromInvesting || isKycFlowUnfinished;

  return (
    <Box className={classes.container}>
      <Box ref={setContainer} className={clsx(classes.content, { 'table-layout': tableLayout })}>
        {basketDeals?.map((basketDeal) => (
          <Record
            key={basketDeal.id}
            record={basketDeal}
            isReadonly={isReadonly}
            tableLayout={tableLayout}
            {...rest}
          />
        ))}

        {basketLoadingNumber !== false &&
          Array.from({ length: basketLoadingNumber }, (_, index) => (
            <RecordSkeleton key={index} isReadonly={isReadonly} tableLayout={tableLayout} />
          ))}

        {tableLayout && ((basketDeals?.length ?? 0) > 0 || basketLoadingNumber !== false) && (
          <Box className={classes.dividerContainer}>
            <Typography variant="caption" fontWeight={500}>
              <FormattedMessage id="commitments_above_line" />
            </Typography>
            <Divider />
          </Box>
        )}

        {data.map((record) => {
          return (
            <Record
              key={record.id}
              record={record}
              isReadonly={isReadonly}
              tableLayout={tableLayout}
              {...rest}
            />
          );
        })}

        {dataLoading &&
          Array.from({ length: recordsLoadingNumber }, (_, index) => (
            <RecordSkeleton
              key={index}
              isReadonly={isReadonly}
              tableLayout={props.tableLayout}
            />
          ))}
      </Box>
    </Box>
  );
};

// To preserve generic types
export const TableLayout = memo(TableLayoutComponent) as typeof TableLayoutComponent;

import { useCallback, useEffect, useRef } from 'react';

import { useDebounce } from './useDebounce';

type TRefElement = HTMLElement & HTMLDivElement;

interface ILastAdjust {
  containerHeight: number;
  containerWidth: number;
  textHeight: number;
  textWidth: number;
}

export const useTextResize = (minFontSize: number, maxFontSize: number) => {
  const refText = useRef<TRefElement | null>(null);
  const refContent = useRef<TRefElement | null>(null);
  const lastAdjust = useRef<ILastAdjust | null>(null);

  const resizeHandler = useCallback(() => {
    if (!refText.current || !refContent.current) return;

    let textBodyW = refText.current.getBoundingClientRect().width;
    let textBodyH = refText.current.getBoundingClientRect().height;
    const containerW = refContent.current.getBoundingClientRect().width;
    const containerH = refContent.current.getBoundingClientRect().height;
    const fontSize = parseFloat(window.getComputedStyle(refText.current).fontSize);

    if (
      lastAdjust.current &&
      lastAdjust.current.containerHeight === containerH &&
      lastAdjust.current.containerWidth === containerW &&
      lastAdjust.current.textHeight === textBodyH &&
      lastAdjust.current.textWidth === textBodyW
    ) {
      return;
    }

    if (textBodyW > containerW || textBodyH > containerH) {
      let newFontSize = fontSize;

      do {
        newFontSize -= 1;
        refText.current.style.fontSize = `${newFontSize}px`;
        textBodyW = refText.current.getBoundingClientRect().width;
        textBodyH = refText.current.getBoundingClientRect().height;
      } while ((textBodyW > containerW || textBodyH > containerH) && newFontSize > minFontSize);

      refText.current.style.fontSize = `${Math.max(newFontSize, minFontSize)}px`;
      lastAdjust.current = {
        containerHeight: containerH,
        containerWidth: containerW,
        textHeight: textBodyH,
        textWidth: textBodyW,
      };
    } else if (textBodyW < containerW || textBodyH < containerH) {
      let newFontSize = fontSize - 1;

      do {
        newFontSize += 1;
        refText.current.style.fontSize = `${newFontSize}px`;
        textBodyW = refText.current.getBoundingClientRect().width;
        textBodyH = refText.current.getBoundingClientRect().height;
      } while (textBodyW < containerW && textBodyH < containerH && newFontSize < maxFontSize);

      if (textBodyW > containerW || textBodyH > containerH) newFontSize -= 1;

      refText.current.style.fontSize = `${Math.min(newFontSize, maxFontSize)}px`;
      lastAdjust.current = {
        containerHeight: containerH,
        containerWidth: containerW,
        textHeight: textBodyH,
        textWidth: textBodyW,
      };
    }
  }, [maxFontSize, minFontSize]);

  const debouncedResizeHandler = useDebounce(resizeHandler, 250);

  useEffect(() => {
    resizeHandler();
  }, [resizeHandler]);

  useEffect(() => {
    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, [debouncedResizeHandler]);

  return { refText, refContent, recalculate: resizeHandler };
};

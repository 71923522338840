import { Alert, Box, Button, Divider, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { useInvestorAccounts } from 'providers/investor-accounts';

interface Props {
  wrapper?: (body: JSX.Element) => JSX.Element;
}

export const UnpaidCommitmentsAlert = ({ wrapper = (body) => body }: Props) => {
  const { selectedAccount } = useInvestorAccounts();
  const history = useHistory();

  let { balance } = selectedAccount ?? {};

  if (!balance || balance.every((b) => b.amount >= 0)) {
    return null;
  }

  const body = (
    <Box>
      <Alert
        severity="warning"
        color="info"
        sx={{ marginBottom: '16px' }}
        action={
          <Button
            variant="outlined"
            sx={{ flexShrink: 0 }}
            onClick={() => {
              if (selectedAccount?.investor?.id) {
                history.push(
                  `/accounts/${selectedAccount.investor.id}/account-balance`,
                  { openDeposit: true },
                );
              }
            }}
          >
            <FormattedMessage id="unpaid_investment_alert.complete_checkout_button" />
          </Button>
        }
      >
        <Typography variant="body2">
          <FormattedMessage
            id="unpaid_investment_alert.description"
            values={{ b: (msg: string) => <b>{msg}</b> }}
          />
        </Typography>
      </Alert>

      <Divider />
    </Box>
  );

  return wrapper(body);
};

import { useMemo } from 'react';

import { InvestmentWithDealDto } from 'api/investor-client';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { DealInformation as DI } from 'pages/DealDetailsPage/tabs';
import { getDealDetails } from 'utils';

interface Props {
  investment: InvestmentWithDealDto;
}

export const DealInformation = ({ investment }: Props) => {
  const { selectedAccount } = useInvestorAccounts();

  const dealDetails = useMemo(() => {
    if (!selectedAccount?.investor?.id) return null;
    return getDealDetails(investment.deal, selectedAccount.investor.id);
  }, [investment, selectedAccount]);

  if (!dealDetails) return null;

  return (
    <DI
      deal={investment.deal}
      dealDetails={dealDetails}
      totalAmount={dealDetails.common.investorCommittedAmount}
    />
  );
};

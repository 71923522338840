import { useMemo } from 'react';
import { Grid, Box, Alert, Typography } from '@mui/material';

import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';

import { DealDto } from 'api/investor-client';
import { InlineTooltip } from 'components/UI';
import Card from 'components/card';
import { DataOverviewTable, ITableRow } from 'components';
import { IDealDetails, mixinArray, safeParseNumber, toTwoDecimals } from 'utils';
import { useFormatMoney } from 'hooks';
import { Currency } from 'types';

interface Props {
  deal: DealDto;
  dealDetails: IDealDetails;
  totalAmount: number;
}

const useStyles = makeStyles(() => ({
  financialRatios: {
    '& tr:first-of-type': {
      borderBottom: '2px solid #BDBDBD',
      '& > *': {
        padding: '12px 10px 8px',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#222',
      },
    },
  },
  budget: {
    '& tr:last-of-type': {
      '& > *': {
        fontWeight: 600,
        color: '#222',
      },
    },
  },
  collateralTable: {
    '& .divider:after': {
      left: '0% !important',
      right: '0% !important',
      bottom: '-1px !important',
      height: '2px !important',
    },
  },
  ratingHeader: {
    color: '#222',
    fontWeight: 600,
  },
  ratingBox: {
    width: 80,
    height: 80,
    backgroundColor: '#37A1E4',
    borderRadius: '10px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'xx-large',
    fontWeight: 'bold',
  },
}));

const retrieveRatiosData = (
  data: Record<string, string[]>,
  dataName: string,
  expectedLength: number,
  currency?: Currency
) => {
  const result = (data[dataName]?.slice(0, expectedLength) ?? []).map((e) => safeParseNumber(e, currency));
  const currentLength = data[dataName]?.length ?? 0;
  if (currentLength < expectedLength) {
    result.push(...Array.from({ length: expectedLength - currentLength }, () => '-'));
  }
  return result;
};

export const RiskAssessmentSubTab = ({ deal }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const formatMoney = useFormatMoney({ prefix: `${deal.currency} ` });

  const financialRatiosRows = useMemo((): ITableRow[] => {
    if (deal.risk.productType === 'PRIVATE') return [];
    if (deal.risk.productType === 'REAL_ESTATE') return [];
    if (!(deal.risk.financialRatios && Object.keys(deal.risk.financialRatios).length > 0)) return [];
    const colsLength = deal.risk.financialRatios?.dateRanges.length ?? 1;
    const data = (deal.risk.financialRatios?.data ?? {}) as Record<string, string[]>;

    const columnMappings: Record<string, string> = {
      'Audited': 'financial_ratios.audited',
      'Revenue from sale of goods and services': 'financial_ratios.revenue',
      'Equity / Short-term debt': 'financial_ratios.equity_short-term_debt',
      'EBITDA / Short-term debt': 'financial_ratios.EBITDA_short-term_debt',
      'netDebtEbitda': 'financial_ratios.EBITDA_debt',
      'Assets / Debt': 'financial_ratios.assets_debt',
      'quickRatio': 'financial_ratios.quick_ratio',
      'currentRatio': 'financial_ratios.current_ratio',
      'workCapitalAssets': 'financial_ratios.working_capital_assets',
      'EBIT / Sales': 'financial_ratios.EBIT_sales',
      'netIncomeAssets': 'financial_ratios.profit_assets',
      'netSalesAssets': 'financial_ratios.sales_assets',
      'Sales / Receivables': 'financial_ratios.sales_receivables',
      'Debt Utilisation': 'financial_ratios.debt_utilisation',
      'Liabilities / Trade payables': 'financial_ratios.liabilities_trade_payables',
      'Change in sales': 'financial_ratios.change_in_sales',
      'Change in EBIT': 'financial_ratios.change_in_EBIT',
      'altmanZScorePrivate': 'financial_ratios.altman_z_score',
    };

    const tableRows: ITableRow[] = [];

    tableRows.push({
      cols: [
        intl.formatMessage({ id: 'financial_ratios' }),
        ...(deal.risk.financialRatios?.dateRanges.map((range) => {
          return `${dayjs(range[0], ).utc().format('DD.MM.YYYY')}-${dayjs(range[1]).utc().format(
            'DD.MM.YYYY',
          )}`;
        }) ?? ['n/a - n/a']),
      ],
    });

    for (const [columnName, id] of Object.entries(columnMappings)) {
      const cols = [
        intl.formatMessage({ id }),
        ...retrieveRatiosData(data, columnName, colsLength),
      ];
      tableRows.push({ cols });
    }

    return tableRows;
  }, [deal, intl]);

  const budgetRows = useMemo((): ITableRow[] => {
    if (deal.risk.productType === 'PRIVATE') {
      const dataArray = ((deal.risk.genericData as any)?.borrowerInformation ?? []) as [{ id: string, value: string }];
      const data = Object.values(dataArray).reduce((acc, val) => ({ ...acc, [val.id]: val.value }), {} as any)
      return [
        ...mixinArray(!!data.borrowerTotalMonthlyIncome, [
          {
            cols: [
              intl.formatMessage({ id: 'monthly_income' }),
              formatMoney(data.borrowerTotalMonthlyIncome),
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerTotalMonthlyExpenses, [
          {
            cols: [
              intl.formatMessage({ id: 'monthly_expense' }),
              formatMoney(data.borrowerTotalMonthlyExpenses),
            ],
          },
        ]),
        ...mixinArray(!!data.borrowerTotalMonthlySurplus, [
          {
            cols: [
              intl.formatMessage({ id: 'monthly_budget_surplus' }),
              formatMoney(data.borrowerTotalMonthlySurplus),
            ],
          },
        ]),
      ];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, intl.formatMessage]);

  const affordabilityRows = useMemo((): ITableRow[] => {
    if (deal.risk.productType === 'REAL_ESTATE') {
      const dataArray = ((deal.risk.genericData as any)?.borrowerInformation ?? []) as [{ id: string, value: string }];
      const data = Object.values(dataArray).reduce((acc, val) => ({ ...acc, [val.id]: val.value }), {} as any)
      return [
        ...mixinArray(!!data.annualPropertyEarnings, [
          {
            cols: [
              intl.formatMessage({ id: 'annual_property_earnings' }),
              formatMoney(data.annualPropertyEarnings),
            ],
          },
        ]),
        ...mixinArray(!!data.totalAnnualPropertyCosts, [
          {
            cols: [
              intl.formatMessage({ id: 'total_annual_property_costs' }),
              formatMoney(data.totalAnnualPropertyCosts),
            ],
          },
        ]),
        ...mixinArray(!!data.affordability, [
          {
            cols: [
              intl.formatMessage({ id: 'affordability' }),
              `${data.affordability}%`,
            ],
          },
        ]),
      ];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, intl.formatMessage]);

  const partnerBudgetRows = useMemo((): ITableRow[] => {
    if (deal.risk.productType === 'PRIVATE') {
      const dataArray = ((deal.risk.genericData as any)?.borrowerInformation ?? []) as [{ id: string, value: string }];
      const data = Object.values(dataArray).reduce((acc, val) => ({ ...acc, [val.id]: val.value }), {} as any)
      return [
        ...mixinArray(!!data.partnerTotalMonthlyIncome, [
          {
            cols: [
              intl.formatMessage({ id: 'monthly_income' }),
              formatMoney(data.partnerTotalMonthlyIncome),
            ],
          },
        ]),
        ...mixinArray(!!data.partnerTotalMonthlyExpenses, [
          {
            cols: [
              intl.formatMessage({ id: 'monthly_expense' }),
              formatMoney(data.partnerTotalMonthlyExpenses),
            ],
          },
        ]),
        ...mixinArray(!!data.partnerTotalMonthlySurplus, [
          {
            cols: [
              intl.formatMessage({ id: 'monthly_budget_surplus' }),
              formatMoney(data.partnerTotalMonthlySurplus),
            ],
          },
        ]),
      ];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal, intl.formatMessage]);

  const collateralRows = useMemo((): ITableRow[] => {
    return deal.risk?.collaterals?.map(collateral => [
      {
        cols: [intl.formatMessage({ id: 'collateral.type' }), collateral.type ? intl.formatMessage({ id: `collateral.type.${collateral.type}` }) : 'n/a'],
      },
      {
        cols: [intl.formatMessage({ id: 'collateral.place' }), collateral.place ?? 'n/a'],
      },
      {
        cols: [intl.formatMessage({ id: 'estimate' }), collateral.estimate ? toTwoDecimals(collateral.estimate) : 'n/a'],
      },
      {
        cols: [intl.formatMessage({ id: 'ltv' }), collateral.ltv ? `${collateral.ltv}%` : 'n/a'],
      },
      {
        cols: [intl.formatMessage({ id: 'currency' }), collateral.currency ?? 'n/a'],
        divider: true
      },
      // {
      //   cols: ['Vorgang Schuldbrief (?)', 'n/a'],
      // },
      // {
      //   cols: ['Rang Originator / Betrag (?)', 'n/a / n/a'],
      // },
      // {
      //   cols: [intl.formatMessage({ id: 'total_financing_amount' }), 'n/a'],
      // },
    ]).flat() ?? [];
  }, [deal, intl]);

  return (
    <Grid container mt={0} spacing={4}>
      <Grid item md={7} sm={12}>
        <Grid container direction="column" spacing={4}>
          {!!financialRatiosRows?.length && (
            <Grid item>
              <DataOverviewTable
                title={intl.formatMessage({ id: 'financial_ratios' })}
                rows={financialRatiosRows}
                textAlign="left"
                divided
                className={classes.financialRatios}
              />
            </Grid>
          )}
          {!!budgetRows?.length && (
            <Grid item>
              <DataOverviewTable
                title={intl.formatMessage({ id: 'budget_calculation' })}
                rows={budgetRows}
                divided
                className={classes.budget}
              />
            </Grid>
          )}
          {!!partnerBudgetRows?.length && (
            <Grid item>
              <DataOverviewTable
                title={intl.formatMessage({ id: 'budget_calculation_partner' })}
                rows={partnerBudgetRows}
                divided
                className={classes.budget}
              />
            </Grid>
          )}
          {!!affordabilityRows?.length && (
            <Grid item>
              <DataOverviewTable
                title={intl.formatMessage({ id: 'affordability_calculation' })}
                rows={affordabilityRows}
                divided
                className={classes.budget}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item md={5} sm={12}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Card>
              <Card.Body>
                <Grid container alignItems="center" justifyContent="center" spacing={20}>
                  <Grid item>
                    <Typography variant={'body1'} className={classes.ratingHeader}>
                      CG24 Rating
                      <InlineTooltip
                        tooltip={intl.formatMessage({ id: 'rating_tooltip' })}
                        size={20}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box className={classes.ratingBox}>{deal.risk.rating}</Box>
                  </Grid>
                </Grid>
              </Card.Body>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <Card.Header
                variant="condensed"
                title={intl.formatMessage({ id: 'comment_from_risk_team' })}
                divided
              />
              <Card.Body>
                {deal.risk.riskTeamComment ? (
                  <Box sx={{ px: '10px' }}>{deal.risk.riskTeamComment}</Box>
                ) : (
                  <Alert severity="warning" color="info">
                    <FormattedMessage id="warning.no_info" />
                  </Alert>
                )}
              </Card.Body>
            </Card>
          </Grid>
          <Grid item>
            {!!deal.risk.collaterals?.length && (
              <DataOverviewTable
                title={intl.formatMessage({ id: 'collateral' })}
                rows={collateralRows}
                textAlign="left"
                divided
                className={classes.collateralTable}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

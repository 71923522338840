import { useMemo, useState } from 'react';
import { Box, Grid, Stack, Theme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import { useAsyncCallback } from 'react-async-hook';
import dayjs, { Dayjs } from 'dayjs';

import { GeneralStatisticsResultDto, ProfitLossStatisticsResultDto } from 'api/investor-client';
import { investorApiClient } from 'api';
import Card from 'components/card';
import { Select } from 'components/forms';
import { InlineTooltip } from 'components/UI';
import { DataOverviewTable, ITableRow, SubmitFormOnChange } from 'components';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';
import { makeStyles } from '@mui/styles';

interface TotalAmountProps {
  amounts: Record<string, string | number>;
  format?: boolean;
}

// const toPercents = (val: number) => {
//   return `${(val * 100).toFixed(2)}%`;
// };

const TotalAmount = ({ amounts, format = true }: TotalAmountProps) => {
  const formatMoney = useFormatMoney();

  return (
    <Box sx={{ textAlign: 'right' }}>
      {Object.entries(amounts).map(([currency, amount]) => (
        <Typography key={currency} fontSize={20} whiteSpace="nowrap">
          <Typography component="span" fontSize="inherit" fontWeight={600}>
            {currency}
          </Typography>{' '}
          {format && typeof amount === 'number'
            ? formatMoney(moveDecimalPoint(amount, -2))
            : amount}
        </Typography>
      ))}
    </Box>
  );
};

interface Props {
  generalStatistics?: GeneralStatisticsResultDto;
  loading?: boolean;
}

interface Values {
  range: { from: Dayjs | undefined; to: Dayjs | undefined };
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minHeight: '290px',
  },
}));

export const StatisticHeader = ({ generalStatistics, loading }: Props) => {
  const intl = useIntl();
  const formatMoney = useFormatMoney();
  const [pnl, setPnl] = useState<ProfitLossStatisticsResultDto | null>(null);

  const getStatistics = useAsyncCallback(async (values: Values) => {
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetProfitAndLossStatistics({
      from: values.range.from?.toISOString(),
      to: values.range.to?.toISOString(),
    });

    setPnl(data);
  });

  const rangeOptions = useMemo(() => {
    return [
      {
        value: { from: undefined, to: undefined },
        label: intl.formatMessage({ id: 'since_inception' }),
      },
      {
        value: {
          from: dayjs.utc().subtract(12, 'months').startOf('month'),
          to: dayjs.utc().endOf('month'),
        },
        label: intl.formatMessage({ id: 'last_months' }, { nrOfMonths: 12 }),
      },
      {
        value: {
          from: dayjs.utc().startOf('year'),
          to: dayjs.utc().endOf('year'),
        },
        label: intl.formatMessage({ id: 'this_year' }),
      },
    ];
  }, [intl]);

  const exposureRows = useMemo((): ITableRow[] => {
    const CHFOnTime = generalStatistics?.principalOnTime[Currency.CHF] ?? 0;
    const EUROnTime = generalStatistics?.principalOnTime[Currency.EUR] ?? 0;

    const CHFBelow31 = generalStatistics?.principalBelow31[Currency.CHF] ?? 0;
    const EURBelow31 = generalStatistics?.principalBelow31[Currency.EUR] ?? 0;

    const CHFAbove31 = generalStatistics?.principalAbove31[Currency.CHF] ?? 0;
    const EURAbove31 = generalStatistics?.principalAbove31[Currency.EUR] ?? 0;

    return [
      {
        cols: ['', Currency.CHF, Currency.EUR],
      },
      {
        cols: [
          intl.formatMessage({ id: 'investment.status.OnTime' }),
          formatMoney(moveDecimalPoint(CHFOnTime, -2)),
          formatMoney(moveDecimalPoint(EUROnTime, -2)),
        ],
        variant: 'header-accent',
      },
      {
        cols: [
          intl.formatMessage({ id: 'repayment_status.Arrears_<30' }),
          formatMoney(moveDecimalPoint(CHFBelow31, -2)),
          formatMoney(moveDecimalPoint(EURBelow31, -2)),
        ],
        divider: true,
        variant: 'header-accent',
      },
      {
        cols: [
          intl.formatMessage({ id: 'repayment_status.Arrears_>31' }),
          formatMoney(moveDecimalPoint(CHFAbove31, -2)),
          formatMoney(moveDecimalPoint(EURAbove31, -2)),
        ],
        variant: 'header-accent',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalStatistics, intl.formatMessage]);

  const profitLossRows = useMemo((): ITableRow[] => {
    const CHFProfit = pnl?.profits[Currency.CHF] ?? 0;
    const EURProfit = pnl?.profits[Currency.EUR] ?? 0;

    const CHFLoss = pnl?.losses[Currency.CHF] ?? 0;
    const EURLoss = pnl?.losses[Currency.EUR] ?? 0;

    // const CHFIrr = pnl?.irrs[Currency.CHF] ?? 0;
    // const EURIrr = pnl?.irrs[Currency.EUR] ?? 0;

    return [
      {
        cols: ['', Currency.CHF, Currency.EUR],
      },
      {
        cols: [
          intl.formatMessage({ id: 'profit' }),
          formatMoney(moveDecimalPoint(CHFProfit, -2)),
          formatMoney(moveDecimalPoint(EURProfit, -2)),
        ],
        variant: 'header-accent',
      },
      {
        cols: [
          intl.formatMessage({ id: 'loss' }),
          formatMoney(moveDecimalPoint(CHFLoss, -2)),
          formatMoney(moveDecimalPoint(EURLoss, -2)),
        ],
        // divider: true,
        variant: 'header-accent',
      },
      // {
      //   cols: [
      //     intl.formatMessage({ id: 'irr' }),
      //     toPercents(CHFIrr),
      //     toPercents(EURIrr),
      //   ],
      //   variant: 'header-accent',
      // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pnl, intl.formatMessage]);

  const classes = useStyles();

  return (
    <Grid item md={12} container spacing={4}>
      <Grid item xs={12} sm={6} md={4}>
        <Stack spacing={4}>
          <Card className={classes.card}>
            <Card.Header
              variant="condensed"
              title={
                <>
                  {intl.formatMessage({ id: 'expected_return' })}{' '}
                  <InlineTooltip
                    size={18}
                    tooltip={intl.formatMessage({
                      id: 'expected_return.dashboard_tooltip',
                    })}
                  />
                </>
              }
            />

            <Card.Body loadingContent={loading}>
              <TotalAmount
                amounts={{
                  CHF: generalStatistics?.upcomingInterest[Currency.CHF] ?? 0,
                  EUR: generalStatistics?.upcomingInterest[Currency.EUR] ?? 0,
                }}
              />
            </Card.Body>
          </Card>

          {/* <Card>
            <Card.Header
              variant="condensed"
              title={
                <>
                  {intl.formatMessage({ id: 'irr.expected_net' })}{' '}
                  <InfoTooltip
                    size={18}
                    tooltip={intl.formatMessage({
                      id: 'irr.description_tooltip',
                    })}
                  />
                </>
              }
            />

            <Card.Body loadingContent={loading}>
              <TotalAmount
                amounts={{
                  CHF: toPercents(generalStatistics?.irrs[Currency.CHF] ?? 0),
                  EUR: toPercents(generalStatistics?.irrs[Currency.EUR] ?? 0),
                }}
                format={false}
              />
            </Card.Body>
          </Card> */}
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
        <Card sx={{ flex: 1 }}>
          <Card.Header
            variant="condensed"
            title={
              <>
                {intl.formatMessage({ id: 'current_exposure' })}{' '}
                <InlineTooltip
                  size={18}
                  tooltip={intl.formatMessage({
                    id: 'current_exposure.dashboard_tooltip',
                  })}
                />
              </>
            }
          />

          <Card.Body loadingContent={loading}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TotalAmount
                  amounts={{
                    CHF: generalStatistics?.upcomingPrincipal[Currency.CHF] ?? 0,
                    EUR: generalStatistics?.upcomingPrincipal[Currency.EUR] ?? 0,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <DataOverviewTable tableOnly rows={exposureRows} />
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </Grid>

      <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
        <Card sx={{ flex: 1 }}>
          <Card.Header
            variant="condensed"
            title={
              <>
                {intl.formatMessage({ id: 'net_profit_n_loss' })}{' '}
                <InlineTooltip
                  size={18}
                  tooltip={intl.formatMessage({
                    id: 'net_profit_n_loss.dashboard_tooltip',
                  })}
                />
              </>
            }
          />
          <Card.Body loadingContent={getStatistics.loading}>
            <Grid container spacing={4} flexWrap="wrap">
              <Grid item xs display="flex" alignItems="center">
                <Formik<Values>
                  initialValues={{
                    range: rangeOptions[0].value,
                  }}
                  onSubmit={(v) => getStatistics.execute(v)}
                >
                  <Form>
                    <Field
                      id="range"
                      name="range"
                      sx={{ width: '150px' }}
                      component={Select}
                      options={rangeOptions}
                      variant="filter"
                      disableSelectedClass
                    />

                    <SubmitFormOnChange initialSubmit />
                  </Form>
                </Formik>
              </Grid>

              <Grid item xs>
                <TotalAmount
                  amounts={{
                    CHF: pnl?.totals[Currency.CHF] ?? 0,
                    EUR: pnl?.totals[Currency.EUR] ?? 0,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <DataOverviewTable tableOnly rows={profitLossRows} />
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </Grid>
    </Grid>
  );
};

import { Box, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import {
  EPrimaryDealSortOption,
  primaryDealSortVariantsMap,
} from 'components/filters/primary-deal';
import { InlineTooltip } from 'components/UI';
import { SortIcon } from 'components';
import { SortOrder } from 'types';

import { useTableRowStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },

  cell: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },

  firstCell: {},

  lastCell: {
    minHeight: 'auto',
    paddingRight: '8px',
    justifyContent: 'flex-end',
    color: alpha(theme.palette.text.secondary, 0.36),
  },

  sortIcon: {
    fontSize: '20px',
  },

  tooltip: {
    color: alpha(theme.palette.text.secondary, 0.36),
  },
}));

interface Props {
  sort: { sortBy: string; sortOrder: SortOrder } | undefined;
  onSortChange: (sortState: { sortBy: string; sortOrder: SortOrder } | undefined) => void;
}

export const PrimaryDealHeader = ({ sort, onSortChange }: Props) => {
  const tableRowClasses = useTableRowStyles();
  const classes = useStyles();
  const intl = useIntl();

  const getSortState = (sortOption: EPrimaryDealSortOption) => {
    if (!sort) return undefined;
    const sortValue = primaryDealSortVariantsMap[sortOption][sort.sortOrder];
    if (sortValue.sortBy === sort.sortBy) return sort.sortOrder;
    return undefined;
  };

  const handleSortClick = (sortOption: EPrimaryDealSortOption) => {
    return () => {
      if (sort) {
        const sortValue = primaryDealSortVariantsMap[sortOption][sort.sortOrder];

        if (sortValue.sortBy === sort.sortBy) {
          switch (sort.sortOrder) {
            case SortOrder.ASC:
              return onSortChange(primaryDealSortVariantsMap[sortOption][SortOrder.DESC]);

            case SortOrder.DESC:
              return onSortChange(undefined);
          }
        }
      }

      onSortChange(primaryDealSortVariantsMap[sortOption][SortOrder.ASC]);
    };
  };

  return (
    <Box className={classes.card}>
      <Box display="flex" flexGrow={1}>
        <Box
          className={clsx(
            tableRowClasses.cell,
            tableRowClasses.currencyCell,
            classes.cell,
            classes.firstCell,
          )}
        >
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="currency.short" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.Currency)}
            onClick={handleSortClick(EPrimaryDealSortOption.Currency)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.riskCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="rating" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.Rating)}
            onClick={handleSortClick(EPrimaryDealSortOption.Rating)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.productTypeCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="type" />
          </Typography>

          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.ProductType)}
            onClick={handleSortClick(EPrimaryDealSortOption.ProductType)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.durationCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="duration" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.Duration)}
            onClick={handleSortClick(EPrimaryDealSortOption.Duration)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.irrCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="irr" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.IRR)}
            onClick={handleSortClick(EPrimaryDealSortOption.IRR)}
          />
          <InlineTooltip
            tooltip={intl.formatMessage({ id: 'irr.description_tooltip' })}
            className={classes.tooltip}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.dealSizeCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="loan_size.funded_full_amount" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.Amount)}
            onClick={handleSortClick(EPrimaryDealSortOption.Amount)}
          />
        </Box>
      </Box>

      <Box
        className={clsx(
          tableRowClasses.cell,
          tableRowClasses.inputCell,
          classes.cell,
          classes.lastCell,
        )}
      >
        <Typography variant="caption" noWrap>
          <FormattedMessage id="your_investment_input" />
        </Typography>
      </Box>
    </Box>
  );
};

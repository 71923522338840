import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { DealDto } from 'api/investor-client';
import { ProductTypeChip, DealStatusChip, InlineTooltip, RiskChip } from 'components/UI';
import { IDealDetails } from 'utils';
import { DealRating, DealType } from 'types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '40px',
    flexWrap: 'wrap',
    marginBottom: '16px',

    [theme.breakpoints.up('sm')]: {
      gap: '16px',
      marginBottom: 0,
    },

    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },

    [theme.breakpoints.up('lg')]: {
      marginBottom: '24px',
    },
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    overflow: 'hidden',
  },

  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px 24px',
    flexWrap: 'wrap',
  },

  subtitleTextGroup: {
    display: 'flex',
    gap: '8px',
  },

  dealInfoContainer: {
    maxWidth: '100%',
    height: 'fit-content',
    display: 'flex',
    flexShrink: 0,
    flexGrow: 1,
    flexWrap: 'wrap',
    gap: '24px 48px',
    padding: '16px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],

    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      justifyContent: 'space-around',
    },
  },

  dealInfoGroup: {
    display: 'flex',
    gap: '16px',
    overflow: 'hidden',
  },

  dealInfoGroupText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '4px',
    overflow: 'hidden',
  },

  tooltipIcon: {
    marginLeft: '8px',
  },
}));

interface Props {
  deal: DealDto;
  dealDetails: IDealDetails;
}

export const DealDetailsHeader = ({ deal, dealDetails }: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.titleContainer}>
        <Typography variant="h5" fontWeight={600} noWrap>
          <FormattedMessage id="deal" /> {deal.id}
        </Typography>

        <Box className={classes.subtitleContainer}>
          <DealStatusChip status={deal.status} />

          <Box className={classes.subtitleTextGroup}>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="investment_date" />:
            </Typography>
            <Typography variant="subtitle3">
              {dealDetails.common.investorCommittedDate
                ? dayjs(dealDetails.common.investorCommittedDate).format('DD/MM/YYYY')
                : '~'}
            </Typography>
          </Box>

          <Box className={classes.subtitleTextGroup}>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="duration" />:
            </Typography>
            <Typography variant="subtitle3">
              {deal.duration} <FormattedMessage id={deal.duration === 1 ? 'month' : 'months'} />
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className={classes.dealInfoContainer}>
        <Box className={classes.dealInfoGroup}>
          <RiskChip rating={deal.risk.rating as DealRating} size="large" />

          <Box className={classes.dealInfoGroupText}>
            <Typography variant="caption" color="text.secondary">
              <FormattedMessage id="rating" />
              <InlineTooltip
                tooltip={intl.formatMessage({ id: 'rating_tooltip' })}
                size={14}
                className={classes.tooltipIcon}
              />
            </Typography>

            <Typography variant="body2" noWrap>
              <FormattedMessage id="risk_level" />
            </Typography>
          </Box>
        </Box>

        <Box className={classes.dealInfoGroup}>
          <ProductTypeChip productType={deal.risk.productType as DealType} size="large" />

          <Box className={classes.dealInfoGroupText}>
            <Typography variant="caption" color="text.secondary">
              <FormattedMessage id="borrower.type" />
              <InlineTooltip
                tooltip={intl.formatMessage({ id: 'borrower.type.tooltip' })}
                size={14}
                className={classes.tooltipIcon}
              />
            </Typography>

            <Typography variant="body2" noWrap>
              <FormattedMessage id={`borrower.${deal.risk.productType}`} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

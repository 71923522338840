import 'chart.js/auto';

import { ChangeEvent, useMemo, useState } from 'react';
import type { ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';

import { RepaymentStatisticsResultDto } from 'api/investor-client';
import { investorApiClient } from 'api';
import Card from 'components/card';
import { Select } from 'components/forms';
import { SubmitFormOnChange } from 'components';
import { Currency } from 'types';
import { formatMoney, moveDecimalPoint } from 'utils';

import { options } from './chart-options';
import { getChartDataFromRowStatistics } from './process-row-stat';
import { makeStyles } from '@mui/styles';

dayjs.extend(utc);

interface Props {
  defaultCurrency?: Currency;
}

interface Values {
  range: { from: Dayjs; to: Dayjs };
  currency: Currency;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minHeight: '510px',
  },
}));

export const ExpectedRepayments = ({ defaultCurrency }: Props) => {
  const intl = useIntl();
  const [dataset, setDataset] = useState<RepaymentStatisticsResultDto[]>([]);
  const [total, setTotal] = useState<number>(0);

  const rangeOptions = useMemo(() => {
    return [
      {
        value: {
          from: dayjs.utc().startOf('months'),
          to: dayjs.utc().add(6, 'months').endOf('month'),
        },
        label: intl.formatMessage({ id: 'next_months' }, { nrOfMonths: 6 }),
      },
      {
        value: {
          from: dayjs.utc().startOf('months'),
          to: dayjs.utc().add(12, 'months').endOf('month'),
        },
        label: intl.formatMessage({ id: 'next_months' }, { nrOfMonths: 12 }),
      },
    ];
  }, [intl]);

  const currencyOptions = useMemo(() => {
    return Object.values(Currency).map((e) => ({
      value: e,
      label: e,
    }));
  }, []);

  const [currency, setCurrency] = useState(defaultCurrency ?? currencyOptions[0].value);

  const data = useMemo((): ChartData<'bar'> => {
    return getChartDataFromRowStatistics(dataset, intl);
  }, [dataset, intl]);

  const getRepaymentStatistics = useAsyncCallback(async (values: Values) => {
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetRepaymentStatistics({
      from: values.range.from.toISOString(),
      to: values.range.to.toISOString(),
      currency: (values.currency),
    });
    let total = 0;

    data.forEach((d) => {
      d.repayments.forEach((r) => {
        total += r.repayment.principal + r.repayment.interest;
      });
    });

    setDataset(data);
    setTotal(total);
  });

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Card.Header variant="condensed" title={intl.formatMessage({ id: 'expected_repayments' })} />
      <Card.Body loadingContent={getRepaymentStatistics.loading}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Formik<Values>
            initialValues={{
              range: rangeOptions[0].value,
              currency: currency,
            }}
            onSubmit={(v) => {
              getRepaymentStatistics.execute(v);
            }}
          >
            <Form>
              <Stack direction="row" spacing={4}>
                <Field
                  id="range"
                  name="range"
                  sx={{ width: '150px' }}
                  component={Select}
                  options={rangeOptions}
                  variant="filter"
                  disableSelectedClass
                />
                <Field
                  id="currency"
                  name="currency"
                  sx={{ width: '90px' }}
                  component={Select}
                  options={currencyOptions}
                  variant="filter"
                  disableSelectedClass
                  onChange={(e: ChangeEvent<{ value: Currency }>) => {
                    setCurrency(e.target.value);
                  }}
                />
              </Stack>
              <SubmitFormOnChange initialSubmit />
            </Form>
          </Formik>

          <Box>
            <Typography variant="h6" component="span" fontWeight={600}>
              {currency}{' '}
            </Typography>
            <Typography variant="h6" component="span">
              {formatMoney(moveDecimalPoint(total, -2))}
            </Typography>
          </Box>
        </Box>
        <Bar options={options} data={data} plugins={[ChartDataLabels]} />
      </Card.Body>
    </Card>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAsyncCallback } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { BalanceDto, IDealDto, ShareCommitmentDto } from 'api/investor-client';
import { VirtualAccountDTO } from 'api/payments-client';
import { documentApiClient, investorApiClient, paymentsApiClient } from 'api';
import { EMarketTypeBasket, IBaseBasketDealIdentifier, useBasketProvider } from 'providers/basket';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { useSelectedInvestorAccount } from 'hooks';
import { BulkInvestmentsResponseWithPayment, Currency, EBankAccountStatus } from 'types';

import type { InvestmentConfirmationModalOptions } from '../InvestmentConfirmationModal';
import { PaymentsDetails } from '../PaymentsDetails';
import { InvestmentsReview } from '../InvestmentsReview';
import { MarketType } from 'types/secondary-market';
import {
  getAgreementFullOfferId,
  getFullOfferId,
} from 'providers/basket/helpers';
import { FileMetaInvestor, SearchResultInvestor } from 'api/document-client';
import { getDealDetails } from 'utils';

interface Props {
  deals: IDealDto[];
  openSummaryModal: (modalProps: InvestmentConfirmationModalOptions) => void;
  paymentDetailsContainer?: HTMLElement | null;
  agreements: Record<string, {id: string, name: string}>
  handleAgreementsAdd: (data: {agreement: {id: string, name: string}, providerId: string, dealId: string, totalAmount: number}[]) => void
}

export const PrimaryMarketBasketContent = (props: Props) => {
  const { deals, openSummaryModal, paymentDetailsContainer, agreements, handleAgreementsAdd } = props;

  const intl = useIntl();

  const basket = useBasketProvider();
  const { updateAccountBalance } = useInvestorAccounts();
  // prettier-ignore
  const { selectedAccount, isViewOnly, isBlockedFromInvesting, isKycFlowUnfinished } = useSelectedInvestorAccount();

  const [virtualAccount, setVirtualAccount] = useState<VirtualAccountDTO | undefined>(undefined);
  const [amountError, setAmountError] = useState(false);

  const [oldBalance, setOldBalance] = useState<BalanceDto[] | null>(null);
  useEffect(() => {
    setOldBalance(selectedAccount?.VABalance ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!selectedAccount?.VABalance]);
  const dealDetails = useMemo(() => !selectedAccount?.investor ? {} : Object.fromEntries(
    deals.map(deal =>
      [getFullOfferId(deal.providerId, deal.id),
        getDealDetails(deal, selectedAccount.investor!.id)])), [selectedAccount?.investor?.id, deals]);

  const missingAgreements = useMemo(() => {
    return basket.primary.deals.map(deal => {
      if(!deal.providerId) return {providerId: '', id: '', totalAmount: 0};
      let baseFullId = getFullOfferId(deal.providerId, deal.id);
      let oldCommitmentAmount = dealDetails[baseFullId]?.common?.investorCommittedAmount || 0;
      let totalCommitmentAmount = deal.amount + oldCommitmentAmount;
      let agreementFullDealId = getAgreementFullOfferId(
        deal.providerId,
        deal.id,
        totalCommitmentAmount
      );
      return agreements[agreementFullDealId] ? {providerId: '', id: '', totalAmount: 0} : {
        providerId: deal.providerId,
        id: deal.id,
        totalAmount: totalCommitmentAmount
      }; //keep only missing ids in the array, then filter for truthy values
    }).filter(el => el.id);
  }, [basket, agreements]);

  const uploadMissingAgreements = useAsyncCallback(async () => {
    const agreements: {
      agreement: { id: string; name: string };
      providerId: string;
      dealId: string;
      totalAmount: number;
    }[] = [];

    const concurrencyLimit = 3; // Set your desired concurrency limit

    const processAgreement = async (missingAgreement: {providerId: string, id: string, totalAmount: number}) => {
      let agreement: FileMetaInvestor;
      // Check if the agreement already exists
      const existingAgreementsData = (await documentApiClient.api.investorApiControllerList({
        searchCriteria: {
          documentType: "Investor Agreements basket",
          totalInvestmentAmount: missingAgreement.totalAmount,
          fileNamePartial: missingAgreement.id,
        },
        orderCriteria: [{ name: "created", order: "DESC" }],
      })).data as unknown as SearchResultInvestor;

      if (existingAgreementsData.files.length) {
        agreement = existingAgreementsData.files[0];
      } else {
        const generatedAgreementData = await investorApiClient.investors.investorApiControllerGenerateBasketInvestorAgreement(
          missingAgreement.providerId,
          missingAgreement.id,
          { amount: missingAgreement.totalAmount },
        );

        agreement = generatedAgreementData.data as FileMetaInvestor;
      }

      agreements.push({
        providerId: missingAgreement.providerId,
        dealId: missingAgreement.id,
        totalAmount: missingAgreement.totalAmount,
        agreement,
      });
    };

    // Use a concurrency limiter to control the number of concurrent requests
    const limitConcurrency = async (arr: {providerId: string, id: string, totalAmount: number}[], limit: number) => {
      const results: any[] = [];

      const executeNext = async () => {
        const item = arr.shift();
        if (item) {
          await processAgreement(item);
          results.push(item);
          await executeNext();
        }
      };

      const promises: Array<() => Promise<void>> = [];
      for (let i = 0; i < limit && i < arr.length; i++) {
        promises.push(executeNext);
      }

      await Promise.all(promises.map(promise => promise()));
      return results;
    };

    // Use the concurrency limiter to process agreements concurrently
    await limitConcurrency(missingAgreements.slice(), concurrencyLimit);

    // All agreements have been processed at this point
    handleAgreementsAdd(agreements);
  });


  const totalAmount = useMemo(
    () => basket.primary.deals.reduce((sum, deal) => sum + deal.amount, 0),
    [basket],
  );

  const handleDealRemove = useCallback(
    (deal: IBaseBasketDealIdentifier) => basket.primary.removeDeal(deal),
    [basket.primary],
  );

  const handleDealsClear = useCallback(() => {
    basket.primary.clear();
  }, [basket]);

  const commitInvestment = useAsyncCallback(async () => {
    return investorApiClient.investors.investorApiControllerCommitBulkInvestment({
      investments: basket.primary.deals
        .filter((d) => d.amount)
        .map((d) => ({
          dealId: d.id,
          providerId: d.providerId!,
          amount: d.amount,
          currency: d.currency as 'CHF' | 'EUR',
        })),
    });
  });

  const fetchVirtualAccount = useAsyncCallback(async () => {
    const { data } = await paymentsApiClient.api.investorApiControllerGetCurrentVirtualAccount();
    setVirtualAccount(data);
    return data;
  });

  const payInvestment = useAsyncCallback(
    async (providerId: string, dealId: string, investmentId: string) => {
      try {
        const { data } = await investorApiClient.investors.investorApiControllerPayInvestment(
          providerId,
          dealId,
          investmentId,
        );

        return !!data;
      } catch (error) {
        return null;
      }
    },
  );

  const submitOrder = useAsyncCallback(async (currency: Currency, amount: number) => {
    if (!amount) return;
    await paymentsApiClient.api.investorApiControllerCreatePayin({ currency, amount });
  });

  const submit = useAsyncCallback(async () => {
    let va = virtualAccount;

    if (!va) {
      try {
        va = await fetchVirtualAccount.execute();
      } catch {
        va = undefined;
      }
    }

    const { data } = await commitInvestment.execute();
    const commitRes = data as BulkInvestmentsResponseWithPayment;
    const payResponses = new Map<string, boolean | null>();
    let allPaid = true;

    for (const res of commitRes.result) {
      const isPaid = await payInvestment.execute(res.deal.providerId, res.deal.id, res.response.id);

      if (!isPaid) {
        try {
          if (
            va?.bankAccounts?.find((ba) => {
              return (
                ba.currency === res.deal.currency &&
                (ba?.dd?.status === EBankAccountStatus.Active ||
                  ba?.lsv?.status === EBankAccountStatus.Active)
              );
            })
          ) {
            await submitOrder.execute(Currency[res.deal.currency], res.request.amount);
          }
        } catch {
          toast.error(intl.formatMessage({ id: 'error.investment.dd' }, { dealId: res.deal.id }));
        }
      }

      if (allPaid) allPaid = !!isPaid;
      payResponses.set(res.response.id, isPaid);
    }

    commitRes.result.forEach((res) => {
      const payResponse = payResponses.get(res.response.id);

      if (payResponse !== undefined) {
        (res.response as ShareCommitmentDto).confirmed = payResponse ?? false;
        res.paymentResult = payResponse !== null;
      }
    });

    openSummaryModal({
      oldBalance: oldBalance ?? undefined,
      commitmentResult: commitRes,
      virtualAccount: virtualAccount,
    });

    basket.primary.clear();
    updateAccountBalance();
  });

  return (
    <>
      <InvestmentsReview
        type={MarketType.primary}
        data={deals}
        agreements={agreements}
        onDealRemove={handleDealRemove}
        onDealsClear={handleDealsClear}
        onError={setAmountError}
      />

      {paymentDetailsContainer &&
        createPortal(
          <PaymentsDetails
            type={MarketType.primary}
            agreementsReady={missingAgreements.length === 0}
            uploadMissingAgreements={uploadMissingAgreements}
            submitLoading={submit.loading}
            onSubmit={submit.execute}
            submitDisabled={
              isViewOnly ||
              isBlockedFromInvesting ||
              isKycFlowUnfinished ||
              amountError ||
              submit.loading ||
              totalAmount === 0
            }
          />,
          paymentDetailsContainer,
        )}
    </>
  );
};

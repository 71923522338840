import { ComponentProps } from 'react';

import { Currency } from 'types';

interface Props extends ComponentProps<'img'> {
  currency: Currency | string;
  size?: number | string;
}

export const CurrencyIcon = ({ currency, size = 40, ...rest }: Props) => {
  const getPath = () => {
    let name;

    switch (currency) {
      case Currency.CHF: {
        name = 'swiss-flag';
        break;
      }

      case Currency.EUR: {
        name = 'euro-flag';
        break;
      }
    }

    return name ? `/img/currency-icons/${name}.svg` : null;
  };

  const iconPath = getPath();
  if (!iconPath) return null;

  return <img src={iconPath} alt="flag" height={size} width={size} {...rest} />;
};

import { IconButton, InputAdornment } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

type InType = 'text' | 'password';

const normalizeType = (type?: string): InType => {
  return ['text', 'password'].includes(type!) ? (type as InType) : 'text';
};

export const usePasswordType = (type?: string) => {
  const [exType, setExType] = useState(type ?? 'text');
  const [inType, setInType] = useState<InType>(normalizeType(type));
  const isPassword = useMemo(() => exType === 'password', [exType]);

  useEffect(() => {
    setExType(type ?? 'text');
  }, [type]);

  const handleClickShowPassword = useCallback(() => {
    setInType((t) => (t === 'password' ? 'text' : 'password'));
  }, []);

  const result = useMemo(() => {
    const t = isPassword ? inType : exType;

    return {
      type: t,
      inputProps: isPassword
        ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {inType === 'password' ? (
                    <MdVisibility />
                  ) : (
                    <MdVisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }
        : {},
    };
  }, [isPassword, exType, inType, handleClickShowPassword]);

  return result;
};

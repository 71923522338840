import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import {
  DataOverviewList,
  DataOverviewListRow,
} from 'components/deals/DataOverviewList';
import { CurrencyIcon } from 'components/UI';
import { Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    padding: '4px 8px',
    marginBottom: '16px',
    backgroundColor: theme.palette.background.barkSurface004,
    borderRadius: '4px',
  },

  dataOverviewList: {
    '& .data-overview-list__row': {
      padding: '0 8px',

      '& .data-overview-list__cell.cell-data': {
        '& .data-overview-list__text_summary': {
          fontSize: '1rem',
        },
      },
    },
  },
}));

interface Props {
  currency: Currency;
  rows: DataOverviewListRow[];
}

export const PaymentSummary = ({ currency, rows }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.title}>
        <CurrencyIcon currency={currency} size={20} />
        <Typography variant="body1">
          <FormattedMessage id={`payment_details.${currency}`} />
        </Typography>
      </Box>

      <DataOverviewList rows={rows} className={classes.dataOverviewList} />
    </Box>
  );
};

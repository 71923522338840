import React, { ComponentProps, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { useUserDetails } from 'providers/user-details';
import { translationMessages } from 'translations';
import { DefaultLanguage } from 'types';

type Props = {
  children: ReactNode;
  onError?: ComponentProps<typeof IntlProvider>['onError'];
};

export const I18nWrapper = ({ onError, children }: Props) => {
  const userContext = useUserDetails();

  return (
    <IntlProvider
      locale={userContext.currentLanguage}
      defaultLocale={DefaultLanguage}
      messages={translationMessages[userContext.currentLanguage]}
      onError={onError}
    >
      {children}
    </IntlProvider>
  );
};

export default I18nWrapper;

export interface FilterFragment {
  id: string;
  value: string | (string | null)[] | null;
}

export interface SortFragment {
  id: string;
  desc?: boolean;
}

export interface FilterData {
  filters?: FilterFragment[];
  sort?: SortFragment[];
  pageSize?: number;
  pageIndex?: number;
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}
import React from 'react';
import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useFormattedMoney } from 'hooks';
import { grey } from '@mui/material/colors';


interface Props {
  sx?: SxProps<Theme>;
  className?: string;
  intlName: string;
  amount: number;
  grey?: boolean;
}

const useStyles = makeStyles(() => ({
  amount: {
    color: '#000000',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  name: {
    fontSize: '16px',
    color: '#000000',
  },
  container: (props: { grey: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    flexWrap: 'unset',
    minHeight: '40px',
    border: `1px solid ${grey[400]}`,
    background: props.grey ? '#EDEDED' : 'inherit',
  }),
}));

export const Total = (props: Props) => {
  const { amount, intlName, grey } = props;
  const classes = useStyles({ grey: !!grey });
  const formattedAmount = useFormattedMoney(amount / 100, { optionalDecimals: true });
  return (
    <Paper
      elevation={0}
      className={clsx(classes.container, props.className)}
      sx={props.sx}
    >
      <div className={classes.amount}>
        {formattedAmount}
      </div>
      <div className={classes.name}>
        <FormattedMessage id={intlName} />
      </div>
    </Paper>
  );
};

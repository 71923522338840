import { useEffect, useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import { IDealDto } from 'api/investor-client';
import { InlineTooltip } from 'components/UI';
import { useFormatMoney, useSelectedInvestorAccount, useTextResize } from 'hooks';
import { moveDecimalPoint } from 'utils';

import { RepaymentsChart } from '../RepaymentsChart';
import { useCardStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  tooltipIcon: {
    marginLeft: '8px',
  },

  amountWrapper: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
  },

  amount: {
    display: 'inline',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },

  chartContainer: {
    position: 'relative',
    padding: '0 24px',
  },
}));

interface Props {
  deal: IDealDto;
}

export const RepaymentPrincipalCard = ({ deal }: Props) => {
  const cardClasses = useCardStyles();
  const classes = useStyles();
  const intl = useIntl();

  const formatMoney = useFormatMoney({ prefix: `${deal.currency} ` });

  const { selectedAccount } = useSelectedInvestorAccount();
  const investorId = selectedAccount?.investor?.id;

  const { refText, refContent, recalculate } = useTextResize(16, 28);
  useEffect(() => recalculate(), [recalculate]);

  const totalAmount = useMemo(() => {
    const investorShares = deal.shares.filter((s) => s.investorId === investorId);
    return investorShares.reduce((amount, share) => amount + share.amount, 0);
  }, [deal, investorId]);

  return (
    <Card variant="outlined" className={cardClasses.card}>
      <CardContent className={cardClasses.cardContent}>
        <Typography variant="body2" color="text.secondary" marginBottom="16px">
          <FormattedMessage id="deal_details.investment_principal" />
          <InlineTooltip
            tooltip={intl.formatMessage({ id: 'deal_details.investment_principal.tooltip' })}
            size={16}
            className={classes.tooltipIcon}
          />
        </Typography>

        <Box ref={refContent} className={classes.amountWrapper}>
          <Typography ref={refText} variant="h5" className={classes.amount}>
            {formatMoney(moveDecimalPoint(totalAmount, -2))}
          </Typography>
        </Box>

        <Box className={classes.chartContainer}>
          <RepaymentsChart deal={deal} type="principal" />
        </Box>
      </CardContent>
    </Card>
  );
};

import { useCallback, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  alpha,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiArrowDownSFill, RiCloseLine } from 'react-icons/ri';
import { Field, Form, FormikContext, useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { InlineBadge } from 'components/UI';
import { AmountTextField, InlineSelect, Radio } from 'components/forms';
import { withDialog } from 'wrappers';
import { ShareSaleStatus, SortOrder } from 'types';

import { SortButton } from '../SortButton';
import { useCommonFiltersStyles } from '../useCommonFiltersStyles';
import { useProductTypeOptions, useRatingOptions, useCurrencyOptions } from '../options';
import { CommonFilterModalProps } from '../types';
import { secondaryInvestmentFiltersHelper } from './helpers';
import { secondaryInvestmentSortVariantsMap } from './constants';
import { SecondaryInvestmentFilterValues, ESecondaryInvestmentSortOption } from './types';

const useStyles = makeStyles((theme) => ({
  closeButtonContainer: {
    position: 'absolute',
    top: '12px',
    right: '12px',
  },

  body: {
    padding: '24px 24px 0px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '4px',
  },

  sortContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  sortGroup: {
    display: 'flex',
    gap: '16px',
    padding: '0 16px',
    flexWrap: 'wrap',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    borderTop: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
    boxShadow: `0px 2px 6px 0px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

type Props = CommonFilterModalProps<SecondaryInvestmentFilterValues>;

export const SecondaryInvestmentFilterModal = withDialog((props: Props) => {
  const { initialValues, withSort, onClear, onApply, onClose } = props;

  const commonFiltersStyles = useCommonFiltersStyles();
  const classes = useStyles();
  const intl = useIntl();

  const productTypeOptions = useProductTypeOptions(intl);
  const ratingOptions = useRatingOptions();
  const currencyOptions = useCurrencyOptions();

  const statusFilterOptions = useMemo(() =>
    [ShareSaleStatus.Open, ShareSaleStatus.Sold].map((o) => ({
      label: intl.formatMessage({ id: `portfolio.secondary.tabs.${o}` }),
      value: o
    })), [intl]);

  const handleSubmit = (values: SecondaryInvestmentFilterValues) => {
    onApply?.(values);
    onClose?.();
  };

  const formik = useFormik<SecondaryInvestmentFilterValues>({
    initialValues: initialValues ?? secondaryInvestmentFiltersHelper.getEmptyValue(),
    onSubmit: handleSubmit,
  });

  const getSortLabel = useCallback(
    (type: ESecondaryInvestmentSortOption) => {
      switch (type) {
        case ESecondaryInvestmentSortOption.Currency:
          return intl.formatMessage({ id: 'currency' });

        case ESecondaryInvestmentSortOption.InvestmentAmount:
          return intl.formatMessage({ id: 'investment.amount' });

        case ESecondaryInvestmentSortOption.Duration:
          return intl.formatMessage({ id: 'duration' });

        case ESecondaryInvestmentSortOption.HavingSaleStatus:
          return intl.formatMessage({ id: 'status' });

        case ESecondaryInvestmentSortOption.InvestmentDate:
          return intl.formatMessage({ id: 'investment_date' });

        case ESecondaryInvestmentSortOption.Rating:
          return intl.formatMessage({ id: 'rating' });

        case ESecondaryInvestmentSortOption.ProductType:
          return intl.formatMessage({ id: 'type' });

        default:
          return '';
      }
    },
    [intl],
  );

  const handleClear = () => {
    formik.resetForm({ values: secondaryInvestmentFiltersHelper.getEmptyValue() });
    onClear?.();
  };

  const totalFiltersCount = secondaryInvestmentFiltersHelper.countValues(formik.values);
  const durationCount = secondaryInvestmentFiltersHelper.countDurationValues(formik.values);

  return (
    <Box>
      <Box className={classes.closeButtonContainer}>
        <IconButton onClick={onClose}>
          <RiCloseLine />
        </IconButton>
      </Box>

      <FormikContext.Provider value={formik}>
        <Form className={classes.body}>
          <Box className={classes.title}>
            <Typography variant="subtitle1" fontWeight={500}>
              <FormattedMessage id="all_filters" />
            </Typography>

            {totalFiltersCount > 0 && <InlineBadge label={totalFiltersCount} />}
          </Box>

          <Typography variant="body2" color="text.secondary" marginBottom="32px">
            <FormattedMessage id="select_filters_to_apply" />
          </Typography>

          {withSort && (
            <Accordion className={commonFiltersStyles.accordion} elevation={0}>
              <AccordionSummary expandIcon={<RiArrowDownSFill />}>
                <Typography>
                  <FormattedMessage id="sort_by" />
                </Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.sortContainer}>
                {Object.entries(secondaryInvestmentSortVariantsMap).map(([key, value]) => {
                  return (
                    <Box key={key} className={classes.sortGroup}>
                      <SortButton
                        label={getSortLabel(key as ESecondaryInvestmentSortOption)}
                        value={value[SortOrder.ASC]}
                      />

                      <SortButton
                        label={getSortLabel(key as ESecondaryInvestmentSortOption)}
                        value={value[SortOrder.DESC]}
                        defaultSelect={key === ESecondaryInvestmentSortOption.InvestmentDate}
                      />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="status" />
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Field
                id="havingSaleStatus"
                name="havingSaleStatus"
                component={Radio}
                options={statusFilterOptions}
                defaultValue={ShareSaleStatus.Open}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="borrower.type" />
              </Typography>

              {formik.values.productType?.length > 0 && (
                <InlineBadge label={formik.values.productType?.length} />
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Field
                id="productType"
                name="productType"
                component={InlineSelect}
                options={productTypeOptions}
                multiple
                className={commonFiltersStyles.inlineSelect}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="loan_currency" />
              </Typography>

              {formik.values.currency?.length > 0 && (
                <InlineBadge label={formik.values.currency?.length} />
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Field
                id="currency"
                name="currency"
                component={InlineSelect}
                options={currencyOptions}
                multiple
                className={commonFiltersStyles.inlineSelect}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="risk_type" />
              </Typography>

              {formik.values.rating?.length > 0 && (
                <InlineBadge label={formik.values.rating?.length} />
              )}
            </AccordionSummary>

            <AccordionDetails>
              <Field
                id="rating"
                name="rating"
                component={InlineSelect}
                options={ratingOptions}
                multiple
                className={commonFiltersStyles.inlineSelect}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion className={commonFiltersStyles.accordion} elevation={0}>
            <AccordionSummary expandIcon={<RiArrowDownSFill />}>
              <Typography>
                <FormattedMessage id="loan_duration.in_months" />
              </Typography>

              {durationCount > 0 && <InlineBadge label={durationCount} />}
            </AccordionSummary>

            <AccordionDetails>
              <Box className={commonFiltersStyles.rangeInput}>
                <Field
                  id="minDuration"
                  name="minDuration"
                  component={AmountTextField}
                  label={intl.formatMessage({ id: 'min_duration' })}
                  size="medium"
                  inputProps={{
                    thousandSeparator: false,
                    decimalScale: 0,
                  }}
                />
                <Box className={commonFiltersStyles.dash} />

                <Field
                  id="maxDuration"
                  name="maxDuration"
                  component={AmountTextField}
                  label={intl.formatMessage({ id: 'max_duration' })}
                  size="medium"
                  inputProps={{
                    thousandSeparator: false,
                    decimalScale: 0,
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Form>
      </FormikContext.Provider>

      <Paper className={classes.footer}>
        <Button variant="text" onClick={handleClear}>
          <FormattedMessage id="filters.clear" />
        </Button>

        <Button onClick={() => handleSubmit(formik.values)}>
          <FormattedMessage id="filters.apply" />
        </Button>
      </Paper>
    </Box>
  );
});

import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Card from 'components/cardv2';
import { FormattedMessage } from 'react-intl';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BusinessKycRecord, KycRecord } from 'api/investor-client';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '64px',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

type IProps = {
  kycRecord: KycRecord;
};

export const CompanyKyc = (props: IProps) => {
  const classes = useStyles();
  const { kycRecord } = props;
  const businessKycRecord = kycRecord as BusinessKycRecord;

  return (
    <>
      {businessKycRecord.contactPerson && (
        <>
          <Typography className={classes.title}>
            <FormattedMessage id="company_contact" />
            <AiOutlineInfoCircle />
          </Typography>
          <Grid container spacing={4}>
            <Grid xs={12} sm={6} md={6} lg={6}>
              <Card
                header={'Company details'}
                items={[
                  { key: 'legal_name', value: businessKycRecord.companyName },
                  { key: 'uid', value: businessKycRecord.companyUID },
                  { key: 'legal_structure', value: businessKycRecord.legalStructure },
                  {
                    key: 'address',
                    value: `${businessKycRecord.address.street} ${businessKycRecord.address.streetNr}, ${businessKycRecord.address.zipcode} ${businessKycRecord.address.country.shortName}`,
                  },
                  {
                    key: 'industry',
                    value: businessKycRecord.industry,
                  },
                ]}
              />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={6}>
              <Card
                header={'Contact person'}
                items={[
                  {
                    key: 'legal_name',
                    value: `${businessKycRecord.contactPerson.firstName} ${businessKycRecord.contactPerson.lastName}`,
                  },
                  {
                    key: 'common.form_field.birthday',
                    value: businessKycRecord.contactPerson.dateOfBirth,
                  },
                  {
                    key: 'common.form_field.nationality',
                    value: businessKycRecord.contactPerson.nationality.shortName,
                  },
                  {
                    key: 'address',
                    value: `${businessKycRecord.contactPerson.address.street} ${businessKycRecord.contactPerson.address.streetNr}, ${businessKycRecord.contactPerson.address.zipcode} ${businessKycRecord.contactPerson.address.country.shortName}`,
                  },
                  {
                    key: 'common.form_field.phone_number',
                    value: businessKycRecord.contactPerson.phoneNumber,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </>
      )}

      {businessKycRecord?.beneficialOwners?.length > 0 && (
        <>
          <Typography className={classes.title}>
            <FormattedMessage id="beneficial_owners" />
            <AiOutlineInfoCircle />
          </Typography>
          <Grid container spacing={4}>
            {businessKycRecord.beneficialOwners.map((e, idx) => {
              return (
                <Grid xs={12} sm={6} md={6} lg={6}>
                  <Card
                    header={<FormattedMessage id="beneficial_owner_nr" values={{ nr: idx + 1 }} />}
                    items={[
                      { key: 'legal_name', value: `${e.firstName} ${e.lastName}` },
                      { key: 'common.form_field.birthday', value: e.dateOfBirth },
                      { key: 'common.form_field.nationality', value: e.nationality.shortName },
                      {
                        key: 'address',
                        value: `${e.address.street} ${e.address.streetNr}, ${e.address.zipcode} ${e.address.country.shortName}`,
                      },
                      // { key: 'common.form_field.phone_number', value: '+41 76 416 1511' },
                    ]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}

      <Typography className={classes.title}>
        <FormattedMessage id="aml_questionnaire" />
        <AiOutlineInfoCircle />
      </Typography>
      <Grid container spacing={4}>
        <Grid xs={12} sm={6} md={6} lg={6}>
          <Card
            items={[
              { key: 'businessAnnualIncome', value: businessKycRecord.businessAnnualIncome },
              { key: 'businessNetWorth', value: businessKycRecord.businessNetWorth },
              {
                key: 'businessPlannedInvestment',
                value: businessKycRecord.businessPlannedInvestment,
              },
              { key: 'moneyOrigination', value: businessKycRecord.moneyOrigination },
              { key: 'politicalExposure', value: businessKycRecord.politicalExposure },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

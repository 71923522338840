import { Box, CSSObject, Typography, styled } from '@mui/material';

import { mixinObject, restrictProp } from 'utils';
import { DealRating } from 'types';

type TSize = 'medium' | 'large';

const SWrapper = styled(Box, {
  shouldForwardProp: restrictProp(['size']),
})<{ size: TSize }>(({ size, theme }) => ({
  minWidth: '46px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  textTransform: 'uppercase',

  ...mixinObject<CSSObject>(size === 'medium', {
    padding: '1px 6px',
    border: '1px solid',
  }),

  ...mixinObject<CSSObject>(size === 'large', {
    padding: '4px 12px',
  }),
}));

const getColor = (rating: DealRating) => {
  switch (rating) {
    case DealRating.AAA:
      return '#46814F';

    case DealRating.AA:
      return '#23A438';

    case DealRating.A:
      return '#FB9702';

    case DealRating.B:
      return '#889CAA';

    case DealRating.C:
      return '#FF897D';

    case DealRating.D:
      return '#DE3730';

    case DealRating.E:
      return '#BA1A1A';
  }
};

interface Props {
  rating: DealRating;
  size?: 'medium' | 'large';
}

export const RiskChip = ({ rating, size = 'medium' }: Props) => {
  return (
    <SWrapper color={getColor(rating)} size={size}>
      <Typography
        variant={size === 'medium' ? 'subtitle3' : 'h6'}
        fontWeight={size === 'medium' ? 400 : 500}
      >
        {rating}
      </Typography>
    </SWrapper>
  );
};

import { Box, LinearProgress, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { DealDto, IDealDto } from 'api/investor-client';
import { getDurationToEndDeal } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },

  progress: {
    height: '8px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.barkSurface, 0.08),

    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.light,
    },

    '&.table-layout': {
      height: '6px',
    },
  },
}));

interface Props {
  deal: IDealDto | DealDto;
  size?: 'shrink' | 'normal';
}

export const DurationDisplay = ({ deal, size = 'shrink' }: Props) => {
  const classes = useStyles();

  if (size === 'shrink') {
    const { durationToEnd, displayInMonths } = getDurationToEndDeal(deal);

    return (
      <Box display="flex">
        <Typography variant="body2" fontWeight={500} textTransform="lowercase" noWrap>
          {durationToEnd} <FormattedMessage id={displayInMonths ? 'month.short' : 'days.short'} />
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {'\u00a0/\u00a0'}
        </Typography>

        <Typography variant="body2" color="text.secondary" textTransform="lowercase" noWrap>
          {deal.duration} <FormattedMessage id="month.short" />
        </Typography>
      </Box>
    );
  }

  const endDate = dayjs(new Date(deal.endDate));
  const durationInMonths = endDate.diff(dayjs(), 'months');
  const monthsLeft = deal.duration - Math.max(endDate.diff(dayjs(), 'months', true), 0);
  const leftoverDays = endDate.diff(dayjs().add(durationInMonths, 'months'), 'days');

  const withMonths = durationInMonths > 0;
  const withDays = leftoverDays > 0;
  const isOver = durationInMonths <= 0 && leftoverDays <= 0;

  return (
    <Box className={classes.container}>
      <Box display="flex">
        <Typography variant="body2" fontWeight={500} textTransform="lowercase" noWrap>
          {withMonths && (
            <>
              {durationInMonths} <FormattedMessage id={durationInMonths > 1 ? 'months' : 'month'} />
              {withDays && (
                <>
                  {'\u00a0'}
                  <FormattedMessage id="and" />
                  {'\u00a0'}
                </>
              )}
            </>
          )}

          {withDays && (
            <>
              {leftoverDays} <FormattedMessage id={leftoverDays > 1 ? 'days' : 'day'} />
            </>
          )}

          {isOver && (
            <>
              0 <FormattedMessage id="days" />
            </>
          )}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {'\u00a0/\u00a0'}
        </Typography>

        <Typography variant="body2" color="text.secondary" textTransform="lowercase" noWrap>
          {deal.duration} <FormattedMessage id={deal.duration > 1 ? 'months' : 'month'} />
        </Typography>
      </Box>

      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={(monthsLeft / deal.duration) * 100}
      />
    </Box>
  );
};

import { Box, Theme, alpha, styled, useTheme } from '@mui/material';
import { RiAlertLine, RiNotification4Line, RiSettings4Line, RiStarLine } from 'react-icons/ri';

import { ENotificationID, NotificationMessage } from 'api/notifications-client';

const SContainer = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: theme.shape.borderRadius,
}));

const getIcon = (notification: NotificationMessage, theme: Theme) => {
  switch (notification.notificationId) {
    case ENotificationID.PayoutDeclined:
    case ENotificationID.PayinDeclined:
    case ENotificationID.DealCancelled:
    case ENotificationID.DealDefaulted:
    case ENotificationID.InvestmentCancelled:
    case ENotificationID.DealNotFinanced: {
      return {
        bgColor: theme.palette.background.lightRed,
        color: theme.palette.error.main,
        icon: <RiAlertLine />,
      };
    }

    case ENotificationID.NewDealPublished:
    case ENotificationID.DealFilter: {
      return {
        bgColor: alpha(theme.palette.text.secondary, 0.08),
        color: theme.palette.primary.light,
        icon: <RiStarLine />,
      };
    }

    case ENotificationID.AutoWithdrawalBooked:
    case ENotificationID.BankAccountChangeLsvStatus: {
      return {
        bgColor: alpha(theme.palette.text.secondary, 0.08),
        color: theme.palette.text.secondary,
        icon: <RiSettings4Line />,
      };
    }

    case ENotificationID.PayoutBooked:
    case ENotificationID.PayinBooked:
    case ENotificationID.MilestoneAdded:
    case ENotificationID.UserRoleChangedInInvestorAccount:
    case ENotificationID.UserRemovedFromInvestorAccount:
    case ENotificationID.UserAddedToInvestorAccount:
    case ENotificationID.DealPayout:
    case ENotificationID.InvestmentPaid:
    default: {
      return {
        bgColor: alpha(theme.palette.text.secondary, 0.08),
        color: theme.palette.text.secondary,
        icon: <RiNotification4Line />,
      };
    }
  }
};

interface Props {
  notification: NotificationMessage;
}

export const NotificationIcon = ({ notification }: Props) => {
  const theme = useTheme();
  const { bgColor, color, icon } = getIcon(notification, theme);

  return <SContainer sx={{ backgroundColor: bgColor, color: color }}>{icon}</SContainer>;
};

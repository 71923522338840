import { Tab, TabProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { UrlObject } from 'url';

interface Props extends TabProps {
  href: string | UrlObject;
}

export const TabLink = ({ href, ...rest }: Props) => {
  return (
    <Link to={href.toString()}>
      <Tab {...rest} />
    </Link>
  );
};

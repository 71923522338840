import React, { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { makeStyles } from '@mui/styles';
import { Box, Button, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { MdInfoOutline } from 'react-icons/md';
import { RiCloseLine } from 'react-icons/ri';

import { useUserDetails } from 'providers/user-details';
import { getLinks } from 'utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'sticky',
    bottom: '16px',
    left: 0,
    right: 0,
    height: 0,
    margin: '0 16px',
    zIndex: 9999,
  },
  container: {
    position: 'absolute',
    bottom: 0,
    padding: '18px',
    color: '#FFFFFF',
    background: '#1A4063',
    borderRadius: '4px',
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '14px',
  },
  icon: {
    fontSize: '28px',
    minWidth: '28px',
    marginRight: '12px',
  },
  buttonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media (max-width: 900px)': {
      justifyContent: 'center',
    },
  },
  closeButton: {
    marginLeft: '20px',
    width: '42px',
    height: '36px',
    fontSize: '20px',
    minWidth: 0,
    padding: 0,
  },
}));

const COOKIE_NAME = 'cookies-accepted';

interface Props {
  isMenuOpen?: boolean;
}

export const CookieBanner = ({ isMenuOpen }: Props) => {
  const classes = useStyles({ isMenuOpen });
  const intl = useIntl();

  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const userContext = useUserDetails();

  const { privacyLink } = useMemo(() => {
    return getLinks(userContext.currentLanguage);
  }, [userContext.currentLanguage]);

  const closeBanner = useCallback(
    () => setCookie(COOKIE_NAME, true, { path: '/' }),
    [setCookie],
  );

  if (!!cookies?.[COOKIE_NAME]) {
    return null;
  }

  return (
    <Box className={classes.wrapper}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={9} className={classes.textBlock}>
          <MdInfoOutline className={classes.icon} />
          {intl.formatMessage({ id: 'cookies_banner' })}
        </Grid>

        <Grid item xs={12} md={3} className={classes.buttonsBlock}>
          <Button
            variant="contained"
            onClick={() => {
              window.open(privacyLink, '_blank');
            }}
          >
            {intl.formatMessage({ id: 'privacy_statement_open' })}
          </Button>

          <Button
            variant="contained"
            className={classes.closeButton}
            onClick={closeBanner}
          >
            <RiCloseLine />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

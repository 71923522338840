import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import {
  Grid,
  Theme,
  SelectChangeEvent,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { countries } from 'countries-list';
import { toast } from 'react-toastify';
import { useAsyncCallback } from 'react-async-hook';
import { FormattedMessage, useIntl } from 'react-intl';

import { Iso2CountryCode } from 'api/investor-client';
import { Select } from 'components/forms';
import { Select as UISelect } from 'components/UI';
import { Input } from 'components';
import Card from 'components/card';
import { notEmpty } from 'utils/validation';
import { useUserDetails } from 'providers/user-details';
import { Salutation } from 'types';
import { MdEdit } from 'react-icons/md';

interface Values {
  firstName: string;
  lastName: string;
  salutation: Salutation;
  birthday: string;
  phone: string;
  country: string;
  nationality: string;
  city: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  email: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: '750px',
  },
  cardHeader: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}));

const FormFields = ({
  initialized,
  loading,
  edit,
  setEdit,
}: {
  initialized: boolean;
  loading: boolean;
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const fields = useFormikContext<Values>();
  const { countryList, nationalityList } = useMemo(() => {
    const countryList: Array<{ label: string | ReactNode; value: string }> = [];
    const nationalityList: Array<{ label: string | ReactNode; value: string }> = [];

    const sortedCountries = Object.entries(countries).sort((a, b) => {
      return a[1].name.localeCompare(b[1].name);
    });

    for (const [key, val] of sortedCountries) {
      countryList.push({ value: key, label: `${val.emoji} ${val.name}` });
      nationalityList.push({
        value: val.name,
        label: `${val.emoji} ${val.name}`,
      });
    }

    return { countryList, nationalityList };
  }, []);

  const salutationList = useMemo(() => {
    return Object.entries(Salutation).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  }, []);

  const countryChanged = useCallback(
    (e: SelectChangeEvent) => {
      const country = countries[e.target.value as keyof typeof countries];
      if (!country) return;

      if (!fields.values['phone'] || fields.values['phone'].length <= 4) {
        fields.setFieldValue('phone', country.phone.split(',')[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields.values['phone']],
  );

  return (
    <Card>
      <Card.Header
        title={intl.formatMessage({ id: 'basic_details' })}
        wrapperClassName={classes.cardHeader}
      >
        <FormattedMessage id="update_profile" />
      </Card.Header>
      <Card.Body loadingContent={!initialized}>
        <Grid container spacing={3} className={classes.wrapper}>
          <Grid item sm={2}>
            <Field
              id="salutation"
              name="salutation"
              label={intl.formatMessage({ id: 'common.form_field.salutation' })}
              labelDirection="column"
              options={salutationList}
              component={Select}
              validate={notEmpty}
            />
          </Grid>
          <Grid item sm={5}>
            <Field
              id="firstName"
              name="firstName"
              label={intl.formatMessage({ id: 'common.form_field.first_name' })}
              labelDirection="column"
              component={Input}
              validate={notEmpty}
            />
          </Grid>
          <Grid item sm={5}>
            <Field
              id="lastName"
              name="lastName"
              label={intl.formatMessage({ id: 'common.form_field.last_name' })}
              labelDirection="column"
              component={Input}
              validate={notEmpty}
            />
          </Grid>

          <Grid item sm={6}>
            <Field
              type="email"
              id="email"
              name="email"
              label={intl.formatMessage({ id: 'email' })}
              labelDirection="column"
              component={Input}
              validate={notEmpty}
              disabled={!edit}
            />
          </Grid>
          {/* <Grid item sm={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Tooltip title={intl.formatMessage({ id: 'change_email.tooltip' })}>
              <IconButton
                size="medium"
                color={edit ? 'primary' : 'secondary'}
                onClick={() => {
                  // fields.handleReset();
                  setEdit((e) => !e);
                }}
              >
                <MdEdit />
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </Card.Body>
      <Card.Footer>
        <Button
          size="small"
          variant="contained"
          color="primary"
          type="submit"
          sx={{ width: 'max-content' }}
          disabled={loading}
        >
          {loading && <CircularProgress size={20} sx={{ marginRight: 3 }} />}
          <FormattedMessage id="save" />
        </Button>
      </Card.Footer>
    </Card>
  );
};

export const PersonalDetailsForm = () => {
  const intl = useIntl();
  const { userDetails, initiallyLoaded, updateUser, updateEmail, refresh } = useUserDetails();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const submit = useAsyncCallback(async (data: Values, formik: FormikHelpers<Values>) => {
    await updateUser({
      firstName: data.firstName,
      lastName: data.lastName,
      salutation: data.salutation,
      birthday: data.birthday ? new Date(data.birthday).valueOf() : null,
      phone: data.phone || null,
      country: (data.country as Iso2CountryCode) || null,
      nationality: data.nationality || null,
      city: data.city || null,
      street: data.street || null,
      streetNumber: data.streetNumber || null,
      zipCode: data.zipCode || null,
    });
    toast.success(intl.formatMessage({ id: 'success.person_details_change' }));

    if (data?.email !== userDetails?.email) {
      await updateEmail({ email: data?.email });
      setEdit(false);
      toast.success(intl.formatMessage({ id: 'change_email.success_request' }));
    }
  });

  return (
    <Formik
      initialValues={{
        firstName: userDetails?.firstName ?? '',
        lastName: userDetails?.lastName ?? '',
        salutation: (userDetails?.salutation ?? Salutation.Mr) as Salutation,
        birthday: userDetails?.birthday ? dayjs(userDetails.birthday).format('YYYY-MM-DD') : '',
        phone: userDetails?.phone ?? '',
        country: userDetails?.country ?? '',
        nationality: userDetails?.nationality ?? '',
        city: userDetails?.city ?? '',
        street: userDetails?.street ?? '',
        streetNumber: userDetails?.streetNumber ?? '',
        zipCode: userDetails?.zipCode ?? '',
        email: userDetails?.email ?? '',
      }}
      onSubmit={submit.execute}
      enableReinitialize
    >
      <Form>
        <FormFields
          initialized={initiallyLoaded}
          loading={submit.loading}
          edit={edit}
          setEdit={setEdit}
        />
      </Form>
    </Formik>
  );
};

import React, { ChangeEvent, useMemo } from 'react';
import { Button, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import {
  getProductTypeOptions,
  getTransactionTypeOptions,
  getTransactionMethodOptions,
  omitBy,
  omitEmptyFilters,
} from 'utils';
import { Select } from 'components/forms';
import { RangeInput } from 'components';
import { DealRating } from 'types';

interface Props {
  initialFilters: Record<string, any>;
  filtersOptions?: { investments: string[] };
  onChange: (data: {
    filters: Record<string, string | number | string[]>;
  }) => void;
  onClearFilters: () => void;
}

export const TableFilters = ({
  initialFilters,
  filtersOptions,
  onChange,
  onClearFilters,
}: Props) => {
  const intl = useIntl();

  const productTypeOptions = useMemo(() => getProductTypeOptions(intl), [intl]);
  const transactionTypeOptions = useMemo(
    () => getTransactionTypeOptions(intl),
    [intl],
  );
  const transactionMethodOptions = useMemo(
    () => getTransactionMethodOptions(intl),
    [intl],
  );
  const investmentOptions = useMemo(
    () =>
      filtersOptions?.investments.map((e) => ({
        label: e,
        value: e,
      })) ?? [],
    [filtersOptions],
  );
  const ratingOptions = useMemo(
    () =>
      Object.values(DealRating).map((e) => ({
        value: e,
        label: e,
      })),
    [],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        type: -1,
        method: -1,
        investmentId: -1,
        productType: -1,
        rating: -1,
        ...initialFilters,
      }}
      onSubmit={() => {}}
    >
      {() => (
        <Form>
          <Grid container alignItems="center" justifyContent="space-between">
            <Field
              id="type"
              name="type"
              label={intl.formatMessage({ id: 'type' })}
              labelDirection="column"
              component={Select}
              options={[
                {
                  value: -1,
                  label: intl.formatMessage({ id: 'all' }),
                },
                ...transactionTypeOptions,
              ]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  filters: omitBy(
                    {
                      ...initialFilters,
                      type: e.target.value,
                    },
                    omitEmptyFilters,
                  ),
                });
              }}
              className="filter auto"
              labelClassName="filter"
            />
            <Field
              id="method"
              name="method"
              label={intl.formatMessage({ id: 'category' })}
              labelDirection="column"
              component={Select}
              options={[
                {
                  value: -1,
                  label: intl.formatMessage({ id: 'all' }),
                },
                ...transactionMethodOptions,
              ]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  filters: omitBy(
                    {
                      ...initialFilters,
                      method: e.target.value,
                    },
                    omitEmptyFilters,
                  ),
                });
              }}
              className="filter auto"
              labelClassName="filter"
            />
            <Field
              id="productType"
              name="productType"
              label={intl.formatMessage({ id: 'borrower.type' })}
              labelDirection="column"
              component={Select}
              options={[
                {
                  value: -1,
                  label: intl.formatMessage({ id: 'all' }),
                },
                ...productTypeOptions,
              ]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  filters: omitBy(
                    {
                      ...initialFilters,
                      productType: e.target.value,
                    },
                    omitEmptyFilters,
                  ),
                });
              }}
              className="filter auto"
              labelClassName="filter"
            />
            <Field
              id="rating"
              name="rating"
              label={intl.formatMessage({ id: 'rating' })}
              labelDirection="column"
              component={Select}
              options={[
                {
                  value: -1,
                  label: intl.formatMessage({ id: 'all' }),
                },
                ...ratingOptions,
              ]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  filters: omitBy(
                    {
                      ...initialFilters,
                      rating: e.target.value,
                    },
                    omitEmptyFilters,
                  ),
                });
              }}
              className="filter auto"
              labelClassName="filter"
            />
            <Field
              id="investmentId"
              name="investmentId"
              label={intl.formatMessage({ id: 'investment' })}
              labelDirection="column"
              component={Select}
              options={[
                {
                  value: -1,
                  label: intl.formatMessage({ id: 'all' }),
                },
                ...investmentOptions,
              ]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange({
                  filters: omitBy(
                    {
                      ...initialFilters,
                      investmentId: e.target.value,
                    },
                    omitEmptyFilters,
                  ),
                });
              }}
              className="filter auto"
              labelClassName="filter"
            />
            <Field
              id="bookingDate"
              leftId="minBookingDate"
              rightId="maxBookingDate"
              name="bookingDate"
              type="date"
              label={intl.formatMessage({ id: 'pay_date' })}
              labelDirection="column"
              component={RangeInput}
              onChange={(id: string, value: string | number) => {
                onChange({
                  filters: omitBy(
                    {
                      ...initialFilters,
                      [id]: value,
                    },
                    omitEmptyFilters,
                  ),
                });
              }}
              className="filter range"
              labelClassName="filter"
            />
            <Field
              id="transferDueDate"
              leftId="minTransferDueDate"
              rightId="maxTransferDueDate"
              name="transferDueDate"
              type="date"
              label={intl.formatMessage({ id: 'due_date' })}
              labelDirection="column"
              component={RangeInput}
              onChange={(id: string, value: string | number) => {
                onChange({
                  filters: omitBy(
                    {
                      ...initialFilters,
                      [id]: value,
                    },
                    omitEmptyFilters,
                  ),
                });
              }}
              className="filter range"
              labelClassName="filter"
            />
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                onClearFilters();
              }}
            >
              <FormattedMessage id="filters.clear" />
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { CellProps } from 'react-table';
import dayjs from 'dayjs';

import {
  InvestmentWithDealDto,
  RepaymentDto,
  ShareRepaymentTransactionDto,
  ShareTransactionDto,
} from 'api/investor-client';
import { Table } from 'components';
import { useFormatMoney } from 'hooks';
import { getRepaymentTableData } from 'utils/repayments';
import { moveDecimalPoint } from 'utils';

interface Props {
  investment: InvestmentWithDealDto;
}

type RepaymentObject = ShareRepaymentTransactionDto & { dealRepayment?: RepaymentDto };
type RepaymentType = { [key: string]: any } & RepaymentObject;

const useStyles = makeStyles({
  table: {
    '& td': {
      whiteSpace: 'nowrap',
    },
    '& th, & tr:last-of-type td': {
      fontWeight: 600,
    },
  },
  accentCol: {
    background: '#ededed',
    '&.MuiTableCell-head': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
  },
});

const getTotalsFromRepayments = (repayments: ShareRepaymentTransactionDto[]) => {
  const total: any = {
    principal: 0,
    interest: 0,
    fee: 0,
    overdueFee: 0,
    overdueInterest: 0,
    lateFee: 0,
    other: 0,
    total: 0,
    totalIncome: 0,
    totalExpenses: 0,
    outstandingInvestment: 0,
  };
  const totalDefault: any = {
    principal: 0,
    interest: 0,
    fee: 0,
    overdueFee: 0,
    overdueInterest: 0,
    lateFee: 0,
    other: 0,
    total: 0,
    totalIncome: 0,
    totalExpenses: 0,
    outstandingInvestment: 0,
  };

  repayments.forEach((r) => {
    total.principal += r.transaction.principal;
    totalDefault.principal += r.isLost ? r.transaction?.principal || 0 : 0;
    total.interest += r.transaction.interest;
    totalDefault.interest += r.isLost ? r.transaction?.interest || 0 : 0;
    total.fee += r.transaction.fee;
    totalDefault.fee += r.isLost ? r.transaction?.fee || 0 : 0;
    total.overdueFee += r.transaction.overdueFee;
    totalDefault.overdueFee += r.isLost ? r.transaction?.overdueFee || 0 : 0;
    total.overdueInterest += r.transaction.overdueInterest;
    total.lateFee += r.transaction.lateFee;
    total.lostLateFee += r.isLost ? r.transaction?.lateFee || 0 : 0;
    total.other += r.transaction.other;
    total.lostOther += r.isLost ? r.transaction?.other || 0 : 0;
    total.total += r.transaction.total;
    total.totalIncome += r.transaction.totalIncome;
    totalDefault.totalIncome += r.isLost ? r.transaction?.totalIncome || 0 : 0;
    total.totalExpenses += r.transaction.totalExpenses;
    total.outstandingInvestment += r.remainingPrincipal;
  });

  return { total, totalDefault };
};

export const RepaymentSchedule = ({ investment }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const formatMoney = useFormatMoney({
    prefix: `${investment.deal.currency} `,
  });

  const { repayments, total } = useMemo(() => {
    const repayments = getRepaymentTableData(investment.repayments, investment.deal.repayments);

    const totalTransaction = getTotalsFromRepayments(repayments).total as ShareTransactionDto;
    const total = {} as RepaymentType;
    total.installment = 'Total';
    total.transaction = totalTransaction;

    return { repayments, total };
  }, [investment]);

  function getRepaymentStatus(original: RepaymentObject) {
    if (!original.transaction.id) return '';
    if (original.isLost) return 'Loss';
    if (original.isBooked) return 'Repaid';
    if (original.isPaid) return 'Pending';
    if (original.daysInArrears > 0) return 'Delayed';
    return 'Scheduled';
  }

  const columns = useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: 'installment' }),
        accessor: 'installment',
        disableSortBy: true,
        Cell: function Cell({ value }: CellProps<RepaymentObject>) {
          return value ?? intl.formatMessage({ id: 'total' });
        },
      },
      {
        Header: intl.formatMessage({ id: 'scheduled_payment_date' }),
        accessor: 'transaction.date',
        disableSortBy: true,
        Cell: function Cell({ value }: CellProps<RepaymentObject, string>) {
          if (!value) return '';
          return dayjs(value).format('DD.MM.YYYY');
        },
      },
      {
        Header: intl.formatMessage({ id: 'actual_payment_date' }),
        id: 'actual_repayment',
        disableSortBy: true,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          if (original.isPaid && original.dealRepayment?.repaymentDate) {
            return dayjs(original.dealRepayment.repaymentDate).format('DD.MM.YYYY');
          } else {
            return '~';
          }
        },
      },
      {
        Header: intl.formatMessage({ id: 'gross_repayment' }),
        id: 'gross_repayment',
        disableSortBy: true,
        className: classes.accentCol,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          return `${formatMoney(
            moveDecimalPoint(original.transaction.principal + original.transaction.interest, -2),
          )}`;
        },
      },
      {
        Header: intl.formatMessage({ id: 'amortization' }),
        disableSortBy: true,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          return formatMoney(moveDecimalPoint(original.transaction.principal, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'earnings' }),
        disableSortBy: true,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          return formatMoney(moveDecimalPoint(original.transaction.totalIncome, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'expenses' }),
        disableSortBy: true,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          return formatMoney(moveDecimalPoint(original.transaction.totalExpenses, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'net_repayment' }),
        disableSortBy: true,
        className: classes.accentCol,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          return formatMoney(moveDecimalPoint(original.transaction.total, -2));
        },
      },
      {
        Header: intl.formatMessage({ id: 'status' }),
        id: 'status',
        disableSortBy: true,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          let repaymentStatus = getRepaymentStatus(original);
          return (
            repaymentStatus && intl.formatMessage({ id: 'repayment_status.' + repaymentStatus })
          );
        },
      },
      {
        Header: intl.formatMessage({ id: 'investment.outstanding' }),
        disableSortBy: true,
        Cell: function Cell({ row: { original } }: CellProps<RepaymentObject>) {
          return formatMoney(
            moveDecimalPoint(
              (original.transaction as any).outstandingInvestment || original.remainingPrincipal,
              -2,
            ),
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage, formatMoney],
  );

  return (
    <Grid container spacing={4}>
      <Grid item md={12}>
        <Table<RepaymentType>
          size="small"
          initialFilters={[]}
          initialSort={[]}
          withPagination={false}
          onFiltersChange={(f) => {}}
          columns={columns}
          data={[...repayments, total]}
          count={0}
          noRowsIntlName="warning.no_repayments"
          className={classes.table}
        />
      </Grid>
    </Grid>
  );
};

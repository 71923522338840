import React, { useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { TTabData } from '../types';
import { BalanceDto } from 'api/investor-client';
import { isError } from '../PaymentsOverview/PaymentCard';
import { formatMoney, moveDecimalPoint } from 'utils';
import { Currency } from 'types';
import { CurrencyIcon } from 'components/UI';

interface Props {
  dataToDisplay: TTabData | null;
  balance?: BalanceDto[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: '1px solid rgba(114, 119, 127, 0.16)',
    boxShadow: '0px -1px 6px 0px rgba(0, 0, 0, 0.15)',
    position: 'sticky',
    display: 'flex',
    background: 'white',
    padding: '24px',
    bottom: 0,
    flex: 1,
  },
}));

export const StickyFooter = ({ dataToDisplay, balance }: Props) => {
  const classes = useStyles();
  const totals = useMemo(() =>
    Object.entries(dataToDisplay || {}).reduce((acc, [currency, payments]) => {
      const balanceCurr = balance?.find((bal) => bal.currency === currency)?.amount ?? 0;
      const isRejected = payments.some((payment) => isError(payment));
      if (isRejected || balanceCurr === undefined) return acc;
      const orderAmount = payments.reduce((sum, next) => {
        return sum + next.request.amount + (next.request.fee || 0);
      }, 0);

      const isEnoughBalance = orderAmount <= balanceCurr;
      const total = isEnoughBalance ? orderAmount : 0;
      return {
        ...acc,
        [currency]: total
      }
    }, {} as Record<Currency, number>), [dataToDisplay, balance]);
  return (
    <Grid container className={classes.container}>
      {!!Object.entries(totals).length && <Grid item xs={6}>
        <Typography variant='subtitle3'>
          <FormattedMessage id="balance.deduct_from_current" />:
        </Typography>
      </Grid>}

      {Object.entries(totals).map(([currency, total], index) => (
        <Grid item xs={3} display="flex" flexDirection="row">
          {index > 0 && <Divider orientation="vertical" />}
          <Box flex={1} gap="16px" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <CurrencyIcon currency={currency} size={18} />
            <Typography variant='subtitle2'>
              {formatMoney(moveDecimalPoint(total, -2), { prefix: `${currency} ` })}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
};
import { Box } from '@mui/material';

import { VirtualAccountDTO } from 'api/payments-client';
import { BalanceDto } from 'api/investor-client';
import { Currency, EBankAccountStatus } from 'types';

import { PaymentCard } from './PaymentCard';
import { TTabData } from '../types';
import { MarketType } from 'types/secondary-market';

interface Props {
  type?: MarketType;
  dataToDisplay: TTabData | null;
  virtualAccount?: VirtualAccountDTO;
  newBalance?: BalanceDto[];
  oldBalance?: BalanceDto[];
  isPaidTab?: boolean;
}

export const PaymentsOverview = (props: Props) => {
  const { dataToDisplay, newBalance, oldBalance, virtualAccount, isPaidTab } = props;

  const isDDActive = (currency: string) => {
    const exists = virtualAccount?.bankAccounts?.find((bankAccount) => {
      return (
        bankAccount.currency === currency &&
        (bankAccount.dd?.status === EBankAccountStatus.Active ||
          bankAccount.lsv?.status === EBankAccountStatus.Active)
      );
    });

    return !!exists;
  };

  const isDataToDisplay = Object.keys(dataToDisplay || {}).length > 0;

  if (!isDataToDisplay) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap="72px">
      {Object.entries(dataToDisplay || {}).map(([currency, payments], index) => (
        <PaymentCard
          type={props.type}
          key={currency + index}
          currency={currency as Currency}
          payments={payments}
          isPaidTab={isPaidTab}
          ddActive={isDDActive(currency)}
          newBalance={newBalance?.find((bal) => bal.currency === currency)?.amount ?? 0}
          oldBalance={oldBalance?.find((bal) => bal.currency === currency)?.amount ?? 0}
        />
      ))}
    </Box>
  );
};

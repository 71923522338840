import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAsync } from 'react-async-hook';
import { useIntl } from 'react-intl';

import { investorApiClient, paymentsApiClient } from 'api';
import { PageContainer } from 'components/UI';
import { Currency, EBankAccountStatus, TransactionMethod, TransactionType } from 'types';

import { ManageFunds } from './ManageFunds';
import { Transactions } from './Transactions';
import { UnpaidCommitments } from './UnpaidCommitments';

const useStyles = makeStyles((theme) => ({
  header: {
    margin: '24px 0',

    [theme.breakpoints.up('sm')]: {
      margin: '24px',
    },
  },
}));

interface Props {}

export const AccountBalancePage = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const location = useLocation<{ openDeposit?: boolean }>();
  const depositDefaultOpened = location?.state?.openDeposit ?? false;

  const [tab, setTab] = useState<Currency>(Currency.CHF);
  const [type, setType] = useState<TransactionType | undefined>();
  const [method, setMethod] = useState<TransactionMethod | undefined>();

  const { result: transactions = [], execute } = useAsync(async () => {
    const { data } = await investorApiClient.investors.investorApiControllerGetTransactions({
      currency: tab,
      ...(type ? { type: type as any } : {}),
      ...(method ? { method: method as any } : {}),
    });
    return data.sort((a, b) => b?.date?.localeCompare(a?.date || '') || 0);
  }, [tab, type, method]);

  const { result: vaInfo } = useAsync(async () => {
    const { data } = await paymentsApiClient.api.investorApiControllerGetCurrentVirtualAccount();

    return {
      ibans: data.bankAccounts?.reduce((acc, val) => {
        const iban: any = val.iban;
        if (iban && iban.value) acc[val.currency] = iban?.value;
        return acc;
      }, {} as Record<string, string>),
      ddActivations: data.bankAccounts?.reduce(
        (acc, val) => ({
          ...acc,
          [val.currency]:
            val.dd?.status === EBankAccountStatus.Active ||
            val.lsv?.status === EBankAccountStatus.Active,
        }),
        {} as Record<string, boolean>,
      ),
    };
  }, []);

  const { result, loading } = useAsync(async () => {
    const { data } = await investorApiClient.investors.investorApiControllerGetCommitments({
      currency: [tab],
      pageIndex: 0,
      pageSize: 1000,
    });

    return data;
  }, [tab]);

  const total = useMemo(() => {
    return result?.items?.reduce((acc, val) => acc + val.unpaidAmount, 0);
  }, [result]);

  return (
    <PageContainer>
      <Card variant="page">
        <CardHeader
          className={classes.header}
          title={
            <>
              <Box display="flex" justifyContent="center">
                <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                  <Tab
                    label={`${intl.formatMessage({ id: 'currency.CHF.full' })} ${intl.formatMessage(
                      { id: 'account' },
                    )}`}
                    value={Currency.CHF}
                  />
                  <Tab
                    label={`${intl.formatMessage({ id: 'currency.EUR.full' })} ${intl.formatMessage(
                      { id: 'account' },
                    )}`}
                    value={Currency.EUR}
                  />
                </Tabs>
              </Box>

              <Divider />
            </>
          }
        />

        <CardContent>
          <Grid container spacing={{ xs: 8, md: 6 }}>
            <Grid item xs={12} md={7}>
              <ManageFunds
                depositDefaultOpened={depositDefaultOpened}
                unpaidCommitments={total}
                isDDActivated={vaInfo?.ddActivations?.[tab]}
                bankAccount={vaInfo?.ibans?.[tab]}
                currency={tab}
                onReload={execute}
              />
            </Grid>

            <Grid item xs={12} md={5} sx={{ display: 'flex' }}>
              <UnpaidCommitments data={result?.items} loading={loading} currency={tab} />
            </Grid>

            <Grid item xs={12}>
              <Transactions
                data={transactions}
                type={type}
                method={method}
                setType={setType}
                setMethod={setMethod}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

import { useState } from 'react';
import { useAsync } from 'react-async-hook';

import { investorApiClient } from 'api';
import { IDealDto } from 'api/investor-client';
import { useBasketProvider } from 'providers/basket';
import { getDealsComparator, getDealsComparator2 } from 'utils';

export const useInitiatePrimaryDeals = () => {
  const basket = useBasketProvider();
  const [primaryDeals, setPrimaryDeals] = useState<IDealDto[]>([]);
  const [initiallyFetched, setInitiallyFetched] = useState<boolean>(false);

  // Pre-fetch all primary deals and filter basket if the deal is no longer
  // available (from the backend perspective).
  useAsync(async () => {
    if (basket.primary.deals.length === 0) {
      setPrimaryDeals([]);
      setInitiallyFetched(true);
      return;
    }

    if (basket.primary.deals?.length !== primaryDeals.length) {
      setPrimaryDeals(primaryDeals.filter(d => basket.primary.deals.some(getDealsComparator2(d))));
    }

    /** check if all deals already fetched */
    if (primaryDeals.length) {
      const allFetched = basket.primary.deals.every((bd) => {
        return !!primaryDeals.find(getDealsComparator(bd));
      });

      if (allFetched) {
        setInitiallyFetched(true);
        return;
      }
    }

    const res = await investorApiClient.investors.investorApiControllerGetBulkDeal({
      deals: basket.primary.deals.map((d) => ({ dealId: d.id, providerId: d.providerId! })),
      withXirr: true,
    });

    const fetchedPrimaryDeals = res.data;
    setPrimaryDeals(fetchedPrimaryDeals);
    setInitiallyFetched(true);

    basket.primary.deals.forEach((d) => {
      const exists = fetchedPrimaryDeals.find(getDealsComparator(d));
      if (!exists) basket.primary.removeDeal(d);
    });
  }, [basket.primary.deals, basket.primary.deals?.length]);

  return { primaryDeals, primaryDealsInitiallyFetched: initiallyFetched };
};

import { makeStyles } from '@mui/styles';

export const useCardStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
  },

  cardContent: {
    margin: '24px',
  },
}));

import { IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCloseLine } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

import { IDealDto } from 'api/investor-client';
import { WithDialogPros, withDialog } from 'wrappers';
import { IDealEventWithShareId } from 'types';

import { ActivityTimelineEvent } from './ActivityTimelineEvent';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '32px 80px 32px 40px',
  },

  closeButton: {
    position: 'absolute',
    top: '14px',
    right: '14px',
  },
}));

interface Props extends WithDialogPros {
  deal: IDealDto;
  dealEvents: IDealEventWithShareId[];
}

export const ActivityTimelineModal = withDialog(
  ({ deal, dealEvents, onClose }: Props) => {
    const classes = useStyles();

    return (
      <Paper className={classes.container}>
        <Typography variant="body2" color="text.secondary" marginBottom="40px">
          <FormattedMessage id="your_activity_timeline" />
        </Typography>

        <IconButton className={classes.closeButton} onClick={() => onClose?.()}>
          <RiCloseLine />
        </IconButton>

        {dealEvents.map((dealEvent, i) => (
          <ActivityTimelineEvent
            key={dealEvent.id}
            deal={deal}
            dealEvent={dealEvent}
            elementAbove={i > 0}
            elementBelow={i < dealEvents.length - 1}
          />
        ))}
      </Paper>
    );
  },
  { maxWidth: 'sm' },
);

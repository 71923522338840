import { IntlShape } from 'react-intl';
import { InvestorRole, BooleanOptions, OptionsType } from 'types';

export const renderMutipleFilterValue =
  (intl: IntlShape, options: OptionsType) => (value: string[]) => {
    if (value?.length === 1) return options.find((opt) => opt.value === value[0])?.label ?? value;
    if (value?.length > 1) return '+';
    return intl.formatMessage({ id: 'all' });
  };

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const omitEmptyFilters = (value?: string | number | string[]) =>
  value === undefined ||
  value === -1 ||
  value === '' ||
  (Array.isArray(value) && value.length === 0);

export const omitBy = (
  obj: Record<string, string | number | string[]>,
  fn: (arg0: string | number | string[]) => boolean,
) => {
  return Object.keys(obj)
    .filter((k) => !fn(obj[k]))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: obj[key],
      }),
      {} as Record<string, string | number | string[]>,
    );
};

export const getInvestorRoleList = (intl: IntlShape) => {
  return Object.values(InvestorRole).map((val) => ({
    label: intl.formatMessage({ id: `role.${val}` }),
    value: val,
  }));
};

export const getBooleanOptions = (intl: IntlShape) => {
  return Object.values(BooleanOptions).map((val) => ({
    label: intl.formatMessage({ id: `boolean.${val}` }),
    value: val,
  }));
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export * from './format';
export * from './deal';
export * from './deal-event';
export * from './debounce';
export * from './user';
export * from './get-number';
export * from './trim-object';
export * from './move-decimal-point';
export * from './download';
export * from './select-options';
export * from './csv';
export * from './sx-helpers';
export * from './mixin-object';
export * from './restrict-prop';

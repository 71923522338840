import { useMemo, useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { useIntl } from 'react-intl';
import { useRouteMatch, useHistory, useParams, Switch } from 'react-router';

import { useInvestorAccounts } from 'providers/investor-accounts';
import { NotificationsProvider } from 'providers/notifications';
import { KycProvider } from 'providers/kyc';
import { Spinner } from 'components';
import { useChangePathParam } from 'hooks';

import { LayoutRoute } from './LayoutRoute';

import {
  DashboardPage,
  NewDealsPage,
  SettingsPage,
  BasketReviewPage,
  PortfolioPage,
  InvestmentDetailPage,
  SupportPage,
  TransactionsPage,
  NotFoundPage,
  DealDetailsPage,
  AccountBalancePage,
} from 'pages';

export const AccountsRouter = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { accountId } = useParams<{ accountId: string }>();
  const investorAccounts = useInvestorAccounts();
  const changePathParam = useChangePathParam();

  const selectedAccountId = useMemo(
    () => investorAccounts.selectedAccount?.investor?.id,
    [investorAccounts.selectedAccount],
  );

  const correctAccount = useMemo(
    () => accountId === selectedAccountId,
    [accountId, selectedAccountId],
  );

  // Validate selected account
  useEffect(() => {
    if (!selectedAccountId) {
      const isSet = investorAccounts.selectAccount(accountId);
      if (!isSet) history.push('/accounts');
    } else if (!correctAccount) {
      changePathParam('accountId', selectedAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountId,
    history,
    changePathParam,
    correctAccount,
    selectedAccountId,
    investorAccounts.selectAccount,
  ]);

  const { loading } = useAsync(async () => {
    if (!correctAccount) return;
    await investorAccounts.updateInvestorToken();
  }, [accountId, correctAccount]);

  if (loading || !correctAccount) {
    return <Spinner />;
  }

  return (
    <NotificationsProvider>
      <KycProvider>
        <Switch>
          <LayoutRoute
            exact
            title={intl.formatMessage({ id: 'dashboard' })}
            path={`${path}/dashboard`}
          >
            <DashboardPage />
          </LayoutRoute>

          <LayoutRoute
            title={intl.formatMessage({ id: 'deals.new' })}
            path={`${path}/deals/:market/:providerId/:id`}
          >
            {({ match }) => {
              const market = match?.params?.market
              const isSecondary = market === 'secondary';
              return <DealDetailsPage isSecondaryMarket={isSecondary} />;
            }}
          </LayoutRoute>

          <LayoutRoute
            title={intl.formatMessage({ id: 'deals.new' })}
            path={`${path}/deals/basket`}
          >
            <BasketReviewPage />
          </LayoutRoute>

          <LayoutRoute title={intl.formatMessage({ id: 'deals.new' })} path={`${path}/deals`}>
            <NewDealsPage />
          </LayoutRoute>

          <LayoutRoute
            title={intl.formatMessage({ id: 'portfolio' })}
            path={`${path}/portfolio/:market/investments/:id`}
          >
            <InvestmentDetailPage />
          </LayoutRoute>

          <LayoutRoute
            title={intl.formatMessage({ id: 'portfolio' })}
            path={`${path}/portfolio`}
          >
            <PortfolioPage />
          </LayoutRoute>

          <LayoutRoute title={intl.formatMessage({ id: 'settings' })} path={`${path}/settings`}>
            <SettingsPage />
          </LayoutRoute>

          <LayoutRoute exact title={intl.formatMessage({ id: 'support' })} path={`${path}/support`}>
            <SupportPage />
          </LayoutRoute>

          <LayoutRoute
            title={intl.formatMessage({ id: 'transactions' })}
            path={`${path}/transactions`}
          >
            <TransactionsPage />
          </LayoutRoute>

          <LayoutRoute
            title={intl.formatMessage({ id: 'account_balance' })}
            path={`${path}/account-balance`}
          >
            <AccountBalancePage />
          </LayoutRoute>

          <LayoutRoute title={intl.formatMessage({ id: 'not_found' })} path={`${path}/not-found`}>
            <NotFoundPage withPortfolioLink/>
          </LayoutRoute>
        </Switch>
      </KycProvider>
    </NotificationsProvider>
  );
};

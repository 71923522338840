import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  loader: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export function Spinner() {
  const classes = useStyles();
  return (
    <Box width={'100vw'} height={'100vh'} className={classes.loader}>
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <img src="/img/spinner.gif" alt="Spinner" width={200} />
      </Box>
    </Box>
  );
}

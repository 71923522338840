import { trimObject } from 'utils';
import { PrimaryInvestmentFilterValues, PrimaryInvestmentFilterPayload, InvestmentStatusFilterOption } from './types';
import { FilterTransformHelpers } from '../types';
import { ShareStatus } from 'api/investor-client';

class PrimaryInvestmentFiltersHelper
  implements FilterTransformHelpers<PrimaryInvestmentFilterPayload, PrimaryInvestmentFilterValues>
{
  getEmptyValue = (): PrimaryInvestmentFilterValues => {
    return {
      productType: [],
      rating: [],
      currency: [],
      status: undefined
    };
  };

  getShareStatusListByTab = (tab?: InvestmentStatusFilterOption) => {
    switch (tab) {
      case InvestmentStatusFilterOption.Unpaid:
        return [InvestmentStatusFilterOption.Unpaid];
      // case InvestmentStatusFilterOption.Inactive:
      case InvestmentStatusFilterOption.All:
        return undefined;

      case InvestmentStatusFilterOption.Active:
        return [
          ShareStatus.Active,
          ShareStatus.Overdue30Days,
          ShareStatus.Overdue60Days,
          ShareStatus.Overdue120Days,
          ShareStatus.TechnicalDefault
        ];
      case InvestmentStatusFilterOption.Inactive:
        return [
          ShareStatus.Paid
        ];

      case InvestmentStatusFilterOption.Completed:
        return [
          ShareStatus.Finished,
          ShareStatus.Loss,
          ShareStatus.Cancelled,
          ShareStatus.PartialLoss,
        ];

      default:
        return undefined;
    }
  };

  getTabByShareStatus = (status?: string[]) => {
    if (status?.includes(InvestmentStatusFilterOption.Unpaid)) return InvestmentStatusFilterOption.Unpaid;
    if (status && [
      ShareStatus.Finished,
      ShareStatus.Loss,
      ShareStatus.PartialLoss,
      ShareStatus.Cancelled
    ].some(s => status.includes(s))) return InvestmentStatusFilterOption.Completed;
    if (!status?.length) return InvestmentStatusFilterOption.All;
    return InvestmentStatusFilterOption.Active;
  };

  getPayloadFromValue = (values: PrimaryInvestmentFilterValues): PrimaryInvestmentFilterPayload => {
    return trimObject({
      productType: values.productType?.length ? values.productType : undefined,
      rating: values.rating?.length ? values.rating : undefined,
      currency: values.currency?.length ? values.currency : undefined,
      shareStatus: this.getShareStatusListByTab(values.status),
      sortBy: values.sort?.sortBy,
      sortOrder: values.sort?.sortOrder,
    });
  };

  getValueFromPayload = (
    payload: Partial<PrimaryInvestmentFilterPayload>,
  ): PrimaryInvestmentFilterValues => {
    return trimObject({
      productType: Array.isArray(payload.productType) ? payload.productType : [],
      rating: Array.isArray(payload.rating) ? payload.rating : [],
      currency: Array.isArray(payload.currency) ? payload.currency : [],
      status: this.getTabByShareStatus(payload.shareStatus),

      // prettier-ignore
      sort: payload.sortBy && payload.sortOrder
        ? { sortBy: payload.sortBy, sortOrder: payload.sortOrder }
        : undefined,
    });
  };

  countDurationValues = (values: PrimaryInvestmentFilterValues) => {
    let counter = 0;

    if (typeof values.minDuration === 'number') counter += 1;
    if (typeof values.maxDuration === 'number') counter += 1;

    return counter;
  };

  countValues = (values: PrimaryInvestmentFilterValues) => {
    let counter = 0;

    counter += values.currency?.length ?? 0;
    counter += values.productType?.length ?? 0;
    counter += values.rating?.length ?? 0;
    counter += this.countDurationValues(values);
    counter += this.getShareStatusListByTab(values.status)?.length ? 1 : 0;

    return counter;
  };
}

export const primaryInvestmentFiltersHelper = new PrimaryInvestmentFiltersHelper();

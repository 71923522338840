import type {
  BulkCreateInvestmentsResponseDto,
  BulkRequest,
  DealDto,
  ShareCommitmentDto,
  ShareSaleDto,
  TransactionsInfoDto,
} from 'api/investor-client';

export enum DealType {
  CORPORATE = 'CORPORATE',
  PRIVATE = 'PRIVATE',
  REAL_ESTATE = 'REAL_ESTATE',
}

export enum DealRating {
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

/**
 * Current deal statuses from Ledger ream model.
 * Temporary, I believe.
 */
export enum LedgerDealStatus {
  New = 'New',
  Invest = 'Invest',
  Committed = 'Committed',
  Payout = 'Payout',
  Active = 'Active',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Loss = 'Loss',
}

export interface BulkResultWithPayment {
  request: BulkRequest;
  deal: DealDto;
  paymentResult: boolean;
  response: ShareCommitmentDto | ShareSaleDto;
}

export type BulkInvestmentsResponseWithPayment = Modify<
  BulkCreateInvestmentsResponseDto,
  { result: BulkResultWithPayment[] }
>;

export enum PortfolioAllocationInvestmentStatus {
  Committed = 'Committed',
  Investing = 'Investing',
  ReadyToPayout = 'ReadyToPayout',
  OnTime = 'OnTime',
  Overdue30Days = 'Overdue30',
  Overdue60Days = 'Overdue60',
  Overdue90Days = 'Overdue90Days', //remove this?
  Overdue120Days = 'Overdue120',
  TechnicalDefault = 'TechnicalDefault',
  Repaid = 'Repaid',
  Loss = 'Loss',
  NotFinanced = 'NotFinanced',
  Cancelled = 'Cancelled',
}

export interface ExpectedRepayments extends TransactionsInfoDto {
  expectedInterest: number;
  expectedFee: number;
}

import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DealDto } from 'api/investor-client';
import { DetailsSubTabs } from 'components';
import { OverviewSubTab } from './OverviewSubTab';
import { PaymentScheduleSubTab } from './PaymentScheduleSubTab';
import { IDealDetails } from 'utils';
import { ExpectedRepayments } from 'types';

interface Props {
  deal: DealDto;
  dealDetails: IDealDetails;
  totalAmount: number;
  repaymentsData?: ExpectedRepayments;
  loading: boolean;
}

enum ESubTab {
  Overview = 'overview',
  PaymentSchedule = 'payment_schedule',
}

export interface SubTabProps {
  deal: DealDto;
  totalAmount: number;
  repaymentsData?: ExpectedRepayments;
  loading?: boolean;
  isSecondaryMarket?: boolean
}

export const InvestmentInformation = (props: Props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(ESubTab.Overview);

  const subTabs = useMemo(
    () => [
      {
        id: ESubTab.Overview,
        title: intl.formatMessage({ id: ESubTab.Overview }),
        content: OverviewSubTab,
      },
      {
        id: ESubTab.PaymentSchedule,
        title: intl.formatMessage({ id: ESubTab.PaymentSchedule }),
        content: PaymentScheduleSubTab,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage],
  );

  return (
    <DetailsSubTabs
      tabs={subTabs}
      activeTab={activeTab}
      onTabChange={setActiveTab as any}
      tabProps={
        {
          ...props,
        } as SubTabProps
      }
    />
  );
};

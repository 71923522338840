import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, Grid, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  RiAddFill,
  RiMailLine,
  RiNotification4Line,
  RiSettings4Line,
  RiStarLine,
} from 'react-icons/ri';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { FormattedMessage, useIntl } from 'react-intl';

import { UserDealFilterDto } from 'api/investor-client';
import { ENotificationID } from 'api/notifications-client';
import { investorApiClient } from 'api';
import {
  PrimaryDealFilterConfigurationModal,
  PrimaryDealFilterConfigurationValues,
  primaryDealFiltersHelper,
} from 'components/filters/primary-deal';
import { mixinObject } from 'utils';
import { useNotificationConfiguration } from './useNotificationConfiguration';
import { INotificationConfigOption } from './types';
import { DealFilterCard } from './DealFilterCard';
import { NotificationConfigurationCard } from './NotificationConfigurationCard';

const useStyles = makeStyles((theme) => ({
  customNotificationsCard: {
    padding: '24px',
    marginTop: '16px',
    backgroundColor: theme.palette.common.darkBlue,
    borderRadius: theme.shape.borderRadius,
  },

  customNotificationsCardHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: '24px',
    },
  },

  customNotificationsCardHeaderIcon: {
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.common.white,
  },

  customNotificationsCardHeaderDivider: {
    margin: '24px 0',
  },

  customNotificationsCardContent: {
    flexGrow: 1,
    marginBottom: '16px',

    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },

  createNotificationBtn: {
    alignSelf: 'center',
  },
}));

interface Props {}

export const NotificationsTab = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  // prettier-ignore
  const { initialLoading, optionGroups, changeOptions, changeOptionsBulk, findOption } = useNotificationConfiguration();

  // --- Filters section ---

  const [filterModal, setFilterModal] = useState(false);
  const [filters, setFilters] = useState<UserDealFilterDto[]>([]);

  useAsync(async () => {
    const { data } = await investorApiClient.investors.investorApiControllerGetUserDealFilters();
    setFilters(Object.values(data));
  }, []);

  const addDealFilter = useAsyncCallback(async (filter: UserDealFilterDto) => {
    const { data } = await investorApiClient.investors.investorApiControllerUpsertUserDealFilter(
      filter,
    );

    setFilters(Object.values(data));
  });

  const addDealFiltersBulk = useAsyncCallback(async (filters: UserDealFilterDto[]) => {
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerUpsertUserDealFiltersBulk({
      filters: filters,
    });

    setFilters(Object.values(data));
  });

  const deleteDealFilter = useAsyncCallback(async (name: string, updateState = true) => {
    await investorApiClient.investors.investorApiControllerRemoveUserDealFilter(
      encodeURIComponent(name),
    );

    updateState && setFilters((state) => state.filter((f) => f.name !== name));
  });

  const handleAddNewFilter = async (values: PrimaryDealFilterConfigurationValues) => {
    const { name, ...filterData } = values;

    const dealFilterNotification = findOption(ENotificationID.DealFilter);
    const dealFilterNotificationArr = Object.values(dealFilterNotification).filter(
      (notification): notification is INotificationConfigOption => !!notification,
    );
    const dealFilterNotificationEnabled = dealFilterNotificationArr.some((n) => n.checked);

    const filter: UserDealFilterDto = {
      name,
      filters: primaryDealFiltersHelper.getPayloadFromValue(filterData),
      enableNotifications: dealFilterNotificationEnabled,
    };

    return addDealFilter.execute(filter);
  };

  const editDealFilter = useAsyncCallback(
    async (oldFilter: UserDealFilterDto, newFilter: PrimaryDealFilterConfigurationValues) => {
      if (oldFilter.name !== newFilter.name) {
        await deleteDealFilter.execute(oldFilter.name, false);
      }

      await handleAddNewFilter(newFilter);
    },
  );

  const filterNames = useMemo(() => filters.map((f) => f.name), [filters]);

  // --- End Filters section ---
  // --- Notification configuration section ---

  const changeCustomFilterNotificationState = (target: UserDealFilterDto, newState: boolean) => {
    addDealFilter.execute({ ...target, enableNotifications: newState });

    const newFilters = filters.map((filter) => {
      if (filter.name !== target.name) return filter;
      return { ...filter, enableNotifications: newState };
    });

    setFilters(newFilters);

    // Rule: enable `DealFilter` Notifuse notification if it is disabled and one
    // of the custom filters was enabled.
    if (newFilters.some((f) => f.enableNotifications)) {
      const dealFilterNotification = findOption(ENotificationID.DealFilter);
      const dealFilterNotificationArr = Object.values(dealFilterNotification).filter(
        (notification): notification is INotificationConfigOption => !!notification,
      );

      if (dealFilterNotificationArr.every((n) => !n.checked)) {
        changeOptionsBulk({
          ...mixinObject(!!dealFilterNotification.widget, {
            widget: [{ ...dealFilterNotification.widget, checked: true }],
          }),
          ...mixinObject(!!dealFilterNotification.email, {
            email: [{ ...dealFilterNotification.email, checked: true }],
          }),
          ...mixinObject(!!dealFilterNotification.sms, {
            sms: [{ ...dealFilterNotification.sms, checked: true }],
          }),
        });
      }
    }
  };

  // Rule: disable all custom notifications if the `DealFilter` Notifuse notification is disabled.
  useEffect(() => {
    const dealFilterNotification = findOption(ENotificationID.DealFilter);
    const dealFilterNotificationArr = Object.values(dealFilterNotification).filter(
      (notification): notification is INotificationConfigOption => !!notification,
    );

    if (dealFilterNotificationArr.every((n) => !n.checked)) {
      if (filters.some((f) => f.enableNotifications)) {
        const newState = filters.map((filter) => ({ ...filter, enableNotifications: false }));
        setFilters(newState);
        addDealFiltersBulk.execute(newState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionGroups]);

  return (
    <Grid container spacing="24px">
      <Grid item xs={12}>
        <Box className={classes.customNotificationsCard}>
          <Box className={classes.customNotificationsCardHeader}>
            <Box className={classes.customNotificationsCardHeaderIcon}>
              <RiStarLine size={24} />
            </Box>

            <Box className={classes.customNotificationsCardContent}>
              <Typography variant="subtitle2" marginBottom="4px">
                <FormattedMessage id="custom_loan_notifications" />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <FormattedMessage id="custom_loan_notifications.description" />
              </Typography>
            </Box>

            <Button
              variant="outlined"
              startIcon={<RiAddFill />}
              onClick={() => setFilterModal(true)}
              className={classes.createNotificationBtn}
            >
              <FormattedMessage id="create_custom_notification" />
            </Button>
          </Box>

          {filters.length > 0 && (
            <>
              <Divider className={classes.customNotificationsCardHeaderDivider} />

              <Grid container spacing="24px">
                {filters.map((filter) => (
                  <DealFilterCard
                    key={filter.name}
                    filter={filter}
                    filterNames={filterNames}
                    onEditFilter={(values) => editDealFilter.execute(filter, values)}
                    onDeleteFilter={deleteDealFilter.execute}
                    onNotificationChange={(state) => {
                      changeCustomFilterNotificationState(filter, state);
                    }}
                  />
                ))}
              </Grid>
            </>
          )}

          <PrimaryDealFilterConfigurationModal
            open={filterModal}
            takenFilterNames={filterNames}
            onClose={() => setFilterModal(false)}
            onApply={handleAddNewFilter}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} display="flex">
        <NotificationConfigurationCard
          title={intl.formatMessage({ id: 'in_app_notifications' })}
          subtitle={intl.formatMessage({ id: 'in_app_notifications.description' })}
          icon={<RiNotification4Line />}
          options={optionGroups.widget}
          onOptionsChange={(changes) => changeOptions('widget', changes)}
          loading={initialLoading}
        />
      </Grid>

      <Grid item xs={12} md={6} display="flex">
        <NotificationConfigurationCard
          title={intl.formatMessage({ id: 'email_notifications' })}
          subtitle={intl.formatMessage({ id: 'email_notifications.description' })}
          icon={<RiMailLine />}
          options={optionGroups.email}
          onOptionsChange={(changes) => changeOptions('email', changes)}
          loading={initialLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <NotificationConfigurationCard
          title={intl.formatMessage({ id: 'system_notifications' })}
          subtitle={intl.formatMessage({ id: 'system_notifications.description' })}
          icon={<RiSettings4Line />}
        />
      </Grid>
    </Grid>
  );
};

import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ParsedQuery } from 'query-string';
import { Theme, useMediaQuery } from '@mui/material';

import { investorApiClient } from 'api';
import { useBasketProvider } from 'providers/basket';
import {
  SecondaryDealFilterModal,
  SecondaryDealFilterPayload,
  SecondaryDealFilterValues,
  secondaryDealFiltersHelper,
} from 'components/filters/secondary-deal';
import { useQuery, useLoadMoreController } from 'hooks';
import { SecondaryDeal } from 'types/secondary-market';
import { EndOfTableMessage, ScrollToTopButton, TableLayout } from 'components';

import { TableFilters } from '../components';
import { SecondaryDealHeader } from './SecondaryDealHeader';
import { SecondaryDealRecord, SecondaryDealRecordSkeleton } from './SecondaryDealRecord';
// import { downloadCSV } from 'utils';
// import dayjs from 'dayjs';
// import { InvestorApiControllerExportDealsParams } from 'api/investor-client';

interface Props {
  wrapperRef?: HTMLElement | null;
}

export const SecondaryMarketTab = ({ wrapperRef }: Props) => {
  const { parsedQuery, push } = useQuery();
  const basket = useBasketProvider();

  const [data, setData] = useState<SecondaryDeal[]>([]);
  const [continuationToken, setContinuationToken] = useState<string | null>(null);
  const [total, setTotal] = useState<number | null>(null);

  const fetchDeals = useAsyncCallback(async (query: ParsedQuery, fromBeginning?: boolean) => {
    if (!fromBeginning && continuationToken === 'EOF') return;

    if (fromBeginning) {
      setData([]);
      setContinuationToken(null);
      setTotal(null);
    }

    const { data } =
      await investorApiClient.investors.investorApiControllerGetSecondarymarketOffersByContinuationToken({
        ...query,
        continuationToken: fromBeginning ? undefined : continuationToken ?? undefined,
      });

    // fetch request
    const mockedData: SecondaryDeal[] = data.data.map((shareSell) => {
      return {
        ...shareSell,
        id: shareSell.shareSale.id,
        // expiresAt: new Date(Date.now() + Math.abs(getRandomNumber(10_000, 100_000_000_000) | 0)),
      };
    });

    setData((state) => state.concat(mockedData));
    setContinuationToken(data.continuationToken ?? null);
    setTotal((state) => (typeof state === 'number' ? state : data.total));
  });

  // const exportDeals = useAsyncCallback(async (payload: SecondaryDealFilterPayload) => {
  //   const resp = await investorApiClient.investors.investorApiControllerExportDeals(
  //     payload as InvestorApiControllerExportDealsParams,
  //   );

  //   if (resp.data) {
  //     downloadCSV(resp.data, `${dayjs().format('DDMMYYYY')}_marketplace_secondary_deals_export`);
  //   }
  // });

  useDeepCompareEffect(() => {
    fetchDeals.execute(parsedQuery, true);
  }, [parsedQuery]);

  useLoadMoreController({
    wrapperRef: wrapperRef,
    loading: fetchDeals.loading,
    continuationToken: continuationToken,
    fetch: fetchDeals.execute,
    query: parsedQuery,
  });

  const withTableLayout = useMediaQuery<Theme>((theme) => theme.breakpoints.up(1400));

  const dataToDisplay = data.filter((shareSell) => {
    const inBasket = basket.secondary.deals.find((basketDeal) => {
      return basketDeal.id === shareSell.id
    });

    return !inBasket;
  });

  return (
    <>
      <TableFilters<SecondaryDealFilterPayload, SecondaryDealFilterValues>
        components={{
          TableHeader: SecondaryDealHeader,
          AllFiltersModal: SecondaryDealFilterModal,
        }}
        helpers={secondaryDealFiltersHelper}
        initialFilters={parsedQuery}
        onFiltersChange={push}
        // onExportDeals={exportDeals.execute}
        totalDeals={total}
        loading={fetchDeals.loading}
        tableLayout={withTableLayout}
      />

      <TableLayout
        components={{
          Record: SecondaryDealRecord,
          RecordSkeleton: SecondaryDealRecordSkeleton,
        }}
        data={dataToDisplay}
        basketDeals={[]}
        basketLoading={false}
        dataLoading={fetchDeals.loading}
        tableLayout={withTableLayout}
      />

      {continuationToken === 'EOF' && <EndOfTableMessage />}
      <ScrollToTopButton wrapperRef={wrapperRef} />
    </>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

type InType = 'text' | 'password';

const normalizeType = (type?: string): InType => {
  return ['text', 'password'].includes(type!) ? (type as InType) : 'text';
};

export const usePasswordType = (type?: string) => {
  const [originType, setOriginType] = useState(type ?? 'text');
  useEffect(() => setOriginType(type ?? 'text'), [type]);

  const [innerType, setInnerType] = useState<InType>(normalizeType(type));

  const handleClickShowPassword = useCallback(() => {
    setInnerType((t) => (t === 'password' ? 'text' : 'password'));
  }, []);

  return useMemo(() => {
    const isPassword = originType === 'password';
    const t = isPassword ? innerType : originType;

    return {
      type: t,
      inputProps: isPassword
        ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {innerType === 'password' ? <RiEyeLine /> : <RiEyeOffLine />}
                </IconButton>
              </InputAdornment>
            ),
          }
        : {},
    };
  }, [originType, innerType, handleClickShowPassword]);
};

import { ComponentProps, PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { MenuItem as ProMenuItem } from 'react-pro-sidebar';
import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UrlObject } from 'url';
import clsx from 'clsx';

import { ANIMATION_SPEED } from '../constants';
import { ESidebarSize } from '../types';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:not(:last-of-type)': {
      marginBottom: '8px',
    },

    '&.collapsed': {
      '&& > .ps-menu-button': {
        padding: '8px 22px',
        gap: '32px',

        '& > .ps-menu-label': {
          opacity: 0,
        },
      },
    },

    '&.desktop': {
      '&& > .ps-menu-button': {
        padding: '8px 24px',
      },
    },

    '&& > .ps-menu-button': {
      height: 'unset',
      padding: '8px 32px',
      gap: '16px',
      transition: theme.transitions.create(['padding', 'gap', 'background-color'], {
        duration: ANIMATION_SPEED,
      }),

      '&:not(.ps-active):hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
      },

      '&.ps-active': {
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },

      '& > .ps-menu-icon': {
        width: 'unset',
        minWidth: 'unset',
        height: 'unset',
        minHeight: 'unset',
        marginRight: 'unset',
        transition: theme.transitions.create(['color'], {
          duration: ANIMATION_SPEED,
        }),

        '&.ps-active': {
          color: 'white',
        },
      },

      '& > .ps-menu-label': {
        transition: theme.transitions.create(['opacity'], {
          duration: ANIMATION_SPEED,
        }),
      },
    },
  },
}));

type Props = Modify<
  ComponentProps<typeof ProMenuItem>,
  {
    href?: string | UrlObject;
    icon?: React.ReactNode;
    activeMatch?: 'full' | 'partial' | 'start';
    collapsed?: boolean;
    sidebarSize?: ESidebarSize;
  }
>;

export const MenuItem = (props: PropsWithChildren<Props>) => {
  const { href, activeMatch = 'full', collapsed, sidebarSize, className, ...restProps } = props;

  const classes = useStyles();
  const location = useLocation();

  const active = useMemo(() => {
    if (!href) return false;

    switch (activeMatch) {
      case 'full':
        return location.pathname === href.toString();
      case 'partial':
        return location.pathname.includes(href.toString());
      case 'start':
        return location.pathname.startsWith(href.toString());
      default:
        return false;
    }
  }, [href, location.pathname, activeMatch]);

  const isDesktop = sidebarSize === ESidebarSize.Desktop;
  const isCollapsed = collapsed && isDesktop;

  return (
    <ProMenuItem
      component={href ? <Link to={href.toString()} /> : undefined}
      active={active}
      className={clsx(
        classes.menuItem,
        { collapsed: isCollapsed },
        { desktop: isDesktop },
        className,
      )}
      {...restProps}
    />
  );
};

import React, { ChangeEvent, FocusEvent, forwardRef, useCallback } from 'react';
import { FieldProps } from 'formik';
import { IMaskInput } from 'react-imask';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { withFormLabel } from './forms/withFormLabel';
import { usePasswordType } from 'hooks/usePasswordType';
import { IconButton } from '@mui/material';

type MaskOptions = {
  mask: string | RegExp;
  lazy?: boolean;
  unmask?: boolean;
  definitions?: Record<string, string | RegExp>;
};

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    min?: number;
    max?: number;
  } & {
    withMask?: MaskOptions;
    clearButtonFunc?: () => void;
  };
const InputComponent = ({
  disabled,
  multiline,
  field,
  min,
  max,
  error = false,
  type,
  withMask,
  clearButtonFunc,
  onChange,
  onBlur,
  ...props
}: Props): JSX.Element => {
  const { type: inType, inputProps } = usePasswordType(type);

  return (
    <TextField
      multiline={multiline}
      disabled={disabled}
      error={error}
      size="small"
      variant="outlined"
      inputProps={{
        min,
        max,
      }}
      type={inType}
      {...field}
      {...props}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        ...props.InputProps,
        ...inputProps,
      }}
    />
  );
};

type MaskProps = {
  name: string;
  onChange(event: { target: { name: string; value: string } }): void;
  [key: string]: any;
};

const TextMaskCustom = forwardRef<HTMLElement, MaskProps>((props, ref) => {
  const { onChange, ...other } = props;
  other.inputRef = ref;

  return (
    <IMaskInput
      {...other}
      // @ts-ignore: wrong types in `react-imask` lib
      onAccept={(value: string) => {
        if (onChange) onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
});

export const Input = withFormLabel(({ field, ...props }: Props) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (field.onChange) field.onChange(e);
      if (props.onChange) props.onChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onChange, props.onChange],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (field.onBlur) field.onBlur(e);
      if (props.onBlur) props.onBlur(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.onBlur, props.onBlur],
  );

  const additionalProps: Record<string, any> = {};

  if (props.withMask) {
    additionalProps.InputProps = {
      inputComponent: TextMaskCustom,
      inputProps: props.withMask,
    };
  }

  return (
    <>
      <InputComponent
        field={field}
        {...props}
        {...additionalProps}
        onChange={onChange}
        onBlur={onBlur}
      />
      {props.clearButtonFunc && field.value && (
        <IconButton
          onClick={() => {
            props.clearButtonFunc?.();
          }}
          type="button"
          sx={{
            position: 'absolute',
            left: '71%',
            top: '8%',
            transform: 'translate(-50%, 50%)',
          }}
        >
          ×
        </IconButton>
      )}
    </>
  );
});

import { ChangeEvent, useCallback } from 'react';
import { MenuList, MenuListProps } from '@mui/material';

import { useMapOptions } from './useMapOptions';
import { BaseSelectProps } from './types';

type Props<T> = BaseSelectProps<T> & MenuListProps & { multiple?: boolean };

export const InlineSelect = <T,>(props: Props<T>) => {
  const { field, form, meta, options, multiple: isMultiselect, ...restProps } = props;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLUListElement>) => {
      if (field?.onChange) field?.onChange(e);
      if (props.onChange) props.onChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field?.onChange, props.onChange],
  );

  const { list } = useMapOptions(props, !!isMultiselect, onChange);

  return <MenuList {...restProps}>{list}</MenuList>;
};

import { useMemo, useState } from 'react';
import { Grid, Box, Typography, IconButton, Paper, Menu, MenuItem, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiMoreFill } from 'react-icons/ri';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { DealFiltersDto, UserDealFilterDto } from 'api/investor-client';
import {
  PrimaryDealFilterConfigurationModal,
  PrimaryDealFilterConfigurationValues,
  primaryDealFiltersHelper,
} from 'components/filters/primary-deal';
import { formatMoney, moveDecimalPoint } from 'utils';
import { DealRating } from 'types';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '16px',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px',
  },

  headerLabel: {
    flexGrow: 1,
  },

  optionButton: {
    margin: '-8px -8px -8px 0',
  },

  chipContainer: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
  },

  chip: {
    padding: '2px 4px',
    ...(theme.typography.caption as any),
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.lightBlueSurface,
  },
}));

const getLabelsFromFilterValues = (filters: DealFiltersDto, intl: IntlShape) => {
  const labels: string[] = [];

  if (filters.currency?.length) {
    labels.push([...filters.currency].sort((a, b) => b.localeCompare(a)).join(', '));
  }

  if (typeof filters.minPrincipal === 'number' && typeof filters.maxPrincipal === 'number') {
    const minPrincipal = formatMoney(moveDecimalPoint(filters.minPrincipal, -2));
    const maxPrincipal = formatMoney(moveDecimalPoint(filters.maxPrincipal, -2));
    labels.push(`${minPrincipal} - ${maxPrincipal}`);
  } else if (typeof filters.minPrincipal === 'number') {
    labels.push(`> ${formatMoney(moveDecimalPoint(filters.minPrincipal, -2))}`);
  } else if (typeof filters.maxPrincipal === 'number') {
    labels.push(`< ${formatMoney(moveDecimalPoint(filters.maxPrincipal, -2))}`);
  }

  if (filters.rating?.length) {
    const ratings = Object.fromEntries(
      Object.values(DealRating).map((rating, index) => [rating, index]),
    );

    labels.push([...filters.rating].sort((a, b) => ratings[a] - ratings[b]).join(', '));
  }

  if (filters.productType?.length) {
    labels.push(
      filters.productType
        .map((pt) => intl.formatMessage({ id: `borrower.${pt}` }))
        .sort((a, b) => b.localeCompare(a))
        .join(', '),
    );
  }

  const monthsStr = intl.formatMessage({ id: 'month.short' });

  if (typeof filters.minDuration === 'number' && typeof filters.maxDuration === 'number') {
    labels.push(`${filters.minDuration}-${filters.maxDuration}${monthsStr}`);
  } else if (typeof filters.minDuration === 'number') {
    labels.push(`> ${filters.minDuration}${monthsStr}`);
  } else if (typeof filters.maxDuration === 'number') {
    labels.push(`< ${filters.maxDuration}${monthsStr}`);
  }

  return labels;
};

interface Props {
  filter: UserDealFilterDto;
  filterNames: string[];
  onEditFilter?: (values: PrimaryDealFilterConfigurationValues) => void;
  onDeleteFilter?: (name: string) => void;
  onNotificationChange?: (newState: boolean) => void;
}

export const DealFilterCard = (props: Props) => {
  const { filter, filterNames, onEditFilter, onDeleteFilter, onNotificationChange } = props;

  const classes = useStyles();
  const intl = useIntl();

  const [filterModal, setFilterModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const chips = useMemo(() => {
    return getLabelsFromFilterValues(filter.filters, intl);
  }, [filter.filters, intl]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setFilterModal(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    onDeleteFilter?.(filter.name);
    handleMenuClose();
  };

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={0} className={classes.card}>
        <Box className={classes.header}>
          <Typography variant="subtitle3" className={classes.headerLabel} noWrap>
            {filter.name}
          </Typography>

          <Switch
            checked={filter.enableNotifications}
            onChange={(e) => onNotificationChange?.(e.target.checked)}
          />

          <IconButton
            className={classes.optionButton}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <RiMoreFill size={16} />
          </IconButton>

          <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
            <MenuItem onClick={handleEdit}>
              <FormattedMessage id="edit" />
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <FormattedMessage id="delete" />
            </MenuItem>
          </Menu>

          <PrimaryDealFilterConfigurationModal
            open={filterModal}
            initialValues={{
              name: filter.name,
              ...primaryDealFiltersHelper.getValueFromPayload(filter.filters),
            }}
            takenFilterNames={filterNames}
            onClose={() => setFilterModal(false)}
            onApply={(data) => onEditFilter?.(data)}
          />
        </Box>

        <Box className={classes.chipContainer}>
          {chips.map((chip, i) => (
            <Box key={i} className={classes.chip}>
              {chip}
            </Box>
          ))}
        </Box>
      </Paper>
    </Grid>
  );
};

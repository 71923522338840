import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Alert, Box, Button, CircularProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';

import { SearchResultInvestor } from 'api/document-client';
import { DealEventDto, DealStatusDto, InvestmentWithDealDto, ShareStatus } from 'api/investor-client';
import { documentApiClient, investorApiClient } from 'api';
import Card from 'components/card';
import { InlineTooltip } from 'components/UI';
import { DataOverviewTable, ITableRow, Loader, Table } from 'components';
import { useFormatMoney } from 'hooks';
import { DealEventsToShow, downloadFile, getInvestmentRepaymentInfo, mixinArray, moveDecimalPoint } from 'utils';

interface Props {
  investment: InvestmentWithDealDto;
}

const useStyles = makeStyles(() => ({
  gridCell: {
    '&.MuiGrid-item': {
      maxWidth: 'fill-available',
    },
  },
  button: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 2,
    },
  },
  milestoneHeader: {
    fontWeight: 600,
  },
}));

export const InvestmentDetails = ({ investment }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { accountId } = useParams<{ accountId: string }>();
  const formatMoney = useFormatMoney({
    prefix: `${investment.deal.currency} `,
  });

  const repaymentDetails = useMemo(() => {
    return getInvestmentRepaymentInfo(investment);
  }, [investment]);

  const { result: events = [] } = useAsync(async () => {
    const { data } = await investorApiClient.investors.investorApiControllerGetInvestmentEvents({
      investmentId: investment.id,
      types: DealEventsToShow,
    });
    return data;
  }, [investment]);

  let lostPrincipal = 0;
  investment.transactions
    .filter((tr) => tr.type === 'Default')
    .forEach((tr) => {
      lostPrincipal += tr.principal;
    });

  const loadInvestorAgreement = useAsync(async () => {
    const { data } = await documentApiClient.api.investorApiControllerList({
      searchCriteria: {
        documentType: 'Investor Agreements',
        fileNamePartial: `${investment.deal.id}`,
      },
      orderCriteria: [{ name: 'created', order: 'DESC' }],
    });
    return (data as unknown as SearchResultInvestor)?.files?.[0];
  }, []);

  const downloadContract = useAsyncCallback(async () => {
    if (!loadInvestorAgreement?.result?.id) {
      toast.error(intl.formatMessage({ id: 'error.cant_find_agreement' }));
    } else {
      const { data } = await documentApiClient.api.investorApiControllerDownload(
        loadInvestorAgreement.result.id,
        {
          format: 'arraybuffer',
        },
      );
      downloadFile(data as unknown as string, loadInvestorAgreement.result.name);
    }
  });

  const repaymentDetailsRows = useMemo((): ITableRow[] => {
    const lastRepaymentDateRows = [
      ...mixinArray(!!repaymentDetails.actualLastRepaymentDate, [
        {
          cols: [
            intl.formatMessage({ id: 'date_last_payment' }),
            dayjs(repaymentDetails.actualLastRepaymentDate).format('DD.MM.YYYY'),
          ],
        },
      ]),
    ];

    if (investment.status === ShareStatus.Loss || investment.status === ShareStatus.PartialLoss) {
      return [
        {
          cols: [
            intl.formatMessage({ id: 'loss_amount' }),
            formatMoney(moveDecimalPoint(lostPrincipal, -2)),
          ],
        },
        {
          cols: [
            intl.formatMessage({ id: 'default_date' }),
            dayjs(investment.deal.lossDate).format('DD.MM.YYYY'),
          ],
        },
        ...lastRepaymentDateRows,
      ];
    }

    return [
      ...mixinArray(
        investment.deal.status !== DealStatusDto.Completed &&
        !!repaymentDetails.nextRepaymentDate,
        [
          {
            cols: [
              intl.formatMessage({ id: 'schedule_date_next_payment' }),
              dayjs(repaymentDetails.nextRepaymentDate).format('DD.MM.YYYY'),
            ],
            variant: 'highlighted',
          },
        ],
      ),
      ...mixinArray(
        investment.deal.status !== DealStatusDto.Completed &&
        repaymentDetails.nextRepaymentAmount !== null,
        [
          {
            cols: [
              intl.formatMessage({ id: 'next_payment_amount' }),
              formatMoney(moveDecimalPoint(repaymentDetails.nextRepaymentAmount!, -2)),
            ],
          },
        ],
      ),
      ...lastRepaymentDateRows,
      ...mixinArray(!!repaymentDetails.repaymentAmountInDelay, [
        {
          cols: [
            intl.formatMessage({ id: 'amount_in_delay' }),
            formatMoney(moveDecimalPoint(repaymentDetails.repaymentAmountInDelay!, -2)),
          ],
        },
      ]),
      ...mixinArray(!!repaymentDetails.firstMissedRepaymentDate, [
        {
          cols: [
            intl.formatMessage({ id: 'first_missed_payment_date' }),
            dayjs(repaymentDetails.firstMissedRepaymentDate).format('DD.MM.YYYY'),
          ],
        },
      ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repaymentDetails, intl.formatMessage, formatMoney]);

  const returnsRows = useMemo((): ITableRow[] => {
    return [
      ...mixinArray(!!investment.xirr, [
        {
          cols: [
            intl.formatMessage({ id: 'irr.expected_net' }),
            `${(investment.xirr * 100).toFixed(4)}%`,
          ],
        },
      ]),
      ...mixinArray(!!investment.actualXirr, [
        {
          cols: [
            intl.formatMessage({ id: 'irr.actual_net' }),
            `${(investment.actualXirr! * 100).toFixed(4)}%`,
          ],
        },
      ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment, intl.formatMessage]);

  const paymentOverviewRows = useMemo((): ITableRow[] => {
    const repaymentTransactions = investment.repayments.map((rep) => rep.transaction);

    const bookedTransactions = investment.repayments
      .filter((rep) => rep.isBooked)
      .map((rep) => rep.transaction);
    const defaultedTransactions = investment.repayments
      .filter((rep) => rep.isLost)
      .map((rep) => rep.transaction);

    const tAmortization = investment.amount;
    const bAmortization = bookedTransactions.reduce((sum, next) => {
      return sum + next.principal;
    }, 0);
    const dAmortization = defaultedTransactions.reduce((sum, next) => {
      return sum + next.principal;
    }, 0);
    const oAmortization = tAmortization - bAmortization - dAmortization;

    const tInterest = repaymentTransactions.reduce((sum, next) => {
      return sum + next.interest;
    }, 0);
    const bInterest = bookedTransactions.reduce((sum, next) => {
      return sum + next.interest;
    }, 0);
    const dInterest = defaultedTransactions.reduce((sum, next) => {
      return sum + next.interest;
    }, 0);
    const oInterest = tInterest - bInterest - dInterest;

    const tOverdueInterest = repaymentTransactions.reduce((sum, next) => {
      return sum + next.overdueInterest;
    }, 0);
    const bOverdueInterest = bookedTransactions.reduce((sum, next) => {
      return sum + next.overdueInterest;
    }, 0);
    const dOverdueInterest = defaultedTransactions.reduce((sum, next) => {
      return sum + next.overdueInterest;
    }, 0);
    const oOverdueInterest = tOverdueInterest - bOverdueInterest - dOverdueInterest;

    const tOtherIncome = repaymentTransactions.reduce((sum, next) => {
      return sum + next.other;
    }, 0);
    const bOtherIncome = bookedTransactions.reduce((sum, next) => {
      return sum + next.other;
    }, 0);

    const dOtherIncome = defaultedTransactions.reduce((sum, next) => {
      return sum + next.other;
    }, 0);
    const oOtherIncome = tOtherIncome - bOtherIncome - dOtherIncome;

    const tOtherExpenses = repaymentTransactions.reduce((sum, next) => {
      return sum + next.totalExpenses;
    }, 0);
    const bOtherExpenses = bookedTransactions.reduce((sum, next) => {
      return sum + next.totalExpenses;
    }, 0);

    const dOtherExpenses = defaultedTransactions.reduce((sum, next) => {
      return sum + next.totalExpenses;
    }, 0);
    const oOtherExpenses = tOtherExpenses - bOtherExpenses - dOtherExpenses;

    const tTotal = tAmortization + tInterest + tOverdueInterest + tOtherIncome + tOtherExpenses;
    const bTotal = bAmortization + bInterest + bOverdueInterest + bOtherIncome + bOtherExpenses;
    const oTotal = oAmortization + oInterest + oOverdueInterest + oOtherIncome + oOtherExpenses;
    const dTotal = dAmortization + dInterest + dOverdueInterest + dOtherIncome + dOtherExpenses;
    const isLoss =
      investment.status === 'Loss' ||
      dAmortization ||
      dInterest ||
      dOverdueInterest ||
      dOtherIncome ||
      dOtherExpenses;

    return [
      {
        cols: [
          null,
          intl.formatMessage({ id: 'total' }),
          intl.formatMessage({ id: 'booked' }),
          intl.formatMessage({ id: 'open' }),
          ...(isLoss ? [intl.formatMessage({ id: 'loss' })] : []),
        ],
        variant: 'header',
      },
      {
        cols: [
          intl.formatMessage({
            id: 'amortization',
          }),
          formatMoney(moveDecimalPoint(tAmortization, -2)),
          formatMoney(moveDecimalPoint(bAmortization, -2)),
          formatMoney(moveDecimalPoint(oAmortization, -2)),
          ...(isLoss ? [formatMoney(moveDecimalPoint(dAmortization, -2))] : []),
        ],
        variant: 'highlighted',
      },
      {
        cols: [
          intl.formatMessage({ id: 'interests' }),
          formatMoney(moveDecimalPoint(tInterest, -2)),
          formatMoney(moveDecimalPoint(bInterest, -2)),
          formatMoney(moveDecimalPoint(oInterest, -2)),
          ...(isLoss ? [formatMoney(moveDecimalPoint(dInterest, -2))] : []),
        ],
      },
      {
        cols: [
          intl.formatMessage({ id: 'interests.overdue' }),
          formatMoney(moveDecimalPoint(tOverdueInterest, -2)),
          formatMoney(moveDecimalPoint(bOverdueInterest, -2)),
          formatMoney(moveDecimalPoint(oOverdueInterest, -2)),
          ...(isLoss ? [formatMoney(moveDecimalPoint(dOverdueInterest, -2))] : []),
        ],
      },
      {
        cols: [
          <>
            {intl.formatMessage({ id: 'other_incomes' })}{' '}
            <InlineTooltip
              tooltip={intl.formatMessage({
                id: 'tooltip.summary_of_additional_incomes',
              })}
            />
          </>,
          formatMoney(moveDecimalPoint(tOtherIncome, -2)),
          formatMoney(moveDecimalPoint(bOtherIncome, -2)),
          formatMoney(moveDecimalPoint(oOtherIncome, -2)),
          ...(isLoss ? [formatMoney(moveDecimalPoint(dOtherIncome, -2))] : []),
        ],
      },
      {
        cols: [
          <>
            {intl.formatMessage({ id: 'expenditures' })}{' '}
            <InlineTooltip
              tooltip={intl.formatMessage({
                id: 'tooltip.summary_of_fees',
              })}
            />
          </>,
          formatMoney(moveDecimalPoint(tOtherExpenses, -2)),
          formatMoney(moveDecimalPoint(bOtherExpenses, -2)),
          formatMoney(moveDecimalPoint(oOtherExpenses, -2)),
          ...(isLoss ? [formatMoney(moveDecimalPoint(dOtherExpenses, -2))] : []),
        ],
      },
      {
        cols: [
          intl.formatMessage({
            id: 'total_net_repayment',
          }),
          formatMoney(moveDecimalPoint(tTotal, -2)),
          formatMoney(moveDecimalPoint(bTotal, -2)),
          formatMoney(moveDecimalPoint(oTotal, -2)),
          ...(isLoss ? [formatMoney(moveDecimalPoint(dTotal, -2))] : []),
        ],
        variant: 'highlighted',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMoney, investment, intl.formatMessage]);

  const columns = useMemo(() => {
    return [
      {
        Header: (
          <span className={classes.milestoneHeader}>{intl.formatMessage({ id: 'date' })}</span>
        ),
        accessor: 'createdDate',
        Cell: function Cell({ value }: CellProps<DealEventDto>) {
          return <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />;
        },
      },
      {
        Header: (
          <span className={classes.milestoneHeader}>{intl.formatMessage({ id: 'milestone' })}</span>
        ),
        accessor: 'type',
        Cell: function Cell({ value }: CellProps<DealEventDto>) {
          return intl.formatMessage({ id: `milestone.${value}` });
        },
      },
      {
        Header: (
          <span className={classes.milestoneHeader}>
            {intl.formatMessage({ id: 'additional_information' })}
          </span>
        ),
        accessor: 'json.description',
        Cell: function Cell({ value }: CellProps<DealEventDto>) {
          if (!value) return null;
          if (typeof value === 'string') {
            return value;
          } else {
            let recoveryObj =
              value[intl.locale] || value['en-CH'] || (Object.values(value)[0] as any);
            return recoveryObj?.name && recoveryObj.text
              ? `${recoveryObj?.name}: ${recoveryObj?.text}`
              : JSON.stringify(value);
          }
        },
      },
    ];
  }, [intl, classes.milestoneHeader]);

  return (
    <Grid container spacing={4}>
      <Grid item md={6}>
        <Grid container direction="column" spacing={4}>
          <Grid item className={classes.gridCell}>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'repayment.details' })}
              rows={repaymentDetailsRows}
              emptyMessage={
                <Alert severity="warning" color="info">
                  <FormattedMessage id="warning.no_info" />
                </Alert>
              }
            />
          </Grid>
          <Grid item className={classes.gridCell}>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'returns' })}
              rows={returnsRows}
              emptyMessage={
                <Alert severity="warning" color="info">
                  <FormattedMessage id="warning.no_info" />
                </Alert>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6}>
        <Grid container direction="column" spacing={4}>
          <Grid item className={classes.gridCell}>
            <DataOverviewTable
              title={intl.formatMessage({
                id: 'payment_overview',
              })}
              rows={paymentOverviewRows}
            />
          </Grid>
          <Grid item className={classes.gridCell}>
            <Card>
              <Card.Header variant="condensed" title={intl.formatMessage({ id: 'documents' })} />
              <Card.Body>
                {loadInvestorAgreement.loading ? (
                  <CircularProgress />
                ) : !loadInvestorAgreement.result ? (
                  <Box
                    sx={{
                      background: '#bdbdbd',
                      color: '#f5f5f5',
                      padding: '10px',
                      borderRadius: '4px',
                      fontSize: '14px',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => history.push(`/accounts/${accountId}/support`)}
                  >
                    <FormattedMessage id="contract_not_available" />{' '}
                    <FormattedMessage id="contact_support">
                      {(txt) => <u>{txt}</u>}
                    </FormattedMessage>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={downloadContract.execute}
                  >
                    <FormattedMessage id="button.download_contract" />
                    {downloadContract.loading && <Loader />}
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12}>
        <Grid container direction="column" spacing={4}>
          <Grid item className={classes.gridCell}>
            <Card>
              <Card.Header
                variant="condensed"
                title={intl.formatMessage({ id: 'activity_timeline' })}
              />
              <Card.Body>
                <Table<DealEventDto & { [key: string]: any }>
                  initialFilters={[]}
                  initialSort={[]}
                  withPagination={false}
                  onFiltersChange={() => {}}
                  columns={columns}
                  data={events}
                  count={events.length}
                  sortable={false}
                />
              </Card.Body>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

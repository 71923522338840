import { trimObject } from 'utils';
import { SecondaryInvestmentFilterValues, SecondaryInvestmentFilterPayload } from './types';
import { FilterTransformHelpers } from '../types';
import { ShareSaleStatus } from 'types';

class SecondaryInvestmentFiltersHelper
  implements FilterTransformHelpers<SecondaryInvestmentFilterPayload, SecondaryInvestmentFilterValues>
{
  getEmptyValue = (): SecondaryInvestmentFilterValues => {
    return {
      productType: [],
      rating: [],
      currency: [],
      havingSaleStatus: ShareSaleStatus.Open
    };
  };

  getPayloadFromValue = (values: SecondaryInvestmentFilterValues): SecondaryInvestmentFilterPayload => {
    return trimObject({
      productType: values.productType?.length ? values.productType : undefined,
      rating: values.rating?.length ? values.rating : undefined,
      currency: values.currency?.length ? values.currency : undefined,
      havingSaleStatus: values.havingSaleStatus ? [values.havingSaleStatus] : [ShareSaleStatus.Open],
      sortBy: values.sort?.sortBy,
      sortOrder: values.sort?.sortOrder,
    });
  };

  getValueFromPayload = (
    payload: Partial<SecondaryInvestmentFilterPayload>,
  ): SecondaryInvestmentFilterValues => {
    return trimObject({
      productType: Array.isArray(payload.productType) ? payload.productType : [],
      rating: Array.isArray(payload.rating) ? payload.rating : [],
      currency: Array.isArray(payload.currency) ? payload.currency : [],
      havingSaleStatus: Array.isArray(payload.havingSaleStatus) ? payload.havingSaleStatus : [ShareSaleStatus.Open],

      // prettier-ignore
      sort: payload.sortBy && payload.sortOrder
        ? { sortBy: payload.sortBy, sortOrder: payload.sortOrder }
        : undefined,
    });
  };

  countDurationValues = (values: SecondaryInvestmentFilterValues) => {
    let counter = 0;

    if (typeof values.minDuration === 'number') counter += 1;
    if (typeof values.maxDuration === 'number') counter += 1;

    return counter;
  };

  countValues = (values: SecondaryInvestmentFilterValues) => {
    let counter = 0;

    counter += values.currency?.length ?? 0;
    counter += values.productType?.length ?? 0;
    counter += values.rating?.length ?? 0;
    counter += this.countDurationValues(values);
    counter += values.havingSaleStatus === ShareSaleStatus.Sold ? 1 : 0;

    return counter;
  };
}

export const secondaryInvestmentFiltersHelper = new SecondaryInvestmentFiltersHelper();

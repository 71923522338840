import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useKycProvider } from 'providers/kyc';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  task: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    gap: '24px',
    padding: '24px',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '4px',
    border: `2px solid ${theme.palette.primary.dark}`,
    color: theme.palette.common.white,
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color', 'opacity'], {
      duration: 150,
    }),

    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

interface Props {
  wrapper?: (body: JSX.Element) => JSX.Element;
}

export const UncompletedTaskGroups = ({ wrapper = (body) => body }: Props) => {
  const kycProvider = useKycProvider();
  const classes = useStyles();

  if (!kycProvider.uncompletedTaskGroups.length) {
    return null;
  }

  const body = (
    <Card variant="page">
      <CardHeader
        title={
          <>
            <Typography variant="subtitle2" marginBottom="8px">
              <FormattedMessage id="complete_onboarding_tasks" />
            </Typography>
            <Typography variant="body2" color="text.secondary" maxWidth="500px">
              <FormattedMessage id="complete_onboarding_tasks.description" />
            </Typography>
          </>
        }
        style={{ marginLeft: '16px' }}
      />

      <CardContent>
        <Grid container spacing={4}>
          {kycProvider.uncompletedTaskGroups.map(({ link, title, description, srcLogo }) => (
            <Grid key={link} item xs={12} md={4} style={{ paddingLeft: '8px' }}>
              <Box
                className={classes.task}
                onClick={() => kycProvider.setUnfinishedTaskGroupLink(link)}
              >
                <img src={srcLogo} alt={srcLogo} loading="lazy" />
                <Box overflow={'hidden'}>
                  <Typography noWrap fontWeight={'500'}>
                    {title}
                  </Typography>
                  <Typography noWrap>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );

  return wrapper(body);
};

import { useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCloseLine } from 'react-icons/ri';
import { useAsyncCallback } from 'react-async-hook';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';

import { paymentsApiClient } from 'api';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { WithDialogPros, withDialog } from 'wrappers';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';
import { AmountInput } from 'components';

import { IbanDisplay, useDialogStyles } from '../shared';

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    position: 'relative',
    padding: '48px 24px',
    background: theme.palette.background.barkSurface004,
    textAlign: 'center',
  },

  closeIcon: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },

  dialogContent: {
    padding: '24px 24px 32px',
  },

  addAllButton: {
    ...(theme.typography.subtitle2 as any),
  },

  submitButton: {
    ...(theme.typography.subtitle2 as any),
  },
}));

interface Props extends WithDialogPros {
  currency: Currency;
  bankAccount?: string;
  onReload: () => Promise<void>;
}

export const WithdrawModal = withDialog(
  ({ onClose, onReload, bankAccount, currency }: Props) => {
    const classes = useStyles();
    const intl = useIntl();
    const { selectedAccount, updateAccountBalance } = useInvestorAccounts();
    const formatMoney = useFormatMoney({ prefix: `${currency} ` });

    const [amount, setAmount] = useState(0);

    const handleWithdraw = useAsyncCallback(async () => {
      try {
        await paymentsApiClient.api.investorApiControllerWithdrawAmount({
          [currency]: moveDecimalPoint(amount, 2),
        });

        toast.success(intl.formatMessage({ id: 'withdrawal.success' }));
        onReload();
      } finally {
        updateAccountBalance();
        onClose?.();
      }
    });

    const balance = moveDecimalPoint(
      selectedAccount?.VABalance?.find((e1) => e1['currency'] === currency)?.amount ?? 0,
      -2,
    );

    return (
      <>
        <Box className={classes.dialogHeader}>
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <RiCloseLine />
          </IconButton>

          <Typography variant="subtitle2" marginBottom="32px">
            <FormattedMessage id="withdraw" />
          </Typography>

          <AmountInput
            amount={amount}
            setAmount={setAmount}
            currency={currency}
            disabled={handleWithdraw.loading}
          />

          <Typography variant="body2" color="text.secondary" marginTop="8px" marginBottom="32px">
            {formatMoney(balance)} <FormattedMessage id="available" />
          </Typography>

          <Button
            variant="text"
            className={classes.addAllButton}
            onClick={() => setAmount(balance)}
          >
            <FormattedMessage id="add_all" />
          </Button>
        </Box>

        <Box className={classes.dialogContent}>
          {bankAccount && (
            <IbanDisplay
              currency={currency}
              iban={bankAccount}
              label={<FormattedMessage id="transfer_to" />}
            />
          )}

          <Button
            fullWidth
            className={classes.submitButton}
            onClick={handleWithdraw.execute}
            disabled={!bankAccount || !amount || handleWithdraw.loading}
            startIcon={handleWithdraw.loading && <CircularProgress size={18} color="inherit" />}
          >
            <FormattedMessage id="withdraw" />
          </Button>
        </Box>
      </>
    );
  },
  undefined,
  useDialogStyles,
);

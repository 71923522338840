import { useLayoutEffect, useState } from 'react';
import { Tabs, Theme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';

import { ProfileTab } from './ProfileTab';
import { AccountTab } from './AccountTab';
import Card from 'components/card';
import { PageContainer } from 'components/UI';
import { TabLink } from 'components';
import { KycTab } from './KycTab';
import { NotificationsTab } from './NotificationsTab';
import { makeStyles } from '@mui/styles';

enum ETab {
  Profile = 'profile',
  Account = 'account',
  Kyc = 'kyc',
  Notifications = 'notifications',
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: '12px 16px 0 12px',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const SettingsPage = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();
  const [tab, setTab] = useState<ETab>();

  useLayoutEffect(() => {
    if (`${url}/${ETab.Profile}` === pathname) {
      setTab(ETab.Profile);
    } else if (`${url}/${ETab.Account}` === pathname) {
      setTab(ETab.Account);
    } else if (`${url}/${ETab.Kyc}` === pathname) {
      setTab(ETab.Kyc);
    } else if (`${url}/${ETab.Notifications}` === pathname) {
      setTab(ETab.Notifications);
    } else {
      history.replace(`${url}/${ETab.Profile}`);
    }
  }, [pathname, setTab, url, history]);

  return tab ? (
    <PageContainer>
      <Card>
        <Card.Header sx={{ overflowY: 'auto' }} wrapperClassName={classes.header}>
          <Tabs
            value={tab}
            classes={{
              indicator: classes.indicator,
            }}
          >
            <TabLink
              href={`${url}/${ETab.Profile}`}
              label={<FormattedMessage id="profile" />}
              value={ETab.Profile}
            />
            <TabLink
              href={`${url}/${ETab.Account}`}
              label={<FormattedMessage id="account" />}
              value={ETab.Account}
            />
            <TabLink href={`${url}/${ETab.Kyc}`} label={'KYC'} value={ETab.Kyc} />
            <TabLink
              href={`${url}/${ETab.Notifications}`}
              label={<FormattedMessage id="notifications" />}
              value={ETab.Notifications}
            />
          </Tabs>
        </Card.Header>

        <Card.Body>
          <Switch>
            <Route exact path={`${path}/${ETab.Profile}`}>
              <ProfileTab />
            </Route>
            <Route exact path={`${path}/${ETab.Account}`}>
              <AccountTab />
            </Route>
            <Route exact path={`${path}/${ETab.Kyc}`}>
              <KycTab />
            </Route>
            <Route exact path={`${path}/${ETab.Notifications}`}>
              <NotificationsTab />
            </Route>
          </Switch>
        </Card.Body>
      </Card>
    </PageContainer>
  ) : null;
};

export const DealEventsToShow = [
  'InvestmentCommittedEvent',
  'InvestmentCancelledEvent',
  'DealCancelledEvent',
  'DealNotFinancedEvent',
  'DealMarkedAsLossEvent',
  'InvestmentPaidEvent',
  'DealPaidEvent',
  'DealFinishedEvent',
  'RecoveryUpdate',
];
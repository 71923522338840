import type { ChartOptions } from 'chart.js';

import { formatBigNumber, formatMoney } from 'utils';

export const options: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },

    tooltip: {
      mode: 'index',
      boxPadding: 4,
      titleFont: { size: 12 },

      itemSort: function (a, b) {
        return (a.raw as number) - (b.raw as number);
      },

      callbacks: {
        title(items) {
          const item = items[0];
          const labels = item.chart.data.labels;
          if (!labels) return item.label;

          const label = labels[item.dataIndex] as MaybeArray<string>;
          if (!label) return item.label;

          return Array.isArray(label) ? label.join(', ') : label;
        },

        label(item) {
          const value = item.dataset.data[item.dataIndex] ?? 0;
          return `${item.dataset.label}: ${formatMoney(value)}`;
        },
      },
    },

    datalabels: {
      anchor: 'end',
      align: 'end',
      offset: 4,
      color: '#333333',
      font: { size: 12 },

      formatter(value: number, context) {
        let total = value;

        context.chart.data.datasets.forEach((dataset) => {
          if (dataset === context.dataset) return;
          const value = (dataset.data[context.dataIndex] as number) ?? 0;
          total += value;
        });

        return formatMoney(total);
      },

      display(context) {
        const currentOrder = context.dataset.order;
        if (currentOrder === undefined) return true;

        let highestOrder = 0;

        context.chart.data.datasets.forEach((dataset) => {
          if (dataset.order !== undefined && dataset.order > highestOrder) {
            highestOrder = dataset.order;
          }
        });

        return currentOrder >= highestOrder;
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#333333',

        callback(tickValue, index, ticks) {
          const labels = this.chart.data.labels;
          if (!labels) return tickValue;

          const label = labels[index] as MaybeArray<string>;
          if (!label) return tickValue;
          if (typeof label === 'string') return label;

          return label.map((v, index) => {
            if (v.endsWith(',') || index + 1 === label.length) return v;
            return `${v},`;
          });
        },
      },
      grid: {
        borderDash: [5, 5],
        color: '#cccccc',
        tickWidth: 0,
        tickLength: 2,
        drawBorder: false,
      },
    },
    y: {
      grace: '25%',
      ticks: {
        color: '#333333',

        callback: (label) => {
          return typeof label === 'string' ? label : formatBigNumber(label, 2);
        },
      },
      grid: {
        borderDash: [5, 5],
        color: '#cccccc',
        tickWidth: 0,
        tickLength: 12,
        drawBorder: false,
      },
    },
  },
};

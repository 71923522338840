import { useMemo, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ParsedQuery } from 'query-string';
import { Theme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';

import { investorApiClient } from 'api';
import { IDealDto, InvestorApiControllerExportDealsParams } from 'api/investor-client';
import { useBasketProvider } from 'providers/basket';
import {
  PrimaryDealFilterModal,
  PrimaryDealFilterPayload,
  PrimaryDealFilterValues,
  primaryDealFiltersHelper,
} from 'components/filters/primary-deal';
import { useQuery, useLoadMoreController } from 'hooks';
import { downloadCSV } from 'utils';
import { EndOfTableMessage, ScrollToTopButton, TableLayout } from 'components';

import { TableFilters } from '../components';
import { PrimaryDealHeader } from './PrimaryDealHeader';
import { PrimaryDealRecord, PrimaryDealRecordSkeleton } from './PrimaryDealRecord';

interface Props {
  wrapperRef?: HTMLElement | null;
}

export const PrimaryMarketTab = ({ wrapperRef }: Props) => {
  const { parsedQuery, push } = useQuery();
  const basket = useBasketProvider();

  const [basketDeals, setBasketDeals] = useState<IDealDto[]>([]);
  const [deals, setDeals] = useState<IDealDto[]>([]);
  const [continuationToken, setContinuationToken] = useState<string | null>(null);
  const [totalDeals, setTotalDeals] = useState<number | null>(null);

  const dealIds = useMemo(() => {
    return basket.primary.deals.map((deal) => ({
      dealId: deal.id,
      providerId: deal.providerId!,
    }));
  }, [basket.primary.deals]);

  const fetchBasketDeals = useAsync(async () => {
    if (dealIds.length === 0) return setBasketDeals([]);

    const allDeals = basketDeals.concat(deals);
    const newBasketDeals: IDealDto[] = [];
    let canSetWithoutRequest = true;

    for (const dealId of dealIds) {
      let dealExists = allDeals.find(
        (d) => d.id === dealId.dealId && d.providerId === dealId.providerId,
      );

      if (dealExists) {
        newBasketDeals.push(dealExists);
      } else {
        canSetWithoutRequest = false;
        break;
      }
    }

    if (canSetWithoutRequest) {
      setBasketDeals(newBasketDeals);
      return;
    }

    const { data } = await investorApiClient.investors.investorApiControllerGetBulkDeal({
      deals: dealIds,
      withXirr: true,
    });

    setBasketDeals(data);
  }, [dealIds]);

  const fetchDeals = useAsyncCallback(async (query: ParsedQuery, fromBeginning?: boolean) => {
    if (!fromBeginning && continuationToken === 'EOF') return;

    if (fromBeginning) {
      setDeals([]);
      setContinuationToken(null);
      setTotalDeals(null);
    }

    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetDealsByContinuationToken({
      ...query,
      withXirr: true,
      continuationToken: fromBeginning ? undefined : continuationToken ?? undefined,
    });

    setDeals((state) => state.concat(data.data));
    setContinuationToken(data.continuationToken ?? null);
    setTotalDeals((state) => (typeof state === 'number' ? state : data.total));
  });

  const exportDeals = useAsyncCallback(async (payload: PrimaryDealFilterPayload) => {
    const resp = await investorApiClient.investors.investorApiControllerExportDeals(
      payload as InvestorApiControllerExportDealsParams,
    );

    if (resp.data) {
      downloadCSV(resp.data, `${dayjs().format('DDMMYYYY')}_marketplace_primary_deals_export`);
    }
  });

  useDeepCompareEffect(() => {
    fetchDeals.execute(parsedQuery, true);
  }, [parsedQuery]);

  useLoadMoreController({
    wrapperRef: wrapperRef,
    loading: fetchDeals.loading,
    continuationToken: continuationToken,
    fetch: fetchDeals.execute,
    query: parsedQuery,
  });

  const withTableLayout = useMediaQuery<Theme>((theme) => theme.breakpoints.up(1400));

  const dealsToDisplay = deals.filter((deal) => {
    const inBasket = basketDeals.find((basketDeal) => {
      return basketDeal.id === deal.id && basketDeal.providerId === deal.providerId;
    });

    return !inBasket;
  });

  return (
    <>
      <TableFilters<PrimaryDealFilterPayload, PrimaryDealFilterValues>
        components={{
          TableHeader: PrimaryDealHeader,
          AllFiltersModal: PrimaryDealFilterModal,
        }}
        helpers={primaryDealFiltersHelper}
        initialFilters={parsedQuery}
        onFiltersChange={push}
        onExportDeals={exportDeals.execute}
        totalDeals={totalDeals}
        loading={fetchDeals.loading || fetchBasketDeals.loading}
        tableLayout={withTableLayout}
      />

      <TableLayout
        components={{
          Record: PrimaryDealRecord,
          RecordSkeleton: PrimaryDealRecordSkeleton,
        }}
        data={dealsToDisplay}
        basketDeals={basketDeals}
        basketLoading={fetchBasketDeals.loading && !basketDeals.length ? !!dealIds.length : false}
        dataLoading={fetchDeals.loading}
        tableLayout={withTableLayout}
      />

      {continuationToken === 'EOF' && <EndOfTableMessage />}
      <ScrollToTopButton wrapperRef={wrapperRef} />
    </>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, Theme, Typography } from '@mui/material';
import { matchPath, useHistory, useLocation, useRouteMatch } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import urljoin from 'url-join';

import { WithLayoutPageTitle } from 'providers/layout-provider';
import { EMarketTypeBasket, useBasketProvider } from 'providers/basket';
import { CardLoader, PageContainer } from 'components/UI';
import { INavigationTabOption, useDealAmountInput, useSelectedInvestorAccount, useTabNavigation } from 'hooks';

import { InvestmentConfirmationModal } from './InvestmentConfirmationModal';
import { EmptyBasketMessage } from './EmptyBasketMessage';
import { PrimaryMarketBasketContent } from './primary-market';
import { SecondaryMarketBasketContent } from './secondary-market';
import { useCalculatePaymentDetailsColOffset, useInitiatePrimaryDeals, useInitiateSecondaryDeals } from './hooks';
import { InvestmentConfirmationModalOptionsWithType } from './InvestmentConfirmationModal/types';
import { MarketType } from 'types/secondary-market';
import { addAgreementsToCache, getAgreementsFromCache } from 'providers/basket/helpers';

const GRID_PADDING = '16px';

export enum EBasketTab {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

const PRIMARY_BASKET_URL_PATH = 'primary';
const SECONDARY_BASKET_URL_PATH = 'secondary';

interface StyleProps {
  paymentDetailsColOffset: number | null;
}

// @ts-ignore: old type defs
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    minHeight: `calc(100% + ${GRID_PADDING})`,
  },

  investmentsCard: {
    flexGrow: 1,

    [theme.breakpoints.down('sm')]: {
      background: 'transparent',
      borderColor: 'transparent !important',
    },
  },

  summaryCol: {
    position: 'sticky',
    top: (props: StyleProps) => `calc(${props.paymentDetailsColOffset ?? 0}px + ${GRID_PADDING})`,

    '& > div': {
      marginLeft: '16px',
      marginRight: '16px',
    },

    [theme.breakpoints.down('sm')]: {
      position: 'initial',
    },

    [theme.breakpoints.up('md')]: {
      minHeight: (props: StyleProps) => {
        const offset = props.paymentDetailsColOffset ?? 0;
        return `calc(100vh - ${offset}px - (${GRID_PADDING} * 2))`;
      },
    },
  },
}));

export const BasketReviewPage = () => {
  const { setPaymentDetailsCol, paymentDetailsColOffset } = useCalculatePaymentDetailsColOffset();
  const classes = useStyles({ paymentDetailsColOffset });
  const intl = useIntl();

  const location = useLocation();
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const basket = useBasketProvider();

  const [modalState, setModalState] = useState<InvestmentConfirmationModalOptionsWithType | null>(null);
  const [paymentDetailsRef, setPaymentDetailsRef] = useState<HTMLElement | null>(null);
  const { selectedAccount} = useSelectedInvestorAccount();

  const { primaryDeals, primaryDealsInitiallyFetched } = useInitiatePrimaryDeals();
  const { secondaryDeals, secondaryDealsInitiallyFetched } = useInitiateSecondaryDeals();
  const initiallyFetched = primaryDealsInitiallyFetched && secondaryDealsInitiallyFetched;
  const anyDeals = !!basket.primary.deals.length || !!basket.secondary.deals.length;
  const [primaryDealAgreements, setPrimaryDealAgreements] = useState<Record<string, {id: string, name: string}>>({});
  const [secondaryDealAgreements, setSecondaryDealAgreements] = useState<Record<string, {id: string, name: string}>>({});


  useEffect(() => {
    setPrimaryDealAgreements(getAgreementsFromCache(EMarketTypeBasket.Primary, selectedAccount?.investor?.id))
    setSecondaryDealAgreements(getAgreementsFromCache(EMarketTypeBasket.Secondary, selectedAccount?.investor?.id))
  }, [selectedAccount?.investor?.id]);

  const getHandleAgreementsAddCallback = (type: EMarketTypeBasket) => useCallback(
    (data: {
      agreement: {id: string, name: string},
      totalAmount: number,
      providerId?: string,
      dealId: string
    }[]) => {
      let agreementsToCache = addAgreementsToCache(
        type,
        selectedAccount?.investor?.id,
        data);
      type === EMarketTypeBasket.Primary ? setPrimaryDealAgreements(
        agreementsToCache) : setSecondaryDealAgreements(agreementsToCache);
    },
    [selectedAccount?.investor?.id],
  );

  const tabNavigationOptions = useMemo((): INavigationTabOption<EBasketTab>[] => {
    const primaryDealsLength = basket.primary.deals.length;
    const secondaryDealsLength = basket.secondary.deals.length;

    return [
      {
        name: EBasketTab.Primary,
        urlPath: PRIMARY_BASKET_URL_PATH,
        tabLabel: intl.formatMessage({ id: 'market.primary' }) + ` (${primaryDealsLength})`,
        tabProps: { disabled: primaryDealsLength === 0 },
        disabled: primaryDealsLength === 0,
        component: (
          <PrimaryMarketBasketContent
            deals={primaryDeals}
            agreements={primaryDealAgreements}
            handleAgreementsAdd={getHandleAgreementsAddCallback(EMarketTypeBasket.Primary)}
            openSummaryModal={(payload) => setModalState({ type: MarketType.primary, ...payload })}
            paymentDetailsContainer={paymentDetailsRef}
          />
        ),
      },
      {
        name: EBasketTab.Secondary,
        urlPath: SECONDARY_BASKET_URL_PATH,
        tabLabel: intl.formatMessage({ id: 'market.secondary' }) + ` (${secondaryDealsLength})`,
        tabProps: { disabled: secondaryDealsLength === 0 },
        disabled: secondaryDealsLength === 0,
        component: (
          <SecondaryMarketBasketContent
            deals={secondaryDeals}
            agreements={secondaryDealAgreements}
            handleAgreementsAdd={getHandleAgreementsAddCallback(EMarketTypeBasket.Secondary)}
            openSummaryModal={(payload) => setModalState({ type: MarketType.secondary, ...payload })}
            paymentDetailsContainer={paymentDetailsRef}
          />
        ),
      },
    ];
  }, [
    intl,
    primaryDeals,
    secondaryDeals,
    basket.primary.deals,
    basket.secondary.deals,
    paymentDetailsRef,
    primaryDealAgreements,
    secondaryDealAgreements,
    getHandleAgreementsAddCallback
  ]);

  const { tabsComponent, routeNavigationComponent } = useTabNavigation(tabNavigationOptions);

  const handleOpenBasketTab = useCallback(() => {
    const primaryBasketUrl = urljoin(routeMatch.path, PRIMARY_BASKET_URL_PATH);
    const isPrimaryBasketUrl = matchPath(location.pathname, primaryBasketUrl);
    if (isPrimaryBasketUrl && basket.primary.deals.length > 0) return;

    const secondaryBasketUrl = urljoin(routeMatch.path, SECONDARY_BASKET_URL_PATH);
    const isSecondaryBasketUrl = matchPath(location.pathname, secondaryBasketUrl);
    if (isSecondaryBasketUrl && basket.secondary.deals.length > 0) return;

    if (basket.primary.deals.length > 0) {
      history.replace(PRIMARY_BASKET_URL_PATH);
    } else if (basket.secondary.deals.length > 0) {
      history.replace(SECONDARY_BASKET_URL_PATH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    basket.primary.deals,
    basket.secondary.deals
  ]);

  // Force change a tab is the basket is empty
  useEffect(() => {
    if (!initiallyFetched) return;
    handleOpenBasketTab();
  }, [initiallyFetched, handleOpenBasketTab]);

  const handleConfirmationModalClose = useCallback(() => {
    if (basket.primary.deals?.length || basket.secondary.deals?.length) {
      handleOpenBasketTab();
      setModalState(null);
    } else {
      history.push('../deals');
    }
  }, [
    history,
    basket.primary.deals,
    basket.secondary.deals,
    handleOpenBasketTab
  ]);

  return (
    <PageContainer>
      <WithLayoutPageTitle title={intl.formatMessage({ id: 'investment_bag' })} />

      <Grid container spacing={'16px'} className={classes.container}>
        {initiallyFetched ? (
          anyDeals ? (
            // Page is loaded and has deals to display
            <>
              <Grid item xs={12} md={7} lg={8} sx={{ display: 'flex' }}>
                <Card variant="page" className={classes.investmentsCard}>
                  <CardHeader
                    title={
                      <>
                        {tabsComponent}
                        <Divider />
                      </>
                    }
                  />

                  <CardContent>{routeNavigationComponent}</CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={5} lg={4} ref={setPaymentDetailsCol}>
                <Card variant="page" className={classes.summaryCol}>
                  <CardHeader
                    title={
                      <Typography variant="subtitle1" fontWeight={500}>
                        <FormattedMessage id="payment_details" />
                      </Typography>
                    }
                  />

                  <CardContent ref={setPaymentDetailsRef} />
                  <CardLoader loading={!initiallyFetched} />
                </Card>
              </Grid>
            </>
          ) : (
            // Page is loaded but the basket is empty
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <EmptyBasketMessage />
            </Grid>
          )
        ) : (
          // Page is loading (fetching deals)
          <>
            <Grid item xs={12} md={7} lg={8} sx={{ display: 'flex' }}>
              <Card variant="page" className={classes.investmentsCard}>
                <CardLoader loading />
              </Card>
            </Grid>

            <Grid item xs={12} md={5} lg={4} ref={setPaymentDetailsCol}>
              <Card variant="page" className={classes.summaryCol}>
                <CardLoader loading />
              </Card>
            </Grid>
          </>
        )}
      </Grid>

      <InvestmentConfirmationModal
        isOpen={!!modalState}
        onClose={handleConfirmationModalClose}
        {...modalState}
      />
    </PageContainer>
  );
};

import { FieldValidator } from 'formik';

export * from './not-empty';
export * from './valid-email';
export * from './password';

export const groupValidators = <T = any>(
  ...validators: FieldValidator[]
): FieldValidator => {
  return async (value: T) => {
    for (let i = 0; i < validators.length; i++) {
      const result = await validators[i](value);
      if (typeof result === 'string') return result;
    }
  };
};

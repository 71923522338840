import { useEffect, useState } from 'react';
import { Box, Fab, Fade, styled } from '@mui/material';
import { RiArrowUpLine } from 'react-icons/ri';

const SContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 'calc(16px + var(--app-header, 0px))',
  display: 'flex',
  justifyContent: 'flex-end',

  [theme.breakpoints.up('sm')]: {
    bottom: 'calc(28px + var(--app-header, 0px))',
  },
}));

const SFab = styled(Fab)({
  position: 'absolute',
  bottom: 0,
});

interface Props {
  wrapperRef?: HTMLElement | null;
}

export const ScrollToTopButton = ({ wrapperRef }: Props) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (!wrapperRef) return;

    const listener = () => {
      if (wrapperRef.scrollTop > 350) {
        display === false && setDisplay(true);
      } else {
        display === true && setDisplay(false);
      }
    };

    wrapperRef.addEventListener('scroll', listener);
    document.addEventListener('resize', listener);

    return () => {
      wrapperRef.removeEventListener('scroll', listener);
      document.removeEventListener('resize', listener);
    };
  }, [display, wrapperRef]);

  const handleClick = () => {
    wrapperRef?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <SContainer>
      <Fade in={display} onClick={handleClick}>
        <SFab>
          <RiArrowUpLine size={24} />
        </SFab>
      </Fade>
    </SContainer>
  );
};

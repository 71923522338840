import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Sidebar as ProSidebar, useProSidebar } from 'react-pro-sidebar';
import { Box, Modal, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useLocallySavedState } from 'hooks';
import { MenuFab } from './components';
import { SidebarBody } from './SidebarBody';
import { SidebarFooter } from './SidebarFooter';
import { SidebarHeader } from './SidebarHeader';
import { ANIMATION_SPEED, COLLAPSED_WIDTH, EXPANDED_WIDTH } from './constants';
import { ESidebarSize } from './types';

const useStyles = makeStyles((theme) => ({
  sidebarDrawer: {
    transition: `width ${ANIMATION_SPEED}ms`,
  },

  sidebar: {
    '&&': {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
      border: 'none',
      zIndex: 1101,

      '&.mobile': {
        width: 'unset',
        minWidth: 'unset',
      },

      '&.desktop': {
        left: 'unset',
        right: 'unset',
        bottom: 'unset',
        position: 'sticky',
        height: '100vh',
      },

      '& > .ps-sidebar-container': {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
      },
    },
  },

  mouseCapture: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 40,
  },
}));

interface Props {}

export const Sidebar = (props: Props) => {
  const classes = useStyles();

  // --- Collapsed/Closed mode handler ---

  const [open, setOpen] = useState(false);
  // prettier-ignore
  const [localCollapsed, setLocalCollapsed] = useLocallySavedState<boolean>('sidebar-collapsed', false);
  const { collapsed, collapseSidebar } = useProSidebar();
  const [expandedByCursor, setExpandedByCursor] = useState(false);

  const handleCollapseSidebar = (
    collapsedState?: boolean | undefined,
    type?: 'default' | 'cursor',
  ) => {
    if (type === 'cursor') {
      const newState = collapsedState ?? !collapsed;
      setExpandedByCursor(!newState);
      setLocalCollapsed(newState);
      return collapseSidebar(newState);
    }

    if (expandedByCursor === false || collapsed) {
      setLocalCollapsed(collapsedState ?? false);
      collapseSidebar(collapsedState);
    }

    setExpandedByCursor(false);
  };

  const initialCall = useRef(false);
  useLayoutEffect(() => {
    if (initialCall.current) setLocalCollapsed(collapsed);
    initialCall.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  const forceCollapse = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));
  const previousCollapsedState = useRef<boolean | null>(null);
  useLayoutEffect(() => {
    if (forceCollapse && !collapsed) {
      collapseSidebar(true);
      previousCollapsedState.current = false;
    } else if (!forceCollapse && previousCollapsedState.current === false) {
      collapseSidebar(false);
      previousCollapsedState.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceCollapse]);

  // --- END Collapsed/Closed mode handler ---
  const isDesktopSize = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const sidebarSize = isDesktopSize ? ESidebarSize.Desktop : ESidebarSize.Mobile;

  useEffect(() => {
    if (sidebarSize === ESidebarSize.Desktop && open) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarSize]);

  const showSidebar = sidebarSize === ESidebarSize.Mobile ? open : true;
  const drawerExpanded =
    collapsed === false &&
    localCollapsed === false &&
    forceCollapse === false &&
    expandedByCursor === false;

  return (
    <>
      {expandedByCursor && (
        <Box
          className={classes.mouseCapture}
          onMouseOver={() => {
            expandedByCursor && handleCollapseSidebar(true, 'cursor');
          }}
        />
      )}

      {showSidebar && (
        <Box
          className={classes.sidebarDrawer}
          width={drawerExpanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH}
        >
          <ProSidebar
            defaultCollapsed={localCollapsed}
            collapsedWidth={`${COLLAPSED_WIDTH}px`}
            width={`${EXPANDED_WIDTH}px`}
            transitionDuration={ANIMATION_SPEED}
            onMouseEnter={() => {
              collapsed && handleCollapseSidebar(undefined, 'cursor');
            }}
            onMouseLeave={() => {
              expandedByCursor && handleCollapseSidebar(undefined, 'cursor');
            }}
            className={clsx(classes.sidebar, {
              desktop: sidebarSize === ESidebarSize.Desktop,
              mobile: sidebarSize === ESidebarSize.Mobile,
            })}
          >
            <SidebarHeader
              sidebarSize={sidebarSize}
              collapsed={localCollapsed}
              expandedByHover={expandedByCursor}
              onCollapseClick={handleCollapseSidebar}
              hideExpandButton={forceCollapse}
            />

            <SidebarBody
              sidebarSize={sidebarSize}
              collapsed={localCollapsed}
              onSidebarClose={
                sidebarSize === ESidebarSize.Mobile ? () => setOpen(false) : undefined
              }
            />

            <SidebarFooter
              sidebarSize={sidebarSize}
              collapsed={localCollapsed}
              onSidebarClose={
                sidebarSize === ESidebarSize.Mobile
                  ? () => setOpen(false)
                  : expandedByCursor
                    ? () => handleCollapseSidebar(true, 'cursor')
                    : undefined
              }
            />
          </ProSidebar>

          {/**
           * Workaround to disable scrollbar on `body` element.
           * MUI has a good alg. but doesn't export it.
           */}
          {sidebarSize === ESidebarSize.Mobile && (
            <Modal component="div" open hideBackdrop sx={{ display: 'none' }}>
              <></>
            </Modal>
          )}
        </Box>
      )}

      {sidebarSize === ESidebarSize.Mobile && (
        <MenuFab active={open} onClick={() => setOpen((s) => !s)} />
      )}
    </>
  );
};

import { styled } from '@mui/material';

const SContainer = styled('div')(({ theme }) => ({
  padding: '4px 8px',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid',
  backgroundColor: '#4D4D4D0A',

  ...theme.typography.caption,
  fontWeight: 500,
}));

interface Props {
  coefficient: number;
}

export const DiscountChip = ({ coefficient }: Props) => {
  return (
    <SContainer>
      {coefficient >= 0 ? '+' : '-'}
      {(Math.abs(coefficient) * 100).toFixed()}%
    </SContainer>
  );
};

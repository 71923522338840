import { Box, CSSObject, styled, Typography } from '@mui/material';

import { mixinObject, restrictProp } from 'utils';
import { FormattedMessage } from 'react-intl';
import { DealStatusDto, InvestmentWithDealDto, ShareStatus } from 'api/investor-client';

interface Props {
  type?: InvestmentWithDealDto['type'];
  status?: InvestmentWithDealDto['status'];
  dealStatus?: InvestmentWithDealDto['deal']['status'];
}

type TStatusType = 'Commitment' | 'Overdue' | 'Active' | 'Inactive';

const getStatusType = ({ type, status }: Props): TStatusType => {
  if (!status || status === 'Unpaid' || type === 'Commitment') return 'Commitment';
  const investmentType = [
    "Committed",
    "Investing",
    "ReadyToPayout",
    "OnTime",
    "Active",
    "Finished"
  ];

  if (investmentType.includes(status)) return 'Active';
  if (status === 'Paid') return 'Inactive';
  return 'Overdue';
};

const SWrapper = styled(Box, {
  shouldForwardProp: restrictProp(['statusType']),
})<{ statusType: TStatusType }>(({ theme, statusType }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 8px',
  borderRadius: theme.shape.borderRadius,
  borderWidth: '1px',
  borderStyle: 'solid',

  ...mixinObject<CSSObject>(statusType === 'Overdue', {
    backgroundColor: 'rgb(255, 237, 234)',
    color: 'rgb(186, 26, 26)',
    borderColor: 'rgb(186, 26, 26)'
  }),

  ...mixinObject<CSSObject>(statusType === 'Active', {
    backgroundColor: 'rgb(242, 253, 244)',
    color: 'rgb(35, 164, 56)',
    borderColor: 'rgb(35, 164, 56)'
  }),

  ...mixinObject<CSSObject>(statusType === 'Commitment', {
    backgroundColor: 'rgb(253, 250, 241)',
    color: 'rgb(211, 133, 23)',
    borderColor: 'rgb(211, 133, 23)'
  }),
  ...mixinObject<CSSObject>(statusType === 'Inactive', {
    backgroundColor: 'rgb(253, 253, 253)',
    color: 'rgb(130, 130, 130)',
    borderColor: 'rgb(130, 130, 130)'
  }),

}));
const getMessageSuffix = (dealStatus?: DealStatusDto, shareStatus?: ShareStatus) => {
  if(shareStatus !== ShareStatus.Paid){
    return shareStatus;
  }
  if (dealStatus === DealStatusDto.Invest) {
    return 'deal-investing';
  } else if (dealStatus === DealStatusDto.Committed) {
    return 'deal-committed';
  } else if (dealStatus === DealStatusDto.Payout) {
    return 'deal-payout';
  } else if (dealStatus === DealStatusDto.Start) {
    return 'deal-starting';
  } else {
    return 'Paid';
  }
};
export const InvestmentStatusChip = ({ type, status, dealStatus }: Props) => {
  const messageId =
    type === 'Commitment'
      ? 'investment.status.committed'
      : `investment.status.${getMessageSuffix( dealStatus, status)}`;

  return (
    <SWrapper statusType={getStatusType({ type, status })}>
      <Typography variant="body2" noWrap>
        <FormattedMessage id={messageId} />
      </Typography>
    </SWrapper>
  );
};

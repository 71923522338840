import { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { RiArrowDownSFill, RiBriefcaseLine } from 'react-icons/ri';
import clsx from 'clsx';

import { useBasketProvider } from 'providers/basket';
import {
  CurrencyIcon,
  DiscountChip,
  InlineTooltip,
  ProductTypeChip,
  RiskChip,
} from 'components/UI';
import {
  DurationDisplay,
  // ExpirationTimer
} from 'components';
import { useFormatMoney, useLink, useSelectedInvestorAccount } from 'hooks';
import { DealRating, DealType } from 'types';
import { SecondaryDeal } from 'types/secondary-market';
import { moveDecimalPoint } from 'utils';

import { useTableRowStyles } from '../styles';

interface Props {
  record: SecondaryDeal;
  isReadonly?: boolean;
  tableLayout?: boolean;
}

interface ViewComponentProps extends Props {
  addDealToBasket(): void;
}

const useCardViewStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },
  },

  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 16px 24px',
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,

    '&.in-basket': {
      borderColor: '#00639A1C',
    },
  },

  expirationTimer: {
    fontWeight: 500,
  },

  accordionBtn: {
    height: 'fit-content',
    marginTop: '-8px',
    marginRight: '-4px',
    transition: theme.transitions.create(['transform'], {
      duration: 150,
    }),

    '&.expanded': {
      transform: 'rotate(180deg)',
    },
  },

  chipsGroup: {
    display: 'flex',
    gap: '8px',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    padding: '24px 16px',
    backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.16)}`,
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
  },

  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexBasis: '40%',
    overflow: 'hidden',
  },

  textGroupHeader: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    textTransform: 'uppercase',
  },

  tooltip: {
    color: alpha(theme.palette.text.secondary, 0.36),
    fontSize: '16px',
    lineHeight: '16px',
  },

  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px 16px 16px',
  },
}));

const SecondaryDealRecordCardView = (props: ViewComponentProps) => {
  const { record: shareSell, addDealToBasket } = props;

  const classes = useCardViewStyles();
  const intl = useIntl();
  const getLink = useLink();
  const formatMoney = useFormatMoney({ prefix: `${shareSell.shareWithDeal.deal?.currency} ` });

  const [expanded, setExpanded] = useState(false);

  const { isViewOnly } = useSelectedInvestorAccount();

  const isTabletSize = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'), { noSsr: true });
  const isExpanded = isTabletSize ? true : expanded;

  return (
    <Box className={classes.card}>
      <Box component={Link} to={getLink(`${shareSell.shareWithDeal.deal?.providerId}/${shareSell.shareWithDeal.deal?.id}?amount=${shareSell.shareSale.saleAmount}`, 'continue-url')}>
        <Box className={classes.cardHeader}>
          <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
            {/* <Box display="flex"> */}
            {/* <Typography variant="subtitle3" color="text.secondary" noWrap>
                <ExpirationTimer
                  expiredAt={shareSell.expiresAt}
                  className={classes.expirationTimer}
                />
              </Typography> */}

            {/* <Typography variant="subtitle3" color="text.secondary" fontWeight={400} noWrap>
                {'\u00a0'}
                <FormattedMessage id="left" />
              </Typography> */}
            {/* </Box> */}

            {!isTabletSize && (
              <Box>
                <IconButton
                  size="small"
                  className={clsx(classes.accordionBtn, { expanded: isExpanded })}
                  onClick={(e) => {
                    e.preventDefault();
                    setExpanded((state) => !state);
                  }}
                >
                  <RiArrowDownSFill />
                </IconButton>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Box sx={{ flexGrow: 1, textDecoration: 'underline', overflow: 'hidden' }}>
              <Typography variant="subtitle2" noWrap>
                {shareSell.shareWithDeal.id + '_' + shareSell.id}
              </Typography>
            </Box>

            <Box className={classes.chipsGroup}>
              <CurrencyIcon currency={shareSell.shareWithDeal.deal?.currency} size={24} />
              <RiskChip rating={shareSell.shareWithDeal.deal?.risk.rating as DealRating} />
              <ProductTypeChip productType={shareSell.shareWithDeal.deal?.risk.productType as DealType} />
            </Box>
          </Box>
        </Box>

        <Collapse in={isExpanded}>
          <Box className={classes.cardBody}>
            <Box className={classes.row}>
              <Box className={classes.textGroup}>
                <Box display="flex" alignItems="center" gap="8px">
                  <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                    <FormattedMessage id="nominal_value" />
                  </Typography>

                  <InlineTooltip
                    tooltip={intl.formatMessage({ id: 'nominal_value.description_tooltip' })}
                    className={classes.tooltip}
                  />
                </Box>

                <Box display="flex" gap="0px 4px" alignItems="center" flexWrap="wrap">
                  <Typography variant="subtitle2" noWrap>
                    {formatMoney(moveDecimalPoint(shareSell.shareSale.saleAmount, -2))}
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.textGroup}>
                <Box display="flex" alignItems="center" gap="8px">
                  <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                    <FormattedMessage id="interest" />
                  </Typography>

                  <InlineTooltip
                    tooltip={intl.formatMessage({ id: 'interest.description_tooltip' })}
                    className={classes.tooltip}
                  />
                </Box>

                <Box display="flex" gap="0 4px" alignItems="center" flexWrap="wrap">
                  <Typography variant="subtitle2" noWrap>
                    {`+ ${(shareSell.shareWithDeal.deal.nominalInterestRate * 100).toFixed(2)}%`}
                  </Typography>

                  <Typography variant="body2" color="text.secondary" noWrap>
                    ({formatMoney(moveDecimalPoint(shareSell.shareSale.potentialInterest, -2))})
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={classes.row}>
              <Box className={classes.textGroup} sx={{ flexBasis: '100% !important' }}>
                <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                  <FormattedMessage id="terms_left" />
                </Typography>

                <DurationDisplay deal={shareSell.shareWithDeal.deal} size="normal" />
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Box className={classes.cardFooter}>
        <Box className={classes.row}>
          <Box className={classes.textGroup} sx={{ flexBasis: '100% !important' }}>
            <Box display="flex" alignItems="center" gap="8px">
              <Typography variant="caption" className={classes.textGroupHeader} noWrap>
                <FormattedMessage id="deal_price" />
              </Typography>

              {/*<InlineTooltip*/}
              {/*  tooltip={intl.formatMessage({ id: 'deal_price.description_tooltip' })}*/}
              {/*  className={classes.tooltip}*/}
              {/*/>*/}
            </Box>

            <Box display="flex" gap="0 8px" alignItems="center" flexWrap="wrap">
              <Typography variant="subtitle2" noWrap>
                {formatMoney(moveDecimalPoint(shareSell.shareSale.latestCalculatedPrice, -2))}
              </Typography>

              <DiscountChip coefficient={shareSell.shareSale.priceMultiplier - 1} />
            </Box>
          </Box>
        </Box>

        {!isViewOnly && (
          <Button fullWidth startIcon={<RiBriefcaseLine size={18} />} onClick={addDealToBasket}>
            <FormattedMessage id="add_to_basket" />
          </Button>
        )}
      </Box>
    </Box>
  );
};

const useRowViewStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
    overflow: 'hidden',
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 150,
    }),

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.04),
    },
  },

  dealTooltip: {
    maxWidth: 'fit-content',
  },

  linkContainer: {
    display: 'flex',
    flexGrow: 1,
    cursor: 'pointer',
  },

  rowWrapper: {
    width: '100%',
    display: 'flex',
  },

  cellDivider: {
    borderLeft: `1px solid ${alpha(theme.palette.text.secondary, 0.36)}`,
  },

  submitBtn: {
    minWidth: 'auto',
    padding: '8px 16px',
  },
}));

const SecondaryDealRecordRowView = (props: ViewComponentProps) => {
  const { record: shareSell, addDealToBasket } = props;

  const tableRowClasses = useTableRowStyles();
  const classes = useRowViewStyles();
  const intl = useIntl();
  const getLink = useLink();

  const { isViewOnly } = useSelectedInvestorAccount();
  const formatMoney = useFormatMoney({ prefix: `${shareSell.shareWithDeal.deal?.currency} ` });

  return (
    <Box className={clsx(classes.card)}>
      <Tooltip
        title={`${intl.formatMessage({ id: 'deal.id' })}: ${shareSell.shareWithDeal.id + '_' + shareSell.id}`}
        componentsProps={{
          tooltip: { className: classes.dealTooltip },
          popper: { modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] },
        }}
        followCursor
      >
        <Link
          to={getLink(`${shareSell.shareWithDeal.deal?.providerId}/${shareSell.shareWithDeal.deal?.id}?amount=${shareSell.shareSale.saleAmount}`, 'continue-url')}
          className={classes.linkContainer}
        >
          <Box className={classes.rowWrapper}>
            <Box className={clsx(tableRowClasses.cell, tableRowClasses.dealPriceCell)}>
              <Typography variant="subtitle2" noWrap>
                {formatMoney(moveDecimalPoint(shareSell.shareSale.latestCalculatedPrice ?? 0, -2))}
              </Typography>
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.percentCell)}>
              <DiscountChip coefficient={shareSell.shareSale.priceMultiplier - 1} />
            </Box>

            <Box
              className={clsx(tableRowClasses.cell, tableRowClasses.riskCell, classes.cellDivider)}
            >
              <RiskChip rating={shareSell.shareWithDeal.deal?.risk.rating as DealRating} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.productTypeCell)}>
              <ProductTypeChip productType={shareSell.shareWithDeal.deal?.risk.productType as DealType} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.nominalValueCell)}>
              <Typography variant="subtitle3" noWrap>
                {formatMoney(moveDecimalPoint(shareSell.shareSale.saleAmount ?? 0, -2))}
              </Typography>
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.timeLeftCell)}>
              <DurationDisplay deal={shareSell.shareWithDeal.deal} />
            </Box>

            <Box className={clsx(tableRowClasses.cell, tableRowClasses.irrCell)}>
              <Typography variant="subtitle3" noWrap flexShrink={0}>
                {`+ ${+(shareSell.shareWithDeal.deal.nominalInterestRate * 100).toFixed(2)}%\u00a0`}
              </Typography>
              <Typography variant="body2" noWrap>
                ({formatMoney(moveDecimalPoint(shareSell.shareSale.potentialInterest ?? 0, -2))})
              </Typography>
            </Box>

            {/* <Box className={clsx(tableRowClasses.cell, tableRowClasses.expirationCell)}>
              <ExpirationTimer expiredAt={shareSell.expiresAt} />
            </Box> */}
          </Box>

          {isViewOnly && <Box className={clsx(tableRowClasses.cell, tableRowClasses.inputCell)} />}
        </Link>
      </Tooltip>

      {!isViewOnly && (
        <Box className={clsx(tableRowClasses.cell, tableRowClasses.inputCell)}>
          <Button className={classes.submitBtn} onClick={addDealToBasket}>
            <RiBriefcaseLine size={18} />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const SecondaryDealRecord = (props: Props) => {
  const { record: shareSell } = props;

  const intl = useIntl();
  const basket = useBasketProvider();

  const addDealToBasket = () => {
    let shareSale = shareSell.shareSale;
    basket.secondary.setDeal({
      id: shareSell.id,
      currency: shareSell.shareWithDeal.deal?.currency,
      amount: shareSale.saleAmount,
      multiplierAmount: shareSale.multiplierAmount,
      latestCalculatedPrice: shareSale.latestCalculatedPrice,
      accruedInterest: shareSale.accruedInterest,
      buyerFeeAmount: shareSale.buyerFeeAmount,
    });

    toast.success(intl.formatMessage({ id: 'deal.details.select_investment_success' }));
  };

  const ViewComponent = props.tableLayout
    ? SecondaryDealRecordRowView
    : SecondaryDealRecordCardView;

  return <ViewComponent {...props} addDealToBasket={addDealToBasket} />;
};

import { IntlShape } from 'react-intl';
import {
  TransactionType,
  TransactionMethodExtended,
  DealType,
  LedgerDealStatus,
  DealRating,
  Currency,
} from 'types';

export const getTransactionTypeOptions = (intl: IntlShape) => {
  return Object.values(TransactionType).map((val) => ({
    label: intl.formatMessage({ id: `transaction_type.${val}` }),
    value: val,
  }));
};

export const getTransactionMethodOptions = (intl: IntlShape) => {
  return Object.values(TransactionMethodExtended).map((val) => ({
    label: intl.formatMessage({ id: `transaction_method.${val}` }),
    value: val,
  }));
};

export const getProductTypeOptions = (intl: IntlShape) => {
  return Object.values(DealType).map((val) => ({
    label: intl.formatMessage({ id: `borrower.${val}` }),
    value: val,
  }));
};

export const getDealStatusOptions = (intl: IntlShape) => {
  // FIXME: return `DealStatus` when the statuses would be fixed
  return Object.values(LedgerDealStatus).map((val) => ({
    label: intl.formatMessage({ id: `deal.status.${val}` }),
    value: val,
  }));
};

export const getRatingOptions = () => {
  return Object.values(DealRating).map((e) => ({
    value: e,
    label: e,
  }));
};

export const getCurrencyOptions = () => {
  return Object.values(Currency).map((e) => ({
    value: e,
    label: e,
  }));
};

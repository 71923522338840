import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { RiCloseLine } from 'react-icons/ri';
import clsx from 'clsx';

import { useInvestorAccounts } from 'providers/investor-accounts';
import { useUserDetails } from 'providers/user-details';

import { Avatar } from '../../Avatar';
import { ESidebarSize } from '../../../types';
import { MOBILE_HEIGHT_BREAKPOINT } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '0 24px',
    marginBottom: '40px',

    '&.medium-height': {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },

  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  closeBtn: {
    margin: '-8px -8px 0 0',

    '&.medium-height': {
      height: 'max-content',
    },
  },

  header: {
    display: 'flex',
    gap: '16px',
    flexGrow: 1,
    overflow: 'hidden',
  },

  investorCredentials: {
    overflow: 'hidden',
  },

  investorCredentialsTopRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  investorName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  investorRole: {
    padding: '2px 12px',
    background: theme.palette.background.blueSurface,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: 'nowrap',
  },

  investorId: {
    lineHeight: 1.125,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
  onClose?: () => void;
}

export const MenuHeader = ({ onClose, sidebarSize }: Props) => {
  const classes = useStyles();
  const investorAccountsContext = useInvestorAccounts();
  const { userDetails } = useUserDetails();

  const selectedInvestor = investorAccountsContext.selectedAccount?.investor;
  const investorName = selectedInvestor?.name ?? '';
  const investorId = selectedInvestor?.id ?? '';
  const investorRole = investorAccountsContext.selectedAccount?.role;

  const mediumHeight = useMediaQuery(MOBILE_HEIGHT_BREAKPOINT);
  const isMobile = sidebarSize === ESidebarSize.Mobile;

  return (
    <Box className={clsx(classes.wrapper, { 'medium-height': mediumHeight })}>
      {isMobile && (
        <Box className={classes.closeContainer}>
          <IconButton
            onClick={() => onClose?.()}
            className={clsx(classes.closeBtn, {
              'medium-height': mediumHeight,
            })}
          >
            <RiCloseLine />
          </IconButton>
        </Box>
      )}

      <Box className={classes.header}>
        <Avatar user={userDetails} />

        <Box className={classes.investorCredentials}>
          <Box className={classes.investorCredentialsTopRow}>
            <Tooltip title={`${investorName}`} placement="top">
              <Typography fontWeight={600} className={classes.investorName}>
                {investorName}
              </Typography>
            </Tooltip>

            {investorRole && (
              <Typography variant="body2" className={classes.investorRole}>
                <FormattedMessage id={`role.${investorRole}`} />
              </Typography>
            )}
          </Box>
          <Typography variant="body2" className={classes.investorId}>
            {investorId}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

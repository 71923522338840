import React from 'react';

import { IKycContext } from './types';

const stub = () => {
  throw new Error('<KycContext> not found.');
};

export const KycContext = React.createContext<IKycContext>({
  kycRecord: null,
  uncompletedTaskGroups: [],
  taskFlowFinished: true,
  setUnfinishedTaskGroupLink: stub,
});

import { styled, useTheme } from '@mui/material';

import { SortOrder } from 'types';

const SSvg = styled('svg')({
  cursor: 'pointer',
});

interface Props {
  sortOrder?: SortOrder;
  onClick?: () => void;
  className?: string;
}

export const SortIcon = ({ sortOrder, onClick, className }: Props) => {
  const theme = useTheme();

  return (
    <SSvg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick?.()}
      className={className}
    >
      <path
        d="M8.00004 4.66669L10.6667 7.33335H5.33337L8.00004 4.66669Z"
        fill={sortOrder === SortOrder.ASC ? theme.palette.text.primary : 'currentColor'}
      />
      <path
        d="M7.99996 11.3333L5.33329 8.66665H10.6666L7.99996 11.3333Z"
        fill={sortOrder === SortOrder.DESC ? theme.palette.text.primary : 'currentColor'}
      />
    </SSvg>
  );
};

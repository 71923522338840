import { useState, useLayoutEffect } from 'react';

import { debounce } from 'utils';

export const useCalculatePaymentDetailsColOffset = () => {
  const [paymentDetailsCol, setPaymentDetailsCol] = useState<HTMLDivElement | null>(null);
  const [paymentDetailsColOffset, setPaymentDetailsColOffset] = useState<number | null>(null);

  useLayoutEffect(() => {
    setPaymentDetailsColOffset(paymentDetailsCol?.offsetTop ?? null);

    const resizeListener = debounce(() => {
      setPaymentDetailsColOffset(paymentDetailsCol?.offsetTop ?? null);
    }, 150);

    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [paymentDetailsCol]);

  return { setPaymentDetailsCol, paymentDetailsColOffset };
};

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {}

export const DotLoader = (props: Props) => {
  const [dotSize, setDotSize] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => setDotSize((s) => (s + 1) % 4 || 1), 750);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      component="span"
      sx={{
        '&::after': {
          content: `"${'.'.repeat(dotSize)}"`,
          position: 'absolute',
        },
      }}
    ></Box>
  );
};

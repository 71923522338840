import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

type TStorageType = 'local' | 'session';

const getStorage = (storageType: TStorageType) => {
  switch (storageType) {
    case 'session':
      return sessionStorage;

    case 'local':
    default:
      return localStorage;
  }
};

const setObject = (storageType: TStorageType, storageName: string, obj: JSONObject) => {
  getStorage(storageType).setItem(storageName, JSON.stringify(obj));
};

const getObject = (storageType: TStorageType, storageName: string, setDefault?: JSONObject) => {
  const obj = getStorage(storageType).getItem(storageName);

  if (obj === null) {
    if (setDefault !== undefined) {
      setObject(storageType, storageName, setDefault);
      return setDefault;
    }

    return null;
  }

  try {
    return JSON.parse(obj);
  } catch (error) {
    getStorage(storageType).removeItem(storageName);
    return null;
  }
};

export const useLocallySavedState = <S extends JSONObject>(
  localStorageName: string,
  defaultState?: S,
): [S, Dispatch<SetStateAction<S>>] => {
  const [value, setValue] = useState<S>(() => getObject('local', localStorageName, defaultState));

  const setValueCb = useCallback(
    (newValue: SetStateAction<S>) => {
      setValue((state) => {
        const value = typeof newValue === 'function' ? newValue(state) : newValue;
        setObject('local', localStorageName, value);
        return value;
      });
    },
    [localStorageName],
  );

  return [value, setValueCb];
};

export const useSessionalySavedState = <S extends JSONObject>(
  sessionStorageName: string,
  defaultState?: S,
): [S, Dispatch<SetStateAction<S>>] => {
  const [value, setValue] = useState(() => getObject('session', sessionStorageName, defaultState));

  useEffect(() => {
    setObject('session', sessionStorageName, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [value, setValue];
};

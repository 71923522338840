import { useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useIntl } from 'react-intl';
import { useAsyncDebounce } from 'react-table';

import { investorApiClient } from 'api';
import { IDealDto } from 'api/investor-client';
import { useLayoutPageTitle } from 'providers/layout-provider';
import { useBasketProvider } from 'providers/basket';
import { PageContainer } from 'components/UI';
import { DetailsTabs, PageLoader } from 'components';
import { useSelectedInvestorAccount } from 'hooks';
import { DealEventsToShow, getDealDetails, moveDecimalPoint } from 'utils';
import { ExpectedRepayments, IDealEventWithShareId } from 'types';

import { DealDetailsHeader, DealDetailsInvestmentInfo } from './components';
import { DealInformation, InvestmentInformation } from './tabs';
import { parseUrl } from 'query-string';

interface IUrlParams {
  accountId: string;
  providerId: string;
  id: string;
}

enum DealDetailTab {
  InvestmentInformation = 'investment',
  DealInformation = 'deal_information',
}

export const DealDetailsPage = ({isSecondaryMarket} : {isSecondaryMarket?: boolean}) => {
  const { accountId, providerId, id } = useParams<IUrlParams>();
  const search = useLocation()?.search;
  const initialAmount = +(search && parseUrl(search)?.query?.amount || 0);
  const intl = useIntl();
  useLayoutPageTitle(intl.formatMessage({ id: 'deal.header' }, { id, providerId }));

  // prettier-ignore
  const { selectedAccount, isViewOnly, isBlockedFromInvesting, isKycFlowUnfinished } = useSelectedInvestorAccount();
  const investorId = selectedAccount?.investor?.id;

  const basket = useBasketProvider();

  const [deal, setDeal] = useState<IDealDto | undefined>();
  const [dealEvents, setDealEvents] = useState<IDealEventWithShareId[]>([]);
  const [expectedRepayments, setExpectedRepayments] = useState<ExpectedRepayments | null>(null);
  const [amountToInvest, setAmountToInvest] = useState<number | undefined>(() => {
    const basketAmount = basket.primary.getDeal({ id, providerId })?.amount;
    if (typeof basketAmount === 'number') {
      return moveDecimalPoint(basketAmount, -2);
    } else if (initialAmount) {
      return moveDecimalPoint(initialAmount, -2);
    }
    return undefined;
  });

  const { loading, error } = useAsync(async () => {
    if (providerId && id) {
      const { data } = await investorApiClient.investors.investorApiControllerGetDeal({
        providerId: providerId.toString(),
        id: id.toString(),
        skipRisk: false,
        loadBorrower: true,
      });

      setDeal(data);
    }
  }, [providerId, id]);

  const dealDetails = useMemo(() => {
    return deal && investorId ? getDealDetails(deal, investorId) : null;
  }, [investorId, deal]);

  const { loading: eventsLoading } = useAsync(async () => {
    if (!deal) return setDealEvents([]);

    const investorShares = deal.shares.filter((share) => share.investorId === investorId);

    const promises = investorShares.map(async (share) => {
      const { data } = await investorApiClient.investors.investorApiControllerGetInvestmentEvents({
        investmentId: share.id,
        types: DealEventsToShow,
      });

      return data.map((event) => ({ ...event, shareId: share.id } as IDealEventWithShareId));
    });

    const result = (await Promise.all(promises)).flat();
    result.sort((a, b) => new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf());
    setDealEvents(result);
  }, [deal, investorId]);

  const trueAmountToInvest = moveDecimalPoint(amountToInvest ?? 0, 2);
  const investedAmount = dealDetails?.common.investorCommittedAmount ?? 0;
  const totalToInvest = investedAmount + trueAmountToInvest;
  const totalInvestedAmount = (dealDetails?.common.totalCommittedAmount ?? 0) + trueAmountToInvest;
  const totalAmountForRepayment = totalToInvest || deal?.principal || 0;
  const fetchRepayments = useAsyncCallback(async () => {
    if (!providerId || !id || !deal) return;
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetExpectedRepaymentsInfo({
      providerId: providerId.toString(),
      dealId: id.toString(),
      amount: totalAmountForRepayment,
    });

    let expectedInterest = 0;
    let expectedFee = 0;

    for (const transaction of data.transactions) {
      expectedInterest += transaction.interest;
      expectedFee += transaction.fee;
    }

    setExpectedRepayments({ ...data, expectedInterest, expectedFee });
  });

  const fetchRepaymentsDebounced = useAsyncDebounce(fetchRepayments.execute, 250);

  useEffect(() => {
    if (!providerId || !id || !deal) return;

    if (!expectedRepayments) {
      fetchRepayments.execute();
    } else {
      fetchRepaymentsDebounced();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId, id, deal, totalAmountForRepayment]);

  const [tab, setTab] = useState<DealDetailTab>(DealDetailTab.InvestmentInformation);

  const tabs = useMemo(
    () => [
      {
        id: DealDetailTab.InvestmentInformation,
        title: intl.formatMessage({
          id: DealDetailTab.InvestmentInformation,
        }),
        content: InvestmentInformation,
      },
      {
        id: DealDetailTab.DealInformation,
        title: intl.formatMessage({ id: DealDetailTab.DealInformation }),
        content: DealInformation,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage],
  );

  if (!deal) {
    return loading ? <PageLoader /> : <Redirect to={`/accounts/${accountId}/not-found`} />;
  } else if (error || !dealDetails || !investorId) {
    return <Redirect to={`/accounts/${accountId}/not-found`} />;
  }

  const initialLoading =
    (loading && !deal) ||
    (eventsLoading && !dealEvents.length) ||
    (fetchRepayments.loading && !expectedRepayments);

  return (
    <PageContainer>
      <Card variant="page">
        <CardHeader title={<DealDetailsHeader deal={deal} dealDetails={dealDetails} />} />

        <CardContent>
          <DealDetailsInvestmentInfo
            deal={deal}
            dealDetails={dealDetails}
            dealEvents={dealEvents}
            expectedRepaymentsInfo={expectedRepayments}
            amountToInvest={amountToInvest}
            onAmountChange={setAmountToInvest}
            totalToInvest={totalToInvest}
            totalInvestedAmount={totalInvestedAmount}
            isViewOnly={isViewOnly || isBlockedFromInvesting || isKycFlowUnfinished}
            loading={initialLoading}
          />
        </CardContent>
      </Card>

      <DetailsTabs
        activeTab={tab}
        onTabChange={setTab as (id: string) => void}
        tabs={tabs}
        tabProps={{
          deal,
          loading: fetchRepayments.loading,
          repaymentsData: expectedRepayments ?? undefined,
          dealDetails,
          totalAmount: totalAmountForRepayment,
          isSecondaryMarket,
        }}
        wrapperSx={{ marginTop: 6 }}
      />
    </PageContainer>
  );
};

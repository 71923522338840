import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ParsedQuery } from 'query-string';
import { Theme, useMediaQuery } from '@mui/material';

import { investorApiClient } from 'api';
import {
  SecondaryInvestmentFilterModal,
  SecondaryInvestmentFilterPayload,
  SecondaryInvestmentFilterValues,
  secondaryInvestmentFiltersHelper,
} from 'components/filters/secondary-investment';
import { useQuery, useLoadMoreController } from 'hooks';
// import { downloadFile, retrieveFileNameFromHeader } from 'utils';
import { ConfirmationDialog, EndOfTableMessage, ScrollToTopButton, TableLayout } from 'components';

import { TableFilters } from '../components';
import { SecondaryInvestmentHeader } from './SecondaryInvestmentHeader';
import { SecondaryInvestmentRecord, SecondaryInvestmentRecordSkeleton } from './SecondaryInvestmentRecord';
import { SecondaryInvestment, ShareSaleStatus } from 'types/secondary-market';
import { useIntl } from 'react-intl';

interface Props {
  wrapperRef?: HTMLElement | null;
}

export const SecondaryMarketTab = ({ wrapperRef }: Props) => {
  const { parsedQuery, push } = useQuery();
  const intl = useIntl();

  const [data, setData] = useState<SecondaryInvestment[]>([]);
  const [continuationToken, setContinuationToken] = useState<string | null>(null);
  const [total, setTotal] = useState<number | null>(null);
  const [sellShareToConfirm, setSellShareToConfirm] = useState<SecondaryInvestment | null>(null);

  const fetchInvestments = useAsyncCallback(async (query: ParsedQuery, fromBeginning?: boolean) => {
    if (!fromBeginning && continuationToken === 'EOF') return;

    if (fromBeginning) {
      setData([]);
      setContinuationToken(null);
      setTotal(null);
    }

    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetInvestorsSecondarymarketOffersByContinuationToken({
      ...query,
      ...(query?.havingSaleStatus ? {} : { havingSaleStatus: [ShareSaleStatus.Open] }),
      continuationToken: fromBeginning ? undefined : continuationToken ?? undefined,
    });

    // fetch request
    const mockedData: SecondaryInvestment[] = data.data.map((shareSell) => {
      return {
        ...shareSell,
        id: shareSell.shareSale.id,
        // expiresAt: new Date(Date.now() + Math.abs(getRandomNumber(10_000, 100_000_000_000) | 0)),
      };
    });

    setData((state) => state.concat(mockedData));
    setContinuationToken(data.continuationToken ?? null);
    setTotal((state) => (typeof state === 'number' ? state : data.total));
  });

  // const exportInvestments = useAsyncCallback(async () => {
  //   const { data, headers } = await investorApiClient.investors.investorApiControllerDownloadInvestments(
  //     { unpaidSeparate: true } as any,
  //     { format: 'blob' }

  //   );
  //   downloadFile(data as unknown as string, retrieveFileNameFromHeader(headers));
  // });

  const cancelSell = useAsyncCallback(async (inv: SecondaryInvestment) => {
    await investorApiClient.investors.investorApiControllerCancelInvestmentSale(
      inv.shareSale.providerId,
      inv.shareSale.dealId
    );
    fetchInvestments.execute(parsedQuery, true);
  });

  const handleCloseConfirmationModal = useAsyncCallback(async (accepted) => {
    if (accepted && sellShareToConfirm) {
      await cancelSell.execute(sellShareToConfirm);
    }
    setSellShareToConfirm(null);
  });

  useDeepCompareEffect(() => {
    fetchInvestments.execute(parsedQuery, true);
  }, [parsedQuery]);

  useLoadMoreController({
    wrapperRef: wrapperRef,
    loading: fetchInvestments.loading,
    continuationToken: continuationToken,
    fetch: fetchInvestments.execute,
    query: parsedQuery,
  });

  const withTableLayout = useMediaQuery<Theme>((theme) => theme.breakpoints.up(1400));

  return (
    <>
      <TableFilters<SecondaryInvestmentFilterPayload, SecondaryInvestmentFilterValues>
        components={{
          TableHeader: SecondaryInvestmentHeader,
          AllFiltersModal: SecondaryInvestmentFilterModal,
        }}
        isSecondary
        helpers={secondaryInvestmentFiltersHelper}
        initialFilters={parsedQuery}
        onFiltersChange={push}
        // onExport={exportInvestments.execute}
        total={total}
        loading={fetchInvestments.loading}
        tableLayout={withTableLayout}
      />

      <TableLayout
        components={{
          Record: SecondaryInvestmentRecord,
          RecordSkeleton: SecondaryInvestmentRecordSkeleton,
        }}
        data={data}
        dataLoading={fetchInvestments.loading}
        tableLayout={withTableLayout}
        onCancelSell={setSellShareToConfirm}
      />

      {continuationToken === 'EOF' && <EndOfTableMessage />}
      <ScrollToTopButton wrapperRef={wrapperRef} />
      <ConfirmationDialog
        open={!!sellShareToConfirm}
        strings={{
          title: intl.formatMessage({ id: 'are_you_sure' }),
          subtitle: intl.formatMessage({ id: 'canceled_secondary_market_deal_will_be_removed' }),
          cancelText: intl.formatMessage({ id: 'no' }),
          acceptText: intl.formatMessage({ id: 'boolean.true' }),
        }}
        onClose={handleCloseConfirmationModal.execute}
        loading={handleCloseConfirmationModal.loading}
      />
    </>
  );
};

import {
  Box,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { RiArrowRightSLine, RiQuestionLine } from 'react-icons/ri';
import { AddressDto, CreateInvestorDto } from 'api/investor-client';
import { paymentsApiClient, userApiClient } from 'api';
import { FormattedMessage } from 'react-intl';
import { LanguageSelect } from './sidebar/SidebarBody/LanguageSelect';
import { ESidebarSize } from './sidebar/types';
import { Menu } from 'react-pro-sidebar';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  headerGap: {
    height: '48px',
    width: '100%'
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 64,
    right: 64,
    '&>ul': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
  },
  wrapper: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 64,
  },
  box: {
    padding: '16px 16px 24px 16px',
    textAlign: 'left',
    borderRadius: '4px',
  },
  send: {
    width: '167px',
    height: '48px',
    borderRadius: '4px',
    marginTop: '64px',
    background: '#359CDD',
  },
  formControl: {
    alignItems: 'flex-start',
    gap: 10,
  },
  radio: {
    marginRight: '10px',
    paddingTop: '3px',
    '&.Mui-checked': {
      color: '#359CDD',
    },
    '&:hover': {
      background: 'none',
    },
  },
  coverParent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#001D32',
    height: '100%',
    width: '100%',
  },
}));

export const AccountCreationPage = () => {
  const classes = useStyles();
  const [value, setValue] = useState('private');

  const handleAccountCreation = useAsyncCallback(async () => {
    // Create investor account,
    // Create kyc record
    const dto: CreateInvestorDto = {
      name: 'Fresh Account',
      accountType: 'INDIVIDUAL_ACCOUNT',
      address: {
        country: 'CH',
        street: 'Street',
        zipcode: '8048',
        city: 'Zurich',
      } as AddressDto,
      description: 'Freshly created investor account on sign-up',
      isCompany: value === 'private' ? false : true,
      blockedFromInvestingReason: 'Fresh account',
      email: 'fresh@cg24.com',
    };

    const iua = await userApiClient.user.userApiControllerCreateInvestorAccount(dto);
    const { data: accessToken } = await userApiClient.user.userApiControllerGetToken(
      iua.data.investorId,
    );
    paymentsApiClient.setSecurityData({ token: accessToken });
    await paymentsApiClient.api.investorApiControllerCreateVirtualAccount();

    window.location.reload();
  });

  return (
    <>
      <Grid container className={classes.container}>
          <Menu className={classes.header}>
            <Box>
              <img src="/img/logo-full.svg" alt="Logo icon" width={180} />
            </Box>
            <Box display={'flex'} color={'#C2C7CF'} alignItems={'center'}>
              <LanguageSelect rawLabel collapsed={false} sidebarSize={ESidebarSize.Desktop} />
              <Link
                href="https://www.loantrade.io/help"
                target="_blank"
                color="inherit"
                width={24}
                height={24}
              >
                <RiQuestionLine size={24} />
              </Link>
            </Box>
          </Menu>
        <Grid item xs={12} lg={7} className={classes.container}>
          <Box className={classes.wrapper}>
            <span className={classes.headerGap}/>
            <FormControl sx={{ gap: '40px', margin: '0 16px' }}>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography variant="h5" fontWeight="700" color="#000000" textAlign="left">
                  <FormattedMessage id="describes" />
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                sx={{ gap: '24px' }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <Box
                  component="span"
                  className={classes.box}
                  style={{
                    border: value === 'private' ? `1px solid #359CDD` : '1px solid #CBCBCB',
                  }}
                >
                  <FormControlLabel
                    className={classes.formControl}
                    value="private"
                    control={<Radio size="small" className={classes.radio} />}
                    label={
                      <>
                        <Typography variant="subtitle1" fontWeight="400">
                          <FormattedMessage id="private_investor" />
                        </Typography>
                        <Typography
                          variant="caption"
                          fontSize="14px"
                          fontWeight="300"
                          color="text.secondary"
                        >
                          <FormattedMessage id="private_investor_subtitle" />
                        </Typography>
                      </>
                    }
                  />
                </Box>
                <Box
                  component="span"
                  className={classes.box}
                  style={{
                    border: value === 'business' ? `1px solid #359CDD` : '1px solid #CBCBCB',
                  }}
                >
                  <FormControlLabel
                    className={classes.formControl}
                    value="business"
                    control={<Radio size="small" className={classes.radio} />}
                    label={
                      <>
                        <Typography variant="subtitle1" fontWeight="400">
                          <FormattedMessage id="business_investor" />
                        </Typography>
                        <Typography
                          variant="caption"
                          fontSize="14px"
                          fontWeight="300"
                          color="text.secondary"
                        >
                          <FormattedMessage id="business_investor_subtitle" />
                        </Typography>
                      </>
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
            <Button
              variant="contained"
              endIcon={<RiArrowRightSLine size={18} />}
              className={classes.send}
              onClick={handleAccountCreation.execute}
            >
              Continue
            </Button>
            {/* <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logout
        </Button> */}
          </Box>
        </Grid>
        <Grid item xs={0} lg={5} className={classes.coverParent}>
          <Box>
            <img src="/img/account-create.svg" alt="" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

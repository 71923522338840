import { forwardRef, ComponentType, ComponentProps, useCallback, FocusEvent } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { NumericFormatProps, NumericFormat, OnValueChange } from 'react-number-format';
import { FieldProps } from 'formik';

import { mixinObject } from 'utils';
import { Currency } from 'types';

type XProps = Partial<FieldProps<string>> &
  TextFieldProps & {
    currency?: Currency;
    inputProps?: NumericFormatProps;
  };

export const AmountTextField = forwardRef<HTMLDivElement, XProps>((props, ref) => {
  const { field, form, meta, currency, inputProps, ...restProps } = props;
  const name = field?.name ?? props.name;
  const value = field?.value ?? props.value;

  const errorMessage = meta?.error ?? form?.errors[name!];
  const isError = !!errorMessage ?? props.error;

  const onValueChange = useCallback<OnValueChange>(
    (data, info) => {
      if (props.inputProps?.onValueChange) props.inputProps.onValueChange(data, info);
      if (name && form?.setFieldValue) form.setFieldValue(name, data.floatValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, form?.setFieldValue, props.inputProps?.onValueChange],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (field?.onBlur) field.onBlur(e);
      if (props?.onBlur) props.onBlur(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field?.onBlur, props?.onBlur],
  );

  return (
    <TextField
      ref={ref}
      {...restProps}
      name={name}
      value={value}
      onBlur={onBlur}
      helperText={errorMessage ?? props.helperText}
      error={isError}
      InputProps={{
        ...restProps.InputProps,
        inputComponent: NumericFormat as ComponentType<ComponentProps<'input'>>,
      }}
      inputProps={{
        thousandSeparator: '’',
        allowNegative: false,
        decimalScale: 2,
        fixedDecimalScale: true,
        ...mixinObject(!!currency, { prefix: `${currency} ` }),
        ...props.inputProps,
        onValueChange: onValueChange,
      }}
    />
  );
});

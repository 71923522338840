import { ComponentType, useMemo } from 'react';
import { Tabs, Tab, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props<T extends object> {
  tabs: Array<{ id: string; title: string; content: ComponentType<T> }>;
  activeTab: string;
  onTabChange(id: string): void;
  tabProps: T;
}

const useStyles = makeStyles(() => ({
  divider: {
    color: '#DFE3E8',
    marginBottom: '25px',
  },
  tabs: {
    position: 'relative',
    marginBottom: '-1px',

    '& .MuiTabs-indicator': {
      height: '1.5px',
      zIndex: 1,
    },
  },
  tab: {},
}));

export const DetailsSubTabs = <T extends object>({
  tabs,
  activeTab,
  onTabChange,
  tabProps,
}: Props<T>) => {
  const classes = useStyles();

  const Body = useMemo(
    () => tabs.find((tab) => tab.id === activeTab)?.content ?? null,
    [activeTab, tabs],
  );

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_, newTab) => onTabChange(newTab)}
        className={classes.tabs}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            value={tab.id}
            label={tab.title}
            className={classes.tab}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      {Body && <Body {...tabProps} />}
    </>
  );
};

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';

import { investorApiClient } from 'api';
import {
  BaseNotificationMessageData,
  MessageListDto,
  NotifuseApiClient,
  NotifuseUserPreference,
  NotifuseUserResponse,
} from 'api/notifications-client';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { useUserDetails } from 'providers/user-details';

import { NotificationsContext } from './context';
import { INotificationMetadata } from './types';

type MessageListPromise = Promise<AxiosResponse<MessageListDto<BaseNotificationMessageData>>>;

interface Props {}

export const NotificationsProvider = (props: PropsWithChildren<Props>) => {
  const history = useHistory();
  const { selectedAccount } = useInvestorAccounts();
  const { initiallyLoaded, languageChanging } = useUserDetails();

  const [hmacSignature, setHmacSignature] = useState<string | null>(null);
  const [notificationMetadata, setNotificationMetadata] = useState<INotificationMetadata>({
    total: 0,
    unread: 0,
  });

  const [userData, setUserData] = useState<NotifuseUserResponse | null>(null);
  const messagesRequest = useRef<MessageListPromise | null>(null);

  // --- Initialization ---

  useLayoutEffect(() => {
    setNotificationMetadata({ total: 0, unread: 0 });
    setHmacSignature(null);
  }, [selectedAccount]);

  const userId = useMemo(() => {
    if (!selectedAccount || !selectedAccount.investor) return null;
    return `${selectedAccount.userId}_${selectedAccount.investor.id}`;
  }, [selectedAccount]);

  useAsync(async () => {
    if (selectedAccount && initiallyLoaded) {
      // prettier-ignore
      const { data } = await investorApiClient.investors.investorApiControllerGetHmacSignature();
      setHmacSignature(data);
    }
  }, [selectedAccount, initiallyLoaded]);

  const notifuseApiClient = useMemo(() => {
    if (!userId || !hmacSignature) return null;
    return new NotifuseApiClient(userId, hmacSignature);
  }, [userId, hmacSignature]);

  // --- End Initialization ---
  // --- Language Sync ---

  const fetchUser = useAsyncCallback(async () => {
    if (!notifuseApiClient) return;
    let { data } = await notifuseApiClient.getUser();

    if (!data.user) {
      await investorApiClient.investors.investorApiControllerSynchronizeUserAccount();
      const resp = await notifuseApiClient.getUser();
      data = resp.data;
    }

    setUserData(data);
  });

  useAsync(async () => {
    if (!notifuseApiClient || languageChanging) return;
    if (!userData) fetchUser.execute();
    else setTimeout(() => fetchUser.execute(), 1000);
  }, [notifuseApiClient, languageChanging]);

  // --- End Language Sync ---
  // --- API ---

  // Optimize requests by managing request promise
  const requestFirstMessages = useCallback(async () => {
    if (messagesRequest.current) return messagesRequest.current;

    if (!notifuseApiClient) return null;
    const promise = notifuseApiClient.listMessages(0, 0);
    messagesRequest.current = promise;

    promise
      .then((res) => {
        messagesRequest.current = null;
        return res;
      })
      .catch((error) => {
        messagesRequest.current = null;
        throw error;
      });

    return messagesRequest.current;
  }, [notifuseApiClient]);

  const requestNotificationMetadata = useAsyncCallback(async () => {
    if (!notifuseApiClient) return;

    const response = await requestFirstMessages();
    if (!response) return;

    setNotificationMetadata({ total: response.data.total, unread: response.data.unread });
  });

  useEffect(() => {
    if (!notifuseApiClient) return;
    requestNotificationMetadata.execute();

    const interval = setInterval(() => {
      requestNotificationMetadata.execute();
    }, 60_000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifuseApiClient, requestNotificationMetadata.execute]);

  const getMessages = useCallback(
    async (skip: number, limit: number) => {
      if (!notifuseApiClient) return [];
      let response: Awaited<MessageListPromise> | null;

      if (skip === 0 && limit === 0) {
        response = await requestFirstMessages();
      } else {
        response = await notifuseApiClient.listMessages(skip, limit);
      }

      if (!response) return [];

      setNotificationMetadata({ total: response.data.total, unread: response.data.unread });
      return response.data.messages;
    },
    [notifuseApiClient, requestFirstMessages],
  );

  const markMessageAsRead = useCallback(
    async (messageId: string) => {
      if (!notifuseApiClient) return;

      await notifuseApiClient.markMessageAsRead(messageId);
      setNotificationMetadata((state) => ({
        ...state,
        unread: Math.max(state.unread - 1, 0),
      }));

      requestNotificationMetadata.execute();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifuseApiClient, requestNotificationMetadata.execute],
  );

  const markAllMessagesAsRead = useCallback(
    async () => {
      if (!notifuseApiClient) return;

      await notifuseApiClient.markAllMessagesAsRead();
      setNotificationMetadata((state) => ({ ...state, unread: 0 }));
      requestNotificationMetadata.execute();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifuseApiClient, requestNotificationMetadata.execute],
  );

  const goToUrl = useCallback(
    (targetUrl: string) => {
      try {
        const url = new URL(targetUrl, document.location.origin);

        if (url.hostname === document.location.hostname) {
          history.push(url.pathname + url.search);
        } else {
          window.open(targetUrl, '_self');
        }
      } catch {}
    },
    [history],
  );

  const changeUserPreferences = useCallback(
    async (preferences: NotifuseUserPreference[]) => {
      if (!userData?.user || !notifuseApiClient) return;

      const preferencesObj = Object.fromEntries(preferences.map((p) => [p.notificationId, p]));

      const newUser = {
        ...userData.user,
        preferences: {
          ...userData.user.preferences,
          ...preferencesObj,
        },
      };

      setUserData({ ...userData, user: newUser });
      notifuseApiClient.updateUser(newUser);
    },
    [userData, notifuseApiClient],
  );

  return (
    <NotificationsContext.Provider
      value={{
        userId,
        hmacSignature,
        notificationMetadata,
        userData,
        isReady: !!notifuseApiClient,
        refreshMetadata: requestNotificationMetadata.execute,
        getMessages,
        markMessageAsRead,
        markAllMessagesAsRead,
        goToUrl,
        refreshUser: fetchUser.execute,
        fetchingUser: fetchUser.loading,
        changeUserPreferences,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

import { ReactNode, useMemo } from 'react';
import { Box, Divider, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import { BulkError } from 'api/investor-client';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { BulkResultWithPayment } from 'types';
import { MarketType } from 'types/secondary-market';

interface ChildProps {
  columns: { size: number, id?: string; data: ReactNode; }[][];
  isRejected?: boolean;
}

interface Props {
  type?: MarketType;
  payment: BulkResultWithPayment | BulkError;
  isRejected?: boolean;
  actionTitle?: string;
  actionContent?: ReactNode;
}

const useRowViewStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px',
    margin: '16px 0',
    background: theme.palette.background.barkSurface004,
    border: `solid 1px ${theme.palette.background.barkSurface004}`,
    borderRadius: theme.shape.borderRadius,
    backgroundClip: 'padding-box',

    '&.shallow': {
      background: 'transparent',
      borderColor: theme.palette.divider,
    },
  },

  dataListTitle: {
    display: 'inline-block',
    color: theme.palette.text.secondary,
    marginBottom: '8px',
    fontWeight: 500,
    textTransform: 'uppercase',

    '&.error': {
      color: theme.palette.error.dark,
    },
  },

  actionContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '24px',
    overflow: 'hidden',
  },
}));

const PaymentDetailsRowView = ({ columns, isRejected }: ChildProps) => {
  const classes = useRowViewStyles();

  const [lastCol, ...restCols] = useMemo(() => columns.flat().reverse(), [columns]);

  const rightCol = useMemo(() => {
    if (!lastCol.data) return null;
    return (
      <Grid item xs={lastCol.size} sx={{ display: 'flex' }}>
        <Divider orientation="vertical" />

        <Box className={classes.actionContainer} margin={0}>
          <Typography
            variant="caption"
            className={clsx(classes.dataListTitle, { error: isRejected })}
          >
            <FormattedMessage id={lastCol.id} />
          </Typography>
          {lastCol.data}
        </Box>
      </Grid>
    )
  }, [lastCol, isRejected, classes])

  return (
    <Box className={clsx(classes.container, { shallow: isRejected })}>
      <Grid container spacing={1}>
        {restCols.reverse().map(r =>
          <Grid item xs={r.size}>
            <Typography variant="caption" className={classes.dataListTitle}>
              <FormattedMessage id={r.id} />
            </Typography>
            <Typography variant="subtitle2">
              {r.data}
            </Typography>
          </Grid>
        )}
        {rightCol}
      </Grid>
    </Box>
  );
};

const useTileViewStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    padding: '24px',
    margin: '16px 0',
    background: theme.palette.background.barkSurface004,
    border: `solid 1px ${theme.palette.background.barkSurface004}`,
    borderRadius: theme.shape.borderRadius,
    backgroundClip: 'padding-box',

    '&.shallow': {
      background: 'transparent',
      borderColor: theme.palette.divider,
    },
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
  },

  rowCell: {
    flexBasis: '45%',
  },
}));

const PaymentDetailsTileView = ({ columns, isRejected }: ChildProps) => {
  const classes = useTileViewStyles();

  return (
    <Box className={clsx(classes.container, { shallow: isRejected })}>
      {columns.map(cols => (
        <Box className={classes.row}>
          {cols.map(col => (
            <Box className={classes.rowCell}>
              <Typography variant="caption" color="text.secondary" textTransform='uppercase' fontWeight={500} mb="8px">
                <FormattedMessage id={col.id} />
              </Typography>
              <Typography variant="subtitle2">{col.data}</Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box >
  );
};

export const PaymentDetails = (props: Props) => {
  const { type, payment, isRejected, actionTitle, actionContent } = props;
  const intl = useIntl();
  const isDesktopView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const formatMoney = useFormatMoney({
    prefix: `${payment.deal?.currency ?? ''} `,
  });

  const columns = useMemo(() => {
    return [
      [
        {
          size: 2,
          id: 'deal.id',
          data: payment.deal?.id ?? '~'
        },
        {
          size: 1.5,
          id: 'type',
          data: payment.deal?.risk.productType ? intl.formatMessage({ id: `borrower.${payment.deal?.risk.productType}` }) : '~'
        },
      ],
      [

        {
          size: 1.5,
          id: 'rating',
          data: payment.deal?.risk.rating ?? '~'
        },
        // {
        //   id: 'duration',
        //   data: payment.deal
        //     ? `${payment.deal.duration} ${intl.formatMessage({
        //       id: payment.deal.duration > 1 ? 'months' : 'month',
        //     })}`
        //     : '~'
        // },
        {
          size: 2,
          id: type === MarketType.primary ? 'amount' : 'deal_price',
          data: payment.request.amount
            ? formatMoney(moveDecimalPoint(payment.request.amount, -2))
            : '~'
        },
        ...type === MarketType.primary ? [] : [{
          size: 2,
          id: 'service_fee',
          data: payment.request.fee
            ? formatMoney(moveDecimalPoint(payment.request.fee, -2))
            : '~'
        }],
      ],
      [
        // {
        //   id: 'amount',
        //   data: payment.request.amount
        //     ? formatMoney(moveDecimalPoint(payment.request.amount, -2))
        //     : '~'
        // },
        {
          size: 3,
          id: actionTitle,
          data: actionContent
        }
      ]
    ]
  }, [payment, intl, formatMoney, actionContent, actionTitle, type]);

  if (isDesktopView) return <PaymentDetailsRowView columns={columns} />;
  return <PaymentDetailsTileView columns={columns} isRejected={isRejected} />;
};

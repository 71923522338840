import { User, useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    borderTop: `1px solid  ${theme.palette.text.secondary}`,
    boxShadow: '0px -2px 6px 0px rgba(0, 0, 0, 0.20)',
    display: 'flex',
    padding: '24px',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
}));

type IProps = {
  user: User;
  acceptTerms: any;
};

export const Auth0ToSPage = ({ user, acceptTerms }: IProps) => {
  const classes = useStyles();
  const { logout } = useAuth0();

  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      height={'100vh'}
      bgcolor="#001D32"
    >
      <Box
        display="flex"
        width={576}
        height={720}
        bgcolor="white"
        flexDirection={'column'}
        borderRadius={'8px'}
      >
        <Box overflow={'auto'} margin="40px 40px 0 40px">
          <Box padding={'20px 0'}>
            <Box>
              <Typography variant="h6" fontWeight={700}>
                <FormattedMessage id="loantrade_tos_title" />
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                <FormattedMessage id="loantrade_tos_last_updated" />
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" whiteSpace="pre-line">
                <FormattedMessage id="loantrade_tos_content"
                                  values={{ b: (msg: string) => <b>{msg}</b> }}/>
              </Typography>
            </Box>
          </Box>
          <Box padding={'20px 0'}>
            <Box>
              <Typography variant="h6" fontWeight={700}>
                <FormattedMessage id="privacy_policy_title" />
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                <FormattedMessage id="privacy_policy_last_updated" />
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" whiteSpace="pre-line">
                <FormattedMessage id="privacy_policy_content"
                                  values={{ b: (msg: string) => <b>{msg}</b> }}
                />
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.buttonsContainer}>
          <Button
            size="large"
            variant="outlined"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            <Typography variant="button">
              <FormattedMessage id="reject" />
            </Typography>
          </Button>
          <Button size="large" variant="contained" onClick={acceptTerms}>
            <Typography variant="button">
              <FormattedMessage id="accept" />
            </Typography>
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

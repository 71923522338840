import { Typography, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const SContainer = styled(Typography)(({ theme }) => ({
  margin: '72px 24px 64px',
  textAlign: 'center',
  color: theme.palette.secondary.dark,
}));

interface Props {}

export const EndOfTableMessage = (props: Props) => {
  return (
    <SContainer variant="subtitle3">
      <FormattedMessage id="you_reached_the_end_of_table" />
    </SContainer>
  );
};

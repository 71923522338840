import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { documentApiClient, investorApiClient, paymentsApiClient, userApiClient } from 'api';
import { BalanceDto, InvestorUserAccessDto } from 'api/investor-client';
import { Currency } from 'types';
import { InvestorAccountsContext } from './context';

interface Props {
  loadInitially?: boolean;
}
export interface AccountWithBalance extends InvestorUserAccessDto {
  balance: BalanceDto[];
  unpaid: BalanceDto[];
  VABalance: BalanceDto[];
  pendingPayins: BalanceDto[];
  pendingPayouts: BalanceDto[];
}

const getPrimaryAccount = (accounts: InvestorUserAccessDto[]): InvestorUserAccessDto | null => {
  return accounts.find((acc) => acc.isPrimaryAccount) ?? accounts[0] ?? null;
};

export const InvestorAccountsProvider = ({
                                           loadInitially = true,
                                           children,
                                         }: PropsWithChildren<Props>) => {
  // prettier-ignore
  const [selectedAccount, setSelectedAccount] = useState<AccountWithBalance | null>(null);
  const [accounts, setAccounts] = useState<InvestorUserAccessDto[]>([]);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const updateAccountBalance = useAsyncCallback(async () => {
    const { data } = await investorApiClient.investors.investorApiControllerGetBalance([
      'VirtualAccount:Assets:Current',
      'VirtualAccount:Liabilities:InvestmentPayable',
      'VirtualAccount:Assets:PendingPayin',
      'VirtualAccount:Assets:PendingPayout',
    ]);

    // How much money an investor has in its Virtual Account
    const vaBalanceData = data.filter((e) => e.account === 'VirtualAccount:Assets:Current');
    // How much money an investor has to pay for his unpaid investments
    const unpaidInvestmentsBalanceData = data.filter((e) => e.account === 'VirtualAccount:Liabilities:InvestmentPayable')
    // How much money should we receive (pending)
    const pendingPayins = data.filter((e) => e.account === 'VirtualAccount:Assets:PendingPayin');
    // How much money are we payinout (pending)
    const pendingPayouts = data.filter((e) => e.account === 'VirtualAccount:Assets:PendingPayout');

    const amountCurrencies = [Currency.CHF, Currency.EUR];
    const totalBalance = amountCurrencies.map((currency) => ({
      currency,
      amount:
        (vaBalanceData.find((e) => e['currency'] === currency)?.amount ?? 0) +
        (unpaidInvestmentsBalanceData.find((e) => e['currency'] === currency)?.amount ?? 0) +
        (pendingPayins.find((e) => e['currency'] === currency)?.amount ?? 0) +
        (pendingPayouts.find((e) => e['currency'] === currency)?.amount ?? 0),
    })) as BalanceDto[];

    setSelectedAccount((state) => {
      return {
        ...state,
        balance: totalBalance,
        VABalance: vaBalanceData,
        unpaid: unpaidInvestmentsBalanceData,
        pendingPayins: pendingPayins,
        pendingPayouts: pendingPayouts,
      } as AccountWithBalance;
    });
  });

  const updateInvestorToken = useAsyncCallback(async () => {
    if (!selectedAccount?.investor?.id) return;
    const { data: accessToken } = await userApiClient.user.userApiControllerGetToken(
      selectedAccount.investor.id,
    );
    investorApiClient.setSecurityData({ token: accessToken });
    paymentsApiClient.setSecurityData({ token: accessToken });
    documentApiClient.setSecurityData({ token: accessToken });
    void updateAccountBalance.execute();
  });

  const fetchAccount = useAsyncCallback(async (withDefaultAccountSelection = true) => {
    const { data } = await userApiClient.user.userApiControllerGetInvestorAccounts();

    setAccounts(data);
    setInitiallyLoaded(true);

    setSelectedAccount((selected) => {
      if (!selected) {
        if (withDefaultAccountSelection) {
          return getPrimaryAccount(data) as AccountWithBalance;
        }

        return null;
      }

      return (data.find((acc) => {
        return acc.investor && acc.investor.id === selected.investor?.id;
      }) ?? getPrimaryAccount(data)) as AccountWithBalance;
    });
  });

  const selectAccount = useCallback(
    (accountId?: string) => {
      const acc = accountId
        ? accounts.find((acc) => acc?.investor?.id === accountId)
        : getPrimaryAccount(accounts);

      if (acc) {
        setSelectedAccount({
          ...acc,
          balance: [],
          unpaid: [],
          VABalance: [],
          pendingPayins: [],
          pendingPayouts: [],
        });

        return true;
      }

      return false;
    },
    [accounts],
  );

  useEffect(() => {
    if (loadInitially) fetchAccount.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InvestorAccountsContext.Provider
      value={{
        accounts: accounts,
        selectedAccount: selectedAccount,
        selectAccount: selectAccount,
        updateAccountBalance: updateAccountBalance.execute,
        accountBalanceLoading: updateAccountBalance.loading,
        updateInvestorToken: updateInvestorToken.execute,
        loading: fetchAccount.loading,
        initiallyLoaded: initiallyLoaded,
        refresh: fetchAccount.execute,
      }}
    >
      {children}
    </InvestorAccountsContext.Provider>
  );
};

import { match, compile } from 'path-to-regexp';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

export const useChangePathParam = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const [parse, toPath] = useMemo(() => {
    return [
      match<Record<string, string>>(path + '/:rest*', {
        decode: decodeURIComponent,
      }),
      compile(path + '/:rest*', { encode: encodeURIComponent }),
    ];
  }, [path]);

  const changeParam = useCallback(
    (paramName: string, value: string, replace = false) => {
      const parsed = parse(pathname);

      if (
        !parsed ||
        !parsed.params[paramName] ||
        parsed.params[paramName] === value
      ) {
        return false;
      }

      const newPath = toPath({ ...parsed.params, [paramName]: value });
      if (replace) history.replace(newPath);
      else history.push(newPath);

      return true;
    },
    [parse, pathname, toPath, history],
  );

  return changeParam;
};

import React, { useCallback, useMemo } from 'react';
import { Box, Button, Fade, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Column } from 'react-table';
import SimpleBar from 'simplebar-react';
import dayjs from 'dayjs';

import { Select } from 'components/forms';
import { Table } from 'components';
import { formatMoney, moveDecimalPoint } from 'utils';
import { TransactionMethod, TransactionType } from 'types';
import { TransactionDto } from 'api/investor-client';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '32px 0 0',

    [theme.breakpoints.up('sm')]: {
      padding: '40px 8px 0',
    },
  },

  title: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 16px',
    },
  },

  filterWrapper: {
    margin: '16px -24px',

    [theme.breakpoints.up('sm')]: {
      margin: '16px',
    },

    '& .simplebar-track.simplebar-horizontal': {
      margin: '0 24px',

      [theme.breakpoints.up('sm')]: {
        margin: '0',
      },
    },
  },

  filterContainer: {
    display: 'inline-flex',
    gap: '16px',
    padding: '16px 24px',

    [theme.breakpoints.up('sm')]: {
      padding: '16px 0',
    },

    '& > .MuiButton-root': {
      whiteSpace: 'nowrap',
    },
  },

  table: {
    margin: '0 -24px',

    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },

    '& .MuiTableContainer-root': {
      maxHeight: '230px',

      '& .MuiTable-root': {
        paddingRight: '24px',

        [theme.breakpoints.up('sm')]: {
          paddingRight: 0,
        },
      },

      '& .MuiTableCell-root': {
        border: 'none',
        borderRight: `1px solid ${theme.palette.divider}`,
      },

      '& .MuiTableCell-head': {
        padding: '0px 24px 8px',
        ...theme.typography.subtitle2,
      },

      '& .MuiTableCell-body': {
        padding: '8px 24px',
        ...theme.typography.body1,
        color: theme.palette.secondary.dark,
      },

      '& .simplebar-track.simplebar-horizontal': {
        margin: '0 24px',

        [theme.breakpoints.up('sm')]: {
          margin: '0',
        },
      },
    },
  },

  transactionType: {
    padding: '2px 8px',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.barkSurface004,
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  data: TransactionDto[];
  type: TransactionType | undefined;
  method: TransactionMethod | undefined;
  setType: React.Dispatch<React.SetStateAction<TransactionType | undefined>>;
  setMethod: React.Dispatch<React.SetStateAction<TransactionMethod | undefined>>;
}

export const Transactions = (props: Props) => {
  const { data, type, setType, method, setMethod } = props;

  const intl = useIntl();
  const classes = useStyles();
  const isSMSize = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const typeOptions = useMemo(() => {
    return Object.values(TransactionType).map((t) => ({
      label: intl.formatMessage({
        id: t === TransactionType.Payin ? 'deposit_made' : 'withdrawal',
      }),
      value: t,
    }));
  }, [intl]);

  const methodOptions = useMemo(() => {
    return Object.values(TransactionMethod).map((m) => ({
      label: intl.formatMessage({ id: `transaction_method.${m}` }),
      value: m,
    }));
  }, [intl]);

  const columns = useMemo((): Column<TransactionDto>[] => {
    return [
      {
        Header: intl.formatMessage({ id: 'execution_date' }),
        accessor: 'transferDueDate',
        Cell: function Cell({ value }) {
          return dayjs(value).format('DD/MM/YYYY');
        },
      },
      {
        Header: intl.formatMessage({ id: 'transaction_type' }),
        accessor: 'type',
        Cell: function Cell({ value }) {
          return (
            <Box component="span" className={classes.transactionType}>
              <FormattedMessage
                id={value === TransactionType.Payin ? 'deposit_made' : 'withdrawal'}
              />
            </Box>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: 'currency' }),
        accessor: 'currency',
      },
      {
        Header: intl.formatMessage({ id: 'amount' }),
        accessor: 'amount',
        Cell: function Cell({ value, row: { original } }) {
          const prefixSign = original.type === TransactionType.Payin ? '+' : '-';

          return (
            <Typography noWrap>{`${prefixSign} ${formatMoney(
              moveDecimalPoint(value, -2),
            )}`}</Typography>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: 'payment_type' }),
        accessor: 'method',
        Cell: function Cell({ value }) {
          return (
            <Typography noWrap>
              <FormattedMessage id={`transaction_method.${value}`} />
            </Typography>
          );
        },
      },
    ];
  }, [intl, classes.transactionType]);

  const handleResetFilters = useCallback(() => {
    setType(undefined);
    setMethod(undefined);
  }, [setType, setMethod]);

  const isAnyValueFilled = !!type || !!method;

  return (
    <Box className={classes.container}>
      <Typography variant={isSMSize ? 'h4' : 'h6'} fontWeight={500} className={classes.title}>
        <FormattedMessage id="transactions" />
      </Typography>

      <SimpleBar className={classes.filterWrapper}>
        <Box className={classes.filterContainer}>
          <Select
            variant="filter"
            placeholder={intl.formatMessage({ id: 'transaction_type' })}
            options={typeOptions}
            value={type}
            onChange={(e) => setType(e.target.value as TransactionType)}
          />

          <Select
            variant="filter"
            size="small"
            placeholder={intl.formatMessage({ id: 'payment_type' })}
            options={methodOptions}
            value={method}
            onChange={(e) => setMethod(e.target.value as TransactionMethod)}
          />

          <Fade in={isAnyValueFilled} unmountOnExit>
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={handleResetFilters}
              disabled={!type && !method}
            >
              <FormattedMessage id="filters.reset" />
            </Button>
          </Fade>
        </Box>
      </SimpleBar>

      <Table<TransactionDto>
        columns={columns}
        data={data}
        className={classes.table}
        withPagination={false}
        sortable={false}
        stickyHeader
      />
    </Box>
  );
};

import { Box, Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { DealStatusDto, IDealDto } from 'api/investor-client';
import { IDealDetails, moveDecimalPoint } from 'utils';
import { ExpectedRepayments, IDealEventWithShareId } from 'types';

import {
  InvestmentCard,
  ExpectedReturnCard,
  ActivityTimelineCard,
  AddInvestment,
  RepaymentPrincipalCard,
  RepaymentInterestCard,
  AddInvestmentModal,
} from './components';

interface Props {
  deal: IDealDto;
  dealDetails: IDealDetails;
  dealEvents: IDealEventWithShareId[];
  expectedRepaymentsInfo: ExpectedRepayments | null | undefined;
  amountToInvest: number | undefined;
  onAmountChange(value: number | undefined): void;
  totalToInvest: number;
  totalInvestedAmount: number;
  isViewOnly: boolean;
  loading?: boolean;
}

export const DealDetailsInvestmentInfo = (props: Props) => {
  const { deal, amountToInvest, onAmountChange, isViewOnly, loading } = props;

  const dealIsActive = deal.status === DealStatusDto.Active;
  const skeletonSize = dealIsActive ? 358 : 233;
  const canDisplayInput = !isViewOnly && !dealIsActive;

  const isTabletView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={600} marginBottom={{ xs: '32px', sm: '16px' }}>
        <FormattedMessage id="your_investment_commitment" />
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={dealIsActive ? 6 : undefined} lg={4}>
          {loading ? (
            <Skeleton height={skeletonSize} variant="rounded" />
          ) : dealIsActive ? (
            <RepaymentPrincipalCard {...props} />
          ) : (
            <InvestmentCard {...props} amountToInvest={moveDecimalPoint(amountToInvest ?? 0, 2)} />
          )}
        </Grid>

        <Grid item xs={12} sm={dealIsActive ? 12 : 6} md={dealIsActive ? 6 : undefined} lg={4}>
          {loading ? (
            <Skeleton height={skeletonSize} variant="rounded" />
          ) : dealIsActive ? (
            <RepaymentInterestCard {...props} />
          ) : (
            <ExpectedReturnCard {...props} />
          )}
        </Grid>

        <Grid item xs={12} sm={dealIsActive ? 12 : 6} lg={4}>
          {loading ? (
            <Skeleton height={skeletonSize} variant="rounded" />
          ) : (
            <ActivityTimelineCard {...props} />
          )}
        </Grid>

        {canDisplayInput &&
          (isTabletView ? (
            <Grid item xs={12}>
              <AddInvestment deal={deal} amount={amountToInvest} onAmountChange={onAmountChange} />
            </Grid>
          ) : (
            <AddInvestmentModal
              deal={deal}
              amount={amountToInvest}
              onAmountChange={onAmountChange}
            />
          ))}
      </Grid>
    </Box>
  );
};

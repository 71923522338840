import React from 'react';
import { makeStyles } from '@mui/styles';
import { Badge, Box, Button, IconButton, Tooltip, Typography, alpha } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { RiWalletLine } from 'react-icons/ri';
import urljoin from 'url-join';
import clsx from 'clsx';

import { useInvestorAccounts } from 'providers/investor-accounts';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';

const useStyles = makeStyles((theme) => ({
  amountTooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    color: theme.palette.text.primary,
    padding: '16px 24px 24px 16px',
  },

  amountBadge: {
    '& .MuiBadge-badge': {
      margin: '2px 4px 0 0',
    },
  },

  amountValue: {
    padding: '0 7px',
    borderRadius: '4px',
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: 150,
    }),

    '&:not(.loading)': {
      cursor: 'pointer',

      '&:hover': {
        background: alpha(theme.palette.background.barkSurface, 0.04),
      },
    },
  },
}));

interface AmountDisplayProps {
  currency: string;
  loading?: boolean;
  VABalance: number;
  balance: number;
  unpaid: number;
  pendingPayin: number;
  pendingPayout: number;
}

const AmountDisplay = ({
  currency,
  loading,
  VABalance,
  balance,
  unpaid,
  pendingPayin,
  pendingPayout,
}: AmountDisplayProps) => {
  const classes = useStyles();
  const formatMoney = useFormatMoney({ prefix: `${currency} ` });

  return (
    <Tooltip
      arrow={false}
      placement="bottom-start"
      disableHoverListener={loading}
      classes={{ tooltip: classes.amountTooltip }}
      title={
        <div>
          <Typography variant="caption" color="grey.700">
            <FormattedMessage id="balance.info.VABalance" />
          </Typography>
          <Typography variant="body2" marginBottom="16px">
            {formatMoney(moveDecimalPoint(VABalance, -2))}
          </Typography>

          <Typography variant="caption" color="grey.700">
            <FormattedMessage id="balance.info.unpaid" />
          </Typography>
          <Typography
            variant="body2"
            color={unpaid !== 0 ? 'error' : undefined}
            marginBottom="16px"
          >
            {formatMoney(moveDecimalPoint(-unpaid, -2))}
          </Typography>

          <Typography variant="caption" color="grey.700">
            <FormattedMessage id="balance.info.pending_payments" />
          </Typography>
          <Typography variant="body2" marginBottom="16px">
            {formatMoney(moveDecimalPoint(pendingPayin + pendingPayout, -2))}
          </Typography>

          <Typography variant="caption" color="grey.700">
            <FormattedMessage id="balance.info.balance" />
          </Typography>
          <Typography variant="body2">{formatMoney(moveDecimalPoint(balance, -2))}</Typography>
        </div>
      }
    >
      <Badge
        variant="dot"
        color="error"
        invisible={loading || unpaid === 0}
        className={classes.amountBadge}
      >
        <Typography
          variant="subtitle2"
          whiteSpace="nowrap"
          color={VABalance < 0 ? 'error' : undefined}
          className={clsx(classes.amountValue, { loading })}
        >
          {formatMoney(moveDecimalPoint(VABalance, -2))}
        </Typography>
      </Badge>
    </Tooltip>
  );
};

const useBalanceInfoStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: '8px',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: '4px',
  },

  walletBtn: {
    padding: '4px',
    margin: '0 12px 0 4px',
    borderRadius: theme.shape.borderRadius * 2,

    '&.active': {
      backgroundColor: alpha(theme.palette.text.secondary, 0.08),

      '&:hover': {
        backgroundColor: alpha(theme.palette.text.secondary, 0.08),
      },
    },
  },

  amountsContainer: {
    position: 'relative',
    display: 'flex',
    gap: '16px',
    marginRight: '15px',
  },

  fundsBtn: {
    whiteSpace: 'nowrap',
  },

  verticalDivider: {
    width: '1px',

    '&::after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '1px',
      backgroundColor: theme.palette.divider,
    },
  },
}));

export const BalanceInfo = () => {
  const classes = useBalanceInfoStyles();
  const { selectedAccount, accountBalanceLoading } = useInvestorAccounts();

  const currentRouterPath = useRouteMatch();
  const activeUrl = urljoin(currentRouterPath.url, 'account-balance');
  const activeRouterPath = useRouteMatch(activeUrl);

  const {
    VABalance,
    balance: totalBalance,
    unpaid,
    pendingPayins,
    pendingPayouts,
  } = selectedAccount ?? {};

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <IconButton
          component={Link}
          to={activeUrl}
          color="inherit"
          className={clsx(classes.walletBtn, { active: !!activeRouterPath })}
        >
          <RiWalletLine size={24} />
        </IconButton>

        <Box className={classes.amountsContainer}>
          {totalBalance?.map((e, index) => (
            <React.Fragment key={e.currency}>
              <AmountDisplay
                currency={e.currency}
                loading={accountBalanceLoading}
                VABalance={VABalance?.find((e1) => e1['currency'] === e.currency)?.amount ?? 0}
                balance={e.amount ?? 0}
                unpaid={unpaid?.find((e1) => e1['currency'] === e.currency)?.amount ?? 0}
                pendingPayin={
                  pendingPayins?.find((e1) => e1['currency'] === e.currency)?.amount ?? 0
                }
                pendingPayout={
                  pendingPayouts?.find((e1) => e1['currency'] === e.currency)?.amount ?? 0
                }
              />

              {index + 1 !== totalBalance.length && <div className={classes.verticalDivider}></div>}
            </React.Fragment>
          ))}
        </Box>
      </Box>

      <Button
        component={Link}
        to={activeUrl}
        variant="contained"
        color="secondary"
        className={classes.fundsBtn}
      >
        <FormattedMessage id="manage_funds" />
      </Button>
    </Box>
  );
};

import { makeStyles } from '@mui/styles';
import { WithLayoutPageTitle } from 'providers/layout-provider';
import { Route, RouteProps } from 'react-router';

import { CookieBanner, Header, Sidebar } from '../components';

const useStyles = makeStyles(() => ({
  pageContainer: {
    display: 'flex',
    minHeight: '100vh',
  },

  body: {
    minWidth: '375px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  wrapper: {
    display: 'flex',
    flex: 1,
  },
}));

interface Props extends RouteProps {
  title?: string;
}

export const LayoutRoute = ({ title, ...props }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.pageContainer}>
      <WithLayoutPageTitle title={title} />
      <Sidebar />

      <main className={classes.body}>
        <Header />

        <div className={classes.wrapper}>
          <Route {...props} />
        </div>

        <CookieBanner />
      </main>
    </div>
  );
};

import { PropsWithChildren } from 'react';
import { Grid } from '@mui/material';

import { PersonalDetailsForm } from './PersonalDetailsForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import { AccountsList } from './AccountsList';

interface Props {}

export const ProfileTab = (props: PropsWithChildren<Props>) => {
  const isMultiAccountsEnabled = process.env.REACT_APP_DISABLE_MULTI_ACCOUNT !== 'true';
  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <PersonalDetailsForm />
        </Grid>
        <Grid item md={6}>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <ChangePasswordForm />
            </Grid>
            {isMultiAccountsEnabled && (
              <Grid item md={12}>
                <AccountsList />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

import { useState, useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Theme,
  alpha,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import SimpleBar from 'simplebar-react';
import clsx from 'clsx';

import { useKycProvider } from 'providers/kyc';
import { PageContainer } from 'components/UI';
import { UncompletedTaskGroups } from 'components/card-widgets';
import { INavigationTabOption, useTabNavigation } from 'hooks';

import { BlockedFromInvestingModal } from './components';
import { PrimaryMarketTab } from './PrimaryMarketTab';
import { SecondaryMarketTab } from './SecondaryMarketTab';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    position: 'relative',
    gap: '10px',
  },

  card: {
    '&.MuiPaper-page': {
      position: 'relative',
      height: '100%',
      borderWidth: 0,

      [theme.breakpoints.up('sm')]: {
        borderWidth: '1px',
      },
    },

    '&.with-content-above': {
      height: '85vh',
    },
  },

  cardContentWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },

  header: {
    margin: '0 0 24px',
    paddingBottom: '8px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,

    [theme.breakpoints.up('sm')]: {
      margin: '24px',
      paddingBottom: '0',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-root': {
        '& .MuiTabs-indicator': {
          display: 'none',
        },

        '& .MuiTab-root': {
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: alpha(theme.palette.primary.main, 0.12),
          },

          '&.Mui-disabled': {
            color: alpha(theme.palette.common.white, 0.38),
          },
        },
      },
    },
  },

  content: {
    margin: '32px 16px',

    [theme.breakpoints.up('sm')]: {
      margin: '24px',
    },
  },
}));

enum EDealsTab {
  Primary = 'primary',
  Secondary = 'secondary',
}

export const NewDealsPage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const kycProvider = useKycProvider();

  const [simpleBarRef, setSimpleBarRef] = useState<SimpleBar | null>(null);

  const tabNavigationOptions = useMemo((): INavigationTabOption<EDealsTab>[] => {
    return [
      {
        name: EDealsTab.Primary,
        component: <PrimaryMarketTab wrapperRef={simpleBarRef?.getScrollElement()} />,
        tabLabel: intl.formatMessage({ id: 'market.primary' }),
        urlPath: 'primary',
      },
      {
        name: EDealsTab.Secondary,
        component: <SecondaryMarketTab wrapperRef={simpleBarRef?.getScrollElement()} />,
        tabLabel: intl.formatMessage({ id: 'market.secondary' }),
        urlPath: 'secondary',
      },
    ];
  }, [intl, simpleBarRef]);

  const { tabsComponent, routeNavigationComponent } = useTabNavigation(tabNavigationOptions);

  const isTabletView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const withUncompletedTaskGroups = kycProvider.uncompletedTaskGroups.length > 0;

  return (
    <PageContainer>
      <UncompletedTaskGroups wrapper={(body) => <Box marginBottom="24px">{body}</Box>} />

      <Card
        variant="page"
        className={clsx(classes.card, { 'with-content-above': withUncompletedTaskGroups })}
      >
        <SimpleBar className={classes.cardContentWrapper} ref={setSimpleBarRef}>
          <CardHeader
            className={classes.header}
            title={
              <>
                <Box display="flex" justifyContent="center">
                  {tabsComponent}
                </Box>

                {isTabletView && <Divider />}
              </>
            }
          />

          <CardContent className={classes.content}>{routeNavigationComponent}</CardContent>
        </SimpleBar>
        <BlockedFromInvestingModal />
      </Card>
    </PageContainer>
  );
};

import { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Doughnut } from 'react-chartjs-2';

import { IDealDto } from 'api/investor-client';
import { useFormatMoney, useSelectedInvestorAccount, useTextResize } from 'hooks';
import { getTotalRepaymentDetailsFromShares, moveDecimalPoint } from 'utils';

import { legendColors, useChartData, useOptions } from './helpers';
import { TDataType } from './types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },

  chartWrapper: {
    position: 'relative',
  },

  chartAmountContainer: {
    position: 'absolute',
    bottom: 'calc(15% * 1.5)',
    left: '25%',
    right: '25%',
    textAlign: 'center',
    overflow: 'hidden',
  },

  amountContext: {
    whiteSpace: 'nowrap',
    fontWeight: 500,
    textTransform: 'uppercase',
  },

  legendContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    flexWrap: 'wrap',
  },

  legendContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },

  legendDot: {
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
}));

interface Props {
  deal: IDealDto;
  type: TDataType;
}

export const RepaymentsChart = ({ deal, type }: Props) => {
  const classes = useStyles();
  const formatMoney = useFormatMoney({ suffix: ` ${deal.currency}` });

  const { selectedAccount } = useSelectedInvestorAccount();
  const investorId = selectedAccount?.investor?.id;

  const repaymentsData = useMemo(() => {
    const investorShares = deal.shares.filter((s) => s.investorId === investorId);
    return getTotalRepaymentDetailsFromShares(investorShares);
  }, [deal, investorId]);

  const { chartData, order, colorsType } = useChartData(repaymentsData, type);
  const { activeElement, chartOptions } = useOptions(chartData);

  const amountToDisplay = chartOptions.data.datasets[0].data.find(
    (d) => d.id === activeElement,
  )?.value;

  const { refText, refContent, recalculate } = useTextResize(12, 16);
  useEffect(() => recalculate(), [amountToDisplay, recalculate]);

  return (
    <Box className={classes.container}>
      <Box className={classes.chartWrapper}>
        <Doughnut {...chartOptions} />

        <Box ref={refContent} className={classes.chartAmountContainer}>
          <Typography ref={refText} variant="subtitle2" display="inline" whiteSpace="nowrap">
            {typeof amountToDisplay === 'number'
              ? formatMoney(moveDecimalPoint(amountToDisplay, -2))
              : '--'}
          </Typography>

          {activeElement && (
            <Typography
              component="p"
              variant="caption"
              className={classes.amountContext}
              color={legendColors[activeElement]}
            >
              <FormattedMessage id={`repayment.status.${activeElement}`} />
            </Typography>
          )}
        </Box>
      </Box>

      <Box className={classes.legendContainer}>
        {order.map((dataType, index) => (
          <Box className={classes.legendContent}>
            <Box
              className={classes.legendDot}
              sx={{ backgroundColor: legendColors[colorsType[index]] }}
            />
            <Typography variant="caption">
              <FormattedMessage id={`repayment.status.${dataType}`} />
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

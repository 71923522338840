import { alpha, Box, Button, CircularProgress, Link, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncCallback } from 'react-async-hook';
import Card from 'components/card';
import { downloadFile } from 'utils';
import { investorApiClient } from 'api';
import { RiCheckboxCircleFill } from 'react-icons/ri';


export const SecondaryMarketContractDownload = ({unlocked, investorId}: {unlocked?: boolean, investorId?: string}) => {
  const intl = useIntl();

  const downloadSecondaryMarketContract = useAsyncCallback(async () => {

    const { data } = await investorApiClient.investors.investorApiControllerGetSecondaryMarketContract(
      { format: 'arraybuffer' },
    );
    let fileName = intl.formatMessage({id: 'secondary.market.contract.filename'}, {investorId: investorId || ''});
    downloadFile(data as unknown as string, `${fileName}.pdf`);
  });

  return (
    <>
      <Card>
        <Card.Header>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography variant="h6" mb={1} fontWeight={600}>
              <FormattedMessage id="secondary.market.contract.card_title" />
            </Typography>
          </Box>
          {unlocked ? (
            <RiCheckboxCircleFill size={32} color='green' />
          ) : (
            <Typography>
              <FormattedMessage
                id='secondary.market.contract.description'
                values={{
                  aSup: (msg: string) => (
                    <Link
                      href='mailto:support@loantrade.io'
                      target='_blank'
                      rel='noopener noreferrer'
                      sx={(theme) => ({
                        color: theme.palette.common.lightBlue,
                        textDecorationColor: alpha(theme.palette.common.lightBlue, 0.4),
                      })}
                    >
                      {msg}
                    </Link>
                  ),
                }}
              />
            </Typography>
          )}
        </Card.Header>

        {!unlocked && <Card.Body>
          <Button variant="contained" size="large" disabled={downloadSecondaryMarketContract.loading} onClick={downloadSecondaryMarketContract.execute}>
            {downloadSecondaryMarketContract.loading && <CircularProgress size="1rem" />}{' '}
            <FormattedMessage id="secondary.market.contract.download_button" />
          </Button>
        </Card.Body>}
      </Card>
    </>
  );
};

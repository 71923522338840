import { ShareSaleWithShareAndDealDto } from 'api/investor-client';

export interface SecondaryDeal extends ShareSaleWithShareAndDealDto {
  id: string;
}

export interface SecondaryInvestment extends ShareSaleWithShareAndDealDto {
  id: string;
}

export enum MarketType {
  primary = 'primary',
  secondary = 'secondary',
}

export enum ShareSaleStatus {
  Open = 'Open',
  Cancelled = 'Cancelled',
  Sold = 'Sold',
}
import { Box, LinearProgress, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },

  progressWrapper: {
    maxWidth: '300px',
  },

  progress: {
    height: '8px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.barkSurface, 0.08),

    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.light,
    },

    '&.table-layout': {
      height: '6px',
    },
  },
}));

interface Props {
  totalAmount: number;
  investedAmount: number;
  currency: string;
  tableLayout?: boolean;
}

export const InvestedTotalProgress = ({ totalAmount, investedAmount, currency, tableLayout }: Props) => {
  const classes = useStyles();
  const formatMoney = useFormatMoney();

  return (
    <Box className={classes.container}>
      <Box>
        <Typography component="span" variant="subtitle3">
          {currency}{' '}
        </Typography>
        <Typography component="span" variant="subtitle3">
          {formatMoney(moveDecimalPoint(investedAmount, -2))}
        </Typography>
        <Typography component="span" variant="subtitle3">
          {' / '}
        </Typography>
        <Typography component="span" variant="body2" color="text.secondary">
          {formatMoney(moveDecimalPoint(totalAmount, -2))}
        </Typography>
      </Box>

      <LinearProgress
        className={clsx(classes.progress, { 'table-layout': tableLayout })}
        variant="determinate"
        value={(investedAmount / totalAmount) * 100}
      />
    </Box>
  );
};

import { Box, Button, Dialog, Divider, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SimpleBar from 'simplebar-react';
import { useAsyncDebounce } from 'react-table';

import { InvestmentWithDealDto, ShareSalePriceDto } from 'api/investor-client';
import { RiBuilding2Line, RiCarLine, RiCloseLine, RiHome2Line, RiPriceTag3Line } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncCallback } from 'react-async-hook';
import { investorApiClient } from 'api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { InlineTooltip } from 'components/UI';
import { PlusMinusStepper } from 'components';
import { Currency } from 'types';
import { useHistory } from 'react-router';
import { AmountInputBase } from 'components/forms';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    position: 'relative',
    gap: '32px',
    padding: '24px 16px',

    [theme.breakpoints.up('sm')]: {
      padding: '24px',
    },
  },

  dialog: {
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      alignSelf: 'flex-end',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },

  closeButton: {
    marginRight: '-8px',
    marginTop: '-6px',

    [theme.breakpoints.down('sm')]: {
      '& .MuiIconButton-root': {
        backgroundColor: theme.palette.background.blueSurface,
      },
    },
  },

  errorColor: {
    color: theme.palette.error.main,
  },

  headerBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '4px',
    border: '1px solid rgba(114, 119, 127, 0.16)',
    backgroundColor: 'rgba(114, 119, 127, 0.04)',
  },

  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '4px 12px',
    minWidth: '45px',
    borderRadius: '8px',
    border: '1px solid #E0E0E0'
  },

  amountBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '8px',
    padding: '24px 0',
    borderRadius: '4px',
    border: '1px solid rgba(114, 119, 127, 0.16)',
    backgroundColor: 'rgba(114, 119, 127, 0.04)',
  },

  premiumBox: {
    marginBottom: '8px',
    padding: '16px',
    borderRadius: '4px',
    border: '1px solid rgba(114, 119, 127, 0.12)',
    backgroundColor: 'rgba(114, 119, 127, 0.04)',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  amountInput: {
    color: '#9AA6AC',
    fontSize: '24px !important',
    lineHeight: '1.334 !important',
  }
}));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  share: InvestmentWithDealDto | null;
  onReload: () => Promise<void>;
}

export const SellModal = ({ isOpen, onClose, share, onReload }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const formatMoney = useFormatMoney({ prefix: `${share?.deal.currency} ` });

  const [saleAmount, setSaleAmount] = useState<number>(0);
  const [priceMultiplier, setPriceMultiplier] = useState<number>(0);
  const [salePrice, setSalePrice] = useState<ShareSalePriceDto | undefined>();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const { execute } = useAsyncCallback(async () => {
    if (!share?.deal || !saleAmount) {
      setSalePrice(undefined);
      return;
    }
    const { data } = await investorApiClient.investors.investorApiControllerGetShareSaleSellerPrice({
      providerId: share?.deal?.providerId,
      id: share?.deal?.id,
      saleAmount: saleAmount * 100,
      priceMultiplier: 1 + priceMultiplier / 100
    });
    setSalePrice(data);
  });

  const fetchPriceInfoDebounced = useAsyncDebounce(execute, 250);
  let minValue = moveDecimalPoint(Math.max(share?.deal?.configuration?.minInvestment || 0, 5000), -2);

  useEffect(() => {
    fetchPriceInfoDebounced();
  }, [saleAmount, priceMultiplier, fetchPriceInfoDebounced]);

  useEffect(() => {
    setSaleAmount(minValue);
  }, [share?.outstandingAmount]);

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      setSaleAmount(0);
      setPriceMultiplier(0);
      setSalePrice(undefined);
    }, 500);
  }, [onClose]);

  const handleCloseSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(false);
  }, []);

  const handleOpenOffering = useCallback(() => {
    handleCloseSuccessModal();
    history.push('./secondary');
  }, [history, handleCloseSuccessModal]);

  const handleSell = useAsyncCallback(async () => {
    if (!share?.deal || !saleAmount) return;
    await investorApiClient.investors.investorApiControllerSellShare(
      share?.deal?.providerId,
      share?.deal?.id,
      {
        saleAmount: saleAmount * 100,
        priceMultiplier: 1 + priceMultiplier / 100
      }
    );
    onReload();
    handleClose();
    setIsSuccessModalOpen(true);
  });

  const DealTypeIcon = useMemo(() => {
    switch (share?.deal?.risk?.productType) {
      case 'CORPORATE': {
        return RiBuilding2Line;
      }
      case 'PRIVATE': {
        return RiCarLine;
      }
      case 'REAL_ESTATE': {
        return RiHome2Line;
      }
    }
    return RiHome2Line;
  }, [share?.deal?.risk?.productType]);

  let shareOutstandingMoved = moveDecimalPoint(share?.outstandingAmount ?? 0, -2);
  let isTooLowSell = saleAmount < minValue;
  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        PaperProps={{ className: classes.dialog }}
        PaperComponent={SimpleBar}
      >
        <Paper className={classes.container}>
          <Box display="flex" flex={1} flexDirection="column" gap="24px" width="100%">
            <Box display="flex" flex={1} justifyContent="space-between">
              <Typography component="span" variant="subtitle2">
                <FormattedMessage id="create_offer_secondary_market" />
              </Typography>

              <IconButton onClick={handleClose} className={classes.closeButton}>
                <RiCloseLine />
              </IconButton>
            </Box>
            <Box className={classes.headerBox}>
              <Box
                sx={{
                  overflow: 'hidden',
                  flexGrow: 1,
                  flex: 1,
                  textDecoration: 'underline',
                }}>
                <Typography variant='subtitle3' noWrap>
                  {share?.id}
                </Typography>
              </Box>
              <Box display="flex" flex={2} justifyContent="flex-end" gap="8px">
                <Box className={classes.infoBox}>
                  <DealTypeIcon size={16} />
                </Box>
                <Box className={classes.infoBox}>
                  <Typography variant='subtitle2'>
                    {share?.deal.risk.rating}
                  </Typography>
                </Box>
                <Box gap="4px" className={classes.infoBox}>
                  <RiPriceTag3Line size={16} color="#72777F" />
                  <Typography variant='subtitle3'>
                    {formatMoney(shareOutstandingMoved)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="body2">
              1. <FormattedMessage id="set_nominal_amount_for_sell" />
            </Typography>
            <Box className={classes.amountBox}>
              <AmountInputBase
                currency={share?.deal.currency as Currency}
                className={classes.amountInput}
                disabled={handleSell.loading}
                value={saleAmount}
                inputProps={{
                  onValueChange: (data) => {
                    const max = shareOutstandingMoved;
                    const value = data.floatValue ?? 0;
                    if (value > max) setSaleAmount(max)
                    // else if (value < minValue) setSaleAmount(minValue)
                    else setSaleAmount(value);
                  },
                  isAllowed: (data) => {
                    const max = shareOutstandingMoved;
                    const value = data.floatValue ?? 0;
                    if (value > max) setSaleAmount(max)
                    // else if (value < minValue) setSaleAmount(minValue);
                    return value <= max
                  },
                  style: { textAlign: 'center', fontWeight: 500 }
                }}
              />
              <Typography variant="caption" color="#72777F">
                <span className={isTooLowSell ? classes.errorColor : undefined}>{intl.formatMessage({ id: 'min' })}: {formatMoney(minValue)}</span>; {intl.formatMessage({ id: 'max' })}: {formatMoney(shareOutstandingMoved)}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="body2">
              2. <FormattedMessage id="set_discount_premium_for_sell" />
            </Typography>

            <Divider sx={{ height: '1px', borderColor: 'text.secondary016' }} />

            <Box className={classes.premiumBox}>
              <Typography variant="body2" color="#72777F">
                <FormattedMessage id="set_premium_discount" />
              </Typography>
              <PlusMinusStepper
                min={-5}
                max={5}
                value={priceMultiplier}
                onChange={setPriceMultiplier}
                step={1}
              />
            </Box>

            <Typography variant="caption">
              <Box display="flex" flex={1} justifyContent="space-between">
                <span><FormattedMessage id="nominal_amount" /></span>
                <span>{moveDecimalPoint(salePrice?.nominalAmount ?? 0, -2)}</span>
              </Box>
            </Typography>

            <Typography variant="caption" color="#72777F">
              <Box display="flex" flex={1} justifyContent="space-between">
                <span><FormattedMessage id="premium_discount" /></span>
                <span>{moveDecimalPoint(salePrice?.multiplierAmount ?? 0, -2)}</span>
              </Box>
            </Typography>

            <Typography variant="caption" color="#72777F">
              <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                <Box display="flex" gap="8px" alignItems="center">
                  <FormattedMessage id="accrued_interest" />
                  <InlineTooltip
                    size={16}
                    tooltip={intl.formatMessage({
                      id: 'accrued_interest.sell.tooltip',
                    })}
                  />
                </Box>
                <span>{moveDecimalPoint(salePrice?.accruedInterest ?? 0, -2)}</span>
              </Box>
            </Typography>

            <Typography variant="caption" color="#72777F">
              <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
                <Box display="flex" gap="8px" alignItems="center">
                  <FormattedMessage id="service_fee" />
                  <InlineTooltip
                    size={16}
                    tooltip={intl.formatMessage({
                      id: 'service_fee.sell.tooltip',
                    })}
                  />
                </Box>
                <span>{-moveDecimalPoint(salePrice?.sellerFeeAmount ?? 0, -2)}</span>
              </Box>
            </Typography>

            <Divider sx={{ height: '1.5px', borderColor: 'text.primary' }} />

            <Box display="flex" flex={1} alignItems="center" justifyContent="space-between">
              <Typography variant="body2">
                <FormattedMessage id="total" />
              </Typography>
              <Typography variant="subtitle1" fontWeight={500}>
                {formatMoney(moveDecimalPoint((salePrice?.priceBeforeFees ?? 0) - (salePrice?.sellerFeeAmount ?? 0), -2))}
              </Typography>
            </Box>

          </Box>
          <Box display="flex" flex={1}>
            <Button sx={{ width: '100%' }} onClick={handleSell.execute} disabled={isTooLowSell || handleSell.loading}>
              <Typography variant="subtitle2" textTransform="uppercase">
                <FormattedMessage id="start_offering_for" /> {formatMoney(moveDecimalPoint(salePrice?.priceBeforeFees ?? 0, -2))}
              </Typography>
            </Button>
          </Box>
        </Paper>
      </Dialog>
      <Dialog
        open={isSuccessModalOpen}
        maxWidth={'xs'}
        PaperProps={{ className: classes.dialog }}
        PaperComponent={SimpleBar}
      >
        <Paper className={classes.container}>
          <Box display="flex" flex={1} flexDirection="column" alignItems="center" width="100%">
            <Box display="flex" flex={1} justifyContent="flex-end" width="100%">
              <IconButton onClick={handleCloseSuccessModal} className={classes.closeButton}>
                <RiCloseLine />
              </IconButton>
            </Box>
            <img src="/img/success.svg" alt="" width={140} height={140} />
          </Box>
          <Box display="flex" flexDirection="column" gap="16px" textAlign="center" padding="8px">
            <Typography variant="subtitle1" fontWeight={600}>
              <FormattedMessage id="share_now_on_secondary_market" />
            </Typography>
            <Typography variant="body2" color="#72777F">
              <FormattedMessage
                id="find_offering_in_portfolio"
                values={{
                  aSup: (msg: string) => (
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.primary"
                      onClick={handleOpenOffering}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      {msg}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mb="8px">
            <Button sx={{ minWidth: '80px' }} onClick={handleCloseSuccessModal}>
              <Typography variant="subtitle3" textTransform="uppercase">
                <FormattedMessage id="ok" />
              </Typography>
            </Button>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Stack, Theme, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useAsyncCallback } from 'react-async-hook';

import { InvestmentSummaryResultDto } from 'api/investor-client';
import { investorApiClient } from 'api';
import Card from 'components/card';
import { Select } from 'components/forms';
import { InlineTooltip } from 'components/UI';
import { SubmitFormOnChange } from 'components';
import { Currency, DealType } from 'types';
import { getProductTypeOptions } from 'utils';

import { InvestmentSummaryTable } from './InvestmentSummaryTable';
import { makeStyles } from '@mui/styles';

interface Props {
  defaultCurrency?: Currency;
}

interface Values {
  productType: DealType | -1;
  currency: Currency;
}

type TTypeOptions = Array<{ label: string; value: DealType | -1 }>;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minHeight: '510px',
  },
}));

export const InvestmentSummary = ({ defaultCurrency }: Props) => {
  const intl = useIntl();
  const [stat, setStat] = useState<InvestmentSummaryResultDto | null>(null);

  const productTypeOptions = useMemo((): TTypeOptions => {
    return [
      {
        label: intl.formatMessage({ id: 'all' }),
        value: -1,
      },
      ...getProductTypeOptions(intl),
    ];
  }, [intl]);

  const currencyOptions = useMemo(() => {
    return Object.values(Currency).map((e) => ({
      value: e,
      label: e,
    }));
  }, []);

  const getSummaryStatistics = useAsyncCallback(async (values: Values) => {
    // prettier-ignore
    const { data } = await investorApiClient.investors.investorApiControllerGetInvestmentSummary({
     productType: values.productType === -1 ? undefined : values.productType,
      currency: values.currency,
    });

    setStat(data);
  });

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Card.Header
        variant="condensed"
        title={
          <>
            {intl.formatMessage({ id: 'investment_summary' })}{' '}
            <InlineTooltip
              size={18}
              tooltip={intl.formatMessage({ id: 'investment_summary.tooltip' })}
            />
          </>
        }
      />
      <Card.Body loadingContent={getSummaryStatistics.loading}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 4,
            gap: 4,
          }}
        >
          <Typography component="span">
            <FormattedMessage id="borrower.type" />:
          </Typography>

          <Formik<Values>
            initialValues={{
              productType: productTypeOptions[0].value,
              currency: defaultCurrency ?? currencyOptions[0].value,
            }}
            onSubmit={(v) => getSummaryStatistics.execute(v)}
          >
            <Form>
              <Stack direction="row" spacing={4}>
                <Field
                  id="productType"
                  name="productType"
                  sx={{ width: '150px' }}
                  component={Select}
                  options={productTypeOptions}
                  variant="filter"
                  disableSelectedClass
                />
                <Field
                  id="currency"
                  name="currency"
                  sx={{ width: '90px' }}
                  component={Select}
                  options={currencyOptions}
                  variant="filter"
                  disableSelectedClass
                />
              </Stack>
              <SubmitFormOnChange initialSubmit />
            </Form>
          </Formik>
        </Box>

        <InvestmentSummaryTable data={stat} />
      </Card.Body>
    </Card>
  );
};

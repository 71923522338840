import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAsyncCallback } from 'react-async-hook';

import { BalanceDto, BulkError } from 'api/investor-client';
import { VirtualAccountDTO } from 'api/payments-client';
import { documentApiClient, investorApiClient, paymentsApiClient } from 'api';
import { IBaseBasketDealIdentifier, useBasketProvider } from 'providers/basket';
import { useInvestorAccounts } from 'providers/investor-accounts';
import { useSelectedInvestorAccount } from 'hooks';
import { MarketType, SecondaryDeal } from 'types/secondary-market';

import type { InvestmentConfirmationModalOptions } from '../InvestmentConfirmationModal';
import { PaymentsDetails } from '../PaymentsDetails';
import { InvestmentsReview } from '../InvestmentsReview';
import { BulkResultWithPayment } from 'types';
import { useIntl } from 'react-intl';
import { getAgreementFullOfferId, getFullOfferId } from 'providers/basket/helpers';
import { getDealDetails } from 'utils';
import { FileMetaInvestor, SearchResultInvestor } from 'api/document-client';
import { toast } from 'react-toastify';

interface Props {
  deals: SecondaryDeal[];
  openSummaryModal: (modalProps: InvestmentConfirmationModalOptions) => void;
  paymentDetailsContainer?: HTMLElement | null;
  agreements: Record<string, {id: string, name: string}>
  handleAgreementsAdd: (data: {agreement: {id: string, name: string}, dealId: string, providerId?: string, totalAmount: number}[]) => void
}
export const SecondaryMarketBasketContent = (props: Props) => {
  const { deals, openSummaryModal, paymentDetailsContainer, agreements, handleAgreementsAdd } = props;

  const intl = useIntl();
  const basket = useBasketProvider();
  const { updateAccountBalance } = useInvestorAccounts();
  // prettier-ignore
  const { selectedAccount, isViewOnly, isBlockedFromInvesting, isKycFlowUnfinished } = useSelectedInvestorAccount();

  const [virtualAccount, setVirtualAccount] = useState<VirtualAccountDTO | undefined>(undefined);
  const [amountError, setAmountError] = useState(false);
  const [oldBalance, setOldBalance] = useState<BalanceDto[] | null>(null);
  useEffect(() => {
    setOldBalance(selectedAccount?.VABalance ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!selectedAccount?.VABalance]);
  const dealDetails = useMemo(() => !selectedAccount?.investor ? {} : Object.fromEntries(
    deals.map(deal =>
      [getFullOfferId(undefined, deal.id),
        getDealDetails(deal.shareWithDeal.deal, selectedAccount.investor!.id)])), [selectedAccount?.investor?.id, deals]);

  const missingAgreements = useMemo(() => {
    return basket.secondary.deals.map(deal => {
      let baseFullId = getFullOfferId(undefined, deal.id);
      let oldCommitmentAmount = dealDetails[baseFullId]?.common?.investorCommittedAmount || 0;
      let totalCommitmentAmount = (deal.amount || 0) + oldCommitmentAmount;
      let agreementFullDealId = getAgreementFullOfferId(
        undefined,
        deal.id,
        totalCommitmentAmount
      );
      return agreements[agreementFullDealId] ? {saleId: '', totalAmount: 0} : {
        saleId: deal.id,
        totalAmount: totalCommitmentAmount
      }; //keep only missing ids in the array, then filter for truthy values
    }).filter(el => el.saleId);
  }, [basket, agreements]);

  const uploadMissingAgreements = useAsyncCallback(async () => {
    const agreements: {
      agreement: { id: string; name: string };
      saleId: string;
      totalAmount: number;
    }[] = [];

    const concurrencyLimit = 3; // Set your desired concurrency limit

    const processAgreement = async (missingAgreement: { saleId: string, totalAmount: number}) => {
      let agreement: FileMetaInvestor;
      // Check if the agreement already exists
      const dealId = deals.find(el => el.shareSale.id === missingAgreement.saleId)?.shareSale.dealId;
      const existingAgreementsData = (await documentApiClient.api.investorApiControllerList({
        searchCriteria: {
          documentType: "Investor Agreements basket",
          totalInvestmentAmount: missingAgreement.totalAmount,
          fileNamePartial: dealId,
        },
        orderCriteria: [{ name: "created", order: "DESC" }],
      })).data as unknown as SearchResultInvestor;

      if (existingAgreementsData.files.length) {
        agreement = existingAgreementsData.files[0];
      } else {
        const generatedAgreementData = await investorApiClient.investors.investorApiControllerGenerateSecondaryBasketInvestorAgreement(
          missingAgreement.saleId,
          { amount: missingAgreement.totalAmount },
        );

        agreement = generatedAgreementData.data as FileMetaInvestor;
      }

      agreements.push({
        saleId: missingAgreement.saleId,
        totalAmount: missingAgreement.totalAmount,
        agreement,
      });
    };

    // Use a concurrency limiter to control the number of concurrent requests
    const limitConcurrency = async (arr: {saleId: string, totalAmount: number}[], limit: number) => {
      const results: any[] = [];

      const executeNext = async () => {
        const item = arr.shift();
        if (item) {
          await processAgreement(item);
          results.push(item);
          await executeNext();
        }
      };

      const promises: Array<() => Promise<void>> = [];
      for (let i = 0; i < limit && i < arr.length; i++) {
        promises.push(executeNext);
      }

      await Promise.all(promises.map(promise => promise()));
      return results;
    };

    // Use the concurrency limiter to process agreements concurrently
    await limitConcurrency(missingAgreements.slice(), concurrencyLimit);

    // All agreements have been processed at this point
    handleAgreementsAdd(agreements.map(el =>
      ({ agreement: el.agreement, totalAmount: el.totalAmount, dealId: el.saleId, providerId: undefined })));
  });


  const totalAmount = useMemo(
    () => basket.secondary.deals.reduce((sum, deal) => sum + deal.amount, 0),
    [basket],
  );

  const handleDealRemove = useCallback(
    (deal: IBaseBasketDealIdentifier) => basket.secondary.removeDeal(deal),
    [basket.secondary],
  );

  const handleDealsClear = useCallback(() => {
    basket.secondary.clear();
  }, [basket]);

  const fetchVirtualAccount = useAsyncCallback(async () => {
    const { data } = await paymentsApiClient.api.investorApiControllerGetCurrentVirtualAccount();
    setVirtualAccount(data);
    return data;
  });

  const buyShare = useAsyncCallback(
    async (providerId: string, dealId: string, investorId: string) => {
      try {
        const { data } = await investorApiClient.investors.investorApiControllerBuyShare(
          providerId,
          investorId,
          dealId,
        );

        return !!data;
      } catch (error) {
        return null;
      }
    },
  );

  const submit = useAsyncCallback(async () => {
    let va = virtualAccount;

    if (!va) {
      try {
        va = await fetchVirtualAccount.execute();
      } catch {
        va = undefined;
      }
    }

    const result: BulkResultWithPayment[] = [];
    const errors: BulkError[] = [];

    for (const basketDeal of basket.secondary.deals) {
      const deal = deals.find(d => d.shareSale.id === basketDeal.id);
      let isPaid: boolean | null = false;
      if (deal) {
        try {
          const response = await buyShare.execute(deal.shareSale.providerId, deal.shareSale.dealId, deal.shareSale.investorId);
          if (response === null) {
            isPaid = null;
          } else {
            isPaid = response
          }
        } catch {
          isPaid = null;
        }

        if (isPaid) {
          result.push({
            request: {
              dealId: deal.shareSale.dealId,
              providerId: deal.shareSale.providerId,
              amount: deal.shareSale.latestCalculatedPrice,
              fee: deal.shareSale.buyerFeeAmount,
              currency: deal.shareWithDeal.deal.currency
            },
            deal: deal.shareWithDeal.deal,
            response: deal.shareSale,
            paymentResult: true
          })
        } else {
          errors.push({
            request: {
              dealId: deal.shareSale.dealId,
              providerId: deal.shareSale.providerId,
              amount: deal.shareSale.latestCalculatedPrice,
              fee: deal.shareSale.buyerFeeAmount,
              currency: deal.shareWithDeal.deal.currency
            },
            deal: deal.shareWithDeal.deal,
            response: isPaid === null ? {
              status: 500,
              name: 'Error',
              message: intl.formatMessage({ id: 'buy_secondary_market.error_message' })
            } : {
              status: 500,
              name: 'Error',
              message: intl.formatMessage({ id: 'buy_secondary_market.error_balance_message' })
          },
          })
        }
      }
    }

    openSummaryModal({
      oldBalance: oldBalance ?? undefined,
      commitmentResult: {
        result,
        errors
      },
      virtualAccount: virtualAccount,
    });

    basket.secondary.clear();
    updateAccountBalance();
  });

  return (
    <>
      <InvestmentsReview
        type={MarketType.secondary}
        data={deals}
        agreements={agreements}
        onDealRemove={handleDealRemove}
        onDealsClear={handleDealsClear}
        onError={setAmountError}
      />

      {paymentDetailsContainer &&
        createPortal(
          <PaymentsDetails
            type={MarketType.secondary}
            agreementsReady={missingAgreements.length === 0}
            uploadMissingAgreements={uploadMissingAgreements}
            submitLoading={submit.loading}
            onSubmit={submit.execute}
            submitDisabled={
              isViewOnly ||
              isBlockedFromInvesting ||
              isKycFlowUnfinished ||
              amountError ||
              submit.loading ||
              totalAmount === 0
            }
          />,
          paymentDetailsContainer,
        )}
    </>
  );
};


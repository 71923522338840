import { makeStyles } from '@mui/styles';
import theme from 'config/theme';

export const useOverviewTableStyles = makeStyles(
  (theme) => ({
    table: {
      '&.divided': {
        '& tr': {
          borderBottom: '1px solid #BDBDBD',
        },
      },
    },
    row: {
      '& *:last-child': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      '& *:first-child': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
      '& th,td': {
        border: 'none',
        padding: '5px 10px',
      },
      '&.header': {
        '& th,td': {
          fontWeight: 600,
        },
      },
      '&.highlighted': {
        '& th,td': {
          background: '#ededed',
          fontWeight: 600,
        },
      },
      '&.result': {
        '& th,td': {
          background: '#ededed',
          fontWeight: 600,
        },
      },
      '&.value-accent': {
        '& td': {
          fontWeight: 600,
        },
      },
      '&.header-accent': {
        '& th': {
          fontWeight: 600,
        },
      },
      '&.divider': {
        position: 'relative',

        '& th,td': {
          paddingBottom: '12px',
        },
        '& + tr > *': {
          paddingTop: '12px',
        },

        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '6%',
          right: '6%',
          height: '1px',
          background: '#C4C4C4',
        },
      },
      '&.color': {
        '&-default > th': {
          color: theme.palette.text.primary,
        },
        '&-primary > th': {
          color: theme.palette.primary.light,
        },
      },
    },
  }),
  { defaultTheme: theme },
);

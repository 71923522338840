import { useMemo } from 'react';
import { IntlShape } from 'react-intl';

import { IOption } from 'components/forms';
import { DealType, DealRating, Currency } from 'types';
import { getProductTypeOptions, getRatingOptions, getCurrencyOptions } from 'utils';

export const useProductTypeOptions = (intl: IntlShape) => {
  return useMemo((): IOption<DealType>[] => {
    return [
      {
        value: undefined,
        label: 'All',
        selectDefault: true,
        singleSelectValue: true,
      },
      ...getProductTypeOptions(intl),
    ];
  }, [intl]);
};

export const useRatingOptions = () => {
  return useMemo((): IOption<DealRating>[] => {
    return [
      {
        value: undefined,
        label: 'All',
        selectDefault: true,
        singleSelectValue: true,
      },
      ...getRatingOptions(),
    ];
  }, []);
};

export const useCurrencyOptions = () => {
  return useMemo((): IOption<Currency>[] => {
    return [
      {
        value: undefined,
        label: 'All',
        selectDefault: true,
        singleSelectValue: true,
      },
      ...getCurrencyOptions(),
    ];
  }, []);
};

import { CommonFilterValues } from '../types';
import { InvestorApiControllerGetSharesParams } from 'api/investor-client';

export enum InvestmentStatusFilterOption {
  All = 'all',
  Active = 'active',
  Unpaid = 'unpaid',
  Inactive = 'inactive',
  Completed = 'completed',
}

export interface CommonPrimaryInvestmentFilterValues extends CommonFilterValues {
  minDuration?: number;
  maxDuration?: number;
}

export interface PrimaryInvestmentFilterValues extends CommonPrimaryInvestmentFilterValues {
  status?: InvestmentStatusFilterOption;
}

export type PrimaryInvestmentFilterPayload = Omit<
  InvestorApiControllerGetSharesParams,
  'id' | 'pageSize' | 'pageIndex'
>;

export enum EPrimaryInvestmentSortOption {
  Currency = 'Currency',
  Duration = 'Duration',
  InvestmentAmount = 'InvestmentAmount',
  InvestmentDate = 'InvestmentDate',
  Rating = 'Rating',
  ProductType = 'ProductType',
  ShareStatus = 'SharesStatus',
}

import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { TransactionsTable } from './TransactionsTable';
import { PageContainer } from 'components/UI';

interface Props {}

enum ECurrencyTab {
  CHF = 'CHF',
  EUR = 'EUR',
}

export const TransactionsPage = (props: Props) => {
  const [tab, setTab] = useState<ECurrencyTab>(ECurrencyTab.CHF);

  return (
    <PageContainer>
      <Box sx={{ mb: 4 }}>
        <Tabs value={tab} onChange={(_, value) => setTab(value)}>
          <Tab label="CHF" value={ECurrencyTab.CHF} />
          <Tab label="EUR" value={ECurrencyTab.EUR} />
        </Tabs>
      </Box>

      <Box>
        <TransactionsTable currency={tab} />
      </Box>
    </PageContainer>
  );
};

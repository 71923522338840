import { Box, Typography, styled } from '@mui/material';

import { UserDto } from 'api/investor-client';

const SAvatar = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

interface Props {
  user: UserDto | null | undefined;
}

export const Avatar = ({ user }: Props) => {
  const initials = user
    ? `${user.firstName?.charAt(0) ?? ''}${user.lastName?.charAt(0) ?? ''}`.trim()
    : '';

  return (
    <SAvatar>
      <Typography variant={initials.length > 1 ? 'h6' : 'h5'} fontWeight={500} color="common.white">
        {initials}
      </Typography>
    </SAvatar>
  );
};

import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { RiAddFill, RiSubtractFill } from 'react-icons/ri';

interface Props {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (arg0: number) => void;
}

const useStyles = makeStyles((theme) => ({
  circle: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid rgba(114, 119, 127, 0.16)',
    color: '#72777F',
    background: 'white',
    cursor: 'pointer',
    userSelect: 'none',
    outline: 0,

    '&:hover': {
      color: theme.palette.text.primary,
      border: '1px solid #72777F',
    },
    '&.disabled': {
      cursor: 'default',
      color: 'rgba(114, 119, 127, 0.16)',
      border: '1px solid rgba(114, 119, 127, 0.12)',
      '&:hover': {
        border: '1px solid rgba(114, 119, 127, 0.12)',
        color: 'rgba(114, 119, 127, 0.16)',
      },
    }
  }
}));

export const PlusMinusStepper = ({
  min, max, value, step, onChange
}: Props) => {
  const classes = useStyles();

  const handleMinus = useCallback(() => {
    if (value <= min) return;
    onChange(value - step);
  }, [onChange, step, value, min]);

  const handlePlus = useCallback(() => {
    if (value >= max) return;
    onChange(value + step);
  }, [onChange, step, value, max]);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
      <Box className={clsx(
        classes.circle,
        { disabled: value === min }
      )}
        onClick={handleMinus}>
        <RiSubtractFill size={24} />
      </Box>
      <Typography
        variant="subtitle3"
        sx={{ minWidth: '32px', textAlign: 'center' }}
      >
        {value}%
      </Typography>
      <Box className={clsx(
        classes.circle,
        { disabled: value === max }
      )}
        onClick={handlePlus}>
        <RiAddFill size={24} />
      </Box>
    </Box>
  )
}
import { Box, Button, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PageContainer } from 'components/UI';
import { FcSupport } from 'react-icons/fc';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  apology: {
    borderBottom: '1px solid black',
    margin: '0 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const NotFoundPage = ({withPortfolioLink}: {withPortfolioLink?: boolean}) => {
  const classes = useStyles();
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <PageContainer maxWidth="md" centered>
      <Paper>
        <Box alignSelf="center">
          <FormattedMessage id="not-found-apology">
            {(txt) => (
              <h3 className={classes.apology}>
                <FcSupport size={60} />
                <span>{txt}</span>
              </h3>
            )}
          </FormattedMessage>
        </Box>
        <Box alignSelf="center" style={{ margin: '25px 50px' }}>
          <FormattedMessage
            id="not-found-error"
            values={{
              address: (
                <a
                  href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                  style={{ color: 'rgb(82 158 219)' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </a>
              ),
            }}
          >
            {(txt) => <span>{txt}</span>}
          </FormattedMessage>
        </Box>
        {withPortfolioLink && <Box alignSelf="center">
          <Button variant="contained">
            <Link to={`/accounts/${accountId}/portfolio`}>
              <FormattedMessage id="not-found-portfolio" />
            </Link>
          </Button>
        </Box>}
      </Paper>
    </PageContainer>
  );
};

import { useState, useEffect, useCallback } from 'react';

import { getDealsFromCache, saveDealsToCache } from './helpers';
import { IBaseBasketDealIdentifier, EMarketTypeBasket, MarketTypeBasket } from './types';

const getDealComparator = <Deal extends IBaseBasketDealIdentifier>(deal: Deal) => {
  return (d: Deal) => d.id === deal.id && d.providerId === deal.providerId;
};

const getDealFilter = <Deal extends IBaseBasketDealIdentifier>(deal: Deal) => {
  return (d: Deal) => d.id !== deal.id || d.providerId !== deal.providerId;
};

export const useMarketTypeBasket = <Search extends IBaseBasketDealIdentifier, Deal extends Search>(
  type: EMarketTypeBasket,
  investorId: string | null,
): MarketTypeBasket<Search, Deal> => {
  const [deals, setDeals] = useState<Deal[]>(() => {
    if (investorId) return getDealsFromCache(type, investorId);
    return [];
  });

  useEffect(() => {
    if (investorId) setDeals(getDealsFromCache(type, investorId));
  }, [type, investorId]);

  useEffect(() => {
    if (investorId) saveDealsToCache(type, investorId, deals);
  }, [type, investorId, deals]);

  const getDeal = useCallback(
    (deal: Search) => deals.find(getDealComparator(deal)) ?? null,
    [deals],
  );

  const setDeal = useCallback((deal: Deal) => {
    setDeals((deals) => [...deals.filter(getDealFilter(deal)), { ...deal }]);
  }, []);

  const removeDeal = useCallback((deal: Search) => {
    setDeals((deals) => deals.filter(getDealFilter(deal)));
  }, []);

  const clear = useCallback(() => {
    setDeals([]);
  }, []);

  return { deals, getDeal, setDeal, removeDeal, clear };
};

import { ReactElement, ReactNode, useMemo } from 'react';
import { TableRow, TableCell, Table, TableBody } from '@mui/material';
import clsx from 'clsx';

import Card from 'components/card';
import { useOverviewTableStyles } from 'styles';

export interface ITableRow {
  cols: Array<string | number | ReactElement | null>;
  variant?:
  | 'header'
  | 'highlighted'
  | 'header-accent'
  | 'value-accent'
  | 'normal';
  color?: 'default' | 'primary';
  divider?: boolean;
}

export interface TableProps {
  tableOnly?: boolean;
  title?: string;
  rows: ITableRow[];
  loadingContent?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  className?: string;
  cellClassName?: string;
  divided?: boolean;
  emptyMessage?: ReactNode;
}

export const OverviewTable = (props: TableProps) => {
  const classes = useOverviewTableStyles();

  const collAmount = useMemo(() => {
    return Math.max(...props.rows.map((r) => r.cols.length));
  }, [props.rows]);

  return (
    <Table
      size="small"
      className={clsx(
        classes.table,
        { divided: props.divided },
        props.className,
      )}
    >
      <TableBody>
        {props.rows.map((row, idx) => (
          <TableRow
            key={idx}
            className={clsx(
              classes.row,
              { highlighted: row.variant === 'highlighted' },
              { header: row.variant === 'header' },
              { 'header-accent': row.variant === 'header-accent' },
              { 'value-accent': row.variant === 'value-accent' },
              { divider: row.divider },
              `color-${row.color ?? 'default'}`,
            )}
          >
            {Array.from({ length: collAmount }, () => null).map((_, colIdx) => (
              <TableCell
                key={colIdx}
                component={
                  colIdx === 0 || row.variant === 'header' ? 'th' : 'td'
                }
                align={props.textAlign ?? (colIdx > 0 ? 'right' : 'left')}
                className={props.cellClassName}
              >
                {row.cols[colIdx]}
              </TableCell>
            ))}
          </TableRow>
        ))}

        {props.rows.length === 0 && <>{props.emptyMessage}</>}
      </TableBody>
    </Table>
  );
};

export const DataOverviewTable = (props: TableProps) => {
  if (props.tableOnly) return <OverviewTable {...props} />;

  return (
    <Card>
      {props.title && (
        <Card.Header
          variant="condensed"
          title={props.title}
          divided={props.divided}
          withWrapperBlock={false}
        />
      )}
      <Card.Body
        sx={{
          ...(props.divided ? { paddingTop: '0px !important' } : {}),
        }}
        wrapperSx={{ overflow: 'auto' }}
        loadingContent={props.loadingContent}
      >
        <OverviewTable {...props} />
      </Card.Body>
    </Card>
  );
};

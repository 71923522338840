import React from 'react';

import { ChangeUserEmailDto, UserDto, UserUpdateDto } from 'api/investor-client';
import { DefaultLanguage, Language } from 'types';

export interface IUserDetailsContext {
  userDetails?: UserDto;
  loading: boolean;
  initiallyLoaded: boolean;
  refresh: () => Promise<void> | void;
  updateUser: (arg0: UserUpdateDto) => Promise<void> | void;
  updateEmail: (arg0: ChangeUserEmailDto) => Promise<void> | void;
  currentLanguage: Language;
  setLanguage: (arg0: Language) => Promise<void> | void;
  languageChanging: boolean;
}

export const UserDetailsContext = React.createContext<IUserDetailsContext>({
  userDetails: undefined,
  loading: false,
  initiallyLoaded: false,
  refresh: () => {},
  updateUser: () => {},
  updateEmail: () => {},
  currentLanguage: DefaultLanguage,
  setLanguage: () => {},
  languageChanging: false,
});

import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { BulkError, ShareCommitmentDto, ShareSaleDto } from 'api/investor-client';
import { CurrencyIcon } from 'components/UI';
import { BulkResultWithPayment, Currency } from 'types';

import { PaymentDetails } from './PaymentDetails';
import { PaymentSummary } from './PaymentSummary';
import { DownloadAgreementAction } from './DownloadAgreementAction';
import { MarketType } from 'types/secondary-market';

export const isError = (payment: BulkResultWithPayment | BulkError): payment is BulkError => {
  return typeof (payment.response as any)?.status === 'number';
};

interface Props {
  type?: MarketType;
  currency: Currency;
  payments: Array<BulkResultWithPayment | BulkError>;
  newBalance: number;
  oldBalance: number;
  ddActive?: boolean;
  isPaidTab?: boolean;
}

export const PaymentCard = (props: Props) => {
  const { currency, payments, newBalance, oldBalance, ddActive, isPaidTab } = props;

  const isTabletView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const isRejected = payments.some((payment) => isError(payment));

  return (
    <Box>
      <Box display="flex" alignItems="center" gap="16px" marginBottom="24px">
        <CurrencyIcon currency={currency} size={18} />

        <Typography
          variant={isTabletView ? 'body1' : 'subtitle1'}
          fontWeight={500}
        >
          <FormattedMessage id={`payment_details.${currency}`} />
        </Typography>
      </Box>

      {payments.map((payment) => {
        const isRejected = isError(payment);

        return (
          <PaymentDetails
            type={props.type}
            payment={payment}
            isRejected={isRejected}
            actionTitle={isRejected ? 'rejected_reason' : 'agreement'}
            actionContent={
              isRejected ? payment.response.message : (
                <DownloadAgreementAction
                  id={payment.deal.id}
                  totalAmount={(payment.response as ShareCommitmentDto).totalAmount || (payment.response as ShareSaleDto).saleAmount || 0}
                />
              )
            }
          />
        );
      })}

      {!isRejected && newBalance !== undefined && (
        <PaymentSummary
          currency={currency}
          payments={payments}
          isPaidTab={isPaidTab}
          newBalance={newBalance}
          oldBalance={oldBalance}
          ddActive={!!ddActive}
        />
      )}
    </Box>
  );
};

export enum TransactionType {
  Payin = 'PAYIN',
  Payout = 'PAYOUT'
}

export enum TransactionMethod {
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH',
  DebitDirect = 'DEBIT_DIRECT',
  LSV = 'LSV'
}

export enum TransactionMethodExtended {
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH',
  DebitDirect = 'DEBIT_DIRECT',
  Repayment = 'Repayment',
  Investment = 'Investment',
}

import { SxProps, SystemStyleObject } from '@mui/system';

export const combineSx = <T extends object>(
  ...styles: Array<SxProps<T> | undefined>
) => {
  return (theme: T) => {
    const outStyles = styles.map((arg) => {
      if (arg === undefined) return {} as SystemStyleObject<T>;
      if (typeof arg === 'function') return arg(theme);
      return arg;
    });

    return Object.assign({}, ...outStyles);
  };
};

export const mixinSx = (condition: boolean, sx: SxProps) => {
  return condition ? sx : ({} as SystemStyleObject);
};

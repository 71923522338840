import { createContext } from 'react';

interface ILayoutContext {
  /** Title in the Header */
  title: string;
  setTitle(title: string | null): void;
}

export const LayoutContext = createContext<ILayoutContext>({
  title: 'Loantrade',
  setTitle: () => {},
});

import { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { RiInformationLine } from 'react-icons/ri';

import { IDealDto } from 'api/investor-client';
import { InvestmentCard } from './InvestmentCard';
import { MarketType, SecondaryDeal } from 'types/secondary-market';
import { useLocallySavedState } from 'hooks';

const useStyles = makeStyles((theme) => ({
  investmentsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },

  removeAllBox: {
    marginTop: '48px',
    marginBottom: '8px',
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      marginTop: '96px',
    },
  },
  priceChangeBox: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid rgba(0, 99, 154, 0.12)',
    marginBottom: '24px',
    background: 'rgba(0, 99, 154, 0.04)'
  },
  tooltip: {
    color: theme.palette.primary.light
  }
}));

type ReviewDeal = IDealDto | SecondaryDeal;

interface Props {
  type: MarketType;
  data: ReviewDeal[];
  onDealRemove(deal: ReviewDeal): void;
  onDealsClear(): void;
  onError(error: boolean): void;
  loadingSize?: number;
  agreements: Record<string, {id: string, name: string}>
}

export const InvestmentsReview = memo((props: Props) => {
  const { data, onDealRemove, onDealsClear, onError, loadingSize, type, agreements } = props;

  const classes = useStyles();
  const [priceChangesCollapsed, setPriceChangesCollapsed] = useLocallySavedState<boolean>('price-changes-info-closed', false);
  const [errors, setErrors] = useState<boolean[]>([]);

  const handleClosePriceChangesInfo = useCallback(() => {
    setPriceChangesCollapsed(true);
  }, [setPriceChangesCollapsed]);

  const handleError = useCallback(
    (index: number, err: boolean) => {
      setErrors((state) => {
        const newState = [...state];
        newState[index] = err;
        return newState;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.length],
  );

  useLayoutEffect(() => {
    setErrors([]);
  }, [data.length]);

  useEffect(() => {
    onError(errors.some((e) => e === true));
  }, [errors, onError]);

  return (
    <Box>
      {type === 'secondary' && !priceChangesCollapsed && (
        <Box className={classes.priceChangeBox}>
          <Box display="flex" gap="16px">
            <RiInformationLine size={18} className={classes.tooltip} />
            <Typography variant="body2">
              <FormattedMessage id="price_changes_description" />
            </Typography>
          </Box>
          <Button
            size="large"
            variant="text"
            onClick={handleClosePriceChangesInfo}
          >
            <FormattedMessage id="dismiss" />
          </Button>
        </Box>
      )}
      <Box className={classes.investmentsWrapper}>
        {loadingSize
          ? Array.from({ length: Math.min(loadingSize, 3) }, (_, index) => (
            <Skeleton key={index} variant="rectangular" height={148} />
          ))
          : data.map((deal, index) => (
            <InvestmentCard
              type={type}
              key={deal.id}
              agreements={agreements}
              deal={deal}
              index={index}
              onClose={() => onDealRemove(deal)}
              onError={handleError}
            />
          ))}
      </Box>

      {data.length > 0 && (
        <Box className={classes.removeAllBox}>
          <Button variant="outlined" onClick={onDealsClear}>
            <FormattedMessage id="remove_all_investments" />
          </Button>
        </Box>
      )}
    </Box>
  );
});

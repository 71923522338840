import { useState } from 'react';
import { Box, Button, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCloseLine } from 'react-icons/ri';
import { useAsync } from 'react-async-hook';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { paymentsApiClient } from 'api';
import { WithDialogPros, withDialog } from 'wrappers';
import { Currency } from 'types';

import { DirectDebitTab } from './DirectDebitTab';
import { WireTransferTab } from './WireTransferTab';
import { useDialogStyles } from '../shared';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 16px 0',
    borderBottom: `2px solid ${theme.palette.divider}`,

    '&.noBorder': {
      border: 'none',
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
    },
  },

  closeButtonContainer: {
    margin: '-16px -8px 0 0',
    textAlign: 'right',
  },

  tabs: {
    marginBottom: '-2px',
  },
}));

enum DepositTab {
  DD = 'DD',
  Wire = 'Wire',
}

interface Props extends WithDialogPros {
  unpaidCommitments?: number;
  bankAccount?: string;
  isDDActivated?: boolean;
  currency: Currency;
  onReload: () => Promise<void>;
}

export const DepositModal = withDialog(
  ({ onClose, onReload, unpaidCommitments, isDDActivated, bankAccount, currency }: Props) => {
    const classes = useStyles();
    const intl = useIntl();

    const [tab, setTab] = useState<DepositTab>(isDDActivated ? DepositTab.DD : DepositTab.Wire);
    const [depositInitAmount, setDepositInitAmount] = useState('');

    const { result: qrCode } = useAsync(async () => {
      const { data } = await paymentsApiClient.api.investorApiControllerGetQrCode({ currency });
      return data;
    }, [currency]);

    const { result: depositDetails } = useAsync(async () => {
      const { data } = await paymentsApiClient.api.investorApiControllerGetDepositDetails({
        currency,
      });
      return data;
    }, [currency]);

    return (
      <>
        <Box className={clsx(classes.header, { noBorder: !!depositInitAmount })}>
          <Box className={classes.closeButtonContainer}>
            <IconButton onClick={onClose}>
              <RiCloseLine />
            </IconButton>
          </Box>

          {!depositInitAmount && (
            <Tabs value={tab} onChange={(_, value) => setTab(value)} className={classes.tabs}>
              <Tab
                disabled={!isDDActivated}
                label={intl.formatMessage({ id: 'direct_debit' })}
                value={DepositTab.DD}
              />
              <Tab label={intl.formatMessage({ id: 'wire_transfer' })} value={DepositTab.Wire} />
            </Tabs>
          )}
        </Box>

        {!!depositInitAmount ? (
          <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
            <img src="/img/bank-transfer.svg" alt="" />
            <Box textAlign="center">
              <Typography variant="subtitle2">
                {intl.formatMessage({ id: 'deposit_initiated' }, { amount: depositInitAmount })}
              </Typography>
              <Typography variant="body2" mt={2}>
                {intl.formatMessage({ id: '2_working_days' })}
              </Typography>
            </Box>
            <Button
              size="large"
              variant="text"
              onClick={onClose}
              sx={{
                marginTop: '12px',
                marginBottom: '32px',
              }}
            >
              {intl.formatMessage({ id: 'dismiss' })}
            </Button>
          </Box>
        ) : tab === DepositTab.DD ? (
          <DirectDebitTab
            currency={currency}
            onReload={onReload}
            setDepositInitAmount={setDepositInitAmount}
            bankAccount={bankAccount}
            unpaidCommitments={unpaidCommitments}
            isDDActivated={isDDActivated}
          />
        ) : tab === DepositTab.Wire ? (
          <WireTransferTab
            currency={currency}
            qrCode={qrCode}
            depositDetails={depositDetails}
            unpaidCommitments={unpaidCommitments}
            isDDActivated={isDDActivated}
            onClose={onClose}
          />
        ) : null}
      </>
    );
  },
  undefined,
  useDialogStyles,
);

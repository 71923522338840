import { PropsWithChildren, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import ProductFruits from 'react-product-fruits';

import { userApiClient } from 'api';
import { ChangeUserEmailDto, UserDto, UserUpdateDto } from 'api/investor-client';
import { DefaultLanguage, Language } from 'types';
import { useLocallySavedState } from 'hooks';

import { UserDetailsContext } from './context';

interface Props {
  loadInitially?: boolean;
}

export const UserDetailsProvider = ({
  loadInitially = true,
  children,
}: PropsWithChildren<Props>) => {
  // prettier-ignore
  const [currentLanguage, setCurrentLanguage] = useLocallySavedState<Language>('last-lang', DefaultLanguage);
  const [user, setUser] = useState<UserDto | undefined>();
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const fetchUser = useAsyncCallback(async () => {
    const { data } = await userApiClient.user.userApiControllerGetUserDetails({
      withAccounts: true,
    });
    setUser(data);
  });

  const updateUser = useAsyncCallback(async (userUpdate: UserUpdateDto) => {
    const { data } = await userApiClient.user.userApiControllerUpdateUserDetails(userUpdate);
    // Accounts are not returned in update, so to keep them update is just merged
    setUser((user) => ({ ...user, ...data }));
  });

  const updateEmail = useAsyncCallback(async (dto: ChangeUserEmailDto) => {
    await userApiClient.user.userApiControllerRequestChangeEmail(dto);
  });

  const setLanguage = useAsyncCallback(async (language: Language) => {
    setCurrentLanguage(language);
    await updateUser.execute({ language });
  });

  useEffect(() => {
    if (user) {
      if (!initiallyLoaded && user.language && user.language !== DefaultLanguage) {
        switch (user.language) {
          case 'en-CH':
            setCurrentLanguage(Language.EN);
            break;
          // case 'fr-CH':
          //   setCurrentLanguage(Language.FR);
          //   break;
          // case 'it-CH':
          //   setCurrentLanguage(Language.IT);
          //   break;
          default:
            setCurrentLanguage(Language.DE);
            break;
        }
      }

      setInitiallyLoaded(true);
    }
  }, [initiallyLoaded, setCurrentLanguage, user]);

  return (
    <UserDetailsContext.Provider
      value={{
        userDetails: user,
        loading: fetchUser.loading || updateUser.loading,
        initiallyLoaded: initiallyLoaded,
        refresh: fetchUser.execute,
        updateUser: updateUser.execute,
        updateEmail: updateEmail.execute,

        currentLanguage,
        setLanguage: setLanguage.execute,
        languageChanging: setLanguage.loading,
      }}
    >
      {children}
      {user && (
        <ProductFruits
          projectCode={process.env.REACT_APP_PRODUCT_FRUITS_PROJECT_CODE}
          language={user?.language?.split('-')?.[0] ?? 'de'}
          email={user?.email}
          username={user?.id}
        />
      )}
    </UserDetailsContext.Provider>
  );
};

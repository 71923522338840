import { Box, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import {
  EPrimaryDealSortOption,
  primaryDealSortVariantsMap,
} from 'components/filters/primary-deal';
import { InlineTooltip } from 'components/UI';
import { SortIcon } from 'components';
import { SortOrder } from 'types';

import { useTableRowStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
  },

  cell: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },

  firstCell: {},

  lastCell: {
    minHeight: 'auto',
    paddingRight: '8px',
    justifyContent: 'flex-end',
    color: alpha(theme.palette.text.secondary, 0.36),
  },

  sortIcon: {
    fontSize: '20px',
  },

  tooltip: {
    color: alpha(theme.palette.text.secondary, 0.36),
  },
}));

interface Props {
  sort: { sortBy: string; sortOrder: SortOrder } | undefined;
  onSortChange: (sortState: { sortBy: string; sortOrder: SortOrder } | undefined) => void;
}

export const SecondaryDealHeader = ({ sort, onSortChange }: Props) => {
  const tableRowClasses = useTableRowStyles();
  const classes = useStyles();
  const intl = useIntl();

  const getSortState = (sortOption: EPrimaryDealSortOption) => {
    if (!sort) return undefined;
    const sortValue = primaryDealSortVariantsMap[sortOption][sort.sortOrder];
    if (sortValue.sortBy === sort.sortBy) return sort.sortOrder;
    return undefined;
  };

  const handleSortClick = (sortOption: EPrimaryDealSortOption) => {
    return () => {
      if (sort) {
        const sortValue = primaryDealSortVariantsMap[sortOption][sort.sortOrder];

        if (sortValue.sortBy === sort.sortBy) {
          switch (sort.sortOrder) {
            case SortOrder.ASC:
              return onSortChange(primaryDealSortVariantsMap[sortOption][SortOrder.DESC]);

            case SortOrder.DESC:
              return onSortChange(undefined);
          }
        }
      }

      onSortChange(primaryDealSortVariantsMap[sortOption][SortOrder.ASC]);
    };
  };

  return (
    <Box className={classes.card}>
      <Box display="flex" flexGrow={1}>
        <Box
          className={clsx(
            tableRowClasses.cell,
            tableRowClasses.dealPriceCell,
            classes.cell,
            classes.firstCell,
          )}
        >
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="deal_price" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.Amount)}
            onClick={handleSortClick(EPrimaryDealSortOption.Amount)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.percentCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            %
          </Typography>
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.riskCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="risk" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.Rating)}
            onClick={handleSortClick(EPrimaryDealSortOption.Rating)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.productTypeCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="type" />
          </Typography>

          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.ProductType)}
            onClick={handleSortClick(EPrimaryDealSortOption.ProductType)}
          />
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.nominalValueCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="nominal_value" />
          </Typography>
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.timeLeftCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="terms_left" />
          </Typography>
        </Box>

        <Box className={clsx(tableRowClasses.cell, tableRowClasses.irrCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="interest" />
          </Typography>
          <SortIcon
            className={classes.sortIcon}
            sortOrder={getSortState(EPrimaryDealSortOption.IRR)}
            onClick={handleSortClick(EPrimaryDealSortOption.IRR)}
          />
          <InlineTooltip
            tooltip={intl.formatMessage({ id: 'interest.description_tooltip' })}
            className={classes.tooltip}
          />
        </Box>

        {/* <Box className={clsx(tableRowClasses.cell, tableRowClasses.expirationCell, classes.cell)}>
          <Typography variant="caption" color="text.secondary" noWrap>
            <FormattedMessage id="offer_expires_in" />
          </Typography>
        </Box> */}
      </Box>

      <Box
        className={clsx(
          tableRowClasses.cell,
          tableRowClasses.inputCell,
          classes.cell,
          classes.lastCell,
        )}
      ></Box>
    </Box>
  );
};

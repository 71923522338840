/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface FileMetaInvestor {
  /** @format date-time */
  created: string;
  id: string;
  name: string;
  documentType: "NONE" | "KOPIEAUSWEIS" | "Investor Agreements";
}

export interface SearchResultInvestor {
  files: FileMetaInvestor[];
  nextPageToken: string;
}

export interface FileMetaBaseInvestor {
  name: string;
  documentType: "NONE" | "KOPIEAUSWEIS" | "Investor Agreements";
}

export interface FileUploadInvestorDto {
  content: File;
  meta: FileMetaBaseInvestor;
}

export interface SearchCriteriaInvestor {
  documentType?: "NONE" | "KOPIEAUSWEIS" | "Investor Agreements" | "Investor Agreements basket";
  fileNameExact?: string;
  fileNamePartial?: string;
  totalInvestmentAmount?: number;
}

export interface SortCriteriaEntry {
  name: "created" | "modified";
  order: "DESC" | "ASC";
}

export interface SearchInvestorDto {
  searchCriteria?: SearchCriteriaInvestor;
  orderCriteria?: SortCriteriaEntry[];
  pageSize?: number;
  nextPageToken?: string;
}

export interface ProblemDocument {
  status: number;
  title: string;
  detail?: string;
  instance?: string;
  type?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Documents investor API
 * @version 1.0
 * @contact
 *
 * The Documents investor API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags investorFiles
     * @name InvestorApiControllerUploadFile
     * @request POST:/api/investorFiles/upload
     * @secure
     */
    investorApiControllerUploadFile: (data: FileUploadInvestorDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investorFiles/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investorFiles
     * @name InvestorApiControllerDownload
     * @request GET:/api/investorFiles/download/{id}
     * @secure
     */
    investorApiControllerDownload: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investorFiles/download/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investorFiles
     * @name InvestorApiControllerDownloadZip
     * @request POST:/api/investorFiles/download/zip
     * @secure
     */
    investorApiControllerDownloadZip: (data: SearchInvestorDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investorFiles/download/zip`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investorFiles
     * @name InvestorApiControllerList
     * @request POST:/api/investorFiles/search
     * @secure
     */
    investorApiControllerList: (data: SearchInvestorDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/investorFiles/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}

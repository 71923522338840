import { PropsWithChildren, ReactNode } from 'react';
import { Box, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';

interface Props {
  title?: ReactNode;
  sx?: SxProps<Theme>;
  wrapperClassName?: string;
  boxClassName?: string;
  boxSx?: SxProps<Theme>;
  withWrapperBlock?: boolean;
  /** default: `normal` */
  variant?: 'large' | 'normal' | 'condensed' | 'small';
  divided?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'unset',
    padding: '12px 16px',
    borderBottom: `1px solid ${grey[300]}`,

    '&.large': {
      padding: '32px 16px 16px',
      borderBottom: `none`,
    },

    '&.condensed': {
      borderBottom: 'none',
      paddingBottom: 0,
    },

    '&.divided': {
      position: 'relative',
      paddingBottom: '8px',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '16px',
        right: '16px',
        height: '2px',
        background: '#BDBDBD',
      },
      '& + .MuiPaper-root': {
        paddingTop: '8px',
      },
    },
  },

  title: {
    color: '#222',

    '&.small': {
      fontWeight: 600,
    },

    '&.condensed': {
      fontWeight: 600,
    },
  },
}));

export const CardHeader = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const {
    title,
    sx,
    wrapperClassName,
    boxClassName,
    boxSx,
    withWrapperBlock = true,
    variant = 'normal',
    divided,
    children,
  } = props;

  const getHeaderVariant = () => {
    switch (variant) {
      case 'large':
        return 'h5';

      case 'normal':
        return 'h6';

      case 'small':
        return 'body1';

      case 'condensed':
      default:
        return 'body1';
    }
  };

  return (
    <Paper
      elevation={0}
      className={clsx(classes.paper, variant, { divided }, wrapperClassName)}
      sx={sx}
    >
      {title && (
        <Typography variant={getHeaderVariant()} className={clsx(classes.title, variant)}>
          {title}
        </Typography>
      )}
      {!!children && (
        <>
          {withWrapperBlock ? (
            <Box className={boxClassName} sx={boxSx}>
              {children}
            </Box>
          ) : (
            { children }
          )}
        </>
      )}
    </Paper>
  );
};

import { Button } from '@mui/material';
import { dequal } from 'dequal';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import { useFormikContext } from 'formik';

import { SortOrder } from 'types';
import { CommonFilterValues, IFilterSort } from './types';

interface Props {
  label: string;
  value: IFilterSort;
  defaultSelect?: boolean;
}

export const SortButton = ({ label, value, defaultSelect }: Props) => {
  const formik = useFormikContext<CommonFilterValues>();
  const isSelected = formik.values.sort ? dequal(formik.values.sort, value) : !!defaultSelect;

  return (
    <Button
      variant="filter"
      startIcon={value.sortOrder === SortOrder.ASC ? <RiSortAsc /> : <RiSortDesc />}
      className={isSelected ? 'value-selected' : undefined}
      onClick={() => formik.setFieldValue('sort', value)}
    >
      {label}
    </Button>
  );
};

import { ComponentType } from 'react';
import { Dialog, DialogProps } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import clsx from 'clsx';

export type DialogOptions = Omit<DialogProps, 'open' | 'onClose'> & {
  disableBackdropClose?: boolean;
};

export interface WithDialogPros {
  open: boolean;
  onClose?: () => void;
  dialogOptions?: DialogOptions;
}

export const withDialog = <P extends WithDialogPros>(
  Component: ComponentType<P>,
  options?: DialogOptions,
  useStyles?: (props?: any) => Partial<ClassNameMap<'root' | 'paper' | 'backdrop'>>,
) => {
  return (props: P) => {
    const classes = useStyles?.();

    const classNames = options?.className?.split(' ') ?? [];
    const isCentered = classNames.includes('centered');

    return (
      <Dialog
        open={props.open}
        onClose={(_, reason) => {
          if (props.dialogOptions?.disableBackdropClose && reason === 'backdropClick') return;
          props.onClose?.();
        }}
        {...options}
        {...props.dialogOptions}
        className={clsx(classes?.root, options?.className, props.dialogOptions?.className)}
        PaperProps={{
          ...options?.PaperProps,
          ...props.dialogOptions?.PaperProps,
          className: clsx(
            classes?.paper,
            options?.PaperProps?.className,
            props.dialogOptions?.PaperProps?.className,
          ),
        }}
        BackdropProps={{
          ...options?.BackdropProps,
          ...props.dialogOptions?.BackdropProps,
          className: clsx(
            classes?.backdrop,
            options?.BackdropProps?.className,
            props.dialogOptions?.BackdropProps?.className,
          ),
        }}
        TransitionProps={{
          unmountOnExit: true,
          mountOnEnter: true,
          ...options?.TransitionProps,
          ...props.dialogOptions?.TransitionProps,
          className: clsx(
            options?.TransitionProps?.className,
            props.dialogOptions?.TransitionProps?.className,
            { centered: isCentered },
          ),
        }}
      >
        <Component {...props} />
      </Dialog>
    );
  };
};

import { Link } from 'react-router-dom';
import { Box, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { RiSideBarFill } from 'react-icons/ri';
import clsx from 'clsx';

import { ESidebarSize } from '../types';
import { ANIMATION_SPEED, MOBILE_HEIGHT_BREAKPOINT } from '../constants';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { ReactComponent as LogoIcon } from 'assets/svg/logo-icon.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 32px 48px',
    transition: theme.transitions.create(['padding'], {
      duration: ANIMATION_SPEED,
    }),

    '&.medium': {
      padding: '32px 32px 72px',
    },

    '&.desktop': {
      padding: '24px 24px 96px',

      '&.collapsed': {
        padding: '24px 14px 104px',
      },
    },
  },

  logoContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    color: theme.palette.common.lightBlue,

    '& > svg': {
      margin: '2px 0',
      transition: theme.transitions.create(['width'], {
        duration: ANIMATION_SPEED,
      }),
    },

    '&.collapsed': {
      '& > svg': {
        width: '40px',
      },
    },
  },
  logoText: {
    width: '180px',
  },
  title: {
    position: 'absolute',
    left: '100%',
    width: 'min-content',
    marginLeft: '16px',
    transition: theme.transitions.create(['opacity'], {
      duration: ANIMATION_SPEED,
    }),

    '&.collapsed': {
      opacity: '0',
    },
  },

  collapseButton: {
    alignSelf: 'flex-start',
    color: theme.palette.common.white,
    margin: '-8px -8px 0px 32px',
    transition: theme.transitions.create(['opacity'], {
      duration: ANIMATION_SPEED / 2,
    }),

    '&.collapsed': {
      opacity: '0',
    },
  },
}));

interface Props {
  collapsed?: boolean;
  expandedByHover?: boolean;
  sidebarSize?: ESidebarSize;
  onCollapseClick?: () => void;
  hideExpandButton?: boolean;
}

export const SidebarHeader = (props: Props) => {
  const { collapsed, expandedByHover, sidebarSize, onCollapseClick, hideExpandButton } = props;

  const classes = useStyles();
  const intl = useIntl();

  const mediumHeight = useMediaQuery(MOBILE_HEIGHT_BREAKPOINT);
  const isDesktop = sidebarSize === ESidebarSize.Desktop;
  const isCollapsed = collapsed && isDesktop;

  return (
    <Box
      className={clsx(
        classes.header,
        { medium: mediumHeight },
        { desktop: isDesktop },
        { collapsed: isCollapsed },
      )}
    >
      <Link to="/" className={clsx(classes.logoContainer, { collapsed: isCollapsed })}>
        {!isCollapsed && <Logo height={40} />}
        {isCollapsed && <LogoIcon width={40} height={40} />}
      </Link>

      {!hideExpandButton && sidebarSize === ESidebarSize.Desktop && (
        <Tooltip
          title={intl.formatMessage({
            id: `sidebar_collapse_tooltip.${!collapsed && expandedByHover ? 'expand' : 'collapse'}`,
          })}
        >
          <IconButton
            className={clsx(classes.collapseButton, { collapsed: isCollapsed })}
            onClick={() => onCollapseClick?.()}
          >
            <RiSideBarFill size={'20px'} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

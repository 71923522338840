export function downloadCSV(data: string, fileName: string): void {
  const linkSource = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = `${fileName}.csv`;
  downloadLink.click();
}

export const retrieveFileNameFromHeader = (
  header: Record<string, string>,
  fallbackName = 'download.txt',
) => {
  const disposition = header['content-disposition'];
  if (!disposition) return fallbackName;

  const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

  if (matches != null && matches[1]) {
    const name = matches[1].replace(/['"]/g, '');
    if (name) return name;
  }

  return fallbackName;
};

export const downloadFile = (data: Blob | string, fileName: string) => {
  let blob: Blob;

  if (data instanceof Blob) {
    blob = data;
  } else {
    blob = new Blob([data], { type: 'octet/stream' });
  }

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

import { EMarketTypeBasket } from './types';

const getLocalCacheKey = (type: EMarketTypeBasket, id: string) => {
  return `${type}-deals-basket_${id}`;
};
export const getLocalAgreementsCacheKey = (type: EMarketTypeBasket, id: string) => {
  return `${type}-deal-agreements_${id}`;
};

export const getAgreementsFromCache = (type: EMarketTypeBasket, investorId?: string): Record<string, {id: string, name: string}> => {
  try {
    if (!investorId) return {};
    const agreements = investorId && localStorage.getItem(getLocalAgreementsCacheKey(type, investorId));

    return agreements ? JSON.parse(agreements) : {};
  } catch (error) {
    investorId && localStorage.setItem(getLocalAgreementsCacheKey(EMarketTypeBasket.Primary, investorId), '{}');
    return {};
  }
};

export const getAgreementFullOfferId = (providerId?: string, id?: string, totalAmount?: number ): string => {
  return providerId ? `${providerId}_${id}_${totalAmount}` : `${id}_${totalAmount}`;
}

export const getFullOfferId = (providerId?: string, id?: string): string => {
  return (providerId ? `${providerId}_${id}` : id) ?? '';
}

export const addAgreementsToCache = (type: EMarketTypeBasket, investorId: string | undefined, extraAgreements: Array<{
  agreement: {id: string, name: string},
  totalAmount: number,
  providerId?: string,
  dealId: string
}>) => {
  if(!investorId) return {};
  const agreements = getAgreementsFromCache(type, investorId);
  extraAgreements.forEach(extraAgreement => {
    let fullDealId = getAgreementFullOfferId(extraAgreement.providerId, extraAgreement.dealId, extraAgreement.totalAmount);
    agreements[fullDealId] = extraAgreement.agreement;
  });
  localStorage.setItem(getLocalAgreementsCacheKey(type, investorId), JSON.stringify(agreements));
  return agreements;
};

export const saveDealsToCache = <Deal extends AnyObject>(
  type: EMarketTypeBasket,
  investorId: string,
  deals: Deal[],
) => {
  localStorage.setItem(getLocalCacheKey(type, investorId), JSON.stringify(deals));
};

export const getDealsFromCache = <Deal extends AnyObject>(
  type: EMarketTypeBasket,
  investorId: string,
): Deal[] => {
  try {
    const deals = localStorage.getItem(getLocalCacheKey(type, investorId));
    if (deals === null) return [];
    return JSON.parse(deals);
  } catch (error) {
    saveDealsToCache(type, investorId, []);
    return [];
  }
};

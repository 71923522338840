import { FC, useCallback, useRef, useState } from 'react';
import { Box, Button, Dialog, Link, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RiCheckLine, RiFileCopyLine, RiQrCodeLine } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import { DepositDetailsDto, QrCodeDto } from 'api/payments-client';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '32px 24px',
    background: theme.palette.background.barkSurface004,
  },

  qrDialog: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },

  closeButtonContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  qrContainer: {
    width: 'fit-content',
    display: 'flex',
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
  },

  dialogContent: {
    padding: '32px',
    gap: '32px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
  },
}));

const ItemLabel: FC = ({ children }) => (
  <Typography variant="body1" color="text.secondary" marginBottom="4px">
    {children}
  </Typography>
);

const ItemValue: FC<{ last?: boolean }> = ({ children, last }) => {
  const theme = useTheme();
  let timeout = useRef<NodeJS.Timeout | undefined>();

  const [copied, setCopied] = useState<boolean>(false);

  const copy = useCallback(() => {
    if (typeof children === 'string') {
      navigator.clipboard.writeText(children);
      if (timeout.current) clearTimeout(timeout.current);
      setCopied(true);
      timeout.current = setTimeout(() => setCopied(false), 1000);
    }
  }, [children]);

  return (
    <Typography
      variant="body1"
      onClick={copy}
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: !last ? '16px' : undefined,
        fontWeight: 500,
        cursor: 'pointer',

        '&:hover .copy': {
          display: 'block',
        },

        '& .copy': {
          marginLeft: '16px',
          color: ({ palette }) => (copied ? palette.success.main : palette.text.secondary),
          fontSize: '18px',

          [theme.breakpoints.up('sm')]: {
            display: copied ? 'block' : 'none',
          },
        },
      }}
    >
      {children}
      {copied ? <RiCheckLine className="copy" /> : <RiFileCopyLine className="copy" />}
    </Typography>
  );
};

interface Props {
  currency: Currency;
  depositDetails?: DepositDetailsDto;
  qrCode: QrCodeDto | undefined;
  unpaidCommitments?: number;
  isDDActivated?: boolean;
  onClose?: () => void;
}

export const WireTransferTab = ({
  currency,
  qrCode,
  depositDetails,
  isDDActivated,
  unpaidCommitments,
  onClose,
}: Props) => {
  const [qrModalOpened, setQrModalOpened] = useState(false);
  const classes = useStyles();
  const intl = useIntl();

  const formatMoney = useFormatMoney({ prefix: `${currency} ` });

  return (
    <Box className={classes.dialog}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight="500">
          {intl.formatMessage({ id: 'payment_details' })}
        </Typography>
        <Button
          disabled={!qrCode?.svg}
          variant="filter"
          startIcon={<RiQrCodeLine />}
          onClick={() => setQrModalOpened(true)}
        >
          <FormattedMessage id="qr_code" />
        </Button>
      </Box>

      <Typography variant="subtitle3">
        {intl.formatMessage({ id: 'deposit_qr_description' })}
      </Typography>

      <Box className={classes.dialogContent}>
        <ItemLabel>{intl.formatMessage({ id: 'account_payable_to' })}</ItemLabel>
        <ItemValue>{depositDetails?.creditor.name}</ItemValue>

        <ItemLabel>{intl.formatMessage({ id: 'iban' })}</ItemLabel>
        <ItemValue>{depositDetails?.creditor.account}</ItemValue>

        <ItemLabel>{intl.formatMessage({ id: 'reference' })}</ItemLabel>
        <ItemValue last>{depositDetails?.reference}</ItemValue>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={onClose}
        style={{ textTransform: 'uppercase' }}
      >
        <Typography variant="subtitle2">
          <FormattedMessage id="confirm_payment" />
        </Typography>
      </Button>

      {!isDDActivated && (
        <Typography variant="subtitle3">
          <FormattedMessage
            id="enable_direct_debit"
            values={{
              a: (msg: string) => (
                <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{msg}</Link>
              ),
            }}
          />
        </Typography>
      )}

      <Typography variant="caption" color="#72777F">
        <FormattedMessage
          id="deposit_customer_support"
          values={{
            a: (msg: string) => (
              <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{msg}</Link>
            ),
          }}
        />
      </Typography>

      <Dialog
        open={qrModalOpened}
        onClose={() => setQrModalOpened(false)}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <Box className={clsx(classes.dialog, classes.qrDialog)}>
          <Typography variant="subtitle3" color="#72777F">
            {intl.formatMessage({ id: 'scan_qr_code' })}
          </Typography>
          <div
            dangerouslySetInnerHTML={{ __html: qrCode?.svg ?? '' }}
            className={classes.qrContainer}
          />

          {!!unpaidCommitments && (
            <Typography variant="subtitle3" color="error">
              <FormattedMessage id="unpaid_commitments" />:{' '}
              {formatMoney(moveDecimalPoint(unpaidCommitments, -2))}
            </Typography>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

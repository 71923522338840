import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import { DataOverviewTable, ITableRow } from 'components';
import { SubTabProps } from './InvestmentInformation';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';

export const OverviewSubTab = ({ deal, totalAmount, repaymentsData, loading }: SubTabProps) => {
  const intl = useIntl();
  const formatMoney = useFormatMoney();

  const repaymentOverviewRows = useMemo((): ITableRow[] => {
    const rdValue = repaymentsData?.pricingInfo.value as any;
    // prettier-ignore
    const serviceFeeName = typeof rdValue?.interestFee === 'number'
      ? ` (${intl.formatMessage(
        { id: 'amount_interest_payments.value' },
        { amount: formatMoney(moveDecimalPoint(rdValue.interestFee, 4)) },
      )})`
      : '';

    const expectedNetReturn =
      (repaymentsData?.expectedInterest ?? totalAmount ?? 0) +
      (repaymentsData?.expectedFee ?? totalAmount ?? 0);
    const totalNetRepayment = (totalAmount ?? 0) + expectedNetReturn;

    return [
      {
        cols: [
          intl.formatMessage({ id: 'amortization' }),
          deal.currency,
          formatMoney(moveDecimalPoint(totalAmount, -2)),
        ],
        variant: 'highlighted',
      },
      {
        cols: [
          intl.formatMessage({ id: 'expected_gross_return' }),
          deal.currency,
          formatMoney(moveDecimalPoint(repaymentsData?.expectedInterest ?? totalAmount, -2)),
        ],
      },
      {
        cols: [
          `${intl.formatMessage({ id: 'service_fee' })}${serviceFeeName}`,
          deal.currency,
          formatMoney(moveDecimalPoint(repaymentsData?.expectedFee ?? totalAmount, -2)),
        ],
      },
      {
        cols: [
          intl.formatMessage({ id: 'total_net_repayment.expected' }),
          deal.currency,
          formatMoney(moveDecimalPoint(totalNetRepayment, -2)),
        ],
        variant: 'highlighted',
      },
      {
        cols: [
          intl.formatMessage({ id: 'expected_net_return' }),
          deal.currency,
          formatMoney(moveDecimalPoint(expectedNetReturn, -2)),
        ],
      },
      {
        cols: [
          intl.formatMessage({ id: 'periodic_net_repayment_amount' }),
          deal.currency,
          formatMoney(moveDecimalPoint(totalNetRepayment / deal.repayments.length, -2)),
        ],
      },
      {
        cols: [
          intl.formatMessage({ id: 'irr.maximum_net' }),
          '',
          `${((repaymentsData?.xirr || 0) * 100).toFixed(2)}%`,
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.formatMessage, deal, formatMoney, totalAmount, repaymentsData]);

  // const irrRows = useMemo((): ITableRow[] => {
  //   return [
  //     {
  //       cols: [
  //         intl.formatMessage({ id: 'irr.maximum_net.short' }),
  //         `${((deal.dealProviderShare?.xirr ?? 0) * 100).toFixed(4)}%`,
  //       ],
  //     },
  //     {
  //       cols: [
  //         intl.formatMessage({ id: 'irr.expected_net.short' }),
  //         repaymentsData?.xirr
  //           ? `${(repaymentsData.xirr * 100).toFixed(4)}%`
  //           : '~',
  //       ],
  //     },
  //   ];
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [intl.formatMessage, deal, repaymentsData]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const investorProtectionRows = useMemo((): ITableRow[] => {
    return [
      {
        cols: [intl.formatMessage({ id: 'solidarity_insurance' }), `n/a`],
      },
      {
        cols: [intl.formatMessage({ id: 'solidarity_group' }), 'n/a'],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.formatMessage]);

  return (
    <Grid container mt={0} spacing={4}>
      <Grid item md={6} sm={12}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'repayment_overview' })}
              rows={repaymentOverviewRows}
              loadingContent={loading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sm={12}>
        <Grid container direction="column" spacing={4}>
          {/* <Grid item>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'irr' })}
              rows={irrRows}
            />
          </Grid> */}
          {/* TODO: add feature later on */}
          {/* <Grid item>
            <DataOverviewTable
              title={intl.formatMessage({ id: 'investor_protection' })}
              rows={investorProtectionRows}
            />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  emphasize,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAsyncCallback } from 'react-async-hook';
import { FormattedMessage } from 'react-intl';
import { RiDownload2Line } from 'react-icons/ri';
import clsx from 'clsx';

import { documentApiClient } from 'api';
import { BulkResultWithPayment } from 'types';
import { downloadFile } from 'utils';
import { EBasketReviewTabType, ITabObjet } from '../types';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  tabs: {
    [theme.breakpoints.up('sm')]: {
      minHeight: 'unset',
      overflow: 'unset !important',

      '& .MuiTabs-scroller': {
        overflow: 'unset !important',
      },

      '& .MuiTabs-indicator': {
        bottom: '-17px',
      },

      '& .MuiTabs-flexContainer': {
        marginTop: '7px',
      },
    },
  },

  divider: {
    [theme.breakpoints.down('sm')]: {
      '&.with-tabs': {
        paddingTop: '0 !important',
      },

      '& .MuiDivider-root': {
        margin: '0 -16px',
      },
    },
  },
}));

interface Props {
  results: BulkResultWithPayment[] | undefined;
  tabs: ITabObjet[];
  activeTab?: EBasketReviewTabType;
  onTabChange?: (newTab: EBasketReviewTabType) => void;
  rejectedType?: boolean;
}

export const ConfirmationModalSubtitle = (props: Props) => {
  const { results, tabs, activeTab, onTabChange, rejectedType } = props;

  const classes = useStyles();
  const [loadingDelay, setLoadingDelay] = useState(true);

  // Because of async processes on the backend
  useEffect(() => {
    setLoadingDelay(true);
    const delay = 2000 + (results?.length || 0) * 1000;
    setTimeout(() => setLoadingDelay(false), delay);
  }, [results?.length]);

  const downloadZIP = useAsyncCallback(async () => {
    if (!results) return;

    // prettier-ignore
    const { data } = await documentApiClient.api.investorApiControllerDownloadZip(
      {
        searchCriteria: {
          documentType: 'Investor Agreements',
          fileNamePartial: [...new Set(results.map((r) => r.deal.id))].join(','),
        },
      },
      { format: 'arraybuffer' },
    );

    if (typeof data === 'string' || (data as any) instanceof ArrayBuffer) {
      downloadFile(data as unknown as string, 'Agreements.zip');
    }
  });

  const isTabletView = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const withTabs = tabs.length > 1;

  const downloadButton = (
    <Grid item xs={6} sm={4} className={classes.buttonWrapper}>
      {!rejectedType && (
        <Button
          variant="outlined"
          endIcon={
            downloadZIP.loading || loadingDelay ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <RiDownload2Line />
            )
          }
          disabled={downloadZIP.loading || loadingDelay}
          onClick={() => {
            !downloadZIP.loading && !loadingDelay && downloadZIP.execute();
          }}
        >
          <FormattedMessage id="download.all" />
        </Button>
      )}
    </Grid>
  );

  return (
    <Grid container spacing={4} sx={{ marginBottom: '32px' }}>
      <Grid item xs={6} sm={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1" sx={{ color: '#000', fontWeight: 600 }}>
          <FormattedMessage id={rejectedType ? 'rejected_investments' : 'order_summary'} />
        </Typography>
      </Grid>

      {!isTabletView && downloadButton}

      {withTabs && (
        <Grid item xs={8} sm={8}>
          <Tabs
            value={activeTab}
            className={classes.tabs}
            onChange={(_, newVal) => onTabChange?.(newVal)}
          >
            {tabs.map((tab) => {
              const tabColor = tab.color
                ? tab.value === activeTab
                  ? tab.color
                  : emphasize(tab.color, 0.4)
                : undefined;

              return (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={
                    <span style={{ color: tabColor }}>
                      <FormattedMessage id={`investment_confirmation.type.${tab.value}`} />{' '}
                      <span>({tab.size})</span>
                    </span>
                  }
                />
              );
            })}
          </Tabs>
        </Grid>
      )}

      {isTabletView && downloadButton}

      <Grid item xs={12} className={clsx(classes.divider, { 'with-tabs': withTabs })}>
        <Divider />
      </Grid>
    </Grid>
  );
};

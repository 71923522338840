import { useMemo } from 'react';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useUserDetails } from 'providers/user-details';
import { getLinks } from 'utils';
import { ESidebarSize } from '../../../types';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0 24px',

    '& > *': {
      color: theme.palette.grey[600],

      '& a': {
        textDecoration: 'underline',
      },
    },
  },
}));

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
}

export const MenuFooter = (props: Props) => {
  const classes = useStyles();
  const { currentLanguage } = useUserDetails();

  const { termsConditionsLink, privacyLink, legalNoticeLink } = useMemo(() => {
    return getLinks(currentLanguage);
  }, [currentLanguage]);

  return (
    <Box className={classes.footer}>
      <Typography variant="body2">
        <a href={privacyLink}>
          <FormattedMessage id="privacy_statement" />
        </a>
      </Typography>

      <Typography variant="body2">
        <FormattedMessage
          id="terms_conditions.plus_legal_notice"
          values={{
            al: (msg: string) => <a href={legalNoticeLink}>{msg}</a>,
            at: (msg: string) => <a href={termsConditionsLink}>{msg}</a>,
          }}
        />
      </Typography>
    </Box>
  );
};

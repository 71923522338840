import { ShareSaleStatus } from 'types';
import { CommonFilterValues } from '../types';
import { InvestorApiControllerGetInvestorsSecondarymarketOffersParams } from 'api/investor-client';

export interface CommonSecondaryInvestmentFilterValues extends CommonFilterValues {
  havingSaleStatus?: ShareSaleStatus;
  minDuration?: number;
  maxDuration?: number;
}

export interface SecondaryInvestmentFilterValues extends CommonSecondaryInvestmentFilterValues {}

export type SecondaryInvestmentFilterPayload = Omit<
  InvestorApiControllerGetInvestorsSecondarymarketOffersParams,
  'id' | 'pageSize' | 'pageIndex'
>;

export enum ESecondaryInvestmentSortOption {
  Currency = 'Currency',
  Duration = 'Duration',
  InvestmentAmount = 'InvestmentAmount',
  InvestmentDate = 'InvestmentDate',
  Rating = 'Rating',
  ProductType = 'ProductType',
  HavingSaleStatus = 'HavingSaleStatus',
}

import { Typography, Grid, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import Card from 'components/card';
import { PageContainer } from 'components/UI';
import { Link } from 'react-router-dom';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    height: '100%',
    justifyContent: 'center',
  },
  mainTitle: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  itemBox: {
    borderRadius: '8px',
    border: `1px solid ${theme.palette.text.secondary016}`,
    padding: '24px 24px 16px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.text.secondary016}`,
    paddingBottom: '16px',
    gap: '20px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export const SupportPage = (props: Props) => {
  const classes = useStyles();

  return (
    <PageContainer>
      <Card className={classes.card}>
        <Card.Body>
          <Grid
            container
            flexDirection={'column'}
            display="flex"
            alignItems="normal"
            alignContent="center"
            gap="30px"
          >
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <Typography className={classes.mainTitle}>
                <FormattedMessage id="support.choose" />
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} lg={6} xl={6}>
              <Link
                to="/"
                onClick={(e) => {
                  window.open(process.env.REACT_APP_LOANTRADE_LINK, '_blank');
                  e.preventDefault();
                }}
              >
                <div className={classes.itemBox}>
                  <div className={classes.contentBox}>
                    <Box display="flex" flexDirection="column">
                      <FormattedMessage id="faq.title">
                        {(text) => <span className={classes.title}>{text}</span>}
                      </FormattedMessage>
                      <FormattedMessage id="faq.description">
                        {(text) => <span className={classes.text}>{text}</span>}
                      </FormattedMessage>
                    </Box>
                    <img src="/img/IlloFAQ.svg" alt="FAQ" />
                  </div>
                  <FormattedMessage id="faq.link_text">
                    {(txt) => <span className={classes.link}>{txt}</span>}
                  </FormattedMessage>
                </div>
              </Link>
            </Grid>

            <Grid item sm={12} md={6} lg={6} xl={6}>
              <Link
                to="/"
                onClick={(e) => {
                  window.open(`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`, '_blank');
                  e.preventDefault();
                }}
              >
                <div className={classes.itemBox}>
                  <div className={classes.contentBox}>
                    <Box display="flex" flexDirection="column">
                      <FormattedMessage id="report_bug.title">
                        {(text) => <span className={classes.title}>{text}</span>}
                      </FormattedMessage>
                      <FormattedMessage id="report_bug.description">
                        {(text) => <span className={classes.text}>{text}</span>}
                      </FormattedMessage>
                    </Box>
                    <img src="/img/IlloBug.svg" alt="Bug" />
                  </div>
                  <FormattedMessage id="report_bug.link_text">
                    {(txt) => <span className={classes.link}>{txt}</span>}
                  </FormattedMessage>
                </div>
              </Link>
            </Grid>

            <Grid item sm={12} md={6} lg={6} xl={6}>
              <Link
                to="/"
                onClick={(e) => {
                  window.open(`mailto:${process.env.REACT_APP_PRODUCT_EMAIL}`, '_blank');
                  e.preventDefault();
                }}
              >
                <div className={classes.itemBox}>
                  <div className={classes.contentBox}>
                    <Box display="flex" flexDirection="column">
                      <FormattedMessage id="contact_us.title">
                        {(text) => <span className={classes.title}>{text}</span>}
                      </FormattedMessage>
                      <FormattedMessage id="contact_us.description">
                        {(text) => <span className={classes.text}>{text}</span>}
                      </FormattedMessage>
                    </Box>
                    <img src="/img/IlloContact.svg" alt="Contact" />
                  </div>
                  <FormattedMessage id="contact_us.link_text">
                    {(txt) => <span className={classes.link}>{txt}</span>}
                  </FormattedMessage>
                </div>
              </Link>
            </Grid>
          </Grid>
        </Card.Body>
      </Card>
    </PageContainer>
  );
};

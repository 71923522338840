import { ComponentType, useMemo } from 'react';
import { Box, Tabs, Tab, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';

const useTabsStyles = makeStyles(() => {
  const theme = useTheme();

  return {
    wrapper: {},
    body: {
      position: 'relative',
      padding: '12px 16px 16px 16px',
      background: 'white',
      border: '1px solid #DFE3E8',
    },
    tabs: {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
        gap: 0,
      },
      marginBottom: '19px',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    tab: {
      minWidth: '188px',
      minHeight: '43px',
      paddingTop: '6px',
      paddingBottom: '6px',
      borderRadius: '4px',
      marginBottom: 0,
      position: 'relative',
      fontSize: '18px',
      lineHeight: '21px',
      background: '#ffffff',
      border: '2px solid #ffffff',
      color: theme.palette.text.primary,

      '&:not(:first-child)': {
        marginLeft: '-1.5px',
      },

      '&.Mui-selected': {
        color: '#ffffff',
        overflow: 'visible',
        background: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.light}`,
        zIndex: 1,
      },
    },
  };
});

interface Props<T extends object> {
  tabs: Array<{ id: string; title: string; content: ComponentType<T> }>;
  activeTab: string;
  onTabChange(id: string): void;
  tabProps: T;
  wrapperSx?: SxProps;
}

export const DetailsTabs = <T extends object>({
  tabs,
  activeTab,
  onTabChange,
  tabProps,
  wrapperSx,
}: Props<T>) => {
  const classes = useTabsStyles();

  const Body = useMemo(
    () => tabs.find((tab) => tab.id === activeTab)?.content ?? null,
    [activeTab, tabs],
  );

  return (
    <Box className={classes.wrapper} sx={wrapperSx}>
      <Tabs
        value={activeTab}
        scrollButtons
        onChange={(_, value) => onTabChange(value)}
        className={classes.tabs}
      >
        {tabs.map((tab) => (
          <Tab key={tab.id} label={tab.title} value={tab.id} className={classes.tab} />
        ))}
      </Tabs>

      <Box className={classes.body}>{Body && <Body {...tabProps} />}</Box>
    </Box>
  );
};

import { useLayoutEffect, useMemo } from 'react';
import { Tabs, Tab, TabProps } from '@mui/material';
import { useRouteMatch, useHistory, useLocation, Switch, Route, Link } from 'react-router-dom';
import urlJoin from 'url-join';

export interface INavigationTabOption<T extends string> {
  name: T;
  urlPath: string;
  tabLabel: string;
  component?: JSX.Element;
  tabProps?: Omit<Partial<TabProps>, 'component' | 'label' | 'value'>;
  disabled?: boolean;
}

export const useTabNavigation = <T extends string>(tabsOption: INavigationTabOption<T>[]) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const tabUrls = tabsOption.map((tab) => urlJoin(url, tab.urlPath));
    if (!tabUrls.some((tabUrl) => pathname.includes(tabUrl))) history.replace(tabUrls[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const activeTab = useMemo(() => {
    const tabUrls = tabsOption.map((tab) => ({
      key: tab.name,
      url: urlJoin(url, tab.urlPath),
    }));

    return tabUrls.find(({ url }) => pathname.includes(url))?.key ?? null;
  }, [pathname, tabsOption, url]);

  const tabsComponent = useMemo(() => {
    return (
      <Tabs value={activeTab ?? false}>
        {tabsOption.map((tab) => (
          <Tab
            key={tab.name}
            {...(tab.tabProps as any)}
            label={tab.tabLabel}
            value={tab.name}
            component={Link}
            disabled={tab.disabled}
            to={urlJoin(url, tab.urlPath)}
          />
        ))}
      </Tabs>
    );
  }, [activeTab, tabsOption, url]);

  const routeNavigationComponent = useMemo(() => {
    return (
      <Switch>
        {tabsOption.map((tab) => (
          <Route key={tab.name} exact path={urlJoin(path, tab.urlPath)}>
            {tab.component}
          </Route>
        ))}
      </Switch>
    );
  }, [path, tabsOption]);

  return { activeTab, tabsComponent, routeNavigationComponent };
};

import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCommonFiltersStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: '16px',

    [theme.breakpoints.up('md')]: {
      minWidth: '650px',
    },

    '&:before': {
      display: 'none',
    },

    '& .MuiAccordionSummary-root': {
      padding: '0 16px',
      backgroundColor: alpha(theme.palette.text.secondary, 0.08),
      borderRadius: theme.shape.borderRadius,
      minHeight: 'unset',

      '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        margin: '16px 0',

        '& .MuiTypography-root': {
          ...theme.typography.body2,
          color: theme.palette.text.secondary,
        },
      },

      '& .MuiAccordionSummary-expandIconWrapper': {
        fontSize: '18px',
        color: theme.palette.text.primary,
      },
    },

    '& .MuiAccordionDetails-root': {
      padding: '24px 0',

      [theme.breakpoints.up('sm')]: {
        padding: '24px 16px',
      },
    },
  },

  inlineSelect: {
    padding: 0,

    '& .MuiMenuItem-root': {
      [theme.breakpoints.up('sm')]: {
        padding: '16px',
      },

      '&.Mui-selected': {
        backgroundColor: 'unset',
      },
    },
  },

  rangeInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px 16px',

    [theme.breakpoints.up('sm')]: {
      padding: '24px 32px',
    },

    '& .MuiTextField-root': {
      flexGrow: 1,
    },
  },

  dash: {
    width: '8px',
    height: '1px',
    marginTop: '24px',
    backgroundColor: theme.palette.text.secondary,
  },
}));

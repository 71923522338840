import { moveDecimalPoint, trimObject } from 'utils';
import { PrimaryDealFilterValues, PrimaryDealFilterPayload } from './types';
import { FilterTransformHelpers } from '../types';

class PrimaryDealFiltersHelper
  implements FilterTransformHelpers<PrimaryDealFilterPayload, PrimaryDealFilterValues>
{
  getEmptyValue = (): PrimaryDealFilterValues => {
    return {
      productType: [],
      rating: [],
      currency: [],
    };
  };

  getPayloadFromValue = (values: PrimaryDealFilterValues): PrimaryDealFilterPayload => {
    return trimObject({
      productType: values.productType?.length ? values.productType : undefined,
      rating: values.rating?.length ? values.rating : undefined,
      currency: values.currency?.length ? values.currency : undefined,

      minDuration: values.minDuration,
      maxDuration: values.maxDuration,
      minPrincipal: values.minPrincipal
        ? moveDecimalPoint(values.minPrincipal, 2)
        : values.minPrincipal,
      maxPrincipal: values.maxPrincipal
        ? moveDecimalPoint(values.maxPrincipal, 2)
        : values.maxPrincipal,

      sortBy: values.sort?.sortBy,
      sortOrder: values.sort?.sortOrder,
    });
  };

  getValueFromPayload = (payload: Partial<PrimaryDealFilterPayload>): PrimaryDealFilterValues => {
    return trimObject({
      productType: Array.isArray(payload.productType) ? payload.productType : [],
      rating: Array.isArray(payload.rating) ? payload.rating : [],
      currency: Array.isArray(payload.currency) ? payload.currency : [],

      minDuration: payload.minDuration,
      maxDuration: payload.maxDuration,
      minPrincipal: payload.minPrincipal
        ? moveDecimalPoint(payload.minPrincipal, -2)
        : payload.minPrincipal,
      maxPrincipal: payload.maxPrincipal
        ? moveDecimalPoint(payload.maxPrincipal, -2)
        : payload.maxPrincipal,

      // prettier-ignore
      sort: payload.sortBy && payload.sortOrder
        ? { sortBy: payload.sortBy, sortOrder: payload.sortOrder }
        : undefined,
    });
  };

  countAmountValues = (values: PrimaryDealFilterValues) => {
    let counter = 0;

    if (typeof values.minPrincipal === 'number') counter += 1;
    if (typeof values.maxPrincipal === 'number') counter += 1;

    return counter;
  };

  countDurationValues = (values: PrimaryDealFilterValues) => {
    let counter = 0;

    if (typeof values.minDuration === 'number') counter += 1;
    if (typeof values.maxDuration === 'number') counter += 1;

    return counter;
  };

  countValues = (values: PrimaryDealFilterValues) => {
    let counter = 0;

    counter += values.currency?.length ?? 0;
    counter += values.productType?.length ?? 0;
    counter += values.rating?.length ?? 0;
    counter += this.countDurationValues(values);
    counter += this.countAmountValues(values);

    return counter;
  };
}

export const primaryDealFiltersHelper = new PrimaryDealFiltersHelper();

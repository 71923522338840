import { useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import { InvestmentSummaryResultDto } from 'api/investor-client';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint, toPercent } from 'utils';
import { useOverviewTableStyles } from 'styles';
import SimpleBar from 'simplebar-react';

interface Props {
  data: InvestmentSummaryResultDto | null;
}

const useStyles = makeStyles({
  scrollbar: {
    height: 'inherit',
    maxHeight: 'inherit',
    width: 'inherit',
    maxWidth: 'inherit',
  },

  table: {
    border: '1px solid rgba(224, 224, 224, 1)',

    '& th, & td': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },

    '& th': {
      fontWeight: 600,
    },
  },

  row: {
    '&.underline': {
      '& th, & td': {
        borderBottom: '2px solid #444444',
      },
    },

    '& > td:first-child': {
      fontWeight: 600,
    },

    '& > td:not(:first-child)': {
      textAlign: 'center',
    },
  },
});

export const InvestmentSummaryTable = ({ data }: Props) => {
  const tableClasses = useOverviewTableStyles();
  const classes = useStyles();
  const formatMoney = useFormatMoney();

  const format = useCallback(
    (amount: number | undefined) => {
      return amount !== undefined ? formatMoney(moveDecimalPoint(amount, -2)) : '~';
    },
    [formatMoney],
  );

  const totalDefault = (data?.technicalDefault.total ?? 0) - (data?.technicalDefault.interest ?? 0);

  const totalLoss = (data?.loss.total ?? 0) - (data?.loss.interest ?? 0);

  return (
    <TableContainer>
      <SimpleBar className={classes.scrollbar}>
        <Table size="small" className={clsx(tableClasses.table, classes.table)}>
          <TableHead>
            <TableRow className={tableClasses.row}>
              <TableCell></TableCell>
              <TableCell>
                <FormattedMessage id="principal" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="interest" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="amount.total" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="share_of_outstanding_portfolio" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className={clsx(tableClasses.row, classes.row)}>
              <TableCell>
                <FormattedMessage id="performing" />
              </TableCell>
              <TableCell>{format(data?.performing.principal)}</TableCell>
              <TableCell>{format(data?.performing.interest)}</TableCell>
              <TableCell>{format(data?.performing.total)}</TableCell>
              <TableCell>{data ? toPercent(data.performing.share) : '~'}</TableCell>
            </TableRow>

            <TableRow className={clsx(tableClasses.row, classes.row)}>
              <TableCell>
                <FormattedMessage id="repayment_status.Arrears_1_30" />
              </TableCell>
              <TableCell>{format(data?.arrears30.principal)}</TableCell>
              <TableCell>{format(data?.arrears30.interest)}</TableCell>
              <TableCell>{format(data?.arrears30.total)}</TableCell>
              <TableCell>{data ? toPercent(data.arrears30.share) : '~'}</TableCell>
            </TableRow>

            <TableRow className={clsx(tableClasses.row, classes.row)}>
              <TableCell>
                <FormattedMessage id="repayment_status.Arrears_31_60" />
              </TableCell>
              <TableCell>{format(data?.arrears60.principal)}</TableCell>
              <TableCell>{format(data?.arrears60.interest)}</TableCell>
              <TableCell>{format(data?.arrears60.total)}</TableCell>
              <TableCell>{data ? toPercent(data.arrears60.share) : '~'}</TableCell>
            </TableRow>

            <TableRow className={clsx(tableClasses.row, classes.row)}>
              <TableCell>
                <FormattedMessage id="repayment_status.Arrears_61_119" />
              </TableCell>
              <TableCell>{format(data?.arrears119.principal)}</TableCell>
              <TableCell>{format(data?.arrears119.interest)}</TableCell>
              <TableCell>{format(data?.arrears119.total)}</TableCell>
              <TableCell>{data ? toPercent(data.arrears119.share) : '~'}</TableCell>
            </TableRow>

            <TableRow className={clsx(tableClasses.row, classes.row, 'underline')}>
              <TableCell>
                <FormattedMessage id="repayment_status.TechnicalDefault" />
              </TableCell>
              <TableCell>{format(data?.technicalDefault.principal)}</TableCell>
              <TableCell>-</TableCell>
              <TableCell>{format(data ? totalDefault : undefined)}</TableCell>
              <TableCell>{data ? toPercent(data.technicalDefault.share) : '~'}</TableCell>
            </TableRow>

            <TableRow className={clsx(tableClasses.row, classes.row)}>
              <TableCell>
                <FormattedMessage id="repaid" />
              </TableCell>
              <TableCell>{format(data?.repaid.principal)}</TableCell>
              <TableCell>{format(data?.repaid.interest)}</TableCell>
              <TableCell>{format(data?.repaid.total)}</TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow className={clsx(tableClasses.row, classes.row)}>
              <TableCell>
                <FormattedMessage id="loss" />
              </TableCell>
              <TableCell>{format(data?.loss.principal)}</TableCell>
              <TableCell>-</TableCell>
              <TableCell>{format(data ? totalLoss : undefined)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SimpleBar>
    </TableContainer>
  );
};

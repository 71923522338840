import { makeStyles } from '@mui/styles';

const getCellSize = (percentage: number) => {
  return `calc(100% * ${percentage / 100})`;
};

export const useTableRowStyles = makeStyles((theme) => ({
  cell: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px 12px',
  },

  idCell: {
    minWidth: '100px',
    width: getCellSize(12),

    [theme.breakpoints.up('xl')]: {
      width: getCellSize(16),
    },
  },

  riskCell: {
    minWidth: '80px',
    paddingLeft: '24px',
    width: getCellSize(8),

    [theme.breakpoints.up('xl')]: {
      width: getCellSize(9),
    },
  },

  productTypeCell: {
    minWidth: '80px',
    width: getCellSize(8),

    [theme.breakpoints.up('xl')]: {
      width: getCellSize(12),
    },
  },

  statusCell: {
    minWidth: '80px',
    width: getCellSize(11),

    [theme.breakpoints.up('xl')]: {
      width: getCellSize(16),
    },
  },

  durationCell: {
    minWidth: '100px',
    width: getCellSize(10),

    [theme.breakpoints.up('xl')]: {
      width: getCellSize(11),
    },
  },

  dateCell: {
    minWidth: '100px',
    width: getCellSize(10),

    [theme.breakpoints.up('xl')]: {
      width: getCellSize(11),
    },
  },

  priceCell: {
    paddingLeft: '16px',
    minWidth: '120px',
    width: getCellSize(12),

    [theme.breakpoints.up('xl')]: {
      width: getCellSize(21),
    },
  },

  inputCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 'calc(50px + 32px)',
    minWidth: 'calc(50px + 32px)',
    width: 'calc(50px + 32px)',
    minHeight: 'calc(34px + 36px)',
    flexShrink: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { InvestmentWithDealDto } from 'api/investor-client';
import { DetailsSubTabs } from 'components';
import { InvestmentDetails } from './InvestmentDetailsSubtab';
import { RepaymentSchedule } from './RepaymentScheduleSubtab';

interface Props {
  investment: InvestmentWithDealDto;
}

enum ESubTab {
  Overview = 'overview',
  PaymentSchedule = 'payment_schedule',
}

export const InvestmentInformation = (props: Props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState(ESubTab.Overview);

  const subTabs = useMemo(
    () => [
      {
        id: ESubTab.Overview,
        title: intl.formatMessage({ id: ESubTab.Overview }),
        content: InvestmentDetails,
      },
      {
        id: ESubTab.PaymentSchedule,
        title: intl.formatMessage({ id: ESubTab.PaymentSchedule }),
        content: RepaymentSchedule,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl.formatMessage],
  );

  return (
    <DetailsSubTabs
      tabs={subTabs}
      activeTab={activeTab}
      onTabChange={setActiveTab as any}
      tabProps={props}
    />
  );
};

import { useMemo } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';

import { IDealDto } from 'api/investor-client';
import { IDealDetails, getDealDetails, moveDecimalPoint } from 'utils';

import { useControlledState } from './useControlledState';
import { useFormatMoney } from './makeFormatMoney';
import { useSelectedInvestorAccount } from './useSelectedInvestorAccount';

const getFieldMessages = (
  dDetails: IDealDetails,
  formatMessage: IntlFormatters['formatMessage'],
  formatMoney: (value: number) => string,
) => {
  let placeholder = '';
  const tooltip: string[] = [];

  // if last investor
  if (dDetails.common.isLastInvestor) {
    const value = formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2));
    placeholder = formatMessage({ id: 'deals.amount_for_last_investor.mini' }, { value: value });
    tooltip.push(formatMessage({ id: 'deals.amount_for_last_investor' }, { value: value }));
    return { placeholder, tooltip: tooltip.join('. ') };
  }

  // step message
  if (dDetails.config.investmentStep > 1) {
    tooltip.push(
      formatMessage(
        { id: 'deals.step' },
        { value: formatMoney(moveDecimalPoint(dDetails.config.investmentStep, -2)) },
      ),
    );
  }

  // min. amount message
  if (dDetails.config.minInvestment && dDetails.config.minInvestment > 1) {
    const value = formatMoney(moveDecimalPoint(dDetails.config.minInvestment, -2));
    placeholder = formatMessage({ id: 'deals.min_amount_to_invest.mini' }, { value: value });
    tooltip.push(formatMessage({ id: 'deals.min_amount_to_invest' }, { value: value }));
  }

  // max. amount message
  if (dDetails.config.maxInvestment) {
    if (
      dDetails.config.maxInvestment === dDetails.common.totalRestToCommit ||
      dDetails.config.maxInvestment + dDetails.config.investmentStep >=
        dDetails.common.totalRestToCommit
    ) {
      const trueValue =
        dDetails.config.maxInvestment + dDetails.config.investmentStep ===
        dDetails.common.totalRestToCommit
          ? dDetails.common.totalRestToCommit
          : dDetails.config.maxInvestment;

      const value = formatMoney(moveDecimalPoint(trueValue, -2));

      if (!placeholder) {
        placeholder = formatMessage({ id: 'deals.max_amount_to_invest.mini' }, { value: value });
      }

      tooltip.push(formatMessage({ id: 'deals.max_amount_to_invest' }, { value: value }));
    } else {
      const value = formatMoney(moveDecimalPoint(dDetails.config.maxInvestment, -2));
      const total = formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2));

      if (!placeholder) {
        placeholder = formatMessage({ id: 'deals.max_amount_to_invest.mini' }, { value: total });
      }

      tooltip.push(
        formatMessage({ id: 'deals.max_amount_to_invest_with_total' }, { value, total }),
      );
    }
  }

  return { placeholder, tooltip: tooltip.join('. ') };
};

const validate = (
  amount: number,
  dDetails: IDealDetails,
  formatMessage: IntlFormatters['formatMessage'],
  formatMoney: (value: number, config?: any) => string,
) => {
  // validate last investor
  if (dDetails.common.isLastInvestor && amount !== dDetails.common.totalRestToCommit) {
    return formatMessage(
      { id: 'deals.error.last_investor' },
      { value: formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2)) },
    );
  }

  // validate min amount
  if (amount < dDetails.config.minInvestment) {
    return formatMessage(
      { id: 'deals.error.min_amount' },
      { value: formatMoney(moveDecimalPoint(dDetails.config.minInvestment, -2)) },
    );
  }

  // validate max amount
  if (
    dDetails.config.maxInvestment &&
    amount > dDetails.config.maxInvestment &&
    amount !== dDetails.common.totalRestToCommit
  ) {
    if (dDetails.config.maxInvestment === dDetails.common.totalRestToCommit) {
      return formatMessage(
        { id: 'deals.error.max_amount' },
        { value: formatMoney(moveDecimalPoint(dDetails.config.maxInvestment, -2)) },
      );
    } else if (
      dDetails.config.maxInvestment + dDetails.config.investmentStep >=
      dDetails.common.totalRestToCommit
    ) {
      return formatMessage(
        { id: 'deals.error.max_amount' },
        { value: formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2)) },
      );
    } else {
      return formatMessage(
        { id: 'deals.error.max_amount_with_total' },
        {
          value: formatMoney(moveDecimalPoint(dDetails.config.maxInvestment, -2)),
          total: formatMoney(moveDecimalPoint(dDetails.common.totalRestToCommit, -2)),
        },
      );
    }
  }

  // validate step amount
  if (dDetails.config.investmentStep && amount % dDetails.config.investmentStep !== 0) {
    let minNearest = amount - (amount % dDetails.config.investmentStep);

    if (dDetails.config.minInvestment && minNearest < dDetails.config.minInvestment) {
      minNearest = dDetails.config.minInvestment;
    }

    let maxNearest = minNearest + dDetails.config.investmentStep;

    if (dDetails.config.maxInvestment && maxNearest > dDetails.config.maxInvestment) {
      maxNearest = dDetails.config.maxInvestment;
    }

    return formatMessage(
      { id: 'deals.error.step' },
      {
        value: formatMoney(moveDecimalPoint(dDetails.config.investmentStep, -2), {
          prefix: '',
        }),
        minNearest: formatMoney(moveDecimalPoint(minNearest, -2), { prefix: '' }),
        maxNearest: formatMoney(moveDecimalPoint(maxNearest, -2), { prefix: '' }),
      },
    );
  }

  return null;
};

export const useDealAmountInput = (deal: IDealDto, initialAmount?: number) => {
  const intl = useIntl();
  const { selectedAccount } = useSelectedInvestorAccount();
  const investorId = selectedAccount?.investor?.id;

  const [amount, setAmount] = useControlledState(initialAmount);
  const formatMoney = useFormatMoney({ prefix: `${deal.currency} ` });

  const dealDetails = useMemo(() => {
    return investorId ? getDealDetails(deal, investorId) : null;
  }, [deal, investorId]);

  const helperMessages = useMemo(
    () => (dealDetails ? getFieldMessages(dealDetails, intl.formatMessage, formatMoney) : null),
    [dealDetails, intl.formatMessage, formatMoney],
  );

  const error = useMemo(() => {
    if (amount === undefined || !deal.configuration || !dealDetails) return null;
    const trueAmount = moveDecimalPoint(amount, 2);
    return validate(trueAmount, dealDetails, intl.formatMessage, formatMoney);
  }, [amount, deal, dealDetails, intl.formatMessage, formatMoney]);

  const tooltipMessage = useMemo(() => {
    return error ? error! : helperMessages?.tooltip ?? null;
  }, [error, helperMessages?.tooltip]);

  return {
    dealDetails,
    amount,
    setAmount,
    placeholder: helperMessages?.placeholder,
    tooltipMessage,
    isError: error !== null,
    loaded: !!dealDetails,
  };
};

import { useState } from 'react';
import { Button, Menu, MenuItem, CircularProgress, ButtonProps } from '@mui/material';
import { RiArrowDownSFill } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

interface Props extends Omit<ButtonProps, 'onClick'> {
  onExportCurrent(): void;
  onExportFull(): void;
  loading?: boolean;
  exportCurrentMessage?: string;
  exportAllMessage?: string;
}

export const ExportButton = ({
  onExportCurrent,
  onExportFull,
  loading,
  exportCurrentMessage,
  exportAllMessage,
  ...buttonProps
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        variant="outlined"
        {...buttonProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        disabled={loading || buttonProps.disabled}
        endIcon={loading ? <CircularProgress size="1rem" /> : <RiArrowDownSFill size="1rem" />}
      >
        <FormattedMessage id="export_to_csv" />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            onExportCurrent();
            handleClose();
          }}
        >
          {exportCurrentMessage ? (
            exportCurrentMessage
          ) : (
            <FormattedMessage id="export_current_view" />
          )}
        </MenuItem>

        <MenuItem
          onClick={() => {
            onExportFull();
            handleClose();
          }}
        >
          {exportAllMessage ? exportAllMessage : <FormattedMessage id="export_all" />}
        </MenuItem>
      </Menu>
    </>
  );
};

import { useState } from 'react';
import { Box, Button, Paper, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { RiAddFill, RiArrowDownLine } from 'react-icons/ri';

import { useInvestorAccounts } from 'providers/investor-accounts';
import { CurrencyIcon } from 'components/UI';
import { useFormatMoney } from 'hooks';
import { moveDecimalPoint } from 'utils';
import { Currency } from 'types';

import { WithdrawModal } from './WithdrawModal/WithdrawModal';
import { DepositModal } from './DepositModal/DepositModal';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    padding: '24px 16px',
    background: theme.palette.background.barkSurface004,

    [theme.breakpoints.up('sm')]: {
      padding: '32px',
    },
  },

  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '8px',

    [theme.breakpoints.up('sm')]: {
      paddingRight: '0px',
    },
  },

  content: {
    margin: '56px 0 64px',
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      margin: '0 0 74px',
      textAlign: 'left',
    },
  },

  actionGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: '16px',
    },
  },

  button: {
    flexGrow: 1,
    flexBasis: '25%',
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'uppercase',
  },

  unpaidCommitmentsMessage: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0 0',

    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '0px',
      right: '0px',
      height: '32px',
      justifyContent: 'flex-end',
      padding: '0 32px',
    },
  },
}));

interface Props {
  currency: Currency;
  onReload: () => Promise<any>;
  depositDefaultOpened: boolean | undefined;
  unpaidCommitments: number | undefined;
  bankAccount: string | undefined;
  isDDActivated: boolean | undefined;
}

export const ManageFunds = (props: Props) => {
  const {
    depositDefaultOpened,
    onReload,
    unpaidCommitments,
    isDDActivated,
    bankAccount,
    currency,
  } = props;

  const classes = useStyles();
  const { selectedAccount } = useInvestorAccounts();
  const formatMoney = useFormatMoney({ prefix: `${currency} ` });

  const isSMSize = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
  const [isDepositOpen, setIsDepositOpen] = useState(depositDefaultOpened ?? false);

  const balance = selectedAccount?.VABalance?.find((e1) => e1.currency === currency)?.amount ?? 0;

  return (
    <>
      <Paper elevation={0} className={classes.card}>
        <Box className={classes.iconWrapper}>
          <CurrencyIcon currency={currency} size={isSMSize ? 32 : 40} />
        </Box>

        <Box className={classes.content}>
          <Typography variant="h2" fontWeight={700}>
            {formatMoney(moveDecimalPoint(balance, -2))}
          </Typography>

          <Typography variant="subtitle2" color="text.secondary">
            <FormattedMessage id={`currency.${currency}`} />{' '}
            <FormattedMessage id="account_balance" />
          </Typography>
        </Box>

        <Box className={classes.actionGroup}>
          <Button
            variant="contained"
            color="primary"
            size={isSMSize ? 'medium' : 'large'}
            className={classes.button}
            startIcon={<RiAddFill size={24} />}
            onClick={() => setIsDepositOpen(true)}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="add_funds" />
            </Typography>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size={isSMSize ? 'medium' : 'large'}
            className={classes.button}
            startIcon={<RiArrowDownLine size={18} />}
            onClick={() => setIsWithdrawOpen(true)}
            disabled={unpaidCommitments === undefined || unpaidCommitments > 0 || balance === 0}
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="withdraw_funds" />
            </Typography>
          </Button>
        </Box>

        {!!unpaidCommitments && (
          <Box className={classes.unpaidCommitmentsMessage}>
            <Typography variant="caption" color="error" lineHeight={1}>
              <FormattedMessage id="withdraw_funds.disabled" />
            </Typography>
          </Box>
        )}
      </Paper>

      <DepositModal
        open={isDepositOpen}
        onClose={() => setIsDepositOpen(false)}
        bankAccount={bankAccount}
        currency={currency}
        unpaidCommitments={unpaidCommitments}
        isDDActivated={isDDActivated}
        onReload={onReload}
      />

      <WithdrawModal
        open={isWithdrawOpen}
        onClose={() => setIsWithdrawOpen(false)}
        bankAccount={bankAccount}
        currency={currency}
        onReload={onReload}
      />
    </>
  );
};

import { alpha, Menu, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { MenuContent } from './MenuContent';
import { ESidebarSize } from '../../types';
import { MOBILE_HEIGHT_BREAKPOINT } from '../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    '&.mobile': {
      bottom: '0 !important',
      left: '0 !important',
      right: '0 !important',
      width: '100vw',
      maxWidth: '100vw',
      borderRadius: 'unset',

      '&:not(.medium-height)': {
        top: '0 !important',
        height: '100vh',
        maxHeight: '100vh',
      },

      '&.medium-height': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
  },

  menu: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 0',

    '&.desktop': {
      height: '520px',
      width: '350px',
    },
  },

  backdrop: {
    backgroundColor: alpha(theme.palette.primary.dark, 0.8),

    '&.desktop': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  collapsed?: boolean;
  sidebarSize?: ESidebarSize;
  anchorEl?: HTMLElement | null | undefined;
  onClose?: () => void;
  onSidebarClose?: () => void;
}

export const FooterMenu = ({
  anchorEl,
  onClose,
  onSidebarClose,
  collapsed,
  sidebarSize,
}: Props) => {
  const classes = useStyles();

  const mediumHeight = useMediaQuery(MOBILE_HEIGHT_BREAKPOINT);
  const isMobile = sidebarSize === ESidebarSize.Mobile;
  const isDesktop = sidebarSize === ESidebarSize.Desktop;

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      MenuListProps={{
        className: clsx(classes.menu, { desktop: isDesktop }),
      }}
      PaperProps={{
        className: clsx(classes.container, { mobile: isMobile }, { 'medium-height': mediumHeight }),
      }}
      slotProps={{
        backdrop: { className: clsx(classes.backdrop, { desktop: isDesktop }) },
      }}
      elevation={2}
      sx={{ zIndex: 2000 }}
      marginThreshold={isDesktop ? 16 : 0}
      disablePortal={isDesktop}
    >
      <MenuContent
        collapsed={collapsed}
        sidebarSize={sidebarSize}
        onClose={onClose}
        onSidebarClose={onSidebarClose}
      />
    </Menu>
  );
};

import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { RiCloseLine, RiNotification4Line, RiSettings4Line } from 'react-icons/ri';
import { useAsyncCallback } from 'react-async-hook';
import { FormattedMessage } from 'react-intl';

import { NotificationMessage } from 'api/notifications-client';
import { useNotificationsProvider } from 'providers/notifications';

import { Notification } from './Notification';
import { NotificationSkeleton } from './NotificationSkeleton';

const useStyles = makeStyles((theme) => ({
  badge: {
    cursor: 'pointer',

    '& .MuiBadge-badge': {
      padding: '0 4px',
      outline: `2px solid ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.primary.light,

      [theme.breakpoints.up('sm')]: {
        minWidth: '16px',
        height: '16px',
        outline: `2px solid ${theme.palette.background.paper}`,
      },
    },
  },

  popover: {
    zIndex: 1550,

    '& .MuiPaper-root': {
      width: '430px',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        borderRadius: 'unset',
      },
    },
  },

  closeButton: {
    marginRight: '-8px',
  },

  divider: {
    borderColor: theme.palette.grey[400],
  },

  markAllButton: {
    padding: '6px 8px',
    marginRight: '-8px',
  },

  popoverTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    flexShrink: 0,
    padding: '16px',

    [theme.breakpoints.up('sm')]: {
      padding: '20px 16px',
    },
  },

  popoverBody: {
    overflow: 'auto',
    flexGrow: 1,

    [theme.breakpoints.up('sm')]: {
      height: 'min(650px, 55vh)',
    },
  },

  popoverFooter: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    padding: '8px 16px',
    color: theme.palette.text.secondary,
  },

  settingsButton: {
    margin: '0px -8px',
  },

  placeholderContainer: {
    height: '100%',
    padding: '24px 16px',
  },

  placeholder: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    padding: '16px 36px 56px',
    textAlign: 'center',
  },
}));

export const NotificationsWidget = () => {
  const classes = useStyles();
  const {
    notificationMetadata,
    isReady,
    getMessages,
    markMessageAsRead,
    markAllMessagesAsRead,
    goToUrl,
  } = useNotificationsProvider();
  const { url } = useRouteMatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);

  const [wrapperRef, setWrapperRef] = useState<HTMLElement | null>(null);

  const fetchMessages = useAsyncCallback(async (restart?: boolean) => {
    if (!isReady || notificationMetadata.total === 0) return;
    const eof = !restart && notifications.length >= notificationMetadata.total;
    if (eof) return;

    const messages = await getMessages(restart ? 0 : notifications.length, 15);
    setNotifications((state) => (restart ? messages : state.concat(messages)));
  });

  const loadingRef = useRef(fetchMessages.loading);
  loadingRef.current = fetchMessages.loading;

  // initial fetch
  useEffect(() => {
    if (!isReady || notificationMetadata.total === 0 || notifications.length) return;
    fetchMessages.execute(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, notificationMetadata.total]);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    fetchMessages.execute(true);
  };

  const handleClose = () => {
    setNotifications((state) => state.slice(0, 15));
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification: NotificationMessage) => {
    if (!notification.readAt) {
      setNotifications((state) => {
        return state.map((n) => {
          if (n.id === notification.id) return { ...n, readAt: new Date().toISOString() };
          return n;
        });
      });

      markMessageAsRead(notification.id);
    }

    if (typeof notification.data.url === 'string') {
      goToUrl(notification.data.url);
      setAnchorEl(null);
    }
  };

  const handleMarkAllAsRead = async () => {
    setNotifications((state) => {
      return state.map((n) => {
        if (!n.readAt) return { ...n, readAt: new Date().toISOString() };
        return n;
      });
    });

    await markAllMessagesAsRead();
  };

  const loadMore = useCallback(() => {
    if (loadingRef.current) return;
    fetchMessages.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMessages.execute]);

  const checkToLoadNext = useCallback(() => {
    const el = wrapperRef;
    if (!el) return;

    const elTotalHeight = el.scrollHeight;
    const elScrolledDistance = el.clientHeight + el.scrollTop;
    const PRELOAD_HEIGHT = 100;

    if (elScrolledDistance + PRELOAD_HEIGHT > elTotalHeight) {
      loadMore();
    }
  }, [loadMore, wrapperRef]);

  useEffect(() => {
    if (!wrapperRef) return;

    wrapperRef.addEventListener('scroll', checkToLoadNext);
    document.addEventListener('resize', checkToLoadNext);

    return () => {
      wrapperRef.removeEventListener('scroll', checkToLoadNext);
      document.removeEventListener('resize', checkToLoadNext);
    };
  }, [checkToLoadNext, wrapperRef]);

  const isTabletSize = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const isEmpty = notifications.length === 0;
  const isLoading = (fetchMessages.loading && isEmpty) || !isReady;
  const isLoadingMore = fetchMessages.loading && !isEmpty;

  return (
    <>
      <Badge
        badgeContent={notificationMetadata.unread}
        color="primary"
        variant={isTabletSize ? 'standard' : 'dot'}
        className={classes.badge}
      >
        <IconButton color="inherit" sx={{ margin: '-8px' }} onClick={handleOpen}>
          <RiNotification4Line size={24} />
        </IconButton>
      </Badge>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={classes.popover}
        marginThreshold={isTabletSize ? 16 : 0}
      >
        <Box className={classes.popoverTitle}>
          <Typography variant="subtitle2">
            <FormattedMessage id="notifications" />
            {notificationMetadata.unread > 0 ? ` (${notificationMetadata.unread})` : undefined}
          </Typography>

          {isTabletSize ? (
            <Button
              variant="text"
              className={classes.markAllButton}
              onClick={handleMarkAllAsRead}
              disabled={notificationMetadata.unread === 0}
            >
              <FormattedMessage id="mark_all_as_read" />
            </Button>
          ) : (
            <IconButton className={classes.closeButton} onClick={() => setAnchorEl(null)}>
              <RiCloseLine />
            </IconButton>
          )}
        </Box>

        <Divider className={classes.divider} />

        <Box ref={setWrapperRef} className={classes.popoverBody}>
          {isLoading ? (
            Array.from({ length: 3 }, (_, i) => i).map((index) => (
              <NotificationSkeleton key={`skeleton-${index}`} />
            ))
          ) : isEmpty ? (
            <Box className={classes.placeholderContainer}>
              <Box className={classes.placeholder}>
                <img src="/img/notification-placeholder.svg" alt="" />
                <Typography variant="body2">
                  <FormattedMessage id="empty_notification_widget_message" />
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              {notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onClick={handleNotificationClick}
                />
              ))}

              {isLoadingMore &&
                Array.from({ length: 2 }, (_, i) => i).map((index) => (
                  <NotificationSkeleton key={`skeleton-${index}`} />
                ))}
            </>
          )}
        </Box>

        <Divider className={classes.divider} />

        <Box className={classes.popoverFooter}>
          {!isTabletSize && (
            <Button
              variant="text"
              className={classes.markAllButton}
              onClick={handleMarkAllAsRead}
              disabled={notificationMetadata.unread === 0}
            >
              <FormattedMessage id="mark_all_as_read" />
            </Button>
          )}

          <Link to={`${url}/settings/notification`} onClick={() => setAnchorEl(null)}>
            <IconButton color="inherit" className={classes.settingsButton}>
              <RiSettings4Line size={24} />
            </IconButton>
          </Link>
        </Box>
      </Popover>
    </>
  );
};
